import React, { useContext, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import Text from "../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { css, styled } from "styled-components";
import ActionListItem from "../components/molecule/ActionListItem";
import { MoreRightIcon } from "../../../../components/ver1.5/assets/icons";
import { useNavigate } from "react-router-dom";
import Divider from "../../../../components/ver1.5/atom/Divider";
import Userterms2 from "../../../../components/common/UserTerms2";
import Serviceterms from "../../../../components/common/ServiceTerms";
import ActionListCommon from "../components/organism/ActionListCommon";
import { UserContext } from "../../../../contexts/UserContext";
import DefaultModal from "../../../../components/ver1.5/organism/DefaultModal";

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

const OverlappedBtn = styled.div`
  width: calc(100vw - 3.2rem);
  max-width: 80rem;
  height: 4rem;
  position: absolute;
  right: 0;
  top: -1rem;
`;

const Setting = () => {
  const { accessToken } = useContext(UserContext);

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const settingList = [
    {
      text: "개인정보처리방침",
      rightContent: (
        <Userterms2
          customBtn={(onClick) => (
            <IconWrapper onClick={() => onClick()}>
              <MoreRightIcon />
              <OverlappedBtn onClick={() => onClick()} />
            </IconWrapper>
          )}
        />
      ),
    },
    {
      text: "이용약관",
      rightContent: (
        <Serviceterms
          customBtn={(onClick) => (
            <IconWrapper onClick={() => onClick()}>
              <MoreRightIcon />
              <OverlappedBtn onClick={() => onClick()} />
            </IconWrapper>
          )}
        />
      ),
    },
    {
      text: "버전",
      rightContent: (
        <Text fontStyle={font.body1} color={color.gray.d300}>
          1.5.0
        </Text>
      ),
    },
  ];

  return (
    <Layout
      footerPadding={false}
      styles={css`
        > * {
          margin-left: 1.6rem;
          margin-right: 1.6rem;
        }
      `}
    >
      <Text
        fontStyle={font.body2}
        color={color.gray.d900}
        marginTop="1.6rem"
        marginBottom="0.8rem"
        marginLeft="1.6rem"
        marginRight="1.6rem"
      >
        알림 설정
      </Text>
      <ActionListItem
        text={"혜택/이벤트"}
        subText={"앱 푸시,문자,이메일"}
        fontStyle={font.body1}
        isLast={true}
        containerStyle={css`
          padding: 2.4rem 0;
        `}
        rightContent={
          <IconWrapper>
            <MoreRightIcon />
          </IconWrapper>
        }
        onClickContainer={() => {
          if (!accessToken) {
            setModal(true);
            return;
          }
          navigate("/user/my/alarm");
        }}
      />
      <Divider direction="horizontal" width={"1rem"} color={color.gray.d50} />

      <ActionListCommon
        rows={settingList}
        fontStyle={font.body1}
        itemVerticalPadding={"2.4rem"}
      />
      {modal && (
        <DefaultModal
          title={"로그인이 필요합니다."}
          actionText={"로그인"}
          cancelText={"취소"}
          onAction={() =>
            navigate("/user/login", {
              state: { redirectUrl: `/user/my/setting` },
            })
          }
          onCancel={() => setModal(false)}
        />
      )}
    </Layout>
  );
};

export default Setting;
