import React from "react";
import { css, styled } from "styled-components";
import Text from "../../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../../components/ver1.5/styles/theme";
import { setCommas } from "../../../../../../function/common";
import MenuDefault from "../../../../../../components/ver1.5/assets/img/menuItemDefault.svg";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 1.2rem 1.6rem;
  gap: 2rem;
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
  ${(p) =>
    !p.$isLast &&
    css`
      border-bottom: 1px solid ${color.gray.d50};
    `}
`;

const Image = styled.img`
  min-width: 10rem;
  height: 8rem;
  aspect-ratio: 34 / 21;
  border-radius: 0.8rem;
  flex-shrink: 0;
  object-fit: cover;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const ProductListItem = ({ item, onClick, isLast }) => {
  const { description, name, price, representImageUrl, images } = item;
  return (
    <Container onClick={() => (onClick ? onClick() : null)} $isLast={isLast}>
      <Image src={representImageUrl ?? images?.[0]?.url ?? MenuDefault} />
      <TextContainer>
        <Text fontStyle={font.subtitle3} color={color.gray.d900}>
          {name}
        </Text>
        <Text
          fontStyle={font.body4}
          color={color.gray.d600}
          styles={css`
            word-break: keep-all;
          `}
        >
          {description}
        </Text>
        <Text fontStyle={font.subtitle2} color={color.gray.d900}>
          {setCommas(price) + "원"}
        </Text>
      </TextContainer>
    </Container>
  );
};

export default ProductListItem;
