import React from "react";
import { css, styled } from "styled-components";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import {
  couponSortList,
  headerFooterZIndex,
  presetHeight,
  reviewSortList,
} from "../../../../../components/ver1.5/constants";
import Text from "../../../../../components/ver1.5/atom/Text";
import SelectMenu from "../../../../../components/ver1.5/organism/SelectMenu";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  background: ${color.primary.white};
  z-index: ${headerFooterZIndex - 1};
  position: fixed;
  top: ${presetHeight.header};
  width: 100%;
  left: 0;
`;

const SortHeader = ({ total = 0, sort, setSort }) => {
  return (
    <Container>
      <Text
        fontStyle={font.subtitle3}
        color={color.gray.d900}
        styles={css`
          span {
            color: ${color.primary.flexRed};
          }
        `}
      >
        전체 <span>{total}</span>
      </Text>
      {/* <SelectMenu options={couponSortList} value={sort} setValue={setSort} /> */}
    </Container>
  );
};

export default SortHeader;
