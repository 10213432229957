import { useNavigate, useSearchParams } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import "./MSCommon.css";
import "./Main.css";

import { Modal } from 'react-bootstrap';

export default function Main(props){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState('STEP1');

    const [tel, setTel] = useState();

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const { code } = useParams();
    PrintConsole(code);

    const massageType = "MONEY_SHOW_FLEX_DAY";

    const [item, setItem] = useState();
    function SendItem() {
        document.getElementById("submitBtn").setAttribute("disabled","true");
        // console.log(document.getElementById("TellNumber").value);
        
        // console.log(document.getElementById("TellNumber").value.length);
        if(
            document.getElementById("TellNumber").value === '' || 
            document.getElementById("TellNumber").value === null ||
            document.getElementById("TellNumber").value.length <= 0
        ){
            alert('연락처를 확인해주세요.');
            document.getElementById('submitBtn').removeAttribute("disabled");
            return false;
        }
        
        return new Promise( async function(resolve, reject) {
            let obj = {
                "phoneNumbers": [
                    document.getElementById("TellNumber").value
                ],
                "messageType": massageType
            }

            console.log(obj);
            
            const response = await axios.post(
                getApiUrl('money-shows/me/coupons/send/number' ),
                obj,
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json;",
                // axios.defaults.headers.common['Content-Type'] = "application/json;",
            ).then(
                function(response){
                    console.log('----------------------------------');
                    console.log(response);
                    if(response.status === 200){
                        setItem(response.data);
                        let myModal = new bootstrap.Modal(document.getElementById('MS-Modal-03'), {
                            keyboard: false
                        })
                        myModal.show();
                        CountItem();
                        document.getElementById("TellNumber").value = '';
                        document.getElementById('submitBtn').removeAttribute("disabled");
                        window.scrollTo(0, 0);
                    }
                }
            ).catch(function(error){
                // navigate('/404');
                console.log('error');
                AlertError(error);
                document.getElementById('submitBtn').removeAttribute("disabled");
            })
        });
        
        document.getElementById('submitBtn').removeAttribute("disabled");
    }

    const [count, setCount] = useState();
    function CountItem() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get(
                getApiUrl('money-shows/me/coupons'),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    console.log(response);
                    if(response.status === 200){
                        setCount(response.data);
                    }
                }
            ).catch(function(error){
                // navigate('/404');
                console.log('error');
            })
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출
        CountItem();

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    // useEffect( () => {
    //     if(count !== undefined){
    //         document.getElementById("TmpCount").value = "잔여 : " + count.countOfPurchases + " 개";
    //     }
    // }, [count]);

    return (
        <> 
              <div className="MS">
                <div className="MS-Main">

                    <div className="MSBackground">
                        <img src="https://www.flexdaycdn.net/public/images/moneyshow/background-img1.png" alt=""
                            className="img-fluid bg-img1" />
                        <img src="https://www.flexdaycdn.net/public/images/moneyshow/background-img2.png" alt=""
                            className="img-fluid bg-img2" />
                        <img src="https://www.flexdaycdn.net/public/images/moneyshow/background-img3.png" alt=""
                            className="img-fluid bg-img3" />
                        <img src="https://www.flexdaycdn.net/public/images/moneyshow/background-img4.png" alt=""
                            className="img-fluid bg-img4" />
                    </div>

                    <header className="MSHeader">
                        <div className="inner">
                            <div className="align-items-center d-flex justify-content-between">
                                <div className="HeaderLeft">
                                    <div className="flex-logo">
                                        <img className="img-fluid" alt=""
                                            src="https://www.flexdaycdn.net/public/images/moneyshow/flexday_logo.png" />
                                    </div>
                                    <div className="HeaderNav">
                                        <div className="nav-item">
                                            <a className="nav-link active" href="#">대량 발송</a>
                                        </div>
                                        <div className="nav-item">
                                            <a className="nav-link" onClick={()=>{alert('무료 버전에선 사용할 수 없는 기능입니다.')}} >관리</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="com-logo">
                                    <button type="button" className="btn btn-clear btn-logout rounded-0 w-100" onClick={ () => {localStorage.clear(); navigate("/ms/login") }}>로그아웃</button>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="Contents-inner">
                        <div className="MSPrdList">
                            <div className="MStitbox mb-4-5">
                                <p className="tit">
                                    보유 상품 리스트
                                    <img className="tit-icon img-fluid" alt=""
                                        src="https://www.flexdaycdn.net/public/images/moneyshow/icon_01.png" />
                                </p>
                            </div>
                            <div className="row gx-4 gy-5">
                                { count && count.map( row => (
                                    <div className="col-6 col-md-3 MSitem">
                                        <div className="imgbox">
                                            <img className="img-fluid rounded-4" alt=""
                                                src="https://www.flexdaycdn.net/public/images/moneyshow/flex_prd_01.png" />
                                        </div>
                                        <div className="px-3">
                                            <p className="MSname mt-3 mb-2">
                                                겐츠베이커리
                                            </p>
                                            <p className="MScom mb-0">
                                                {row.productName}
                                            </p>
                                            <p className="MSprice mb-0" id='TmpCount'>
                                                잔여 : { row.countOfPurchases - row.countOfSent}개
                                            </p>
                                        </div>
                                    </div>
                                    ))
                                }
                                <div className="col-6 col-md-3 MSitem">
                                    <div className="imgbox">
                                        <img className="img-fluid rounded-4" alt=""
                                            src="https://www.flexdaycdn.net/public/images/moneyshow/flex_prd_03.jpg" />
                                    </div>
                                    <div className="px-3">
                                        <p className="MSname mt-3 mb-2">
                                            겐츠베이커리
                                        </p>
                                        <p className="MScom mb-0">
                                            쿠키 세트
                                        </p>
                                        <p className="MSprice mb-0">
                                            잔여 : 0개
                                        </p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 MSitem">
                                    <div className="imgbox">
                                        <img className="img-fluid rounded-4" alt=""
                                            src="https://www.flexdaycdn.net/public/images/moneyshow/menu_03.png" />
                                    </div>
                                    <div className="px-3">
                                        <p className="MSname mt-3 mb-2">
                                            신기여울
                                        </p>
                                        <p className="MScom mb-0">
                                            시그니처 라떼
                                        </p>
                                        <p className="MSprice mb-0">
                                            잔여 : 0개
                                        </p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 MSitem">
                                    <div className="imgbox">
                                        <img className="img-fluid rounded-4" alt=""
                                            src="https://www.flexdaycdn.net/public/images/moneyshow/menu_05.png" />
                                    </div>
                                    <div className="px-3">
                                        <p className="MSname mt-3 mb-2">
                                            신기여울
                                        </p>
                                        <p className="MScom mb-0">
                                            아메리카노
                                        </p>
                                        <p className="MSprice mb-0">
                                            잔여 : 0개
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="MSForm">
                            <div className="MStitbox mb-4-5">
                                <p className="tit">
                                    대량 발송
                                </p>
                            </div>
                            <div className="MSFormOption mb-5">
                                <p className="MSSubTitle">
                                    1. 발송할 상품을 선택해주세요.
                                </p>
                                <div className="radioBox row d-flex">
                                    <div className="radioItem col-6 col-md-3 mb-4">
                                        <input className="form-check-input" type="radio" name="MMSPrd" id="MMSPrd" checked/>
                                        <label className="form-check-label" for="MMSPrd1">
                                            { count && count.map( row => (
                                                row.productName
                                            ))}
                                        </label>
                                    </div>
                                    <div className="radioItem col-6 col-md-3 mb-4">
                                        {/* <input className="form-check-input-diabled" type="radio" name="MMSPrd" id="MMSPrd" onClick={ (e) => { e.preventDefault(); e.stopPropagation(); }}/> */}
                                        <label className="form-check-label-diabled" for="MMSPrd2">
                                            쿠키 세트
                                        </label>
                                    </div>
                                    <div className="radioItem col-6 col-md-3 mb-4">
                                        {/* <input className="form-check-input-diabled" type="radio" name="MMSPrd" id="MMSPrd" onClick={ (e) => { e.preventDefault(); e.stopPropagation(); }}/> */}
                                        <label className="form-check-label" for="MMSPrd3">
                                            시그니처 라떼
                                        </label>
                                    </div>
                                    <div className="radioItem col-6 col-md-3 mb-4">
                                        {/* <input className="form-check-input-diabled" type="radio" name="MMSPrd" id="MMSPrd" onClick={ (e) => { e.preventDefault(); e.stopPropagation(); }}/> */}
                                        <label className="form-check-label-diabled" for="MMSPrd4">
                                            아메리카노
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="inputBox">
                                    <input type="number" className="form-control InputType MSInput rounded-3 w-md-50"
                                        placeholder="발송 개수를 입력해주세요." />
                                </div> */}
                            </div>
                            <div className="MSFormbox">
                                <p className="MSSubTitle">
                                    2. 발송할 연락처를 업로드해주세요.
                                </p>
                                <ul className="MSFormNav align-items-end mb-4 nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active px-4" id="contentNum-tab" data-bs-toggle="pill"
                                            data-bs-target="#contentNum" type="button" role="tab"
                                            onClick="SampleHide(this);">연락처로 업로드</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link  px-4" id="contentFile-tab" data-bs-toggle="pill"
                                            data-bs-target="#contentFile" type="button" role="tab"
                                            onClick="SampleShow(this);">엑셀 파일로 업로드</button>
                                    </li>
                                    
                                    <li>
                                        <a href="https://www.flexdaycdn.net/public/images/moneyshow/Moneyshow_Sample.xlsx"
                                            download className="MSSampleLink show">샘플양식</a>
                                    </li>
                                </ul>
                                <p className="MS-Infotxt">
                                    이벤트 상품 발송을 위한 <span data-bs-toggle="modal" data-bs-target="#MS-Modal-04">개인정보 수집에 동의</span>합니다.
                                </p>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade" id="contentFile" role="tabpanel">
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12">
                                                {/* <input type="file" id="ms_file" className="MS-File rounded-3"
                                                    onChange="FileChangeTxt()" />
                                                <label for="ms_file" className="MS-File-Label rounded-3">
                                                    참가자 연락처 파일을 업로드 해주세요. (.xlsx)
                                                </label> */}
                                                <p className="MS-Infotxt">
                                                    현재 권한으로 사용할 수 없는 기능입니다.
                                                </p>
                                            </div>
                                            {/* <div className="col-sm-3 mt-2 mt-sm-0">
                                                <button type="button" className="btn btn-primary MS-File-submit"
                                                    data-bs-toggle="modal" data-bs-target="#MS-Modal-03">발송</button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show active" id="contentNum" role="tabpanel">
                                        <div className="row justify-content-center">
                                            <div className="col-sm-9">
                                                <input type="number" className="form-control rounded-3 InputType MSInput" id="TellNumber"
                                                    placeholder="참가자 연락처를 입력해주세요. (’-’없이 번호만 입력)" />
                                            </div>
                                            <div className="col-sm-3 mt-2 mt-sm-0">
                                                <button type="button" className="btn btn-primary rounded-3 MS-File-submit" id='submitBtn' onClick={ () => {SendItem()}}>발송</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="modal fade text-center MSModal3" id="MS-Modal-04" tabIndex="-1">
                                <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <p className="modal-title">
                                                이벤트 상품 발송을 위한 개인정보 수집
                                            </p>
                                        </div>
                                        <div className="modal-body mt-4">
                                            1) 수집하는 개인정보의 항목 <br/>
                                            : 휴대폰 번호 <br/><br/>
                                            2) 개인정보의 수집/이용 목적<br/>
                                            : 이벤트 상품 발송 <br/><br/>
                                            3) 개인정보 보유 및 이용 기간<br/>
                                            : 특정금융정보법에 의거 개인정보를 제공한 날로부터 5년간 보유·이용 <br/><br/>
                                            <br/>
                                            이벤트 상품 발송을 위한 개인정보 수집에 동의합니다.
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                            <button type="button" className="btn btn-default w-100"
                                                data-bs-dismiss="modal">닫기</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade text-center MSModal2" id="MS-Modal-03" tabIndex="-1">
                                <div className="modal-dialog modal-sm modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header flex-wrap">
                                            <img className="d-block img-fluid mb-2 mx-auto plus w-25" alt=""
                                                src="https://www.flexdaycdn.net/public/images/moneyshow/alert_ani.gif" />
                                            <p className="modal-title">
                                                교환권 발송 완료
                                            </p>
                                        </div>
                                        <div className="modal-body">
                                            교환권 발송이 완료되었습니다! <br />
                                            카카오 알림톡으로 모바일 교환권을 발송합니다.
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                            <button type="button" className="btn btn-default w-100"
                                                data-bs-dismiss="modal">완료</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}