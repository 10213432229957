
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
export default function Calculate(){

    async function submit(){  
        
    }

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="정산계좌" />
                <Row>
                    <Col>
                        <h4>모바일 선물 정산계좌</h4>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Select>
                            <option>국민은행 등</option>
                        </Form.Select>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="passwordCk" >
                            <Form.Label>계좌번호 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="111111-1-11111"/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="passwordCk" >
                            <Form.Label>예금주 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="주식회사 플렉스데이"/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <div className="d-grid gap-1">
                        <Button variant="primary" size="lg" onClick={submit}>
                            완료
                        </Button>
                    </div>
                </Row>
            </Container>
        </>
    )
}