import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { AlertError, getApiUrl, PrintConsole } from '../../../function/common';
import Btn from '../../../components/common/Btn';


export default function UseGiftNumber(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    async function useGift(){

        // document.getElementById('submitBtn').setAttribute("disabled","true");

        if(document.getElementById('CouponNumber').value === null || document.getElementById('CouponNumber').value === '' || document.getElementById('CouponNumber').value.length < 11){
            alert('잘못된 교환권 번호입니다.');
            return false;
        }

        axios.post(
            getApiUrl("stores/" + localStorage.getItem('storeDomain') + "/mobile-couponNumber/" + document.getElementById('CouponNumber').value), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    // console.log(response.data.redirectUrl);
                    window.location.href=response.data.redirectUrl;
                }
            }
        ).catch(function(error){
            AlertError(error);
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="교환권 조회" />
                <Row className="MainSpace">
                    <Col xs={{ span:10, offset:1 }}>
                        <h5>교환권 번호를 입력해주세요.</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span:7, offset:1 }}>
                        <Form.Group className="mt-3" controlId="CouponNumber">
                            <Form.Label>교환권 번호를 입력해 주세요. <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text"  placeholder="교환권 번호를 입력하세요." onChange={onChangeData} />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={{ span:3 }} className='align-self-end'>
                        <div className="d-grid gap-1">
                            <Button variant="primary" id='submitBtn' onClick={ useGift }>
                                조회
                            </Button>
                        </div>
                    </Col>
                </Row>

                <div className="fixed-bottom mb-4 row">
                    <div className="col-10 offset-1">
                        <div className="PageInfoTxt2 p-2 p-3">
                            <p>
                                • 재화 또는 서비스 제공 없이 교환권, 금액권을 사용처리 하시면 안됩니다.
                            </p>
                            <p className="mb-0">
                                • 교환권의 경우, 반드시 교환권 금액 이상 사용하셔야 합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}