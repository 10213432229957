import { Alert, Button, Image } from "react-bootstrap";
import { getNewlines } from "../../function/common.js";
// import { Button } from 'react-floating-action-button';
import { Link, useNavigate } from "react-router-dom";

export default function CustomAlert(props) {
  let TmpStyle = {};
  const navigate = useNavigate();

  if (props.css === "LEFT") {
    TmpStyle = { paddingLeft: "20%" };
    console.log(TmpStyle);
  }

  let tit = "",
    sub = "",
    iconUrl = "",
    btntext = "";

  if (props.code === 4) {
    tit = "매장등록 심사 중입니다.";
    sub = "최종 완료를 위해 남은 단계를 진행해 보세요!";
    iconUrl =
      "https://www.flexdaycdn.net/public/images/static/store-main/store_sand.png";
    btntext = "사업자 등록증 사본 올리기";
  } else if (props.code === 3) {
    tit = "사업자 승인이 거절되었습니다.";
    sub = "관리자 전달 사항을 확인해보세요.";
    iconUrl =
      "https://www.flexdaycdn.net/public/images/static/store-main/store_fail.png";
    btntext = "승인 내역 확인";
  }

  if (props.type === "ALERT") {
    return (
      <>
        <Alert variant={"primary"} style={TmpStyle}>
          {getNewlines(props.text)}
        </Alert>
      </>
    );
  } else if (props.type === "CENTER") {
    return (
      <>
        <Alert variant={"primary"} className="text-center">
          {getNewlines(props.text)}
        </Alert>
      </>
    );
  } else if (props.type === "BTN") {
    return (
      <>
        <Alert
          variant={"light"}
          className="text-center Register-Process active"
        >
          {Boolean(iconUrl) && (
            <Image className="img-fluid Register_img basic" src={iconUrl} />
          )}
          {Boolean(tit) && <p className="Register_tit">{tit}</p>}
          {Boolean(sub) && <p className="Register_sub">{sub}</p>}
          <Button
            variant="default"
            className="type15 btn-light h-40px"
            onClick={() => navigate("/store/approveprocess")}
          >
            {btntext}
          </Button>
        </Alert>
      </>
    );
  } else {
    return (
      <>
        <Alert variant="success" style={{ whiteSpace: "pre-wrap" }}>
          {getNewlines(props.text)}
        </Alert>
      </>
    );
  }
}
