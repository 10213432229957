import { useNavigate, useSearchParams } from "react-router-dom";

import { useState, React, useRef, useCallback } from "react";
import { useEffect } from "react";
import axios from "axios";
import {
  AlertError,
  getApiUrl,
  PrintConsole,
  setCommas,
} from "../../../function/common";
import { Container, Col, Row, Form, Button, Modal } from "react-bootstrap";
import { openQRRead, CancelQRRead } from "./../../../api/ApiWebView";

import "./Payment.css";

export default function Calculator() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});
  const onChangeData = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
    PrintConsole(data);
  };

  const [appData, setAppData] = useState([]);

  const [calState, setCalState] = useState("STEP1");

  const [newbie, setNewbie] = useState(true);

  // const [lastNumber, setLastNumber] = useState();
  const lastNumber = useRef(null);

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //함수 호출
    window.event = new Event("event");
    window.addEventListener("event", async (e) => {
      console.log("이벤트 시작");

      if (e.detail["status"] === 1 || e.detail["status"] === "1") {
        setAppData((appData) => [
          {
            productName: e.detail["productName"],
            couponNumber: e.detail["couponNumber"],
            storeSalesPrice: e.detail["storeSalesPrice"],
            productstatus: e.detail["status"],
            expireDate: e.detail["expireDate"],
          },
          ...appData,
        ]);

        lastNumber.current = e.detail["couponNumber"];
      }

      setAppData((appData) =>
        appData.filter((Element, index) => {
          return (
            appData.findIndex(
              (item) => item["couponNumber"] === Element["couponNumber"]
            ) === index
          );
        })
      );

      console.log("이벤트 끝");
    });

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  useEffect(() => {
    var TmpSum = 0;

    console.log(appData.length);
    if (appData.length > 0) {
      appData.map((row) => {
        console.log(row.productstatus);
        if (row.productstatus === "1" || row.productstatus === 1) {
          TmpSum = parseInt(TmpSum) + parseInt(row.storeSalesPrice);
        }
      });
    }
    setSumItem(TmpSum);
  }, [appData]);

  const bulkUseGift = async (e) => {
    // console.log(newbie);
    let elementTelNumber = document.getElementById("TelNumber");

    let TelNumber = elementTelNumber == null ? null : elementTelNumber.value;
    // console.log(TelNumber);
    // console.log()

    let obj = {
      numbers: [],
      totalPrice: totalPrice["TotalPrice"],
      cashPhoneNumber: TelNumber,
      firstVisit: newbie,
    };
    appData.map((row) => {
      obj["numbers"].push(row.couponNumber);
    });

    console.log(obj);

    await axios
      .post(
        getApiUrl(
          "stores/" + localStorage.getItem("storeDomain") + "/coupons/"
        ),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;"),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        PrintConsole(response.data);
        if (response.status === 200) {
          setCalState("STEP4");
        } else {
          alert("교환권이 사용 처리에 실패하였습니다.");
        }
      })
      .catch(function (error) {
        PrintConsole(error);
        AlertError(error);
      });
  };

  function itemDelete(delItem) {
    setAppData(appData.filter((row) => row.couponNumber !== delItem));
  }

  const [totalPrice, setTotalPrice] = useState({});
  const onChangeTotalPrice = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    setTotalPrice({
      ...totalPrice,
      [e.target.id]: e.target.value,
    });
    PrintConsole(totalPrice);
    inputNumberCheck();
  };

  const [sumItem, setSumItem] = useState({});

  function MinQRCode() {
    if (document.getElementById("CheckVisitorRadio2").checked === true) {
      setNewbie(false);
      console.log("겐츠 첫방문이 아님");
      // return false;
    } else {
      setNewbie(true);
      // openQRRead();
    }

    setCalState("STEP3");
  }

  function CheckVisit() {
    var TmpTotalPrice = document.getElementById("TotalPrice").value;
    console.log(TmpTotalPrice);
    if (
      TmpTotalPrice === "" ||
      TmpTotalPrice === undefined ||
      TmpTotalPrice === null ||
      TmpTotalPrice === "null"
    ) {
      alert("필수 입력입니다.");
      return false;
    }
    if (TmpTotalPrice < 1000) {
      alert("최소 결제금액은 1,000원 입니다.");
      return false;
    } else {
      setCalState("STEP2");
      // openQRRead();
    }
  }

  function PayboxShow() {
    let Navbox = document.querySelector(".b-navbar");
    let Paybox = document.querySelector(".b-paybox");
    Navbox.classList.add("hide");
    Paybox.classList.add("show");
  }

  function PayboxHide() {
    let Navbox = document.querySelector(".b-navbar");
    let Paybox = document.querySelector(".b-paybox");
    Navbox.classList.remove("hide");
    Paybox.classList.remove("show");
  }

  function uncomma(str) {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  }

  function comma(str) {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  }

  // function inputNumberFormat(obj) {
  //     obj.value = comma(uncomma(obj.value));
  // }

  function inputNumberCheck() {
    let PriceInput = document.querySelector(".PayPrice");
    let Value = PriceInput.value;

    if (Value < 1000) {
      let Btn = document.querySelector(".btn-numcheck");
      Btn.classList.add("btn-secondary");
      Btn.classList.remove("btn-default");

      PriceInput.classList.add("active");
    } else {
      let Btn = document.querySelector(".btn-numcheck");
      Btn.classList.remove("btn-secondary");
      Btn.classList.add("btn-default");

      PriceInput.classList.remove("active");
    }
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (loading) return <></>;

  console.log(JSON.parse(localStorage.getItem("myStores")).storeStaffId);

  let TmpGantz = false;
  let TmpStaffId = JSON.parse(localStorage.getItem("myStores")).storeStaffId;

  console.log(TmpStaffId);
  if (
    TmpStaffId === "118" ||
    TmpStaffId === "119" ||
    TmpStaffId === "120" ||
    TmpStaffId === "121" ||
    TmpStaffId === "122"
  ) {
    TmpGantz = true;
  }
  console.log(TmpGantz);

  if (calState === "STEP1") {
    return (
      <>
        <div className="PaymentPage">
          <div className="container-fluid g-0">
            <nav className="b-paybox fixed-bottom">
              <div className="bar-icon mx-auto my-2"></div>
              <div className="btnbox d-flex justify-content-between my-3 px-5">
                <a
                  href="#none"
                  className="btn btn-secondary h-45px rounded-pill"
                >
                  결제하기
                </a>
                <a
                  href="#none"
                  className="btn btn-light h-45px rounded-pill"
                  onClick={() => {
                    PayboxHide();
                  }}
                >
                  결제 취소
                </a>
              </div>
            </nav>

            <div className="PaymentBox Inner">
              <div className="Payment-title mt-5">
                <p className="PageTit mb-1">
                  총 결제 금액
                  <img
                    className="img-fluid mb-1"
                    style={{ width: "24px" }}
                    src="https://www.flexdaycdn.net/public/images/static/payment/pay_icon.png"
                    alt=""
                  />
                </p>
                <p className="PageSib">
                  결제할 총 금액을 입력해주세요. <br />
                  여러 개의 결제를 한 번에 할 수 있어요!
                </p>
              </div>
              <div className="Payment-Contents row mb-4">
                <div className="col Payment-Inputbox position-relative">
                  <input
                    type="number"
                    className="form-control InputType h-50px PayPrice"
                    placeholder="총 결제 금액"
                    required
                    id="TotalPrice"
                    onChange={onChangeTotalPrice}
                  />
                  <div className="end-0 me-3 mt-3 pe-3 position-absolute price-txt top-0">
                    원
                  </div>
                  <div className="form-text info-txt">
                    1,000원부터 입력 가능합니다.
                  </div>
                </div>
              </div>
            </div>

            <div className="Payment-Confirm Inner">
              <button
                type="button"
                className="btn btn-secondary btn-numcheck"
                onClick={() => {
                  CheckVisit();
                }}
              >
                QR로 결제하기
              </button>
            </div>

            {/* <div className="Inner mb-4 mb-5 row">
                            <div className="col-10 offset-1">
                                <div className="PageInfoTxt2 p-2 p-3">
                                    <p>
                                        • 재화 또는 서비스 제공 없이 교환권, 금액권을 사용처리 하시면 안됩니다.
                                    </p>
                                    <p className="mb-0">
                                        • 교환권의 경우, 반드시 교환권 금액 이상 사용하셔야 합니다.
                                    </p>
                                </div>
                            </div>
                        </div> */}

            <div className="Inner mb-4 mt-5">
              <div className="PageInfoTxt2 p-2 p-3">
                <p>
                  • 재화 또는 서비스 제공 없이 교환권, 금액권을 사용처리 하시면
                  안됩니다.
                </p>
                <p className="mb-0">
                  • 교환권의 경우, 반드시 교환권 금액 이상 사용하셔야 합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (calState === "STEP2") {
    if (localStorage.getItem("storeDomain") === "gentz") {
      return (
        <>
          <div className="CheckVisitor">
            <div className="container g-0 mw-100">
              <div className="Inner m-auto py-2">
                <div className="CheckVisitor-title mt-20vh text-center">
                  <p className="sub mb-1">잠깐! 이 멘트 안내를 부탁드려요.</p>
                  <p className="tit">"겐츠 방문은 처음이신가요?"</p>
                </div>
                <div className="BtnGroup d-flex mb-3 mt-5">
                  <input
                    className="btn-check"
                    type="radio"
                    name="CheckVisitorRadio"
                    id="CheckVisitorRadio1"
                    checked
                  />
                  <label
                    className="form-check-label btn h-auto btn-light"
                    htmlFor="CheckVisitorRadio1"
                  >
                    네, 첫방문입니다.
                  </label>

                  <input
                    className="btn-check"
                    type="radio"
                    name="CheckVisitorRadio"
                    id="CheckVisitorRadio2"
                  />
                  <label
                    className="form-check-label btn h-auto btn-light"
                    htmlFor="CheckVisitorRadio2"
                  >
                    아니오.
                  </label>
                </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      MinQRCode();
                    }}
                  >
                    완료
                  </button>
                  <p className="confirm-txt mt-3 text-center">
                    답변 완료 시, 바로 결제가 가능합니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      setCalState("STEP3");
    }
  } else if (calState === "STEP3") {
    return (
      <>
        <div className="PaymentPage ver2">
          <div className="container-fluid g-0">
            <div className="PaymentBox">
              <div className="PaymentTop">
                <div className="PayPriceTotal">
                  <div className="row py-2 px-4-5 align-items-center g-0">
                    <div className="col-7 tit">총 결제 금액</div>
                    <div className="col-5 price text-end">
                      <span className="price-total me-1">
                        {setCommas(totalPrice["TotalPrice"])}
                      </span>
                      원
                    </div>
                  </div>
                </div>
                <div className="PayPriceAdd">
                  <div className="row py-3 px-4-5 align-items-center g-0">
                    <div className="col-7 tit">추가로 결제하실 금액</div>
                    <div className="col-5 price text-end">
                      <span className="price-add me-1">
                        {setCommas(totalPrice["TotalPrice"] - sumItem)}
                      </span>
                      원
                    </div>
                  </div>
                </div>
              </div>
              <div className="PaymentList">
                <div className="align-items-center g-0 py-4 row">
                  {appData &&
                    appData.map((row) => (
                      <div
                        className="col-md-6 col-sm-12 mb-4-5 px-4-5"
                        key={row.couponNumber}
                      >
                        <div className="PaymentList-Item py-4 px-4-5 position-relative">
                          <div className="d-flex align-items-end g-0 justify-content-between prd-titbox row">
                            <div className="name w-75">{row.productName}</div>
                            <div className="text-end use w-25">
                              {row.productstatus === "1"
                                ? "사용가능"
                                : "사용 불가능"}
                            </div>
                          </div>
                          <div className="align-items-end d-flex justify-content-between prd-infobox">
                            <div className="price">
                              <span>{setCommas(row.storeSalesPrice)}</span>원
                            </div>
                            <div className="date text-end">
                              {row.expireDate}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-clear end-0 p-2 position-absolute top-0"
                            onClick={() => {
                              itemDelete(row.couponNumber);
                            }}
                          >
                            <img
                              src="https://www.flexdaycdn.net/public/images/static/payment/close_btn.png"
                              alt=""
                              className="img-fluid wid"
                              style={{ width: "20px" }}
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="Payment-Confirm fixed-bottom bg-white">
              {TmpGantz === true ? (
                <div className="px-4-5 py-4">
                  <div className="Payment-AddInfo">
                    <p className="info-txt mb-3">
                      ㈜플렉스데이 교환권 사용 금액에 대한 현금영수증 발행
                      요청이 있을 시, 아래 내용을 기입해주세요.
                    </p>
                    <div className="CashReceiptsBox px-4-5 py-3">
                      <div className="CashReceiptCheck mb-2">
                        <input
                          className="form-check-input me-1"
                          type="radio"
                          name="CashReceipts"
                          id="CashReceipts2"
                          checked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="CashReceipts2"
                        >
                          현금영수증 미발행
                        </label>
                      </div>

                      <div className="CashReceiptCheck">
                        <input
                          className="form-check-input me-1"
                          type="radio"
                          name="CashReceipts"
                          id="CashReceipts1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="CashReceipts1"
                        >
                          현금영수증 발행
                        </label>
                        <div className="CashReceiptInput row mt-2 mt-md-0">
                          <div className="col-auto">
                            <p className="mb-0 w-100">
                              <span className="ColorA">*</span>
                              휴대폰번호 (소득공제용)
                            </p>
                          </div>
                          <div className="col mt-2 mt-md-0">
                            <input
                              type="number"
                              id="TelNumber"
                              className="form-control InputType h-auto p-2"
                              placeholder="(‘-’없이 숫자만 입력)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="d-flex">
                <button
                  type="button"
                  className="align-items-center btn btn-light d-flex justify-content-center rounded-0 w-50"
                  onClick={() => {
                    openQRRead();
                  }}
                >
                  <img
                    className="img-fluid me-2 qr-scan"
                    src="https://www.flexdaycdn.net/public/images/static/payment/qr_scan.png"
                    alt=""
                  />
                  추가결제
                </button>
                <Button
                  variant="default"
                  className="rounded-0"
                  onClick={handleShow}
                >
                  결제 완료
                </Button>
              </div>
            </div>

            <Modal
              size="sm"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="modal fade modal-prd-stop text-center"
              centered
            >
              <Modal.Header className="pt-4-5">
                <Modal.Title>
                  <p className="modal-title">결제 하시겠습니까?</p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-clear btn-modal-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-clear btn-modal-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    bulkUseGift();
                  }}
                >
                  확인
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    );
  } else if (calState === "STEP4") {
    return (
      <>
        <div className="PaymentPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">결제 완료</p>
              <button
                type="button"
                className="btn-clear btn-close"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/static/store-main/close.png"
                />
              </button>
            </nav>

            <div className="PaymentBox Inner">
              <div className="Payment-title my-5 text-center">
                <img
                  className="img-fluid mb-3"
                  src="https://www.flexdaycdn.net/public/images/static/payment/smile_emoji.png"
                  alt=""
                />
                <p className="PageTit2 mb-1">
                  결제가 성공적으로 <br />
                  완료되었습니다.
                </p>
              </div>

              <div className="PaymentResult mb-5 px-4-5 py-4-5">
                <div className="PaymentResult-Item d-flex justify-content-between mb-2">
                  <div className="tit w-50">입력하신 금액</div>
                  <div className="price text-end w-50">
                    <span>{setCommas(totalPrice["TotalPrice"])}</span>원
                  </div>
                </div>

                <div className="PaymentResult-Item d-flex mb-3">
                  <div className="tit w-50">결제 완료 금액</div>
                  <div className="price text-end w-50">
                    <span>{setCommas(sumItem)}</span>원
                  </div>
                </div>
                <div className="PaymentResult-Item add-price d-flex">
                  <div className="tit w-75">추가로 결제하실 금액</div>
                  <div className="price text-end w-50">
                    <span>{setCommas(totalPrice["TotalPrice"] - sumItem)}</span>
                    원
                  </div>
                </div>
              </div>
            </div>

            <div className="Payment-Confirm Inner">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  navigate("/store/storecalculate");
                }}
              >
                정산내역 보러가기
              </button>
              <p className="Payment-info-txt mt-3 text-center">
                <a href="javascript:void(0);" onClick={CancelQRRead}>
                  결제 취소
                </a>
                가 필요하세요?
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
