import React, { useContext, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { styled } from "styled-components";
import Text from "../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Button from "../../../../components/ver1.5/atom/Button";
import DefaultModal from "../../../../components/ver1.5/organism/DefaultModal";
import PasswordModal from "../components/organism/PasswordModal";
import WithdrawalConfirmModal from "../components/organism/WithdrawalConfirmModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiUrl } from "../../../../function/common";
import sha256 from "sha256";
import { UserContext } from "../../../../contexts/UserContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding: 2.4rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const ContentWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  list-style: disc;
`;

const BottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
`;

const infoList = [
  "플렉스데이 서비스에 가입하신 아이디/계정을 탈퇴할 경우, 재사용 및 복구가 불가능합니다.",
  "탈퇴 후 회원님의 정보 및 이용기록은 모두 삭제됩니다. 삭제된 데이터는 복구되지 않습니다",
  "탈퇴 후 재가입은 탈퇴일로부터 30일 이후부터 가능합니다.",
];

const Withdrawal = () => {
  const { accessToken, logout } = useContext(UserContext);

  const [password, setPassword] = useState("");
  const [passwordModal, setPasswordModal] = useState(false);
  const [withdrawalConfirmModal, setWithdrawalConfirmModal] = useState(false);
  const navigate = useNavigate();

  const onCheckPasswordModal = () => {
    deleteUser();
  };

  const deleteUser = async () => {
    const res = await axios
      .delete(
        getApiUrl("users/me"),
        {
          data: {
            password: sha256(password),
          },
        },
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken),
        (axios.defaults.headers.common["ContentType"] =
          "application/json; charset=utf-8")
      )
      .then((res) => {
        if (res.status === 200) {
          setPasswordModal(false);
          setWithdrawalConfirmModal(true);
        } else {
          alert("회원 탈퇴에 실패하였습니다. 잠시 후 다시 이용 부탁드립니다.");
          setWithdrawalConfirmModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message) {
          alert(error.response.data.message);
        } else {
          alert("회원 탈퇴에 실패하였습니다. 잠시 후 다시 이용 부탁드립니다.");
        }
        setWithdrawalConfirmModal(false);
      });
    return res;
  };

  return (
    <Layout>
      <Container>
        <TitleWrapper>
          <Text fontStyle={font.h2} color={color.gray.d800}>
            탈퇴하시겠습니까?
          </Text>
          <div>
            <Text fontStyle={font.body1} color={color.gray.d800}>
              탈퇴&nbsp;후,&nbsp;계정&nbsp;복구가&nbsp;불가능합니다
            </Text>
            <Text fontStyle={font.body1} color={color.gray.d800}>
              신중하게&nbsp;선택해주세요!
            </Text>
          </div>
        </TitleWrapper>
        <ContentWrapper>
          {infoList.map((info, index) => (
            <li key={index} style={{ marginLeft: "2.4rem" }}>
              <Text fontStyle={font.caption2} color={color.gray.d700}>
                {info}
              </Text>
            </li>
          ))}
        </ContentWrapper>
      </Container>
      <BottomContainer>
        <Button text="회원탈퇴" onClick={() => setPasswordModal(true)} />
      </BottomContainer>
      {passwordModal && (
        <PasswordModal
          onAction={onCheckPasswordModal}
          onCancel={() => setPasswordModal(false)}
          password={password}
          setPassword={setPassword}
        />
      )}
      {withdrawalConfirmModal && (
        <WithdrawalConfirmModal
          onAction={() => {
            logout();
            setWithdrawalConfirmModal(false);
            navigate("/user", { replace: true });
          }}
        />
      )}
    </Layout>
  );
};

export default Withdrawal;
