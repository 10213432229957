import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { PrintConsole, getImgUrl } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { setAccessToken } from './../../api/ApiWebView';

export default function StoreApply(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    if(localStorage.getItem('storeDomain') === undefined && localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }
        

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        try{
            setAccessToken();
        }catch(e){}

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Row>
                    <Btn type="NAVBACK" title="홍보물" />
                </Row>

                <Row className='mt-3'>
                    <Col className='text-center' >
                        <Image className='w-50' src={getImgUrl('images/icon/image_errerpage_404.png')} fluid/>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='text-center' xs={{span:10, offset:1}}>
                        <h1 className="display-1 fw-bold LabelTitle Label404">서비스<br/>준비중입니다.</h1>
                        <p className="fs-3 LabelTitle Label404">불편을 드려 죄송합니다.</p>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='d-grid' xs={{span:10, offset:1}}>
                        <Link to={"/store/"+localStorage.getItem('storeDomain')} className="btn btn-primary">홈으로</Link>
                    </Col>
                </Row>
            </Container>
        </>
    )
}