import React, { useEffect, useRef } from "react";
import DetailInfoItem from "../molecule/DetailInfoItem";
import detailInfoContentList from "./detailInfoContentList";
import { color } from "../../../../../../components/ver1.5/styles/theme";

const DetailInfo = ({ storeInfo: info }) => {
  const mapRef = useRef();
  const addressInfo = info?.storeAddress;
  const isSingleStore = Boolean(info.singleStore);

  const KakaoMapScript = () => {
    if (!addressInfo) return;
    if (!mapRef?.current) return;
    const container = mapRef?.current;
    window.kakao.maps.load(() => {
      const options = {
        center: new window.kakao.maps.LatLng(33.450701, 126.570667),
        level: 3,
      };
      const map = new window.kakao.maps.Map(container, options);

      const geocoder = new window.kakao.maps.services.Geocoder();

      // TODO: 나중에 위/경도 추가되면 해당 값으로 정상적으로 지도 호출되는지 확인 필요. (현재 확인할 수 없음)
      if (Boolean(Number(info.latitude)) && Boolean(Number(info.longitude))) {
        const coords = new window.kakao.maps.LatLng(
          info.latitude,
          info.longitude
        );

        const marker = new window.kakao.maps.Marker({
          map,
          position: coords,
        });

        map.setCenter(coords);
      } else {
        geocoder.addressSearch(addressInfo.address, (result, status) => {
          if (status === window.kakao.maps.services.Status.OK) {
            const coords = new window.kakao.maps.LatLng(
              result[0].y,
              result[0].x
            );
            // eslint-disable-next-line
            const marker = new window.kakao.maps.Marker({
              map,
              position: coords,
            });

            map.setCenter(coords);
          }
        });
      }
    });
  };

  const infoList = detailInfoContentList({
    info,
  });

  useEffect(() => {
    if (mapRef?.current && addressInfo) {
      KakaoMapScript();
    }
  }, [mapRef, addressInfo]);

  return (
    <>
      {infoList.map((info, index) => {
        return (
          <div key={index}>
            <DetailInfoItem
              sectionTitle={info.title}
              contents={info.content}
              titleWidth={info.titleWidth}
              isAccordion={info.isAccordion}
              isLast={
                index === infoList.length - 1 || info.title === "영업 정보"
              }
            />
            {info.title === "영업 정보" && isSingleStore && (
              <div
                ref={mapRef}
                style={{
                  width: "100vw",
                  maxWidth: "80rem",
                  height: "25.2rem",
                  borderBottom: `1rem solid ${color.gray.d50}`,
                }}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default DetailInfo;
