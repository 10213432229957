import React, { useEffect } from "react";
import { styled } from "styled-components";
import { color, font } from "../styles/theme";
import Text from "../atom/Text";
import { headerFooterZIndex } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 3.2rem);
  position: fixed;
  bottom: ${(p) => p.$bottom ?? "3.2rem"};
  left: 50%;
  transform: translate(-50%);
  background-color: ${(p) => p.$backgroundColor ?? color.gray.d900};
  padding: 1rem 1.2rem;
  z-index: ${headerFooterZIndex + 1};
  border-radius: 1.2rem;
  gap: 1rem;
  ${(p) => p.$styles};
`;

const Toast = ({
  bottom,
  backgroundColor,
  text,
  icon,
  fontColor,
  setShow,
  duration = 1500,
  containerStyles,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (setShow) {
        setShow(false);
      }
      navigate(location.pathname, { replace: true });
    }, duration);
    // eslint-disable-next-line
  }, []);
  return (
    <Container
      $backgroundColor={backgroundColor}
      $bottom={bottom}
      $styles={containerStyles}
    >
      {icon && icon}
      <Text fontStyle={font.subtitle3} color={fontColor ?? color.primary.white}>
        {text}
      </Text>
    </Container>
  );
};

export default Toast;
