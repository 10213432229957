import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState, useRef } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { AlertError, EmailCheck, getApiUrl, PrintConsole } from '../../../function/common';
import { Container, Row, Col, Form, Image, Button, Table, Tabs } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
import { getImgUrl } from './../../../function/common';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { Tab } from 'react-bootstrap';
import { setAccessToken } from './../../../api/ApiWebView';

export default function ApproveProcess(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});

    const [businessType, setBusinessType] = useState({
        businessType        : "SOLE"
    })
    const onChangeBusinessType = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setBusinessType({
          ...businessType,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    const fileUpload = useRef();
    const [file, setFile] = useState([]);

    const onClickUpload = () => {
        fileUpload.current.click();
    }

    const onChangeImg = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile(uploadFile);

            document.getElementById('PreView').style.display='block';
            document.getElementById('PreViewImg').src = URL.createObjectURL(e.target.files[0]);
        }
    }

    const fileUpload2 = useRef();
    const [file2, setFile2] = useState([]);

    const onClickUpload2 = () => {
        fileUpload2.current.click();
    }

    const onChangeImg2 = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile2(uploadFile);

            document.getElementById('PreView2').style.display='block';
            document.getElementById('PreViewImg2').src = URL.createObjectURL(e.target.files[0]);
        }
    }

    function getStore() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('stores/'+localStorage.getItem('storeDomain') ),
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
            ).then(
                function(response){
                    setData(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    const [store, setStore] = useState();
    function getAccount() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/settle-account' ),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
            ).then(
                function(response){
                    setStore(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };


    const [accept, setAccept] = useState({});
    function getAccept() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                    getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/accept',
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
                )
            ).then(
                function(response){
                    setAccept(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    const [bank, setBank] = useState();
    function getBank() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('common/code?name=BANK_CODE') ).then(
                function(response){
                    setBank(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
            // reject(new Error("Request is failed"));
        });
    };

    async function submit(){  

        document.getElementById('submitBtn').setAttribute("disabled","true");

        

        if( file.length === 0 ){
            alert('사업자 등록증을 첨부해주세요.');
            document.getElementById('submitBtn').removeAttribute("disabled");
            return false;
        }

        if(document.getElementById('businessName').value === '' || document.getElementById('businessName').value === null){
            alert('상호명을 입력해 주세요.');
            document.getElementById('submitBtn').removeAttribute("disabled");
            return false;
        }

        // if(document.getElementById('businessNumber').value === '' || document.getElementById('businessNumber').value === null){
        //     alert('사업자 등록번호를 입력해 주세요.');
        //     document.getElementById('submitBtn').removeAttribute("disabled");
        //     return false;
        // }

        // if(document.getElementById('accountNumber').value === '' || document.getElementById('accountNumber').value === null){
        //     alert('계좌번호를 입력해 주세요.');
        //     document.getElementById('submitBtn').removeAttribute("disabled");
        //     return false;
        // }

        var formData = new FormData();
        formData.append('registration', file);

        axios.put(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/business-registration'), 
            formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization' : 'Bearer ' + localStorage.getItem('accessToken')
                },
            }
        ).then(
            function(response){
                if(response.status === 200){
                    // 사업자 등록증 사본 올리기 성공
                    let obj = {
                        "businessNumber": document.getElementById('businessNumber').value,
                        "businessName": document.getElementById('businessName').value,
                        "businessType": businessType.businessType
                    }

                    axios.put(
                        getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/settlement'), 
                        obj, 
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
                    ).then(

                        function(response){
                            if(response.status === 200){                        
                                axios.post(
                                    getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/accept'), 
                                    obj, 
                                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
                                ).then(
                                    function(response){
                                        if(response.status === 200){
                                            alert('심사 요청이 완료되었습니다.');

                                            document.getElementById('submitBtn').removeAttribute("disabled");            
                                            // window.location.reload();
                                            getAccept();
                                        }
                                    }
                                ).catch(function(error){
                                    AlertError(error);
                                    // navigate('/404');
                                    document.getElementById('submitBtn').removeAttribute("disabled");
                                })
                            }
                        }
                    ).catch(function(error){
                        console.log(error);
                        AlertError(error);
                        // navigate('/404');
                        document.getElementById('submitBtn').removeAttribute("disabled");
                    })
                }
            }
        ).catch(function(error){
            console.log(error);
            AlertError(error);
            // navigate('/404');
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    async function save(){  

        document.getElementById('saveBtn').setAttribute("disabled","true");

        let e = document.getElementById("bank");

        let obj = {
            "businessNumber": document.getElementById('businessNumber').value,
            "businessType": businessType.businessType,
            "businessName": document.getElementById('businessName').value,
            "settleAccount": {
                "bank": e.options[e.selectedIndex].value,
                "accountNumber": document.getElementById('accountNumber').value,
                "accountOwner": document.getElementById('accountOwner').value
            },
            "taxEmail": document.getElementById('taxEmail').value
        }

        //쿠키로 변경

        // axios.put(
        //     getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/temp-settlement'), 
        //     obj, 
        //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        // ).then(
        //     function(response){
        //         if(response.status === 200){
        //             alert('임시 저장이 완료되었습니다.');
        //             document.getElementById('saveBtn').removeAttribute("disabled");
        //         }
        //     }
        // ).catch(function(error){
        //     let detailMessage;
        //     let errorDetails = error.response.data.errorDetails;
        //     if(errorDetails && errorDetails.length > 0){
        //         detailMessage = errorDetails[0].reason;
        //     }
        //     alert(error.response.data.message + "\n" + detailMessage);
        //     // navigate('/404');
        //     document.getElementById('saveBtn').removeAttribute("disabled");
        // });
    }

    // const onBusinessNumber = (e) => {
    //     let obj = { 
    //         businessNumber : data.businessNumber,
    //     }
    //     axios.post(
    //         getApiUrl('store/business-number/duplicate'),
    //         obj, 
    //         axios.defaults.headers.common['ContentType'] = "application/json;",
    //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
    //     ).then(
    //         function(response){
    //             if(response.status === 200){
    //                 if(response.data.businessNumberExist === true){
    //                     e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
    //                     e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
    //                     //강제 포커싱
    //                     e.target.parentElement.querySelector('input').focus();
    //                     e.target.parentElement.querySelector('div').textContent = '중복된 사업자등록번호 입니다.'
    //                 }else{
    //                     e.target.parentElement.querySelector('div').classList.remove('invalid-feedback');
    //                     e.target.parentElement.querySelector('div').classList.add('valid-feedback');
    //                 }
                    
    //             }
    //         }
    //     ).catch(function(error){
    //         e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
    //         e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
    //         e.target.parentElement.querySelector('div').textContent = error.response.data.errorDetails[0].reason;
    //     })
    // }

    async function getAuthority(){
        //권한
        axios.get(
            getApiUrl('users/me/stores/'+localStorage.getItem('storeDomain')), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    if(response.data.type['code'] !== 1){
                        //직원은 사용못함
                        alert('권한이 없습니다.');
                        navigate(-1);
                    }
                }
            }
        ).catch(function(error){
            AlertError(error);
            navigate(-1);
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        //권한
        getAuthority();
        
        //함수 호출
        getStore();
        getAccept();
        getBank();
        getAccount();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>
    console.log(data);
    console.log(store);
    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="사업장 승인"/>
                
                <Tabs defaultActiveKey="main" id="justify-tab" className="mt-5 mb-3" justify>

                    <Tab eventKey="main" title="승인 신청">
                        <Row className='p-1'>
                            <Col xs={{ span:5, offset:1 }}>
                                <Form.Group className="xs-12" controlId="logo">
                                    <Form.Label>사업자 등록증 <span className='PointText'>*</span></Form.Label>
                                    <Col>
                                        <input type="file" style={{display : 'none'}} ref={fileUpload} onChange={onChangeImg}  />
                                        {/* <button onClick={onClickUpload}>IMG 변경</button> */}
                                        <Image className='w-100 DefaultShadow' fluid src={getImgUrl('images/icon/button_upload_add.png')} onClick={onClickUpload}/>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={{span:5}} id='PreView' style={{display:'none'}}>
                                    <Form.Group className="xs-12" controlId="logo">
                                        <Form.Label>업로드 프리뷰</Form.Label>
                                        <Col>
                                            <Image className='w-100 DefaultShadow' id='PreViewImg' fluid src='' />
                                        </Col>
                                    </Form.Group>
                                </Col>
                        </Row>

                        <Row className='p-1'>
                            <Col xs={{ span:10, offset:1 }}>
                                <Form.Group className="xs-12" controlId="businessName">
                                    <Form.Label>상호명 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="사업자등록증상 상호명을 입력해 주세요." value={data.businessName || ''} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='p-1'>
                            <Col xs={{ span:10, offset:1 }}>
                                <Form.Group className="xs-12" controlId="businessNumber">
                                    <Form.Label>사업자등록번호 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="number" placeholder="- 없이 숫자만 입력" value={data.businessNumber || ''} onChange={onChangeData} onKeyDown={(e)=> e.key === 'e' && e.preventDefault()}/>
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='p-1'>
                            <Col xs={{span:10, offset:1}} className='mt-3'>
                                <label className='CustomLabel'>사업자 유형 <span className='PointText'>*</span></label>
                            </Col>
                            <Col xs={{ span:5, offset:1 }} key='SOLE'>
                                <Form.Check type='radio' label='개인사업자' id='businessType' value='SOLE' checked={businessType['businessType'] === 'SOLE'} onChange={onChangeBusinessType}/>
                            </Col>
                            <Col xs={{ span:5, offset:1 }} key='CORPORATION'>
                                <Form.Check type='radio' label='법인사업자' id='businessType' value='CORPORATION' checked={businessType['businessType'] === 'CORPORATION'} onChange={onChangeBusinessType}/>
                            </Col>
                        </Row>

                        {/* <Row className='p-1'>
                            <Col className='mt-3' xs={{span:10, offset:1}}>
                                <Form.Group controlId="bank">
                                    <Form.Select size="lg" onChange={onChangeData} key={store && store.bank['code']} defaultValue={store && store.bank['code']}>
                                    {
                                        bank && bank.map( row => (
                                            <option key={row.code} value={row.code}>{row.korName}</option>
                                        ) )
                                    }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row> */}

                        {/* <Row className='p-1'>
                            <Col className='mt-3' xs={{ span:10, offset:1 }}>
                                <Form.Group className="xs-12" controlId="accountOwner">
                                    <Form.Label>예금주명 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" defaultValue={store && store.accountOwner || ''} placeholder="예금주" onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                        </Row> */}

                        {/* <Row className='p-1'>
                            <Col className='mt-3' xs={{span:10, offset:1}}>
                                <Form.Group className="xs-12" controlId="accountNumber">
                                    <Form.Label>계좌번호 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="number" defaultValue={store && store.accountNumber || ''} placeholder="계좌번호 입력" onChange={onChangeData} onKeyDown={(e)=> e.key === 'e' && e.preventDefault()}/>
                                </Form.Group>
                            </Col>
                        </Row> */}
{/* 
                        <Row className='p-1'>
                            <Col xs={{ span:5, offset:1 }} className='mt-3'>
                                <Form.Group className="xs-12" controlId="logo">
                                    <Form.Label>계좌번호 사본 <span className='PointText'>*</span></Form.Label>
                                    <Col>
                                        <input type="file" style={{display : 'none'}} ref={fileUpload2} onChange={onChangeImg2}  />
                                        <Image className='w-100 DefaultShadow' fluid src={getImgUrl('images/icon/button_upload_add.png')} onClick={onClickUpload2}/>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={{span:5}} id='PreView2' style={{display:'none'}}>
                                <Form.Group className="xs-12" controlId="logo">
                                    <Form.Label>업로드 프리뷰</Form.Label>
                                    <Col>
                                        <Image className='w-100 DefaultShadow' id='PreViewImg2' fluid src='' />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row> */}

                        <Row className='p-1 mt-3'>
                            <Col xs={{ span:10, offset:1 }}>
                                <div className="d-grid gap-1 mt-2 mb-3">
                                    <Button variant="primary" size="lg" id='submitBtn' onClick={ submit }>
                                        자료 제출
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="history" title="승인 내역">

                        <Row className='mt-3 mb-3'>
                            <Col xs={{ span:10, offset:1 }}>
                                <Form.Group className="xs-12">
                                    <Form.Label>승인 내역</Form.Label>
                                    <Table responsive className='text-center'>
                                        <thead>
                                            <tr>
                                                <td>승인 상태</td>
                                                <td>관리자 전달 사항</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                accept && accept.length > 0 ?
                                                accept && accept.map( row => (
                                                    <tr key={row.createdAt+row.status['description']}>
                                                        <td className='text-center'>
                                                            {row.status['description']}<br/>
                                                            <small>({row.createdAt})</small>
                                                        </td>
                                                        <td>{row.admin_memo}</td>
                                                    </tr>
                                                )
                                            )
                                                :
                                                    <tr>
                                                        <td className='text-center' colSpan={2}>신청 내역이 없습니다.</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Form.Group>
                            </Col>
                        </Row>

                    </Tab>

                </Tabs>
            </Container>
        </>
    )
}