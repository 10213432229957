import React from "react";
import Radio from "../../../../../../components/ver1.5/molecule/Radio";
import { styled } from "styled-components";
import Button from "../../../../../../components/ver1.5/atom/Button";
import { color, font } from "../../../../../../components/ver1.5/styles/theme";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AgreementRadio = ({
  label,
  value,
  checked,
  onClick,
  termComponent,
  labelFontStyle,
}) => {
  return (
    <Container>
      <Radio
        label={label}
        value={value}
        checked={checked}
        onClick={() => (onClick ? onClick() : null)}
        type="checkbox"
        labelFontStyle={labelFontStyle}
      />
      {termComponent}
    </Container>
  );
};

export default AgreementRadio;
