import { Outlet, matchPath, useLocation, useNavigate } from "react-router-dom";
import Main from "../pages/ver1.5/Main";
import RootLayout from "../components/ver1.5/layout/RootLayout";
import BottomNavigation from "../components/ver1.5/BottomNavigation";
import Header from "../components/ver1.5/Header";
import History from "../pages/ver1.5/History";
import My from "../pages/ver1.5/My";
import StoreDetail from "../pages/ver1.5/Store/Detail";
import Search from "../pages/ver1.5/Store/Search";
import Gift from "../pages/ver1.5/Gift";
import GiftOutlet from "./renew/GiftOutlet";
import PgSuccess from "../pages/ver1.5/Pg/Success";
import HistoryDetail from "../pages/ver1.5/History/HistoryDetail";
import Setting from "../pages/ver1.5/My/pages/Setting";
import Alarm from "../pages/ver1.5/My/pages/Alarm";
import EditProfile from "../pages/ver1.5/My/pages/EditProfile";
import MyOutlet from "./renew/MyOutlet";
import EditEmail from "../pages/ver1.5/My/pages/EditEmail";
import EditPhone from "../pages/ver1.5/My/pages/EditPhone";
import ResetPw from "../pages/ver1.5/My/pages/ResetPw";
import Login from "../pages/ver1.5/Login";
import Withdrawal from "../pages/ver1.5/My/pages/Withdrawal";
import Faq from "../pages/ver1.5/Common/Faq";
import Notice from "../pages/ver1.5/Common/Notice";
import Join from "../pages/ver1.5/Login/Join";
import Certification from "../pages/ver1.5/Login/Certification";
import ReviewReport from "../pages/ver1.5/Store/ReviewReport";
import UserReview from "../pages/ver1.5/Store/Review";
import UnderReview from "../pages/ver1.5/Common/UnderReview";
import Fail from "../pages/ver1.5/Pg/Fail";
import SignupDone from "../pages/ver1.5/Login/Join/SignupDone";
import FindAccount from "../pages/ver1.5/Login/FindAccount";
import ReportEnd from "../pages/ver1.5/Store/ReviewReport/ReportEnd";
import { useEffect, useState } from "react";

const RenewOutlet = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [currentStore, setCurrentStore] = useState({
    storeName: "",
    storeAddress: "",
  });
  let patternRoutes = [];
  for (let route of RenewRoutes.children.filter((route) => !route.children)) {
    patternRoutes.push("/user/" + route.path);
  }

  for (let routes of RenewRoutes.children.filter((route) => route.children)) {
    for (let child of routes.children) {
      if (child.path === "") {
        patternRoutes.push("/user/" + routes.path);
      } else {
        patternRoutes.push("/user/" + routes.path + "/" + child.path);
      }
    }
  }

  const pathPattern = patternRoutes.find((route) => {
    if (pathname === "/") {
      return route === "/user/";
    } else {
      if (
        pathname.includes("login") ||
        pathname.includes("report") ||
        pathname.includes("search") ||
        pathname.includes("join") ||
        pathname.includes("underreview") ||
        pathname.includes("storereview") ||
        pathname.includes("my") ||
        pathname.includes("notice") ||
        pathname.includes("faq") ||
        pathname.includes("find") ||
        pathname.includes("reset") ||
        pathname.includes("certification") ||
        pathname.includes("signupEnd")
        // route === pathname.replace("/user/", "")
      ) {
        return route === pathname;
      } else {
        return matchPath(route, pathname);
      }
    }
  });

  const currentPath =
    pathPattern.replace("/user", "") === ""
      ? ""
      : pathPattern.replace("/user/", "");
  let currentElement = {};
  const firstChild = RenewRoutes.children
    .filter((child) => !child.children)
    .find((route) => route.path === currentPath);
  if (firstChild) {
    currentElement = firstChild;
  } else {
    let secondChildList = [];
    for (let route of RenewRoutes.children.filter((child) => child.children)) {
      for (let child of route.children) {
        if (child.path === "") {
          secondChildList.push({ ...child, path: route.path });
        } else {
          secondChildList.push({
            ...child,
            path: route.path + "/" + child.path,
          });
        }
      }
    }
    const secondChild = secondChildList.find(
      (route) => route.path === currentPath
    );
    if (secondChild) {
      currentElement = secondChild;
    }
  }

  const headerType = currentElement?.options?.headerType ?? "default";
  const headerTitle = currentElement?.title;
  const footerShown = currentElement?.options?.footerShown ?? true;
  const headerShown = currentElement?.options?.headerShown ?? true;
  const customOnClick = currentElement?.options?.onClickIcon
    ? () => currentElement?.options?.onClickIcon(navigate)
    : null;

  const initKakao = () => {
    if (window.Kakao?.isInitialized()) return;
    window.Kakao?.init(process.env.REACT_APP_KAKAO_API_KEY);
  };

  useEffect(() => {
    initKakao();
  }, []);

  return (
    <RootLayout>
      {headerShown && (
        <Header
          type={headerType}
          title={headerTitle}
          currentStore={currentStore}
          setCurrentStore={setCurrentStore}
          customOnClick={customOnClick}
        />
      )}
      <Outlet
        context={{
          headerShown,
          footerShown,
          setCurrentStore,
        }}
      />
      {footerShown && <BottomNavigation />}
    </RootLayout>
  );
};

const RenewRoutes = {
  path: "",
  element: <RenewOutlet />,
  children: [
    {
      path: "",
      element: <Main />,
      title: "FLEXDAY 선물하기",
      options: {
        headerType: "main",
      },
    },
    {
      path: "history",
      element: <History />,
      title: "구매내역",
      options: {
        headerType: "default",
      },
    },
    {
      path: "history/detail/:orderId",
      element: <HistoryDetail />,
      title: "구매내역 상세보기",
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
    {
      path: "my",
      element: <MyOutlet />,
      children: [
        {
          path: "",
          element: <My />,
          title: "마이페이지",
          options: {
            headerType: "my",
          },
        },
        {
          path: "setting",
          element: <Setting />,
          title: "설정",
          options: {
            headerType: "back",
            footerShown: false,
          },
        },
        {
          path: "alarm",
          element: <Alarm />,
          title: "혜택/이벤트",
          options: {
            headerType: "back",
            footerShown: false,
          },
        },
        {
          path: "editProfile",
          element: <EditProfile />,
          title: "내 정보 관리",
          options: {
            headerType: "back",
            footerShown: false,
          },
        },
        {
          path: "editEmail",
          element: <EditEmail />,
          title: "이메일",
          options: {
            headerType: "back",
            footerShown: false,
          },
        },
        {
          path: "editPhone",
          element: <EditPhone />,
          title: "전화번호",
          options: {
            headerType: "back",
            footerShown: false,
          },
        },
        {
          path: "resetPw",
          element: <ResetPw />,
          title: "비밀번호 변경",
          options: {
            headerType: "back",
            footerShown: false,
          },
        },
        {
          path: "withdrawal",
          element: <Withdrawal />,
          title: "회원탈퇴",
          options: {
            headerType: "back",
            footerShown: false,
          },
        },
      ],
    },
    {
      path: ":storeDomain",
      element: <StoreDetail />,
      title: "매장 상세",
      options: {
        footerShown: false,
        // headerShown: false,
        headerType: "detail",
      },
    },
    {
      path: "login",
      element: <Login />,
      title: "로그인",
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
    {
      path: "join",
      element: <Join />,
      title: "회원가입",
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
    {
      path: "certification",
      element: <Certification />,
      title: "회원가입",
      options: {
        footerShown: false,
        headerType: "back",
      },
    },
    {
      path: "signupEnd",
      element: <SignupDone />,
      options: {
        headerShown: false,
        footerShown: false,
      },
    },
    {
      path: "search",
      element: <Search />,
      options: {
        headerType: "search",
        footerShown: false,
      },
    },
    {
      path: "report",
      title: "리뷰 신고하기",
      element: <ReviewReport />,
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
    {
      path: "reportEnd",
      element: <ReportEnd />,
      options: {
        headerShown: false,
        footerShown: false,
      },
    },
    {
      path: "gift",
      element: <GiftOutlet />,
      children: [
        {
          path: ":storeDomain/:productId",
          element: <Gift />,
          title: "결제",
          options: {
            footerShown: false,
            headerType: "back",
          },
        },
      ],
    },
    {
      path: "storereview",
      element: <UserReview />,
      title: "리뷰 작성",
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
    {
      path: "storereview/edit",
      element: <UserReview />,
      title: "리뷰 수정",
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
    {
      path: "underreview",
      element: <UnderReview />,
      options: {
        headerShown: false,
        footerShown: false,
      },
    },
    {
      path: "pg",
      children: [
        {
          path: "success",
          element: <PgSuccess />,
          title: "결제 완료",
          options: {
            headerType: "closable",
            footerShown: false,
            onClickIcon: (navigate) => navigate("/user", { replace: true }),
          },
        },
        {
          path: "fail",
          element: <Fail />,
          options: {
            headerType: "closable",
            footerShown: false,
            onClickIcon: (navigate) => navigate("/user", { replace: true }),
          },
        },
      ],
    },
    {
      path: "faq",
      element: <Faq />,
      title: "자주 묻는 질문",
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
    {
      path: "notice",
      element: <Notice />,
      title: "공지사항",
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
    {
      path: "findAccount",
      element: <FindAccount />,
      options: {
        headerType: "back",
        footerShown: false,
      },
    },
  ],
};

export default RenewRoutes;
