import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AlertError,
  PrintConsole,
  getApiUrl,
} from "../../../../function/common";
import axios from "axios";
import Text from "../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { presetHeight } from "../../../../components/ver1.5/constants";
import Radio from "../../../../components/ver1.5/molecule/Radio";
import LabelledTextField from "../../../../components/ver1.5/organism/LabelledTextField";
import Button from "../../../../components/ver1.5/atom/Button";
import { UserContext } from "../../../../contexts/UserContext";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const reportTypes = [
  {
    label: "광고/홍보성",
    value: "ADVERTISEMENT",
  },
  {
    label: "욕설/인신공격/음란성",
    value: "INAPPROPRIATE",
  },
  {
    label: "명예훼손/개인정보 침해",
    value: "PRIVACY",
  },
  {
    label: "도배/분란 유도",
    value: "ORDER_UNRELATED",
  },
  {
    label: "선물과 관련 없는 내용",
    value: "GIFT_UNRELATED",
  },
  {
    label: "기타",
    value: "ETC",
  },
];

const ReviewReport = () => {
  const { accessToken } = useContext(UserContext);

  const navigate = useNavigate();
  const [reportType, setReportType] = useState(null);
  const [content, setContent] = useState("");
  const [searchParams] = useSearchParams();
  const domain = searchParams.get("domain");
  const reviewId = searchParams.get("id");

  const submit = async () => {
    let obj = {
      domain,
      reviewId,
      reason: content,
      reviewReportType: reportType,
    };
    PrintConsole(obj);
    await axios
      .post(
        getApiUrl("stores/" + domain + "/reviews/" + reviewId + "/reports"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;"),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken)
      )
      .then(function (response) {
        PrintConsole(response);
        if (response.status === 200) {
          //페이지 이동
          navigate("/user/reportEnd", { replace: true });
        }
      })
      .catch(function (error) {
        PrintConsole(error);
        AlertError(error);
        navigate(-1, { replace: true });
      });

    document.getElementById("submitBtn").removeAttribute("disabled");
  };
  return (
    <Layout
      footerPadding={false}
      styles={css`
        padding: 2.4rem;
        padding-top: ${`calc(${presetHeight.header} + 2.4rem)`};
      `}
    >
      <ContentContainer>
        <TitleWrapper>
          <Text fontStyle={font.h2} color={color.gray.d800}>
            신고 사유를 선택해주세요
          </Text>
          <Text fontStyle={font.caption3} color={color.gray.d600}>
            허위 신고의 경우, 신고자의 서비스 이용이 제한될 수 있습니다
          </Text>
        </TitleWrapper>
        <RadioContainer>
          {reportTypes.map((type) => (
            <Radio
              key={type.value}
              label={type.label}
              checked={reportType === type.value}
              onClick={() => setReportType(type.value)}
            />
          ))}
        </RadioContainer>
        <LabelledTextField
          label={"신고 내용"}
          elementType={"textarea"}
          placeholder={"신고 내용을 입력해주세요"}
          rows={10}
          type="filled"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <BtnWrapper>
          <Button
            text="취소"
            variant="outline"
            customButtonStyle={css`
              border-color: ${color.gray.d100};
              div {
                color: ${color.gray.d600};
              }
            `}
            onClick={() => navigate(-1, { replace: true })}
          />
          <Button
            text="신고하기"
            disabled={!(reportType && content)}
            onClick={submit}
          />
        </BtnWrapper>
      </ContentContainer>
    </Layout>
  );
};

export default ReviewReport;
