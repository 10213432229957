import React from "react";
import { Container, Image, Row } from "react-bootstrap";
import Btn from "../../../components/common/Btn";

const imageBaseUrl =
  "https://www.flexdaycdn.net/public/images/static/store-main";

const steps = [
  {
    stepDesc: "상품의 대표사진을 등록해주세요.",
    descriptions: [
      {
        image: imageBaseUrl + "/guide_step1.png",
        description:
          "사각형 도형의 +버튼을 눌러 원하시는 사진을 등록해주세요.\n+버튼 터치 후 연동되는 카메라로 직접 촬영하시거나,\n사진첩에 있는 사진으로 등록해주셔도 됩니다.",
      },
      {
        image: imageBaseUrl + "/guide_step1-1.png",
        description:
          "등록된 사진은 교환권 리스트에서\n위 예시 이미지처럼 보여지게 됩니다.",
      },
    ],
  },
  {
    stepDesc: "상품의 이름을 입력해주세요.",
    descriptions: [
      {
        image: imageBaseUrl + "/guide_step2.png",
        description:
          "상품 이름 입력란을 누르신 후,\n원하시는 상품명을 입력해주세요.",
      },
      {
        image: imageBaseUrl + "/guide_step2-1.png",
        description:
          "등록된 상품명은 교환권 리스트에서\n위 예시 이미지처럼 보여지게 됩니다.",
      },
    ],
  },
  {
    stepDesc: "상품의 가격을 입력해주세요.",
    descriptions: [
      {
        image: imageBaseUrl + "/guide_step3.png",
        description:
          "상품 가격 입력란에 가격을 입력해주세요.\n상품의 가격은 최소 1,000원부터\n최대 950,000원까지 입력가능합니다.",
      },
      {
        image: imageBaseUrl + "/guide_step3-1.png",
        description:
          "등록된 상품가격은 교환권 리스트에서\n위 예시 이미지처럼 보여지게 됩니다.",
      },
    ],
  },
  {
    stepDesc: "상품의 설명을 입력해주세요",
    descriptions: [
      {
        image: imageBaseUrl + "/guide_step4.png",
        description:
          "상품 설명 입력란을 누르신 후,\n상품에 대한 설명을 입력해주세요.",
      },
      {
        image: imageBaseUrl + "/guide_step4-1.png",
        description:
          "등록된 상품설명은 교환권 리스트에서\n위 예시 이미지처럼 보여지게 됩니다.",
      },
      {
        image: imageBaseUrl + "/guide_step4-2.png",
        description:
          "원산지 입력이 필요한 업종의 사장님이시라면,\n원산지 입력란의 화살표 버튼을 눌러\n원산지 표기 항목을 입력해주세요.",
      },
    ],
  },
  {
    stepDesc: "아이콘을 추가해주세요.",
    descriptions: [
      {
        image: imageBaseUrl + "/guide_step5.png",
        description:
          "BEST, NEW, 추천상품 아이콘을 선택하시면\n상품 리스트에서 해당 상품을 주력으로 내세울 수 있습니다.",
      },
      {
        image: imageBaseUrl + "/guide_step5-1.png",
        description:
          "새로고침 버튼을 누르시면\n선택했던 항목을 초기화할 수 있습니다.",
      },
      {
        image: imageBaseUrl + "/guide_step5-2.png",
        description: "선택하신 아이콘에 따라,\n해당 상품명 상단에 표기됩니다.",
      },
    ],
  },
  {
    stepDesc: "완료 버튼을 눌러주세요.",
    descriptions: [
      {
        image: imageBaseUrl + "/guide_step6.png",
        description:
          '필수 항목을 모두 입력하셨다면,\n맨 아래에 있는 "완료" 버튼을 눌러주세요.',
      },
      {
        image: imageBaseUrl + "/guide_step6-1.png",
        description:
          "완료 버튼을 누르신 후, 최종완료되면\n위 예시 이미지처럼 입력해주신\n모든 항목이 표기됩니다.",
      },
    ],
  },
];

const StepBox = ({ step, stepDesc, descriptions }) => {
  return (
    <div className="AddGuide-StepBox">
      <div className="AddGuide-Step">
        <p className="Step-tit mb-2">STEP {step}.</p>
        <p className="Step-sub mb-5">{stepDesc}</p>
        {descriptions.map(({ image, description }, index) => {
          return (
            <div className="Step-descbox" key={index}>
              <Image
                className="Step-img img-fluid mb-4"
                alt={`가이드${step}`}
                src={image}
              />
              {description.split("\n").map((desc, index) => {
                if (index !== description.split("\n").length - 1) {
                  return (
                    <p className="Step-desc text-center mb-0" key={index}>
                      {desc}
                    </p>
                  );
                } else {
                  return (
                    <p className="Step-desc text-center" key={index}>
                      {desc}
                    </p>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ItemCreateGuide = () => {
  return (
    <div className="StoreMainPage">
      <Btn type="TITLE" titleCanClose title="교환권 등록 가이드" />
      <Container className="AddPrdBox py-4-5 px-0">
        <Row className="AddGuide-Titlebox mb-5">
          <p className="tit d-flex align-items-center">
            교환권 등록 가이드
            <Image
              className="img-fluid"
              src="https://www.flexdaycdn.net/public/images/static/store-main/guide_book.png"
            />
          </p>
          <p className="sub">
            교환권 등록 절차가 어려우신 분들을 위해
            <br />
            등록 가이드를 제공합니다.
          </p>
        </Row>
        {steps.map((step, index) => {
          return (
            <StepBox
              key={index}
              step={index + 1}
              stepDesc={step.stepDesc}
              descriptions={step.descriptions}
            />
          );
        })}
      </Container>
    </div>
  );
};

export default ItemCreateGuide;
