import { useNavigate, useSearchParams } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import Btn from './../../components/common/Btn';

export default function JoinStaff(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    
    async function seacrh(){

        document.getElementById('submitBtn').setAttribute("disabled","true");

        let obj = { 
            'addressDTO': {
                'zipCode': document.getElementById('zipCode').value,
                'address': document.getElementById('address').value,
                'addressDetail': document.getElementById('addressDetail').value
            },
            'storePhoneNumber': document.getElementById('storePhoneNumber').value,
            'storeName': document.getElementById('storeName').value,
            'businessHours': document.getElementById('businessHours').value,
            'holidays': document.getElementById('holidays').value
        }

        axios.put(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/store-info'), 
            obj, 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    alert('수정이 완료하였습니다.');
                    navigate('/store/'+localStorage.getItem('storeDomain'));
                }
            }
        ).catch(function(error){
            AlertError(error);
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    async function invaitations(){

        document.getElementById('submitBtn').setAttribute("disabled","true");

        let obj = { 
            'addressDTO': {
                'zipCode': document.getElementById('zipCode').value,
                'address': document.getElementById('address').value,
                'addressDetail': document.getElementById('addressDetail').value
            },
            'storePhoneNumber': document.getElementById('storePhoneNumber').value,
            'storeName': document.getElementById('storeName').value,
            'businessHours': document.getElementById('businessHours').value,
            'holidays': document.getElementById('holidays').value
        }

        axios.put(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/store-info'), 
            obj, 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    alert('수정이 완료하였습니다.');
                    navigate('/store/'+localStorage.getItem('storeDomain'));
                }
            }
        ).catch(function(error){
            AlertError(error);
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="직원 추가" />
                <Row className="MainSpace">
                    <Col xs={{ span:10, offset:1 }}>
                        <h5>아이디 검색으로</h5>
                        <h5>직원을 등록해 주세요.</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span:8, offset:1 }}>
                        <Form.Group className="xs-10 mt-3" controlId="loginId">
                            <Form.Label>직원 아이디를 조회 후 등록해 주세요. <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text"  placeholder="아이디를 입력하세요." onChange={onChangeData} />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={{ span:2 }}>
                        <Form.Group className='mt-3'>
                            <Form.Label>&nbsp;</Form.Label>
                            <Button variant="primary" id='submitBtn' onClick={ seacrh }>
                                검색
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col xs={{span:10, offset:1}}>
                        <label><small>검색결과 : {0} 건</small></label>
                        <hr/>
                        <Table responsive>
                            <tbody className='AlignCenter'>
                                <tr>
                                    <td>test</td>
                                    <td>곽**</td>
                                    <td>010-7140-****</td>
                                    <td>
                                        <Button variant="primary" id='submitBtn' onClick={ invaitations() }>
                                            직원초대
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        
                    </Col>
                </Row>
            </Container>
        </>
    )
}