import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { getApiUrl, PrintConsole } from '../../../function/common';
import { Container, Row, Col, Table } from 'react-bootstrap';
import LeftMenu from '../../../components/admin/LeftMenu';

export default function BoardList(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    const del = async (e) => {
        let obj = { 
            'name' : ''
        }
        await axios.delete(
            getApiUrl('api'),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;",
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                console.log(response);
                if(response.status === 200){
                    //200
                }else{
                    //error
                }
            }
        ).catch(function(error){
            PrintConsole(error);
        })
    }

    const post = (e) => {
        let obj = { 
            loginId : data.loginId,
        }
        axios.post(
            getApiUrl('api'),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    
                }else{
                    //error
                }
            }
        ).catch(function(error){
            PrintConsole(error);
        })
    }

    const put = (e) => {
        let obj = {
            'bank' : ''
        }
        axios.put(
            getApiUrl('api'), 
            obj, 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    
                }else{
                    //error
                }
            }
        ).catch(function(error){
            PrintConsole(error);
        });
    }

    function get() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('api')
            ).then(
                function(response){
                    if(response.status === 200){
                    
                    }else{
                        //error
                    }
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs ={2}>
                        <LeftMenu />
                    </Col>
                    <Col>
                        <Table response>
                            <thead>
                                <tr>
                                    <td>
                                        제목
                                    </td>
                                    <td>
                                        카테고리1
                                    </td>
                                    <td>
                                        카테고리2
                                    </td>
                                    <td>
                                        카테고리3
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {/* data 값으로 루프 */}
                                <tr onClick={ () => { navigate('/admin/boarddetail/'+'1') }}>
                                    <td>
                                        내용1
                                    </td>
                                    <td>
                                        내용2
                                    </td>
                                    <td>
                                        내용3
                                    </td>
                                    <td>
                                        내용4
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}