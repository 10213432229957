import React, { useContext, useState } from "react";
import { styled } from "styled-components";
import StarIcons from "../atom/StarIcons";
import Text from "../../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../../components/ver1.5/styles/theme";
import Divider from "../../../../../../components/ver1.5/atom/Divider";
import {
  DefaultProfile,
  MoreIcon,
} from "../../../../../../components/ver1.5/assets/icons";
import BottomSheet from "../../../../../../components/ver1.5/organism/BottomSheet";
import { UserContext } from "../../../../../../contexts/UserContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.2rem 1.6rem;
  gap: 0.8rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  border-radius: 1.2rem;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 22.3rem;
`;

const ProfileCircle = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1.6rem;
  overflow: hidden;
  object-fit: cover;
  border: 1px solid ${color.gray.d100};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BottomSheetItemContainer = styled.div`
  display: flex;
  height: 6.4rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
`;

const ReviewListItem = ({
  info = {
    contents: "",
    grade: "",
    images: [],
    reviewerName: "",
    createdAt: "-",
    profile: "",
  },
  isLast,
  onClickReport,
  onClick,
  onClickEdit,
  onClickDelete,
}) => {
  const [moreBottomSheet, setMoreBottomSheet] = useState(false);
  const { accessToken } = useContext(UserContext);
  const loggedIn = Boolean(accessToken);

  const bottomSheetItem = [
    {
      name: "수정",
      value: "edit",
      callback: (onClose) => {
        onClose();
        onClickEdit();
      },
    },
    {
      name: "삭제",
      value: "delete",
      callback: async (onClose) => {
        await onClickDelete();
        onClose();
      },
    },
    {
      name: "신고하기",
      value: "report",
      callback: (onClose) => {
        onClose();
        onClickReport();
      },
    },
  ];

  return (
    <>
      <Container onClick={() => (onClick ? onClick() : null)}>
        <TitleWrapper>
          {info.profile ? (
            <ProfileCircle src={info.profile} />
          ) : (
            <DefaultProfile size={"3.2rem"} />
          )}
          <Text
            fontStyle={font.body2}
            color={color.gray.d900}
            marginLeft="0.8rem"
          >
            {info.reviewerName}
          </Text>
          {loggedIn && (
            <IconWrapper onClick={() => setMoreBottomSheet(true)}>
              <MoreIcon />
            </IconWrapper>
          )}
        </TitleWrapper>
        <RowWrapper>
          <StarIcons grade={info.grade} />
          <Text fontStyle={font.body4} color={color.gray.d600}>
            {info.createdAt.split(" ")[0]}
          </Text>
        </RowWrapper>
        {info.images.length > 0 &&
          info.images.map((image) => <Image key={image.id} src={image.url} />)}
        <Text fontStyle={font.body4} color={color.gray.d900}>
          {info.contents}
        </Text>
      </Container>
      {!isLast && (
        <Divider direction="horizontal" width={"1rem"} color={color.gray.d50} />
      )}
      {moreBottomSheet && (
        <BottomSheet
          visible={moreBottomSheet}
          onClose={() => setMoreBottomSheet(false)}
        >
          {({ onClose }) => (
            <>
              {bottomSheetItem.map((item) => (
                <BottomSheetItemContainer
                  key={item.value}
                  onClick={() => {
                    if (item.callback) {
                      item.callback(onClose);
                    } else {
                      onClose();
                    }
                  }}
                >
                  <Text fontStyle={font.subtitle2} color={color.gray.d900}>
                    {item.name}
                  </Text>
                </BottomSheetItemContainer>
              ))}
            </>
          )}
        </BottomSheet>
      )}
    </>
  );
};

export default ReviewListItem;
