import { Container, Row, Col, Form } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

export default function Sectors(){

    const [loading, setLoading] = useState(false);

    const [res, setRes] = useState();
    function getData() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get('http://118.67.142.31:8095/common/signup').then(
                function(response){
                    // console.log(response);
                    setRes(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
            // reject(new Error("Request is failed"));
        });
    };

    

    
    const [businessClass, setBusinessClass] = useState([]);

    const onCheckData = (e) => {
        if(e.currentTarget.checked){
            setBusinessClass([
                ...businessClass, e.target.value
            ]);
            e.currentTarget.parentElement.querySelector('label').classList.remove('btn-default');
            e.currentTarget.parentElement.querySelector('label').classList.add('btn-primary');
        }else{
            setBusinessClass(businessClass.filter(row => Number(row) !== Number(e.target.value) ));
            e.currentTarget.parentElement.querySelector('label').classList.remove('btn-primary');
            e.currentTarget.parentElement.querySelector('label').classList.add('btn-default');
        }
        // console.log(businessClass);         
    };


    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        getData();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    if(res === undefined || res === null) return <></>

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="업태 / 업종" />
                <Row>
                    <Col>
                        <h4>상호명</h4>
                        <h6>스토어에 보여질 매장 업종을 등록해 주세요.</h6>
                    </Col>         
                </Row>
                <Row>
                    <Col>
                        <Form.Select>
                            <option>업태선택</option>
                        </Form.Select>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Label>업종 <span className='PointText'>*</span></Form.Label>
                        {/* <div direction="horizontal" gap={1}> */}
                        <div className="d-flex flex-wrap justify-content-start">
                            {
                                res.businessClass.map( row => (
                                    <div className='p-1' key={row.value+row.code}>
                                        <input type='checkbox' className="btn-check" autoComplete="off" id={row.value+row.code} value={row.code} onChange={onCheckData}/>
                                        <label htmlFor={row.value+row.code} className="btn btn-default">{row.value}</label>
                                    </div>
                                ) )
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}