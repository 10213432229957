const theme = {
  color: {
    primary: {
      flexRed: "#E03352",
      flexRedPressed: "#C22E49",
      white: "#FFFFFF",
      dark: "#161E26",
    },
    gray: {
      d10: "#FBFBFC",
      d50: "#F6F7F8",
      d100: "#E9EAED",
      d200: "#D2D5DA",
      d300: "#BCBFC8",
      d400: "#A5AAB5",
      d500: "#8F95A3",
      d600: "#7C8394",
      d700: "#656C7E",
      d800: "#505768",
      d900: "#212B35",
    },
    dimmed: {
      d70: "#161E26B3",
    },
    functional: {
      danger: {
        d100: "#FEF2F2",
        d200: "#FFEBEE",
        d300: "#FFCCD2",
        d400: "#F49898",
        d500: "#EB6F70",
        d600: "#F64C4C",
      },
      link: {
        d100: "#F1F8FF",
        d200: "#E4F2FF",
        d300: "#BDDDFF",
        d400: "#93C8FF",
        d500: "#4BA1FF",
        d600: "#3B82F6",
      },
      success: {
        d100: "#F2FAF6",
        d200: "#E5F5EC",
        d300: "#C0E5D1",
        d400: "#97D4B4",
        d500: "#6BC497",
        d600: "#47B881",
      },
      warning: {
        d100: "#FFF9EE",
        d200: "#FFF7E1",
        d300: "#FFEAB3",
        d400: "#FFDD82",
        d500: "#FFC62B",
        d600: "#FFAD0D",
      },
    },
  },
  font: {
    h1: `
      font-family: "pretendard Bold", sans-serif;
      font-size: 2.6rem;
      line-height: 3.4rem;
    `,
    h2: `
      font-family: "Pretendard Bold", sans-serif;
      font-size: 2.2rem;
      line-height: 3rem;
    `,
    subtitle1: `
      font-family: "Pretendard Bold", sans-serif;
      font-size: 2rem;
      line-height: 2.8rem;
    `,
    subtitle2: `
      font-family: "Pretendard Bold", sans-serif;
      font-size: 1.8rem;
      line-height: 2.6rem;
    `,
    subtitle3: `
      font-family: "Pretendard Bold", sans-serif;
      font-size: 1.6rem;
      line-height: 2.6rem;
    `,
    subtitle4: `
      font-family: "Pretendard Bold", sans-serif;
      font-size: 1.4rem;
      line-height: 2rem;
    `,
    body1: `
      font-family: "Pretendard SemiBold", sans-serif;
      font-size: 1.6rem;
      line-height: 2.4rem;
    `,
    body2: `
      font-family: "Pretendard SemiBold", sans-serif;
      font-size: 1.4rem;
      line-height: 2rem;
    `,
    body3: `
      font-family: "Pretendard SemiBold", sans-serif;
      font-size: 1.2rem;
      line-height: 1.8rem;
    `,
    body4: `
      font-family: "Pretendard Regular", sans-serif;
      font-size: 1.2rem;
      line-height: 1.8rem;
    `,
    body5: `
      font-family: "Pretendard Regular", sans-serif;
      font-size: 1rem;
      line-height: 1.6rem;
    `,
    caption1: `
      font-family: "Pretendard Regular", sans-serif;
      font-size: 1.6rem;
      line-height: 2.4rem;
    `,
    caption2: `
      font-family: "Pretendard Regular", sans-serif;
      font-size: 1.4rem;
      line-height: 2rem;
    `,
    caption3: `
      font-family: "Pretendard Regular", sans-serif;
      font-size: 1.2rem;
      line-height: 1.8rem;
    `,
    caption4: `
      font-family: "Pretendard Regular", sans-serif;
      font-size: 1rem;
      line-height: 1.6rem;
    `,
  },
  spacing: {
    xxxs: "0.2rem",
    xxs: "0.4rem",
    xs: "0.8rem",
    s: "1rem",
    m: "1.2rem",
    l: "1.6rem",
    xl: "2rem",
    xxl: "2.4rem",
    xxl2: "3.2rem",
    xxl3: "4rem",
    xxl4: "4.8rem",
    xxl5: "5.6rem",
    xxl6: "6.4rem",
  },
  elevation: {
    d1: `0px -2px 12px 0px #3A3C4026;`,
    d2: `0px 0px 8px 0px #3A3C4014;`,
  },
};

export const { color, font, spacing, elevation } = theme;
