export default function Switch( props ){
    const customCSS = {
        marginLeft : "-1.5em"
    }
    return (
        <div className="form-check form-switch">
            <input style={customCSS} className="form-check-input" type="checkbox" id="mySwitch" name={props.name} value={props.val} defaultChecked={props.checked !== undefined ? props.checked : false} />
            { props.label !== undefined ? <label className="form-check-label" htmlFor="mySwitch">{props.label}</label> : null }
        </div>
    )
}