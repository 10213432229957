import React, { useEffect, useState } from "react";
import { css, styled } from "styled-components";
import LabelledTextField from "../../../../../../components/ver1.5/organism/LabelledTextField";
import { color, font } from "../../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../../components/ver1.5/atom/Text";
import TextField from "../../../../../../components/ver1.5/atom/TextField";
import Button from "../../../../../../components/ver1.5/atom/Button";
import useTimer from "../../../../../../components/ver1.5/hooks/useTimer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiUrl } from "../../../../../../function/common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.4rem;
`;

const Row = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
`;

const CertCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const CertBtn = styled.button`
  padding: 1.5rem 1.6rem;
  background: ${(p) => p.$backgroundColor};
  border: 1px solid ${(p) => p.$borderColor};
  border-radius: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  ${(p) => p.$styles};
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const FindId = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(null);
  const [data, setData] = useState({
    name: "",
    phone: "",
    certificationNumber: "",
  });
  const [token, setToken] = useState(undefined);
  const [certDisabled, setCertDisabled] = useState(true);
  const [certSend, setCertSend] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [accountInfo, setAccountInfo] = useState({
    email: "",
    createdAt: "",
  });
  const [done, setDone] = useState(false);
  const { time, timerFunc } = useTimer({
    timerSeconds: 180,
    timeEndCallback: () => setTimerOn(false),
  });

  const onChangeData = (e, target) => {
    setData((prev) => ({ ...prev, [target]: e.target.value }));
  };

  const getAuthCode = async () => {
    if (!(data.name && data.phone)) {
      return;
    }
    if (loading) return;
    setLoading(true);
    await axios
      .post(getApiUrl("auth/id/code"), {
        name: data.name,
        phoneNumber: data.phone,
      })
      .then((res) => {
        setToken(res.data.token);
        setCertSend(true);
        setTimerOn(true);
      })
      .catch((error) => {
        console.error(error);
        alert(
          error?.response?.data?.message
            ? error.response.data.message
            : error.message
        );
      });
    setLoading(false);
  };

  const getAccount = async () => {
    if (!(data.certificationNumber && token)) {
      return;
    }
    if (loading) return;
    setLoading(true);
    await axios
      .post(getApiUrl("auth/id/auth"), {
        findIdToken: token,
        authCode: data.certificationNumber,
      })
      .then((res) => {
        setDone(true);
        setAccountInfo({
          email: res.data.loginId,
          createdAt: res.data.createdAt,
        });
      })
      .catch((error) => {
        console.error(error);
        alert(
          error?.response?.data?.message
            ? error.response.data.message
            : error.message
        );
      });
    setLoading(false);
  };

  useEffect(() => {
    if (timerOn) {
      timerFunc();
    }
    // eslint-disable-next-line
  }, [timerOn]);

  useEffect(() => {
    if (data.phone.length > 10) {
      setCertDisabled(false);
    } else {
      setCertDisabled(true);
    }
  }, [data]);

  return (
    <Container>
      {!done && (
        <>
          <LabelledTextField
            label={"이름"}
            placeholder={"실명 입력"}
            type={
              focused === "name" || data.name.length > 0 ? "bordered" : "filled"
            }
            value={data.name}
            onChange={(e) => onChangeData(e, "name")}
            onFocus={() => setFocused("name")}
            onBlur={() => setFocused(null)}
            customBorderColor={
              focused === "name" ? color.gray.d900 : color.gray.d100
            }
          />
          <CertCol>
            <Row>
              <LabelledTextField
                label={"휴대폰 번호"}
                placeholder={"숫자만 입력"}
                type={
                  focused === "phone" || data.phone.length > 0
                    ? "bordered"
                    : "filled"
                }
                value={data.phone}
                onChange={(e) => onChangeData(e, "phone")}
                onFocus={() => setFocused("phone")}
                onBlur={() => setFocused(null)}
                customBorderColor={
                  focused === "phone" ? color.gray.d900 : color.gray.d100
                }
                maxLength={11}
                onlyNumber
                inputMode={"tel"}
              />
              <CertBtn
                $backgroundColor={
                  certDisabled ? color.gray.d200 : color.primary.white
                }
                $borderColor={
                  certDisabled ? "transparent" : color.primary.flexRed
                }
                disabled={certDisabled}
                onClick={async () => {
                  await getAuthCode();
                }}
              >
                <Text
                  fontStyle={font.body1}
                  color={
                    certDisabled ? color.primary.white : color.primary.flexRed
                  }
                  styles={css`
                    white-space: nowrap;
                  `}
                >
                  {certSend ? "재전송" : "인증번호"}
                </Text>
              </CertBtn>
            </Row>
            <RelativeWrapper>
              <LabelledTextField
                placeholder={"인증번호 입력"}
                type={
                  focused === "certificationNumber" ||
                  data.certificationNumber.length > 0
                    ? "bordered"
                    : "filled"
                }
                value={data.certificationNumber}
                onChange={(e) => onChangeData(e, "certificationNumber")}
                onFocus={() => setFocused("certificationNumber")}
                onBlur={() => setFocused(null)}
                customBorderColor={
                  focused === "certificationNumber"
                    ? color.gray.d900
                    : color.gray.d100
                }
                helperText={!timerOn && certSend && "유효시간이 지났습니다."}
                status={!timerOn && certSend && "error"}
              />
              {timerOn && (
                <Text
                  fontStyle={font.caption1}
                  color={color.primary.flexRed}
                  styles={css`
                    position: absolute;
                    right: 1.6rem;
                    bottom: 1.6rem;
                  `}
                >
                  {`0${Math.floor(time / 60)}:${
                    time % 60 === 0
                      ? "00"
                      : time % 60 > 10
                      ? time % 60
                      : "0" + (time % 60)
                  }`}
                </Text>
              )}
            </RelativeWrapper>
          </CertCol>
          <BtnWrapper>
            <Button
              disabled={
                Object.values(data).filter((value) => Boolean(value) === true)
                  .length !== 3
              }
              text="확인"
              onClick={getAccount}
            />
          </BtnWrapper>
        </>
      )}
      {done && (
        <>
          <div>
            <Text fontStyle={font.h2} color={color.gray.d800}>
              입력하신 정보와
            </Text>
            <Text fontStyle={font.h2} color={color.gray.d800}>
              일치하는 아이디입니다
            </Text>
          </div>
          <div>
            <TextField
              readOnly
              value={accountInfo.email}
              padding={"1.6rem"}
              inputStyles={css`
                border: 1px solid ${color.gray.d100};
                border-radius: 1.2rem;
                ${font.body1};
              `}
            />
            <Text
              fontStyle={font.caption2}
              color={color.gray.d600}
              marginTop="1.6rem"
            >
              가입일 {accountInfo.createdAt}
            </Text>
          </div>
          <BtnWrapper
            $styles={css`
              display: flex;
              gap: 1rem;
            `}
          >
            <Button
              text="홈으로"
              onClick={() => navigate("/user")}
              variant="primaryDark"
            />
            <Button text="로그인" onClick={() => navigate("/user/login")} />
          </BtnWrapper>
        </>
      )}
    </Container>
  );
};

export default FindId;
