import React from "react";
import { css, styled } from "styled-components";
import { color, font } from "../styles/theme";
import Text from "./Text";
import { LoadingCion } from "../assets/icons";

const btnColorTree = {
  primary: {
    active: color.primary.flexRed,
    inactive: color.gray.d200,
    pressed: color.primary.flexRedPressed,
  },
  primaryDark: {
    active: color.primary.dark,
    inactive: color.gray.d200,
    pressed: color.gray.d900,
  },
  outline: {
    active: color.primary.white,
    inactive: color.primary.white,
    pressed: color.gray.d50,
  },
};

const Container = styled.button`
  background-color: ${(p) =>
    p.$variant === "outline"
      ? color.primary.white
      : p.disabled
      ? p.$colorTree.inactive
      : p.$colorTree.active};
  &:active {
    background-color: ${(p) =>
      p.$variant === "outline"
        ? p.disabled
          ? p.$colorTree.inactive
          : p.$colorTree.pressed
        : p.disabled
        ? p.$colorTree.inactive
        : p.$colorTree.pressed};
  }
  ${(p) =>
    p.$variant === "outline" &&
    css`
      border: 1px solid ${p.disabled ? color.gray.d200 : color.primary.flexRed};
    `}
  margin-top: ${(p) => (p.$marginTop ? p.$marginTop : 0)};
  margin-bottom: ${(p) => (p.$marginBottom ? p.$marginBottom : 0)};
  margin-right: ${(p) => (p.$marginRight ? p.$marginRight : 0)};
  margin-left: ${(p) => (p.$marginLeft ? p.$marginLeft : 0)};
  padding: 1.4rem 0;
  width: ${(p) => (p.$width ? p.$width : "100%")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.2rem;
  ${(p) => p.$customButtonStyle};
`;

const TextBtnContainer = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p.$textBtnStyle};
`;

const LoadingComponent = styled.div`
  animation: rotate 2s infinite;
  margin-left: 1rem;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

/**
 * Button 구성요소
 * @typedef {object} ButtonProperty
 * @property {'primary'|'primaryDark'|'text'|'outline'} variant
 * @property {void} onClick
 * @property {string} text
 * @property {boolean=} loading
 * @property {boolean=} disabled
 * @property {any} width
 * @property {any=} textBtnFontStyle
 * @property {string=} textBtnColor
 * @property {any=} textBtnStyle
 * @property {any=} customFontStyle
 * @property {any=} icon
 * @property {'left'|'right'} iconPosition
 * @property {string=} marginTop
 * @property {string=} marginBottom
 * @property {string=} marginLeft
 * @property {string=} marginRight
 * @property {any=} customButtonStyle
 *
 * @param {ButtonProperty}
 */
const Button = ({
  text,
  onClick,
  variant = "primary",
  loading,
  disabled = false,
  width,
  textBtnFontStyle = font.body2,
  textBtnColor = color.gray.d800,
  textBtnStyle,
  customFontStyle,
  icon,
  iconPosition = "right",
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  customButtonStyle,
}) => {
  const colorTree = btnColorTree[variant];

  if (variant === "text") {
    return (
      <TextBtnContainer
        onClick={() => (onClick ? (loading ? null : onClick()) : null)}
        disabled={disabled}
        $textBtnStyle={textBtnStyle}
      >
        <Text
          fontStyle={textBtnFontStyle}
          color={textBtnColor}
          styles={customFontStyle}
        >
          {iconPosition === "left" && icon}
          {text}
          {iconPosition === "right" && icon}
        </Text>
      </TextBtnContainer>
    );
  } else {
    return (
      <Container
        $variant={variant}
        $colorTree={colorTree}
        disabled={disabled}
        $width={width}
        onClick={() => (onClick ? (loading ? null : onClick()) : null)}
        $marginTop={marginTop}
        $marginBottom={marginBottom}
        $marginLeft={marginLeft}
        $marginRight={marginRight}
        $customButtonStyle={customButtonStyle}
      >
        <Text
          fontStyle={font.subtitle3}
          color={
            variant === "outline"
              ? disabled
                ? color.gray.d300
                : color.primary.flexRed
              : color.primary.white
          }
          styles={customFontStyle}
        >
          {iconPosition === "left" && icon}
          {text}
          {iconPosition === "right" && icon}
        </Text>
        {loading && (
          <LoadingComponent>
            <LoadingCion />
          </LoadingComponent>
        )}
      </Container>
    );
  }
};

export default Button;
