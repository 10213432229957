import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import { color, font } from "../../../components/ver1.5/styles/theme";
import Footer from "../../../components/ver1.5/organism/Footer";
import { commonInfoContents } from "../Store/Detail/components/organism/detailInfoContentList";
import DetailInfoItem from "../Store/Detail/components/molecule/DetailInfoItem";
import LabelItem from "../Gift/components/molecule/LabelItem";
import Text from "../../../components/ver1.5/atom/Text";
import { getApiUrl, setCommas } from "../../../function/common";
import Divider from "../../../components/ver1.5/atom/Divider";
import HistoryDetailItem from "./components/organism/HistoryDetailItem";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../contexts/UserContext";

const WhiteContainer = styled.div`
  background: ${color.primary.white};
  padding-top: 2.4rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  ${(p) => (p.$styles ? p.$styles : null)};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.$gap ?? 0};
`;

const HistoryDetail = () => {
  const { accessToken } = useContext(UserContext);

  const location = useLocation();
  const orderNumber =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const [storeName, setStoreName] = useState("");
  const [price, setPrice] = useState(0);
  const [payType, setPayType] = useState({
    code: 0,
    description: "",
    minimumFee: 0,
  });

  const [data, setData] = useState({});

  const getCoupon = async () => {
    if (!orderNumber) return;
    const res = await axios
      .get(getApiUrl(`users/me/coupons/${orderNumber}`), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((e) => console.error(e));
    return res;
  };

  useEffect(() => {
    getCoupon().then((res) => {
      setStoreName(res.data?.storeName);
      setPrice(res.data?.price);
      setPayType(res.data?.payType);
      setData(res.data);
    });
    // eslint-disable-next-line
  }, []);

  const infoContents = commonInfoContents(storeName);

  const paymentInfo = [
    {
      name: "총 상품 금액",
      value: price,
      type: "price",
    },
    {
      name: "결제 방법",
      value: payType.description,
    },
  ];

  return (
    <Layout
      footerPadding={false}
      styles={css`
        background: ${color.gray.d50};
      `}
    >
      <HistoryDetailItem {...data} />
      <WhiteContainer>
        <LabelItem title="결제 정보" contentsMarginTop={"2.4rem"}>
          <Column $gap={"2.4rem"}>
            {paymentInfo.map((info) => (
              <Row
                key={info.name}
                $styles={css`
                  justify-content: space-between;
                  ${info.isTotal &&
                  css`
                    border-top: 1px solid ${color.gray.d100};
                    padding-top: 1.6rem;
                  `}
                `}
              >
                <Text fontStyle={font.caption1} color={color.gray.d900}>
                  {info.name}
                </Text>
                <Text fontStyle={font.body1} color={color.gray.d900}>
                  {info.type === "price"
                    ? setCommas(info.value) + "원"
                    : info.value}
                </Text>
              </Row>
            ))}
          </Column>
        </LabelItem>
        <Divider
          direction="horizontal"
          width={"1rem"}
          color={color.gray.d50}
          marginTop={"2.4rem"}
        />
        {infoContents.map((info, index) => (
          <DetailInfoItem
            key={index}
            isAccordion
            dividerWidth={"0.1rem"}
            isLast={index === infoContents.length - 1}
            contents={info.content}
            titleWidth={info.titleWidth}
            sectionTitle={info.title}
          />
        ))}
      </WhiteContainer>
      <Footer />
    </Layout>
  );
};

export default HistoryDetail;
