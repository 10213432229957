import React, { useState } from "react";
import "./PrdCate.css";
import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import Btn from "../../../components/common/Btn";
import axios from "axios";
import { getApiUrl } from "../../../function/common";

const EditGiftBox = ({ row = { name: "" }, isFirst, onClickAdd }) => {
  return (
    <div className={`EditGiftBox2 ${isFirst ? "first" : ""}`}>
      <Row className="py-4 px-4-5 align-items-center g-0">
        <Col className="col-11 txt">{row.name}</Col>
        <Col className="col-1 img text-end">
          <Button variant="clear" onClick={() => onClickAdd(row)}>
            <Image
              fluid
              src="https://www.flexdaycdn.net/public/images/static/prd-cate/store_switch.png"
            />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

// TODO: 카테고리 관련한 로직 만들어지고 나면 재작업 해야함.
const categorySample = ["세트", "카페"];

const ProductCategory = () => {
  const [products, setProducts] = useState([]);
  const [addModalShow, setAddModalShow] = useState(false);

  const getProducts = async () => {
    await axios
      .get(
        getApiUrl(
          `stores/${localStorage.getItem("storeDomain")}/products?status=SALE`
        )
      )
      .then((res) => {
        const { status, data } = res;
        if (status === 200 && data.data.length > 0) {
          const { data: list } = data;
          setProducts(list);
        }
      });
  };

  return (
    <div className="PrdCatePage">
      <Btn type="NAVBACK" title="카테고리 관리" />
      <Container fluid className="g-0">
        <div className="PrdCateTab">
          <Tabs
            defaultActiveKey="category"
            id="pills-tab"
            className="Inner py-4-5 justify-content-around nav-pills"
            onSelect={(key) => {
              if (key === "product") {
                getProducts();
              }
            }}
          >
            <Tab
              eventKey={"category"}
              title="카테고리"
              tabClassName="PrdCateBtn"
            >
              <Row className="EditBtnGroup d-flex px-4-5 mb-4-5">
                <Button variant="light">+ 추가하기</Button>
              </Row>
              <div className="PrdCateBlank Inner text-center">
                <Image
                  fluid
                  className="emoji mb-4"
                  src="https://www.flexdaycdn.net/public/images/static/prd-cate/prdcate_emoji.png"
                />
                <p className="desc">
                  만들어진 카테고리가 없네요!
                  <br />
                  카테고리를 만들어 상품을 운영해보세요.
                </p>
              </div>
            </Tab>
            <Tab eventKey={"product"} title="교환권" tabClassName="PrdCateBtn">
              <Row className="EditBtnGroup d-flex px-4-5 mb-4-5">
                <Button variant="light">순서 편집하기</Button>
              </Row>
              {products && products.length > 0 && (
                <>
                  {products.map((product, index) => {
                    return (
                      <EditGiftBox
                        row={product}
                        key={product.id}
                        isFirst={index === 0}
                        onClickAdd={(row) => {
                          setAddModalShow(true);
                          console.log(row);
                        }}
                      />
                    );
                  })}
                </>
              )}
            </Tab>
          </Tabs>
        </div>
        <Modal
          show={addModalShow}
          onHide={() => setAddModalShow(false)}
          className="text-center modal-prd-stop PrdCatePage"
          size="sm"
          dialogClassName="modal-bottom"
          contentClassName="border-0"
        >
          <Modal.Header className="bg-white mb-2 p-0 rounded-3">
            <p className="modal-txt1">어떤 카테고리에 추가할까요?</p>
          </Modal.Header>
          <Modal.Body className="bg-white rounded-3 p-0">
            {categorySample.map((txt, index) => {
              return (
                <Row key={index} className="txtbox">
                  <Button
                    variant="clear"
                    key={index}
                    onClick={() => {
                      console.log(txt);
                      setAddModalShow(false);
                    }}
                  >
                    <p className="modal-txt1">{txt}</p>
                  </Button>
                </Row>
              );
            })}
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default ProductCategory;
