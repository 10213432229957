import React from "react";
import { css, styled } from "styled-components";
import Text from "../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import { StarIcon } from "../../../../../components/ver1.5/assets/icons";
import { gradeConvert } from "../../../../../function/common";
import DefaultBackground from "../../../../../components/ver1.5/assets/img/shopBackgroundDefault.svg";
import DefaultLogo from "../../../../../components/ver1.5/assets/img/shopProfileImgDefault.svg";

const Container = styled.div`
  ${(p) =>
    p.$variant === "square" &&
    css`
      max-width: 16rem;
      img {
        aspect-ratio: 1;
      }
      flex-shrink: 0;
    `}
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

const Image = styled.img`
  width: 100%;
  max-height: 17.4rem;
  border-radius: 1.2rem;
  overflow: hidden;
  object-fit: cover;
`;

const Logo = styled.img`
  width: 4rem;
  height: 4rem;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 2rem;
  border: 1px solid ${color.gray.d100};
  margin-right: 0.8rem;
  flex-shrink: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => p.direction};
  margin-top: 1rem;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
`;
/**
 * ShopListItem 구성요소
 * @typedef {object} ShopListItemProperty
 * @property {any=} styles
 * - using " css`...` "
 * @property {void} onClick
 * - onClick 필요할 때 있으면 사용
 * @property {"full"|"square"=} variant
 * @property {object} item
 *
 * @param {ShopListItemProperty}
 */
const ShopListItem = ({
  variant = "square",
  styles,
  onClick,
  item = {
    url: "",
    storeName: "",
    logoUrl: "",
    storeAddress: {
      address: "",
      addressDetail: "",
    },
    grade: 0,
    images: [],
    domain: "",
  },
}) => {
  return (
    <Container $variant={variant} onClick={() => (onClick ? onClick() : null)}>
      <Image
        src={item.images.length ? item.images[0].url : DefaultBackground}
      />
      <ContentWrapper direction={variant === "full" ? "row" : "column"}>
        {variant === "full" && <Logo src={item.logoUrl ?? DefaultLogo} />}
        <TextWrapper>
          <Text
            fontStyle={variant === "full" ? font.subtitle3 : font.subtitle4}
            ellipsis
          >
            {item.storeName}
          </Text>
          <Text fontStyle={font.body4} color={color.gray.d500} ellipsis>
            {item.storeAddress.address + " " + item.storeAddress.addressDetail}
          </Text>
        </TextWrapper>
        <Text
          marginLeft={variant === "full" ? "auto" : "0"}
          marginTop={variant === "full" ? "0" : "0.4rem"}
          fontStyle={font.body2}
          color={color.gray.d900}
          styles={css`
            display: flex;
            align-items: center;
            gap: 0.4rem;
            ${variant === "full" &&
            css`
              align-self: flex-start;
            `}
          `}
        >
          <StarIcon />
          {gradeConvert(item.grade)}
        </Text>
      </ContentWrapper>
    </Container>
  );
};

export default ShopListItem;
