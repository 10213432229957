import React, { useEffect, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css } from "styled-components";
import { color } from "../../../../components/ver1.5/styles/theme";
import PaymentInfo from "./receiptDetailComponents/PaymentInfo";
import ProductInfo from "./receiptDetailComponents/ProductInfo";
import Divider from "../../../../components/ver1.5/atom/Divider";
import StoreInfo from "./receiptDetailComponents/StoreInfo";
import { useLocation } from "react-router-dom";

const ReceiptDetail = () => {
  const location = useLocation();
  const [receiptInfo, setReceiptInfo] = useState({
    issueCompanyName: "",
    type: "",
    installment: "",
    cardNumber: "",
    approveDate: "",
    approveId: "",
    orderNumber: "",
    productName: "",
    paymentPrice: "",
    storeName: "",
    storeBusinessNumber: "",
    storeAddress: {
      address: "",
    },
  });

  useEffect(() => {
    if (location.state?.receiptInfo) {
      setReceiptInfo(location.state.receiptInfo);
    }
  }, [location]);

  return (
    <Layout
      footerPadding={false}
      styles={css`
        padding-bottom: 2.4rem;
        background-color: ${color.primary.white};
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <PaymentInfo
        cardName={receiptInfo.issueCompanyName}
        type={receiptInfo.type}
        installment={receiptInfo.installment}
        cardNumber={receiptInfo.cardNumber}
        approveDate={receiptInfo.approveDate}
        approveId={receiptInfo.approveId}
      />
      <Divider width={"1rem"} color={color.gray.d50} />
      <ProductInfo
        orderNumber={receiptInfo.orderNumber}
        productName={receiptInfo.productName}
        price={receiptInfo.paymentPrice}
      />
      <Divider width={"1rem"} color={color.gray.d50} />
      <StoreInfo
        storeName={receiptInfo.storeName}
        businessNumber={receiptInfo.storeBusinessNumber}
        address={receiptInfo.storeAddress.address}
      />
      <Divider width={"1rem"} color={color.gray.d50} marginBottom={"3.4rem"} />
    </Layout>
  );
};

export default ReceiptDetail;
