import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import { setAccessToken } from './../../api/ApiWebView';
import { setCommas, getImgUrl } from './../../function/common';
import Btn from './../../components/common/Btn';

export default function PosCheck(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    // if( Parameters.length === 0 ){
    //     navigate('/404');
    // }


    const [item, setItem] = useState();
    function getOrderItem() {
        // alert(localStorage.getItem('accessToken'));
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('stores/'+Parameters[0].storedomain+'/coupons/'+Parameters[1].couponnumber),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                    }
                }
            ).then(
                function(response){
                    setItem(response.data);
                }
            ).catch(function(error){
                console.log(error);
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    const useGift = async (e) => {
        let obj = { 
            'storeDomain' : Parameters[1].storedomain,
            'couponnumber' : Parameters[0].couponnumber
        }
        await axios.post(
            getApiUrl('stores/'+ Parameters[0].storedomain + '/coupons/' + Parameters[1].couponnumber),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;",
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                PrintConsole(response.data);
                if(response.status === 200){
                    alert('쿠폰이 사용 처리 되었습니다.');
                    navigate('/pos/main');
                }else{
                    alert('쿠폰이 사용 처리에 실패하였습니다.');
                }
            }
        ).catch(function(error){
            PrintConsole(error);
            AlertError(error);
        })
    }

    const cancelGift = async (e) => {
        let obj = { 
            'storeDomain' : Parameters[1].storedomain,
            'couponnumber' : Parameters[0].couponnumber
        }
        await axios.delete(
            getApiUrl('stores/'+ Parameters[0].storedomain + '/coupons/' + Parameters[1].couponnumber),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;",
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                PrintConsole(response.data);
                if(response.status === 200){
                    alert('쿠폰이 사용이 취소 되었습니다.');
                    navigate('/pos/main');
                }else{
                    alert('쿠폰이 사용 처리에 실패하였습니다.');
                }
            }
        ).catch(function(error){
            PrintConsole(error);
            AlertError(error);
        })
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        getOrderItem();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Row>
                    <Col className='text-center mt-3' xs={{span : 4, offset : 4}}>
                        <div class="card">
                            {
                                item && item.status.code === 1 ? 
                                <img src={item && item.representImageUrl === null ? 'http://placeimg.com/180/180' : item && item.representImageUrl} className='card-img' />
                                :
                                <img src={item && item.representImageUrl === null ? 'http://placeimg.com/180/180' : item && item.representImageUrl} className='card-img' style={{opacity : '0.6'}} />
                            }
                            
                            <div class='card-img-overlay'>
                                {
                                    item && item.status.code === 1 ? 
                                        <img src={getImgUrl('images/pos/use_stamp2.png')} className='card-overlay-img w-15'/>
                                    : 
                                        <img src={getImgUrl('images/pos/use_stamp.png')} className='card-overlay-img w-15'/>
                                }
                                {/* <p class="card-text Register_tit">
                                    {item && item.status.code === 1 ? '사용 가능한 교환권 입니다.' : '사용 불가능한 교환권 입니다.'}
                                </p> */}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-3 text-center' xs={{span : 4, offset : 4}}>
                        <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                                <tbody>
                                    <tr>
                                        <td className='text-start'>상품</td>
                                        <td className='text-end'><b>{item && item.productName}</b></td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>가격</td>
                                        <td className='text-end'><b>{item && setCommas(item.priceInfo.storeSalesPrice) + ' 원'}</b></td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>유효기간</td>
                                        <td className='text-end'><b>{item && item.expireDate}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                {
                    item && item.status.code === 1 ? <></> :
                    <Row className='mt-1 text-center'>
                        <Col xs={{span:8, offset:2}}>
                            <p>사용 취소는 사용당일(23:59)까지만 가능합니다.</p>
                        </Col>
                    </Row>
                }
                <Row className=''>
                    <Col xs={{span:4, offset:4}}>
                        <div className="d-grid gap-1">
                            <Button variant="primary" size="lg" onClick={ item && item.status.code === 1 ? useGift : cancelGift } >
                                {item && item.status.code === 1 ? '교환권 사용하기' : '사용 취소하기'}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={{span:4, offset:4}}>
                        <div className="d-grid gap-1">
                            <Button variant="default" size="lg" onClick={ () => {navigate('/pos/main')} } >
                                메인 화면
                            </Button>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </>
    )
}