import React, { useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import { presetHeight } from "../../../../components/ver1.5/constants";
import Text from "../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import TextField from "../../../../components/ver1.5/atom/TextField";
import Button from "../../../../components/ver1.5/atom/Button";
import { Logo } from "../../../../components/ver1.5/assets/icons";
import GiftParticle from "../../../../components/ver1.5/assets/img/giftParticle.svg";
import GiftIllust from "../../../../components/ver1.5/assets/img/receiveGift.svg";
import axios from "axios";
import { getApiUrl } from "../../../../function/common";
import OneBtnModal from "../../../../components/ver1.5/organism/OneBtnModal";
import ModalLayout from "../../../../components/ver1.5/molecule/ModalLayout";

const ImageWrapper = styled.div`
  position: relative;
  height: 22rem;
  width: 100%;
`;

const Image = styled.img`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 0 1.6rem;
  width: 100%;
`;

const IconContainer = styled.div`
  /* position: absolute;
  bottom: 0; */
  margin-top: auto;
  margin-bottom: 4.2rem;
`;

const CheckPhone = ({ setStep, code, setData }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [wrongNumberModal, setWrongNumberModal] = useState(false);
  const [usedModal, setUsedModal] = useState(false);
  const [canceledModal, setCanceledModal] = useState(false);
  const [defaultDisableModal, setDefaultDisableModal] = useState(false);

  const checkPhone = async () => {
    if (!code) alert("교환권코드를 확인해주세요.");
    await axios
      .post(getApiUrl(`coupons/new-qrcode/${code}`), {
        phoneNumber,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status.code === 1 || res.data.status.code === 6) {
            setData(res.data);
            setStep(2);
          } else {
            if (res.data.status.code === 2) {
              setUsedModal(true);
            } else if (res.data.status.name.includes("취소")) {
              setCanceledModal(true);
            } else {
              setDefaultDisableModal(true);
            }
          }
        } else {
          setDefaultDisableModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setWrongNumberModal(true);
      });
    setPhoneNumber("");
  };

  return (
    <Layout
      footerPadding={false}
      styles={css`
        /* background-color: #fcf5f4; */
        background-color: ${color.primary.white};
        padding: 2.4rem;
        padding-top: ${`calc(${presetHeight.header} + 2.4rem)`};
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        align-items: center;
      `}
    >
      <div>
        {/* <ImageWrapper>
          <Image src={GiftIllust} />
          <Image src={GiftParticle} />
        </ImageWrapper> */}
        {/* <Text fontStyle={font.h1} color={color.gray.d900}>
          {senderName}님으로 부터
        </Text> */}
        <Text
          fontStyle={font.h1}
          color={color.gray.d900}
          styles={css`
            span {
              color: ${color.primary.flexRed};
            }
          `}
        >
          <span>선물</span>이 도착했어요!
        </Text>
      </div>
      <TextContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text fontStyle={font.body1} color={color.gray.d900}>
            본인 확인을 위해
          </Text>
          <Text fontStyle={font.body1} color={color.gray.d900}>
            연락처 끝번호 4자리를 입력해주세요
          </Text>
        </div>
        <Text
          fontStyle={font.body1}
          color={color.gray.d900}
          styles={css`
            span {
              color: ${color.primary.flexRed};
            }
          `}
        >
          예시) 010-****-<span>0000</span>
        </Text>
      </TextContainer>
      <InputContainer>
        <TextField
          placeholder="연락처 끝번호 4자리 입력"
          backgroundColor={color.primary.white}
          padding={"1.6rem"}
          inputStyles={css`
            border: 1px solid ${color.gray.d100};
            border-radius: 1.2rem;
          `}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Button
          text="확인"
          disabled={!Boolean(phoneNumber)}
          onClick={checkPhone}
        />
      </InputContainer>
      <IconContainer>
        <Logo width={80} height={20} />
      </IconContainer>
      {wrongNumberModal && (
        <OneBtnModal
          text={"연락처 끝 번호가 일치하지 않습니다."}
          onAction={() => setWrongNumberModal(false)}
        />
      )}
      {usedModal && (
        <OneBtnModal
          text={"이미 사용된 교환권입니다."}
          onAction={() => {
            window.location.replace("/user");
            setUsedModal(false);
          }}
        />
      )}
      {canceledModal && (
        <OneBtnModal
          text={
            <pre style={{ textAlign: "center" }}>
              구매자에 의해
              <br />
              선물이 취소되었습니다
            </pre>
          }
          onAction={() => {
            window.location.replace("/user");
            setCanceledModal(false);
          }}
        />
      )}
      {defaultDisableModal && (
        <OneBtnModal
          text={"취소 혹은 사용처리된 교환권입니다."}
          onAction={() => {
            window.location.replace("/user");
            setDefaultDisableModal(false);
          }}
        />
      )}
    </Layout>
  );
};

export default CheckPhone;
