import React, { useEffect, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { getApiUrl } from "../../../../function/common";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { css, styled } from "styled-components";
import Text from "../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { presetHeight } from "../../../../components/ver1.5/constants";
import ShopListItem from "../../Main/components/molecule/ShopListItem";
import useInfiniteScroll from "../../../../components/ver1.5/hooks/useInfiniteScroll";
import ReactGA from "react-ga4";

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
`;

const Search = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [isLast, setIsLast] = useState(false);
  const [result, setResult] = useState({
    content: [],
  });
  const [total, setTotal] = useState(0);
  const [emptyText, setEmptyText] = useState("");

  const clear = () => {
    setLoading(false);
    setPage(0);
    setSize(10);
    setIsLast(false);
    setResult({
      content: [],
    });
    setTotal(0);
  };

  const getStores = async ({ page = 0, size = 10 }) => {
    if (loading) return;
    if (keyword) {
      setLoading(true);
      const res = await axios
        .get(getApiUrl("stores-keyword"), {
          params: {
            keyword,
            page,
            size,
          },
        })
        .then((res) => {
          if (res.data) {
            if (res.data.last) {
              setIsLast(true);
            } else {
              setPage((prev) => prev + 1);
            }
            setResult((prev) => ({
              ...res.data,
              content: [...prev.content, ...res.data.content],
            }));
            setTotal(res.data.totalElements);
            if (res.data.content.length === 0) {
              setEmptyText("검색결과가 없습니다");
            }
          }
          setLoading(false);
        });
      return res;
    } else {
      setResult({ content: [] });
      setTotal(0);
      setEmptyText("검색어를 입력해주세요");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keyword) {
      clear();
      if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
        ReactGA.event({
          category: "store",
          action: "searchStore",
          label: keyword,
        });
      }
      getStores({ page: 0, size });
    }
    // eslint-disable-next-line
  }, [keyword]);

  const { Target } = useInfiniteScroll(async (entry, observer) => {
    if (loading) {
      return;
    }
    if (isLast) {
      return;
    }
    observer.unobserve(entry.target);
    await getStores({ page, size });
    observer.observe(entry.target);
  });

  return (
    <Layout
      styles={css`
        font-size: 1.6rem;
        padding: 0 1.6rem 6.4rem;
        padding-top: ${`calc(${presetHeight.header} + ${
          keyword ? "6.4rem" : "2.4rem"
        })`};
      `}
      footerPadding={false}
    >
      {keyword && (
        <Text
          fontStyle={font.subtitle1}
          styles={css`
            position: fixed;
            padding-top: 2.4rem;
            top: ${`calc(${presetHeight.header})`};
            background-color: ${color.primary.white};
            width: calc(100% - 3.2rem);
            span {
              color: ${color.primary.flexRed};
            }
          `}
          marginBottom="1.2rem"
        >
          {keyword} <span>{total}개</span>
        </Text>
      )}
      <ListContainer>
        {result.content.length > 0 ? (
          result.content.map((shop, index) => (
            <ShopListItem
              variant="full"
              item={shop}
              key={index}
              onClick={() => {
                if (shop.domain) {
                  navigate(`/user/${shop.domain}`);
                }
              }}
            />
          ))
        ) : (
          <Text
            fontStyle={font.subtitle3}
            color={color.gray.d400}
            marginTop="24.8rem"
            marginLeft="auto"
            marginRight="auto"
          >
            {emptyText}
          </Text>
        )}
      </ListContainer>
      <Target />
    </Layout>
  );
};

export default Search;
