export const deviceType = () => {
  var mobile = /iphone|ipad|ipod|android/i.test(
    navigator.userAgent.toLowerCase()
  );
  let currentOS = "";

  if (mobile) {
    // 유저에이전트를 불러와서 OS를 구분합니다.
    var userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.search("android") > -1) currentOS = "android";
    else if (
      userAgent.search("iphone") > -1 ||
      userAgent.search("ipod") > -1 ||
      userAgent.search("ipad") > -1
    )
      currentOS = "ios";
    else currentOS = "else";
  } else {
    // 모바일이 아닐 때
    currentOS = "nomobile";
  }

  return currentOS;
};

export const getVersion = () => {
  if (deviceType === "android") {
    // eslint-disable-next-line no-undef
    window.flexdayaos.getVersion();
  } else if (deviceType === "ios") {
    var obj = {};
    obj.name = "getVersion";
    // eslint-disable-next-line no-undef
    window.webkit.messageHandlers.fdcallbackhandler.postMessage(
      JSON.stringify(obj)
    );
  }
};

export const setAccessToken = (token) => {
  // console.log(deviceType());
  if (deviceType() === "android") {
    // eslint-disable-next-line no-undef
    window.flexdayaos.setAccessToken(localStorage.getItem("accessToken"));
    // eslint-disable-next-line no-undef
    window.flexdayaos.setDomain(localStorage.getItem("storeDomain"));
    console.log(localStorage.getItem("storeDomain"));
    // alert('sat');
  } else if (deviceType() === "ios") {
    var obj = {
      accessToken: localStorage.getItem("accessToken"),
      storeDomain: localStorage.getItem("storeDomain"),
    };
    // eslint-disable-next-line no-undef
    window.webkit.messageHandlers.fdcallbackhandler.postMessage(
      JSON.stringify(obj)
    );
  }
};

export const getAccessToken = (token) => {
  if (
    localStorage.getItem("storeDomain") !== undefined &&
    localStorage.getItem("storeDomain") !== null &&
    localStorage.getItem("storeDomain") !== "null" &&
    localStorage.getItem("storeDomain") !== ""
  ) {
    let obj = {
      setAccessToken: localStorage.getItem("accessToken"),
    };
    window.webkit.messageHandlers.fdcallbackhandler.postMessage(
      JSON.stringify(obj)
    );
  } else {
    let obj = {
      setAccessToken: localStorage.getItem("accessToken"),
    };
    window.webkit.messageHandlers.fdcallbackhandler.postMessage(
      JSON.stringify(obj)
    );
  }
};
export const getStoreDomain = (token) => {
  if (
    localStorage.getItem("storeDomain") !== undefined &&
    localStorage.getItem("storeDomain") !== null &&
    localStorage.getItem("storeDomain") !== "null" &&
    localStorage.getItem("storeDomain") !== ""
  ) {
    let obj = {
      setStoreDomain: localStorage.getItem("storeDomain"),
    };
    window.webkit.messageHandlers.fdcallbackhandler.postMessage(
      JSON.stringify(obj)
    );
  } else {
    let obj = {
      setStoreDomain: localStorage.getItem("storeDomain"),
    };
    window.webkit.messageHandlers.fdcallbackhandler.postMessage(
      JSON.stringify(obj)
    );
  }
};

export const setNav1 = (token) => {
  if (deviceType() === "android") {
    // eslint-disable-next-line no-undef
    window.flexdayaos.setNav1();
  } else if (deviceType() === "ios") {
    window.webkit.messageHandlers.flexdayaos.setNav1();
  }
};

export const setNav2 = (token) => {
  if (deviceType() === "android") {
    // eslint-disable-next-line no-undef
    window.flexdayaos.setNav2();
  } else if (deviceType() === "ios") {
    window.webkit.messageHandlers.flexdayaos.setNav2();
  }
};

export const setNav3 = (token) => {
  // 로그인시 버튼 리셋
  if (deviceType() === "android") {
    // eslint-disable-next-line no-undef
    window.flexdayaos.setNav3();
  } else if (deviceType() === "ios") {
    window.webkit.messageHandlers.flexdayaos.setNav3();
  }
};

export const setNav4 = (token) => {
  if (deviceType() === "android") {
    // eslint-disable-next-line no-undef
    window.flexdayaos.setNav4();
  } else if (deviceType() === "ios") {
    window.webkit.messageHandlers.flexdayaos.setNav4();
  }
};

export const setNav5 = (token) => {
  if (deviceType() === "android") {
    // eslint-disable-next-line no-undef
    window.flexdayaos.setNav5();
  } else if (deviceType() === "ios") {
    window.webkit.messageHandlers.flexdayaos.setNav5();
  }
};

export const openQRRead = (token) => {
  try {
    if (deviceType() === "android") {
      // eslint-disable-next-line no-undef
      window.flexdayaos.QRRead();
    } else if (deviceType() === "ios") {
      // window.webkit.messageHandlers.fdcallbackhandler.QRRead();
      // window.webkit.messageHandlers.fdcallbackhandler('QRRead');
      window.webkit.messageHandlers.fdcallbackhandler.postMessage(
        "openQRCodeReader"
      );
    }
  } catch (e) {
    console.log(e);
  }
};

export const CancelQRRead = (token) => {
  if (deviceType() === "android") {
    // eslint-disable-next-line no-undef
    window.flexdayaos.CancelQRRead();
  } else if (deviceType() === "ios") {
    // window.webkit.messageHandlers.fdcallbackhandler.QRRead();
    window.webkit.messageHandlers.fdcallbackhandler.postMessage(
      "openQRCodeReader"
    );
  }
};
