import React, { useEffect } from "react";
import ModalLayout from "../../../../../components/ver1.5/molecule/ModalLayout";
import { css, styled } from "styled-components";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";
import Divider from "../../../../../components/ver1.5/atom/Divider";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  padding-bottom: 2.4rem;
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  height: 5.6rem;
  border-top: 1px solid ${color.gray.d100};
`;

const Btn = styled.button`
  width: 100%;
  flex-shrink: 0;
  padding: 1.6rem 0;
`;

const AlarmOnModal = ({ onAction, alarmType, updatedAt }) => {
  const date = updatedAt.split(" ")[0];
  const time = updatedAt.split(" ")[1]?.split(":")[0];
  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  return (
    <ModalLayout onClickOverlay={() => onAction()}>
      <ContentContainer>
        <DescriptionWrapper>
          <Text fontStyle={font.body1} color={color.gray.d900}>
            {`마케팅 정보 ${alarmType} 알림 동의 처리완료`}
          </Text>
          <div>
            <Text
              fontStyle={font.caption1}
              color={color.gray.d900}
              styles={css`
                text-align: center;
              `}
            >
              전송자:(주)플렉스데이 가맹점관리자
            </Text>
            <Text
              fontStyle={font.caption1}
              color={color.gray.d900}
              styles={css`
                text-align: center;
              `}
            >
              처리일시:{date + " " + time + "시"}
            </Text>
          </div>
        </DescriptionWrapper>
      </ContentContainer>
      <BtnContainer>
        <Btn onClick={() => onAction()}>
          <Text fontStyle={font.body1} color={color.functional.link.d600}>
            확인
          </Text>
        </Btn>
      </BtnContainer>
    </ModalLayout>
  );
};

export default AlarmOnModal;
