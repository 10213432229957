import React, { useContext, useState } from "react";
import { styled } from "styled-components";
import { color } from "./styles/theme";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HomeIcon,
  MyOffIcon,
  MyOnIcon,
  WalletOffIcon,
  WalletOnIcon,
} from "./assets/icons";
import { presetHeight, headerFooterZIndex } from "./constants";
import { UserContext } from "../../contexts/UserContext";
import DefaultModal from "./organism/DefaultModal";

const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  /* height: 8.7rem; */
  height: ${presetHeight.bottomNav};
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1.6rem 2.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${headerFooterZIndex};
  /* border-top-left-radius: 3.2rem;
  border-top-right-radius: 3.2rem; */
  /* border-top: 0.1rem solid ${color.border}; */
  box-shadow: 0px -2px 12px 0px #3a3c4026;
  background: ${color.primary.white};
  @media screen and (min-width: 500px) {
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const IconWrapper = styled.button`
  width: 2.4rem;
  min-height: 4rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: ${color.gray.d900};
  font-family: ${(p) =>
      p.selected ? "Pretendard SemiBold" : "Pretendard Regular"},
    sans-serif;
`;

const IconContainer = styled.div`
  width: 2.4rem;
  aspect-ratio: 1;
`;

const BottomNavigation = () => {
  const { accessToken } = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const currentTab =
    location.pathname.replace("/user", "") === ""
      ? "home"
      : location.pathname.replace("/user/", "");
  const navigate = useNavigate();

  const iconList = [
    {
      name: "메인",
      value: "home",
      icon: (
        <HomeIcon
          fillColor={currentTab === "home" ? color.gray.d900 : "#fff"}
        />
      ),
    },
    {
      name: "구매내역",
      value: "history",
      icon: currentTab === "history" ? <WalletOnIcon /> : <WalletOffIcon />,
    },
    {
      name: "마이",
      value: "my",
      icon: currentTab === "my" ? <MyOnIcon /> : <MyOffIcon />,
    },
    // {
    //   name: "홈",
    //   value: "home",
    // },
    // {
    //   name: "페이",
    //   value: "pay",
    // },
    // {
    //   name: "예약",
    //   value: "reservation",
    // },
    // {
    //   name: "쿠폰",
    //   value: "coupon",
    // },
    // {
    //   name: "마이",
    //   value: "my",
    // },
  ];

  const onClickTab = (value) => {
    const baseUrl = "/user";
    if (value === "history" && !accessToken) {
      setModal(true);
      return;
    }

    if (value === "home") {
      navigate(baseUrl);
    } else {
      navigate(baseUrl + "/" + value);
    }
  };

  return (
    <>
      <Container>
        {iconList.map(({ name, value, icon }, index) => {
          return (
            <IconWrapper
              key={index}
              selected={value === currentTab}
              onClick={() => onClickTab(value)}
            >
              <IconContainer>{icon}</IconContainer>
              {name}
            </IconWrapper>
          );
        })}
      </Container>
      {modal && (
        <DefaultModal
          title={"로그인이 필요합니다."}
          actionText={"로그인"}
          cancelText={"취소"}
          onAction={() =>
            navigate("/user/login", {
              state: { redirectUrl: `/user/history` },
            })
          }
          onCancel={() => setModal(false)}
        />
      )}
    </>
  );
};

export default BottomNavigation;
