
import { Card, Container, Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Btn from '../../../components/common/Btn';
import Switch from '../../../components/common/Swtich';
import ListGroupH from '../../../components/ListGroupH';

export default function AdminLogin( props ){


    return (
        <>
            <Container fluid>
                <Row>
                    <Card className="bg-dark text-white" style={{paddingLeft : "0px", paddingRight : "0px", borderRadius: "0"}}>
                        <Card.Img style={{borderRadius : "0"}} 
                            src="https://placeimg.com/1800x600" alt="Card image" 
                        />

                        <Card.ImgOverlay style={{top:"10%", left:"10%"}}>
                            <Card.Title>
                                <img src="/280x80" alt="1" />
                            </Card.Title>
                            <Card.Text>
                                맛있는 식사를,
                            </Card.Text>
                            <Card.Text>
                                좋은 서비스를
                            </Card.Text>
                            <Card.Text>
                                간편하게 선물하세요.
                            </Card.Text>
                        </Card.ImgOverlay>
                        
                    </Card>
                </Row>
            </Container>

            <Container style={{marginTop:"-40px"}}>
                <Row>
                    <Col sm={{span:4, offset:4}} style={{padding:"20px", zIndex:"1", backgroundColor:"gray"}}>
                        <div className="d-grid gap-1">
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    id="floatingInputCustom"
                                    type="text"
                                    placeholder="아이디"
                                />
                                <label htmlFor="floatingInputCustom">아이디</label>
                            </Form.Floating>
                            <Form.Floating>
                                <Form.Control
                                    id="floatingPasswordCustom"
                                    type="password"
                                    placeholder="비밀번호"
                                />
                                <label htmlFor="floatingPasswordCustom">비밀번호</label>
                            </Form.Floating>
                            <Switch label="자동 로그인" />
                        </div>

                        <Btn type="LONG" btntext="로그인" path="/admin/" />

                    </Col>
                </Row>
                <Row className="center-block">
                    <ListGroupH type='PC' listdata={[{'key':1,'title':'아이디 찾기', "url":"/admin/"},{'key':2,'title':'비밀번호 찾기', "url":"/admin/"},{'key':3,'title':'회원가입', "url":"/admin/"}]}/>
                </Row>
                <Row className="text-center">
                    <Col>
                        <p>이용약관 | 개인정보....</p>
                        <p>[logo] copyright....</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}