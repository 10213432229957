
import { useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';

export default function DaumAddress ( {callback} ) {

    const [address, setAddress] = useState(''); // 주소
    const [addressDetail, setAddressDetail] = useState(''); // 상세주소

    const [isOpenPost, setIsOpenPost] = useState(false);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
      };
    
    const onCompletePost = (data) => {
        let fullAddress = data.address;
        let extraAddr = '';
    
        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddr += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddr !== '' ? ` (${extraAddr})` : '';
        }
    
        setAddress(data.zonecode);
        setAddressDetail(fullAddress);
        setIsOpenPost(false);

        // console.log(data.zonecode);
        // console.log(fullAddress);
    };

    const postCodeStyle = {
        display: 'block',
        position: 'relative',
        top: '0%',
        width: '400px',
        height: '400px',
        padding: '7px',
    };

    return (
        <>
            <button type='button' onClick={onChangeOpenPost}>우편번호 검색</button>
            {isOpenPost  ? (
                <div>
                    <DaumPostcodeEmbed style={postCodeStyle} autoClose onComplete={onCompletePost } />
                </div>
        ) : null}
            <br/>
            <input type='text' id="zipCode" value={address} onChange={callback}/>
            <input type='text' id="address" value={addressDetail} onChange={callback}/>
        </>
    )
}