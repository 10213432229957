
// import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
// import FileInput from '../../../components/common/FileInput';
// import InputBox from '../../../components/InputBox';
// import CheckBox from './../../../components/common/CheckBox';
// import CustomRange from './../../../components/common/CustomRange';
// import StoreItem from './../../../components/common/StoreItem';
// import Btn from './../../../components/common/Btn';
import { useRef } from 'react';
import { useState } from 'react';
import { AlertError, getApiUrl, getImgUrl, PrintConsole, sleep } from './../../function/common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
// import ConsignmentTerms from './../../../components/common/ConsignmentTerms';

import "./ItemCreate.css";

export default function ItemCreate( props ){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    const [data, setData] = useState({
        totalAmount : 1
    });

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [menuType, setmenuType] = useState({menuType : 'DEFAULT'});

    const onCheckData = (e) => {
        if(e.currentTarget.checked){
            setmenuType([
                ...menuType, e.target.value
            ]);
        }
        PrintConsole(menuType);
    }

    const fileUpload = useRef();
    const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

    const onClickUpload = () => {
        fileUpload.current.click();
    }

    const onChangeImg = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile(uploadFile);

            document.getElementById('PreView').style.display='block';
            document.getElementById('PreViewImg').src = URL.createObjectURL(e.target.files[0]);
        }
    }

    async function submit(){  
        document.getElementById('submitBtn').setAttribute("disabled","true");
        
        if(!document.getElementById('s1').checked){
            document.getElementById('submitBtn').removeAttribute("disabled");
            alert('필수 이용약관에 동의해주세요.');
            return false;
        }
        if(data.price >= 1000000){
            alert('교환권은 1,000,000 원 이하만 발행 가능합니다.');
            document.getElementById('submitBtn').removeAttribute("disabled");
            return false;
        }

        let obj;
        let tmpData = { 
            'description' : data.description,
            'menuType' : menuType.menuType,
            'productName' : data.productName,
            'price' : data.price,
        }
        console.log(tmpData);
        obj = new Blob( [JSON.stringify(tmpData)], { type: 'application/json'} );

        var formData = new FormData();
        formData.append('params', obj);
        formData.append('images', file);

        axios.post(
            getApiUrl('stores/'+Parameters[0].storeurl+'/products'), 
            formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization' : 'Bearer ' + localStorage.getItem('accessToken')
                },
            }
        ).then( 
            function(response){
                alert('교환권이 등록되었습니다.');
                navigate('/store/' + localStorage.getItem('storeDomain'));
            }
        )
        .catch(error => {
            console.log(error);
            AlertError(error);
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
        // document.getElementById('submitBtn').removeAttribute("disabled");
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></> 

    return (
        <>
            <div className='demo'>
                <div className="TAddTicketPage">
                    <div className="container-fluid g-0">
                        <nav className="sub-navbar d-flex">
                            <p className="navbar-title">교환권 등록</p>
                            <button type="button" className="btn-clear btn-back" onClick={ () => {navigate(-1) }}>
                                <img className="img-fluid" alt="뒤로가기" src="https://www.flexdaycdn.net/public/images/ticket/back.png"/>
                            </button>
                        </nav>

                        <div className="AddPrdBox py-4-5 px-4-5">
                            <div className="AddPrd-Form">
                                <div className="AddPrd-Formitem pb-5">
                                    <p className="addprd-tit">
                                        1. 상품 대표사진
                                    </p>
                                    <div className="addprd-inputbox px-3">
                                        <div className="PhotoList">
                                            <div className="row">
                                                <div className="File-Box col-4">
                                                    <div className="text-center">
                                                        <img className="img-fluid" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/static/store-main/store_plus_color.png" />
                                                        <img className="img-fluid" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/static/store-main/store_plus.png" />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="PhotoImgbox">
                                                        <img className="img-fluid" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/static/store-main/store_main_img.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="AddPrd-Formitem pb-5">
                                    <p className="addprd-tit">
                                        2. 상품 이름
                                    </p>
                                    <div className="addprd-inputbox px-3">
                                        <input type="text" className="form-control InputType" placeholder="상품 이름을 입력해주세요." />
                                    </div>
                                </div>
                                <div className="AddPrd-Formitem pb-5">
                                    <p className="addprd-tit">
                                        3. 상품 가격(원)
                                    </p>
                                    <div className="addprd-inputbox px-3 g-0 position-relative">
                                        <input type="number" className="form-control InputType PayPrice" placeholder="1,000 ~ 950,000"
                                            onChange="inputNumberCheck()" />
                                        <div className="end-0 me-3 pe-3 position-absolute price-txt top-0">원</div>
                                        <div className="form-text info-txt">
                                            1,000원부터 입력 가능합니다.
                                        </div>
                                    </div>
                                </div>
                                <div className="AddPrd-Formitem pb-5">
                                    <p className="addprd-tit">
                                        4. 상품 설명
                                    </p>
                                    <div className="addprd-inputbox Input-placeholderbox px-3">
                                        <div className="Input-placeholder">
                                            상품에 대한 설명을 입력해주세요. <br /><br />
                                            예) 우엉조림 / 뱅글뱅글 쏘시지 / 잡곡밥 / <br />콩나물무침+배추김치 / 닭개장 / 딸바라떼
                                        </div>
                                        <textarea className="form-control InputType mb-2 h-auto" rows="7"
                                            onInput="ChangeValue(this)"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="AddPrd-Formitem">
                                <button type="button" className="btn btn-default h-auto py-4" data-bs-toggle="modal"
                                    data-bs-target="#Modal-04">완료</button>
                            </div>

                            <div className="modal fade modal-register text-center" data-bs-backdrop="static" id="Modal-04" tabIndex="-1"
                                aria-labelledby="Modal-04-Label" aria-hidden="true">
                                <div className="modal-dialog modal-sm modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <p className="modal-title">교환권을 등록하시겠습니까?</p>
                                        </div>
                                        <div className="modal-body">
                                            교환권 등록이 완료됩니다.
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-clear btn-modal-secondary"
                                                data-bs-dismiss="modal">취소</button>
                                            <button type="button" className="btn btn-clear btn-modal-primary"
                                                data-bs-dismiss="modal">확인</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}