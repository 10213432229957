import { Container, Row, Col } from "react-bootstrap";
import InputBox from "./../../../components/InputBox";
// import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import Btn from "../../../components/common/Btn";

export default function PwReset() {
  const [mode, setMode] = useState("STEP1");

  if (mode === "STEP1") {
    // 기본 페이지 분기
    return (
      <Container>
        <Btn type="NAVBACK" title="비밀번호 재설정" />
        <Row>
          <h5>
            새로운 비밀번호를
            <br />
            입력해 주세요.
          </h5>
        </Row>
        <Row>
          <InputBox
            controlId="Pw"
            type="text"
            label="비밀번호"
            placeholder="새로운 비밀번호를 입력하세요."
            mute=""
          />
        </Row>
        <Row>
          <InputBox
            controlId="PwCk"
            type="text"
            label="비밀번호 확인"
            placeholder="새로운 비밀번호를 한번더 입력하세요."
            mute=""
          />
        </Row>
        <Row>
          <Col className="d-grid">
            <button
              className="btn btn-default"
              onClick={() => setMode("STEP2")}
            >
              비밀번호 변경
            </button>
          </Col>
        </Row>
      </Container>
    );
  } else if (mode === "STEP2") {
    // 다음 단게 분기
    return (
      <Container>
        <Btn type="NAVBACK" title="비밀번호 재설정" />
        <Row>
          <h5>비밀번호가 변경되었습니다.</h5>
        </Row>
        <Row>
          <Btn type="LONG" path="/login" size="" btntext="로그인 하러가기" />
        </Row>
      </Container>
    );
  } else if (mode === "STEP3") {
    // 완료 단게 분기
    return <div></div>;
  } else {
    return null;
  }
}
