import React from "react";
import { css, styled } from "styled-components";
import { color } from "../styles/theme";

const Line = styled.div`
  ${(p) =>
    p.direction === "horizontal"
      ? css`
          height: ${(p) => (p.width ? p.width : "1px")};
          width: 100%;
        `
      : css`
          width: ${(p) => (p.width ? p.width : "1px")};
          height: 100%;
        `}
  background-color: ${(p) => (p.color ? p.color : "transparent")};
  margin-top: ${(p) => (p.$marginTop ? p.$marginTop : 0)};
  margin-bottom: ${(p) => (p.$marginBottom ? p.$marginBottom : 0)};
  margin-right: ${(p) => (p.$marginRight ? p.$marginRight : 0)};
  margin-left: ${(p) => (p.$marginLeft ? p.$marginLeft : 0)};
  ${(p) =>
    p.$verticalHeight &&
    css`
      height: ${p.$verticalHeight};
    `}
`;

const Divider = ({
  width,
  direction = "horizontal",
  color: colorProp = color.gray.d100,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  verticalHeight,
}) => {
  return (
    <Line
      $verticalHeight={verticalHeight}
      width={width}
      direction={direction}
      color={colorProp}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $marginLeft={marginLeft}
      $marginRight={marginRight}
    />
  );
};

export default Divider;
