import React, { useEffect, useState } from "react";
import { css, styled } from "styled-components";
import Text from "../atom/Text";
import { color, elevation, font } from "../styles/theme";
import { DropdownIcon } from "../assets/icons";

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `};
  ${(p) =>
    p.$open &&
    css`
      transform: rotate(180deg);
    `}
  transition: transform .3s ease-in-out;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: absolute;
  top: calc(100% + 2.4rem);
  right: 0;
  background: ${color.primary.white};
  box-shadow: ${elevation.d2};
  padding: 1.6rem;
  border-radius: 1.2rem;
`;

const OptionItem = styled.div`
  ${(p) =>
    !p.$isLast &&
    css`
      border-bottom: 1px solid ${color.gray.d100};
    `}
  word-break: keep-all;
  width: 10rem;
  padding: 0.4rem 0.8rem;
  text-align: center;
`;

const Option = ({ children, value, onClick, isLast }) => {
  return (
    <OptionItem
      $isLast={isLast}
      value={value}
      onClick={() => (onClick ? onClick() : null)}
    >
      {children}
    </OptionItem>
  );
};

const SelectMenu = ({ value, setValue, options = [] }) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(!open);
  };

  const onSelect = (targetValue) => {
    setValue(targetValue);
    if (open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "";
    }
  }, [open]);

  return (
    <>
      {open && <Overlay onClick={() => setOpen(false)} />}
      <Wrapper>
        <Container onClick={onOpen}>
          <Text fontStyle={font.body2} color={color.gray.d600}>
            {options.find((option) => option.value === value).name}
          </Text>
          <IconWrapper $open={open}>
            <DropdownIcon />
          </IconWrapper>
        </Container>
        {open && options.length > 0 && (
          <OptionWrapper>
            {options.map((option, index) => (
              <Option
                value={option.value}
                onClick={() => onSelect(option.value)}
                isLast={index === options.length - 1}
                key={index}
              >
                <Text fontStyle={font.body2} color={color.gray.d700}>
                  {option.name}
                </Text>
              </Option>
            ))}
          </OptionWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default SelectMenu;
