import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import CheckBox from '../../../components/common/CheckBox';

// import ListGroupH from '../../../components/ListGroupH';
// import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState } from 'react';
// import { getImgUrl, LocationUrl } from '../../../function/common';
// import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
// import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
// import SideNav from './../../../components/common/SideNav';

import axios from 'axios';

import { getApiUrl, PrintConsole, setCommas, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

import "./common.css";
import "./PaymentCancel.css";

export default function PaymentList(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        // try{
        //     setNav3();
        // }catch(e){}
        setLoading(false);
    },[])

    if (loading) return <></>

    
    

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
        <div className='demo'>

             <div className="TPaymentCancelPage">
        <div className="container-fluid g-0">

            <nav className="sub-navbar">
                <p className="navbar-title">결제 취소</p>
                <button type="button" className="btn-clear btn-close" onClick={()=>{navigate(-1)}}>
                    <img className="img-fluid" alt="뒤로가기"
                        src="https://www.flexdaycdn.net/public/images/ticket/close2.png" />
                </button>
            </nav>

            <div className="CancelBox Inner">
                <div className="CancelBox-title my-5 text-center">
                    <img className="img-fluid mb-4-5" src="https://www.flexdaycdn.net/public/images/ticket/check_circle.png"
                        alt="" />
                    <p className="PageTit2 mb-5">
                        결제 취소가 <br />
                        완료되었습니다.
                    </p>
                    <p className="PageSub">
                        취소일로부터 영업일 기준7일 이내에 <br />
                        결제수단으로 승인 취소 확인기 가능하며 <br />
                        카드 결제일에 따라 카드사를 통해 환불됩니다.
                    </p>
                </div>

            </div>


            <div className="fixed-bottom px-4-5 pt-3 pb-4">
                <p className="PageSub2 text-center mb-3">
                    더 궁금하신 사항은 고객센터로 문의주세요.
                </p>
                <div className="CancelConfirm d-flex justify-content-between">
                    <a href="#none" className="btn btn-default">
                        홈으로
                    </a>
                    <a href="tel:010-1234-5678" className="btn btn-light">
                        고객센터 연결
                    </a>
                </div>
            </div>


        </div>
    </div>

    </div>

        </>
    )
}