import React from "react";
import styled from "styled-components";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";
import { localeStringFormatter } from "../../../../components/ver1.5/utils";
import { RowContainer } from "../../../../components/ver1.5/styles/Ver2GlobalStyles";

const ContentWrapper = styled(RowContainer)`
  width: 100%;
  height: 12rem;
  background: ${color.gray.d50};
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.8rem;
`;

const CouponHistoryInfo = ({
  productType,
  balance,
  rewardBalance,
  remainingCount,
}) => {
  const isCountCoupon = productType?.code === 3;
  return (
    <ContentWrapper>
      <Container>
        <Text fontStyle={font.body2}>
          {isCountCoupon ? "잔여횟수" : "잔액"}
        </Text>
        <Text fontStyle={font.subtitle1}>
          {localeStringFormatter(
            isCountCoupon ? remainingCount : balance,
            isCountCoupon ? "회" : "원"
          )}
        </Text>
      </Container>
      {Boolean(rewardBalance) && (
        <Container>
          <Text fontStyle={font.body2}>적립금 잔액</Text>
          <Text fontStyle={font.subtitle1}>
            {localeStringFormatter(rewardBalance, "원")}
          </Text>
        </Container>
      )}
    </ContentWrapper>
  );
};

export default CouponHistoryInfo;
