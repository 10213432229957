import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "../../pages/franchisee/user/Join";

export default function ConsignmentTerms({ customBtn }) {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow() {
    setFullscreen("lg-down");
    setShow(true);
  }

  return (
    <>
      {customBtn ? (
        customBtn(handleShow)
      ) : (
        <Typography
          size={"13px"}
          weight={"var(--Regular)"}
          color={"var(--ColorTypeD)"}
          onClick={() => handleShow()}
        >
          보기
        </Typography>
      )}
      {/* <FontAwesomeIcon icon={faAngleRight} onClick={() => handleShow()}/> */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              주식회사 플렉스데이
              <br />
              [상품권 발행 및 판매 위탁 특약]
            </h3>
            <small>제정 2022.05.10.</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>
            OOOO(이하 ‘갑’이라 한다)와 주식회사 플렉스데이(이하 ‘을’이라 한다)는
            플렉스데이 서비스(이하 ‘서비스’라고 한다) 이용약관과 함께 아래와
            같이 상품권의 위탁발행 및 판매대행 특약을 체결한다. <br />
            <br />
            제 1조 [목적]
            <br />
            이 계약의 목적은 서비스 이용약관에 부가하여 ‘갑’이 의뢰한 상품권의
            발행과 판매관리 업무를 ‘을’에게 위탁함에 있어서 필요한 사항을 정함에
            있다. <br />
            <br />
            제 2조 [상품권의 발행]
            <br />
            ‘을’은 본 계약에 정한 바에 따라 ‘서비스’ 내 ‘갑’이 생성한 스토어에서
            ‘갑’이 설정한 판매가격으로 판매하여야 한다. 단, ‘갑’과 ‘을’은 협의에
            따라 판매장소, 가격을 변경할 수 있다. <br />
            <br />
            제 3조 [위탁 발행 및 판매 상품권의 종류]
            <br />
            ‘을’이 판매할 수 있는 상품권은 ‘갑’은 상품의 종류와 가격을 정하여
            스토어에 게시한 상품에 한한다. 단, 게시된 내용에 따라 ‘을’은
            스토어에 게시된 종류의 상품권의 발행과 판매를 거절할 수 있다.
            <br />
            <br />
            제 4조 [위탁업무의 양도 및 위임금지]
            <br />
            ‘을’은 ‘갑’과 체결한 위탁업무를 타인에게 양도하거나 위임할 수 없다.
            다만, ‘갑’에게 사전에 신고하여 협의하였을 때에는 그러하지 아니하다.
            <br />
            <br />
            제 5조 [상품권의 청구 및 교부관리]
            <br />
            ‘을’은 상품권의 판매내역 및 정산내역을 ‘갑’이 확인할 수 있도록
            서비스 내 기능을 제공한다.
            <br />
            <br />
            제 6조 [발행 및 판매 대행 수수료]
            <br />
            ‘을’의 상품권 발행 및 판매 대행의 수수료율은 5%로 한다. 이때
            부가가치세는 별도로 한다.
            <br />
            <br />
            제 7조 [대금의 납입]
            <br />
            1. ‘을’은 매주 월요일부터 일요일까지 사용 완료된 상품권에 대해
            서비스 내 공지된 정산일에 6조에서 정한 수수료율을 제외한 금액을
            ‘갑’이 지정한 은행 예금계좌에 입금한다. <br />
            2. 1항의 입금일이 공휴일이나 휴무일 인 경우에는 그 다음 영업일에
            입금한다. <br />
            3. 사용완료된 대금이 정상적으로 확정되었는데도 ‘을’의 착오로 지정된
            일자에 지급되지 아니한 경우에는 지급하여야할 금액의 법정이자에
            해당하는 이자금액을 함께 ‘갑’에게 지급한다. <br />
            <br />
            제 8조 [계약의 해지]
            <br />
            1. ‘갑’은 다음 각 호에 해당하는 경우에는 발행 및 판매 위탁 계약을
            해지할 수 있다. <br />
            1) 동 계약에 의한 판매자의 준수 사항을 이행하지 아니할 때<br />
            2) 제 4조의 약정에 의한 위탁업무를 타인에게 양도하거나 위임할 수
            없음에도 불구하고 위임 또는 양도한 때<br />
            2. ‘을’은 다음 각 호에 해당하는 경우에는 발행 및 판매 위탁 계약을
            해지할 수 있다. <br />
            1) 본 약정을 준수하지 않을 때<br />
            2) ‘갑’의 신용 상태가 현저히 악화되거나 상품권에 상응하는 재화나
            서비스제공이 불가하다고 판단될 때<br />
            3) 상품권의 발행 및 판매 위탁 계약과 관련된 사항에 대한 고객의
            항의로 인해 ‘을’의 이미지가 손상되었다고 판단될 때<br />
            3. 계약을 해지할 경우에는 그 사유 및 일시 등을 명시하여 지체없이
            통지한다. <br />
            4. 기타 부득이한 사정으로 해지할 경우, 그 내용을 통지하고 상당기간
            후 계약을 해지할 수 있다. 이 경우 손해가 있으면 이를 배상해야 한다.{" "}
            <br />
            <br />
            제 9조 [판매 금액의 제한]
            <br />
            ‘을’은 자체의 판단에 따라 ‘갑’의 신용상태 등을 고려하여 상품권의
            판매한도를 정할 수 있다. 이 때는 그 사유와 한도액을 ‘갑’에게
            통보한다. <br />
            <br />
            제 10조 [협력의무]
            <br />
            1. ‘갑’과 ‘을’은 본 계약에 기재된 업무의 체결 및 발전을 위해 협력할
            의무를 진다.
            <br />
            2. 기타 협의되지 않은 사항은 문서로써 협의하고 상호 동의 하에
            시행한다. <br />
            <br />
            제 11조 [해석]
            <br />
            이 계약에 명시되지 아니한 사항 또는 해석상의 이의에 관하여는 ‘갑’,
            ‘을’ 쌍방의 협의에 의하여 정한다.
            <br />
            <br />
            제 12조 [계약기간]
            <br />
            이 계약의 유효기간은 ‘을’이 ‘갑’의 회원가입을 승인한 때로부터 ‘갑’의
            회원탈퇴(계약해지) 의사표시에 따라 ‘을’이 탈퇴처리를 수락한 때
            까지로한다.
            <br />
          </pre>
        </Modal.Body>
      </Modal>
    </>
  );
}
