import React from "react";
import { styled } from "styled-components";
import { color, font } from "../styles/theme";
import Text from "../atom/Text";
import Serviceterms from "../../common/ServiceTerms";
import Userterms2 from "../../common/UserTerms2";
import ConsignmentTerms from "../../common/ConsignmentTerms";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  background: ${color.gray.d50};
  padding: 3.2rem 1.6rem;
  padding-bottom: 8rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
`;

const Footer = () => {
  return (
    <Container>
      <Text fontStyle={font.body2} color={color.gray.d700}>
        (주)플렉스데이
      </Text>
      <Text fontStyle={font.caption3} color={color.gray.d400}>
        <pre>
          {`(48548)부산 남구 전포대로 133Wework BIFC 15층(주)플렉스데이
대표이사 : 오명재  사업자등록번호 : 654-86-02546
통신판매업신고 : 제 2022-부산남구-0756 호
대표전화 : 051-621-0203 이메일 : support@flex.day`}
        </pre>
      </Text>
      <Row>
        <Serviceterms
          customBtn={(onClick) => (
            <Text
              fontStyle={font.body3}
              color={color.gray.d400}
              onClick={() => onClick()}
            >
              이용약관
            </Text>
          )}
        />
        <Userterms2
          customBtn={(onClick) => (
            <Text
              fontStyle={font.body3}
              color={color.gray.d400}
              onClick={() => onClick()}
            >
              개인정보처리방침
            </Text>
          )}
        />
        <ConsignmentTerms
          customBtn={(onClick) => (
            <Text
              fontStyle={font.body3}
              color={color.gray.d400}
              onClick={() => onClick()}
            >
              상품권 발행 및 판매 위탁 특약
            </Text>
          )}
        />
      </Row>
    </Container>
  );
};

export default Footer;
