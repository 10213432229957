import { useNavigate, useSearchParams } from 'react-router-dom';
import { AlertError, EmailCheck, getApiUrl, PrintConsole, sleep } from '../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { GetUserMe } from '../../api/ApiGetUser';
import sha256 from 'sha256';

export default function UserInformation(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    let clientId = '';
    
    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if( window.location.href.indexOf("/user/") !== -1 ){
        clientId = '10000';
        if( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null ){
            alert('로그인이 필요한 서비스 입니다.');
            navigate('/user/login');
        }
    }else if(window.location.href.indexOf("/store/") !== -1){
        clientId = '10001';
        if( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null ){
            alert('로그인이 필요한 서비스 입니다.');
            navigate('/store/login');
        }
    }

    const [isCheck, setIsCheck] = useState([]);
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);

        console.log(isCheck);

        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    function getData() {
        let variables = {
            "clientId": clientId
        }
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('users/me')
                , variables
                , axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    console.log(response);
                    setData(response.data);

                }
            )
            if (response) {
                return resolve(response);
            }
            // reject(new Error("Request is failed"));
        });
    };

    async function submit(){

        document.getElementById('submitBtn').setAttribute("disabled","true");

        if(!EmailCheck(document.getElementById('userEmail').value)){
            alert('이메일 양식이 아닙니다.');
            document.getElementById('submitBtn').removeAttribute("disabled");
            return false;
        }

        if( document.getElementById('cgPassword').value.length < 4){
            alert('최소 비밀번호 길이는 4글자 입니다.');
            document.getElementById('submitBtn').removeAttribute("disabled");
            return false;
        }

        let obj = { 
            email : document.getElementById('userEmail').value
        }

        axios.put(
            getApiUrl('users/me/email'), 
            obj, 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){

                    obj = { 
                        'passwordToBeChanged' : sha256(document.getElementById('cgPassword').value),
                        'nowPassword' : sha256(document.getElementById('nowPassword').value)
                    }
            
                    console.log(obj);
            
                    axios.put(
                        getApiUrl('users/me/password'), 
                        obj, 
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
                    ).then(
                        function(response){
                            if(response.status === 200){
                                
                                obj = {
                                    "appPush": document.getElementById('appPush').checked,
                                    "phoneText": document.getElementById('phoneText').checked,
                                    "email": document.getElementById('email').checked,
                                    "phone": document.getElementById('phone').checked,
                                    "mail": document.getElementById('mail').checked
                                }
                                                        
                                console.log(obj);
                        
                                axios.put(
                                    getApiUrl('users/me/alarm-setting'), 
                                    obj, 
                                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
                                ).then(
                                    function(response){
                                        if(response.status === 200){
                                            
                                            alert('수정이 완료하였습니다.');
                                            // navigate('/store/'+localStorage.getItem('storeDomain'));
                                        }
                                    }
                                ).catch(function(error){
                                    AlertError(error);
                                    document.getElementById('submitBtn').removeAttribute("disabled");
                                });
                            }
                        }
                    ).catch(function(error){
                        AlertError(error);
                        document.getElementById('submitBtn').removeAttribute("disabled");
                    });
                }
            }
        ).catch(function(error){
            AlertError(error);
            document.getElementById('submitBtn').removeAttribute("disabled");
        });

        

        

        document.getElementById('submitBtn').removeAttribute("disabled");
    }

    
    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        getData();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container className='gx-0'>
                <Btn type="NAVBACK" title="내 정보 수정" />

                <Row className='gx-0'>
                    <Col xs={{span:10, offset:1}} className='mt-5'>
                        <h5>{data && data.name} 님</h5>
                        <span>회원정보를 수정할 수 있습니다.</span>
                        {/* <hr className='mt-4'/> */}
                    </Col>
                </Row>
                
                <Row className='gx-0'>
                    <Col className='mt-3' xs={{span:10, offset:1}}>
                        <Form.Group className="xs-12" controlId="userEmail">
                            <Form.Label>이메일 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" onBlur={() => {console.log('email')}}  placeholder="이메일 주소를 입력해 주세요." defaultValue={data && data.email} onChange={onChangeData} />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                
                <Row className='gx-0'>
                    <Col className='mt-3' xs={{span:10, offset:1}}>
                        <Form.Group className="xs-12" controlId="nowPassword">
                            <Form.Label>현재 비밀번호 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="password" onBlur={() => {console.log('password')}}  placeholder="로그인에 이용중인 비밀번호를 입력해주세요." onChange={onChangeData} />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='gx-0'>
                    <Col className='mt-3' xs={{span:10, offset:1}}>
                        <Form.Group className="xs-12" controlId="cgPassword">
                            <Form.Label>변경 비밀번호 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="password" onBlur={() => {console.log('password')}}  placeholder="로그인에 이용할 비밀번호를 입력해 주세요." onChange={onChangeData} />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='gx-0'>
                    <Col xs={{span:10, offset:1}} className='mt-3'>
                        <label>플렉스데이에서 제공하는 각종 혜택 및 이벤트,<br/>사은품에 대한 안내를 받을 수 있습니다.</label>
                    </Col>
                    <Col xs={{span:10, offset:1}} className='d-flex flex-wrap'>
                        <div className='p-2'>
                            <Form.Check type='checkbox' id='appPush' label='앱푸쉬' onChange={handleClick} defaultChecked={data.alarm && data.alarm.appPush }/>
                        </div>
                        <div className='p-2'>
                            <Form.Check type='checkbox' id='phoneText' label='문자' onChange={handleClick} defaultChecked={data.alarm && data.alarm.phoneText }/>
                        </div>
                        <div className='p-2'>
                            <Form.Check type='checkbox' id='email' label='이메일' onChange={handleClick} defaultChecked={data.alarm && data.alarm.email }/>
                        </div>
                        <div className='p-2'>
                            <Form.Check type='checkbox' id='phone' label='전화' onChange={handleClick} defaultChecked={data.alarm && data.alarm.phone }/>
                        </div>
                        <div className='p-2'>
                            <Form.Check type='checkbox' id='mail' label='우편' onChange={handleClick} defaultChecked={data.alarm && data.alarm.mail }/>
                        </div>
                    </Col>
                </Row>

                <Row className='gx-0'>
                    <Col xs={{ span:10, offset:1 }}>
                        <div className="d-grid gap-1 mt-3 mb-3">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ submit }>
                                정보 수정
                            </Button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}