import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import "./main.css";

import SideNav from "./components/common/SideNav";

import Login from "./pages/franchisee/user/Login";
import FindId from "./pages/franchisee/user/FindId";
import FindPw from "./pages/franchisee/user/FindPw";
import Join from "./pages/franchisee/user/Join";
import NotFound from "./pages/NotFound";
import PwReset from "./pages/franchisee/user/PwReset";
import PromotionList from "./pages/promotion/PromotionList";
import PromotionRequest from "./pages/promotion/PromotionRequest";
import SalesJoin from "./pages/salesman/Join";
import Secession from "./pages/franchisee/user/Secession";
import Search from "./pages/user/Search";
import Certification from "./pages/franchisee/user/Certification";
import Success from "./pages/pg/Success";
import Fail from "./pages/pg/Fail";
import UserLogin from "./pages/user/Login";
import Error from "./pages/Error";
import TicketType from "./pages/ticket/TicketType";
import Injeiosqr from "./pages/injeiosqr";
import NotFoundNew from "./pages/ver1.5/Common/NotFound";
import ErrorNew from "./pages/ver1.5/Common/Error";

import DemoRoutes from "./routes/DemoRoutes";
import Demo2Routes from "./routes/Demo2Routes";
import TmpRoutes from "./routes/TmpRoutes";
import AdminRoutes from "./routes/AdminRoutes";
// import UserRoutes from "./routes/UserRoutes";
import StoreRoutes from "./routes/StoreRoutes";
import PosRoutes from "./routes/PosRoutes";
import Pos2Routes from "./routes/Pos2Routes";
import StaffRoutes from "./routes/StaffRoutes";
import WorkplaceRoutes from "./routes/WorkplaceRoutes";
import CouponRoutes from "./routes/CouponRoutes";
import MsRoutes from "./routes/MsRoutes";
import RenewRoutes from "./routes/RenewRoutes";
import Ver2GlobalStyles from "./components/ver1.5/styles/Ver2GlobalStyles";
import Main from "./pages/ver1.5/Main";
import Header from "./components/ver1.5/Header";
import BottomNavigation from "./components/ver1.5/BottomNavigation";
import ScrollTop from "./components/ver1.5/hooks/useScrollTop";
import UserProvider from "./contexts/UserContext";
import NewAlarmTalk from "./pages/ver1.5/NewAlarmTalk";
import Receipt from "./pages/ver1.5/Receipt";
import ReceiptDetail from "./pages/ver1.5/Receipt/components/ReceiptDetail";

function App() {
  if (process.env.NODE_ENV !== "production") {
    console.log("domainType : " + localStorage.getItem("domainType"));
  }
  const routes = [
    {
      // 데모 (+ baemin)
      rootPath: "/demo",
      routes: DemoRoutes,
    },
    {
      // 데모2
      rootPath: "/demo2",
      routes: Demo2Routes,
    },
    {
      // 더미 시안용 (tmp)
      rootPath: "/tmp",
      routes: TmpRoutes,
    },
    {
      // 관리자
      rootPath: "/admin",
      routes: AdminRoutes,
    },
    // {
    //   // 유저
    //   rootPath: "/user",
    //   routes: UserRoutes,
    // },
    {
      // 스토어 (+ App)
      rootPath: "/store",
      routes: StoreRoutes,
    },
    {
      // Pos
      rootPath: "/pos",
      routes: PosRoutes,
    },
    {
      // Pos 계산기 버전 (pos2)
      rootPath: "/pos2",
      routes: Pos2Routes,
    },
    {
      // Staff
      rootPath: "/staff",
      routes: StaffRoutes,
    },
    {
      // Workplace
      rootPath: "/workplace",
      routes: WorkplaceRoutes,
    },
    {
      // Coupon
      rootPath: "/coupon",
      routes: CouponRoutes,
    },
    {
      // 머니쇼 (Ms)
      rootPath: "/ms",
      routes: MsRoutes,
    },
  ];

  if (
    window.location.pathname.startsWith("/user") ||
    window.location.pathname === "/" ||
    window.location.pathname.startsWith("/newAlarmTalk") ||
    window.location.pathname.startsWith("/kiosk")
  ) {
    return (
      <UserProvider>
        <Ver2GlobalStyles />
        <Router>
          <ScrollTop />
          <Routes>
            <Route
              path={"/user" + RenewRoutes.path}
              element={RenewRoutes.element}
            >
              {RenewRoutes.children.map(({ path, element, children }) => {
                if (children) {
                  return (
                    <Route key={path} path={path} element={element}>
                      {children.map((child) => (
                        <Route
                          key={child.path}
                          path={child.path}
                          element={child.element}
                        />
                      ))}
                    </Route>
                  );
                } else {
                  return <Route key={path} path={path} element={element} />;
                }
              })}
            </Route>
            <Route
              path="/newAlarmTalk/qrcode/:code"
              element={
                <>
                  <Header type={"default"} title={"E쿠폰 확인"} />
                  <NewAlarmTalk />
                </>
              }
            />
            <Route
              path="/newAlarmTalk/qrcode/test/:code"
              element={
                <>
                  <Header type={"default"} title={"E쿠폰 확인"} />
                  <NewAlarmTalk />
                </>
              }
            />
            <Route
              path="/kiosk/payment/:paymentLink"
              element={
                <>
                  <Header
                    type={"closable"}
                    title={"구매내역 확인"}
                    closeIconPosition={"right"}
                    customOnClick={() => window.location.replace("/user")}
                  />
                  <Receipt />
                </>
              }
            />
            <Route
              path="/kiosk/payment/:paymentLink/detail"
              element={
                <>
                  <Header
                    type={"closable"}
                    title={"카드 영수증"}
                    closeIconPosition={"right"}
                  />
                  <ReceiptDetail />
                </>
              }
            />
            <Route
              path="/"
              element={
                <>
                  <Header type={"main"} title={"FLEXDAY 선물하기"} />
                  <Main />
                  <BottomNavigation />
                </>
              }
            />
            <Route path="/error" element={<ErrorNew />} />
            <Route path="/user/error" element={<ErrorNew />} />
            <Route path="/404" element={<NotFoundNew />} />
            <Route path="*" element={<NotFoundNew />} />
          </Routes>
        </Router>
      </UserProvider>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <Router>
            <SideNav
              type="RIGHT"
              id="RightSilder"
              domain={localStorage.getItem("domainType")}
            />

            <Routes>
              {routes.map(({ rootPath, routes }) => {
                return (
                  <Route path={rootPath} key={rootPath}>
                    {routes.map(({ path, element, children }) => {
                      if (children) {
                        return (
                          <Route key={path} path={path} element={element}>
                            {children.map(({ path, element }) => (
                              <Route key={path} path={path} element={element} />
                            ))}
                          </Route>
                        );
                      } else {
                        return (
                          <Route key={path} path={path} element={element} />
                        );
                      }
                    })}
                  </Route>
                );
              })}
              {/* 티켓 */}
              <Route path="/alimtalk/qrcode/:code" element={<TicketType />} />
              <Route path="/inje-ios-qr" element={<Injeiosqr />} />
              <Route path="/login" element={<UserLogin />} />
              <Route path="/salesman/join" element={<SalesJoin />} />
              <Route
                path="/promotion/promotionlist"
                element={<PromotionList />}
              />
              <Route
                path="/promotion/promotionrequest"
                element={<PromotionRequest />}
              />
              <Route path="/secession" element={<Secession />} />
              <Route path="/findid/:type" element={<FindId />} />
              <Route path="/findpw/:type" element={<FindPw />} />
              <Route path="/pwreset" element={<PwReset />} />
              <Route path="/join" element={<Join />} />
              <Route path="/certification" element={<Certification />} />
              <Route path="/app/store/login/" element={<Login />} />
              <Route path="/home" element={<Search />} />
              <Route path="/pg/success" element={<Success />} />
              <Route path="/pg/fail" element={<Fail />} />
              <Route path="/error" element={<Error />} />
              <Route path="/404" element={<NotFound />} />
              <Route
                path="/"
                element={
                  localStorage.getItem("domainType") === "STORE" ? (
                    <Login />
                  ) : (
                    <></>
                  )
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </header>
      </div>
    );
  }
}

export default App;
