import { Container, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const UnderReview = () => {
  const navigate = useNavigate();

  return (
    <div className="StoreAlertPage">
      <Container className="g-0">
        <div className="Store-AlertPage Inner text-center">
          <Image
            fluid
            className="Emoji mb-4"
            src="https://www.flexdaycdn.net/public/images/static/store-register/smile-emoji.png"
          />
          <p className="StoreAlert-tit mb-1">이 매장은 심사중이에요!</p>
          <p className="StoreAlert-desc">
            매장 심사에는 평균 3~4일이 소요됩니다.
            <br />
            더 완벽한 매장을 선보이기 위한 단계이니
            <br />
            조금만 기다려주세요!
          </p>
        </div>
        <div
          className="fixed-bottom responsiveInner text-center m-auto"
          style={{ width: "90%" }}
        >
          <p className="StoreAlert-sub mb-4">
            플렉스 스토어에 입점한 다른 매장이 궁금하시다면?
            <br />
            아래 버튼을 눌러주세요.
          </p>
          <Button
            as="a"
            variant="primary"
            className="mb-5"
            onClick={() => navigate("/user")}
          >
            다른 매장도 둘러보기
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default UnderReview;
