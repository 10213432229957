import React from "react";
import TextField from "../../../../../components/ver1.5/atom/TextField";
import { css, styled } from "styled-components";
import { color } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";

const Container = styled.div`
  position: relative;
`;

/**
 * GiftInputProperty
 * @typedef {object} GiftInputProperty
 * @property {'input'|'textarea'} elementType
 * @property {string} value
 * @property {void=} setValue
 * @property {void=} onChange
 * @property {string} placeholder
 * @property {any=} width
 * @property {string=} marginTop
 * @property {string=} marginBottom
 * @property {string=} marginLeft
 * @property {string=} marginRight
 * @property {number=} maxLength
 * @property {number=} rows
 * @property {boolean} autoHeight
 * @property {boolean} showCount
 *
 * @param {GiftInputProperty}
 */
const GiftInput = ({
  placeholder,
  value,
  setValue,
  onChange,
  elementType = "input",
  width,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  onlyNumber = false,
  maxLength,
  autoHeight,
  rows,
  showCount,
  disabled,
  inputMode,
}) => {
  return (
    <Container>
      <TextField
        placeholder={placeholder}
        value={value}
        setValue={onChange ? null : setValue}
        onChange={setValue ? null : onChange}
        padding={"1.7rem 4.4rem 1.7rem 1.6rem"}
        backgroundColor={color.gray.d50}
        inputStyles={css`
          width: ${width ?? "12.8rem"};
          border-radius: 1.2rem;
        `}
        elementType={elementType}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        onlyNumber={onlyNumber}
        maxLength={maxLength}
        rows={rows}
        autoHeight={autoHeight}
        inputMode={inputMode}
        disabled={disabled}
      />
      {showCount && maxLength && (
        <Text
          styles={css`
            font-family: "Pretendard Regular", sans-serif;
            font-size: 1.4rem;
            color: ${color.gray.d500};
            position: absolute;
            right: 1.6rem;
            bottom: 1.8rem;
          `}
        >{`${value.length} / ${maxLength}`}</Text>
      )}
    </Container>
  );
};

export default GiftInput;
