import React from "react";
import { StarIcon } from "../../../../../../components/ver1.5/assets/icons";
import { color } from "../../../../../../components/ver1.5/styles/theme";

const StarIcons = ({ grade, iconSize }) => {
  return (
    <div>
      {Array.from({ length: 5 }, (_, i) => i + 1).map((i) => {
        let type =
          i < grade ? "default" : i === Math.round(grade) ? "default" : "zero";
        return (
          <StarIcon
            key={i}
            color={type === "zero" ? color.gray.d100 : null}
            width={iconSize}
            height={iconSize}
          />
        );
      })}
    </div>
  );
};

export default StarIcons;
