import React, { useEffect } from "react";
import { styled } from "styled-components";
import { color, font } from "../styles/theme";
import ModalLayout from "../molecule/ModalLayout";
import Text from "../atom/Text";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
  padding: 0 0.8rem;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.4rem;
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  height: 5.6rem;
  border-top: 1px solid ${color.gray.d100};
`;

const Btn = styled.button`
  width: 100%;
  flex-shrink: 0;
  padding: 1.6rem 0;
`;

const OneBtnModal = ({ onAction, text }) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  return (
    <ModalLayout onClickOverlay={onAction}>
      <ContentContainer>
        <DescriptionWrapper>
          <Text fontStyle={font.body1} color={color.gray.d900}>
            {text}
          </Text>
        </DescriptionWrapper>
      </ContentContainer>
      <BtnContainer>
        <Btn onClick={() => onAction()}>
          <Text fontStyle={font.body1} color={color.functional.link.d600}>
            확인
          </Text>
        </Btn>
      </BtnContainer>
    </ModalLayout>
  );
};

export default OneBtnModal;
