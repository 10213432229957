
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
export default function Secession(){

    function submit(){

    }

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="사업장 탈퇴" />
                <Row>
                    <Col>
                        <h4>유의사항</h4>
                        <h6>사업장 탈퇴 전에 꼭 확인하세요.</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul className='UlStyle'>
                            <li>플렉스데이 사업장 탈퇴시 플레스데이 사업장 서비스에 탈퇴되며, 회사가 운영하는 서비스(선물하기)의 이용은 가능합니다.</li>
                            <li>플렉스데이 사업장 탈퇴시 플레스데이 사업장 서비스에 탈퇴되며, 회사가 운영하는 서비스(선물하기)의 이용은 가능합니다.</li>
                            <li>플렉스데이 사업장 탈퇴시 플레스데이 사업장 서비스에 탈퇴되며, 회사가 운영하는 서비스(선물하기)의 이용은 가능합니다.</li>
                            <li>플렉스데이 사업장 탈퇴시 플레스데이 사업장 서비스에 탈퇴되며, 회사가 운영하는 서비스(선물하기)의 이용은 가능합니다.</li>
                            <li>플렉스데이 사업장 탈퇴시 플레스데이 사업장 서비스에 탈퇴되며, 회사가 운영하는 서비스(선물하기)의 이용은 가능합니다.</li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div key='default-checkbox'>
                            <Form.Check type='checkbox' id='default-checkbox' label='유의사항을 모두 확인하였으며, 사업장 탈퇴시 정보 소멸에 동의합니다.'/>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="d-grid gap-1">
                        <Button variant="primary" size="lg" onClick={submit}>
                            사업장 탈퇴하기
                        </Button>
                    </div>
                </Row>
            </Container>
        </>
    )
}