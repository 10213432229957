import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { getApiUrl } from '../../function/common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import StoreItem from './../../components/common/StoreItem';
import CustomAlert from './../../components/common/CustomAlert';
import StoreList from './../../components/common/StoreList';
import { PrintConsole } from './../../function/common';

export default function Search(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    if(process.env.NODE_ENV !== 'production'){
        console.log(localStorage.getItem('domainType'));
        console.log(localStorage.getItem('name'));
    }

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }

    const [res, setRes] = useState();
    function getData() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('common/business-class-type') ).then(
                function(response){
                    if(process.env.NODE_ENV !== 'production'){
                        console.log(response.data);
                    }
                    setRes(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
            // reject(new Error("Request is failed"));
        });
    };

    const [store, setStore] = useState();

    function getStore() {
        let query = '';
        
        if (document.querySelector('#SEARCH') !== null) {
            query = '?storeName=' + document.querySelector('#SEARCH').value;
        }
        PrintConsole(query);

        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('stores' + query) ).then(
                function(response){
                    if(process.env.NODE_ENV !== 'production'){
                        console.log(response.data);
                    }
                    setStore(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
            // reject(new Error("Request is failed"));
        });
    };


    const [data, setData] = useState([]);

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        if(process.env.NODE_ENV !== 'production'){
            console.log(data);
        }
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        getData();
        getStore();

        //데이터 로딩 완료
        setLoading(false);
    }, []);

    

    if (loading) return <></>
    // console.log(store);
    if(process.env.NODE_ENV !== 'production'){
        console.log(res);
    }
    
    return (
        <>
            <Container>
                <Row>
                    <Btn type="SEARCHBACK" id='SEARCH' />
                    {/* <Col>
                        <Row className='AlignCenter'>
                            <Col xs={2}>
                                <label>지역</label>
                            </Col>
                            <Col xs={5}>
                                <Form.Select>
                                    <option>시도</option>
                                </Form.Select>
                            </Col>
                            <Col xs={5}>
                                <Form.Select>
                                    <option>시군구</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
                {/* <Row className='d-flex'>
                    <Col xs={2} className='align-self-center text-center'>
                        <label>가격대</label>
                    </Col>
                    <Col xs={10}>
                        <InputGroup className="mb-3">
                            <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                            <InputGroup.Text> ~ </InputGroup.Text>
                            <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                        </InputGroup>
                    </Col>
                </Row> */}
                {/* <Row>
                    <Col>
                        <Form.Group controlId="businessClass">
                            <Form.Select size="lg" onChange={onChangeData}>
                            {
                                res && res.map( row => (
                                    <option key={row} value={row}>{row}</option>
                                ))
                            }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row> */}
                
                <Row>
                    <div className="d-grid gap-1 mt-2">
                        <Button variant="primary" size="lg" onClick={() => {getStore()}}>
                            조회하기
                        </Button>
                    </div>
                </Row>

                <Row className='mt-1'>
                    <Col xs={12}>
                    {
                        store && store.data.length === 0 ? <CustomAlert type="ALERT"text="등록된 상품이 없어요 스토어에 상품을 등록해 주세요." /> :
                            store && store.data.map( row => (
                                <StoreList key={row.domain} id={row.storeName} storeName={row.storeName} reviewCount={row.reviewCount} storeGrade={row.grade} businessClassType={row.businessClassType} img={row.logoUrl} domain={row.domain}/>
                            ))
                    }
                    </Col>
                </Row>

            </Container>
        </>
    )
}