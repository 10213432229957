
import { Row, InputGroup, Form, Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

export default function SearchForm ( props ){

    let label = props.label;
    if( label === undefined ){
        label = "props.label을 입력해 주세요.";
    }
    return (
        <>
            <Row>
                <Col xs={4} >
                    <label>가맹점 회원검색</label>
                    <InputGroup className="mb-2">
                        <InputGroup.Text>검색어</InputGroup.Text>
                        
                        <Form.Select >
                            <option>Default select</option>
                        </Form.Select>
                        <Form.Control aria-label="Search" />
                        <Button variant="outline-secondary" id="button-addon2">
                            검색
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        </>
    )
}