import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getApiUrl } from "../../function/common";
import { Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

const MyOutlet = () => {
  const { accessToken } = useContext(UserContext);

  const isLoggedIn = Boolean(accessToken);
  const navigate = useNavigate();
  const [me, setMe] = useState({ name: "회원", email: "" });

  const getMe = async () => {
    const res = await axios
      .get(
        getApiUrl("users/me"),
        { clientId: "10000" },
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken),
        (axios.defaults.headers.common["ContentType"] =
          "application/json; charset=utf-8")
      )
      .then((res) => setMe(res.data));
    return res;
  };

  useEffect(() => {
    if (!isLoggedIn) {
      // navigate("/user/login", {
      //   replace: true,
      //   state: { redirectUrl: "/user" },
      // });
      return;
    }
    getMe();
    // eslint-disable-next-line
  }, [isLoggedIn]);

  return <Outlet context={{ me, refetch: getMe }} />;
};

export default MyOutlet;
