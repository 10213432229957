import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import CheckBox from '../../../components/common/CheckBox';

// import ListGroupH from '../../../components/ListGroupH';
// import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState } from 'react';
// import { getImgUrl, LocationUrl } from '../../../function/common';
// import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
// import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
// import SideNav from './../../../components/common/SideNav';

import './Login.css';
import { PrintConsole, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

export default function Login(){
    const navigate = useNavigate();

    const [account, setAccount] = useState({
        Id: "",
        Password: "",
        clientId: '10001'
    });

    const onChangeAccount = (e) => {
        //console.log(e.target.value);
        setAccount({
          ...account,
          [e.target.id]: e.target.value,
        });
    };

    useEffect( () => {
        // localStorage.removeItem('accessToken');

        if( localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null ){
            //토큰 있을때 추가 작업할 일 있을 시 사용
            window.location.href='/demo/store/'+ localStorage.getItem('storeDomain');
        }else{
            // navigate('/user/search');
        }

        // try{
        //     setNav3();
        // }catch(e){}
    })

    const loginBtn = async () => {

        try{
            document.getElementById('submitBtn').setAttribute("disabled","true");

            await GetAuthorize(account);
                        
            sleep(500);
            PrintConsole("localStorage : " + localStorage.getItem('accessToken'));
            PrintConsole("localStorage : " + localStorage.getItem('refreshToken'));
            
            let resUserMe = await GetUserMe(account);
            
            sleep(500);
            try{
                localStorage.setItem('domainType', resUserMe.data.domainType);
                localStorage.setItem('name', resUserMe.data.name);
                localStorage.setItem('myStores' , JSON.stringify(resUserMe.data.myStores[0]));
                localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
            }catch(e){
                console.log('mystore 없음 스텝 or 가입안함');
                localStorage.setItem('storeDomain', null);
            }
            
            
            // if(localStorage.getItem('domainType') === 'STORE'){
                // console.log(localStorage.getItem('myStores'));
                // var myStores = JSON.parse(localStorage.getItem('myStores'));
                // console.log(myStores);
            // }

            console.log('//////////////////////////////////');
            console.log(resUserMe);

            if( resUserMe.data.isCEO === true ){
                if(resUserMe.data.shouldAskWhetherCEO === true){
                    navigate('/workplace/join');
                }else{
                    try{
                        setAccessToken();
                    }catch(e){}
                    window.location.href='/demo/store/'+localStorage.getItem('storeDomain');
                }
            }else if( resUserMe.data.isCEO === false ){
                console.log('sm');
                try{
                    setAccessToken();
                }catch(e){}
                navigate('/staff/staffmain');
            }else{
                console.log('sq');
                navigate('/store/storequestions');
            }

            // if(resUserMe.data.shouldAskWhetherCEO === true){
            //     if( resUserMe.data.myStores.length === 0 ){
            //         navigate('/workplace/join');
            //     }
            // }else{
            //     if( resUserMe.data.isCEO === 'CEO' ){
            //         navigate('/store'+localStorage.getItem('storeDomain')+'/storemain?storeurl='+ localStorage.getItem('storeDomain'));
            //     }else if( resUserMe.data.isCEO === false ){
            //         navigate('/staff/main');
            //     }else{
            //         navigate('/store/storequestions');
            //     }
            // }

            //     if(resUserMe.data.myStores[0].storeDomain !== undefined){

            //         localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
            //         try{
            //             console.log(deviceType())
            //             setAccessToken(localStorage.getItem('accessToken'));
            //         }catch(e){
            //             console.log(e);
            //         }

            //         navigate('/store/'+ resUserMe.data.myStores[0].storeDomain );

            //     }else{
            //         navigate('/workplace/join');
            //     }
                
            //     if(resUserMe.data.domainType === 'STORE'){
            //         if( resUserMe.data.isCEO === 'CEO' ){
                        
            //                 // window.location.replace('/store/'+ resUserMe.data.myStores[0].storeDomain);
            //                 // navigate('/store'+localStorage.getItem('storeDomain')+'/storemain?storeurl='+ localStorage.getItem('storeDomain'));
            //             }
            //         }else if( resUserMe.data.isCEO === false ){
            //             navigate('/staff/main');
            //         }else{
            //             // navigate('/404');
            //         }
            //     }
            // }
            document.getElementById('submitBtn').removeAttribute("disabled");
        }catch(e){
            console.log(e);
            document.getElementById('submitBtn').removeAttribute("disabled");
        }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter') {
            loginBtn();
        }
    }

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
            <div className="TLoginPage">
                <div className="container position-absolute start-50 top-50 translate-middle w-100">
                    <div className="Inner m-auto p-2">
                        <div className="LogoImg text-center">
                            <img src="https://www.flexdaycdn.net/public/images/ticket/inje_logo2.png" alt="FLEX" />
                        </div>
                        <div className="InfoSearch d-flex justify-content-end">
                            <a href="#none">아이디 찾기</a>
                            <a href="#none">비밀번호 찾기</a>
                        </div>
                        <div className="LoginBox">
                            <div className="row">
                                <input type="text" className="form-control InputType" placeholder="아이디를 입력해주세요." id='Id' onChange={onChangeAccount} onKeyPress={onKeyPress}/>
                            </div>
                            <div className="row">
                                <input type="password" className="form-control InputType" placeholder="비밀번호를 입력해주세요." id='Password' onChange={onChangeAccount} onKeyPress={onKeyPress}/>
                            </div>
                            <button type="button" className="btn btn-primary" id='submitBtn' onClick={ (e) => { loginBtn(); } }>로그인</button>
                            <button type="button" className="btn btn-default">회원가입</button>

                        </div>
                        <div className="JoinBox mt-5">
                            <p className="InfoTxt mb-2">
                                회원이 아니신가요?
                            </p>
                            <a href="#none" className="btn btn-light type15">
                                비회원 구매
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
        // <div className='LoginPage'>
        //     <div className="container PosionCenter d-flex align-items-center">
        //         <div class="Inner m-auto p-2">
        //             <div class="LogoImg text-center">
        //                 <img src="https://www.flexdaycdn.net/public/images/static/login/new-logo.png" alt="FLEX"/>
        //             </div>
        //             <div class="InfoSearch d-flex justify-content-end">
        //                 <Link to="/findid/store">아이디 찾기</Link>
        //                 <Link to="/findpw/store">비밀번호 찾기</Link>
        //             </div>
        //             <div class="LoginBox">
        //                 <div class="row">
        //                     <input type="text" id='Id' class="form-control InputType" placeholder="아이디를 입력해주세요." onChange={onChangeAccount} onKeyPress={onKeyPress} />
        //                 </div>
        //                 <div class="row">
        //                     <input type="password" id='Password' class="form-control InputType" placeholder="비밀번호를 입력해주세요." onChange={onChangeAccount} onKeyPress={onKeyPress} />
        //                 </div>
        //                 <button type="button" id='submitBtn' class="btn btn-primary" onClick={ (e) => { loginBtn(); } }>로그인</button>
        //                 <div class="form-check d-flex align-items-center p-0 mt-2">
        //                     <input class="CheckInput2 small form-check-input" name="Check" id="Check-01" type="checkbox" checked/>
        //                     <label class="form-check-label CheckLabel" for="Check-01">자동 로그인</label>
        //                 </div>
        //             </div>
        //             <div class="JoinBox mt-4 mob-mt-5">
        //                 <p class="InfoTxt mb-3"><span>아직 회원이 아니신가요?</span></p>
        //                 <Link to="/store/join" class="btn btn-default">회원가입</Link>
        //             </div>
                    
        //         </div>
        //     </div>
            
        // </div>
    )
}