import { Col, Image, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { getImgUrl } from './../../function/common';

export default function StoreList (props){

    const navigate = useNavigate();
        let DumpImg = '';
        // 업태

        console.log(props.businessClassType);
        
        if (props.businessClassType === '외식'){
            DumpImg = getImgUrl('images/icon/logo_eat.png');
        }else if (props.businessClassType === '뷰티,미용'){
            DumpImg = getImgUrl('images/icon/logo_beauty.png');
        }else if (props.businessClassType === '스포츠,레저,여가'){
            DumpImg = getImgUrl('images/icon/logo_sport.png');
        }else{
            DumpImg = getImgUrl('images/icon/logo_etc.png');
        }
    return (
        <>
            {/* <div className="d-flex shadow-lg p-3" onClick={ () => { navigate('/user/storemain?storeurl='+props.domain) }}> */}
            <Row className='shadow-lg p-3' onClick={ () => { navigate('/user/'+props.domain) }}>
                <Col xs={4} className='AlignCenter'>
                    <div className='thumbnail'>
                        {
                            props.img !== null ?
                            <Image src={props.img} fluid rounded />
                            :
                            <Image src={DumpImg} fluid rounded />
                        }
                    </div>
                </Col>
                <Col xs={8}>
                    <div className='flex-column'>
                        {/* <label>{props.businessClassType}</label><br/> */}
                        <label>{props.storeName}</label>
                        <pre>
                            <FontAwesomeIcon className='Gradient' icon={faStar} /> {props.storeGrade} ( {props.reviewCount} )
                        </pre>
                    </div>
                </Col>
            </Row>
        </>
    )
}