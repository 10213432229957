
import { Container, Table, Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { Link } from 'react-router-dom';

export default function PromotionList( props ){

    

    return (
        <>
            <Container >
                <Row>
                    <Table className='text-center'>
                        <thead>
                            <tr>
                                <td>번호</td>
                                <td>신청내용</td>
                                <td>신청일시</td>
                                <td>상태</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td >1</td>
                                <td >종이테이블보 300매</td>
                                <td >22.04.12</td>
                                <td >접수중</td>
                            </tr>
                            <tr>
                                <td >2</td>
                                <td >종이테이블보 300매</td>
                                <td >22.04.12</td>
                                <td >접수중</td>
                            </tr>
                            <tr>
                                <td >3</td>
                                <td >종이테이블보 300매</td>
                                <td >22.04.12</td>
                                <td >접수완료</td>
                            </tr>
                            <tr>
                                <td >4</td>
                                <td >종이테이블보 300매</td>
                                <td >22.04.12</td>
                                <td >접수중</td>
                            </tr>
                            <tr>
                                <td colSpan="4" >
                                    {/* <Btn style={{width:'100%'}} btntext='이전내역 더보기'/> */}
                                    {/* <div className="d-grid gap-1"> */}
                                        <Button variant="primary" >
                                            이전내역 더보기
                                        </Button>
                                    {/* </div> */}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
                
                <Row>
                    <Link to="/promotion/promotionrequest">
                        <div className="d-grid gap-1">
                            <Button variant="primary" size="lg" >
                                홍보물 신청하기(무료)
                            </Button>
                        </div>
                    </Link>
                </Row>
            </Container>
        </>
    )
}