
import { Container, Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import CustomAlert from '../../components/common/CustomAlert';
import CustomModal from '../../components/common/CustomModal';

export default function CouponUseCheck (props){

    return (
        <>
            <Container>
                <Row >
                    <Col className='text-center'>
                        <img
                            className="d-center w-50"
                            src="https://placeimg.com/480/480"
                            alt="1"
                        />
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <p>쿠폰번호 : 1111 - 1111 - 1111 - 1111</p>
                    </Col>
                </Row>
                <Row className='text-center'>
                    <Col>
                        <CustomAlert type="ALERT" text="이미 사용한 쿠폰입니다." />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h4>플렉스 브런치 카페</h4>
                        <p>메뉴이름 : 플렉스 아메리카노</p>
                        <p>메뉴가격 : 18,000원</p>
                        <p>유효기간 : YYYY-mm-dd</p>
                    </Col>
                </Row>

                <Row className='text-left' >
                    <Col>
                        <CustomAlert css="LEFT" type="ALERT" text="결 제 자 : 홍길동\n결제일시 : YYYY. mm. dd. (t:m) " />
                    </Col>
                </Row>

                <Row>
                    <Col className='d-grid'>
                        <CustomModal type='CENTER' mdtitle='판매취소 하시겠습니까?' mdtext='판매취소할 경우, 교환권은 미사용으로 바뀝니다.' rightbtn='판매취소하기' title='판매취소' />
                    </Col>
                </Row>
            </Container>
        </>
    )
}