import { Container, Row, InputGroup, FormControl, Button, Col, Form, Image, Alert } from 'react-bootstrap';

// import { useNavigate, Link, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import './signup.css';
import Btn from '../../../components/common/Btn';
import InputBox from './../../../components/InputBox';
import { AlertError, getApiUrl, timer } from '../../../function/common';
import { useParams } from 'react-router-dom';

export default function FindId(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState({
        loginId             : false,
        phoneNumber         : false
    });

    const { type } = useParams();
    if(type === undefined){
        console.log('잘못된 접속 입니다.');
        navigate('/404');
    }

    const [data, setData] = useState({});
    const [ authCodeShow, setAuthCodeShow] = useState(false)
    const sendAlertRef = useRef()
    const noUserAlertRef = useRef()
    const typeNumberAlertRef = useRef()

    const onName = (e) => {
        setValidation({...validation, name : true});
    }
    const onPhoneNumber = (e) => {
        setValidation({...validation, name : true});
    }

    const onChangeData = async (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        // console.log(data);
    };

    const getAuthCode = async (e) => {
        document.getElementById('submitBtn').setAttribute("disabled","true");

        let obj = { 
            'name' : data.name,
            'phoneNumber' : data.phoneNumber
        }
        await axios.post(
            getApiUrl('auth/id/code'),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                console.log(response);
                if(response.status === 200){
                    setData({
                        ...data,
                        'findIdToken' : response.data.token,
                    });
                    sendAlertRef.current.classList.add('active')
                    setTimeout(() => {
                        sendAlertRef.current.classList.remove('active')
                    }, 3000)
                    setAuthCodeShow(true)
                    timer('timer', 180);
                }else{
                    alert('인증번호가 발송에 실패하였습니다.');
                    document.getElementById('submitBtn').removeAttribute("disabled");
                }
            }
        ).catch(function(error){
            setValidation({...validation, phoneNumber : false});
            console.log(error);
            if (error.response.data.httpStatus === 'NOT_FOUND') {
                noUserAlertRef.current.classList.add('active')
                setTimeout(() => {
                    noUserAlertRef.current.classList.remove('active')
                }, 3000)
            } else {
                AlertError(error);
            }
            document.getElementById('submitBtn').removeAttribute("disabled");
        })
    }

    const checkAuthCode = async (e) => {
        document.getElementById('submitBtn2').setAttribute("disabled","true");
        let obj = { 
            'findIdToken' : data.findIdToken,
            'authCode' : data.authCode
        }
        console.log(obj);
        if (!data.authCode) {
            typeNumberAlertRef.current.classList.add('active')
            return setTimeout(() => {
                typeNumberAlertRef.current.classList.remove('active')
            }, 3000)
        }
        await axios.post(
            getApiUrl('auth/id/auth'),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                // console.log(response);
                if(response.status === 200){
                    setMode("STEP2");
                    
                    setData({
                        ...data,
                        'loginId' : response.data.loginId
                    });
                }else{
                    alert('인증번호 인증에 실패하였습니다.');
                    document.getElementById('submitBtn2').removeAttribute("disabled");
                }
            }
        ).catch(function(error){
            setValidation({...validation, phoneNumber : false});
            AlertError(error);
            document.getElementById('submitBtn2').removeAttribute("disabled");
        })
    }



    const [ mode, setMode ] = useState('STEP1');
    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    if(mode === 'STEP1'){
        // 기본 페이지 분기
        return (
            <div className='SignupPage'>
                <Btn type="NAVBACK" title="아이디 찾기" />
                <Container>
                    <Row className="MainSpace SignUp-title">
                        <p className='tit mb-1'>회원가입시 등록한 정보를<br/>입력해 주세요.
                            <Image
                                className='img-fluid ms-1'
                                src='https://www.flexdaycdn.net/public/images/static/login/signup_info.png'
                            />
                        </p>
                    </Row>

                    <Row>
                        <Form.Group className="xs-12 mt-3" controlId="name">
                            <Form.Control type="text" className='InputType' onBlur={onName}  placeholder="이름을 입력해 주세요." onChange={onChangeData} />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group className="xs-12 mt-3" controlId="phoneNumber">
                            <Form.Control type="number" className='InputType' onBlur={onPhoneNumber}  placeholder="연락처를 입력해 주세요.('-' 없이 숫자만 입력)" onChange={onChangeData} onKeyDown={(e)=> e.key === 'e' && e.preventDefault()} maxLength="12" />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <div className="d-grid gap-1 mt-3">
                            <Button variant="default" size="lg" id='submitBtn' onClick={ (e) => {getAuthCode(e);}}>
                                인증번호 전송
                            </Button>
                        </div>
                    </Row>

                    {
                        authCodeShow && <Row>
                        <Form.Group className="xs-12 mt-3" controlId='authCode'>
                            <Row className='SignUp-Contents g-0'>
                                <Col className='col-8 pe-2 SignUp-Inputbox'>
                                    <Form.Control type="number" className='InputType h-50px' onBlur={onPhoneNumber}  placeholder="인증번호를 입력해 주세요." onChange={onChangeData} onKeyDown={(e)=> e.key === 'e' && e.preventDefault()} maxLength="6"/>
                                </Col>
                                <Col className='col-4'>
                                    <Button variant="default" size="lg" id='submitBtn2' onClick={ (e) => { checkAuthCode() } }>
                                        확인
                                    </Button>
                                </Col>
                            </Row>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    }

                    <Row>
                        <Form.Group className="xs-12 mt-3" controlId='authCode'>
                            <Form.Control.Feedback id='timer' type="invalid"></Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                <div ref={sendAlertRef} className='AlertBox mt-3'>
                    인증번호가 전송되었습니다.
                </div>
                <div ref={noUserAlertRef} className='AlertBox mt-3'>
                    회원가입한 정보가 없습니다.
                </div>
                <div ref={typeNumberAlertRef} className='AlertBox mt-3'>
                    인증번호를 입력해주세요.
                </div>
                </Container>
            </div>
        )
    }else if(mode === "STEP2"){
        // 다음 단게 분기
        return (
            <Container className='SignupPage'>
                <Btn type="NAVBACK" title="아이디 확인" />
                <Row className="MainSpace SignUpBox responsiveInner Sign-AlertBox text-center">
                    <Image
                        className='img-fluid Emoji mb-4 mx-auto'
                        src={'https://www.flexdaycdn.net/public/images/static/login/search_id.png'}
                    />
                    <Row className='Sign-AlertBox-tit justify-content-center'>
                        고객님의 아이디는<br/><span style={{color:'red'}}>{data.loginId}</span> 입니다.
                    </Row>
                </Row>

                <div className='fixed-bottom responsiveInner py-5 mx-auto'>
                    <Button variant="default" className='mb-3' size='lg' onClick={ (e) => { navigate('/'+type+'/login') } }>
                        로그인 하러가기
                    </Button>

                    <Button variant="default" className='btn-light' onClick={() => navigate('/findpw/'+type)} >
                        비밀번호 찾기
                    </Button>
                </div>

            </Container>
        )
    }else if(mode === "STEP3"){
        // 완료 단게 분기
        return (
            <div>

            </div>
        )
    }else{
        return <></>;
    }
}