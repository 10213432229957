
import { Container, Row, Table } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import LeftMenu from './../../../components/admin/LeftMenu';
import SearchForm from './../../../components/admin/SearchForm';
import CheckBox from './../../../components/common/CheckBox';
import Btn from './../../../components/common/Btn';

export default function ConsumerUser( props ){

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs ={2}>
                        <LeftMenu />
                    </Col>

                    <Col xs={10}>
                        <SearchForm label='가맹점 회원검색' />

                        <hr/>
                        <Row className='text-center'>
                            <label>조회결과 : 00건</label>
                            <Table responsive>
                                <thead>
                                    <tr className="text-center">
                                        <th><CheckBox type='TableCheck' /></th>
                                        <th>고객번호</th>
                                        <th>상호명</th>
                                        <th>매장주소</th>
                                        <th>매장전화번호</th>
                                        <th>대표자명</th>
                                        <th>대표자전화번호</th>
                                        <th>매장이름</th>
                                        <th>대표자이메일</th>
                                        <th>가입일</th>
                                        <th>스토어</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><CheckBox type='TableCheck' /></td>
                                        <td>00000</td>
                                        <td>상호명</td>
                                        <td>주소....</td>
                                        <td>051-000-0000</td>
                                        <td>홍길동</td>
                                        <td>010-0000-0000</td>
                                        <td>매장이름</td>
                                        <td>hong22222@google.com</td>
                                        <td>YYYY-mm-dd</td>
                                        <td><Btn type='LOCATION' path='/admin/consumer' btntext='보기' /></td>
                                    </tr>
                                    <tr>
                                        <td><CheckBox type='TableCheck' /></td>
                                        <td>00000</td>
                                        <td>상호명</td>
                                        <td>주소....</td>
                                        <td>051-000-0000</td>
                                        <td>홍길동</td>
                                        <td>010-0000-0000</td>
                                        <td>매장이름</td>
                                        <td>hong22222@google.com</td>
                                        <td>YYYY-mm-dd</td>
                                        <td><Btn type='LOCATION' path='/admin/consumer' btntext='보기' /></td>
                                    </tr>
                                    <tr>
                                        <td><CheckBox type='TableCheck' /></td>
                                        <td>00000</td>
                                        <td>상호명</td>
                                        <td>주소....</td>
                                        <td>051-000-0000</td>
                                        <td>홍길동</td>
                                        <td>010-0000-0000</td>
                                        <td>매장이름</td>
                                        <td>hong22222@google.com</td>
                                        <td>YYYY-mm-dd</td>
                                        <td><Btn type='LOCATION' path='/admin/consumer' btntext='보기' /></td>
                                    </tr>
                                    <tr>
                                        <td><CheckBox type='TableCheck' /></td>
                                        <td>00000</td>
                                        <td>상호명</td>
                                        <td>주소....</td>
                                        <td>051-000-0000</td>
                                        <td>홍길동</td>
                                        <td>010-0000-0000</td>
                                        <td>매장이름</td>
                                        <td>hong22222@google.com</td>
                                        <td>YYYY-mm-dd</td>
                                        <td><Btn type='LOCATION' path='/admin/consumer' btntext='보기' /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </>
    )
}