import React from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import LabelledTextField from "../../../../components/ver1.5/organism/LabelledTextField";
import { useOutletContext } from "react-router-dom";
import { styled } from "styled-components";
import Text from "../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Divider from "../../../../components/ver1.5/atom/Divider";
import { dateFormatter } from "../../../../components/ver1.5/utils";

const Container = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const EditEmail = () => {
  const { me = { email: "", createdAt: "" } } = useOutletContext();
  return (
    <Layout>
      <Container>
        <LabelledTextField label={"이메일 계정"} value={me.email} readOnly />
        <Text fontStyle={font.caption2} color={color.gray.d600}>
          가입일 {me.createdAt ? dateFormatter({ date: me.createdAt }) : "-"}
        </Text>
        <Divider
          direction="horizontal"
          color={color.gray.d100}
          width={"0.1rem"}
        />
        <Text fontStyle={font.caption2} color={color.gray.d600}>
          플렉스데이 서비스 이용 계정으로 등록된 이메일은 직접 삭제/수정 할 수
          없습니다
        </Text>
      </Container>
    </Layout>
  );
};

export default EditEmail;
