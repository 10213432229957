import React, { useContext } from "react";
import { styled } from "styled-components";
import ProductListItem from "../molecule/ProductListItem";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../../contexts/UserContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  > * {
    flex-shrink: 0;
  }
`;

const ProductList = ({ data = [], storeDomain, modal, setModal }) => {
  const { accessToken } = useContext(UserContext);

  const navigate = useNavigate();
  return (
    <Container>
      {data.map((item, index) => (
        <ProductListItem
          key={item.id}
          item={item}
          isLast={index === data.length - 1}
          onClick={() => {
            if (modal) return;
            if (accessToken) {
              navigate(`/user/gift/${storeDomain}/${item.id}`);
            } else {
              setModal(true);
            }
          }}
        />
      ))}
    </Container>
  );
};

export default ProductList;
