import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import Btn from '../../components/common/Btn';
import { getImgUrl } from './../../function/common';


export default function PosMain(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/pos/login');
    }

    async function useGift(){

        document.getElementById('submitBtn').setAttribute("disabled","true");

        if(document.getElementById('CouponNumber').value === null || document.getElementById('CouponNumber').value === '' || document.getElementById('CouponNumber').value.length < 11){
            alert('잘못된 교환권 번호입니다.');
            document.getElementById('submitBtn').removeAttribute("disabled");
            return false;
        }

        axios.post(
            getApiUrl("stores/" + localStorage.getItem('storeDomain') + "/mobile-couponNumber/" + document.getElementById('CouponNumber').value), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    console.log(response.data.redirectUrl);
                    let TmpUrl = response.data.redirectUrl.split('usegift?');
                    console.log('/pos/check?'+TmpUrl[1]);
                    window.location.href='/pos/check?'+TmpUrl[1];
                }
            }
        ).catch(function(error){
            AlertError(error);
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    function InputPos(Val){
        if(Val === 'CLEAR'){
            document.getElementById('CouponNumber').value = '';
        }else if(Val === 'DEL'){
            let before = document.getElementById('CouponNumber').value;
            document.getElementById('CouponNumber').value = before.slice(0, -1);
        }else{
            let before = document.getElementById('CouponNumber').value;
            document.getElementById('CouponNumber').value = before+Val;
        }
        
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Row className='mt-5'>
                    <Col>
                        <p><label className='PointText'>*</label> 교환권 번호를 입력해주세요.</p>
                        <input type="text" id='CouponNumber' name='Id' className="form-control InputType" placeholder="교환권 번호를 입력해주세요. ('-' 없이 숫자만 입력)" onChange={onChangeData} />
                        
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(1);} } >1</Button>
                    </Col>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(2);} }>2</Button>
                    </Col>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(3);} }>3</Button>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(4);} }>4</Button>
                    </Col>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(5);} }>5</Button>
                    </Col>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(6);} }>6</Button>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(7);} }>7</Button>
                    </Col>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(8);} }>8</Button>
                    </Col>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(9);} }>9</Button>
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos('CLEAR');} } >
                            전체삭제
                        </Button>
                    </Col>
                    <Col>
                        <Button variant='secondary' size='lg' onClick={ () => {InputPos(0);} } >0</Button>
                    </Col>
                    <Col>
                        <Button variant='secondary' className='DelBtn' size='lg' onClick={ () => {InputPos('DEL');} } >
                            <img className='DelIcon' src={getImgUrl("images/pos/delet.png")} alt="DEL"/>
                        </Button>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col className='align-self-end'>
                        <Button variant="primary" id='submitBtn' onClick={ useGift }>
                            교환권 조회
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}