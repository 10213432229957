import React from "react";
import { css, styled } from "styled-components";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";
import { setCommas } from "../../../../function/common";
import DetailInfoItem from "../../Store/Detail/components/molecule/DetailInfoItem";
import { commonInfoContents } from "../../Store/Detail/components/organism/detailInfoContentList";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const InfoContainer = styled.div`
  width: 100%;
  padding: 2.4rem 1.6rem 1.6rem 1.6rem;
  gap: 0.8rem;
  border-radius: 2.4rem 2.4rem 0 0;
  background: ${color.primary.white};
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 0;
`;

const CancelInfoContainer = styled.div`
  background: ${color.primary.white};
  border-top: 1rem solid ${color.gray.d50};
`;

const MiddleContent = ({ orderNumber, price = 0 }) => {
  const cancelInfo = commonInfoContents("플렉스데이").find((info) =>
    info.title.includes("취소")
  );
  return (
    <Container>
      <InfoContainer>
        <Text fontStyle={font.subtitle3} color={color.gray.d900}>
          결제 정보
        </Text>
        <RowContainer>
          <Text fontStyle={font.caption2} color={color.gray.d600}>
            주문번호
          </Text>
          <Text fontStyle={font.caption2} color={color.gray.d900}>
            {orderNumber}
          </Text>
        </RowContainer>
        <RowContainer>
          <Text fontStyle={font.caption2} color={color.gray.d600}>
            결제수단
          </Text>
          <Text fontStyle={font.caption2} color={color.gray.d900}>
            카드결제
          </Text>
        </RowContainer>
        <RowContainer>
          <Text fontStyle={font.caption2} color={color.gray.d600}>
            결제금액
          </Text>
          <Text fontStyle={font.caption2} color={color.gray.d900}>
            {setCommas(price)}원
          </Text>
        </RowContainer>
      </InfoContainer>
      <CancelInfoContainer>
        <DetailInfoItem
          sectionTitle={cancelInfo.title}
          contents={cancelInfo.content}
          isAccordion={cancelInfo.isAccordion}
          dividerWidth={"0.1rem"}
        />
        <Text
          fontStyle={font.caption3}
          color={color.gray.d600}
          styles={css`
            width: 100%;
            text-align: center;
            padding: 1.6rem;
          `}
        >
          고객지원센터 051-621-0203
        </Text>
      </CancelInfoContainer>
    </Container>
  );
};

export default MiddleContent;
