export const emailList = [
  "naver.com",
  "hanmail.net",
  "hotmail.com",
  "nate.com",
  "yahoo.co.kr",
  "empas.com",
  "dreamwiz.com",
  "freechal.com",
  "lycos.co.kr",
  "korea.com",
  "gmail.com",
  "hanmir.com",
  "paran.com",
];

export const passwordReg = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[!@#$%^]).{10,18}$/;

export const detectMobile = () => {
  const matchList = [/Android/i, /iPhone/i];

  return matchList.some((match) => {
    return navigator.userAgent.match(match);
  });
};
