import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { PrintConsole, getImgUrl, setCommas, ReturnYear, getApiUrl } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Image, Table, Tabs } from 'react-bootstrap';

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';

import { Tab } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { setAccessToken, setNav5 } from '../../api/ApiWebView';

import './common.css';
import './SettleHistorySub.css';

export default function SettleHistorySub(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [printCoupon, setPrintCoupon] = useState({});
    const onChangeData2 = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setPrintCoupon({
          ...printCoupon,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };
    
    // console.log('==================='+localStorage.getItem('storeDomain'))

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

        
    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    const tableCss = {verticalAlign: 'top'};

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    
    function getSearch() {
        
        let query = '';

        // console.log(startDate.getMonth());

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDate='+TmpStart+'&endDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/settlements'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setData(response.data);
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    function getPrintList() {
        
        let query = '';

        // console.log(startDate.getMonth());

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDateOfIssuedDate='+TmpStart+'&endDateOfIssuedDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupons'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setPrintCoupon(response.data);

                        getSumPrintList();
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    const [sumPrintCoupon, setSumPrintCoupon] = useState({});

    function getSumPrintList() {
        let query = '';

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDateOfIssuedDate='+TmpStart+'&endDateOfIssuedDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupons-summary'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setSumPrintCoupon(response.data);
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        // getSearch();
        // getPrintList();

        try{
            //webview에 토큰값, 도메인 전달
            setAccessToken();
        }catch(e){}
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    console.log(printCoupon);

    if (loading) return <></>

    return (
        <>
        <div className='demo'>

            <div className="TSettleHistorySubPage">
        <div className="container-fluid g-0">

            <nav className="sub-navbar">
                <p className="navbar-title">10월17일 ~ 10월23일</p>
                <button type="button" className="btn-clear btn-close" onClick={() => {navigate(-1)}}>
                    <img className="img-fluid" alt="뒤로가기"
                        src="https://www.flexdaycdn.net/public/images/ticket/close2.png" />
                </button>
            </nav>

            <div className="HistorySub">
                <div className="Listitem px-4-5 py-4">
                    <p className="text-end time">22-10-27 13:01</p>
                    <div className="align-items-center d-flex justify-content-between">
                        <img className="img-fluid logo" alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/logo2.png" />
                        <div className="txtbox">
                            <p className="mb-0 name">중식(정식)</p>
                            <p className="t-num mb-0">
                                (1234-1234-4567)
                            </p>
                            <p className="price">
                                <span className="num">+6,000</span> 원
                            </p>
                        </div>
                    </div>
                </div>
                <div className="Listitem cancel px-4-5 py-4">
                    <p className="text-end time">22-10-25 19:55</p>
                    <div className="align-items-center d-flex justify-content-between">
                        <img className="img-fluid logo" alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/logo2.png" />
                        <div className="txtbox">
                            <p className="mb-0 name">중식(정식)</p>
                            <p className="t-num mb-0">
                                (8888-6752-4912)
                            </p>
                            <p className="price">
                                <span className="num">-6,000</span> 원
                            </p>
                        </div>
                    </div>
                    <p className="text-end cancel-txt">취소된 내역</p>
                </div>
                <div className="Listitem px-4-5 py-4">
                    <p className="text-end time">22-10-24 15:30</p>
                    <div className="align-items-center d-flex justify-content-between">
                        <img className="img-fluid logo" alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/logo2.png" />
                        <div className="txtbox">
                            <p className="mb-0 name">중식(정식)</p>
                            <p className="t-num mb-0">
                                (8888-6152-4912)
                            </p>
                            <p className="price">
                                <span className="num">+6,000</span> 원
                            </p>
                        </div>
                    </div>
                </div>
                <div className="Listitem px-4-5 py-4">
                    <p className="text-end time">22-10-24 10:00</p>
                    <div className="align-items-center d-flex justify-content-between">
                        <img className="img-fluid logo" alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/logo2.png" />
                        <div className="txtbox">
                            <p className="mb-0 name">중식(정식)</p>
                            <p className="t-num mb-0">
                                (9784-4568-7862)
                            </p>
                            <p className="price">
                                <span className="num">+6,000</span> 원
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>

    </div>
        </>
    )
}