import React, { useState } from "react";
import { css, styled } from "styled-components";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";
import { ToggleIcon } from "../../../../../components/ver1.5/assets/icons";
import Radio from "../../../../../components/ver1.5/molecule/Radio";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1.6rem;
  gap: ${(p) => (p.$open ? "1.8rem" : 0)};
  transition: gap 0.3s ease-in-out;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  max-height: ${(p) => (p.$open ? "300vh" : 0)};
  opacity: ${(p) => (p.$open ? 1 : 0)};
  visibility: ${(p) => (p.$open ? "visible" : "hidden")};
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  pre,
  div {
    max-height: ${(p) => (p.$open ? "300vh" : 0)};
    visibility: ${(p) => (p.$open ? "visible" : "hidden")};
    transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  width: 2.4rem;
  aspect-ratio: 1;
  cursor: pointer;
  ${(p) =>
    p.$open &&
    css`
      transform: rotate(180deg);
    `}
  transition: transform .3s ease-in-out;
`;

const CheckboxAccordion = ({ title, contents, checked, onClick }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Container $open={open}>
        <Row>
          <Radio
            type="checkbox"
            label={title}
            checked={checked}
            onClick={() => (onClick ? onClick() : null)}
          />
          <IconWrapper $open={open} onClick={() => setOpen(!open)}>
            <ToggleIcon />
          </IconWrapper>
        </Row>
        <ContentWrapper $open={open}>
          <Text fontStyle={font.body2} color={color.gray.d800}>
            <pre>{contents}</pre>
          </Text>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default CheckboxAccordion;
