import AddStaff from "../pages/staff/AddStaff";
import JoinStaff from "../pages/staff/JoinStaff";
import Management from "../pages/staff/Management";
import StaffMain from "../pages/staff/StaffMain";
import StaffManagement from "../pages/staff/StaffManagement";

const StaffRoutes = [
  { path: "management", element: <Management /> },
  { path: "staffmanagement", element: <StaffManagement /> },
  { path: "addstaff", element: <AddStaff /> },
  { path: "joinstaff", element: <JoinStaff /> },
  { path: "staffmain", element: <StaffMain /> },
];

export default StaffRoutes;
