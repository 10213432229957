import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Accordion,
  Modal,
} from "react-bootstrap";
import FileInput from "../../../components/common/FileInput";
import InputBox from "../../../components/InputBox";
import CheckBox from "./../../../components/common/CheckBox";
import CustomRange from "./../../../components/common/CustomRange";
import StoreItem from "./../../../components/common/StoreItem";
import Btn from "./../../../components/common/Btn";
import { useRef } from "react";
import { useState } from "react";
import {
  AlertError,
  getApiUrl,
  getImgUrl,
  PrintConsole,
  sleep,
} from "./../../../function/common";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import ConsignmentTerms from "./../../../components/common/ConsignmentTerms";

const AddPrdFormItem = ({ children, title, sub, isFirst }) => {
  const responsiveClassName = isFirst
    ? "AddPrd-Formitem py-5"
    : "AddPrd-Formitem pb-5";
  return (
    <div className={responsiveClassName}>
      <p className="addprd-tit">{title}</p>
      {sub &&
        sub.split("\n").map((txt, index) => {
          if (index !== sub.split("\n").length - 1) {
            return (
              <p className="addprd-sub mb-0" key={index}>
                {txt}
              </p>
            );
          } else {
            return (
              <p className="addprd-sub" key={index}>
                {txt}
              </p>
            );
          }
        })}
      {children}
    </div>
  );
};

export default function ItemCreate(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [Params] = useSearchParams();
  const editTargetId = Params.get("productId");

  const priceInputRef = useRef();
  const descriptionRef = useRef();
  const originRef = useRef();
  const [submitModalShow, setSubmitModalShow] = useState(false);

  const radios = [
    {
      value: "POPULAR",
      payload: "BEST",
      id: "menuTypeBest",
    },
    {
      value: "NEW",
      payload: "NEW",
      id: "menuTypeNew",
    },
    {
      value: "RECOMMEND",
      payload: "추천",
      id: "menuTypeRecommend",
    },
  ];

  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }
  PrintConsole(Parameters);

  const [data, setData] = useState({
    totalAmount: 1,
  });

  const onChangeData = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
    PrintConsole(data);
  };

  // 상품 가격 입력 시 1000원 이하일 때 invalid
  const onChangePrice = (e) => {
    if (e.target.id === "price") {
      if (!(!e.target.value || e.target.value?.length === 0)) {
        if (parseInt(uncomma(e.target.value)) < 1000) {
          priceInputRef.current.classList.add("active");
        } else {
          priceInputRef.current.classList.remove("active");
        }
      } else {
        priceInputRef.current.classList.remove("active");
      }
    }

    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
    PrintConsole(data);
  };

  // 상품 설명 textarea placeholder
  const onChangeTextarea = (e, ref, id) => {
    console.log(ref.current);
    if (e.target.id === id) {
      if (e.target.value && e.target.value !== "") {
        ref.current.classList.remove("Input-placeholderbox");
      } else {
        ref.current.classList.add("Input-placeholderbox");
      }
    }

    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
    PrintConsole(data);
  };

  const [menuType, setmenuType] = useState({ menuType: "DEFAULT" });

  const onCheckData = (e) => {
    if (e.target.checked) {
      setmenuType({ menuType: e.target.value });
    }
    PrintConsole(menuType);
  };

  const resetCheck = () => {
    setmenuType({ menuType: "DEFAULT" });
    const checks = document.querySelectorAll("input[name=Icons]");
    for (let check in checks) {
      checks[check].checked = false;
    }
  };

  const fileUpload = useRef();
  const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

  const onClickUpload = () => {
    fileUpload.current.click();
  };

  const onChangeImg = (e) => {
    // if (!e.target) {
    //   setFile(e[0].url);
    //   document.getElementById("PreView").style.display = "block";
    //   document.getElementById("preViewImg").src = "asdf";
    //   // document.getElementById("preViewImg").src = URL.createObjectURL(e[0].url);
    // } else {
    if (e.target.files) {
      const uploadFile = e.target.files[0];
      setFile(uploadFile);

      document.getElementById("PreView").style.display = "block";
      document.getElementById("PreViewImg").src = URL.createObjectURL(
        e.target.files[0]
      );
    }
    // }
  };

  const uncomma = (str) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  };

  const comma = (str) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  };

  const inputNumberFormat = (obj) => {
    obj.value = comma(uncomma(obj.value));
  };

  async function submit() {
    document.getElementById("submitBtn").setAttribute("disabled", "true");

    // if (!document.getElementById("s1").checked) {
    //   document.getElementById("submitBtn").removeAttribute("disabled");
    //   alert("필수 이용약관에 동의해주세요.");
    //   return false;
    // }
    if (data.price >= 500000) {
      alert("교환권은 1,000,000 원 이하만 발행 가능합니다.");
      document.getElementById("submitBtn").removeAttribute("disabled");
      setSubmitModalShow(false);
      return false;
    }

    if (!file) {
      alert("상품 이미지를 등록해주세요.");
      document.getElementById("submitBtn").removeAttribute("disabled");
      setSubmitModalShow(false);
      return false;
    }

    let obj;
    let tmpData = {
      description: data.description,
      menuType: menuType.menuType,
      productName: data.productName,
      price: parseInt(uncomma(data.price)),
    };
    console.log(tmpData);
    obj = new Blob([JSON.stringify(tmpData)], { type: "application/json" });

    var formData = new FormData();
    formData.append("params", obj);
    formData.append("images", file);

    axios
      .post(
        getApiUrl("stores/" + Parameters[0].storeurl + "/products"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      )
      .then(function (response) {
        alert("교환권이 등록되었습니다.");
        navigate("/store/" + localStorage.getItem("storeDomain"));
      })
      .catch((error) => {
        console.log(error);
        AlertError(error);
        document.getElementById("submitBtn").removeAttribute("disabled");
        setSubmitModalShow(false);
      });
    // document.getElementById('submitBtn').removeAttribute("disabled");
  }

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  // const getEditTarget = () => {
  //   return new Promise(async (resolve, reject) => {
  //     const response = await axios
  //       .get(
  //         getApiUrl(
  //           "stores/" +
  //             localStorage.getItem("storeDomain") +
  //             "/products/" +
  //             editTargetId
  //         )
  //       )
  //       .then((res) => res.data)
  //       .catch(function (e) {
  //         console.log(e);
  //       });
  //     if (response) {
  //       return resolve(response);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (editTargetId) {
  //     getEditTarget().then((target) => {
  //       console.log(target);
  //       const { description, images, price, name: productName } = target;
  //       setData((prev) => ({
  //         ...prev,
  //         description,
  //         productName,
  //         price: price.toString(),
  //       }));
  //       onChangeImg(images);
  //     });
  //   }
  // }, [editTargetId]);

  if (loading) return <></>;

  return (
    <div className="StoreMainPage">
      <Container fluid className="g-0">
        <Btn
          type="NAVBACK"
          title={editTargetId ? "교환권 수정" : "교환권 등록"}
        />
        <Container className="AddPrdBox py-4-5 px-4">
          <div className="AppGuide-btn">
            <Button
              onClick={() => navigate("/store/itemcreate/guide")}
              variant="default"
              className="type16"
            >
              등록가이드 보기
            </Button>
          </div>
          <AddPrdFormItem isFirst title={"1. 상품 대표사진"}>
            <Form.Group className="addprd-inputbox px-3" controlId="file">
              <div className="PhotoList">
                <Row>
                  <Col
                    className="File-Box col-4"
                    style={{ aspectRatio: 1, cursor: "pointer" }}
                    onClick={onClickUpload}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={fileUpload}
                      onChange={onChangeImg}
                    />
                    <div className="text-center">
                      <Image
                        className="img-fluid"
                        src="https://www.flexdaycdn.net/public/images/static/store-main/store_plus_color.png"
                        alt=""
                      />
                      <Image
                        className="img-fluid"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/static/store-main/store_plus.png"
                      />
                    </div>
                  </Col>
                  <Col className="col-4">
                    <div
                      className="PhotoImgbox"
                      id="PreView"
                      style={{ display: "none" }}
                    >
                      <Image fluid id="PreViewImg" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Form.Group>
          </AddPrdFormItem>
          <AddPrdFormItem title={"2. 상품 이름"}>
            <Form.Group
              className="addprd-inputbox px-3"
              controlId="productName"
            >
              <Form.Control
                className="InputType"
                type="text"
                placeholder="상품 이름을 입력해주세요."
                onChange={onChangeData}
                readOnly={Boolean(editTargetId)}
                value={data?.productName ?? ""}
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </AddPrdFormItem>
          <AddPrdFormItem title={"3. 상품 가격(원)"}>
            <Form.Group
              className="addprd-inputbox px-3 g-0 position-relative PayPrice"
              controlId="price"
            >
              <Form.Control
                ref={priceInputRef}
                type="text"
                className="InputType"
                placeholder="1,000 ~ 950,000"
                onChange={onChangePrice}
                onKeyUp={(e) => inputNumberFormat(e.target)}
                onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                readOnly={Boolean(editTargetId)}
                defaultValue={
                  data.price ? inputNumberFormat({ value: data.price }) : ""
                }
              />
              <div className="end-0 me-3 mt-2 pe-3 position-absolute price-txt top-0">
                원
              </div>
              <Form.Control.Feedback
                type="valid"
                className="form-text info-txt"
              >
                1,000원부터 입력 가능합니다.
              </Form.Control.Feedback>
            </Form.Group>
          </AddPrdFormItem>
          <AddPrdFormItem title={"4. 상품 설명"}>
            <Form.Group
              className="addprd-inputbox Input-placeholderbox px-3"
              controlId="description"
              ref={descriptionRef}
            >
              {!data?.description && (
                <div className="Input-placeholder">
                  상품에 대한 설명을 입력해주세요.
                  <br />
                  예) 신맛이 거의 없는 고소한 커피
                  <br />
                  과테말라 안티구아 원두를 사용합니다.
                </div>
              )}
              <Form.Control
                as="textarea"
                className="InputType mb-2 h-auto"
                rows={7}
                onChange={(e) =>
                  onChangeTextarea(e, descriptionRef, "description")
                }
                value={data?.description}
              />
            </Form.Group>
            <Form.Group className="addprd-inputbox px-3" controlId="origin">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>원산지 입력이 필요하세요?</Accordion.Header>
                  <Accordion.Body
                    ref={originRef}
                    className="Input-placeholderbox"
                  >
                    {originRef.current?.classList.contains(
                      "Input-placeholderbox"
                    ) && (
                      <div className="Input-placeholder">
                        원산지 입력지를 입력해주세요.
                        <br />
                        예) 쌀 - 국내산, 김치 - 국내산
                      </div>
                    )}
                    <Form.Control
                      as="textarea"
                      className="InputType h-auto p-0 border-0 InputPlaceholder"
                      rows={7}
                      onChange={(e) => onChangeTextarea(e, originRef, "origin")}
                      readOnly={Boolean(editTargetId)}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Form.Group>
          </AddPrdFormItem>
          <AddPrdFormItem
            title={"5. 아이콘 추가"}
            sub={
              "상품에 따라 아이콘이 필요하시면 선택해주세요.\n주력 상품에 추가하시면 좋아요!"
            }
          >
            <div className="addprd-inputbox d-flex">
              {radios.map(({ value, payload, id }) => {
                return (
                  <Form.Group key={id}>
                    <Form.Control
                      type="radio"
                      className="btn-check w-auto"
                      autoComplete="off"
                      id={id}
                      onChange={onCheckData}
                      name="Icons"
                      value={value}
                    />
                    <Form.Label
                      className="btn btn-light type10 w-auto"
                      htmlFor={id}
                    >
                      {payload}
                    </Form.Label>
                  </Form.Group>
                );
              })}
              <Button
                variant="clear"
                className="btn-refresh h-30px"
                onClick={resetCheck}
              >
                <Image
                  fluid
                  alt="새로고침"
                  src="https://www.flexdaycdn.net/public/images/static/store-main/store_reset.png"
                />
              </Button>
            </div>
          </AddPrdFormItem>
          <AddPrdFormItem>
            <Button
              variant="default"
              onClick={() => setSubmitModalShow(true)}
              id="submitBtn"
            >
              완료
            </Button>
          </AddPrdFormItem>
          <Modal
            show={submitModalShow}
            onHide={() => setSubmitModalShow(false)}
            centered
            className="text-center modal-register"
            size="sm"
          >
            <Modal.Header>
              <Modal.Title>교환권을 등록하시겠습니까?</Modal.Title>
            </Modal.Header>
            <Modal.Body>교환권 등록이 완료됩니다.</Modal.Body>
            <Modal.Footer>
              <Button
                variant="clear"
                className="btn-modal-secondary"
                onClick={() => setSubmitModalShow(false)}
              >
                취소
              </Button>
              <Button
                variant="clear"
                className="btn-modal-primary"
                onClick={submit}
              >
                확인
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </Container>
    </div>
  );
}
