

import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import StoreItem from '../../components/common/StoreItem';

import { AlertError, getApiUrl, getRedirctUrl, includeStr, setCommas } from '../../function/common';

import QrCode from '../../components/common/QrCode';

import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel'


import { useParams } from 'react-router-dom';
import { GetUserMe } from '../../api/ApiGetUser';

import { getStoreAuth } from '../../api/ApiGetStoreUser';


import './common.css';
import './StoreMain.css';


import { getImgUrl, PrintConsole } from '../../function/common';
import { openQRRead, setAccessToken, setNav1 } from '../../api/ApiWebView';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Image } from 'react-bootstrap';

export default function DemoStoreMain( ){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { storeDomain } = useParams();
    // console.log(storeDomain);
    // if(storeDomain === 'null' || storeDomain === undefined){
    //     console.log(storeDomain);
    //     navigate('/404');
    // }

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }

    console.log(localStorage.getItem('domainType'));
    console.log(localStorage.getItem('storeDomain'));

    const [store, setStore] = useState();
    function getStore() {
        if( localStorage.getItem('domainType') === 'STORE' ) {
            return new Promise( async function(resolve, reject) {
                const response = await axios.get(
                    getApiUrl('stores/'+storeDomain ),
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                    axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
                ).then(
                    function(response){
                        console.log('----------------------------------');
                        console.log(response);
                        if(response.status === 200){
                            setStore(response.data);
                        }
                    }
                ).catch(function(error){
                    navigate('/404');
                    console.log('error');
                })
                // if (response) {
                //     console.log(response);
                //     return resolve(response);
                // }
            });
        }else{
            return new Promise( async function(resolve, reject) {
                const response = await axios.get( getApiUrl('stores/'+storeDomain )
                ).then(
                    function(response){
                        console.log('----------------------------------');
                        console.log(response);
                        if(response.status === 200){
                            setStore(response.data);
                        }
                    }
                ).catch(function(error){
                    navigate('/404');
                    console.log('error');
                })
            });
        }
    };

    const [storeAuth, setStoreAuth] = useState();

    const [saleItem, setSaleItem] = useState();
    
    function getSeleItem() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('stores/'+storeDomain+'/products?status=SALE')
            ).then(
                function(response){
                    setSaleItem(response.data);
                }
            ).catch(
                function (e) {
                    console.log(e);
                }
            )
            if (response) {

                return resolve(response);
            }
        });
    };
    const [stopItem, setStopItem] = useState();
    function getStopItem() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('stores/'+storeDomain+'/products?status=SALE_STOPPED')
            ).then(
                function(response){
                    setStopItem(response.data);
                }
            ).catch(
                function (e) {
                    console.log(e);
                }
            )
            if (response) {

                return resolve(response);
            }
        });
    };

    const [storeAdmin, setStoreAdmin] = useState();
    function getStoreAdmin(sort) {
        
        return new Promise( async function(resolve, reject) {
            
            const response = await ( getApiUrl('stores/'+storeDomain+'/products'),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                    }
                }
            ).then(
                function(response){
                    // console.log('*********************************');
                    // console.log(response.data);
                    setStoreAdmin(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    const [storeReview, setStoreReview] = useState();
    function getStoreReview(sort) {
        // console.log(sort);
        let query = '';
        
        switch (sort){
            case 'NEW':
                query = '?reviewOrder=NEW';
                break;
            case 'HIGH':
                query = '?reviewOrder=HIGH';
                break;
            case 'LOW':
                query = '?reviewOrder=LOW';
                break;
            default:
                query = '?reviewOrder=NEW';
                break;
        }
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( getApiUrl('stores/'+storeDomain+'/reviews'+query)
            ).then(
                function(response){
                    PrintConsole(response);
                    setStoreReview(response.data);
                }
            ).catch( function(error){
                console.log(error);
            })
            if (response) {
                console.log(response);
                return resolve(response);
            }
        });
    };

    const titleFileUpload = useRef();
    const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

    const onClickUpload = () => {
        titleFileUpload.current.click();
    }

    const onChangeImg = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile(uploadFile);

            let tmpData = [{'priority' : 1}]
    
            let obj = new Blob( [JSON.stringify(tmpData)], { type: 'application/json'} );
    
            var formData = new FormData();
            formData.append('request', obj);
            formData.append('images', uploadFile);
    
            axios.put(
                getApiUrl('stores/'+storeDomain+'/images'),
                formData, 
                axios.defaults.headers.common['ContentType'] = "multipart/form-data",
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
            ).then(
                function(response){
                    if(response.status === 200){
                        window.location.reload();
                    }else{
                        alert('매장 사진이 변경되었습니다.');
                    }
                }
            ).catch(function(error){
                AlertError(error);
            })
        }
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //상점 정보
        // getStore();
        //아이템 정보
        // getSeleItem();
        // getStopItem();
        //리뷰
        // getStoreReview();

        try{
            setAccessToken();
        }catch(e){}

        // try{
        //     setNav1();
        // }catch(e){}

        // init Swiper:
        // const swiper = new Swiper('.TicketPhoto-Slider', {
        //     // Optional parameters
        //     loop: true,

        //     slidesPerView: 1,

        //     // If we need pagination
        //     pagination: {
        //         el: '.swiper-pagination.TS-pag',
        //         type: "fraction",
        //     },
        // });

        setStoreAuth(getStoreAuth());

        // console.log('+++++++'+localStorage.getItem('domainType'));
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    // if(localStorage.getItem('domainType') === 'USER' || localStorage.getItem('domainType') === null){
    //     // 승인전 승인 심사 페이지로 이동
    //     if(store && store.status.code === 4){
    //         navigate('/user/underreview');
    //     }
    // }

    // console.log(storeAuth);

    return (

        <>
        <div className='demo'>
            <div className="TicketPage">
                <div className="container-fluid pt-70px g-0">
                    <div className="stickybox">

                        <nav className="navbar bg-none fixed-top">
                            <div className="container-fluid">
                                <a className="navbar-brand hide" href="#">기본 텍스트</a>
                                <button className="Navbar-Toggler" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                    <img className="img-fluid" alt="메뉴"
                                        src="https://www.flexdaycdn.net/public/images/ticket/menu2.png" />
                                </button>
                                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                                    aria-labelledby="offcanvasNavbarLabel">
                                    <div className="offcanvas-btnbox pt-4 px-4 text-end">
                                        <button type="button" className="btn-close opacity-100 shadow-none"
                                            data-bs-dismiss="offcanvas" aria-label="Close">
                                            <img className="img-fluid close" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/close.png" />
                                        </button>
                                    </div>
                                    <div className="offcanvas-header p-4">
                                        <img className="img-fluid logo" alt=""
                                            src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                        <div className="offcanvas-title" id="offcanvasNavbarLabel">
                                            인제대학교 늘빛관(다인)
                                        </div>
                                    </div>
                                    <div className="offcanvas-body position-relative py-4-5">
                                        <div className="navbar-nav justify-content-end">
                                            <div className="nav-item">
                                                <Link to={"/demo/store/"+storeDomain} className="nav-link active d-flex align-items-center pb-4" aria-current="page"
                                                    href="#none">
                                                    <div className="imgbox position-relative">
                                                        <img className="img-fluid be img1" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket/home_02.png" />
                                                        <img className="img-fluid af img1" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket/home.png" />
                                                    </div>
                                                    <span>관리자 메인</span>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <a className="nav-link d-flex align-items-center pb-4" href="javascript:void(0)" onClick={()=>{openQRRead()}}>
                                                    <div className="imgbox position-relative">
                                                        <img className="img-fluid be img2" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket/qr_pay.png" />
                                                        <img className="img-fluid af img2" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket/qr_pay_02.png" />
                                                    </div>
                                                    <span>QR 결제</span>
                                                </a>
                                            </div>
                                            <div className="nav-item">
                                                <a className="nav-link d-flex align-items-center pb-4" href="/demo/storecalculate">
                                                    <div className="imgbox position-relative">
                                                        <img className="img-fluid be img3" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket/wallet.png" />
                                                        <img className="img-fluid af img3" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket/wallet_02.png" />
                                                    </div>
                                                    <span>매출 / 정산</span>
                                                </a>
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-default" onClick={ ()=> { localStorage.clear(); navigate("/demo/login") }}>로그아웃</button>
                                    </div>
                                </div>
                            </div>
                        </nav>

                        <div className="TicketPhoto">
                            <div className="TicketPhoto-Slider">
                                <Swiper loop={true} pagination={{ el: ".TS-pag", type: "fraction" }} modules={[Pagination, Navigation]} className="mySwiper d-flex">
                                    <SwiperSlide>
                                        <Image fluid className="w-100 StoreBg" alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main.png" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Image fluid className="w-100 StoreBg" alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main02.png" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Image fluid className="w-100 StoreBg" alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main03.png" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Image fluid className="w-100 StoreBg"  alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main04.png" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Image  fluid className="w-100StoreBg" alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main05.png" />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="TicketPhoto-Content d-flex">
                                <button type="button" className="btn btn-clear btn-camera">
                                    <img className="img-fluid" alt=""
                                        src="https://www.flexdaycdn.net/public/images/static/store-main/store_camera.png" />
                                </button>

                                <div className="PhotoCount swiper-pagination TS-pag">
                                </div>
                            </div>
                        </div>

                        <div className="StoreDetail pt-4-5">
                            <div className="StoreTitle align-items-center d-flex px-4-5">
                                <img className="img-fluid logo" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                <p className="mb-0 tit">
                                    인제대학교 늘빛관(다인)
                                </p>
                            </div>
                            <div className="StoreInfo  px-4-5">
                                <div className="g-0 row mb-2">
                                    <div className="col-2 tit">
                                        운영시간
                                    </div>
                                    <div className="col-10 desc">
                                        주중 - 11:00~18:00
                                        <br className="Mob" />
                                        (Breack time 14:30~15:30)
                                        <br />
                                        주말 - 휴무
                                    </div>
                                </div>
                                <div className="g-0 row">
                                    <div className="col-2 tit">
                                        위치안내
                                    </div>
                                    <div className="col-10 desc">
                                        늘빛관 1층
                                    </div>
                                </div>
                            </div>

                            <div className="StoreTab-wrap  px-4-5">
                                <div className="px-3">
                                    <nav className="StoreTab">
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-01"
                                                type="button" role="tab" aria-controls="nav-01" aria-selected="true">판매</button>
                                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#nav-02" type="button"
                                                role="tab" aria-controls="nav-02" aria-selected="false">판매중지</button>
                                        </div>
                                    </nav>

                                    <div className="StoreTab-content tab-content" id="nav-tabContent">
                                        
                                        <div className="tab-pane fade show active" id="nav-01" role="tabpanel"
                                            aria-labelledby="nav-01-tab" tabIndex="0">
                                            <div className="ProductList">
                                                <div className="row g-0">
                                                    <div className="col-7">
                                                        <p className="prd-tit">
                                                            A식단
                                                        </p>
                                                        <p className="prd-sub">
                                                            우엉조림 / 뱅글뱅글 쏘시지 / 잡곡밥 / 콩나물무침+배추김치 / 닭개장 / 딸바라떼
                                                        </p>
                                                        <p className="prd-price">
                                                            5,000원
                                                        </p>

                                                        <div className="prd-btnbox d-flex">
                                                            <button type="button"
                                                                className="btn type14 btn-light px-3 d-none">수정하기</button>
                                                            
                                                            <button type="button"
                                                                className="btn type13 btn-default px-3">판매중지</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-5">
                                                        <img className="img-fluid w-100"
                                                            src="https://www.flexdaycdn.net/public/images/ticket/menu_01.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div className="row g-0">
                                                    <div className="col-7">
                                                        <p className="prd-tit">
                                                            B식단
                                                        </p>
                                                        <p className="prd-sub">
                                                            함박스테이크 / 호밀 쨈파이 / 깍두기+피클 / 두부 파스타 / 옥수수스프 / 스위트 우리매실
                                                        </p>
                                                        <p className="prd-price">
                                                            6,000원
                                                        </p>
                                                        <div className="prd-btnbox d-flex">
                                                            <button type="button"
                                                                className="btn type14 btn-light px-3 d-none">수정하기</button>
                                                            
                                                            <button type="button"
                                                                className="btn type13 btn-default px-3">판매중지</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-5">
                                                        <img className="img-fluid w-100"
                                                            src="https://www.flexdaycdn.net/public/images/ticket/menu_02.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="tab-pane fade" id="nav-02" role="tabpanel" aria-labelledby="nav-02-tab"
                                            tabIndex="0">
                                            <div className="ProductList">
                                                <div className="row g-0">
                                                    <div className="col-7">
                                                        <p className="prd-tit">
                                                            복날 특선
                                                        </p>
                                                        <p className="prd-sub">
                                                            무더위에 지친 학생분들을 위해 특별식을 준비했습니다. 삼계탕 한 그릇과 함께 원기 회복 하세요!

                                                        </p>
                                                        <p className="prd-price">
                                                            5,000원
                                                        </p>
                                                        <div className="prd-btnbox d-flex">
                                                            <button type="button"
                                                                className="btn type14 btn-light  px-3">판매시작</button>
                                                            <button type="button"
                                                                className="btn type13 btn-default px-3">삭제</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-5">
                                                        <img className="img-fluid w-100"
                                                            src="https://www.flexdaycdn.net/public/images/ticket/store-menu.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div className="row g-0">
                                                    <div className="col-7">
                                                        <p className="prd-tit">
                                                            만우절 특선
                                                        </p>
                                                        <p className="prd-sub">
                                                            어떤걸 좋아할지 몰라서 다 준비했습니다. 랜덤으로 먹는 식단! 지금 바로 늘빛관으로 고고
                                                        </p>
                                                        <p className="prd-price">
                                                            6,000원
                                                        </p>
                                                        <div className="prd-btnbox d-flex">
                                                            <button type="button"
                                                                className="btn type14 btn-light px-3">판매시작</button>
                                                            <button type="button"
                                                                className="btn type13 btn-default px-3">삭제</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-5">
                                                        <img className="img-fluid w-100"
                                                            src="https://www.flexdaycdn.net/public/images/ticket/menu-event.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="StoreQR px-4-5 text-end">
                            <img className="img-fluid QR" alt="" src="https://www.flexdaycdn.net/public/images/ticket/scan.png" onClick={()=>{openQRRead()}} />
                        </div>
                    </div>

                    <div className="StorePay">
                        <Link to={"/demo/itemcreate"}
                            className="align-items-center btn btn-primary d-flex h-auto justify-content-center py-4">
                            <img className="img-fluid plus" alt="" src="https://www.flexdaycdn.net/public/images/ticket/plus.png" />
                            <span>교환권 추가</span>
                        </Link>
                    </div>

                </div>
            </div>
            
            </div>
        </>
    )
}