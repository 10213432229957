const length = 13;

const noticeListSample = Array.from({ length }, (_, i) => ({
  title: `${
    length - i
  } 플렉스데이 공지사항을 입력해주세요! 플렉스데이 공지사항 플렉스데이 공지사항 플렉스데이 공지사항`,
  contents: `공지사항 샘플내용입니다. ${length - i}`,
  createdAt: new Date(
    `2023-07-${19 - i}`.replace(/-/g, "/").replace("T", " ")
  ).toISOString(),
}));

export default noticeListSample;
