
import { Table, Container, Row, Col } from 'react-bootstrap';
import Serviceterms from './ServiceTerms';
import Userterms2 from './UserTerms2';
import ConsignmentTerms from './ConsignmentTerms';

export default function Footer(){
    return (
        <>
            <Container className="FooterArea mt-5">
                <Row>
                    <Col xs={{span:10, offset:1}}>
                        
                        <Table className='Footer'>
                            <tbody>
                                <tr>
                                    <td colSpan="2" className='TableTitle'><p>사업자 정보</p></td>
                                </tr>
                                <tr>
                                    <td>(주)플렉스데이</td>
                                    <td>대표이사 오명재</td>
                                </tr>
                                <tr>
                                    <td>주소</td>
                                    <td>(48548)부산 남구 전포대로 133 <br/>Wework BIFC 12층<br/>(주)플렉스데이</td>
                                </tr>
                                <tr>
                                    <td>사업자등록번호</td>
                                    <td>654-86-02546</td>
                                </tr>
                                <tr>
                                    <td>통신판매업번호</td>
                                    <td>제 2022-부산남구-0756 호</td>
                                </tr>
                                <tr>
                                    <td>이메일</td>
                                    <td>support@flex.day</td>
                                </tr>
                                <tr>
                                    <td>고객센터</td>
                                    <td>051-621-0203</td>
                                </tr>
                                <tr>
                                    <td colSpan='2'>
                                        이용 및 개인정보처리약관 <Serviceterms />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='2'>
                                        개인정보처리방침 <Userterms2 />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='2'>
                                        상품권 발행 및 판매 위탁 특약 <ConsignmentTerms />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}