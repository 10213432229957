import { Form , Col, Button } from 'react-bootstrap'

import './InputBox.css';

export default function InputBox(props){
    if( props.mode === 'BTNADDON' ){
        return (
            <>
                <Col xs={9}>
                    <Form.Group controlId={props.controlId}>
                        <Form.Label>{props.label}</Form.Label>
                        <Form.Control type={props.type} placeholder={props.placeholder} />
                        <Form.Text className="text-muted">{props.mute}</Form.Text>
                        {props.feedback !== undefined ? <div className="invalid-feedback">경고 및 안내 문구 필요 없을시 생략</div> : null}
                        {props.description !== undefined ? <div className="">{props.description}</div> : null}
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Button style={{marginTop:'24px'}} variant="outline-secondary" id="button-addon2">
                        {props.btntext}
                    </Button>
                </Col>
            </>
        )
    }
    return (
        <Col xs={{ span:12, offset:0 }}>
            <Form.Group controlId={props.controlId}>
                <Form.Label>{props.label}</Form.Label>
                <Form.Control type={props.type} placeholder={props.placeholder} />
                <Form.Text className="text-muted">{props.mute}</Form.Text>
                {props.feedback !== undefined ? <div className="invalid-feedback">경고 및 안내 문구 필요 없을시 생략</div> : null}
            </Form.Group>
        </Col>
    )
}