import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { AlertError, getApiUrl, PrintConsole } from '../../../function/common';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
import { DaumPostcodeEmbed } from 'react-daum-postcode';
import { setAccessToken, setNav4 } from './../../../api/ApiWebView';

export default function StoreInfo(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    
    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    const [store, setStore] = useState();
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        console.log(e.target.value);
        setData({
            ...store,
            [e.target.id]: e.target.value,
        });
        // PrintConsole(data);
    };

    function getStore() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain') ),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    setStore(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    //주소 관련
    const [isOpenPost, setIsOpenPost] = useState(false);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };
    
    const onCompletePost = (mapData) => {
        let fullAddress = mapData.address;
        let extraAddr = '';
    
        if (mapData.addressType === 'R') {
            if (mapData.bname !== '') {
                extraAddr += mapData.bname;
            }
            if (mapData.buildingName !== '') {
                extraAddr += extraAddr !== '' ? `, ${mapData.buildingName}` : mapData.buildingName;
            }
            fullAddress += extraAddr !== '' ? ` (${extraAddr})` : '';
        }
        setIsOpenPost(false);

        document.getElementById('zipCode').value = mapData.zonecode;
        document.getElementById('address').value = fullAddress;
    };

    const [res, setRes] = useState();
    function getData() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('common/business-class-type') ).then(
                function(response){
                    console.log(response);
                    setRes(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
            // reject(new Error("Request is failed"));
        });
    };

    const [businessClass, setBusinessClass] = useState([]);

    const onCheckData = (e) => {
        if(e.currentTarget.checked){
            setBusinessClass(e.target.value);
            console.log(e.target.value);
            if(e.target.value === '외식'){
                
            }else{
                document.getElementById('originArea').classList.add('Hide');
                // document.getElementById('origin').textContent = '';
                setData({...data, origin : ''});
            }
        }else{
        }
        setRadioCheck();
    };

    async function submit(){

        document.getElementById('submitBtn').setAttribute("disabled","true");

        if(
            document.getElementById('storeName').value === null ||
            document.getElementById('storeName').value === undefined ||
            document.getElementById('storeName').value === '' ||
            document.getElementById('storeName').value >= 4
        ){
            alert('매장이름을 확인 해주세요.')
            return false;
        }

        let obj = { 
            'addressDTO': {
                'zipCode': document.getElementById('zipCode').value,
                'address': document.getElementById('address').value,
                'addressDetail': document.getElementById('addressDetail').value
            },
            'storePhoneNumber': document.getElementById('storePhoneNumber').value,
            'storeName': document.getElementById('storeName').value,
            'businessHours': document.getElementById('businessHours').value,
            'holidays': document.getElementById('holidays').value,
            'businessClass': [businessClass],
            'originLabelling' :document.getElementById('origin').value
        }

        axios.put(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/store-info'), 
            obj, 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    alert('수정이 완료하였습니다.');
                    navigate('/store/'+localStorage.getItem('storeDomain'));
                }
            }
        ).catch(function(error){
            AlertError(error);
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    function setRadioCheck(){
        let TmpLength = document.querySelectorAll('input[type=radio][name="businessClass"]:checked');
        console.log(TmpLength);
        
    }

    useEffect( () => {
        if(businessClass === '외식'){
            document.getElementById('originArea').classList.remove('Hide');
        }else{
            // document.getElementById('originArea').classList.add('Hide');
        }
    }, [businessClass])

    async function getAuthority(){
        //권한
        axios.get(
            getApiUrl('users/me/stores/'+localStorage.getItem('storeDomain')), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    if(response.data.type['code'] !== 1){
                        //사장외 권한 없슴
                        alert('권한이 없습니다.');
                        navigate(-1);
                    }
                }
            }
        ).catch(function(error){
            AlertError(error);
            navigate(-1);
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        //권한
        getAuthority();

        getStore();
        getData();

        try{
            setAccessToken();
        }catch(e){}
        try{
            setNav4();
        }catch(e){}

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    useEffect( () => {
        console.log('store');
        setRadioCheck();
        // console.log(store && store.businessClass);
        setBusinessClass(store && store.businessClass);
    }, [store]);

    if (loading) return <></>

    return (
        <>
            <Container>
                    <Btn type="NAVBACK" title="사업장 정보 수정" />
                    <Row className='p-1 mt-5'>
                        <Col xs={{ span:10, offset:1 }}>
                            {isOpenPost  ? (
                                <div>
                                    <DaumPostcodeEmbed className='postCodeStyle' autoClose onComplete={onCompletePost } />
                                </div>
                            ) : null}
                            <Form.Group className="xs-12" controlId="zipCode">
                                <Form.Label>우편번호 <span className='PointText'>*</span></Form.Label>
                                <Form.Control type="text" defaultValue={store && store.storeAddress.zipCode} placeholder="지번, 도로명, 건물명으로 검색" onChange={onChangeData} onClick={onChangeOpenPost} readOnly/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='p-1'>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group className="xs-12" controlId="address">
                                <Form.Label>기본주소 <span className='PointText'>*</span></Form.Label>
                                <Form.Control type="text" defaultValue={store && store.storeAddress.address} placeholder="지번, 도로명, 건물명으로 검색" onChange={onChangeData} readOnly/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='p-1'>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group className="xs-12" controlId="addressDetail">
                                <Form.Label>상세주소</Form.Label>
                                <Form.Control type="text" defaultValue={store && store.storeAddress.addressDetail} placeholder="예)플렉스상가 101동 101호 플레스카페" onChange={onChangeData}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='ColorArea'>
                        <Col xs={{ span:10, offset:1 }}>
                            <ul>
                                <li><h4>이렇게 검색해 보세요.</h4></li>
                            </ul>

                            <ul>
                                <li>- 도로명 + 건물번호</li>
                                <li>예) 플레스로 12길 3</li>
                            </ul>

                            <ul>
                                <li>- 지역명 + 번지</li>
                                <li>예) 플레스동 12-3</li>
                            </ul>

                            <ul>
                                <li>- 건물명</li>
                                <li>예) 플레스로 12길 3</li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className='p-1'>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group className="xs-12" controlId="storeName">
                                <Form.Label>매장이름  <span className='PointText'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="매장명을 입력하세요." defaultValue={store && store['storeName'] || ''} onChange={onChangeData}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='p-1'>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group className="xs-12" controlId="businessHours">
                                <Form.Label>운영시간</Form.Label>
                                <Form.Control type="text" defaultValue={store && store['businessHours'] || ''} placeholder="예)오전11:00~오후12:00,주말:오전10:00~오후11:00" onChange={onChangeData}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='p-1'>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group className="xs-12" controlId="storePhoneNumber">
                                <Form.Label>매장 전화번호</Form.Label>
                                <Form.Control type="number" placeholder="- 없이 숫자만 입력" defaultValue={store && store.storePhoneNumber || ''} onChange={onChangeData} onKeyDown={(e)=> e.key === 'e' && e.preventDefault()}/>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='p-1'>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group className="xs-12" controlId="holidays">
                                <Form.Label>휴무일</Form.Label>
                                <Form.Control type="text" defaultValue={store && store['holiday'] || ''} placeholder="예) 매월 16일 휴무, 연중무휴" onChange={onChangeData}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='p-1'>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Label>업태 <span className='PointText'>*</span></Form.Label>
                            {/* <div direction="horizontal" gap={1}> */}
                            <div className="d-flex flex-wrap justify-content-start">
                                {
                                    res && res.map( row => (
                                        <div className='p-1' key={row}>
                                            <input type='radio' className="btn-check" name='businessClass' autoComplete="off" id={row} defaultValue={row} onChange={ onCheckData } checked={businessClass && businessClass === row ? true : false}/>
                                            <label htmlFor={row} className="btn btn-default btn-radio" name='businessClass' id={row} >{row}</label>
                                        </div>
                                    ) )
                                }
                            </div>
                        </Col>
                    </Row>

                    <Row className='p-1 Hide' id="originArea">
                        <Col xs={{span:10, offset:1}}>
                            <Form.Group className="xs-12" controlId="origin">
                                <Form.Label>원산지 <span className='PointText'>*</span></Form.Label>
                                <Form.Control as="textarea" placeholder="식자재의 원산지를 입력해 주세요. (예 : 김치(국산)/어묵(국산))" defaultValue={store && store.originLabelling} rows={2} onChange={onChangeData}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col xs={{ span:10, offset:1 }} className='mb-3'>
                            <div className="d-grid gap-1 mt-2">
                                <Button variant="primary" size="lg" id='submitBtn' onClick={ submit }>
                                    정보 수정
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </Container>
        </>
    )
}