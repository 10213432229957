import { Col, Container, Dropdown, DropdownButton, Row, Stack } from "react-bootstrap";
import Switch from "../../components/common/Swtich";
import InputBox from './../../components/InputBox';

export default function Management( props ){

    return (
        <>
            <Container>
                
                <Row>
                    <Col className='d-grid'>
                        <InputBox controlId="text" type="text" label="" placeholder="직원검색" mute="" l/>
                    </Col>
                </Row>
                
                <Row>
                    <Col className='d-grid'>
                        <button className = "btn btn-default" onClick={ () => ( alert('직원추가') )}>직원추가</button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <DropdownButton id="dropdown-button" title="김과장" size="sm">
                            <Dropdown.Item href="#/action-1">수정</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">삭제</Dropdown.Item>
                        </DropdownButton>
                        
                        <Stack direction="horizontal" gap={2}><div>결제관련</div> <div className="ms-auto"><Switch /></div></Stack>
                        <Stack direction="horizontal" gap={2}><div>결제관련</div> <div className="ms-auto"><Switch /></div></Stack>
                        <Stack direction="horizontal" gap={2}><div>결제관련</div> <div className="ms-auto"><Switch /></div></Stack>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <DropdownButton id="dropdown-button" title="김과장" size="sm">
                            <Dropdown.Item href="#/action-1">수정</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">삭제</Dropdown.Item>
                        </DropdownButton>
                        
                        <Stack direction="horizontal" gap={2}><div>결제관련</div> <div className="ms-auto"><Switch checked="true" name='userid2' value='1'  /></div></Stack>
                        <Stack direction="horizontal" gap={2}><div>결제관련</div> <div className="ms-auto"><Switch checked="" name='userid2' value='2'/></div></Stack>
                        <Stack direction="horizontal" gap={2}><div>결제관련</div> <div className="ms-auto"><Switch checked="true" name='userid2' value='3'/></div></Stack>
                    </Col>
                </Row>

            </Container>
        </>
    )
}