import React from "react";
import { css, styled } from "styled-components";
import Text from "../atom/Text";
import { color } from "../styles/theme";

const Container = styled.div`
  height: 100%;
  padding-bottom: 1.8rem;
  text-align: center;
  border-bottom: 2px solid
    ${(p) =>
      p.selected
        ? p.$type === "storeDetail"
          ? color.gray.d900
          : color.primary.flexRed
        : "transparent"};
  ${(p) =>
    p.$type === "storeDetail" &&
    css`
      flex: 1;
    `}
  ${(p) => p.$styles};
`;

/**
 * Tab 구성요소
 * @typedef {object} TabProperty
 * @property {string} text
 * @property {void} onClick
 * - onClick 필요할 때 있으면 사용
 * @property {boolean=} selected
 * @property {"default"|"storeDetail"} type
 * @property {any=} tabContainerStyle
 * @property {any=} tabContentStyle
 * @property {any=} customIndicator
 *
 * @param {TabProperty}
 */
const Tab = ({
  onClick,
  text,
  selected,
  type,
  tabContainerStyle,
  tabContentStyle,
  customIndicator,
}) => {
  const textColorFormatter = () => {
    if (type === "storeDetail") {
      return selected ? color.gray.d900 : color.gray.d500;
    } else {
      return selected ? color.primary.flexRed : color.gray.d900;
    }
  };
  return (
    <Container
      $type={type}
      selected={customIndicator ? null : selected}
      onClick={() => (onClick ? onClick() : null)}
      $styles={tabContainerStyle}
    >
      <Text
        styles={css`
          font-family: ${selected ? "Pretendard Bold" : "Pretendard Medium"};
          font-size: 1.8rem;
          line-height: 1.7rem;
          letter-spacing: -0.05rem;
          ${tabContentStyle};
        `}
        color={textColorFormatter}
      >
        {text}
      </Text>
      {selected && customIndicator && customIndicator}
    </Container>
  );
};

export default Tab;
