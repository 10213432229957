import dayjs from "dayjs";

const TEST_PRODUCT_DEFAULT_INFO = {
  storeName: "테스트 상점",
  couponNumber: {
    AMOUNT: "123456789999",
    VOUCHER: "123456789998",
    COUNT: "123456789997",
  },
  expireDate: dayjs().set("month", 11).set("date", 31).format("YYYY-MM-DD"),
  issuedDate: dayjs().set("month", 0).set("date", 1).format("YYYY-MM-DD"),
  canCancelUseDate:
    dayjs().add(1, "year").set("month", 0).set("date", 1).format("YYYY-MM-DD") +
    "T01:00:00",
  productImage: require("./img/testModeCardImg.jpeg"),
  ownerName: "테스트 사장",
  fromName: "테스터1",
  toName: "테스터1",
};

const TEST_PRODUCT = {
  AMOUNT: {
    storeName: TEST_PRODUCT_DEFAULT_INFO.storeName,
    productName: "테스트 금액권",
    price: 10000,
    couponNumber: TEST_PRODUCT_DEFAULT_INFO.couponNumber.AMOUNT,
    status: {
      code: 1,
      name: "미사용",
      description: "미사용",
    },
    expireDate: TEST_PRODUCT_DEFAULT_INFO.expireDate,
    issuedDate: TEST_PRODUCT_DEFAULT_INFO.issuedDate,
    canCancelUseDate: TEST_PRODUCT_DEFAULT_INFO.canCancelUseDate,
    used: false,
    templateType: {
      name: null,
      code: 1,
      description: "일반템플릿",
    },
    productType: {
      code: 2,
      description: "금액권",
    },
    balance: 10000,
    fromName: TEST_PRODUCT_DEFAULT_INFO.fromName,
    toName: TEST_PRODUCT_DEFAULT_INFO.toName,
    message: "",
    orderNumber: "",
    storeAddress: {
      zipCode: "48059",
      address: "부산 해운대구 센텀중앙로 78",
      addressDetail: "3층 303호",
    },
    singleStore: true,
    totalCount: 0,
    remainingCount: 0,
    store: {
      storeName: TEST_PRODUCT_DEFAULT_INFO.storeName,
      storePhoneNumber: "01012341234",
      businessTimes: {
        "mon,tue,wed,thu,fri": {
          startTime: "10:00",
          endTime: "19:00",
          breakStartTime: null,
          breakEndTime: null,
        },
      },
      ownerName: TEST_PRODUCT_DEFAULT_INFO.ownerName,
      businessNumber: "1231231231",
    },
    stampDuty: 0,
    paymentInfo: null,
    rewardPrice: 2000,
    rewardBalance: 2000,
    productImage: TEST_PRODUCT_DEFAULT_INFO.productImage,
    priceInfo: {
      facePrice: 12000,
      storeSalesPrice: 10000,
      storeSettlePrice: 0,
      fee: 0,
      feeRate: 0,
      stampDuty: 0,
      vat: 0,
      supplyOfFee: 0,
      totalCount: 0,
      remainingCount: 0,
      rewardPrice: 2000,
      rewardBalance: 2000,
    },
    useReward: true,
  },
  VOUCHER: {
    storeName: TEST_PRODUCT_DEFAULT_INFO.storeName,
    productName: "테스트 교환권",
    price: 10000,
    couponNumber: TEST_PRODUCT_DEFAULT_INFO.couponNumber.VOUCHER,
    status: {
      code: 1,
      name: "미사용",
      description: "미사용",
    },
    expireDate: TEST_PRODUCT_DEFAULT_INFO.expireDate,
    issuedDate: TEST_PRODUCT_DEFAULT_INFO.issuedDate,
    canCancelUseDate: TEST_PRODUCT_DEFAULT_INFO.canCancelUseDate,
    used: false,
    templateType: {
      name: null,
      code: 1,
      description: "일반템플릿",
    },
    productType: {
      code: 1,
      description: "교환권",
    },
    balance: 10000,
    fromName: TEST_PRODUCT_DEFAULT_INFO.fromName,
    toName: TEST_PRODUCT_DEFAULT_INFO.toName,
    message: "",
    orderNumber: "",
    storeAddress: {
      zipCode: "48059",
      address: "부산 해운대구 센텀중앙로 78",
      addressDetail: "3층 303호",
    },
    singleStore: true,
    totalCount: 0,
    remainingCount: 0,
    store: {
      storeName: TEST_PRODUCT_DEFAULT_INFO.storeName,
      storePhoneNumber: "01012341234",
      businessTimes: {
        "mon,tue,wed,thu,fri": {
          startTime: "10:00",
          endTime: "19:00",
          breakStartTime: null,
          breakEndTime: null,
        },
      },
      ownerName: TEST_PRODUCT_DEFAULT_INFO.ownerName,
      businessNumber: "1231231231",
    },
    stampDuty: 0,
    paymentInfo: null,
    rewardPrice: 0,
    rewardBalance: 0,
    productImage: TEST_PRODUCT_DEFAULT_INFO.productImage,
    priceInfo: {
      facePrice: 12000,
      storeSalesPrice: 10000,
      storeSettlePrice: 0,
      fee: 0,
      feeRate: 0,
      stampDuty: 0,
      vat: 0,
      supplyOfFee: 0,
      totalCount: 0,
      remainingCount: 0,
    },
  },
  COUNT: {
    storeName: TEST_PRODUCT_DEFAULT_INFO.storeName,
    productName: "테스트 횟수권",
    price: 10000,
    couponNumber: TEST_PRODUCT_DEFAULT_INFO.couponNumber.COUNT,
    status: {
      code: 1,
      name: "미사용",
      description: "미사용",
    },
    expireDate: TEST_PRODUCT_DEFAULT_INFO.expireDate,
    issuedDate: TEST_PRODUCT_DEFAULT_INFO.issuedDate,
    canCancelUseDate: TEST_PRODUCT_DEFAULT_INFO.canCancelUseDate,
    used: false,
    templateType: {
      name: null,
      code: 1,
      description: "일반템플릿",
    },
    productType: {
      code: 3,
      description: "횟수권",
    },
    balance: 10000,
    fromName: TEST_PRODUCT_DEFAULT_INFO.fromName,
    toName: TEST_PRODUCT_DEFAULT_INFO.toName,
    message: "",
    orderNumber: "",
    storeAddress: {
      zipCode: "48059",
      address: "부산 해운대구 센텀중앙로 78",
      addressDetail: "3층 303호",
    },
    singleStore: true,
    totalCount: 12,
    remainingCount: 12,
    store: {
      storeName: TEST_PRODUCT_DEFAULT_INFO.storeName,
      storePhoneNumber: "01012341234",
      businessTimes: {
        "mon,tue,wed,thu,fri": {
          startTime: "10:00",
          endTime: "19:00",
          breakStartTime: null,
          breakEndTime: null,
        },
      },
      ownerName: TEST_PRODUCT_DEFAULT_INFO.ownerName,
      businessNumber: "1231231231",
    },
    stampDuty: 0,
    paymentInfo: null,
    rewardPrice: 0,
    rewardBalance: 0,
    productImage: TEST_PRODUCT_DEFAULT_INFO.productImage,
    priceInfo: {
      facePrice: 12000,
      storeSalesPrice: 10000,
      storeSettlePrice: 0,
      fee: 0,
      feeRate: 0,
      stampDuty: 0,
      vat: 0,
      supplyOfFee: 0,
      totalCount: 12,
      remainingCount: 12,
    },
  },
};

export default TEST_PRODUCT;
