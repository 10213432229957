import React from "react";
import ActionListItem from "../molecule/ActionListItem";
import { css, styled } from "styled-components";
import Text from "../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../components/ver1.5/styles/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.$containerStyle};
`;

const ActionListCommon = ({
  rows = [],
  fontStyle,
  itemVerticalPadding,
  containerStyle,
  title,
  onClickContainer,
}) => {
  return (
    <Container $containerStyle={containerStyle}>
      {title && (
        <Text
          fontStyle={font.body1}
          color={color.gray.d900}
          styles={css`
            padding: 1.6rem 0;
          `}
        >
          {title}
        </Text>
      )}
      {rows.map((row, index) => (
        <ActionListItem
          key={index}
          isLast={index === rows.length - 1}
          text={row.text}
          subText={row.subText ? row.subText : null}
          fontStyle={fontStyle}
          containerStyle={css`
            padding: ${itemVerticalPadding ?? 0} 0;
          `}
          rightContent={row.rightContent}
          onClickContainer={() =>
            onClickContainer ? onClickContainer(row) : null
          }
        />
      ))}
    </Container>
  );
};

export default ActionListCommon;
