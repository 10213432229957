import React, { useEffect, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import {
  headerFooterZIndex,
  presetHeight,
} from "../../../../components/ver1.5/constants";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import CouponTopContent from "../molecule/CouponTopContent";
import Button from "../../../../components/ver1.5/atom/Button";
import CouponInfoContent from "../molecule/CouponInfoContent";
import Divider from "../../../../components/ver1.5/atom/Divider";
import MapContent from "../molecule/MapContent";
import { commonInfoContents } from "../../Store/Detail/components/organism/detailInfoContentList";
import DetailInfoItem from "../../Store/Detail/components/molecule/DetailInfoItem";
import useCapture from "../../../../components/ver1.5/hooks/useCapture";
import CouponImage from "./CouponImage";
import DefaultLogo from "../../../../components/ver1.5/assets/img/shopProfileImgDefault.svg";
import DefaultMenuImg from "../../../../components/ver1.5/assets/img/menuItemDefault.svg";
import OneBtnModal from "../../../../components/ver1.5/organism/OneBtnModal";
import { randomUsername } from "korean-random-username";
import ModalLayout from "../../../../components/ver1.5/molecule/ModalLayout";
import Text from "../../../../components/ver1.5/atom/Text";
import { ShareIcon } from "../../../../components/ver1.5/assets/icons";
import TabList from "../../../../components/ver1.5/organism/TabList";
import CouponHistoryInfo from "../molecule/CouponHistoryInfo";
import HistoryTable from "../molecule/HistoryTable";
import axios from "axios";
import { getApiUrl, phoneNumberConvert } from "../../../../function/common";
import dayjs from "dayjs";
import OperationDisplay from "../molecule/OperationDisplay";
import { ColumnContainer } from "../../../../components/ver1.5/styles/Ver2GlobalStyles";

const BtnWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  background-color: ${color.primary.white};
  z-index: ${headerFooterZIndex};
  display: flex;
  gap: 1rem;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CommonInfoContainer = styled.div`
  background: ${color.primary.white};
`;

const TextContainer = styled.div`
  padding: 0 1.6rem;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.6rem;
`;

const CouponPage = ({ data, code, isTestMode }) => {
  const receiverName = Boolean(data.toName) ? data.toName : "나";
  const message = Boolean(data.message) ? data.message : null;
  const commonInfo = commonInfoContents(
    data.storeName,
    data.paymentChannel,
    data.storePhoneNumber,
    data?.expireDate
  );
  const qrImgUrl =
    process.env.REACT_APP_API + `common/qrcode?data=${code}&size=300`;
  const { ref: couponImageRef, capture } = useCapture(
    `${data.storeName}-${data.productName}`
  );
  const [saveModal, setSaveModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);

  const [currentTab, setCurrentTab] = useState("coupon");
  const [historyRows, setHistoryRows] = useState([]);
  const initialHistoryQueryDateString = dayjs().format("YYYY-MM-DD");
  const [historyQueryDate, setHistoryQueryDate] = useState(
    initialHistoryQueryDateString
  );
  const [businessHours, setBusinessHours] = useState([]);

  const getMobileOperatingSystem = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "AOS";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "IOS";
    }
    return "unknown";
  };

  const getUsedHistory = async () => {
    await axios
      .get(getApiUrl(`coupons/new-qrcode/${code}/logs`), {
        data: {
          queryDate: historyQueryDate,
        },
      })
      .then((res) => {
        const { data: resData } = res.data;
        setHistoryRows(
          resData.map((item) => ({
            ...item,
            storeName: data?.storeName ?? "-",
          }))
        );
      });
  };

  useEffect(() => {
    if (isTestMode) return;
    if (currentTab === "history") {
      getUsedHistory();
    }
    // eslint-disable-next-line
  }, [currentTab]);

  useEffect(() => {
    if (Boolean(data?.businessTimes)) {
      const businessTimesArrConvert = Object.entries(data.businessTimes);
      setBusinessHours(
        businessTimesArrConvert.map((entry) => {
          const {
            startTime: startBusinessHours,
            endTime: endBusinessHours,
            breakStartTime: startBreakHours,
            breakEndTime: endBreakHours,
          } = entry[1];
          return {
            selectedDays: entry[0].split(","),
            startBusinessHours,
            endBusinessHours,
            startBreakHours,
            endBreakHours,
          };
        })
      );
    }
  }, [data.businessTimes]);

  return (
    <Layout
      styles={css`
        /* background-color: #fcf5f4; */
        /* background-color: #f9f9f9; */
        background-color: #fff;
        padding-top: ${`calc(${presetHeight.header} + 1.6rem)`};
        padding-bottom: ${currentTab === "coupon"
          ? `calc(${presetHeight.bottomNav} + 0.8rem)`
          : "0"};
        display: flex;
        flex-direction: column;
        gap: ${currentTab === "coupon" ? "1.6rem" : 0};
        align-items: center;
      `}
    >
      {data?.productType?.code !== 1 && (
        <TabList
          rows={[
            {
              name: "E쿠폰",
              value: "coupon",
            },
            {
              name: "사용내역",
              value: "history",
            },
          ]}
          currentTab={currentTab}
          type="storeDetail"
          tabListContainerStyle={css`
            background: transparent;
            width: 100%;
          `}
          tabContainerStyle={css`
            flex: 0;
            width: fit-content;
          `}
          tabContentStyle={css`
            word-break: keep-all;
          `}
          onClick={({ value }) => setCurrentTab(value)}
        />
      )}
      {currentTab === "coupon" && (
        <>
          <CouponTopContent
            senderName={data.fromName ?? randomUsername()}
            receiverName={receiverName}
            message={message}
            productImage={data.representImageUrl}
            storeName={data.storeName}
            productName={data.productName}
          />

          <InfoContainer>
            <CouponInfoContent
              productPrice={data.price}
              productImage={data.representImageUrl ?? data.productImage}
              productBalance={data.balance}
              productRewardBalance={data.rewardBalance}
              productName={data.productName}
              productCardColor={color.gray.d100}
              productTextColor={color.primary.dark}
              couponNumber={data.couponNumber}
              expireDate={data.expireDate}
              orderNumber={data.orderNumber}
              storeName={data.storeName}
              issuedDate={data.issuedDate}
              productType={data.productType}
              productStatus={data.status}
              onClickCapture={async () => {
                const agent = navigator.userAgent.toLowerCase();
                const os = getMobileOperatingSystem();
                if (os !== "unknown") {
                  if (agent.match(/kakaotalk/i)) {
                    if (os === "IOS") {
                      return setDisableModal(true);
                    }
                    if (os === "AOS") {
                      window.location.href =
                        "intent://" +
                        process.env.REACT_APP_BASEURL.replace(
                          /https?:\/\//i,
                          ""
                        ) +
                        "newAlarmTalk/qrcode/" +
                        code +
                        "#Intent;scheme=http;package=com.android.chrome;end";
                    }
                  } else {
                    await capture();
                  }
                } else {
                  await capture();
                }
                setSaveModal(true);
              }}
              qrImgUrl={qrImgUrl}
              used={data.used}
              singleStore={data.singleStore}
            />
            <Divider
              direction="horizontal"
              width={"1.6rem"}
              // color={color.gray.d50}
              color={"transparent"}
            />
            <TextContainer>
              <Text fontStyle={font.subtitle3} color={color.gray.d900}>
                사용처 정보
              </Text>
              <Text
                fontStyle={font.subtitle4}
                color={color.gray.d500}
                marginTop="1.2rem"
              >
                위치 안내
              </Text>
              <Text fontStyle={font.caption2} color={color.gray.d600}>
                {data.storeAddress?.address}
              </Text>
            </TextContainer>
            {data.storeAddress && data.singleStore && (
              <MapContent address={data.storeAddress?.address} />
            )}
            {data?.businessTimes && (
              <DetailInfoItem
                sectionTitle={"영업시간 안내"}
                isAccordion
                titleFontStyle={font.subtitle4}
                titleColor={color.gray.d600}
                isLast
                customContent={
                  <OperationDisplay businessHours={businessHours} />
                }
                initialOpen
              />
            )}
            <ColumnContainer style={{ padding: "1.6rem", gap: "0.8rem" }}>
              <Text fontStyle={font.subtitle4} color={color.gray.d600}>
                사용처 고객센터
              </Text>
              <Text fontStyle={font.caption2}>
                {Boolean(data.store?.storePhoneNumber)
                  ? phoneNumberConvert(data.store?.storePhoneNumber)
                  : "-"}
              </Text>
            </ColumnContainer>
            <CommonInfoContainer>
              {commonInfo.map((info, index) => (
                <DetailInfoItem
                  key={index}
                  sectionTitle={info.title}
                  contents={info.content}
                  titleWidth={info.titleWidth}
                  isAccordion={info.isAccordion}
                  isLast={index === commonInfo.length - 1}
                  dividerWidth={"0.1rem"}
                />
              ))}
            </CommonInfoContainer>
          </InfoContainer>
          <BtnWrapper>
            <Button
              text="나도 선물할래요"
              onClick={() => window.open("https://smartstore.naver.com/fxd")}
            />
            <Button
              text="멤버십가입"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_STORE_WEB_URL +
                    "membership/register/" +
                    data?.storeId
                )
              }
              variant="outline"
            />
          </BtnWrapper>
        </>
      )}
      {currentTab === "history" && (
        <>
          <CouponHistoryInfo
            productType={data.productType}
            balance={data.balance}
            rewardBalance={data.rewardBalance}
            remainingCount={data.remainingCount}
          />
          <HistoryTable
            rows={historyRows}
            queryDate={historyQueryDate}
            initialDateString={initialHistoryQueryDateString}
            issuedAt={data?.issuedDate}
            onClickRadio={(dateString) => {
              setHistoryQueryDate(dateString);
              getUsedHistory();
            }}
          />
        </>
      )}
      <CouponImage
        ref={couponImageRef}
        productImage={data.representImageUrl ?? DefaultMenuImg}
        productName={data.productName}
        storeName={data.storeName}
        qrImage={qrImgUrl}
        logoUrl={data.storeLogoUrl ?? DefaultLogo}
        expireDate={data.expireDate}
        orderNumber={data.orderNumber}
        couponNumber={data.couponNumber}
        productPrice={data.price}
        productCardColor={color.gray.d100}
        productTextColor={color.primary.dark}
        productType={data.productType}
        productBalance={data.balance}
      />
      {saveModal && (
        <OneBtnModal
          text={
            <Text
              fontStyle={font.body1}
              styles={css`
                text-align: center;
                word-break: keep-all;
              `}
            >
              다운로드가 완료되었습니다.
              <br />
              모바일 갤러리에서 확인이 안될 경우,
              <br />
              [파일(내 파일){` > `}다운로드] 항목에서 확인하실 수 있습니다.
            </Text>
          }
          onAction={() => setSaveModal(false)}
        />
      )}
      {disableModal && (
        <ModalLayout onClickOverlay={() => setDisableModal(false)}>
          <Text
            fontStyle={font.caption2}
            color={color.gray.d800}
            styles={css`
              text-align: center;
            `}
            marginBottom="2.4rem"
          >
            <pre>
              {`인앱브라우저 호환 문제로 인해\n외부 브라우저로 접속해야합니다.\n아래 `}
              {<ShareIcon color={color.gray.d800} size={"1.4rem"} />}
              {` 버튼을 눌러\nsafari로 접속해주시거나\n링크를 복사하여 접속해주시면 감사하겠습니다`}
            </pre>
          </Text>
        </ModalLayout>
      )}
    </Layout>
  );
};

export default CouponPage;
