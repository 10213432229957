import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import CheckBox from '../../../components/common/CheckBox';

// import ListGroupH from '../../../components/ListGroupH';
// import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState } from 'react';
// import { getImgUrl, LocationUrl } from '../../../function/common';
// import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
// import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
// import SideNav from './../../../components/common/SideNav';

import './Login.css';
import { PrintConsole, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

export default function Login(){
    const navigate = useNavigate();

    const [account, setAccount] = useState({
        Id: "",
        Password: "",
        clientId: '10000'
    });

    const onChangeAccount = (e) => {
        //console.log(e.target.value);
        setAccount({
          ...account,
          [e.target.id]: e.target.value,
        });
    };

    useEffect( () => {
        // localStorage.removeItem('accessToken');

        if( localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null ){
            //토큰 있을때 추가 작업할 일 있을 시 사용
            window.location.href='/demo/userstore/storeurl';
        }else{
            // navigate('/user/search');
        }

        // try{
        //     setNav3();
        // }catch(e){}
    })

    const loginBtn = async () => {

        try{
            document.getElementById('submitBtn').setAttribute("disabled","true");

            await GetAuthorize(account);
                        
            sleep(500);
            PrintConsole("localStorage : " + localStorage.getItem('accessToken'));
            PrintConsole("localStorage : " + localStorage.getItem('refreshToken'));
            
            let resUserMe = await GetUserMe(account);
            
            sleep(500);
            try{
                localStorage.setItem('domainType', resUserMe.data.domainType);
                localStorage.setItem('name', resUserMe.data.name);
                localStorage.setItem('myStores' , JSON.stringify(resUserMe.data.myStores[0]));
                localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
            }catch(e){
                console.log('mystore 없음 스텝 or 가입안함');
                localStorage.setItem('storeDomain', null);
            }
            
            console.log('//////////////////////////////////');
            console.log(resUserMe);

            window.location.href='/demo/userstore/storeurl';
            // navigate('/demo/userstore/lMAGxwTXJnys');

            
            document.getElementById('submitBtn').removeAttribute("disabled");
        }catch(e){
            console.log(e);
            document.getElementById('submitBtn').removeAttribute("disabled");
        }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter') {
            loginBtn();
        }
    }

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
            <div className="TLoginPage">
                <div className="container position-absolute start-50 top-50 translate-middle w-100">
                    <div className="Inner m-auto p-2">
                        <div className="LogoImg text-center">
                            <img src="https://www.flexdaycdn.net/public/images/ticket/inje_logo2.png" alt="FLEX" />
                        </div>
                        <div className="InfoSearch d-flex justify-content-end">
                            <a href="#none">아이디 찾기</a>
                            <a href="#none">비밀번호 찾기</a>
                        </div>
                        <div className="LoginBox">
                            <div className="row">
                                <input type="text" className="form-control InputType" placeholder="아이디를 입력해주세요." id='Id' onChange={onChangeAccount} onKeyPress={onKeyPress}/>
                            </div>
                            <div className="row">
                                <input type="password" className="form-control InputType" placeholder="비밀번호를 입력해주세요." id='Password' onChange={onChangeAccount} onKeyPress={onKeyPress}/>
                            </div>
                            <button type="button" className="btn btn-primary" id='submitBtn' onClick={ (e) => { loginBtn(); } }>로그인</button>
                            <button type="button" className="btn btn-default">회원가입</button>

                        </div>
                        <div className="JoinBox mt-5">
                            <p className="InfoTxt mb-2">
                                회원이 아니신가요?
                            </p>
                            <a href="#none" className="btn btn-light type15">
                                비회원 구매
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}