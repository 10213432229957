import React, { useEffect, useState } from "react";
import CheckPhone from "./organism/CheckPhone";
import { useLocation, useMatch } from "react-router-dom";
import CouponPage from "./organism/CouponPage";
import TEST_PRODUCT from "../../../components/ver1.5/assets/TEST_MODE_PRODUCTS";

const NewAlarmTalk = () => {
  const { pathname } = useLocation();
  const isTestMode = pathname.includes("/test/");
  const code = useMatch(
    "/newAlarmTalk/qrcode/" + (isTestMode ? "test/:code" : ":code")
  )?.params?.code;
  const [step, setStep] = useState(1);
  const [data, setData] = useState();

  useEffect(() => {
    if (isTestMode) {
      setData({
        ...TEST_PRODUCT[code],
      });
      setStep(2);
    }
  }, [isTestMode]);

  return (
    <>
      {step === 1 && !isTestMode && (
        <CheckPhone setStep={setStep} setData={setData} code={code} />
      )}
      {step === 2 && (
        <CouponPage data={data} code={code} isTestMode={isTestMode} />
      )}
    </>
  );
};

export default NewAlarmTalk;
