
import { Container, Row, Col, Form } from 'react-bootstrap';
import Btn from '../../../components/common/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default function Information(){
    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="사업장 정보" />
                <Row>
                    <Col>
                        <h4>사업장 이름</h4>
                        <h6>사업장 정보를 수정할 수 있습니다.</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>사업장</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Form>
                        <Form.Group as={Row} className="sm-2 align-items-center">
                            <Form.Label column xs="5">사업장</Form.Label>
                            <Col xs="6" >
                                <Form.Control className='TextRight' plaintext readOnly defaultValue="사업장 이름" />
                            </Col>
                            <Col xs="1">
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
                <Row>
                    <Form>
                        <Form.Group as={Row} className="sm-2 align-items-center">
                            <Form.Label column xs="5">정산계좌</Form.Label>
                            <Col xs="6" >
                                <Form.Control className='TextRight' plaintext readOnly defaultValue="은행-계좌번호" />
                            </Col>
                            <Col xs="1">
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
                <Row>
                    <Form>
                        <Form.Group as={Row} className="sm-2 align-items-center">
                            <Form.Label column xs="6">스토어 주소</Form.Label>
                            <Col xs="5" >
                                <Form.Control className='TextRight' plaintext readOnly defaultValue="storeurl" />
                            </Col>
                            <Col xs="1">
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
                <Row>
                    <Form>
                        <Form.Group as={Row} className="sm-2 align-items-center">
                            <Form.Label column xs="6">업태 / 업종</Form.Label>
                            <Col xs="5" >
                                <Form.Control className='TextRight' plaintext readOnly defaultValue="업태" />
                            </Col>
                            <Col xs="1">
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
            </Container>
        </>
    )
}