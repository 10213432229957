
import { Container, Col, ButtonGroup, Button, Table } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import LeftMenu from './../../../components/admin/LeftMenu';
import SearchForm from './../../../components/admin/SearchForm';
import Btn from './../../../components/common/Btn';
import CustomModal from './../../../components/common/CustomModal';

export default function ConsumerUserDetail ( props ) {

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs ={2}>
                        <LeftMenu />
                    </Col>

                    <Col xs={10}>
                        <SearchForm label='가맹점 회원검색' />
                        <hr/>
                        <Row>
                            <Col>
                                <Btn type='BACK' btntext='목록으로 돌아가기'/>
                            </Col>
                            <Col style={{textAlign:'right'}}>
                                <ButtonGroup  aria-label="Basic">
                                    <CustomModal type='ADMINCENTER' btntext='수정' title='회원 삭제 승인 요청' />
                                    <CustomModal type='ADMINCENTER' btntext='정지' title='회원 계정 정지 승인 요청' />
                                    <Button variant="secondary">폐업처리</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <label>가맹점 회원 정보</label>
                                </Row>
                            </Col>
                            <Col className='text-right'>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Row>
                                    <Table responsive>
                                        <tbody>
                                            <tr>
                                                <td>고객번호</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>상호명</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>사업자등록번호</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>매장주소</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>매장전화번호</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>대표자명</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>대표자 전화번호</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>대표자 이메일</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>매장이름</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>운영시간</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>휴무일</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>업종분류</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>계정상태</td>
                                                <td>0000000</td>
                                            </tr>
                                            <tr>
                                                <td>기타 등등</td>
                                                <td>0000000</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <label>쿠폰 발행내역 : 총 0000건 00,000,000원</label>
                            <Table responsive>
                                <thead>
                                    <tr className="text-center">
                                        <th>고유번호</th>
                                        <th>매장이름</th>
                                        <th>메뉴이름</th>
                                        <th>액면가액</th>
                                        <th>발행일시</th>
                                        <th>쿠폰형태</th>
                                        <th>현재상태</th>
                                        <th>유효기간</th>
                                        <th>최초유효여부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>00000</td>
                                        <td>상호명</td>
                                        <td>메뉴명</td>
                                        <td>16,000</td>
                                        <td>2022.04.11.</td>
                                        <td>QR코드</td>
                                        <td>2022.05</td>
                                        <td>미사용</td>
                                        <td>Y</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}