import Coupon from "../pages/coupon/Coupon";
import History from "../pages/coupon/History";
import CouponUseCheck from "../pages/coupon/CouponUseCheck";

const CouponRoutes = [
  { path: "coupon", element: <Coupon /> },
  { path: "history", element: <History /> },
  { path: "couponusecheck", element: <CouponUseCheck /> },
];

export default CouponRoutes;
