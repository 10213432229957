import React, { useEffect, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import { CloseIcon } from "../../../../components/ver1.5/assets/icons";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../../../components/ver1.5/atom/Text";
import Button from "../../../../components/ver1.5/atom/Button";
// import GiftIllust from "../../../../components/ver1.5/assets/img/signupDone.svg";
// import { presetHeight } from "../../../../components/ver1.5/constants";

const CloseBtnContainer = styled.div`
  width: 20%;
  padding: 0 1.6rem;
  height: 5.4rem;
  display: flex;
  align-items: center;
  min-width: fit-content;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BtnWrapper = styled.div`
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  margin-top: auto;
`;

// const Image = styled.img`
//   width: 16rem;
//   height: 16rem;
// `;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 3.2rem);
  margin: 0 auto;
  padding: 2.4rem;
  border-radius: 1.2rem;
  background: ${color.gray.d50};
  gap: 2.4rem;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Row = styled.div`
  display: flex;
  gap: 2.4rem;
`;

const SignupDone = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState(3);

  const bottomInfo = [
    {
      title: "처리일시",
      value: location?.state?.createdAt ?? "-",
    },
    {
      title: "앱푸시",
      value: location?.state?.alarmAppPush ? "수신동의" : "수신거부",
    },
    {
      title: "문자SMS",
      value: location?.state?.alarmPhoneText ? "수신동의" : "수신거부",
    },
    {
      title: "이메일",
      value: location?.state?.alarmEmail ? "수신동의" : "수신거부",
    },
    {
      title: "전화",
      value: location?.state?.alarmPhone ? "수신동의" : "수신거부",
    },
  ];

  const onClickNavigate = () => {
    const isSaved = sessionStorage.getItem("giftProduct");
    navigate("/user/login", {
      replace: true,
      state: {
        redirectUrl: isSaved && `/user/gift/${isSaved}`,
      },
    });
  };

  useEffect(() => {
    if (count >= 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    } else {
      onClickNavigate();
    }
    // eslint-disable-next-line
  }, [count]);

  return (
    <Layout
      headerPadding={false}
      footerPadding={false}
      styles={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <CloseBtnContainer onClick={onClickNavigate}>
        <CloseIcon color={color.gray.d900} />
      </CloseBtnContainer>
      <ContentContainer>
        <Text
          styles={css`
            font-family: "Pretendard Bold", sans-serif;
            font-weight: 700;
            font-size: 3.2rem;
            letter-spacing: -0.1rem;
            span {
              color: ${color.primary.flexRed};
            }
          `}
          marginTop="1.6rem"
        >
          회원가입 <span>완료</span>
        </Text>
        <Text
          fontStyle={font.subtitle3}
          color={color.gray.d600}
          marginTop="3.2rem"
          styles={css`
            span {
              color: ${color.gray.d900};
            }
          `}
        >
          <span>{location?.state?.nickname ?? "회원"}</span>님 환영합니다
        </Text>
        <Text
          fontStyle={font.subtitle3}
          color={color.gray.d600}
          marginBottom="1.6rem"
        >
          플렉스데이와 함께 일상을 선물해보세요
        </Text>
        {/* <Image src={GiftIllust} /> */}
        <InfoContainer>
          <TopContainer>
            <Text fontStyle={font.subtitle3} color={color.gray.d900}>
              회원정보
            </Text>
            <Row>
              <Text
                fontStyle={font.body2}
                color={color.gray.d900}
                styles={css`
                  width: 5.6rem;
                  line-height: 2.4rem;
                `}
              >
                아이디
              </Text>
              <Text
                fontStyle={font.body2}
                color={color.gray.d600}
                styles={css`
                  line-height: 2.4rem;
                `}
              >
                {location?.state?.email ?? "-"}
              </Text>
            </Row>
          </TopContainer>
          <BottomContainer>
            <Text fontStyle={font.subtitle3} color={color.gray.d900}>
              마케팅 정보 수신 동의 처리결과
            </Text>
            {bottomInfo.map((item, index) => (
              <Row key={index}>
                <Text
                  fontStyle={font.body2}
                  color={color.gray.d900}
                  styles={css`
                    width: 5.6rem;
                    line-height: 2.4rem;
                  `}
                >
                  {item.title}
                </Text>
                <Text
                  fontStyle={font.body2}
                  color={color.gray.d600}
                  styles={css`
                    line-height: 2.4rem;
                  `}
                >
                  {item.value}
                </Text>
              </Row>
            ))}
          </BottomContainer>
        </InfoContainer>
        <Text
          fontStyle={font.body3}
          color={color.gray.d700}
          styles={css`
            padding: 0.4rem 1.2rem;
          `}
          marginTop="1.6rem"
        >
          {count}초 후 홈으로 이동합니다
        </Text>
      </ContentContainer>
      <BtnWrapper>
        <Button onClick={onClickNavigate} text="홈으로" variant="primaryDark" />
      </BtnWrapper>
    </Layout>
  );
};

export default SignupDone;
