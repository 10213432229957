import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function ListGroupH(props){
    const listdata = props.listdata;
    const listItems = listdata.map((data) =>
        <Nav.Item key={data.key}>
            <Nav.Link as={NavLink} to={data.url}>{data.title}</Nav.Link>
        </Nav.Item>
    )

    if (props.type === 'PC'){
        return (
            <>
                <Nav className="justify-content-center">
                    {listItems}
                </Nav>
            </>
        )
    }
    return (
        <Nav className="justify-content-center">
            {listItems}
        </Nav>
    )
}