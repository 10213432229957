import React, { useEffect } from "react";
import ModalLayout from "../../../../../components/ver1.5/molecule/ModalLayout";
import Text from "../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import { css, styled } from "styled-components";
import TextField from "../../../../../components/ver1.5/atom/TextField";
import Divider from "../../../../../components/ver1.5/atom/Divider";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  height: 5.6rem;
  border-top: 1px solid ${color.gray.d100};
`;

const Btn = styled.button`
  width: calc(50% - 0.05rem);
  flex-shrink: 0;
  padding: 1.6rem 0;
`;

const PasswordModal = ({ onCancel, onAction, from, password, setPassword }) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  return (
    <ModalLayout onClickOverlay={onCancel}>
      <ContentContainer>
        <DescriptionWrapper>
          <Text fontStyle={font.body1} color={color.gray.d900}>
            {from === "resetPw" ? "비밀번호 변경을 위해" : "회원탈퇴를 위해"}
          </Text>
          <Text fontStyle={font.body1} color={color.gray.d900}>
            현재 비밀번호를 입력해주세요.
          </Text>
        </DescriptionWrapper>
        <TextField
          placeholder="비밀번호 입력"
          backgroundColor={color.gray.d50}
          padding={"1.6rem"}
          inputStyles={css`
            border-radius: 1.2rem;
            /* width: calc(100% - 3.2rem); */
          `}
          marginBottom="2.4rem"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
      </ContentContainer>
      <BtnContainer>
        <Btn onClick={() => onCancel()}>
          <Text fontStyle={font.body1} color={color.gray.d400}>
            취소
          </Text>
        </Btn>
        <Divider
          direction="vertical"
          width={"0.1rem"}
          color={color.gray.d100}
        />
        <Btn onClick={() => onAction()}>
          <Text fontStyle={font.body1} color={color.functional.link.d600}>
            확인
          </Text>
        </Btn>
      </BtnContainer>
    </ModalLayout>
  );
};

export default PasswordModal;
