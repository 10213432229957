
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
export default function StoreUrl(){
    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="스토어 주소" />
                <Row>
                    <Col>
                        <h4>스토어명</h4>
                        <h6>QR코드로 접속하는 스토어 주소입니다.</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="passwordCk" >
                            <Form.Label>스토어 주소 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <div className="d-grid gap-1">
                        <Button variant="primary" size="lg" onClick={ () => {alert('onclick')}}>
                            완료
                        </Button>
                    </div>
                </Row>
            </Container>
        </>
    )
}