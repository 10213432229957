import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { styled } from "styled-components";
import { headerFooterZIndex } from "../constants";
import { color } from "../styles/theme";

const BottomSheetWrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  justify-content: center;
  align-items: center;
  z-index: ${headerFooterZIndex + 1};
`;

const BottomSheetOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background: ${color.dimmed.d70};
  width: 100vw;
  height: 100vh;
  max-width: 80rem;
  opacity: ${(p) => (p.$isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const ContentContainer = styled.div`
  position: fixed;
  bottom: ${(p) => p.$bottom};
  width: 100%;
  max-width: 80rem;
  max-width: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${color.primary.white};
  border-radius: 3.2rem 3.2rem 0 0;
  padding: 3.2rem 1.6rem 4.8rem;
  transition: bottom ease-in-out 0.3s;
  left: 50%;
  transform: translate(-50%, 0);
`;

const RenderBottomSheet = ({ onClose: onCloseProp, visible, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      onCloseProp();
      document.body.style.overflow = "";
    }, 310);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    if (visible) {
      setIsOpen(true);
    }
  }, [visible]);

  return (
    <BottomSheetWrapper>
      <BottomSheetOverlay onClick={onClose} $isOpen={isOpen} />
      <ContentContainer $bottom={isOpen ? 0 : "-100%"}>
        {children({ onClose })}
      </ContentContainer>
    </BottomSheetWrapper>
  );
};

const BottomSheet = ({ visible, children, onClose }) => {
  return ReactDOM.createPortal(
    <RenderBottomSheet
      visible={visible}
      children={children}
      onClose={onClose}
    />,
    document.body
  );
};

export default BottomSheet;
