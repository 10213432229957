import { Card } from 'react-bootstrap';

export default function CustomCard ( props ){

    let tmpImg = props.img;
    if( tmpImg === undefined ){
        tmpImg = "https://placeimg.com/240/240";
    }

    return (
        <>
            <Card className='text-center' key={props.key}>
                <Card.Img style={{borderRadius: '50%', width:'50%', margin :'0 auto'}} variant="top" src={tmpImg} />
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text>
                        {props.text}
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}