import React, { useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { useLocation } from "react-router-dom";
import FindId from "./components/organism/FindId";
import FindPw from "./components/organism/FindPw";
import TabList from "../../../../components/ver1.5/organism/TabList";
import { css, styled } from "styled-components";
import { color } from "../../../../components/ver1.5/styles/theme";

const TabIndicator = styled.div`
  width: 3.2rem;
  height: 0.2rem;
  background: ${color.primary.flexRed};
  position: absolute;
  bottom: -0.2rem;
  left: 50%;
  transform: translate(-50%);
`;

const FindAccount = () => {
  const location = useLocation();
  const initialTab = location.state?.initialTab ?? "id";
  const [focusedTab, setFocusedTab] = useState(initialTab);

  const tabInfo = [
    {
      name: "아이디 찾기",
      value: "id",
    },
    {
      name: "비밀번호 찾기",
      value: "pw",
    },
  ];

  return (
    <Layout>
      <TabList
        rows={tabInfo}
        currentTab={focusedTab}
        onClick={(row) => setFocusedTab(row.value)}
        marginBottom="1.6rem"
        customIndicator={<TabIndicator />}
        tabContainerStyle={css`
          width: 50%;
          padding: 1.2rem 0;
          position: relative;
        `}
        tabListStyle={css`
          gap: 0;
          padding: 0;
        `}
      />
      {focusedTab === "id" && <FindId />}
      {focusedTab === "pw" && <FindPw />}
    </Layout>
  );
};

export default FindAccount;
