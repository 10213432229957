import React, { useEffect, useState } from "react";

const useTimer = ({ timerSeconds = 180, timeEndCallback }) => {
  const [time, setTime] = useState(timerSeconds);
  const [timerRef, setTimerRef] = useState(undefined);
  const timerFunc = () => {
    const timer = setInterval(() => {
      setTime((prev) => {
        if (prev) {
          return prev - 1;
        }
        return 0;
      });
    }, 1000);
    setTimerRef(timer);
  };
  useEffect(() => {
    if (time === 0) {
      clearInterval(timerRef);
      setTime(timerSeconds);
      if (timeEndCallback) {
        timeEndCallback();
      }
    }
    // eslint-disable-next-line
  }, [time]);

  return { timerFunc, time };
};

export default useTimer;
