import { useNavigate, useSearchParams } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Table, Button, Tabs } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { Tab } from 'react-bootstrap';

export default function StaffMain(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null || localStorage.getItem('accessToken') === 'null'){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    const [invitations, setInvitations] = useState();
    function getInvitations() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('users/me/invitations'),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                    }
                }
            ).then(
                function(response){
                    setInvitations(response.data);
                }
            ).catch(function(error){
                AlertError(error);
            })
        });
    };

    const [storeList, setStoreList] = useState();
    function getStoreList() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('users/me/stores'),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                    }
                }
            ).then(
                function(response){
                    setStoreList(response.data);
                }
            ).catch(function(error){
                AlertError(error);
            })
        });
    }

    function getMyStore(domain) {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('stores/'+domain+'/me' ),
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                }
            }
            ).then(
                function(response){
                    console.log(domain);
                    localStorage.setItem('myStores' , JSON.stringify(response.data));
                    localStorage.setItem('storeDomain', domain);
                    // console.log(localStorage.getItem('myStores'));
                    // var myStores = JSON.parse(localStorage.getItem('myStores'));
                    // console.log(myStores);
                    navigate('/store/'+domain);
                }
            ).catch(function(error){
                console.log(error);
            })
        });
    };

    async function invaitations( id, domain ){

        // document.getElementById('submitBtn').setAttribute("disabled","true");

        let obj = { 
            'replyType' : 'ACCEPT',
            'domain': domain,
            'invitationId': id
        }

        axios.put(
            getApiUrl('stores/'+domain+'/invitations/'+id), 
            obj, 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    alert('승인하였습니다.');
                    window.location.reload();
                }
            }
        ).catch(function(error){
            AlertError(error);
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출
        getInvitations();
        getStoreList();
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Btn type="TITLE" title="스텝" />
                <Row className="MainSpace">
                    <Col xs={{ span:10, offset:1 }}>
                        <h5>스탭 초대 리스트 입니다.</h5>
                        <h5>스텝 초대를 승인해주세요.</h5>
                    </Col>
                </Row>

                <Tabs defaultActiveKey="mystore" id="justify-tab" className="mb-3 mt-3" justify>
                    <Tab eventKey="mystore" title="나의 가맹점">
                        
                        <Row className='mt-2'>
                            <Col xs={{ span:10, offset:1 }}>
                                <Table responsive>
                                    <thead>
                                        <tr className='text-center'>
                                            {/* <th>대표자</th> */}
                                            <th>상호</th>
                                            <th>바로가기</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            storeList && storeList.data.length !== 0 ? 
                                                storeList && storeList.data.map( row => (
                                                    <tr className='AlignCenter' key={row.storeDomain}>
                                                        {/* <td>{row.storeOwner}</td> */}
                                                        <td>{row.storeName}</td>
                                                        <td>
                                                            {
                                                                <Button variant="primary" id='submitBtn' onClick={ (e) => {getMyStore(row.storeDomain)} }>
                                                                    이동
                                                                </Button>
                                                            }
                                                        </td>
                                                    </tr>  
                                                ))
                                            :
                                                <tr>
                                                    <td className='text-center' colSpan={4}>초대 받은 내역이 없습니다.</td>
                                                </tr>
                                        }
                                        
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Tab>
                    <Tab eventKey="invitations" title="초대 리스트">

                        <Row className='mt-2'>
                            <Col xs={{ span:10, offset:1 }}>
                                <Table responsive>
                                    <thead>
                                        <tr className='text-center'>
                                            <th>대표자</th>
                                            <th>상호</th>
                                            {/* <th>도메인</th> */}
                                            <th>상태</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            invitations && invitations.data.length !== 0 ? 
                                                invitations && invitations.data.map( row => (
                                                    <tr className='AlignCenter' key={row.id}>
                                                        <td>{row.storeOwner}</td>
                                                        <td>{row.storeName}</td>
                                                        {/* <td>{row.domain}</td> */}
                                                        <td>
                                                            {row.status.code === 2 ?
                                                                <>승인</>
                                                            :
                                                                <Button variant="primary" id='submitBtn' onClick={ (e) => {invaitations(row.id, row.domain)} }>
                                                                    승인
                                                                </Button>
                                                            }
                                                        </td>
                                                    </tr>  
                                                ))
                                            :
                                                <tr>
                                                    <td className='text-center' colSpan={4}>초대 받은 내역이 없습니다.</td>
                                                </tr>
                                        }
                                        
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Tab>
                </Tabs>

            </Container>
        </>
    )
}