import React, { useContext, useEffect, useState } from "react";
import { css, styled } from "styled-components";
import { color, font } from "./styles/theme";
import {
  BackIcon,
  CheckCircleLineIcon,
  CloseIcon,
  SearchIcon,
  SettingIcon,
  ShareIcon,
} from "./assets/icons";
import {
  useLocation,
  useMatch,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { presetHeight, headerFooterZIndex } from "./constants";
import TextField from "./atom/TextField";
import Toast from "./organism/Toast";
import DefaultModal from "./organism/DefaultModal";
import OneBtnModal from "./organism/OneBtnModal";

const Container = styled.div`
  height: ${presetHeight.header};
  width: 100%;
  max-width: 50rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${headerFooterZIndex};
  background: ${(p) => (p.$background ? p.$background : color.primary.white)};
  padding: 1.6rem;
  ${(p) =>
    p.$blended &&
    css`
      mix-blend-mode: difference;
    `}
  @media screen and (min-width: 500px) {
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const Title = styled.p`
  ${(p) =>
    p.$logo
      ? css`
          /* font-family: "twayair"; */
          font-family: "Pretendard";
          font-weight: 700;
          font-size: 2.2rem;
        `
      : css`
          ${font.subtitle2};
        `}
  ${(p) =>
    p.$centered &&
    css`
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    `}
  color: ${color.gray.d900};
`;

const IconRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

const IconContainer = styled.div`
  padding: 1.2rem;
  position: relative;
  ${(p) =>
    p.$positionLeft &&
    css`
      padding-left: 0;
      margin-right: auto;
    `}
  ${(p) =>
    p.$positionRight &&
    css`
      padding-right: 0;
      margin-left: auto;
    `}
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

const Header = ({
  type,
  title,
  cartNumber = 0,
  currentStore,
  customOnClick,
  closeIconPosition,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const prevKeyword = searchParams.get("keyword");
  const storeDomain = useMatch("/user/:storeDomain")?.params?.storeDomain;
  const [keyword, setKeyword] = useState(prevKeyword ?? "");
  const [showCopyModal, setShowCopyModal] = useState({ show: false, url: "" });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [backConfirmModal, setBackConfirmModal] = useState(false);
  // console.log(accessToken);
  const onSearch = () => {
    if (keyword) {
      navigate(`/user/search?keyword=${keyword}`, { replace: true });
    } else {
      return;
      // navigate("/user/search", { replace: true });
    }
  };

  const onClickCopyUrl = () => {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(showCopyModal.url);
    } else {
      let textarea = document.createElement("input");
      textarea.value = showCopyModal.url;
      textarea.style.position = "fixed";
      textarea.style.top = "-9999999px";
      textarea.style.left = "-9999999px";
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      textarea.setSelectionRange(0, 99999);
      document.execCommand("copy");
      textarea.setSelectionRange(0, 0);
      textarea.remove();
    }
    setShowCopyModal({ show: false, url: "" });
    setShowConfirmModal(true);
  };

  const onClickShare = async () => {
    const targetStoreDomain = storeDomain ?? null;
    let url = "";
    if (targetStoreDomain) {
      url = process.env.REACT_APP_BASEURL + `user/${targetStoreDomain}`;
    } else {
      url = process.env.REACT_APP_BASEURL + "user";
    }
    if (navigator.share) {
      const title =
        "FLEXDAY | " + currentStore.storeName
          ? currentStore.storeName
          : "플렉스데이";
      const text = currentStore.storeAddress
        ? "주소: " +
          currentStore.storeAddress?.address +
          " " +
          currentStore.storeAddress?.addressDetail
        : "모든 일상이 선물이 되는 순간, 플렉스데이";
      navigator
        .share({
          title,
          text,
          url,
        })
        .then(() => console.log("success"))
        .catch((e) => console.log("error", e));
    } else {
      setShowCopyModal({ show: true, url });
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("search")) {
      setKeyword("");
    }
    if (!location.pathname.includes("storereview/edit")) {
      setBackConfirmModal(false);
    }
  }, [location]);

  switch (type) {
    case "default":
      return (
        <Container>
          <Title $centered>{title}</Title>
        </Container>
      );
    case "closable":
      return (
        <Container>
          {!(closeIconPosition === "right") && (
            <IconContainer
              $positionLeft
              onClick={() => (customOnClick ? customOnClick() : navigate(-1))}
            >
              <CloseIcon color={color.gray.d900} />
            </IconContainer>
          )}
          <Title $centered>{title}</Title>
          {closeIconPosition === "right" && (
            <IconContainer
              $positionRight
              onClick={() => (customOnClick ? customOnClick() : navigate(-1))}
            >
              <CloseIcon color={color.gray.d900} />
            </IconContainer>
          )}
        </Container>
      );
    case "back":
      return (
        <Container>
          <IconContainer
            $positionLeft
            onClick={() => {
              if (location.pathname.includes("storereview/edit")) {
                return setBackConfirmModal(true);
              } else {
                if (location.pathname.includes("my/editPhone")) {
                  return navigate("/user/my/editProfile", { replace: true });
                }
                return navigate(-1);
              }
            }}
          >
            <BackIcon color={color.gray.d900} />
          </IconContainer>
          <Title $centered>{title}</Title>
          {backConfirmModal && (
            <DefaultModal
              description={"리뷰 수정을 그만하시겠습니까?"}
              onAction={() => setBackConfirmModal(false)}
              actionText={"계속 수정"}
              onCancel={() => {
                navigate(-1);
                setBackConfirmModal(false);
              }}
              cancelText={"그만하기"}
            />
          )}
        </Container>
      );
    case "main":
      return (
        <Container>
          <Title $centered $logo>
            {title}
          </Title>
          <IconContainer
            $positionRight
            onClick={() => navigate("/user/search")}
          >
            <SearchIcon />
          </IconContainer>
        </Container>
      );
    case "my":
      return (
        <Container>
          <Title $centered>{title}</Title>
          <IconContainer
            $positionRight
            onClick={() => {
              navigate("/user/my/setting");
            }}
          >
            <SettingIcon />
          </IconContainer>
        </Container>
      );
    case "detail":
      return (
        <Container $background={"none"}>
          <IconContainer $positionLeft onClick={() => navigate(-1)}>
            <BackIcon color={color.primary.white} />
          </IconContainer>
          <IconRow>
            <IconContainer onClick={onClickShare}>
              <ShareIcon />
            </IconContainer>
            <IconContainer
              $positionRight
              onClick={() => navigate("/user/search")}
            >
              <SearchIcon color={color.primary.white} />
            </IconContainer>
          </IconRow>
          {showCopyModal.show && (
            <DefaultModal
              description={"링크를 복사하시겠습니까?"}
              onAction={onClickCopyUrl}
              onCancel={() => setShowCopyModal(false)}
              actionText={"복사하기"}
              cancelText={"취소"}
            />
          )}
          {showConfirmModal && (
            <OneBtnModal
              text={"링크가 성공적으로 복사되었습니다"}
              onAction={() => setShowConfirmModal(false)}
            />
          )}
        </Container>
      );
    case "search":
      return (
        <Container>
          <IconContainer
            onClick={() => navigate(-1)}
            style={{
              width: "4rem",
            }}
          >
            <BackIcon color={color.gray.d900} />
          </IconContainer>
          <TextField
            placeholder="검색어를 입력해주세요."
            type="text"
            inputStyles={css`
              border-radius: 0.8rem;
              height: 4.4rem;
              width: calc(100vw - 7.4rem);
              max-width: calc(50rem - 7.4rem);
            `}
            backgroundColor={color.gray.d50}
            padding={"0.8rem 1.2rem 0.8rem 3.6rem"}
            leftIcon={
              <SearchIcon
                width={"1.6rem"}
                height={"1.6rem"}
                color={color.gray.d400}
              />
            }
            iconWidth={"1.6rem"}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
          />
        </Container>
      );
    default:
      return (
        <Container>
          <Title>{title}</Title>
        </Container>
      );
  }
};

export default Header;

// 장바구니 등 기능 추가시 다시 확인 필요
// const CartBadge = styled.div`
//   width: 2rem;
//   height: 2rem;
//   border-radius: 1rem;
//   background-color: ${color.primary.flexRed};
//   position: absolute;
//   top: -0.8rem;
//   right: -1rem;
//   z-index: 2;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
// const IconsWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 2.4rem;
//   margin-left: auto;
// `;
/* <IconsWrapper>
  <IconContainer>
    <SearchIcon />
  </IconContainer>
  <IconContainer>
    <CartIcon />
    {cartNumber > 0 && (
      <CartBadge>
        <Text
          styles={css`
            font-family: "Pretendard Bold", sans-serif;
            font-size: 1.2rem;
            color: ${color.primary.white};
          `}
        >
          {cartNumber}
        </Text>
      </CartBadge>
    )}
  </IconContainer>
</IconsWrapper> */
