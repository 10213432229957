
import { Container, Row, Table, Col, Button } from 'react-bootstrap';
import FAB from '../../components/common/FAB';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import Btn from './../../components/common/Btn';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

export default function StaffManagement(props){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    const addStaff = (e) => {
        navigate('/store/addstaff');
    }
    

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [staff, setStaff] = useState();
    async function getStaff(){
        axios.get(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/staff'), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    setStaff(response.data);
                }
            }
        ).catch(function(error){
            AlertError(error);
            // document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    const [invitations, setInvitations] = useState();
    async function getInvitations(){
        axios.get(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/invitations'), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    setInvitations(response.data);
                }
            }
        ).catch(function(error){
            AlertError(error);
            // document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }
    
    const [deleteId, setdeleteId] = useState();
    async function StaffDelete(){
        console.log(deleteId);
        await axios.delete(
            getApiUrl('stores/'+ localStorage.getItem('storeDomain') +'/staff/'+deleteId),
            axios.defaults.headers.common['ContentType'] = "application/json;",
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                console.log(response);
                if(response.status === 200){
                    window.location.reload();
                }else{
                    alert('직원 삭제에 실패 하였습니다.');
                }
            }
        ).catch(function(error){
            PrintConsole(error);
        })
    }

    async function getAuthority(){
        //권한
        axios.get(
            getApiUrl('users/me/stores/'+localStorage.getItem('storeDomain')), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    console.log(response.data.type['code']);
                    if(response.data.type['code'] === 2){
                        //직원은 사용못함
                        alert('권한이 없습니다.');
                        navigate(-1);
                    }else if(response.data.type['code'] === 99){
                        //직급 없슴
                        alert('권한이 없습니다.');
                        navigate(-1);
                    }
                }
            }
        ).catch(function(error){
            AlertError(error);
            navigate(-1);
        });
    }

    var myStores = JSON.parse(localStorage.getItem('myStores'))
    
    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        //권한
        getAuthority();
        
        //함수 호출
        getStaff();
        getInvitations();

        
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    // console.log(myStores);
    // console.log(myStores.storeStaffId)

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="직원 관리" />
                <Row className='p-1 mt-3'>
                    <Col xs={{ span:10, offset:1 }}>
                        <div className="d-grid gap-1 mt-2">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ addStaff }>
                                직원 추가
                            </Button>
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col className='mt-3' xs={{ span:10, offset:1 }}>
                        <label>직원 목록</label>
                        <Table>
                            <thead>
                                <tr className="text-center">
                                    <th>직급</th>
                                    <th>성명</th>
                                    <th>직원 관리</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    staff && staff.data.length !== 0 ?
                                        staff && staff.data.map( row => (
                                            <tr className='AlignCenter' key={row.id}>
                                                <td >{row.type.description}</td>
                                                <td >{row.name}</td>
                                                <td >
                                                    {
                                                        row.type.code !== 1 ?
                                                            String(row.id) !== String(myStores.storeStaffId) ? 
                                                                <Button variant="primary" onClick={ () => {handleShow(); setdeleteId(row.id);} }>
                                                                    직원삭제
                                                                </Button>
                                                            :                                                           
                                                            <Button variant="primary" disabled >
                                                                직원삭제
                                                            </Button>

                                                        :
                                                            <Button variant="primary" disabled >
                                                                직원삭제
                                                            </Button>
                                                    }

                                                    <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                                                        <Modal.Header closeButton>
                                                            {/* <Modal.Title id="contained-modal-title-vcenter">
                                                            Modal heading
                                                            </Modal.Title> */}
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <p className="text-center">직원 삭제</p>
                                                            <p className="text-center">등록된 직원을 삭제하시겠습니까?</p>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Container className="text-center">
                                                                <Row>
                                                                    <Col onClick={handleClose}>취소</Col>
                                                                    <Col onClick={ (e) => {StaffDelete()}}>삭제</Col>
                                                                </Row>
                                                            </Container>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                    <tr>
                                        <td colSpan={3} className='AlignCenter'>직원이 없습니다.</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row>
                    <Col className='mt-3' xs={{ span:10, offset:1 }}>
                        <label>직원 초대 목록</label>
                        <Table>
                            <thead>
                                <tr className="text-center">
                                    <th>아이디</th>
                                    <th>상태</th>
                                    <th>초대일시</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invitations && invitations.data.length !== 0 ?
                                        invitations && invitations.data.map( row => (
                                            <tr className='AlignCenter' key={row.loginId}>
                                                <td >
                                                    {row.invitedName !== null ? row.invitedName : <></>}<br/>
                                                    <small>{row.loginId !== null ? '('+row.loginId+')' : <></>}</small>
                                                </td>
                                                <td >
                                                    {row.status.description}<br/>
                                                    <small>{row.completedAt !== null ? '('+row.completedAt+')' : ''}</small>
                                                </td>
                                                <td >{row.createdAt}</td>
                                            </tr>
                                        ))
                                        :
                                    <tr>
                                        <td colSpan={3} className='AlignCenter'>직원이 없습니다.</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            </Container>
            
            {/* <FAB/> */}
        </>
    )
}