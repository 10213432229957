import { Button, Row, Col, Container, Table, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function StoreInfo(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Row className="StoreInfoBtn">
        <Button variant="default" className="type13" onClick={handleShow}>
          가게 정보
        </Button>
        <Button
          variant="default"
          className="type13"
          onClick={() => console.log("map btn")}
        >
          지도 정보
        </Button>
      </Row>
      {/* <span onClick={handleShow} > &middot; 원산지 */}
      {/* <FontAwesomeIcon onClick={handleShow} icon={faAngleRight} /> */}
      {/* </span> */}
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>가게정보 &middot; 원산지</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <Form.Label className="LabelTitle">영업 정보</Form.Label>
              <Table className="TextTable">
                <tbody>
                  <tr>
                    <td>상호명</td>
                    <td>{props.storeName}</td>
                  </tr>
                  <tr>
                    <td>운영시간</td>
                    <td>{props.businessHours}</td>
                  </tr>
                  <tr>
                    <td>휴무일</td>
                    <td>{props.holiday}</td>
                  </tr>
                  <tr>
                    <td>전화번호</td>
                    <td>{props.storePhonewNumber}</td>
                  </tr>
                  <tr>
                    <td>주소</td>
                    <td>
                      {props.storeZipCode} <br /> {props.storeAddress} <br />{" "}
                      {props.storeAddressDetail}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <Form.Label className="LabelTitle">사업자 정보</Form.Label>
              <Table className="TextTable">
                <tbody>
                  <tr>
                    <td>대표자명</td>
                    <td>{props.ownerName}</td>
                  </tr>
                  <tr>
                    <td>상호명</td>
                    <td>{props.businessName}</td>
                  </tr>
                  <tr>
                    <td>사업자주소</td>
                    <td>
                      {props.storeZipCode} <br /> {props.storeAddress} <br />{" "}
                      {props.storeAddressDetail}
                    </td>
                  </tr>
                  <tr>
                    <td>사업자등록번호</td>
                    <td>{props.businessNumber}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <Form.Label className="LabelTitle">원산지 표기</Form.Label>
              <Table className="TextTable">
                <tbody>
                  <tr colSpan="2">
                    <td>
                      <pre>{props.originLabelling}</pre>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
