
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

export default function DashBoardC( props ){
    
    return (
        <>
            <Col xs={4}>
                정산현황
                <Row>
                    오늘 정산액
                </Row>
            </Col>
        </>
    )
    
}