
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

export default function DashBoardA( props ){

    return (
        <>
            <Col xs={4}>
                발행현황
                <Row>
                    <p>오늘 발행량</p>
                    <Col>
                        <p>000매</p>
                        <p>0,000,000원</p>
                    </Col>
                </Row>
                <Row>
                    <p>이번달 발행량</p>
                    <Col>
                        <p>000매</p>
                        <p>0,000,000원</p>
                    </Col>
                </Row>
                <Row>
                    <p>총 누적 발행량</p>
                    <Col>
                        <p>000매</p>
                        <p>0,000,000원</p>
                    </Col>
                </Row>
                <Row>
                    <hr/>
                    <p>더 보기</p>
                </Row>
            </Col>
        </>
    )
}