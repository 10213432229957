import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/ver1.5/layout/Layout";
import HistoryList from "./components/organism/HistoryList";
import SortHeader from "./components/organism/SortHeader";
import axios from "axios";
import { AlertError, getApiUrl } from "../../../function/common";
import { css } from "styled-components";
import { color } from "../../../components/ver1.5/styles/theme";
import { couponSortList } from "../../../components/ver1.5/constants";
import { UserContext } from "../../../contexts/UserContext";
import useInfiniteScroll from "../../../components/ver1.5/hooks/useInfiniteScroll";
import OneBtnModal from "../../../components/ver1.5/organism/OneBtnModal";
import DefaultModal from "../../../components/ver1.5/organism/DefaultModal";

// const sampleData = Array.from({ length: 10 }, (v, i) => ({
//   orderNumber: i + 1,
//   productName: "productSample" + (i + 1),
//   orderedAt: Date.now(),
//   imageUrl: "https://picsum.photos/id/42/3456/2304.jpg",
//   recipient: {
//     name: "receiver" + (i + 1),
//     phoneNumber: "01012341234",
//     message: "testMessage",
//   },
//   name: "sampleItemName",
//   storeName: "sampleStore",
//   description: "sampleDescription",
//   price: 1000 * (i + 1),
// }));

const History = () => {
  const { accessToken } = useContext(UserContext);

  const [list, setList] = useState([]);
  const [sort, setSort] = useState(couponSortList[0].value);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [cursorId, setCursorId] = useState(undefined);
  const [hasNext, setHasNext] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [cancelModal, setCancelModal] = useState({
    show: false,
    orderInfo: null,
  });
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
  const getGift = async ({ size, cursorId }) => {
    setLoading(true);
    await axios
      .get(getApiUrl("users/me/coupons"), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          type: "SEND",
          size,
          ...(cursorId && { cursorId }),
        },
      })
      .then((res) => {
        if (res.data.hasNext) {
          if (res.data.nextCursorId) {
            setCursorId(res.data.nextCursorId);
          }
          setHasNext(true);
        } else {
          setHasNext(false);
        }

        setTotalCount(res.data.totalCount);
        setList((prev) => [...prev, ...res.data.data]);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const onClickCancelPayment = async (orderNumber) => {
    if (!orderNumber) return;
    await axios
      .delete(getApiUrl(`users/me/payments/${orderNumber}`), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCancelModal({ show: false, orderInfo: null });
          setCancelConfirmModal(true);
        }
      })
      .catch((e) => {
        AlertError(e);
      });
  };

  useEffect(() => {
    if (accessToken) {
      getGift({ size, cursorId });
    }
    // eslint-disable-next-line
  }, []);

  const { Target } = useInfiniteScroll(async (entry, observer) => {
    if (loading) {
      return;
    }
    if (!hasNext) {
      return;
    }

    observer.unobserve(entry.target);
    await getGift({ size, cursorId });
    observer.observe(entry.target);
  });
  return (
    <Layout
      styles={css`
        background: ${color.gray.d50};
      `}
    >
      <SortHeader total={totalCount} sort={sort} setSort={setSort} />
      <HistoryList
        data={list}
        loading={loading}
        onClickCancel={(orderInfo) =>
          setCancelModal({
            show: true,
            orderInfo,
          })
        }
      />
      <Target />
      {cancelModal.show && (
        <DefaultModal
          description={`"(${
            cancelModal.orderInfo?.productType +
            ") " +
            cancelModal.orderInfo?.name
          }" 구매를 취소하시겠습니까?`}
          onCancel={() => setCancelModal({ show: false, orderInfo: null })}
          onAction={() =>
            onClickCancelPayment(cancelModal.orderInfo?.orderNumber)
          }
          actionText={"확인"}
          cancelText={"취소"}
          descriptionStyles={css`
            padding: 0 1.6rem;
            text-align: center;
            word-break: keep-all;
          `}
        />
      )}
      {cancelConfirmModal && (
        <OneBtnModal
          text={"구매가 정상적으로 취소되었습니다."}
          onAction={() => {
            setCancelConfirmModal(false);
            window.location.reload();
          }}
        />
      )}
    </Layout>
  );
};

export default History;
