import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiUrl, PrintConsole } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import "./Intro.css";

import { Modal } from 'react-bootstrap';

export default function Intro(props){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState('STEP1');

    const [tel, setTel] = useState();

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const { code } = useParams();
    PrintConsole(code);

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    return (
        <> 
             <div className="MS">
                <div className="MS-Intro">
                    <div className="inner">
                        <div className="row g-0 MS_intro_logo">
                            <img src="https://www.flexdaycdn.net/public/images/moneyshow/MS_intro_logo.png" alt="" className="img-fluid" />
                        </div>

                        <div className="row g-0 MS_intro_txt">
                            <p className="mb-0">
                                With
                            </p>
                        </div>
                        <div className="row g-0 MS_intro_img">
                            <img src="https://www.flexdaycdn.net/public/images/moneyshow/MS_intro_img.png" alt="" className="img-fluid" />
                        </div>
                        <div className="row g-0 MS_intro_gentz">
                            <img src="https://www.flexdaycdn.net/public/images/moneyshow/MS_intro_gentz.png" alt="" className="img-fluid" />
                        </div>
                        <div className="row g-0 MS_intro_btnbox">
                            <a href="/ms/main" className="MS_intro_btn">
                                교환권 보내기
                                <img src="https://www.flexdaycdn.net/public/images/moneyshow/MS_intro_arrow.png" alt="" className="img-fluid" />
                            </a>
                        </div>
                        
                    </div>
                    <img src="https://www.flexdaycdn.net/public/images/moneyshow/MS_intro_flexlogo.png" alt="" className="img-fluid flexLogo" />
                </div>
            </div>
        </>
    )
}