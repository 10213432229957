import StoreApply from "../pages/App/StoreApply";
import StoreCalculate from "../pages/App/StoreCalculate";
import StoreHome from "../pages/App/StoreHome";
import ApproveProcess from "../pages/franchisee/store/ApproveProcess";
import Calculator from "../pages/franchisee/store/Calculator";
import ItemCreate from "../pages/franchisee/store/ItemCreate";
import ItemCreateGuide from "../pages/franchisee/store/ItemCreateGuide";
import ProductCategory from "../pages/franchisee/store/ProductCategory";
import StoreAccount from "../pages/franchisee/store/StoreAccount";
import StoreInfo from "../pages/franchisee/store/StoreInfo";
import StoreLogo from "../pages/franchisee/store/StoreLogo";
import StoreMain from "../pages/franchisee/store/StoreMain";
import StoreQRCode from "../pages/franchisee/store/StoreQRCode";
import UseGift from "../pages/franchisee/store/UseGift";
import Certification from "../pages/franchisee/user/Certification";
import Join from "../pages/franchisee/user/Join";
import Login from "../pages/franchisee/user/Login";
import StoreQuestions from "../pages/franchisee/user/StoreQuestions";
import UseGiftNumber from "../pages/franchisee/user/UseGiftNumber";
import WPSelect from "../pages/franchisee/workplace/WPSelect";
import AddStaff from "../pages/staff/AddStaff";
import StaffMain from "../pages/staff/StaffMain";
import StaffManagement from "../pages/staff/StaffManagement";
import Faq from "../pages/user/Faq";
import UserInformation from "../pages/user/UserInformation";

const StoreRoutes = [
  { path: ":storeDomain", element: <StoreMain /> },
  { path: "wpselect", element: <WPSelect /> },
  { path: "storeinfo", element: <StoreInfo /> },
  { path: "storeaccount", element: <StoreAccount /> },
  { path: "storelogo", element: <StoreLogo /> },
  { path: "storeqrcode", element: <StoreQRCode /> },
  { path: "itemcreate", element: <ItemCreate /> },
  { path: "itemcreate/guide", element: <ItemCreateGuide /> },
  { path: "productcategory", element: <ProductCategory /> },
  { path: "userinfo", element: <UserInformation /> },
  { path: "usegiftnumber", element: <UseGiftNumber /> },
  { path: "approveprocess", element: <ApproveProcess /> },
  { path: "addstaff", element: <AddStaff /> },
  { path: "join", element: <Join /> },
  { path: "certification", element: <Certification /> },
  { path: "faq", element: <Faq /> },
  { path: "calculator", element: <Calculator /> },
  {
    path: `${localStorage.getItem("storeDomain")}/usegift`,
    element: <UseGift />,
  },
  { path: "staffadd", element: <StaffMain /> },
  { path: "storequestions", element: <StoreQuestions /> },
  { path: "login/", element: <Login /> },

  // Under App Annotation
  { path: "storeapply", element: <StoreApply /> },
  { path: "storehome", element: <StoreHome /> },
  { path: "storecalculate", element: <StoreCalculate /> },
  { path: "storestaff", element: <StaffManagement /> },
];

export default StoreRoutes;
