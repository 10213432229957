import React from "react";
import { css, styled } from "styled-components";
import Text from "../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../components/ver1.5/styles/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 3.2rem);
  margin: 0 auto;
  ${(p) =>
    p.$gap &&
    css`
      gap: ${(p) => p.$gap};
    `}
  margin-bottom: ${(p) => (p.$marginBottom ? p.$marginBottom : 0)};
`;

const LabelItem = ({
  title = "",
  description = "",
  children,
  contentsMarginTop,
  marginBottom,
  required,
}) => {
  return (
    <Container $gap={contentsMarginTop} $marginBottom={marginBottom}>
      <div>
        <Text
          fontStyle={font.subtitle3}
          color={color.gray.d900}
          styles={css`
            span {
              color: ${color.primary.flexRed};
            }
          `}
        >
          {title}
          {required && <span> *</span>}
        </Text>
        {Boolean(description) && (
          <Text
            fontStyle={font.body4}
            color={color.gray.d600}
            marginTop="0.4rem"
          >
            {description}
          </Text>
        )}
      </div>
      {children}
    </Container>
  );
};

export default LabelItem;
