import React from "react";
import { Route, Routes } from "react-router-dom";

import Baemin from "../pages/baemin/baemin";
import DemoLogin from "../pages/demo/Login";
import DemoUserLogin from "../pages/demo/UserLogin";
import DemoStoreMain from "../pages/demo/StoreMain";
import DemoUserMain from "../pages/demo/UserStore";
import DemoQR from "../pages/demo/QR";
import DemoQRDetail from "../pages/demo/QRDetail";
import DemoItemCreate from "../pages/demo/ItemCreate";
import DemoStoreCalculate from "../pages/demo/StoreCalculate";
import DemoSettleHistory from "../pages/demo/SettleHistory";
import DemoSettleHistorySub from "../pages/demo/SettleHistorySub";
import DemoSettleDetail from "../pages/demo/SettleDetail";
import DemoPayment from "../pages/demo/Payment";
import DemoPaymentList from "../pages/demo/PaymentList";
import DemoSuccess from "../pages/demo/Success";
import DemoPaymentCancel from "../pages/demo/PaymentCancel";

const DemoRoutes = [
  {
    path: "baemin",
    element: <Baemin />,
  },
  {
    path: "login",
    element: <DemoLogin />,
  },
  {
    path: "userlogin",
    element: <DemoUserLogin />,
  },
  {
    path: "userstore/:storeDomain",
    element: <DemoUserMain />,
  },
  {
    path: "qr/:number",
    element: <DemoQR />,
  },
  {
    path: "qrdetail/:number",
    element: <DemoQRDetail />,
  },
  {
    path: "payment",
    element: <DemoPayment />,
  },
  {
    path: "paymentlist",
    element: <DemoPaymentList />,
  },
  {
    path: "success",
    element: <DemoSuccess />,
  },
  {
    path: "paymentcancel",
    element: <DemoPaymentCancel />,
  },
  {
    path: "store/:storeDomain",
    element: <DemoStoreMain />,
  },
  {
    path: "itemcreate",
    element: <DemoItemCreate />,
  },
  {
    path: "storecalculate",
    element: <DemoStoreCalculate />,
  },
  {
    path: "settlehistory",
    element: <DemoSettleHistory />,
  },
  {
    path: "settlehistorysub",
    element: <DemoSettleHistorySub />,
  },
  {
    path: "settledetail/:Mode",
    element: <DemoSettleDetail />,
  },
];

export default DemoRoutes;
