import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { GetAuthorize, GetUserMe } from '../../api/ApiGetUser';
import { PrintConsole, sleep } from '../../function/common';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import { getImgUrl } from '../../function/common';
import CheckBox from '../../components/common/CheckBox';
import ListGroupH from '../../components/ListGroupH';

import './PosLogin.css';

export default function Logout(){
    const navigate = useNavigate();

    useEffect( () => {
        localStorage.clear();
        navigate('/pos2/login');
    })
    
    return (
        <>
        </>
    )
}
