import { useNavigate, useSearchParams } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import Btn from './../../components/common/Btn';

export default function AddStaff(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    const [row, setRow] = useState();
    
    async function invaitations( ){

        document.getElementById('submitBtn').setAttribute("disabled","true");

        let e = document.getElementById("staffType");

        let obj = { 
            'inviteType' : 'PHONE',
            'name': document.getElementById('name').value,
            'phoneNumber': document.getElementById('phoneNumber').value,
            'staffType' : e.options[e.selectedIndex].value
        }

        console.log(obj);

        axios.post(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/invitations'), 
            obj, 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    alert('초대가 완료 되었습니다.');
                    document.getElementById('submitBtn').removeAttribute("disabled");
                }
            }
        ).catch(function(error){
            AlertError(error);
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    async function getAuthority(){
        //권한
        axios.get(
            getApiUrl('users/me/stores/'+localStorage.getItem('storeDomain')), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    if(response.data.type['code'] === 2){
                        //직원은 사용못함
                        alert('권한이 없습니다.');
                        navigate(-1);
                    }else if(response.data.type['code'] === 99){
                        //직급 없슴
                        alert('권한이 없습니다.');
                        navigate(-1);
                    }
                }
            }
        ).catch(function(error){
            AlertError(error);
            navigate(-1);
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //권한
        getAuthority();
        //함수 호출

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="직원 추가" />
                <Row className="MainSpace">
                    <Col xs={{ span:10, offset:1 }}>
                        <h5>휴대폰 번호로</h5>
                        <h5>직원을 초대해 주세요.</h5>
                    </Col>
                </Row>
                <Row className='p-1 mt-3'>
                    <Col xs={{ span:10, offset:1 }}>
                        <Form.Group controlId="staffType">
                            <Form.Select size="lg" onChange={onChangeData}>
                                <option key='MANAGER' value='MANAGER'>중간관리자</option>
                                <option key='STAFF' value='STAFF'>직원</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span:10, offset:1 }}>
                        <Form.Group className="mt-3" controlId="name">
                            <Form.Label>관리자 / 직원 이름을 입력해 주세요. <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text"  placeholder="이름을 입력하세요." onChange={onChangeData} />
                        </Form.Group>
                    </Col>

                    <Col xs={{ span:10, offset:1 }}>
                        <Form.Group className="mt-3" controlId="phoneNumber">
                            <Form.Label>전화번호를 입력해 주세요. <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text"  placeholder="이름을 입력하세요." onChange={onChangeData} />
                        </Form.Group>
                    </Col>

                    <Col xs={{ span:10, offset:1 }} className='mt-3'>
                        <div className="d-grid gap-1">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ invaitations }>
                                초대
                            </Button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}