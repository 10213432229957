import React, { useState, useEffect } from "react";
import Layout from "../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import { presetHeight } from "../../../components/ver1.5/constants";
import { color } from "../../../components/ver1.5/styles/theme";
import TopContent from "./components/TopContent";
import Footer from "../../../components/ver1.5/organism/Footer";
import MiddleContent from "./components/MiddleContent";
import Button from "../../../components/ver1.5/atom/Button";
import { useMatch, useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiUrl } from "../../../function/common";

const BtnWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.gray.d50};
`;

const TEMP_RECEIPT_INFO = {
  status: "PAID",
  paymentPrice: 7500,
  orderNumber: "20230818-001269",
  productName: "((맛집랭킹1위)) 수비드 닭가슴살 샐러드",
  productImageUrl: "string",
  approveId: "10937087",
  approveDate: "2023-08-06 22:21:53",
  cardNumber: "3562********507*",
  issueCompanyName: "신한카드",
  recipient: {
    name: "최현우",
    phoneNumber: "01012345678",
    message:
      "생일을 축하드립니다~ 요거 좋아하실 것 같아 선물해봅니다  항상 건강하세요👍👍👍👍",
  },
  storeName: "유주라이크 센텀점",
  storeLogoUrl: "string",
  storeAddress: {
    zipCode: "string",
    address: "부산 남구 전포대로 133 Wework BIFC 15층",
    addressDetail: "string",
  },
};

const Receipt = () => {
  const code = useMatch("/kiosk/payment/:paymentLink")?.params?.paymentLink;
  const navigate = useNavigate();
  const [data, setData] = useState();

  useEffect(() => {
    getPayment();
  }, []);

  const getPayment = async () => {
    if (!code) alert("영수증 링크를 확인해주세요.");
    await axios
      .get(getApiUrl(`payments-link/${code}`))
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data)
          setData(res.data);
        } else {
          alert("영수증 링크를 확인해주세요.");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Layout
      footerPadding={false}
      styles={css`
        padding-bottom: 2.4rem;
        background-color: ${color.gray.d50};
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      {data && (
        <TopContent
          navigate={navigate}
          orderedAt={data.approveDate}
          receiptInfo={data}
          status={data.status}
          recipient={data.recipient}
          imageUrl={data.productImageUrl}
          logoUrl={data.storeLogoUrl}
          price={data.paymentPrice}
          storeName={data.storeName}
          productName={data.productName}
        />
      )}
      {data && (
        <MiddleContent
          orderNumber={data.orderNumber}
          price={data.paymentPrice}
        />
      )}
      <Footer />
      <BtnWrapper>
        <Button
          text="또 선물할래요"
          onClick={() => navigate("/user", { replace: true })}
        />
      </BtnWrapper>
    </Layout>
  );
};

export default Receipt;
