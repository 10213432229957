import html2canvas from "html2canvas";
import saveAs from "file-saver";
import { useRef } from "react";

// Usage
// ```
// const {ref, capture} = useCapture();
// ...
// return (
//  <div ref={ref} onClick={capture}>
// )
//
// ```
const useCapture = (filename = "result") => {
  const ref = useRef(null);

  const getImgUrl = async () => {
    if (!ref.current) return;

    try {
      const screen = ref.current;
      const canvas = await html2canvas(screen, { scale: 2 });
      return canvas.toDataURL();
    } catch (error) {
      console.error("getBlob error: ", error);
    }
  };
  const capture = async () => {
    if (!ref.current) return;

    try {
      const screen = ref.current;
      const canvas = await html2canvas(screen, { scale: 2 });
      canvas.toBlob((blob) => {
        if (blob !== null) {
          saveAs(blob, `${filename}.jpg`);
        }
      });
    } catch (error) {
      console.error("Capture error: ", error);
    }
  };

  return {
    ref,
    getImgUrl,
    capture,
  };
};

export default useCapture;
