
import { Container, Row, Col, Button, Image, ProgressBar, Form } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
import { useNavigate } from 'react-router-dom';
import { getImgUrl } from './../../../function/common';
import { useState } from 'react';

export default function TmpQuestions(){
    const navigate = useNavigate();
    
    const [step , setStep] = useState('STEP1');


    if(step === 'STEP1'){

        return (
            <>
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <Image className='PageIcon' src={getImgUrl('images/icon/SmailIcon.png')}/>
                            <p className='PageTite'>사장님이신가요?</p>
                            <p className='PageSubTitle'>사장님이시라면, 사업장 정보를 입력해주세요.</p>
                        </Col>
                    </Row>
    
                    <Row>
                        <Col xs={{ span:10, offset:1 }} className='mt-5'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType2' size="lg" id='submitBtn' onClick={() => ( setStep('STEP2') )}>
                                    예 (사업장 등록하러 가기)
                                </Button>
                            </div>
                        </Col>
                    </Row>
    
                    <Row>
                        <Col xs={{ span:10, offset:1 }} className='mt-4'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType2' size="lg" id='submitBtn' >
                                    아니요. 직원입니다.
                                </Button>
                            </div>
                        </Col>
                    </Row>
    
                </Container>
            </>
        )

    }else if( step === 'STEP2'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={15} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>내 매장 주소</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span:10, offset:1 }} className='mt-3'>
                            <Form.Group controlId='Id'>
                                <Form.Control size="lg" className='InputType2Icon' type="text" controlid='Id' name='Id' placeholder="주소를 검색해 주세요." />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={{ span:10, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType1' size="lg" id='submitBtn' onClick={() => ( setStep('STEP3') )}>
                                    다음
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if( step === 'STEP3'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={15} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:5}}>
                            <p className='NoIconPageTite'>내 매장 주소</p>
                        </Col>
                        <Col xs={{span:5}} className='AlignRight'>
                            <Button variant="primary" className='ButtonType4' id='submitBtn'>
                                주소 변경하기
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{offset:1, span:10}} className='mt-3'>
                            <p className='TextContent'>
                                48400<br/>
                                부산 남구 전포대로 133(문현동)
                            </p>
                        </Col>
                        <Col xs={{ span:10, offset:1 }} >
                            <Form.Group controlId='Id'>
                                <Form.Control size="lg" className='InputType2' type="text" controlid='Id' name='Id' placeholder="상세 주소를 입력해주세요. 예) 플렉스상가 101동 101호" />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={{ span:10, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType1' size="lg" id='submitBtn' onClick={() => ( setStep('STEP4') )}>
                                    다음
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if( step === 'STEP4'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={20} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>내 매장 이름</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group controlId='Id'>
                                <Form.Control size="lg" className='InputType2' type="text" controlid='Id' name='Id' placeholder="매장 이름을 입력해주세요." />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={{ span:5, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType5' size="lg" id='submitBtn' onClick={() => ( setStep('STEP3') )}>
                                    이전
                                </Button>
                            </div>
                        </Col>
                        <Col xs={{ span:5, offset:6}} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType6' size="lg" id='submitBtn' onClick={() => ( setStep('STEP5') )}>
                                    다음
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if( step === 'STEP5'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={20} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>내 매장 이름</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group controlId='Id'>
                                <Form.Control size="lg" className='InputType1' type="text" controlid='Id' name='Id' placeholder="매장 이름을 입력해주세요."  value='플렉스데이'/>
                            </Form.Group>
                            <p className='DescriptionA mt-3 ps-2'>
                                이미 사용중인 매장 이름이네요!<br/>
                                매장 주소를 활용하여 다시 입력해보세요.<br/>
                                <br/>
                                예) 플렉스데이(문현점)

                            </p>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={{ span:5, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType6' size="lg" id='submitBtn' onClick={() => ( setStep('STEP4') )}>
                                    이전
                                </Button>
                            </div>
                        </Col>
                        <Col xs={{ span:5, offset:6}} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType5' size="lg" id='submitBtn' onClick={() => ( setStep('STEP6') )}>
                                    다음
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if( step === 'STEP6'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={30} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>매장 업태</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span:10, offset:1 }}>
                            <div className="d-flex flex-wrap justify-content-start">
                                <div className='p-1' key='1'>
                                    <input type='radio' className="btn-check" name='businessClass' autoComplete="off" id='1' value='1' defaultChecked={true}  />
                                    <label htmlFor='1' className="btn btn-info" name='businessClass' id='1' >외식</label>
                                </div>
                                <div className='p-1' key='2'>
                                    <input type='radio' className="btn-check" name='businessClass' autoComplete="off" id='2' value='2' defaultChecked={false} />
                                    <label htmlFor='2' className="btn btn-info" name='businessClass' id='2' >뷰티&#183;미용</label>
                                </div>
                                <div className='p-1' key='3'>
                                    <input type='radio' className="btn-check" name='businessClass' autoComplete="off" id='3' value='3' defaultChecked={false} />
                                    <label htmlFor='3' className="btn btn-info" name='businessClass' id='3' >스포츠&#183;레저&#183;여가</label>
                                </div>
                                <div className='p-1' key='4'>
                                    <input type='radio' className="btn-check" name='businessClass' autoComplete="off" id='4' value='4' defaultChecked={false} />
                                    <label htmlFor='4' className="btn btn-info" name='businessClass' id='4' >반려동물</label>
                                </div>
                                <div className='p-1' key='5'>
                                    <input type='radio' className="btn-check" name='businessClass' autoComplete="off" id='5' value='5' defaultChecked={false} />
                                    <label htmlFor='5' className="btn btn-info" name='businessClass' id='5' >기타</label>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>업종</p>
                            <p className='NoIconPageSubTitle'>복수 선택이 가능해요!</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span:10, offset:1 }}>
                            <div className="d-flex flex-wrap justify-content-start">
                                <div className='p-1' key='a1'>
                                    <input type='check' className="btn-check" name='businessClass' autoComplete="off" id='a1' value='a1' defaultChecked={false} />
                                    <label htmlFor='a1' className="btn btn-info" name='businessClass' id='a1' >한식</label>
                                </div>
                                <div className='p-1' key='a2'>
                                    <input type='check' className="btn-check" name='businessClass' autoComplete="off" id='a2' value='a2' defaultChecked={false}/>
                                    <label htmlFor='a2' className="btn btn-info" name='businessClass' id='a2' >양식</label>
                                </div>
                                <div className='p-1' key='a3'>
                                    <input type='check' className="btn-check" name='businessClass' autoComplete="off" id='a3' value='a3' defaultChecked={false}/>
                                    <label htmlFor='a3' className="btn btn-info" name='businessClass' id='a3' >일식</label>
                                </div>
                                <div className='p-1' key='a4'>
                                    <input type='check' className="btn-check" name='businessClass' autoComplete="off" id='a4' value='a4' defaultChecked={false}/>
                                    <label htmlFor='a4' className="btn btn-info" name='businessClass' id='a4' >중식</label>
                                </div>
                                <div className='p-1' key='a5'>
                                    <input type='check' className="btn-check" name='businessClass' autoComplete="off" id='a5' value='a5' defaultChecked={true}/>
                                    <label htmlFor='a5' className="btn btn-info" name='businessClass' id='a5' >분식</label>
                                </div>
                                <div className='p-1' key='a6'>
                                    <input type='check' className="btn-check" name='businessClass' autoComplete="off" id='a6' value='a6' defaultChecked={false}/>
                                    <label htmlFor='a6' className="btn btn-info" name='businessClass' id='a6' >아시아 음식</label>
                                </div>
                                <div className='p-1' key='a7'>
                                    <input type='check' className="btn-check" name='businessClass' autoComplete="off" id='a7' value='a7' defaultChecked={false}/>
                                    <label htmlFor='a7' className="btn btn-info" name='businessClass' id='a7' >카페</label>
                                </div>
                                <div className='p-1' key='a8'>
                                    <input type='check' className="btn-check" name='businessClass' autoComplete="off" id='a8' value='a8' defaultChecked={false}/>
                                    <label htmlFor='a8' className="btn btn-info" name='businessClass' id='a8' >기타</label>
                                </div>
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={{ span:5, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType6' size="lg" id='submitBtn' onClick={() => ( setStep('STEP5') )}>
                                    이전
                                </Button>
                            </div>
                        </Col>
                        <Col xs={{ span:5, offset:6}} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType5' size="lg" id='submitBtn' onClick={() => ( setStep('STEP7') )}>
                                    다음
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if( step === 'STEP7'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={60} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>매장 전화번호</p>
                            <p className='TextContent2'>
                                필수 사항은 아니지만,<br/>
                                이 매장을 만날 손님을 위해 입력하는 것을 추천드려요.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group controlId='Id'>
                                <Form.Control size="lg" className='InputType2' type="text" controlid='Id' name='Id' placeholder="내 매장 전화번호를 입력해 주세요."/>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={{ span:5, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType6' size="lg" id='submitBtn' onClick={() => ( setStep('STEP6') )}>
                                    이전
                                </Button>
                            </div>
                        </Col>
                        <Col xs={{ span:5, offset:6}} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType5' size="lg" id='submitBtn' onClick={() => ( setStep('STEP8') )}>
                                    다음
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if( step === 'STEP8'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={70} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>매장 운영시간</p>
                            <p className='TextContent2'>
                                필수 사항은 아니지만,<br/>
                                이 매장을 만날 손님을 위해 입력하는 것을 추천드려요.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group controlId='Id'>
                                <Form.Control size="lg" className='InputType2' type="text" controlid='Id' name='Id' placeholder="예) 화요일~일요일 오전 11:00~오후 12:00"/>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={{ span:5, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType6' size="lg" id='submitBtn' onClick={() => ( setStep('STEP7') )}>
                                    이전
                                </Button>
                            </div>
                        </Col>
                        <Col xs={{ span:5, offset:6}} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType5' size="lg" id='submitBtn' onClick={() => ( setStep('STEP9') )}>
                                    다음
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if( step === 'STEP9'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={85} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>매장 휴무일</p>
                            <p className='TextContent2'>
                                필수 사항은 아니지만,<br/>
                                이 매장을 만날 손님을 위해 입력하는 것을 추천드려요.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span:10, offset:1 }}>
                            <Form.Group controlId='Id'>
                                <Form.Control size="lg" className='InputType2' type="text" controlid='Id' name='Id' placeholder="예) 화요일~일요일 오전 11:00~오후 12:00"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span:5, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType6' size="lg" id='submitBtn' onClick={() => ( setStep('STEP8') )}>
                                    이전
                                </Button>
                            </div>
                        </Col>
                        <Col xs={{ span:5, offset:6}} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType5' size="lg" id='submitBtn' onClick={() => ( setStep('STEP10') )}>
                                    다음
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if( step === 'STEP10'){
        return (
            <>
                <Container>
                    <Btn type='TITLE' title='사업장 등록'/>
                </Container>
                <ProgressBar className='PGB' now={100} />
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}}>
                            <p className='NoIconPageTite'>로고 업로드</p>
                            <p className='TextContent2'>
                                로고는 손님들이 만나는 우리 매장의 첫 인상이에요!<br/>
                                로고 이미지를 가지고 계신다면 <br/>
                                <b>정사각형 이미지</b> 파일을 올려주세요.<br/>
                                만약 로고가 없으시다면, 샘플 로고로 대체됩니다.
                            </p>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col xs={{ span:4, offset:1 }}>
                            <Image className='w-100' src={getImgUrl('images/icon/LogoAdd.png')}/>
                        </Col>
                        <Col xs={{ span:4 }}>
                            <Image className='w-100' src={getImgUrl('images/icon/LogoSample.png')}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span:5, offset:1 }} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType7' size="lg" id='submitBtn' onClick={() => ( setStep('STEP8') )}>
                                    이전
                                </Button>
                            </div>
                        </Col>
                        <Col xs={{ span:5, offset:6}} className='FooterBtnArea'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType5' size="lg" id='submitBtn' onClick={() => ( setStep('STEP11') )}>
                                    사업장 등록
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if (step === 'STEP11'){
        return (
            <>
                <Container>
                    <Row className='MainSpace'>
                        <Col xs={{offset:1, span:10}} className='AlignCenter mt-5 mb-5'>
                            <Image className='' src={getImgUrl('images/icon/QustionPrograss.png')}/>
                        </Col>
                        <Col xs={{offset:1, span:10}} className='mt-5'>
                            <p className='NoIconPageTite text-center'>
                                사업장 등록이 완료되었습니다!<br/>
                                남은 단계를 진행해 보세요.
                            </p>
                        </Col>
                    </Row>
    
                    <Row className='MainSpace'>
                        <Col xs={{ span:10, offset:1 }} className='mt-5'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType2' size="lg" id='submitBtn' onClick={() => ( setStep('STEP1') )}>
                                    사업자 등록증 사본 올리기
                                </Button>
                            </div>
                        </Col>
                    </Row>
    
                    <Row>
                        <Col xs={{ span:10, offset:1 }} className='mt-4'>
                            <div className="d-grid gap-1">
                                <Button variant="primary" className='ButtonType7' size="lg" id='submitBtn' >
                                    스토어 꾸미기
                                </Button>
                            </div>
                        </Col>
                    </Row>
    
                </Container>
            </>
        )
    }
}