import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/ver1.5/layout/Layout";
import { useNavigate, useOutletContext } from "react-router-dom";
import Profile from "./components/organism/Profile";
import axios from "axios";
import { getApiUrl } from "../../../function/common";
import Divider from "../../../components/ver1.5/atom/Divider";
import { color, font } from "../../../components/ver1.5/styles/theme";
import Footer from "../../../components/ver1.5/organism/Footer";
import ActionListCommon from "./components/organism/ActionListCommon";
import { css, styled } from "styled-components";
import { MoreRightIcon } from "../../../components/ver1.5/assets/icons";
import { UserContext } from "../../../contexts/UserContext";
import DefaultModal from "../../../components/ver1.5/organism/DefaultModal";

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

const My = () => {
  const { accessToken } = useContext(UserContext);
  const isLoggedIn = Boolean(accessToken);
  const navigate = useNavigate();
  const { me } = useOutletContext();

  const [modal, setModal] = useState(false);
  const actionList = [
    {
      text: "구매내역",
      rightContent: (
        <ContentWrapper
          onClick={() => {
            if (isLoggedIn) {
              navigate("/user/history");
              return;
            } else {
              setModal(true);
              return;
            }
          }}
        >
          <MoreRightIcon />
        </ContentWrapper>
      ),
      navigate: "/user/history",
    },
    {
      text: "자주 묻는 질문",
      rightContent: (
        <ContentWrapper onClick={() => navigate("/user/faq")}>
          <MoreRightIcon />
        </ContentWrapper>
      ),
      navigate: "/user/faq",
    },
    {
      text: "공지사항",
      rightContent: (
        <ContentWrapper onClick={() => navigate("/user/notice")}>
          <MoreRightIcon />
        </ContentWrapper>
      ),
      navigate: "/user/notice",
    },
  ];

  return (
    <Layout
      styles={css`
        background-color: ${color.gray.d50};
        > * {
          background-color: ${color.primary.white};
        }
      `}
    >
      <Profile
        username={me.nickName ?? me.name}
        email={me.email}
        isLoggedIn={isLoggedIn}
        img={me.profileImageUrl}
      />
      <Divider direction="horizontal" width={"1rem"} color={color.gray.d50} />
      <ActionListCommon
        rows={actionList}
        fontStyle={font.caption1}
        itemVerticalPadding={"1.6rem"}
        containerStyle={css`
          padding: 1.6rem;
          padding-bottom: 2.4rem;
        `}
        title={"고객지원"}
        onClickContainer={(row) => {
          if (row.navigate.includes("history") && !isLoggedIn) {
            setModal(true);
          } else {
            return row.navigate ? navigate(row.navigate) : null;
          }
        }}
      />
      <Divider direction="horizontal" width={"1rem"} color={color.gray.d50} />
      <Footer />
      {modal && (
        <DefaultModal
          title={"로그인이 필요합니다."}
          actionText={"로그인"}
          cancelText={"취소"}
          onAction={() =>
            navigate("/user/login", {
              state: { redirectUrl: `/user/my` },
            })
          }
          onCancel={() => setModal(false)}
        />
      )}
    </Layout>
  );
};

export default My;
