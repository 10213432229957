import {
  Container,
  Row,
  Button,
  Col,
  Form,
  Image,
  ProgressBar,
} from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import Btn from "./../../../components/common/Btn";
import { DaumPostcodeEmbed } from "react-daum-postcode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AlertError, getApiUrl, sleep } from "../../../function/common";
import { PrintConsole } from "./../../../function/common";
import { GetUserMe } from "../../../api/ApiGetUser";

const StepBox = ({
  content,
  footer,
  additionalContentClassName,
  additionalFooterClassName,
}) => {
  return (
    <div className="StoreRegister-StepBox">
      <div
        className={`responsiveInner Inner ${additionalContentClassName ?? ""}`}
      >
        {content}
      </div>
      <div
        className={`fixed-bottom responsiveInner Inner ${
          additionalFooterClassName ?? ""
        }`}
      >
        {footer}
      </div>
    </div>
  );
};

const StepDotBox = ({ active, text }) => {
  return (
    <div className={`StepDotBox ${active ? "active" : ""} text-center`}>
      <div className="StepDot"></div>
      {text}
    </div>
  );
};

export default function WpJoin(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    loginId: false,
    email: false,
    businessNumber: false,
  });
  const [nameInputValid, setNameInputValid] = useState("valid");
  const [stepDotBoxes] = useState([
    {
      active: true,
      text: (
        <p>
          사업장
          <br />
          등록완료
        </p>
      ),
    },
    {
      active: false,
      text: (
        <p>
          사업장등록증
          <br />
          올리기
        </p>
      ),
    },
    {
      active: false,
      text: <p>최종완료</p>,
    },
  ]);

  const [mode, setMode] = useState(8);
  const [progressNow, setProgressNow] = useState((1 / 7) * 100);

  const [data, setData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    businessType: "SOLE",
    businessNumber: "",
    domain: "",
    storePhoneNumber: "",
    businessName: "",
    storeName: "",
    zipCode: "",
    address: "",
    addressDetail: "",
    operationTime: "",
    holidays: "",
    recommender: "",
    bank: "",
    accountNumber: "",
    accountOwner: "",
    origin: "",
  });

  const onChangeData = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
    PrintConsole(data);
  };

  //주소 관련
  const [isOpenPost, setIsOpenPost] = useState(false);

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = (mapData) => {
    let fullAddress = mapData.address;
    let extraAddr = "";

    if (mapData.addressType === "R") {
      if (mapData.bname !== "") {
        extraAddr += mapData.bname;
      }
      if (mapData.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${mapData.buildingName}` : mapData.buildingName;
      }
      fullAddress += extraAddr !== "" ? ` (${extraAddr})` : "";
    }
    setIsOpenPost(false);

    setData({
      ...data,
      zipCode: mapData.zonecode,
      address: fullAddress,
    });
  };

  const onCompletePost2 = (mapData) => {
    let fullAddress = mapData.address;
    let extraAddr = "";

    if (mapData.addressType === "R") {
      if (mapData.bname !== "") {
        extraAddr += mapData.bname;
      }
      if (mapData.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${mapData.buildingName}` : mapData.buildingName;
      }
      fullAddress += extraAddr !== "" ? ` (${extraAddr})` : "";
    }
    setIsOpenPost(false);

    setData({
      ...data,
      zipCode2: mapData.zonecode,
      address2: fullAddress,
    });
  };

  const [businessClass, setBusinessClass] = useState();

  const onCheckData = (e) => {
    if (e.currentTarget.checked) {
      setBusinessClass(e.target.value);
      console.log(e.target.value);
      if (e.target.value === "외식") {
      } else {
        if (document.getElementById("originArea")) {
          document.getElementById("originArea").classList.add("Hide");
        }
        if (document.getElementById("origin")) {
          document.getElementById("origin").textContent = "";
        }
        setData({ ...data, origin: "" });
      }
    } else {
    }
    setRadioCheck();
  };
  function setRadioCheck() {
    let TmpLength = document.querySelectorAll(
      'input[type=radio][name="businessClass"]:checked'
    );
    console.log(TmpLength);
  }

  useEffect(() => {
    if (businessClass === "외식") {
      if (document.getElementById("originArea")) {
        document.getElementById("originArea").classList.remove("Hide");
      }
    } else {
      // document.getElementById('originArea').classList.add('Hide');
      // document.getElementById('origin').textContent = '';
    }
  }, [businessClass]);

  const onCheckStoreName = async (storeName) => {
    const res = await axios
      .get(getApiUrl("stores"), {
        params: {
          storeName,
        },
      })
      .then((res) => res.data);
    if (res.data.length > 0) {
      document.getElementById("storeName").classList.add("active");
      setNameInputValid("invalid");
      return;
    } else {
      document.getElementById("storeName").classList.remove("active");
      setNameInputValid("valid");
      return;
    }
  };
  // const onDomain = (e) => {

  //     let obj = {
  //         domain : data.domain,
  //     }
  //     axios.post(
  //         getApiUrl('store/domain/duplicate'),
  //         obj,
  //         axios.defaults.headers.common['ContentType'] = "application/json;",
  //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
  //     ).then(
  //         function(response){
  //             if(response.status === 200){
  //                 if(response.data.domainExist === true){
  //                     setValidation({...validation, domain : false});
  //                     e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
  //                     e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
  //                     //강제 포커싱
  //                     e.target.parentElement.querySelector('input').focus();
  //                     e.target.parentElement.querySelector('div').textContent = '중복된 도메인 입니다.'
  //                 }else{
  //                     setValidation({...validation, domain : true});
  //                     e.target.parentElement.querySelector('div').classList.remove('invalid-feedback');
  //                     e.target.parentElement.querySelector('div').classList.add('valid-feedback');
  //                 }

  //             }
  //         }
  //     ).catch(function(error){
  //         setValidation({...validation, domain : false});
  //         e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
  //         e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
  //         e.target.parentElement.querySelector('div').textContent = error.response.data.errorDetails[0].reason;
  //     })

  //     PrintConsole(validation);
  // }

  const onBusinessNumber = (e) => {
    //사업자번호 중복체크
    // setValidation({...validation, businessNumber : true});
    let obj = {
      businessNumber: data.businessNumber,
    };
    axios
      .post(
        getApiUrl("store/business-number/duplicate"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;"),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.businessNumberExist === true) {
            setValidation({ ...validation, businessNumber: false });
            e.target.parentElement
              .querySelector("div")
              .classList.remove("valid-feedback");
            e.target.parentElement
              .querySelector("div")
              .classList.add("invalid-feedback");
            //강제 포커싱
            e.target.parentElement.querySelector("input").focus();
            e.target.parentElement.querySelector("div").textContent =
              "중복된 사업자등록번호 입니다.";
          } else {
            setValidation({ ...validation, businessNumber: true });
            e.target.parentElement
              .querySelector("div")
              .classList.remove("invalid-feedback");
            e.target.parentElement
              .querySelector("div")
              .classList.add("valid-feedback");
          }
        }
      })
      .catch(function (error) {
        setValidation({ ...validation, businessNumber: false });
        e.target.parentElement
          .querySelector("div")
          .classList.remove("valid-feedback");
        e.target.parentElement
          .querySelector("div")
          .classList.add("invalid-feedback");
        e.target.parentElement.querySelector("div").textContent =
          error.response.data.errorDetails[0].reason;
      });

    PrintConsole(validation);
  };

  const fileUpload = useRef();
  const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

  const onClickUpload = () => {
    fileUpload.current.click();
  };

  const onChangeImg = (e) => {
    if (e.target.files) {
      const uploadFile = e.target.files[0];

      document.getElementById("PreView").style.display = "block";
      document.getElementById("PreViewImg").src = URL.createObjectURL(
        e.target.files[0]
      );

      setFile(uploadFile);
    }
  };

  const [businessClassType, setBusinessClassType] = useState([]);
  function getData() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(getApiUrl("common/business-class-type"))
        .then(function (response) {
          console.log(response);
          setBusinessClassType(response.data);
        });
      if (response) {
        return resolve(response);
      }
      // reject(new Error("Request is failed"));
    });
  }

  const [bank, setBank] = useState();
  function getBank() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(getApiUrl("common/code?name=BANK_CODE"))
        .then(function (response) {
          console.log(response);
          setBank(response.data);
        });
      if (response) {
        return resolve(response);
      }
      // reject(new Error("Request is failed"));
    });
  }

  function JoinStep1() {
    if (data.zipCode === undefined || data.zipCode === "") {
      alert("우편번호는 필수 입력 항목입니다.");
      return false;
    }
    if (data.address === undefined || data.address === "") {
      alert("주소는 필수 입력 항목입니다.");
      return false;
    }
    setMode(2);
  }

  async function submit() {
    // if(validation.domain !== true){
    //     alert('스토어 주소를 확인해주세요.');
    //     document.getElementById("domain").focus();
    //     return false;
    // }

    if (
      document.getElementById("storeName").value === null ||
      document.getElementById("storeName").value === ""
    ) {
      alert("매장이름을 확인해 주세요.");
      document.getElementById("storeName").focus();
      return false;
    }

    if (businessClass === undefined) {
      alert("업태를 선택해 주세요.");
      return false;
    }

    // 수정 체크
    let obj;
    let tmpData = {
      store: {
        storeName: data.storeName,
        businessName: data.businessName,

        holidays: data.holidays,
        storePhoneNumber: data.storePhoneNumber,

        zipCode: data.zipCode,
        address: data.address,
        addressDetail: data.addressDetail,

        businessClassType: businessClass,
        businessClass: [],

        businessType: data.businessType,
        businessNumber: data.businessNumber,
        // domain              : data.domain,
        domain: "",

        origin: data.origin,

        businessHours: data.operationTime,
      },
      recommender: data.origin,
    };

    console.log(tmpData);
    obj = new Blob([JSON.stringify(tmpData)], { type: "application/json" });

    var formData = new FormData();
    formData.append("request", obj);
    formData.append("logo", file);

    axios
      .post(getApiUrl("stores"), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then(async function (response) {
        if (response.status === 200) {
          let account = {
            clientId: "10001",
          };
          let resUserMe = await GetUserMe(account);
          console.log(resUserMe);

          sleep(500);
          try {
            localStorage.setItem("domainType", resUserMe.data.domainType);
            localStorage.setItem("name", resUserMe.data.name);
            localStorage.setItem(
              "myStores",
              JSON.stringify(
                resUserMe.data.myStores[resUserMe.data.myStores.length - 1]
              )
            );
            localStorage.setItem(
              "storeDomain",
              resUserMe.data.myStores[resUserMe.data.myStores.length - 1]
                .storeDomain
            );
          } catch (e) {
            console.log("mystore 없음 스텝 or 가입안함");
            localStorage.setItem("storeDomain", null);
          }

          setMode(8);
        }
      })
      .catch((error) => {
        AlertError(error);
      });
  }

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    getData();
    getBank();

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  useEffect(() => {
    setProgressNow((mode / 7) * 100);
  }, [mode]);

  console.log(localStorage.getItem("storeDomain"));

  return (
    <div className="StoreRegister">
      <Btn
        type="TITLE"
        titleCanClose
        title="사업장 등록"
        customCloseIcon={
          <Button
            variant="clear"
            className="btn-close ver2 position-absolute opacity-100"
            style={{
              width: "25px",
              height: "auto",
              right: "30px",
              top: "calc(1rem + 7.5px)",
            }}
          >
            <Image
              fluid
              className="d-block"
              src="https://www.flexdaycdn.net/public/images/static/store-register/bussiness_close.png"
            />
          </Button>
        }
      />
      <ProgressBar now={progressNow} />
      <Container>
        {mode === 1 && (
          <StepBox
            content={
              <div className="StoreRegister-title">
                <div className="d-flex justify-content-between">
                  <p className="tit mb-3">내 매장 주소</p>
                  {data.zipCode && data.address && (
                    <Button
                      variant="reset"
                      className="mb-3"
                      onClick={() =>
                        setData((prev) => ({
                          ...prev,
                          zipCode: "",
                          address: "",
                        }))
                      }
                    >
                      <Image
                        fluid
                        src="https://www.flexdaycdn.net/public/images/static/store-register/store_address.png"
                      />
                      주소 재설정
                    </Button>
                  )}
                </div>
                {!(data.zipCode || data.address) && (
                  <>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        className="InputType h-50px"
                        placeholder="주소를 검색해주세요."
                        onChange={onChangeData}
                        onClick={onChangeOpenPost}
                        readOnly
                      />
                    </Form.Group>
                    {isOpenPost ? (
                      <div>
                        <DaumPostcodeEmbed
                          className="postCodeStyle"
                          autoClose
                          onComplete={onCompletePost}
                        />
                      </div>
                    ) : null}
                  </>
                )}
                {data.zipCode && data.address && (
                  <>
                    <p className="StoreRegister-sub my-2">
                      <span className="zip">{data.zipCode}</span>
                      <br />
                      {data.address}
                    </p>
                    <Form.Group controlId="addressDetail">
                      <Form.Control
                        type="text"
                        className="InputType h-50px"
                        value={data.addressDetail || ""}
                        placeholder="상세 주소를 입력해주세요. 예) 플렉스상가 101동 101호"
                        onChange={onChangeData}
                      />
                    </Form.Group>
                  </>
                )}
              </div>
            }
            footer={
              <Button
                variant="default"
                disabled={
                  !Boolean(data.zipCode && data.address && data.addressDetail)
                }
                className="mb-5"
                onClick={() => JoinStep1()}
              >
                다음
              </Button>
            }
          />
        )}
        {mode === 2 && (
          <StepBox
            content={
              <div className="StoreRegister-title">
                <div className="d-flex">
                  <p className="tit mb-3">내 매장 이름</p>
                </div>
                <Form.Group controlId="storeName">
                  <Form.Control
                    type="text"
                    className="InputType h-50px"
                    placeholder="매장 이름을 입력해주세요."
                    onChange={onChangeData}
                    onKeyUp={(e) => {
                      if (e.target.value) {
                        setTimeout(() => {
                          onCheckStoreName(e.target.value);
                        }, 500);
                      }
                    }}
                  />
                  <Form.Control.Feedback
                    type={nameInputValid}
                    className="StoreRegister-error-txt"
                  >
                    이미 사용중인 매장 이름네요!
                    <br />
                    매장 주소를 활용하여 다시 입력해보세요.
                    <br />
                    <br />
                    예) 플렉스데이(문현점)
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            additionalFooterClassName={
              "d-flex justify-content-between gap-10px"
            }
            footer={
              <>
                <Button
                  variant="light"
                  className="type8 mb-5 h-45px"
                  onClick={() => setMode(1)}
                >
                  이전
                </Button>
                <Button
                  variant="default"
                  disabled={!(data.storeName && nameInputValid === "valid")}
                  className="type6 mb-5 h-45px"
                  onClick={() => setMode(3)}
                >
                  다음
                </Button>
              </>
            }
          />
        )}
        {mode === 3 && (
          <StepBox
            content={
              <div className="StoreRegister-title">
                <p className="tit mb-3">매장 업태</p>
                <div className="addprd-inputbox d-flex flex-wrap">
                  {businessClassType.map((row) => {
                    return (
                      <Form.Group key={row}>
                        <Form.Control
                          type="radio"
                          className="btn-check w-auto"
                          autoComplete="off"
                          id={row}
                          onChange={onCheckData}
                          name="businessClass"
                          value={row}
                        />
                        <Form.Label
                          className="btn btn-light type10 w-auto"
                          htmlFor={row}
                        >
                          {row}
                        </Form.Label>
                      </Form.Group>
                    );
                  })}
                </div>
              </div>
            }
            additionalFooterClassName={
              "d-flex justify-content-between gap-10px"
            }
            footer={
              <>
                <Button
                  variant="light"
                  className="type8 mb-5 h-45px"
                  onClick={() => setMode(2)}
                >
                  이전
                </Button>
                <Button
                  variant="default"
                  className="type6 mb-5 h-45px"
                  onClick={() => setMode(4)}
                >
                  다음
                </Button>
              </>
            }
          />
        )}
        {mode === 4 && (
          <StepBox
            content={
              <div className="StoreRegister-title">
                <p className="tit mb-3">매장 전화번호</p>
                <p className="StoreRegister-sub">
                  필수 사항은 아니지만,
                  <br />이 매장을 만날 손님을 위해 입력하는 것을 추천드려요!
                </p>
                <Form.Group controlId="storePhoneNumber">
                  <Form.Control
                    type="number"
                    placeholder="내 매장 전화번호를 입력해 주세요."
                    value={data.storePhoneNumber}
                    onBlur={onBusinessNumber}
                    onChange={onChangeData}
                    onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                    maxLength="12"
                    className="InputType h-50px"
                  />
                  <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            additionalFooterClassName={
              "d-flex justify-content-between gap-10px"
            }
            footer={
              <>
                <Button
                  variant="light"
                  className="type8 mb-5 h-45px"
                  onClick={() => setMode(3)}
                >
                  이전
                </Button>
                <Button
                  variant="default"
                  className="type6 mb-5 h-45px"
                  onClick={() => setMode(5)}
                >
                  다음
                </Button>
              </>
            }
          />
        )}
        {mode === 5 && (
          <StepBox
            content={
              <div className="StoreRegister-title">
                <p className="tit mb-3">매장 운영시간</p>
                <p className="StoreRegister-sub">
                  필수 사항은 아니지만,
                  <br />이 매장을 만날 손님을 위해 입력하는 것을 추천드려요!
                </p>
                <Form.Group controlId="operationTime">
                  <Form.Control
                    type="text"
                    value={data["operationTime"] || ""}
                    placeholder="예) 화요일 ~ 일요일 오전 11:00 ~ 오후 12:00"
                    onChange={onChangeData}
                    className="InputType h-50px"
                  />
                </Form.Group>
              </div>
            }
            additionalFooterClassName={
              "d-flex justify-content-between gap-10px"
            }
            footer={
              <>
                <Button
                  variant="light"
                  className="type8 mb-5 h-45px"
                  onClick={() => setMode(4)}
                >
                  이전
                </Button>
                <Button
                  variant="default"
                  className="type6 mb-5 h-45px"
                  onClick={() => setMode(6)}
                >
                  다음
                </Button>
              </>
            }
          />
        )}
        {mode === 6 && (
          <StepBox
            content={
              <div className="StoreRegister-title">
                <p className="tit mb-3">매장 휴무일</p>
                <p className="StoreRegister-sub">
                  필수 사항은 아니지만,
                  <br />이 매장을 만날 손님을 위해 입력하는 것을 추천드려요!
                </p>
                <Form.Group controlId="holidays">
                  <Form.Control
                    type="text"
                    value={data["holidays"] || ""}
                    placeholder="예) 매월 월요일, 연중무휴"
                    onChange={onChangeData}
                    className="InputType h-50px"
                  />
                </Form.Group>
              </div>
            }
            additionalFooterClassName={
              "d-flex justify-content-between gap-10px"
            }
            footer={
              <>
                <Button
                  variant="light"
                  className="type8 mb-5 h-45px"
                  onClick={() => setMode(5)}
                >
                  이전
                </Button>
                <Button
                  variant="default"
                  className="type6 mb-5 h-45px"
                  onClick={() => setMode(7)}
                >
                  다음
                </Button>
              </>
            }
          />
        )}
        {mode === 7 && (
          <StepBox
            content={
              <div className="StoreRegister-title">
                <p className="tit mb-3">로고 업로드</p>
                <p className="StoreRegister-sub">
                  로고는 손님들이 만나는 우리 매장의 첫 인상이에요!
                  <br />
                  로고 이미지를 가지고 계신다면 <br />꼭{" "}
                  <span className="point">정사각형 이미지</span> 파일을
                  올려주세요.
                  <br />
                  만약 로고가 없으시다면 샘플 로고로 대체됩니다.
                </p>
                <div className="PhotoList">
                  <Row>
                    <Col
                      className="col-4 File-Box"
                      style={{ aspectRatio: 1, cursor: "pointer" }}
                    >
                      <Form.Group controlId="logo">
                        <input
                          type="file"
                          id={props.id}
                          style={{ display: "none" }}
                          ref={fileUpload}
                          onChange={onChangeImg}
                        />
                        <div className="text-center" onClick={onClickUpload}>
                          <Image
                            fluid
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/static/store-main/store_plus_color.png"
                          />
                          <Image
                            fluid
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/static/store-main/store_plus.png"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col
                      id="PreView"
                      className="col-4"
                      style={{ display: "none" }}
                    >
                      <Form.Group className="PhotoImgbox" controlId="logo">
                        <Image
                          className="w-100 DefaultShadow"
                          id="PreViewImg"
                          fluid
                          src=""
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            }
            additionalFooterClassName={
              "d-flex justify-content-between gap-10px"
            }
            footer={
              <>
                <Button
                  variant="light"
                  className="type8 mb-5 h-45px"
                  onClick={() => setMode(6)}
                >
                  이전
                </Button>
                <Button
                  variant="default"
                  className="type6 mb-5 h-45px"
                  onClick={submit}
                >
                  다음
                </Button>
              </>
            }
          />
        )}
        {mode === 8 && (
          <Container className="g-0">
            <div className="Inner m-auto p-2">
              <div className="StoreRegister-StepDot d-flex justify-content-between">
                {stepDotBoxes.map(({ active, text }) => {
                  return <StepDotBox active={active} text={text} />;
                })}
              </div>
              <div className="StoreRegister-title text-center mt-5">
                <p className="tit mb-1">
                  사업자 등록이 완료되었습니다!
                  <br />
                  남은 단계를 진행해보세요.
                </p>
              </div>
              <div
                className="fixed-bottom Inner mb-footer"
                style={{
                  maxWidth: 320,
                }}
              >
                <Button
                  variant="default"
                  className="mb-3"
                  onClick={() => {
                    navigate("/store/approveprocess");
                  }}
                >
                  사업자 등록증 사본 올리기
                </Button>
                <Button
                  variant="light"
                  onClick={() => {
                    navigate("/store/" + localStorage.getItem("storeDomain"));
                  }}
                >
                  스토어 꾸미기
                </Button>
              </div>
            </div>
          </Container>
        )}
      </Container>
    </div>
  );
}
