import axios from "axios";
import {
  AlertError,
  getRedirctUrl,
  LocationUrl,
  PrintConsole,
} from "../function/common";
import { getApiUrl, getSubDomain } from "./../function/common";
import sha256 from "sha256";

export const GetAuthorize = async (obj) => {
  var variables = {};
  let redirectUrl = "/";

  console.log("GetAuthorize " + obj.clientId);

  if (obj.clientId === "10000") {
    redirectUrl = "user/search";
  } else {
    redirectUrl = "/";
  }

  variables = {
    clientId: obj.clientId,
    redirectUri: redirectUrl,
    responseType: "string",
  };

  //서비스 호출
  const res = await axios.get(
    getApiUrl("oauth/authorize"),
    {
      params: variables,
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );

  PrintConsole(obj);

  if (res.data["shouldRedirectLogin"] === true) {
    //테스트 - 로그인 페이지 이동
    await GetLogin(obj);
  } else if (res.data["shouldRedirectLogin"] === false) {
    //url로 이동
    await GetLogin(obj);
  } else {
    console.log("error");
  }
};

export const GetLogin = async (obj) => {
  let res;

  try {
    var variables = {};

    console.log("GetLogin " + obj.clientId);

    variables = {
      loginId: obj.Id,
      password: sha256(obj.Password),
      clientId: obj.clientId, //사용자 10000, 가맹점 10001
      autoLogin: true,
    };

    //서비스 호출
    res = await axios.post(getApiUrl("oauth/login"), variables, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    console.log(res.data["code"]);
    if (res.data["code"] !== null) {
      PrintConsole(res.data["code"]);
      await GetToken(obj, res.data["code"]);
    }
  } catch (e) {
    alert(e.response.data.message);
    console.log("GetLogin");
  }
};

export const GetToken = async (obj, code) => {
  let res;

  var variables = {};

  console.log("GetToken " + obj.clientId);
  console.log("code " + code);

  variables = {
    clientId: obj.clientId,
    code: code,
  };

  console.log(variables);

  //서비스 호출
  res = await axios
    .post(getApiUrl("oauth/token"), variables, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then(function (response) {
      PrintConsole(response);
      if (response.status === 200) {
        localStorage.setItem("accessToken", response.data["accessToken"]);
        localStorage.setItem("refreshToken", response.data["refreshToken"]);

        console.log(localStorage.getItem("accessToken"));
        console.log(localStorage.getItem("refreshToken"));
      }
    })
    .catch(function (error) {
      AlertError(error);
    });
};

export const GetUserMe = async (obj) => {
  console.log("GetUserMe Start");

  try {
    let response;

    var variables = {};

    console.log(obj);

    if (obj.clientId === "10001") {
      variables = {
        clientId: "10001",
        redirectUri: getRedirctUrl("/store/home"),
        // "responseType": "string"
      };
    } else {
      variables = {
        clientId: "10000",
        redirectUri: getRedirctUrl("user/home"),
        // "responseType": "string"
      };
    }

    PrintConsole(localStorage.getItem("accessToken"));

    //서비스 호출
    response = await axios.get(
      getApiUrl("users/me"),
      variables,
      (axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken")),
      (axios.defaults.headers.common["ContentType"] =
        "application/json; charset=utf-8")
    );
    PrintConsole(response);
    return response;
  } catch (e) {
    PrintConsole(e);
  }
};
