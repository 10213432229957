import React from "react";
import { styled } from "styled-components";
import Text from "../atom/Text";
import { color, font } from "../styles/theme";

const RadioOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect
      width={23}
      height={23}
      x={0.5}
      y={0.5}
      fill="#F6F7F8"
      stroke="#E9EAED"
      rx={11.5}
    />
  </svg>
);

const RadioOn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect width={24} height={24} fill="#E03352" rx={12} />
    <rect width={8} height={8} x={8} y={8} fill="#fff" rx={4} />
  </svg>
);

const CheckOn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect width={24} height={24} fill="#E03352" rx={12} />
    <path
      fill="#fff"
      d="m9.82 16.619-4.457-4.38c-.484-.477-.484-1.144 0-1.62.485-.476 1.163-.476 1.647 0l3.585 3.524 6.395-6.286c.484-.476 1.162-.476 1.647 0 .484.476.484 1.143 0 1.62l-7.17 7.047c-.29.38-.581.476-.872.476-.29 0-.581-.095-.775-.381Z"
    />
  </svg>
);

const CheckOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect
      width={23}
      height={23}
      x={0.5}
      y={0.5}
      fill="#F6F7F8"
      stroke="#E9EAED"
      rx={11.5}
    />
    <path
      fill="#D2D5DA"
      d="m9.82 16.619-4.457-4.38c-.484-.477-.484-1.144 0-1.62.485-.476 1.163-.476 1.647 0l3.585 3.524 6.395-6.286c.484-.476 1.162-.476 1.647 0 .484.476.484 1.143 0 1.62l-7.17 7.047c-.29.38-.581.476-.872.476-.29 0-.581-.095-.775-.381Z"
    />
  </svg>
);

const Container = styled.button`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

/**
 * Radio 구성요소
 * @typedef {object} RadioProperty
 * @property {string} label
 * @property {void} onClick
 * - onClick 필요할 때 있으면 사용
 * @property {boolean=} checked
 * @property {any} value
 * @property {"radio"|"checkbox"} type
 * @property {any=} labelFontStyle
 *
 * @param {RadioProperty}
 */
const Radio = ({
  checked,
  label,
  value,
  onClick,
  type = "radio",
  labelFontStyle,
}) => {
  return (
    <Container value={value} onClick={() => (onClick ? onClick(value) : null)}>
      {checked ? (
        type === "radio" ? (
          <RadioOn />
        ) : (
          <CheckOn />
        )
      ) : type === "radio" ? (
        <RadioOff />
      ) : (
        <CheckOff />
      )}
      {label && (
        <Text
          fontStyle={labelFontStyle ? labelFontStyle : font.caption1}
          color={color.gray.d900}
        >
          {label}
        </Text>
      )}
    </Container>
  );
};

export default Radio;
