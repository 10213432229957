import CoustomGeocode from "../components/common/CoustomGeocode";
import DaumAddress from "../components/common/DaumAddress";
import Map from "../components/common/Map";
import AdminLogin from "../pages/admin/main/AdminLogin";
import BoardDetail from "../pages/admin/main/BoardDetail";
import BoardList from "../pages/admin/main/BoardList";
import DashBoard from "../pages/admin/main/DashBoard";
import StorePreView from "../pages/admin/store/StorePreView";
import ConsumerUser from "../pages/admin/user/ConsumerUser";
import ConsumerUserDetail from "../pages/admin/user/ConsumerUserDetail";

const AdminRoutes = [
  { path: "postaddress", element: <DaumAddress /> },
  { path: "navermap", element: <Map /> },
  { path: "geocode", element: <CoustomGeocode /> },
  { path: "storepreview", element: <StorePreView /> },
  { path: "consumerdetail", element: <ConsumerUserDetail /> },
  { path: "consumer", element: <ConsumerUser /> },
  { path: "dashboard", element: <DashBoard /> },
  { path: "pg1", element: <BoardList /> },
  { path: "boarddetail/:id", element: <BoardDetail /> },
  { path: "home", element: <DashBoard /> },
  { path: "login", element: <AdminLogin /> },
  { path: "", element: <AdminLogin /> },
];

export default AdminRoutes;
