import React from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="StoreAlertPage">
      <Container className="g-0">
        <div className="Store-AlertPage Inner text-center">
          <Image
            fluid
            className="Emoji mb-4"
            src="https://www.flexdaycdn.net/public/images/static/store-register/cry-emoji.png"
          />
          <p className="StoreAlert-tit mb-1">페이지를 찾을 수 없어요 !</p>
          <p className="StoreAlert-desc">
            요청하신 페이지가 사라졌거나
            <br />
            잘못된 경로를 이용하셨습니다.
          </p>
        </div>
        <div
          className="fixed-bottom responsiveInner text-center m-auto"
          style={{ width: "90%" }}
        >
          <p className="StoreAlert-sub mb-4">
            아래 버튼을 눌러 다시 시도해주세요.
            <br />
            응답이 없을 경우, 앱을 종료하신 후 다시 실행해주세요.
          </p>
          <Button
            variant="primary"
            className="mb-5"
            onClick={() => navigate("/user")}
          >
            스토어로 가기
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
