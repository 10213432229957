import './Loding.css'

export default function Loding(){
    
    const box = {
        
    }
    
    return (
        <>
            <div className='BackGroundOverLay'>
                <img className='LoadingImg' src='https://dev.flexdaycdn.net/public/images/icon/FlexLogo.png'/>
            </div>
        </>
    )
}