import {
  Container,
  Row,
  InputGroup,
  FormControl,
  Button,
  Col,
  Form,
  Image,
} from "react-bootstrap";
import { useRef, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import sha256 from "sha256";

import "./signup.css";
import Btn from "./../../../components/common/Btn";
import InputBox from "./../../../components/InputBox";
import { AlertError, getApiUrl } from "../../../function/common";
import { timer } from "./../../../function/common";
import { passwordReg } from "../../../components/common/constants";

export default function FindPw() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [mode, setMode] = useState("STEP2");

  const [data, setData] = useState({});
  const [authCodeShow, setAuthCodeShow] = useState(false);
  const sendAlertRef = useRef();
  const pwOverEightAlertRef = useRef();
  const pwRegAlertRef = useRef();
  const pwWrongAlertRef = useRef();
  const onChangeData = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
    console.log(data);
  };

  const { type } = useParams();
  if (type === undefined) {
    console.log("잘못된 접속 입니다.");
    navigate("/404");
  }

  const getAuthCode = async (e) => {
    document.getElementById("submitBtn").setAttribute("disabled", "true");
    let obj = {
      // 'loginId' : data.loginId,
      name: data.name,
      phoneNumber: data.phoneNumber,
    };
    await axios
      .post(
        getApiUrl("auth/pw/code"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setData({
            ...data,
            findPwToken: response.data.token,
          });
          //   인증번호 발송 후 나오는 토스트 메세지
          sendAlertRef.current.classList.add("active");
          setTimeout(() => {
            sendAlertRef.current.classList.remove("active");
          }, 3000);
          setAuthCodeShow(true);
          timer("findPwTimer", 180);
        } else {
          alert("인증번호 발송에 실패하였습니다.");
          document.getElementById("submitBtn").removeAttribute("disabled");
        }
      })
      .catch(function (error) {
        console.log(error);
        AlertError(error);
        document.getElementById("submitBtn").removeAttribute("disabled");
      });
  };

  const checkAuthCode = async (e) => {
    document.getElementById("submitBtn2").setAttribute("disabled", "true");

    let obj = {
      findPwToken: data.findPwToken,
      authCode: data.authCode,
    };
    console.log(obj);
    await axios
      .post(
        getApiUrl("auth/pw/auth"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          setMode("STEP3");

          // setData({
          //     ...data,
          //     ['loginId'] : response.data.loginId
          // });
        } else {
          alert("인증번호 인증에 실패하였습니다.");
          document.getElementById("submitBtn2").removeAttribute("disabled");
        }
      })
      .catch(function (error) {
        console.log(error);
        AlertError(error);
        document.getElementById("submitBtn2").removeAttribute("disabled");
      });
  };

  const changePassword = async (e) => {
    let password = document.getElementById("password").value;
    let passwordCK = document.getElementById("passwordCK").value;

    if (password !== passwordCK) {
      // 비밀번호가 다를 때 나오는 토스트 메세지
      pwWrongAlertRef.current.classList.add("active");
      setTimeout(() => {
        pwWrongAlertRef.current.classList.remove("active");
      }, 3000);
      document.getElementById("password").focus();
      // document.getElementById('submitBtn3').removeAttribute("disabled");
      return false;
    }
    // 비밀번호가 여덟자리 이하일때 나오는 토스트 메세지
    if (password?.length < 8) {
      pwOverEightAlertRef.current.classList.add("active");
      setTimeout(() => {
        pwOverEightAlertRef.current.classList.remove("active");
      }, 3000);
      return false;
    }

    // 비밀번호 규칙에 안맞을 때 나온는 토스트 메세지
    if (!passwordReg.test(password)) {
      pwRegAlertRef.current.classList.add("active");
      setTimeout(() => {
        pwRegAlertRef.current.classList.remove("active");
      }, 3000);
      return false;
    }

    let obj = {
      findPwToken: data.findPwToken,
      password: sha256(password),
    };
    // console.log(obj);
    await axios
      .post(
        getApiUrl("auth/pw/reset"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          alert("비밀번호가 변경되었습니다.");
          navigate("/" + type + "/login");
        } else {
          alert("인증번호 인증에 실패하였습니다.");
          document.getElementById("submitBtn").removeAttribute("disabled");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //데이터 로딩 완료
    setLoading(false);

    return () => {
      console.log("unmount");
      clearTimeout();
    };
  }, []);

  useEffect(() => {
    if (mode === "STEP3") {
      document.getElementById("submitBtn3").removeAttribute("disabled");
    }
  }, [mode]);

  if (mode === "STEP1") {
    return (
      <Container>
        <Btn type="NAVBACK" title="비밀번호 찾기" />
        <Row className="MainSpace">
          <Col xs={{ span: 10, offset: 1 }}>
            <h5>
              비밀번호를 찾고자 하는
              <br />
              아이디를 입력해 주세요.
            </h5>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <Form.Group className="xs-12 mt-3" controlId="loginId">
              <Form.Label>
                아이디 <span className="PointText">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="아이디를 입력해 주세요."
                onChange={onChangeData}
                value={data.loginId || ""}
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 10, offset: 1 }} className="d-grid mt-3">
            <Button
              variant="primary"
              onClick={() => setMode("STEP2")}
              size="lg"
            >
              연락처로 인증
            </Button>
          </Col>
        </Row>
      </Container>
    );
  } else if (mode === "STEP2") {
    return (
      <div className="SignupPage">
        <Btn type="NAVBACK" title="비밀번호 찾기" />
        <Container className="SignUpBox responsiveInner">
          <Row className="MainSpace SignUp-title">
            <p className="tit mb-1">
              회원가입시 등록한 정보를
              <br />
              입력해 주세요.
              <Image
                className="img-fluid ms-1"
                src="https://www.flexdaycdn.net/public/images/static/login/signup_info.png"
              />
            </p>
          </Row>
          <Row>
            <Form.Group className="xs-12 mt-3" controlId="name">
              <Form.Control
                type="text"
                className="InputType"
                placeholder="이름을 입력해 주세요."
                onChange={onChangeData}
                value={data.name || ""}
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="xs-12 mt-3" controlId="phoneNumber">
              <Form.Control
                type="number"
                className="InputType"
                placeholder="연락처를 입력해 주세요.('-' 없이 숫자만 입력)"
                onChange={onChangeData}
                value={data.phoneNumber || ""}
                onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                maxLength="12"
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <div className="d-grid gap-1 mt-3">
              <Button
                variant="default"
                className="mt-3"
                size="lg"
                id="submitBtn"
                onClick={() => {
                  getAuthCode();
                }}
              >
                인증번호 요청
              </Button>
            </div>
          </Row>

          {authCodeShow && (
            <Row>
              <Form.Group className="xs-12 mt-3" controlId="authCode">
                <Row className="SignUp-Contents g-0">
                  <Col className="col-8 pe-2 SignUp-Inputbox">
                    <Form.Control
                      type="number"
                      className="InputType h-50px"
                      placeholder="숫자 6자리를 입력해주세요."
                      onChange={onChangeData}
                      value={data.authCode || ""}
                      onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                      maxLength="6"
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                  </Col>
                  <Col className="col-4">
                    <Button
                      variant="default"
                      size="lg"
                      id="submitBtn2"
                      onClick={() => {
                        checkAuthCode();
                      }}
                    >
                      확인
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          )}

          <Row>
            <Form.Group className="xs-12 mt-3" controlId="authCode">
              <Form.Control.Feedback
                id="findPwTimer"
                type="invalid"
              ></Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div ref={sendAlertRef} className="AlertBox mt-3">
            인증번호가 전송되었습니다.
          </div>
        </Container>
      </div>
    );
  } else if (mode === "STEP3") {
    return (
      <div className="SignupPage">
        <Btn type="TITLE" titleCanClose title="비밀번호 재설정" />
        <Container className="SignUpBox responsiveInner">
          <Row className="MainSpace SignUp-title">
            <p className="tit mb-1">
              새로 사용하실 비밀번호를
              <br />
              입력해 주세요.
              <Image
                className="img-fluid ms-1 mb-1 pw"
                src="https://www.flexdaycdn.net/public/images/static/login/search_password.png"
              />
            </p>
          </Row>
          <Row>
            <Form.Group className="xs-12 mt-3" controlId="password">
              <Form.Control
                type="password"
                className="InputType"
                placeholder="새 비밀번호(8자리 이상, 영문, 숫자 입력 필수)"
                onChange={onChangeData}
                value={data.password || ""}
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="xs-12 mt-3" controlId="passwordCK">
              <Form.Control
                type="password"
                className="InputType"
                placeholder="새 비밀번호 확인"
                onChange={onChangeData}
                value={data.passwordCK || ""}
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <div className="d-grid gap-1 mt-3">
              <Button
                variant="default"
                size="lg"
                id="submitBtn3"
                onClick={() => changePassword()}
              >
                비밀번호 변경
              </Button>
            </div>
          </Row>
          <div ref={pwOverEightAlertRef} className="AlertBox mt-3">
            비밀번호 8자리 이상 입력해주세요.
          </div>
          <div ref={pwRegAlertRef} className="AlertBox mt-3">
            영문과 숫자를 모두 입력해주세요.
          </div>
          <div
            ref={pwWrongAlertRef}
            className="AlertBox mt-3 text-center h-auto py-2"
          >
            입력하신 비밀번호가 다릅니다.
            <br />
            다시 입력해주세요.
          </div>
        </Container>
      </div>
    );
  }
}
