import React, { useEffect, useState } from "react";
import { css, styled } from "styled-components";
import { color } from "../styles/theme";

const Container = styled.div`
  width: ${(p) => (p.$width ? p.$width : "4.4rem")};
  height: 2.8rem;
  background: ${(p) => (p.$on ? color.primary.flexRed : color.gray.d200)};
  border-radius: 10rem;
  overflow: hidden;
  position: relative;
`;

const Btn = styled.div`
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1;
  background: ${color.primary.white};
  border-radius: 1.2rem;
  position: absolute;
  top: 0.4rem;
  left: ${(p) => (p.$on ? "calc(100% - 2.4rem)" : "0.4rem")};
  transition: left 0.3s ease-in-out;
`;

const Switch = ({ width, value, setValue }) => {
  const [on, setOn] = useState(value ?? false);
  const onToggle = () => {
    if (setValue) {
      setValue(!on);
    } else {
      setOn(!on);
    }
  };

  useEffect(() => {
    setOn(value);
  }, [value]);

  return (
    <Container onClick={onToggle} $on={on} $width={width}>
      <Btn $on={on} />
    </Container>
  );
};

export default Switch;
