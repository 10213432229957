
import { Row, Col, Container, Form, Table, Image, ProgressBar, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons';
// import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from "react-bootstrap";
// import CustomAlert from './../../../components/common/CustomAlert';
import StoreItem from '../../components/common/StoreItem';
// import CustomModal from './../../../components/common/CustomModal';
import { AlertError, getApiUrl, getRedirctUrl, includeStr, setCommas } from '../../function/common';
// import QRFooter from './../../user/QRFooter';
// import LineChart from '../../../components/chart/LineChart';
import QrCode from '../../components/common/QrCode';
// import { getImgUrl, PrintConsole } from './../../../function/common';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel'
// import Footer from '../../components/common/Footer';
import Gradient from '../../components/common/Gradient';
import StoreInfo from '../../components/common/StoreInfo';
import { useParams } from 'react-router-dom';
import { GetUserMe } from '../../api/ApiGetUser';
// import { deviceType, setAccessToken, setNav1 } from '../../../api/ApiWebView';
import { getStoreAuth } from '../../api/ApiGetStoreUser';
// import Footer_V2 from '../../components/common/Footer_V2';

import './common.css';
import './StoreMain.css';

import CustomAlert from '../../components/common/CustomAlert';
import { getImgUrl, PrintConsole } from '../../function/common';
import Footer_V2 from './../../components/common/Footer_V2';
import QRFooter from '../user/QRFooter';
import { openQRRead, setAccessToken, setNav1 } from '../../api/ApiWebView';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function UserStore(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { storeDomain } = useParams();
    // console.log(storeDomain);
    // if(storeDomain === 'null' || storeDomain === undefined){
    //     console.log(storeDomain);
    //     navigate('/404');
    // }

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }

    console.log(localStorage.getItem('domainType'));
    // console.log(localStorage.getItem('storeDomain'));

    const [store, setStore] = useState();
    function getStore() {
        if( localStorage.getItem('domainType') === 'STORE' ) {
            return new Promise( async function(resolve, reject) {
                const response = await axios.get(
                    getApiUrl('stores/'+storeDomain ),
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                    axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
                ).then(
                    function(response){
                        console.log('----------------------------------');
                        console.log(response);
                        if(response.status === 200){
                            setStore(response.data);
                        }
                    }
                ).catch(function(error){
                    navigate('/404');
                    console.log('error');
                })
                // if (response) {
                //     console.log(response);
                //     return resolve(response);
                // }
            });
        }else{
            return new Promise( async function(resolve, reject) {
                const response = await axios.get( getApiUrl('stores/'+storeDomain )
                ).then(
                    function(response){
                        console.log('----------------------------------');
                        console.log(response);
                        if(response.status === 200){
                            setStore(response.data);
                        }
                    }
                ).catch(function(error){
                    navigate('/404');
                    console.log('error');
                })
                // if (response) {
                //     console.log(response);
                //     return resolve(response);
                // }
            });
        }
    };

    const [storeAuth, setStoreAuth] = useState();

    const [saleItem, setSaleItem] = useState();
    
    function getSeleItem() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('stores/'+storeDomain+'/products?status=SALE')
            ).then(
                function(response){
                    setSaleItem(response.data);
                }
            ).catch(
                function (e) {
                    console.log(e);
                }
            )
            if (response) {

                return resolve(response);
            }
        });
    };
    const [stopItem, setStopItem] = useState();
    function getStopItem() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('stores/'+storeDomain+'/products?status=SALE_STOPPED')
            ).then(
                function(response){
                    setStopItem(response.data);
                }
            ).catch(
                function (e) {
                    console.log(e);
                }
            )
            if (response) {

                return resolve(response);
            }
        });
    };

    const [storeAdmin, setStoreAdmin] = useState();
    function getStoreAdmin(sort) {
        
        return new Promise( async function(resolve, reject) {
            
            const response = await ( getApiUrl('stores/'+storeDomain+'/products'),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                    }
                }
            ).then(
                function(response){
                    // console.log('*********************************');
                    // console.log(response.data);
                    setStoreAdmin(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    const [storeReview, setStoreReview] = useState();
    function getStoreReview(sort) {
        // console.log(sort);
        let query = '';
        
        switch (sort){
            case 'NEW':
                query = '?reviewOrder=NEW';
                break;
            case 'HIGH':
                query = '?reviewOrder=HIGH';
                break;
            case 'LOW':
                query = '?reviewOrder=LOW';
                break;
            default:
                query = '?reviewOrder=NEW';
                break;
        }
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( getApiUrl('stores/'+storeDomain+'/reviews'+query)
            ).then(
                function(response){
                    PrintConsole(response);
                    setStoreReview(response.data);
                }
            ).catch( function(error){
                console.log(error);
            })
            if (response) {
                console.log(response);
                return resolve(response);
            }
        });
    };

    const titleFileUpload = useRef();
    const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

    const onClickUpload = () => {
        titleFileUpload.current.click();
    }

    const onChangeImg = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile(uploadFile);

            let tmpData = [{'priority' : 1}]
    
            let obj = new Blob( [JSON.stringify(tmpData)], { type: 'application/json'} );
    
            var formData = new FormData();
            formData.append('request', obj);
            formData.append('images', uploadFile);
    
            axios.put(
                getApiUrl('stores/'+storeDomain+'/images'),
                formData, 
                axios.defaults.headers.common['ContentType'] = "multipart/form-data",
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
            ).then(
                function(response){
                    if(response.status === 200){
                        window.location.reload();
                    }else{
                        alert('매장 사진이 변경되었습니다.');
                    }
                }
            ).catch(function(error){
                AlertError(error);
            })
        }
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //상점 정보
        // getStore();
        //아이템 정보
        // getSeleItem();
        // getStopItem();
        //리뷰
        // getStoreReview();

        try{
            setAccessToken();
        }catch(e){}

        // try{
        //     setNav1();
        // }catch(e){}

        setStoreAuth(getStoreAuth());

        // console.log('+++++++'+localStorage.getItem('domainType'));
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    // if(localStorage.getItem('domainType') === 'USER' || localStorage.getItem('domainType') === null){
    //     // 승인전 승인 심사 페이지로 이동
    //     if(store && store.status.code === 4){
    //         navigate('/user/underreview');
    //     }
    // }

    // console.log(storeAuth);

    return (

        <>
        <div className='demo'>
            <div className="TicketPage">
                <div className="container-fluid pt-70px g-0">

                    <nav className="navbar bg-none fixed-top">
                        <div className="container-fluid">
                            <a className="navbar-brand hide" href="#">기본 텍스트</a>
                            <button className="Navbar-Toggler" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                <img className="img-fluid" alt="메뉴"
                                            src="https://www.flexdaycdn.net/public/images/ticket/menu2.png" />
                            </button>
                            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                                aria-labelledby="offcanvasNavbarLabel">
                                <div className="offcanvas-btnbox pt-4 px-4 text-end">
                                    <button type="button" className="btn-close opacity-100 shadow-none" data-bs-dismiss="offcanvas"
                                        aria-label="Close">
                                        <img className="img-fluid close" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/close.png" />
                                    </button>
                                </div>
                                <div className="offcanvas-header p-4">
                                    <img className="img-fluid logo" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/logo2.png" />
                                    <div className="offcanvas-title" id="offcanvasNavbarLabel">
                                        양수정 님
                                    </div>
                                </div>
                                <div className="offcanvas-body position-relative py-4-5">
                                    <div className="navbar-nav justify-content-end">
                                        <div className="nav-item">
                                            <a href="javascript:void(0)" className="nav-link active d-flex align-items-center pb-4" aria-current="page">
                                                <div className="imgbox position-relative">
                                                    <img className="img-fluid be img1" alt=""
                                                    src="https://www.flexdaycdn.net/public/images/ticket/home_02.png" />
                                                    <img className="img-fluid af img1" alt=""
                                                    src="https://www.flexdaycdn.net/public/images/ticket/home.png" />
                                                </div>
                                                <span>메인</span>
                                            </a>
                                        </div>
                                        <div className="nav-item">
                                            <a href={"/demo2/paymentlist"} className="nav-link d-flex align-items-center pb-4">
                                                <div className="imgbox position-relative">
                                                    <img className="img-fluid be img2" alt=""
                                                    src="https://www.flexdaycdn.net/public/images/ticket/pay_list.png" />
                                                    <img className="img-fluid af img2" alt=""
                                                    src="https://www.flexdaycdn.net/public/images/ticket/pay_list_02.png" />
                                                </div>
                                                <span>구매내역</span>
                                            </a>
                                        </div>
                                    </div>
                                    <button type="button"
                                    className="btn btn-default" onClick={ ()=> { localStorage.clear(); navigate("/demo2/userlogin") }}>로그아웃</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <div className="TicketPhoto">
                        <div className="TicketPhoto-Slider">
                            <Swiper loop={true} pagination={{ el: ".TS-pag", type: "fraction" }} modules={[Pagination, Navigation]} className="mySwiper d-flex">
                                <SwiperSlide>
                                    <Image fluid className="w-100 StoreBg" alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main.png" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Image fluid className="w-100 StoreBg" alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main02.png" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Image fluid className="w-100 StoreBg" alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main03.png" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Image fluid className="w-100 StoreBg"  alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main04.png" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Image  fluid className="w-100StoreBg" alt="" src="https://www.flexdaycdn.net/public/images/ticket/store_main05.png" />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        

                        <div className="TicketPhoto-Content d-flex">
                            <button type="button" className="btn btn-clear btn-camera opacity-0 pe-none">
                                <img className="img-fluid" alt=""
                                    src="https://www.flexdaycdn.net/public/images/static/store-main/store_camera.png" />
                            </button>

                            <div className="PhotoCount swiper-pagination TS-pag">
                            </div>
                        </div>
                    </div>

                    <div className="StoreDetail pt-4-5">
                        <div className="StoreTitle align-items-center d-flex px-4-5">
                            <img className="img-fluid logo" alt="" src="https://www.flexdaycdn.net/public/images/ticket/logo2.png" />
                            <p className="mb-0 tit">
                                부경대학교 <br/> 교직원식당 라일락
                            </p>
                        </div>
                        <div className="StoreInfo  px-4-5">
                            <div className="g-0 row mb-2">
                                <div className="col-2 tit">
                                    운영시간
                                </div>
                                <div className="col-10 desc">
                                    주중 - 11:00~14:00
                                    <br />
                                    주말 - 휴무
                                </div>
                            </div>
                            <div className="g-0 row">
                                <div className="col-2 tit">
                                    위치안내
                                </div>
                                <div className="col-10 desc">
                                    대연캠퍼스 미래관 별관 3층
                                </div>
                            </div>
                        </div>


                        <div className="StoreTab-wrap b-top px-4-5">
                            <div className="px-3">
                                <div className="StoreTab-content tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-01" role="tabpanel"
                                        aria-labelledby="nav-01-tab" tabIndex="0">

                                        <div className="ProductList">
                                            <div className="row g-0" onClick={()=> {navigate('/demo2/payment?storeurl='+storeDomain+'&productid=57')}}>
                                                <div className="col-7">
                                                    <p className="prd-tit">
                                                        중식(정식)
                                                    </p>
                                                    <p className="prd-sub">
                                                        
                                                    잡곡밥 or 건강현미밥 / 바지락콩나물국 / 돈육고추볶음 / 두부구이 or 양념장 / 호박나물 / 그린샐러드 or
                                                    펜네샐러드 / 오리엔탈 or 유자 드레싱 / 배추김치
                                                
                                                    </p>
                                                    <p className="prd-price">
                                                        6,000원
                                                    </p>

                                                </div>
                                                <div className="col-5">
                                                    <img className="img-fluid w-100"
                                                        src="https://www.flexdaycdn.net/public/images/ticket/menu2_01.png"
                                                        alt="" />
                                                </div>
                                            </div>

                                            <div className="row g-0" onClick={()=> {navigate('/demo2/payment?storeurl='+storeDomain+'&productid=57')}}>
                                                <div className="col-7">
                                                    <p className="prd-tit">
                                                        석식(정식 A세트)
                                                    </p>
                                                    <p className="prd-sub">
                                                        
                                                    잡곡밥 or 건강현미밥 / 육개장 / 백순대볶음 / 얼동태엿장조림 / 묵김가루무침 / 청경채겉절이 / 그린샐러드 or
                                                    콘샐러드 / 발사믹 or 블루베리드레싱 / 배추김치
                                                
                                                    </p>
                                                    <p className="prd-price">
                                                        5,500원
                                                    </p>
                                                </div>
                                                <div className="col-5">
                                                    <img className="img-fluid w-100"
                                                        src="https://www.flexdaycdn.net/public/images/ticket/menu2_02.png"
                                                        alt="" />
                                                </div>
                                            </div>

                                            <div className="row g-0" onClick={()=> {navigate('/demo2/payment?storeurl='+storeDomain+'&productid=57')}}>
                                                <div className="col-7">
                                                    <p className="prd-tit">
                                                        석식(정식 B세트)
                                                    </p>
                                                    <p className="prd-sub">
                                                        
                                                    
                                                    건강현미밥 or 치자버섯밥 + 양념장 / 미역국 / 닭볶음 / 잡채 / 얼갈이된장지짐 / 그린샐러드 or 단호박 감자샐러드
                                                    / 오리엔탈 or 파인드레싱 / 배추김치
                                                
                                                    </p>
                                                    <p className="prd-price">
                                                        5,500원
                                                    </p>
                                                </div>
                                                <div className="col-5">
                                                    <img className="img-fluid w-100"
                                                        src="https://www.flexdaycdn.net/public/images/ticket/menu2_02.png"
                                                        alt="" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            </div>
        </>
    )
}