import React, { forwardRef } from "react";
import { css, styled } from "styled-components";
import { color } from "../styles/theme";
import { presetHeight } from "../constants";

const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  height: 100%;
  min-height: 100vh;
  position: ${(p) => (p.position ? p.position : "relative")};
  background: ${color.primary.white};
  ${(p) =>
    p.$headerPadding &&
    css`
      padding-top: ${presetHeight.header};
    `};
  ${(p) =>
    p.$footerPadding &&
    css`
      padding-bottom: ${presetHeight.bottomNav};
    `}
  ${(p) => p.$styles};
  @media screen and (min-width: 500px) {
    border: 0.1rem solid #fafafa;
    left: 50%;
    transform: translate(-50%, 0);
    /* box-shadow: 0px 5px 10px rgba(10, 10, 10, 0.2); */
    box-shadow: 12px 0 15px -4px rgba(10, 10, 10, 0.2),
      -12px 0 8px -4px rgba(10, 10, 10, 0.2);
  }
`;

const Layout = (
  { children, position, headerPadding = true, footerPadding = true, styles },
  ref
) => {
  return (
    <Container
      ref={ref}
      position={position}
      $headerPadding={headerPadding}
      $footerPadding={footerPadding}
      $styles={styles}
    >
      {children}
    </Container>
  );
};

export default forwardRef(Layout);
