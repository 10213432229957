import React, { useContext } from "react";
import { css, styled } from "styled-components";
import ReviewListItem from "../molecule/ReviewListItem";
import ReviewAverage from "../molecule/ReviewAverage";
import Text from "../../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../../components/ver1.5/styles/theme";
import { reviewSortList } from "../../../../../../components/ver1.5/constants";
import { useNavigate } from "react-router-dom";
import SelectMenu from "../../../../../../components/ver1.5/organism/SelectMenu";
import Button from "../../../../../../components/ver1.5/atom/Button";
import { PencilIcon } from "../../../../../../components/ver1.5/assets/icons";
import { UserContext } from "../../../../../../contexts/UserContext";
import axios from "axios";
import { getApiUrl } from "../../../../../../function/common";
import { getReviews } from "../..";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  > * {
    flex-shrink: 0;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  width: calc(100% - 3.2rem);
  margin: 2.4rem auto 1.6rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const BtnWrapper = styled.div`
  width: 100%;
  padding: 0 1.6rem;
  margin-top: 3.2rem;
`;

const ReviewList = ({
  data = [],
  average,
  setSort,
  sort,
  storeDomain,
  modal,
  setModal,
  deleteCallback,
}) => {
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();

  const onClickWrite = () => {
    if (modal) return;
    if (!accessToken) {
      setModal(true);
      return;
    }
    navigate(`/user/storereview?storeurl=${storeDomain}`);
  };

  const onClickDelete = async (reviewId) => {
    await axios
      .delete(getApiUrl(`stores/${storeDomain}/reviews/${reviewId}`))
      .then((res) => {
        if (res.status === 200) {
          deleteCallback();
        }
      })
      .catch((e) => {
        if (e.response?.data?.httpStatus === "FORBIDDEN") {
          alert("본인이 작성한 리뷰만 삭제할 수 있습니다.");
        } else {
          alert(e.response?.data?.message);
        }
      });
  };

  return (
    <Container>
      <ReviewAverage average={average} />
      <BtnWrapper>
        <Button
          text="리뷰 작성"
          variant="outline"
          iconPosition="left"
          icon={<PencilIcon />}
          customFontStyle={css`
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
          `}
          onClick={() => onClickWrite()}
        />
      </BtnWrapper>
      <SelectContainer>
        <Text fontStyle={font.subtitle3} color={color.gray.d900}>
          전체 리뷰
        </Text>
        <SelectMenu value={sort} options={reviewSortList} setValue={setSort} />
      </SelectContainer>
      {data.map((item, index) => (
        <ReviewListItem
          key={item.id}
          info={item}
          isLast={index === data.length - 1}
          onClickEdit={() => {
            navigate(
              `/user/storereview/edit?storeurl=${storeDomain}&id=${item.id}`,
              {
                state: { reviewInfo: item },
              }
            );
          }}
          onClickReport={() => {
            navigate("/user/report?domain=" + storeDomain + "&id=" + item.id);
          }}
          onClickDelete={() => onClickDelete(item.id)}
        />
      ))}
    </Container>
  );
};

export default ReviewList;
