import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  @media screen and (min-width: 500px) {
    background-color: #fafafa;
  }
`;

const RootLayout = ({ children }) => {
  return <Container>{children}</Container>;
};

export default RootLayout;
