import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { GetAuthorize, GetUserMe } from "../../../api/ApiGetUser1.5";
import ListGroupH from "../../../components/ListGroupH";
import CheckBox from "../../../components/common/CheckBox";
import Layout from "../../../components/ver1.5/layout/Layout";
import { Logo } from "../../../components/ver1.5/assets/icons";
import Text from "../../../components/ver1.5/atom/Text";
import { css, styled } from "styled-components";
import { presetHeight } from "../../../components/ver1.5/constants";
import TextField from "../../../components/ver1.5/atom/TextField";
import { color, font } from "../../../components/ver1.5/styles/theme";
import Radio from "../../../components/ver1.5/molecule/Radio";
import Button from "../../../components/ver1.5/atom/Button";
import Divider from "../../../components/ver1.5/atom/Divider";
import { UserContext } from "../../../contexts/UserContext";
import ReactGA from "react-ga4";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-bottom: 1.6rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0;
  gap: 1.6rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(p) => (p.$gap ? p.$gap : "1.6rem")};
  ${(p) =>
    p.$padding &&
    css`
      padding: ${p.$padding};
    `}
  height: ${(p) => (p.$height ? p.$height : "auto")};
`;

const LoginTextField = ({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  onKeyDown,
  autoComplete = "off",
}) => {
  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={(e) => (onChange ? onChange(e) : null)}
      backgroundColor={color.gray.d50}
      padding={"1.6rem"}
      inputStyles={css`
        border-radius: 1.2rem;
      `}
      label={label}
      type={type}
      onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : null)}
      autoComplete={autoComplete}
    />
  );
};

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = "10000";
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [autoLogin, setAutoLogin] = useState(false);
  // TODO : id, password input error text
  const [error, setError] = useState({
    id: false,
    password: false,
  });

  const { login: loginContext } = useContext(UserContext);

  const onEnterLogin = (e) => {
    if (!(id && password)) return;
    if (e.key === "Enter") {
      onLogin();
    }
  };

  const onLogin = async () => {
    const account = {
      Id: id,
      Password: password,
      clientId,
    };

    await GetAuthorize(account, autoLogin);
    if (
      !localStorage.getItem("userAccessToken") &&
      !sessionStorage.getItem("userAccessToken")
    ) {
      return;
    }
    const res = await GetUserMe(account, autoLogin).then((res) => {
      if (res.status !== 200) alert("로그인에 실패하였습니다.");
      if (autoLogin) {
        localStorage.setItem("userDomainType", res.data.domainType);
        localStorage.setItem("userName", res.data.name);
        localStorage.setItem("userNickName", res.data.nickName);
        localStorage.setItem("userPhoneNumber", res.data.phoneNumber);
        localStorage.setItem("userEmail", res.data.email);
      } else {
        sessionStorage.setItem("userDomainType", res.data.domainType);
        sessionStorage.setItem("userName", res.data.name);
        sessionStorage.setItem("userNickName", res.data.nickName);
        sessionStorage.setItem("userPhoneNumber", res.data.phoneNumber);
        sessionStorage.setItem("userEmail", res.data.email);
      }
    });
    loginContext();
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.event({
        category: "account",
        action: "login",
        label: "" + id,
      });
    }
    if (location.state?.redirectUrl) {
      navigate(location.state.redirectUrl, { replace: true });
    } else {
      navigate("/user", { replace: true });
    }
    return res;
  };

  //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
  // getVersion();
  // setAccessToken();

  return (
    <Layout
      footerPadding={false}
      styles={css`
        padding: 3.2rem;
        padding-top: ${`calc(${presetHeight.header} + 3.2rem)`};
      `}
    >
      <TitleContainer>
        <Text
          styles={css`
            font-family: "twayair", sans-serif;
            font-size: 3.2rem;
          `}
        >
          일상을 선물하는
        </Text>
        <Logo />
      </TitleContainer>
      <ContentContainer>
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1.6rem",
          }}
        >
          <LoginTextField
            placeholder="이메일"
            label={"이메일"}
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <LoginTextField
            placeholder="비밀번호"
            label={"비밀번호"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={onEnterLogin}
            type={"password"}
            autoComplete="new-password"
          />
        </form>
        <Radio
          label="로그인 상태 유지"
          labelFontStyle={font.body1}
          type="checkbox"
          value={autoLogin}
          checked={autoLogin}
          onClick={() => setAutoLogin((prev) => !prev)}
        />
        <Button
          text="로그인"
          onClick={() => onLogin()}
          disabled={!(id && password)}
        />
        <Row $padding={"1.6rem"}>
          <Button
            variant="text"
            text="아이디 찾기"
            onClick={() =>
              navigate("/user/findAccount", { state: { initialTab: "id" } })
            }
          />
          <Divider
            direction="vertical"
            width={"0.1rem"}
            verticalHeight={"1rem"}
          />
          <Button
            variant="text"
            text="비밀번호 찾기"
            onClick={() =>
              navigate("/user/findAccount", { state: { initialTab: "pw" } })
            }
          />
        </Row>
        <Row $gap={"0.8rem"} $padding={"2.4rem"}>
          <Text fontStyle={font.caption2} color={color.gray.d600}>
            아직 회원이 아니신가요?
          </Text>
          <Button
            variant="text"
            text="회원가입하기"
            textBtnFontStyle={font.body1}
            customFontStyle={css`
              text-decoration: underline;
            `}
            onClick={() => navigate("/user/join")}
          />
        </Row>
      </ContentContainer>
    </Layout>
  );
};

export default Login;
