import React from "react";
import { css, styled } from "styled-components";
import {
  ColumnContainer,
  RowContainer,
} from "../../../../../components/ver1.5/styles/Ver2GlobalStyles";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";

const Container = styled(ColumnContainer)`
  padding: 1.6rem;
  padding-top: 2.4rem;
  gap: 0.8rem;
`;

const InfoRow = styled(RowContainer)`
  justify-content: space-between;
  padding: 1.4rem 0;
  ${(p) =>
    !p.$isLast &&
    css`
      border-bottom: 1px solid ${color.gray.d50};
    `}
`;

const StoreInfo = ({ storeName, businessNumber, address }) => {
  const info = [
    {
      label: "상호",
      value: storeName,
    },
    {
      label: "사업자등록번호",
      value: businessNumber,
    },
    {
      label: "주소",
      value: address,
    },
  ];
  return (
    <Container>
      <Text fontStyle={font.subtitle3} color={color.gray.d900}>
        이용 매장 정보
      </Text>
      <ColumnContainer>
        {info.map(({ label, value }, index) => (
          <InfoRow key={index} $isLast={index === info.length - 1}>
            <Text fontStyle={font.caption2} color={color.gray.d600}>
              {label}
            </Text>
            <Text fontStyle={font.caption2} color={color.gray.d900}>
              {value}
            </Text>
          </InfoRow>
        ))}
      </ColumnContainer>
    </Container>
  );
};

export default StoreInfo;
