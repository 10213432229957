import { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./SideNav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { AlertError, getApiUrl, LoginCheck } from "./../../function/common";
import axios from "axios";

export default function SideNav(props) {
  const ref = useRef(null);
  const back = useRef(null);
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const clickedToggle = () => {
    setToggle((prev) => !prev);

    if (toggle === false) {
      openNav();
    } else {
      closeNav();
    }
  };

  const navigate = useNavigate();

  var navMode = "LOGIN";
  if (
    localStorage.getItem("accessToken") !== undefined &&
    localStorage.getItem("accessToken") !== null
  ) {
    navMode = "LOGIN";
  } else {
    navMode = "VIEWER";
  }

  // ref toggle시에 width가 아니라 left값으로 조정으로 변경 + back toggle시에 배경 transition 처리 - 2023.06.30
  function openNav() {
    // document.getElementById(props.id).style.width = "250px";
    // ref.current.style = "width:250px";
    ref.current.style = "left:calc(100vw - 250px);";
    back.current.style = "background-color: rgba(0, 0, 0, 0.2);";
    back.current.classList.add("NavBackground");
    setTimeout(() => {
      document.querySelector(".closebtn").style.display = "block";
    }, 300);
  }

  function closeNav() {
    // document.getElementById(props.id).style.width = "0";
    // ref.current.style = "width:0px";
    document.querySelector(".closebtn").style.display = "none";
    ref.current.style = "left:100vw;";
    back.current.style = "background-color: transparent;";
    setTimeout(() => {
      back.current.classList.remove("NavBackground");
    }, 300);
  }

  const [authority, setAuthority] = useState();
  async function getAuthority() {
    //권한
    // console.log(localStorage.getItem("storeDomain"));
    if (
      localStorage.getItem("accessToken") !== null &&
      localStorage.getItem("storeDomain") !== null &&
      localStorage.getItem("storeDomain") !== "null"
    ) {
      axios
        .get(
          getApiUrl("users/me/stores/" + localStorage.getItem("storeDomain")),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            setAuthority(response.data.type["code"]);
          }
        })
        .catch(function (error) {
          AlertError(error);
        });
    }
  }

  async function getAuthorityForPos2() {
    //권한
    console.log(localStorage.getItem("storeDomain"));
    if (
      localStorage.getItem("accessToken") !== null &&
      localStorage.getItem("storeDomain") !== null &&
      localStorage.getItem("storeDomain") !== "null"
    ) {
      axios
        .get(
          getApiUrl("users/me/stores/" + localStorage.getItem("storeDomain")),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            setAuthority(response.data.type["code"]);
          }
        })
        .catch(function (error) {
          if (error.response.data && error.response.data.code == -6) {
            localStorage.removeItem("accessToken");
            navigate("/pos2/login");
          } else {
            AlertError(error);
          }
        });
    }
  }

  useEffect(() => {
    if (location && location.pathname == "/pos2/login") {
    } else if (location && location.pathname == "/pos2/main") {
      getAuthorityForPos2();
      return;
    } else {
      getAuthority();
    }
  }, [toggle]);
  useEffect(() => {
    if (location && location.pathname == "/pos2/login") {
    } else if (location && location.pathname == "/pos2/main") {
      getAuthorityForPos2();
      return;
    } else {
      getAuthority();
    }
  }, []);

  let defaultUrl = "";
  if (window.location.href.indexOf("/store/") !== -1) {
    defaultUrl = "/store/faq";
  } else {
    defaultUrl = "/user/faq";
  }
  if (window.location.pathname === "/") {
    return null;
  }
  if (window.location.href.indexOf("/user") !== -1) {
    return null;
  }

  if (window.location.href.indexOf("/underreview") !== -1) {
    return <></>;
  } else if (window.location.pathname === "/404") {
    return <></>;
  } else if (window.location.href.indexOf("/tmp/") !== -1) {
    return <></>;
  } else if (window.location.href.indexOf("/mms/") !== -1) {
    return <></>;
  } else if (window.location.href.indexOf("/ms/") !== -1) {
    return <></>;
  } else if (window.location.href.indexOf("/alimtalk/") !== -1) {
    return <></>;
  } else if (window.location.href.indexOf("/demo/") !== -1) {
    return <></>;
  } else if (window.location.href.indexOf("/demo2/") !== -1) {
    return <></>;
  } else if (window.location.href.indexOf("/pos/") !== -1) {
    return <></>;
  } else if (window.location.href.indexOf("/pos2/") !== -1) {
    return <></>;
  } else if (
    window.location.href.indexOf("/login") !== -1 ||
    window.location.href.indexOf("/join") !== -1 ||
    window.location.href.indexOf("/certification") !== -1 ||
    window.location.href.indexOf("/findid") !== -1 ||
    window.location.href.indexOf("/findpw") !== -1
  ) {
    return <></>;
  } else if (window.location.href.indexOf("inje-ios-qr") !== -1) {
    return <></>;
  } else if (window.location.href.indexOf("/staff/staffmain") !== -1) {
    return <></>;
  } else if (
    window.location.href.indexOf("/itemcreate") !== -1 ||
    window.location.href.indexOf("/productcategory") !== -1
  ) {
    return <></>;
  } else if (window.location.href.indexOf("/storequestions") !== -1) {
    return <></>;
  } else if (
    props.domain === "STORE" ||
    window.location.href.indexOf("/store/") !== -1 ||
    window.location.href.indexOf("/staff/") !== -1
  ) {
    return (
      <div className="StoreMainPage" style={{ marginBottom: 0 }}>
        <Navbar bg="light" className="fixed-top" expand={false}>
          <Container fluid>
            <Navbar.Brand
              as={NavLink}
              to={
                "/store/" +
                localStorage.getItem("storeDomain") +
                "/storemain?storeurl=" +
                localStorage.getItem("storeDomain")
              }
            ></Navbar.Brand>
            <Navbar.Toggle
              id="NavOpen"
              style={{ border: "none" }}
              aria-controls="basic-navbar-nav"
              onClick={clickedToggle}
            />
          </Container>
        </Navbar>
        {/* Lsidenav */}
        <div ref={back} onClick={clickedToggle}>
          <div id={props.id} ref={ref} className="Rsidenav">
            <span
              className="closebtn"
              onClick={() => {
                closeNav();
              }}
            >
              <FontAwesomeIcon icon={faXmark} size="xs" />
            </span>
            {navMode === "LOGIN" ? (
              <>
                <Link
                  as={NavLink}
                  to={"/store/" + localStorage.getItem("storeDomain")}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  홈
                </Link>
                <Link
                  as={NavLink}
                  to={"/store/userinfo"}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  내 정보 수정
                </Link>
                {authority && authority === 1 ? (
                  <Link
                    as={NavLink}
                    to={"/store/storeinfo"}
                    onClick={() => {
                      closeNav();
                    }}
                  >
                    사업장 정보 수정
                  </Link>
                ) : (
                  <></>
                )}
                {authority && authority === 1 ? (
                  <Link
                    as={NavLink}
                    to={"/store/wpselect"}
                    onClick={() => {
                      closeNav();
                    }}
                  >
                    사업장 변경
                  </Link>
                ) : (
                  <></>
                )}
                <Link
                  as={NavLink}
                  to={"/store/usegiftnumber"}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  교환권 결제
                </Link>
                <Link
                  as={NavLink}
                  to={"/store/storeaccount"}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  정산계좌 수정
                </Link>
                {authority && authority === 1 ? (
                  <Link
                    as={NavLink}
                    to={"/store/storelogo"}
                    onClick={() => {
                      closeNav();
                    }}
                  >
                    로고 수정
                  </Link>
                ) : (
                  <></>
                )}
                <Link
                  as={NavLink}
                  to={"/store/storeqrcode"}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  QR코드
                </Link>
                <Link
                  as={NavLink}
                  to={"/store/storecalculate"}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  정산내역
                </Link>
                <Link
                  as={NavLink}
                  to={"/store/storeapply"}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  홍보물 신청
                </Link>
                {authority && authority !== 2 ? (
                  <Link
                    as={NavLink}
                    to={"/store/storestaff"}
                    onClick={() => {
                      closeNav();
                    }}
                  >
                    스텝 관리
                  </Link>
                ) : (
                  <></>
                )}
                {authority && authority === 1 ? (
                  <Link
                    as={NavLink}
                    to={"/store/approveprocess"}
                    onClick={() => {
                      closeNav();
                    }}
                  >
                    사업장 승인
                  </Link>
                ) : (
                  <></>
                )}

                {authority && authority !== 1 ? (
                  <Link
                    as={NavLink}
                    to={"/staff/staffmain"}
                    onClick={() => {
                      closeNav();
                    }}
                  >
                    나의 가맹점
                  </Link>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <Link
                  as={NavLink}
                  to="/store/login"
                  onClick={() => {
                    closeNav();
                  }}
                >
                  로그인
                </Link>
                <Link
                  as={NavLink}
                  to="/join"
                  onClick={() => {
                    closeNav();
                  }}
                >
                  회원가입
                </Link>
              </>
            )}
            {/* <Link as={NavLink} to="/" onClick={ ()=>{closeNav()}}>검색</Link> */}
            <Link
              as={NavLink}
              to="/store/faq"
              onClick={() => {
                closeNav();
              }}
            >
              FAQ
            </Link>

            {navMode === "LOGIN" ? (
              <>
                <Link
                  as={NavLink}
                  to="/store/login"
                  onClick={() => {
                    localStorage.clear();
                    closeNav();
                  }}
                >
                  로그아웃
                </Link>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  } else if (
    props.domain === "USER" ||
    window.location.href.indexOf("/user/") !== -1
  ) {
    return (
      <>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand as={NavLink} to="/user/search"></Navbar.Brand>
            <Navbar.Toggle
              id="NavOpen"
              style={{ border: "none" }}
              aria-controls="basic-navbar-nav"
              onClick={clickedToggle}
            />
          </Container>
        </Navbar>
        <div ref={back} onClick={clickedToggle}>
          <div id={props.id} ref={ref} className="Rsidenav">
            <span
              className="closebtn"
              onClick={() => {
                closeNav();
              }}
            >
              <FontAwesomeIcon icon={faXmark} size="xs" />
            </span>
            {navMode === "LOGIN" ? (
              <>
                <Link as={NavLink} to="/user/giftlist/">
                  선물 내역
                </Link>
                {/* <Link as={NavLink} to="/user/giftbox/">선물함</Link> */}
                <Link
                  as={NavLink}
                  to={"/user/userinfo"}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  내 정보 수정
                </Link>
              </>
            ) : (
              <>
                <Link as={NavLink} to="/user/login">
                  로그인
                </Link>
                <Link as={NavLink} to="/join">
                  회원가입
                </Link>
              </>
            )}
            <Link as={NavLink} to="/user/search">
              검색
            </Link>
            <Link as={NavLink} to="/user/faq">
              FAQ
            </Link>

            {navMode === "LOGIN" ? (
              <>
                <Link
                  as={NavLink}
                  to="/user/login"
                  onClick={() => {
                    localStorage.clear();
                    closeNav();
                  }}
                >
                  로그아웃
                </Link>
                <Link
                  as={NavLink}
                  to="/user/Withdrawal"
                  onClick={() => {
                    closeNav();
                  }}
                >
                  회원탈퇴
                </Link>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand as={NavLink} to="/user/search"></Navbar.Brand>
            <Navbar.Toggle
              id="NavOpen"
              style={{ border: "none" }}
              aria-controls="basic-navbar-nav"
              onClick={clickedToggle}
            />
          </Container>
        </Navbar>
        <div ref={back} onClick={clickedToggle}>
          <div id={props.id} ref={ref} className="Rsidenav">
            <span
              className="closebtn"
              onClick={() => {
                closeNav();
              }}
            >
              <FontAwesomeIcon icon={faXmark} size="xs" />
            </span>
            {navMode === "LOGIN" ? (
              <>
                <Link as={NavLink} to="/user/giftlist/">
                  선물 내역
                </Link>
                {/* <Link as={NavLink} to="/user/giftbox/">선물함</Link> */}
                <Link
                  as={NavLink}
                  to={"/user/userinfo"}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  내 정보 수정
                </Link>
              </>
            ) : (
              <>
                <Link as={NavLink} to="/user/login">
                  로그인
                </Link>
                <Link as={NavLink} to="/join">
                  회원가입
                </Link>
              </>
            )}
            <Link as={NavLink} to="/user/search">
              검색
            </Link>
            <Link as={NavLink} to={defaultUrl}>
              FAQ
            </Link>

            {navMode === "LOGIN" ? (
              <>
                <Link
                  as={NavLink}
                  to="/user/login"
                  onClick={() => {
                    localStorage.clear();
                    closeNav();
                  }}
                >
                  로그아웃
                </Link>
                <Link
                  as={NavLink}
                  to="/user/Withdrawal"
                  onClick={() => {
                    closeNav();
                  }}
                >
                  회원탈퇴
                </Link>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}
