import React, { forwardRef, useMemo } from "react";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const SwiperComponent = (
  {
    initialSlide,
    onSlideChange,
    data = [],
    swiperComponent,
    spaceBetween,
    slidesPerView,
    setWrapperSize,
    centeredSlides,
    effect,
    isFreeMode,
    swiperStyle,
    className,
    pagination,
    slideFitContent,
    spaceBetweenZero,
    autoPlay,
    loop,
    navigation,
    onInit,
    onBeforeInit,
    slideStyle,
  },
  ref
) => {
  const modules = useMemo(() => {
    let moduleList = [];
    if (isFreeMode) {
      moduleList = [...moduleList, FreeMode];
    }
    if (autoPlay) {
      moduleList = [...moduleList, Autoplay];
    }
    if (pagination) {
      moduleList = [...moduleList, Pagination];
    }
    if (navigation) {
      moduleList = [...moduleList, Navigation];
    }
    return moduleList;
  }, [isFreeMode, autoPlay, pagination, navigation]);

  return (
    <Swiper
      spaceBetween={spaceBetween ? spaceBetween : spaceBetweenZero ? 0 : 16}
      slidesPerView={slidesPerView ?? "auto"}
      setWrapperSize={setWrapperSize ?? false}
      centeredSlides={centeredSlides ?? false}
      onSlideChange={onSlideChange}
      initialSlide={initialSlide ?? 0}
      effect={effect ?? "slide"}
      modules={modules.length > 0 && modules}
      freeMode={isFreeMode ?? false}
      style={swiperStyle}
      className={className}
      autoplay={autoPlay}
      loop={loop ?? false}
      pagination={pagination ?? false}
      navigation={navigation ?? false}
      loopedSlides={slidesPerView === "auto" ? 5 : null}
      onInit={onInit}
      onBeforeInit={onBeforeInit}
      ref={ref}
    >
      {data.map((item, index) => {
        return (
          <SwiperSlide
            style={{
              display: "flex",
              alignItems: "center",
              ...(slideFitContent && { width: "fit-content" }),
              ...(slideStyle && slideStyle),
            }}
            key={item.id || index}
          >
            {swiperComponent(item, index)}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default forwardRef(SwiperComponent);
