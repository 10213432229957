
import { Container, Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

export default function PromotionRequest( props) {
    return (
    <>
        <Container className='mt-5'>
            <Row className='align-items-center'>
                <Col xs={9}>
                    <h4>웹 스토어 접속 QR코드가 포함된</h4>
                    <h4>홍보물을 신청해보세요.</h4>
                    <label>매장접속 QR코드가 포함된 홍보물 입니다.</label>
                </Col>
                <Col>
                    <img className="d-block w-100" src="https://placeimg.com/380/380" alt="1" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check type="radio" aria-label="radio 1" label="종이 테이블보 300매" /> 
                    <img className="d-block w-100" src="https://placeimg.com/380/120" alt="1" />
                    <label>사이즈 : 400 x 250 mm</label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check type="radio" aria-label="radio 1" label="테이블 POP 배너 20개" /> 
                    <img className="d-block w-100" src="https://placeimg.com/380/120" alt="1" />
                    <label>사이즈 : 60 x 160 mm</label> 
                </Col>
            </Row>
        </Container>
    </>
    )
}