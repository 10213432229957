import React, { useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";
import Text from "../../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../../components/ver1.5/styles/theme";
import Divider from "../../../../../../components/ver1.5/atom/Divider";
import { ToggleIcon } from "../../../../../../components/ver1.5/assets/icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem 1.6rem 3.2rem;
  gap: 1.8rem;
  ${(p) =>
    p.$isAccordion &&
    css`
      gap: ${(p) => (p.$open ? "1.8rem" : 0)};
      transition: gap 0.3s ease-in-out;
    `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  ${(p) =>
    p.$isAccordion &&
    css`
      max-height: ${(p) => (p.$open ? "300vh" : 0)};
      opacity: ${(p) => (p.$open ? 1 : 0)};
      visibility: ${(p) => (p.$open ? "visible" : "hidden")};
      transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
        visibility 0.3s ease-in-out;
      pre,
      div {
        max-height: ${(p) => (p.$open ? "300vh" : 0)};
        visibility: ${(p) => (p.$open ? "visible" : "hidden")};
        transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
      }
    `}
`;

const Content = styled.div`
  display: flex;
  ${(p) =>
    p.$isColumnBox &&
    css`
      flex-direction: column;
      background: ${color.gray.d50};
      padding: 1.6rem;
      border-radius: 1.2rem;
      gap: 0.8rem;
    `}
`;

const IconWrapper = styled.div`
  display: flex;
  width: 2.4rem;
  aspect-ratio: 1;
  ${(p) =>
    p.$open &&
    css`
      transform: rotate(180deg);
    `}
  transition: transform .3s ease-in-out;
`;

const DetailInfoItem = ({
  sectionTitle,
  contents = [],
  isLast,
  titleWidth,
  isAccordion,
  dividerWidth,
  customContent,
  initialOpen = false,
}) => {
  const [open, setOpen] = useState(initialOpen);

  return (
    <>
      <Container $open={open} $isAccordion={isAccordion}>
        <Text
          fontStyle={font.subtitle3}
          color={color.gray.d900}
          styles={
            isAccordion
              ? css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `
              : null
          }
          onClick={() => setOpen(!open)}
        >
          {sectionTitle}
          {isAccordion && (
            <IconWrapper $open={open}>
              <ToggleIcon />
            </IconWrapper>
          )}
        </Text>
        <ContentWrapper $open={open} $isAccordion={isAccordion}>
          {customContent ? (
            customContent
          ) : contents ? (
            typeof contents === "string" ? (
              <Text fontStyle={font.body2} color={color.gray.d800}>
                <pre>{contents}</pre>
              </Text>
            ) : (
              contents.map(({ title, value, mode, visible }, index) => {
                if (!visible && mode === "columnBox")
                  return <div key={index} />;
                return (
                  <div key={index}>
                    <Content $isColumnBox={mode === "columnBox"}>
                      <Text
                        fontStyle={font.body2}
                        color={color.gray.d600}
                        styles={css`
                          word-break: keep-all;
                          width: ${titleWidth ? titleWidth : "100%"};
                          flex-shrink: 0;
                        `}
                        marginRight="2.4rem"
                      >
                        {title}
                      </Text>
                      <Text
                        fontStyle={font.body2}
                        color={color.gray.d800}
                        styles={css`
                          word-break: keep-all;
                        `}
                      >
                        {value}
                      </Text>
                    </Content>
                  </div>
                );
              })
            )
          ) : null}
        </ContentWrapper>
      </Container>
      {!isLast && (
        <Divider
          direction="horizontal"
          width={dividerWidth ?? "1rem"}
          color={color.gray.d50}
        />
      )}
    </>
  );
};

export default DetailInfoItem;
