import React, { forwardRef, useEffect, useMemo, useState } from "react";
import CouponDecoLine from "../../../../components/ver1.5/assets/img/CouponDeco.svg";
import { css, styled } from "styled-components";
import { Logo } from "../../../../components/ver1.5/assets/icons";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";
import { codeFormatter } from "../molecule/CouponInfoContent";
import BwipJs from "bwip-js";
import { localeStringFormatter } from "../../../../components/ver1.5/utils";
// import ProductCard from "../molecule/ProductCard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem;
  padding-bottom: 0;
  background-color: ${color.gray.d100};
  position: absolute;
  top: -99999px;
  left: -99999px;
  z-index: -10;
  width: 39rem;
  height: 78rem;
`;

const ContentContainer = styled.div`
  border-radius: 1.2rem 1.2rem 0 0;
  background: ${color.primary.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 2.6rem;
`;

const MenuImage = styled.img`
  width: calc(39rem - 3.2rem);
  height: 28rem;
`;

const Line = styled.img`
  position: absolute;
  top: -1.6rem;
  left: 50%;
  transform: translate(-50%);
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  padding-bottom: 1.6rem;
  padding-top: 2.4rem;
`;

const QrContainer = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-top: 1px solid ${color.gray.d100};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-bottom: 0.4rem;
`;

const StoreLogo = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  border: 1px solid ${color.gray.d100};
`;

const QrCode = styled.img`
  width: 18rem;
  height: 18rem;
  margin: 0 auto;
`;

const ProductCard = styled.img`
  width: 24rem;
  height: 14rem;
  border-radius: 0.8rem;
  object-fit: cover;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 3.2rem);
  margin-bottom: 3.2rem;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0;
  gap: 1.2rem;
  ${(p) =>
    !p.$isLast &&
    css`
      border-bottom: 1px solid ${color.gray.d50};
    `}
  > * {
    flex: 1 0 0;
  }
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.8rem;
`;

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64Data = reader.result;
      resolve(base64Data);
    };
  });
};

const CouponImage = (
  {
    qrImage: qrImageProp,
    storeName,
    logoUrl: logoImageProp,
    productName,
    expireDate: expireDateProp,
    orderNumber,
    productImage: productImageProp,
    couponNumber,
    productPrice,
    productCardColor,
    productTextColor,
    productType,
    productBalance = 0,
  },
  ref
) => {
  const expireDate = new Date(expireDateProp);
  const [displayDate, setDisplayDate] = useState("-");
  const [qrImage, setQrImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [productImage, setProductImage] = useState(null);

  const getImageUrl = async (url, setImage) => {
    const result = await getBase64FromUrl(url);
    return setImage(result);
  };

  const infoList = useMemo(() => {
    let result = [
      {
        title: "유효기간",
        value: displayDate,
      },
    ];
    if (Boolean(orderNumber)) {
      result = [
        ...result,
        {
          title: "주문번호",
          value: orderNumber,
        },
      ];
    }
    result = [
      ...result,
      {
        title: "사용처",
        value: storeName,
      },
    ];
    return result;
  }, [orderNumber, displayDate, storeName]);

  useEffect(() => {
    if (expireDate !== "Invalid Date") {
      setDisplayDate(
        `${expireDate.getFullYear()}년 ${
          expireDate.getMonth() + 1 > 10
            ? expireDate.getMonth() + 1
            : "0" + (expireDate.getMonth() + 1)
        }월 ${expireDate.getDate()}일까지`
      );
    }
    // eslint-disable-next-line
  }, [expireDateProp]);

  useEffect(() => {
    if (qrImageProp) {
      getImageUrl(qrImageProp, setQrImage);
    }
    if (productImageProp) {
      getImageUrl(productImageProp, setProductImage);
    }
    if (logoImageProp) {
      getImageUrl(logoImageProp, setLogoImage);
    }
  }, [qrImageProp, productImageProp, logoImageProp]);

  useEffect(() => {
    if (couponNumber) {
      try {
        BwipJs.toCanvas("couponImageBarcodeCanvas", {
          bcid: "pdf417",
          text: couponNumber,
          scale: 3,
          height: 10,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [couponNumber]);

  return (
    <Container ref={ref}>
      <ContentContainer>
        <ProductCard src={productImage} />
        {/* <MenuImage src={productImage} /> */}
        <InfoContainer>
          {/* <Line src={CouponDecoLine} /> */}
          <QrContainer>
            {productType !== 1 && (
              <BalanceContainer>
                <Text fontStyle={font.caption3} color={color.gray.d700}>
                  {productName}
                </Text>
                <Text fontStyle={font.subtitle2}>
                  {localeStringFormatter(productBalance, " 원")}
                </Text>
              </BalanceContainer>
            )}
            {/* <TitleWrapper>
              <StoreLogo src={logoImage} />
            </TitleWrapper> */}
            {/* <Text
              fontStyle={font.body1}
              color={color.gray.d600}
              styles={css`
                text-align: center;
              `}
            >
              {storeName}
            </Text>
            <Text
              fontStyle={font.subtitle2}
              color={color.gray.d900}
              styles={css`
                text-align: center;
              `}
            >
              {localeStringFormatter(productPrice, " 원")}
            </Text> */}
            {/* <QrCode src={qrImage} /> */}
            <RowContainer
              style={{
                justifyContent: "center",
                margin: "0 auto",
                marginTop: "1.2rem",
              }}
            >
              <canvas
                id="couponImageBarcodeCanvas"
                style={{
                  paddingBottom: "3rem",
                }}
              />
            </RowContainer>
            <Text
              fontStyle={font.subtitle2}
              color={color.gray.d900}
              styles={css`
                position: absolute;
                bottom: 1rem;
                left: 50%;
                transform: translate(-50%, 0);
                width: 100%;
                text-align: center;
              `}
            >
              {codeFormatter(couponNumber)}
            </Text>
          </QrContainer>
          <RowContainer>
            {infoList.map((info, index) => (
              <InfoRow key={index} $isLast={index === infoList.length - 1}>
                <Text fontStyle={font.caption2} color={color.gray.d600}>
                  {info.title}
                </Text>
                <Text
                  fontStyle={font.caption2}
                  color={color.gray.d900}
                  styles={css`
                    text-align: right;
                  `}
                >
                  {info.value}
                </Text>
              </InfoRow>
            ))}
          </RowContainer>
          <Logo color={color.gray.d900} width={"7.2rem"} height={"1.8rem"} />
        </InfoContainer>
      </ContentContainer>
    </Container>
  );
};

export default forwardRef(CouponImage);
