import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiUrl, PrintConsole } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import "./mms-gentz2.css";

import { Modal } from 'react-bootstrap';

export default function MmsGentz2(props){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState('STEP1');

    const [tel, setTel] = useState();

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const { code } = useParams();
    PrintConsole(code);

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    function SubmitTel(){
        setTel(document.getElementById('Tel').value);
        setMode('STEP2');
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (loading) return <></>

    return (
        <>
            <div className="MMS-wrap2">
                <div className="MMS-Top">
                    <div className="MMS-Product">
                        <img src="https://www.flexdaycdn.net/public/images/static/mms/gentz-deco.png" className="MMS-PrdDeco" />
                        <div className="MMS-PrdImg">
                            <img src={props.representImageUrl}/>
                        </div>
                        <img src="https://www.flexdaycdn.net/public/images/static/mms/gentz-logo.png" className="MMS-GentsLogo"/>

                    </div>
                </div>
                <div className="MMS-Bottom">
                    <div className="MMS-Items">
                        <div className="MMS-Item">
                            <div className="MMS-Tit">유효기간</div>
                            <div className="MMS-Desc" id="expireDate">{props.expireDate}</div>
                        </div>

                        <div className="MMS-Item">
                            <div className="MMS-Tit">상품</div>
                            <div className="MMS-Desc" id="menuName">{props.productName}</div>
                        </div>
                        <div className="MMS-Item last">
                            <div className="MMS-Tit">매장</div>
                            <div className="MMS-Desc">
                                <span id="storeName">겐츠 베이커리</span>
                            </div>
                        </div>
                    </div>
                    <div className="MMS-QR">
                        <div className="QR-ImgBox">
                            <img className="QR-Img" id="qrcodeUrl" data-size="300"
                                src={"https://dev-api.flexday.kr/common/qrcode?data="+(props.qrCode)+"&size=300"} />
                        </div>
                        <p className="QR-Number" id="couponNumber">
                            {props.couponNumber}
                        </p>
                    </div>
                    <div className="MMS-Items info">
                        <div className="MMS-Item last">
                            <div className="MMS-Desc">
                                <span className="small">
                                    해당 매장에서만 사용가능 <br />
                                    (용호본점, 대연점, 오륙도점, <br />
                                    서면점, 동래점, 동부산점, 창원점)
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="MMS-Flexday">
                    <img src="https://www.flexdaycdn.net/public/images/static/mms/gentz-flexday2.png" />
                </div>
            </div>
        </>
    )
}