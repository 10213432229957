import React, { useState } from "react";
import {
  ColumnContainer,
  RowContainer,
} from "../../../../components/ver1.5/styles/Ver2GlobalStyles";
import Radio from "../../../../components/ver1.5/molecule/Radio";
import styled, { css } from "styled-components";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { localeStringFormatter } from "../../../../components/ver1.5/utils";

const TableContainer = styled(ColumnContainer)`
  padding: 1.6rem;
  gap: 1.6rem;
`;

const TableWrapper = styled(ColumnContainer)`
  height: 100%;
  max-height: calc(100vh - 320px);
  overflow-y: overlay;
`;

const TBody = styled.tbody``;

const Th = styled.th`
  width: calc(1 / 3 * 100%);
  background: ${color.gray.d100};
  position: sticky;
  top: 0;
  height: 3.6rem;
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${color.gray.d100};
  ${(p) =>
    p.$rightWhite &&
    css`
      border-right-color: ${color.primary.white};
    `}
  ${font.body2};
`;

const Tr = styled.tr``;

const Td = styled.td`
  border: 1px solid ${color.gray.d100};
  height: 3.6rem;
  padding: 0.4rem;
  text-align: center;
  vertical-align: middle;
  ${font.caption3};
`;

const HistoryTable = ({
  rows = [],
  queryDate,
  initialDateString,
  issuedAt,
  onClickRadio = () => {},
}) => {
  const [checkedRadio, setCheckedRadio] = useState("recent");
  return (
    <TableContainer>
      <RowContainer style={{ gap: "1.6rem" }}>
        <Radio
          label="최근 3개월"
          checked={checkedRadio === "recent"}
          onClick={() => {
            setCheckedRadio("recent");
            onClickRadio(initialDateString);
          }}
        />
        <Radio
          label="전체"
          checked={checkedRadio === "all"}
          onClick={() => {
            setCheckedRadio("all");
            onClickRadio(issuedAt);
          }}
        />
      </RowContainer>
      <TableWrapper>
        <table style={{ tableLayout: "fixed" }}>
          <thead>
            <Tr>
              <Th $rightWhite>사용일시/매장</Th>
              <Th $rightWhite>금액</Th>
              <Th>상태</Th>
            </Tr>
          </thead>
          <TBody>
            {rows.length > 0 ? (
              rows.map((row, index) => (
                <Tr key={row?.lineId ?? index}>
                  <Td style={{ textAlign: "left" }}>
                    {row?.createdAt} <br /> {row?.storeName}
                  </Td>
                  <Td>
                    {row?.priceInfo?.useAmount
                      ? localeStringFormatter(row.priceInfo.useAmount, "원")
                      : row?.priceInfo?.usedCount
                      ? localeStringFormatter(row.priceInfo.usedCount, "회")
                      : "-"}
                  </Td>
                  <Td>
                    {row?.paymentStatus?.code === 1 ? "사용" : "사용 취소"}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={3}>데이터가 없습니다.</Td>
              </Tr>
            )}
          </TBody>
        </table>
      </TableWrapper>
    </TableContainer>
  );
};

export default HistoryTable;
