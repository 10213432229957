import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/ver1.5/layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  AlertError,
  PrintConsole,
  getApiUrl,
  setCommas,
} from "../../../function/common";
import GiftItem from "./components/organism/GiftItem";
import Divider from "../../../components/ver1.5/atom/Divider";
import { color, font } from "../../../components/ver1.5/styles/theme";
import LabelItem from "./components/molecule/LabelItem";
import GiftInput from "./components/molecule/GiftInput";
import { css, styled } from "styled-components";
import { DeleteIconSmall } from "../../../components/ver1.5/assets/icons";
import { phoneNumberFormatter } from "../../../components/ver1.5/utils";
import Radio from "../../../components/ver1.5/molecule/Radio";
import Text from "../../../components/ver1.5/atom/Text";
import { commonInfoContents } from "../Store/Detail/components/organism/detailInfoContentList";
import DetailInfoItem from "../Store/Detail/components/molecule/DetailInfoItem";
import Footer from "../../../components/ver1.5/organism/Footer";
import GiftFooter from "./components/organism/GiftFooter";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { UserContext } from "../../../contexts/UserContext";

const clientKey = process.env.REACT_APP_STORE_KEY;

const Row = styled.div`
  display: flex;
  align-items: center;
  ${(p) => (p.$styles ? p.$styles : null)};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.$gap ?? 0};
`;

const IconBtn = styled.button`
  margin-top: ${(p) => (p.$marginTop ? p.$marginTop : 0)};
  margin-bottom: ${(p) => (p.$marginBottom ? p.$marginBottom : 0)};
  margin-right: ${(p) => (p.$marginRight ? p.$marginRight : 0)};
  margin-left: ${(p) => (p.$marginLeft ? p.$marginLeft : 0)};
`;

const payTypeList = [
  {
    name: "카드결제",
    value: "CARD",
  },
  {
    name: "휴대폰결제",
    value: "PHONE",
  },
  // {
  //   name: "계좌이체",
  //   value: "ACCOUNT_TRANSFER",
  // },
];

const Gift = () => {
  const navigate = useNavigate();
  const { storeDomain, productId } = useParams();
  const { accessToken, name, phoneNumber } = useContext(UserContext);

  const savedGiftProduct = sessionStorage.getItem("giftProduct");
  if (savedGiftProduct !== `${storeDomain}/${productId}`) {
    sessionStorage.removeItem("giftProduct");
    sessionStorage.removeItem("giftPayType");
    sessionStorage.removeItem("giftRecipientName");
    sessionStorage.removeItem("giftRecipientPhoneNumber");
    sessionStorage.removeItem("giftMessageToRecipient");
  }
  const savedPayType = sessionStorage.getItem("giftPayType");
  const savedRecipientName = sessionStorage.getItem("giftRecipientName");
  const savedRecipientPhoneNumber = sessionStorage.getItem(
    "giftRecipientPhoneNumber"
  );
  const savedMessageToRecipient = sessionStorage.getItem(
    "giftMessageToRecipient"
  );
  const [product, setProduct] = useState({
    price: 0,
    businessName: "",
    paymentPrice: 0,
  });
  const [formData, setFormData] = useState({
    payType: savedPayType ?? "CARD",
    recipientName: savedRecipientName ?? "",
    recipientPhoneNumber: savedRecipientPhoneNumber ?? "010",
    messageToRecipient: savedMessageToRecipient ?? "",
  });
  const [paymentAgree, setPaymentAgree] = useState(false);
  const [submitOn, setSubmitOn] = useState(false);
  const [meCheck, setMeCheck] = useState(false);
  const infoContents = commonInfoContents(product.businessName);

  const priceInfo = [
    {
      name: "상품 금액",
      value: product.price ?? 0,
    },
    {
      name: "할인 금액",
      value:
        Boolean(product.price) && Boolean(product.paymentPrice)
          ? product.price - product.paymentPrice
          : 0,
    },
    {
      name: "총 결제 금액",
      value: product.paymentPrice ?? 0,
      isTotal: true,
    },
  ];

  const getProduct = async () => {
    const res = await axios
      .get(getApiUrl(`stores/${storeDomain}/products/${productId}`))
      .then((res) => res.data)
      .catch((e) => e);
    return res;
  };

  const onClickSubmit = () => {
    if (!accessToken) {
      sessionStorage.setItem("giftProduct", `${storeDomain}/${productId}`);
      sessionStorage.setItem("giftPayType", formData.payType);
      sessionStorage.setItem("giftRecipientName", formData.recipientName);
      sessionStorage.setItem(
        "giftRecipientPhoneNumber",
        formData.recipientPhoneNumber
      );
      sessionStorage.setItem(
        "giftMessageToRecipient",
        formData.messageToRecipient
      );

      alert("로그인 이후에 사용이 가능합니다.");
      navigate("/user/login", {
        replace: true,
        state: {
          redirectUrl: `/user/gift/${storeDomain}/${productId}`,
        },
      });
      return;
    }

    const { payType, recipientName, recipientPhoneNumber, messageToRecipient } =
      formData;
    return new Promise(async function (resolve, reject) {
      let obj = {
        orderPrice: product.paymentPrice,
        payType: payType,
        recipientName: recipientName,
        recipientPhoneNumber: recipientPhoneNumber,
        messageToRecipient: messageToRecipient,
      };
      await axios
        .post(
          getApiUrl(`stores/${storeDomain}/products/${productId}/orders`),
          obj,
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken),
          (axios.defaults.headers.common["ContentType"] = "application/json;")
        )
        .then(function (response) {
          tossLoading(response.data.orderId);
        })
        .catch((error) => {
          AlertError(error);
          setPaymentAgree(false);
        });
    });
  };

  const tossLoading = async (orderId) => {
    localStorage.setItem("storeDomain", storeDomain);
    localStorage.setItem("products", productId);
    localStorage.setItem("orderId", orderId);

    const tossPayments = await loadTossPayments(clientKey);
    let tossMode = "";
    if (formData.payType === "CARD") {
      tossMode = "카드";
    } else if (formData.payType === "PHONE") {
      tossMode = "휴대폰";
    } else if (formData.payType === "ACCOUNT_TRANSFER") {
      tossMode = "계좌이체";
    } else {
      alert("결제 모듈 호출에 실패하였습니다.");
    }

    PrintConsole("orderId " + orderId);
    //계좌이체, 휴대폰
    tossPayments.requestPayment(tossMode, {
      // 결제 수단 파라미터
      // 결제 정보 파라미터
      amount: product.paymentPrice,
      taxFreeAmount: product.paymentPrice,
      orderId: orderId,
      orderName: product.name,
      customerName: name,
      successUrl: process.env.REACT_APP_BASEURL + "user/pg/success",
      // successUrl: "http://localhost:3000/" + "user/pg/success",
      failUrl: process.env.REACT_APP_BASEURL + "user/pg/fail",
    });
  };

  useEffect(() => {
    getProduct().then((res) => {
      if (axios.isAxiosError(res)) {
        console.log("error");
      } else {
        setProduct(res);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      formData.payType &&
      formData.recipientName &&
      formData.recipientPhoneNumber &&
      paymentAgree
    ) {
      setSubmitOn(true);
    } else {
      setSubmitOn(false);
    }
  }, [formData, paymentAgree]);

  const toggleMe = () => {
    if (meCheck) {
      setFormData((prev) => ({
        ...prev,
        recipientName: "",
        recipientPhoneNumber: "",
      }));
      setMeCheck(false);
    } else {
      setFormData((prev) => ({
        ...prev,
        recipientName: name,
        recipientPhoneNumber: phoneNumber,
      }));
      setMeCheck(true);
    }
  };
  return (
    <Layout
      footerPadding={false}
      styles={css`
        padding-bottom: 16rem;
      `}
    >
      <GiftItem {...product} />
      <Divider
        direction="horizontal"
        width={"1rem"}
        color={color.gray.d50}
        marginTop={"2.4rem"}
        marginBottom={"2.4rem"}
      />
      <LabelItem
        title="받는 사람"
        description="결제 완료 후, 선물을 받는 분에게 문자로 선물을 전달합니다"
        contentsMarginTop={"1.2rem"}
        marginBottom={"1.6rem"}
        required
      >
        <Row>
          <GiftInput
            placeholder="받는 분 이름"
            marginRight="1rem"
            value={formData.recipientName}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                recipientName: e.target.value,
              }))
            }
            disabled={meCheck}
          />
          <GiftInput
            placeholder="전화번호"
            width={"17.9rem"}
            value={phoneNumberFormatter(
              formData.recipientPhoneNumber.toString()
            )}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                recipientPhoneNumber: e.target.value,
              }))
            }
            onlyNumber
            maxLength={13}
            disabled={meCheck}
            inputMode={"numeric"}
          />
          <IconBtn
            $marginLeft={"auto"}
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                recipientName: "",
                recipientPhoneNumber: "",
              }));
              setMeCheck(false);
            }}
          >
            <DeleteIconSmall />
          </IconBtn>
        </Row>
        <Radio
          type="checkbox"
          label="나에게 보내기"
          value={meCheck}
          checked={meCheck}
          onClick={toggleMe}
          labelFontStyle={css`
            font-size: ${font.caption2};
            color: ${color.gray.d900};
          `}
        />
      </LabelItem>
      <LabelItem
        title="메세지"
        contentsMarginTop={"0.6rem"}
        marginBottom={"3.2rem"}
      >
        <GiftInput
          elementType="textarea"
          width={"100%"}
          placeholder="최대 50자까지 입력가능합니다"
          maxLength={50}
          value={formData.messageToRecipient}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              messageToRecipient: e.target.value,
            }))
          }
          rows={10}
          showCount
        />
      </LabelItem>
      <Divider
        direction="horizontal"
        width={"1rem"}
        color={color.gray.d50}
        marginTop={"2.4rem"}
        marginBottom={"2.4rem"}
      />
      <LabelItem title="결제 방식" contentsMarginTop={"2.4rem"}>
        <Column $gap={"2.4rem"}>
          {payTypeList.map((type) => (
            <Radio
              key={type.value}
              label={type.name}
              value={type.value}
              checked={formData.payType === type.value}
              onClick={(value) =>
                setFormData((prev) => ({ ...prev, payType: value }))
              }
            />
          ))}
        </Column>
      </LabelItem>
      <Divider
        direction="horizontal"
        width={"1rem"}
        color={color.gray.d50}
        marginTop={"2.4rem"}
        marginBottom={"2.4rem"}
      />
      <LabelItem title="결제 내역" contentsMarginTop={"2.4rem"}>
        <Column $gap={"2.4rem"}>
          {priceInfo.map((info) => (
            <Row
              key={info.name}
              $styles={css`
                justify-content: space-between;
                ${info.isTotal &&
                css`
                  border-top: 1px solid ${color.gray.d100};
                  padding-top: 1.6rem;
                `}
              `}
            >
              <Text
                fontStyle={info.isTotal ? font.body1 : font.caption1}
                color={color.gray.d900}
              >
                {info.name}
              </Text>
              <Text
                fontStyle={info.isTotal ? font.subtitle2 : font.body1}
                color={info.isTotal ? color.primary.flexRed : color.gray.d900}
              >
                {setCommas(info.value) + "원"}
              </Text>
            </Row>
          ))}
        </Column>
      </LabelItem>
      <Divider
        direction="horizontal"
        width={"1rem"}
        color={color.gray.d50}
        marginTop={"2.4rem"}
      />
      {infoContents.map((info, index) => (
        <DetailInfoItem
          key={index}
          isAccordion
          dividerWidth={"0.1rem"}
          isLast={index === infoContents.length - 1}
          contents={info.content}
          titleWidth={info.titleWidth}
          sectionTitle={info.title}
        />
      ))}
      <Footer />
      <GiftFooter
        price={product.paymentPrice}
        agree={paymentAgree}
        setAgree={setPaymentAgree}
        disabled={!submitOn}
        onClickSubmit={onClickSubmit}
      />
    </Layout>
  );
};

export default Gift;
