import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AlertError,
  PrintConsole,
  getApiUrl,
} from "../../../../function/common";
import axios from "axios";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import Text from "../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Button from "../../../../components/ver1.5/atom/Button";
import { Spinner } from "react-bootstrap";
import { UserContext } from "../../../../contexts/UserContext";
import ReactGA from "react-ga4";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
`;

const Chip = styled.div`
  display: flex;
  gap: 0.4rem;
  padding: 0.4rem 1.2rem;
  background: ${color.gray.d50};
  border-radius: 5rem;
  margin-bottom: 8.8rem;
`;

const Image = styled.img`
  width: 16rem;
  height: auto;
  object-fit: contain;
`;

const Footer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  background: ${color.primary.white};
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const PgSuccess = () => {
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }
  PrintConsole(Parameters);
  PrintConsole(accessToken);

  const [data, setData] = useState([]);

  const onChangeData = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
    PrintConsole(data);
  };

  function PgResult() {
    return new Promise(async function (resolve, reject) {
      let obj = {
        paymentKey: Parameters[1].paymentKey,
        amount: Parameters[2].amount,
      };
      // console.log(obj);
      await axios
        .post(
          getApiUrl(
            "stores/" +
              localStorage.getItem("storeDomain") +
              "/products/" +
              localStorage.getItem("products") +
              "/orders/" +
              localStorage.getItem("orderId") +
              "/success"
          ),
          obj,
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken),
          (axios.defaults.headers.common["ContentType"] = "application/json;")
        )
        .then(function (response) {
          if (process.env.NODE_ENV !== "production") {
            console.log(response.data);
          }

          if (response.status === 200) {
            setData(response.data);
            if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
              ReactGA.event({
                category: "payment",
                action: "paymentProduct",
                label: "" + response?.data?.orderNumber,
              });
            }
            localStorage.removeItem("storeDomain");
            localStorage.removeItem("products");
            localStorage.removeItem("orderId");
          } else {
          }
        })
        .catch(function (error) {
          AlertError(error);
          navigate("/", { replace: true });
        });
    });
  }

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    PgResult();

    //데이터 로딩 완료
    setLoading(false);
    //   eslint-disable-next-line
  }, []);

  if (loading || !data?.recipient?.phoneNumber)
    return (
      <Layout footerPadding={false}>
        <Container style={{ width: "100%", aspectRatio: 1 }}>
          <Spinner
            animation="border"
            style={{
              width: "50%",
              height: "50%",
              color: color.gray.d200,
              borderWidth: "1.5rem",
            }}
          />
          <Text
            styles={css`
              font-size: 1.5rem;
              padding-top: 5rem;
              color: ${color.gray.d900};
            `}
          >{`결제가 완료될 때까지 창을 닫지 말아주세요`}</Text>
        </Container>
      </Layout>
    );
  return (
    <Layout footerPadding={false}>
      <Container>
        <Text
          styles={css`
            font-family: "Pretendard Bold", sans-serif;
            font-size: 3.2rem;
            letter-spacing: -0.1rem;
            line-height: 3rem;
            color: ${color.gray.d900};
            > span {
              color: ${color.primary.flexRed};
            }
          `}
          marginBottom="3.2rem"
        >
          선물하기 <span>완료</span>
        </Text>
        <Text
          fontStyle={font.subtitle3}
          color={color.gray.d600}
          styles={css`
            span {
              color: ${color.gray.d900};
            }
          `}
        >
          <span>{data.recipient && data.recipient.name}</span>님에게 문자
          메세지로
        </Text>
        <Text
          fontStyle={font.subtitle3}
          color={color.gray.d600}
          marginBottom="2.4rem"
        >
          선물을 전달했습니다.
        </Text>
        <Chip>
          <Text fontStyle={font.body3} color={color.gray.d600}>
            주문번호
          </Text>
          <Text fontStyle={font.body3} color={color.gray.d700}>
            {data && data.orderNumber}
          </Text>
        </Chip>
        <Image
          src={require("../../../../components/ver1.5/assets/img/giftEnd@2x.png")}
        />
      </Container>
      <Footer>
        <Button
          text="홈으로"
          variant="primaryDark"
          onClick={() => navigate("/", { replace: true })}
        />
        <Button
          text="구매내역 보러가기"
          variant="primary"
          onClick={() => navigate("/user/history", { replace: true })}
        />
      </Footer>
    </Layout>
  );
};

export default PgSuccess;
