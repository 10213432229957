import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import CheckBox from '../../../components/common/CheckBox';

// import ListGroupH from '../../../components/ListGroupH';
// import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState } from 'react';
// import { getImgUrl, LocationUrl } from '../../../function/common';
// import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
// import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
// import SideNav from './../../../components/common/SideNav';

import axios from 'axios';

import { getApiUrl, PrintConsole, setCommas, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

import "./common.css";
import "./PaymentList.css";

export default function PaymentList(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [item, setItem] = useState();
    function getOrderItem() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('users/me/food-tickets'),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setItem(response.data);
                    }
                    
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    useEffect( () => {
        // localStorage.removeItem('accessToken');
        //데이터 로딩
        setLoading(true);

        console.log('effect');
        getOrderItem();

        // try{
        //     setNav3();
        // }catch(e){}
        setLoading(false);
    },[])

    if (loading) return <></>

    
    

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
        <div className='demo'>

            <div className="TPaymentListPage">
        <div className="container-fluid g-0">

            <nav className="sub-navbar">
                <p className="navbar-title">구매내역</p>
                <button type="button" className="btn-clear btn-back" onClick={()=>{navigate(-1)}}>
                    <img className="img-fluid" alt="뒤로가기" src="https://www.flexdaycdn.net/public/images/ticket/back.png" />
                </button>
                <button className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4" type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <img className="img-fluid" alt="메뉴" src="https://www.flexdaycdn.net/public/images/ticket/menu.png" />
                </button>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-btnbox pt-4 px-4 text-end">
                        <button type="button" style={{position:'relative'}} className="btn-close opacity-100 shadow-none" data-bs-dismiss="offcanvas"
                            aria-label="Close">
                            <img className="img-fluid close" alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/close.png" />
                        </button>
                    </div>
                    <div className="offcanvas-header p-4">
                        <img className="img-fluid logo" alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/logo2.png" />
                        <div className="offcanvas-title" id="offcanvasNavbarLabel">
                            양수정 님
                        </div>
                    </div>
                    <div className="offcanvas-body position-relative py-4-5">
                        <div className="navbar-nav justify-content-end">
                            <div className="nav-item">
                                <a href='/demo2/userstore/cwSa0KWVDjGO' className="nav-link d-flex align-items-center pb-4" aria-current="page">
                                    <div className="imgbox position-relative">
                                        <img className="img-fluid be img1" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/home_02.png" />
                                        <img className="img-fluid af img1" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/home.png" />
                                    </div>
                                    <span>메인</span>
                                </a>
                            </div>
                            <div className="nav-item">
                                <a href={"/demo2/paymentlist"} className="nav-link active d-flex align-items-center pb-4">
                                    <div className="imgbox position-relative">
                                        <img className="img-fluid be img2" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/pay_list.png" />
                                        <img className="img-fluid af img2" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/pay_list_02.png" />
                                    </div>
                                    <span>구매내역</span>
                                </a>
                            </div>
                        </div>
                        <button type="button"
                        className="btn btn-default" onClick={ ()=> { localStorage.clear(); navigate("/demo2/userlogin") }}>로그아웃</button>
                    </div>
                </div>
                
            </nav>

            <div className="PayCateTab">
                <div className="nav nav-pills justify-content-around mb-4-5 pt-4-5 px-4-5" id="pills-tab" role="tablist">
                    <div className="nav-item" role="presentation">
                        <button className="nav-link active PayCateBtn" id="pills-cate-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-cate" type="button" role="tab" aria-controls="pills-cate"
                            aria-selected="true">
                            사용 가능
                        </button>
                    </div>
                    <div className="nav-item" role="presentation">
                        <button className="nav-link PayCateBtn" id="pills-gift-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-gift" type="button" role="tab" aria-controls="pills-gift"
                            aria-selected="false">
                            사용 완료
                        </button>
                    </div>
                </div>
                <div className="tab-content mb-4" id="pills-tabContent">
                    <div className="tab-pane show active" id="pills-cate" role="tabpanel" aria-labelledby="pills-cate-tab">

                        <div className="PayPrdList px-4-5">
                            {
                                item && item.data.map( row => (
                                    row.status['code'] === 1 ? 
                                        <div className="PayListItem">
                                            <div
                                                className=" infobox align-items-center d-flex justify-content-between px-4-5 pt-4 pb-2">
                                                <div className="infobox-txt">
                                                    <p className="store-name mb-3">
                                                        {row.storeName}
                                                    </p>
                                                    <p className="prd-name mb-0">
                                                        {row.productName}
                                                    </p>
                                                    <p className="prd-price mb-0">
                                                        <span>{setCommas(row.price)}</span>원
                                                    </p>
                                                </div>
                                                <div className="ViewBtn">
                                                    <Link to={"/demo2/qrdetail/"+row.couponId}>
                                                    {/* <Link to={"/demo/qr/"+row.couponId}> */}
                                                        <img className="img-fluid" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket/go.png" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="middlebox align-items-center d-flex justify-content-between">
                                                <div className="circle left"></div>
                                                <div className="line"></div>
                                                <div className="circle right"></div>
                                            </div>
                                            <div className="btnbox pb-3">
                                                <Link to={"/demo2/qr/"+row.couponId} className="btn btn-default h-auto">
                                                    QR 화면 보러가기
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        <></>
                            ))
                        }

                        </div>
                    </div>

                    <div className="tab-pane" id="pills-gift" role="tabpanel" aria-labelledby="pills-gift-tab">
                        <div className="PayPrdList px-4-5">
                            {
                                item && item.data.map( row => (
                                    row.status['code'] === 2 ? 
                                        <div className="PayListItem used">
                                            <div
                                                className=" infobox align-items-center d-flex justify-content-between px-4-5 pt-4 pb-2">
                                                <div className="infobox-txt">
                                                    <p className="store-name mb-3">
                                                        {row.storeName}
                                                    </p>
                                                    <p className="prd-name mb-0">
                                                        {row.productName}
                                                    </p>
                                                    <p className="prd-price mb-0">
                                                        <span>{setCommas(row.price)}</span>원
                                                    </p>
                                                </div>
                                                <div className="ViewBtn">
                                                    <Link to={"/demo2/qrdetail/"+row.couponId}>
                                                        <img className="img-fluid" alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket/go.png" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="middlebox align-items-center d-flex justify-content-between">
                                                <div className="circle left"></div>
                                                <div className="line"></div>
                                                <div className="circle right"></div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                ))
                            }

                            {/* <div className="PayListItem used">
                                <div
                                    className=" infobox align-items-center d-flex justify-content-between px-4-5 pt-4 pb-2">
                                    <div className="infobox-txt">
                                        <p className="store-name mb-3">
                                            인제대학교 하연관(A동) 학생식당
                                        </p>
                                        <p className="prd-name mb-0">
                                            점심(제육덮밥)
                                        </p>
                                        <p className="prd-price mb-0">
                                            <span>6,000</span>원
                                        </p>
                                    </div>
                                    <div className="ViewBtn">
                                        <a href="../PayDetail/PayDetail.html">
                                            <img className="img-fluid" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/go.png" />
                                        </a>
                                    </div>
                                </div>
                                <div className="middlebox align-items-center d-flex justify-content-between">
                                    <div className="circle left"></div>
                                    <div className="line"></div>
                                    <div className="circle right"></div>
                                </div>
                                <div className="btnbox pb-3">
                                    <Link to={"/demo/qr?qr=1"} className="btn btn-default h-auto">
                                        QR 화면 보러가기
                                    </Link>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>

    </div>
        </>
    )
}