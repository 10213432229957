import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import LeftMenu from './../../../components/admin/LeftMenu';

export default function StorePreView( props ) {

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs ={2}>
                        <LeftMenu />
                    </Col>
                    <Col xs={10}>
                        <div style={{ width: 360, height: 740, margin:'0 auto' }}>
                            <embed type="text/html" src="/store/StoreMain" style={{width:'360px', height:'740px'}} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}