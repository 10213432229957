

import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import CheckBox from '../../../components/common/CheckBox';

// import ListGroupH from '../../../components/ListGroupH';
// import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState } from 'react';
// import { getImgUrl, LocationUrl } from '../../../function/common';
// import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
// import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, Link, useSearchParams, useParams } from 'react-router-dom';
import { useEffect } from 'react';
// import SideNav from './../../../components/common/SideNav';

import { getApiUrl, PrintConsole, setCommas, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

import axios from 'axios';

import "./common.css";
import "./QRDetail.css";

export default function QRDetail(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // const [searchParams, setSearchParams] = useSearchParams();
    // // const [Params] = useSearchParams();
    // console.log(searchParams.get('storeurl'));

    const { number } = useParams();
    
    const [item, setItem] = useState();

    function getOrderItem() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('users/me/food-tickets/'+number),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setItem(response.data);
                        
                    }
                    
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    

    useEffect( () => {
        setLoading(true);

        getOrderItem();
        console.log(item); 

        setLoading(false);
    },[])

    if (loading) return <></>

    
    

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>

        <div className='demo'>

            <div className="TPayDetailPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">구매 상세</p>
                        <button type="button" className="btn-clear btn-back" onClick={ () => {navigate(-1)}}>
                            <img className="img-fluid" alt="뒤로가기" src="https://www.flexdaycdn.net/public/images/ticket/back.png" />
                        </button>
                        <button className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4" type="button"
                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <img className="img-fluid" alt="메뉴"
                                src="https://www.flexdaycdn.net/public/images/ticket/menu.png" />
                        </button>

                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-btnbox pt-4 px-4 text-end">
                                <button type="button" style={{position:'relative'}} className="btn-close opacity-100 shadow-none" data-bs-dismiss="offcanvas"
                                    aria-label="Close">
                                    <img className="img-fluid close" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/close.png" />
                                </button>
                            </div>
                            <div className="offcanvas-header p-4">
                                <img className="img-fluid logo" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                <div className="offcanvas-title" id="offcanvasNavbarLabel">
                                    양수정 님
                                </div>
                            </div>
                            <div className="offcanvas-body position-relative py-4-5">
                                <div className="navbar-nav justify-content-end">
                                    <div className="nav-item">
                                        <a href='javascript:void(0)' onClick={()=>{navigate(-1)}} className="nav-link  d-flex align-items-center pb-4" aria-current="page" >
                                            <div className="imgbox position-relative">
                                                <img className="img-fluid be img1" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/home_02.png" />
                                                <img className="img-fluid af img1" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/home.png" />
                                            </div>
                                            <span>메인</span>
                                        </a>
                                    </div>
                                    <div className="nav-item">
                                        <a href={"/demo/paymentlist"} className="nav-link active d-flex align-items-center pb-4">
                                            <div className="imgbox position-relative">
                                                <img className="img-fluid be img2" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/pay_list.png" />
                                                <img className="img-fluid af img2" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/pay_list_02.png" />
                                            </div>
                                            <span>구매내역</span>
                                        </a>
                                    </div>
                                </div>
                                <button type="button"
                                className="btn btn-default" onClick={ ()=> { localStorage.clear(); navigate("/demo/userlogin") }}>로그아웃</button>
                            </div>
                        </div>

                    </nav>
                    <div className="PayDetailBox px-4-5 py-4-5">

                        <div className="PayDetail mb-3 px-3 py-4">
                            <div className="PayDetail-Tit px-2">
                                식권 상품
                            </div>
                            <div className="align-items-center g-0 row">
                                <div className="col-4">
                                    <img className="img-fluid w-100"
                                        src={item && item.representImageUrl} alt="" />
                                </div>
                                <div className="col">
                                    <p className="prd-tit">
                                        {item && item.storeName}
                                    </p>
                                    <p className="prd-sub">
                                    {item && item.productName}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="PayDetail mb-4-5 px-3 py-4">
                            <div className="PayDetail-Tit px-2">
                                결제 정보
                            </div>
                            <div className="PayDetail-Item d-flex mb-1 px-2">
                                <div className="tit">
                                    결제 방법
                                </div>
                                <div className="price text-end">
                                    {item && item.payType['description']}
                                </div>
                            </div>
                            <div className="PayDetail-Item d-flex mb-1 px-2">
                                <div className="tit">
                                    결제 금액
                                </div>
                                <div className="price text-end">
                                    <span>{item && setCommas(item.price)}</span>원
                                </div>
                            </div>
                            <div className="PayDetail-Item d-flex px-2">
                                <div className="tit">
                                    결제 일자
                                </div>
                                <div className="price text-end">
                                    {item && item.paidAt}
                                </div>
                            </div>
                        </div>

                        <p className="PayInfotxt mb-4">
                            • 구매한 날짜에 사용하지 못하셨다면 <br />
                            유효기간 내 같은 금액의 식단으로 사용이 가능합니다.
                        </p>

                        <p className="PayInfotxt">
                            • 구매일로부터 2주까지 취소가 가능합니다. <br />
                            (단, 이미 사용된 교환권의 경우 취소가 불가능합니다.)
                        </p>

                        <Link to="/demo/paymentcancel" className="btn btn-default mb-4">
                            결제 취소
                        </Link>

                    </div>
                </div>
            </div>

            </div>
        </>
    )
}