import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { PrintConsole, getImgUrl, setCommas, ReturnYear, getApiUrl } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Image, Table, Tabs } from 'react-bootstrap';

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';

import { Tab } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { setAccessToken, setNav5 } from '../../api/ApiWebView';

// import { ResponsiveLine } from '@nivo/line'

// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Line } from 'react-chartjs-2';

import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { ResponsiveLine } from '@nivo/line'

export default function StoreCalculate(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [printCoupon, setPrintCoupon] = useState({});
    const onChangeData2 = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setPrintCoupon({
          ...printCoupon,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };
    
    // console.log('==================='+localStorage.getItem('storeDomain'))

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

        
    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    const tableCss = {verticalAlign: 'top'};

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    
    function getSearch() {
        
        let query = '';

        // console.log(startDate.getMonth());

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDate='+TmpStart+'&endDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/settlements'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setData(response.data);
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    function getPrintList() {
        
        let query = '';

        // console.log(startDate.getMonth());

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDateOfIssuedDate='+TmpStart+'&endDateOfIssuedDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupons'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setPrintCoupon(response.data);

                        getSumPrintList();
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    const [useCoupon, setUseCoupon] = useState({});
    function getUseList() {
        
        let query = '';

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?couponLogType=USED&startDateOfUpdated='+TmpStart+'&endDateOfUpdated='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupon-logs'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    // PrintConsole(response);
                    if(response.status === 200){
                        setUseCoupon(response.data);
                        // console.log(response.data);
                        // console.log('getUseList');

                        // getSumPrintList();

                        getChart();
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
        });
    };

    const [useChart, setUseChart] = useState([]);
    var chartData;
    
    function getChart() {
        
        let query = '';

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?couponLogType=USED&startDateOfUpdated='+TmpStart+'&endDateOfUpdated='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupon-logs/by-date/'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    // PrintConsole(response);
                    if(response.status === 200){
                        setUseChart({"data" : response.data});
                        
                        console.log(response.data);
                        // response.data.map(row => (
                        //     console.log();
                        // ))

                        setUseChart(
                            [
                                {
                                    "id" : "금액",
                                    "color": "hsl(158, 70%, 50%)",
                                    "data" : response.data
                                }
                            ]
                        )
                        console.log(useChart)
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
        });
    };

    const [sumPrintCoupon, setSumPrintCoupon] = useState({});
    function getSumPrintList() {
        let query = '';

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDateOfIssuedDate='+TmpStart+'&endDateOfIssuedDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupons-summary'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setSumPrintCoupon(response.data);
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    const [statistics, setStatistics] = useState({});
    function getStatistics() {
        // let query = '';

        // let start = new Date(startDate);
        // let end = new Date(endDate);

        // let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        // let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        // query = '?startDateOfIssuedDate='+TmpStart+'&endDateOfIssuedDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            let obj = { 
                "storeDomain" : localStorage.getItem('storeDomain')
            }
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupon-statistics'),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                obj
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setStatistics(response.data);
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        getSearch();
        getPrintList();
        getUseList();
        // getChart();
        getStatistics();

        try{
            //webview에 토큰값, 도메인 전달
            setAccessToken();
        }catch(e){}

        try{
            setNav5();
        }catch(e){}
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    console.log(useChart);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="정산 내역" />
                <Tabs
                defaultActiveKey="cancel"
                id="fill-tab"
                className="mt-5"
                justify
                >
                    <Tab eventKey="cancel" title="선물 발행내역">
                        
                            <Row>
                                <Col xs={{span:10, offset:1}}>
                                    <p>최대 1년까지 조회 가능합니다.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{span:4, offset:1}}>
                                    <ReactDatePicker className='text-center' selected={startDate} locale={ko} dateFormat='yyyy-MM-dd' onChange={date => setStartDate(date)} /> 
                                </Col>
                                <Col xs={2} className='text-center'> ~ </Col>
                                <Col xs={4}>
                                    <ReactDatePicker className='text-center' selected={endDate} locale={ko} dateFormat='yyyy-MM-dd' onChange={date => setEndDate(date)} />
                                </Col>
                            </Row>
                            
                            
                            <Row>
                                <Col className='d-grid mt-3' xs={{span:10, offset:1}}>
                                    <button className = "btn btn-primary" onClick={ getPrintList }>조회</button>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                {/* <CheckBox controlId="id" label="전체선택"/> */}
                                <Col>
                                    <Table responsive='sm'>
                                        <thead>
                                            <tr className="text-center">
                                                <th>쿠폰번호</th>
                                                <th>발행일</th>
                                                <th>만료일</th>
                                                <th>가격</th>
                                                <th>상품명</th>
                                            </tr>
                                        </thead>
                                        <tbody style={tableCss}>
                                            {
                                                printCoupon.data && printCoupon.data.length === 0 ? 
                                                    <tr>
                                                        <td colSpan='5' className='text-center'>사용된 교환권이 없습니다.</td>
                                                    </tr>
                                                :
                                                printCoupon.data && printCoupon.data.map( row => (
                                                    <tr className='AlignCenter' key={row.number}>
                                                        <td>{ row.number }</td>
                                                        <td>{ row.issuedDate }</td>
                                                        <td>{ row.expireDate }</td>
                                                        <td>{ setCommas(row.price) + ' 원' }</td>
                                                        <td>{ row.productName }</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        
                    </Tab>

                    <Tab eventKey="history" title="정산내역">
                        
                            <Row>
                                <Col xs={{span:10, offset:1}}>
                                    <p>최대 1년까지 조회 가능합니다.</p>
                                </Col>
                            </Row>

                            <Row >
                                <Col xs={{span:4, offset:1}}>
                                    <ReactDatePicker className='text-center' selected={startDate} locale={ko} dateFormat='yyyy-MM-dd' onChange={date => setStartDate(date)} /> 
                                </Col>
                                <Col xs={2} className='text-center'> ~ </Col>
                                <Col xs={4}>
                                    <ReactDatePicker className='text-center' selected={endDate} locale={ko} dateFormat='yyyy-MM-dd' onChange={date => setEndDate(date)} />
                                </Col>
                            </Row>

                            <Row>
                                <Col className='d-grid mt-3' xs={{span:10, offset:1}}>
                                    <button className = "btn btn-primary" onClick={ getSearch }>조회</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='table-responsive' xs={{span:10, offset:1}}>
                                    <Table className='text-center table-borderless'>
                                        <thead>
                                            <tr>
                                                <td>총 판매액</td>
                                                <td>수수료</td>
                                                <td>정산예정액</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{ data.settlementSum && setCommas(data.settlementSum.storeSalesPrice) + ' 원'}</td>
                                                <td>{ data.settlementSum && setCommas(data.settlementSum.fee) + ' 원'}</td>
                                                <td>{ data.settlementSum && setCommas(data.settlementSum.storeSettlePrice) + ' 원'}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan='3'>
                                                    <label><small>월요일 ~ 일요일 사용한 금액이<br/>차주 금요일 입금(정산)됩니다.</small></label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col>
                                    
                                    <Table responsive='sm'>
                                        <thead>
                                            <tr className="text-center">
                                                <th>주차</th>
                                                <th>총판매액</th>
                                                <th>수수료</th>
                                                <th>정산금액</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.settlementRows && data.settlementRows.length === 0 ? 
                                                    <tr>
                                                        <td colSpan='5' className='text-center'>사용된 교환권이 없습니다.</td>
                                                    </tr>
                                                :
                                                data.settlementRows && data.settlementRows.map( row => (
                                                    <tr className='AlignCenter' key={row.id} >
                                                        <td>
                                                            {row.year + ' - ' + row.weekOfYear}
                                                            <br/>
                                                            <small>({row.startDate + ' - ' + row.endDate})</small>
                                                        </td>
                                                        <td>{ setCommas(row.storeSalesPrice) + ' 원'}</td>
                                                        <td>
                                                            { setCommas(row.stampDuty + row.pgFee + row.netIncome) + ' 원'}
                                                        </td>
                                                        <td>{ setCommas(row.storeSettlePrice) + ' 원'}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                    
                                </Col>
                            </Row>
                        
                    </Tab>


                    <Tab eventKey="use" title="사용내역">
                            <Row>
                                <Col xs={{span:10, offset:1}}>
                                    <Table responsive='sm' className='text-center'>
                                        <thead>
                                            {/* <tr>
                                                <td></td>
                                            </tr> */}
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>전체 결제자 수</td>
                                                <td>{statistics && setCommas(statistics.countOfTotalPayer)}</td>
                                            </tr>
                                            <tr>
                                                <td>신규 방문자 수</td>
                                                <td>{statistics && setCommas(statistics.countOfNewPayer)}</td>
                                            </tr>
                                            <tr>
                                                <td>신규 비율</td>
                                                <td>{statistics && statistics.rateOfNew + "%"}</td>
                                            </tr>
                                            <tr>
                                                <td>총 결제 금액</td>
                                                <td>{statistics && setCommas(statistics.amountOfTotalPaid)}</td>
                                            </tr>
                                            <tr>
                                                <td>총 교환권 사용 금액</td>
                                                <td>{statistics && setCommas(statistics.amountOfCouponPaid)}</td>
                                            </tr>
                                            <tr>
                                                <td>업셀링 비율</td>
                                                <td>{statistics && statistics.rateOfUpselling + "%"}</td>
                                            </tr>
                                            <tr>
                                                <td>총 발행 금액</td>
                                                <td>{statistics && setCommas(statistics.amountOfIssued)}</td>
                                            </tr>
                                            <tr>
                                                <td>총 회수율 금액기준</td>
                                                <td>{statistics && statistics.rateOfRecovery + "%"}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{span:10, offset:1}}>
                                    <p>최대 1년까지 조회 가능합니다.</p>
                                </Col>
                            </Row>

                            <Row >
                                <Col xs={{span:4, offset:1}}>
                                    <ReactDatePicker className='text-center' selected={startDate} locale={ko} dateFormat='yyyy-MM-dd' onChange={date => setStartDate(date)} /> 
                                </Col>
                                <Col xs={2} className='text-center'> ~ </Col>
                                <Col xs={4}>
                                    <ReactDatePicker className='text-center' selected={endDate} locale={ko} dateFormat='yyyy-MM-dd' onChange={date => setEndDate(date)} />
                                </Col>
                            </Row>

                            <Row>
                                <Col className='d-grid mt-3' xs={{span:10, offset:1}}>
                                    <button className = "btn btn-primary" onClick={ getUseList }>조회</button>
                                </Col>
                            </Row>

                            <br/>
                            <Row>
                                <Col style={{ width: 'auto', height: '400px', margin: '0 auto' }}> 

                                    <ResponsiveLine
                                        data={useChart}
                                        margin={{ top: 50, right: 60, bottom: 50, left: 70 }}
                                        xScale={{ type: 'point' }}
                                        yScale={{
                                            type: 'linear',
                                            min: 'auto',
                                            max: 'auto',
                                            stacked: true,
                                            reverse: false
                                        }}
                                        yFormat=" >-.2f"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            orient: 'bottom',
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: '날짜',
                                            legendOffset: 36,
                                            legendPosition: 'middle'
                                        }}
                                        axisLeft={{
                                            orient: 'left',
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: '금액',
                                            legendOffset: -60,
                                            legendPosition: 'middle'
                                        }}
                                        pointSize={10}
                                        pointColor={{ theme: 'background' }}
                                        pointBorderWidth={2}
                                        pointBorderColor="black"
                                        pointLabelYOffset={-12}
                                        useMesh={true}
                                        legends={[]}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Table responsive='sm'>
                                        <thead>
                                            <tr className="text-center">
                                                <th>쿠폰번호</th>
                                                <th>사용날짜</th>
                                                <th>가격</th>
                                                <th>상품명</th>
                                                <th>사용처</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                useCoupon.data && useCoupon.data.length === 0 ? 
                                                    <tr>
                                                        <td colSpan='5' className='text-center'>사용된 교환권이 없습니다.</td>
                                                    </tr>
                                                :
                                                useCoupon.data && useCoupon.data.map( row => (
                                                    <tr className='AlignCenter' key={row.id} >
                                                        <td>
                                                            {row.couponNumber}
                                                        </td>
                                                        <td>
                                                            {row.createdAt}
                                                        </td>
                                                        <td>{ row.facePrice !== null ? setCommas(row.facePrice) + ' 원' : "가격 정보가 없습니다."}</td>
                                                        <td>
                                                            { row.productName }
                                                        </td>
                                                        <td>
                                                            { row.staffName }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                    
                                </Col>
                            </Row>
                        
                    </Tab>

                </Tabs>
            </Container>
        </>
    )
}