import React, { useEffect, useRef } from "react";
import { color } from "../../../../components/ver1.5/styles/theme";
import { styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  /* width: calc(100% - 1.6rem); */
  /* padding-top: 2.4rem; */
  background-color: ${color.primary.white};
  /* border-radius: 0.8rem; */
  /* box-shadow: 0 0 0.8rem 0 ${color.gray.d100}; */
`;

const MapContainer = styled.div`
  width: 100%;
  height: 14.1rem;
  border-bottom: 1rem solid ${color.primary.white};
`;

const MapContent = ({ address }) => {
  const mapRef = useRef();
  const KakaoMapScript = () => {
    if (!address) return;
    const container = mapRef?.current;
    window.kakao.maps.load(() => {
      const options = {
        center: new window.kakao.maps.LatLng(33.450701, 126.570667),
        level: 3,
      };
      const map = new window.kakao.maps.Map(container, options);

      const geocoder = new window.kakao.maps.services.Geocoder();

      geocoder.addressSearch(address, (result, status) => {
        if (status === window.kakao.maps.services.Status.OK) {
          const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
          //   setLatLng({ lat: result[0].y, lng: result[0].x });
          // eslint-disable-next-line
          const marker = new window.kakao.maps.Marker({
            map,
            position: coords,
          });

          map.setCenter(coords);
        }
      });
    });
  };

  useEffect(() => {
    KakaoMapScript();
    // eslint-disable-next-line
  }, [mapRef, address]);

  return (
    <Container>
      <MapContainer ref={mapRef} />
    </Container>
  );
};

export default MapContent;
