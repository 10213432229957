import React, { useRef } from "react";
import { css, styled } from "styled-components";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import {
  CameraIcon,
  DefaultProfile,
} from "../../../../../components/ver1.5/assets/icons";
import TextField from "../../../../../components/ver1.5/atom/TextField";
import Text from "../../../../../components/ver1.5/atom/Text";

const Container = styled.div`
  padding: 0 1.6rem;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 9.6rem;
  height: 9.6rem;
  margin: 2.4rem auto 1.2rem auto;
`;

const ProfileImage = styled.img`
  width: 9.6rem;
  height: 9.6rem;
  aspect-ratio: 1;
  border-radius: 10rem;
  overflow: hidden;
  border: 1px solid ${color.gray.d100};
  object-fit: cover;
  object-position: center center;
`;

const PhotoBtn = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1.6rem;
  border: 1px solid ${color.gray.d100};
  background: ${color.primary.white};
`;

const InputContainer = styled.div`
  padding: 1.6rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;
const DefaultContainer = styled.div`
  svg {
    width: 100%;
    height: 100%;
  }
`;

const EditProfileTopSection = ({
  imageUrl,
  onChangeImg,
  nickname,
  setNickname,
}) => {
  const inputRef = useRef();

  const onClickUpload = () => {
    inputRef.current.click();
  };

  return (
    <Container>
      <ImageWrapper>
        {imageUrl ? (
          <ProfileImage src={imageUrl} />
        ) : (
          <DefaultContainer>
            <DefaultProfile />
          </DefaultContainer>
        )}
        <PhotoBtn onClick={onClickUpload}>
          <CameraIcon />
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            accept="image/jpg, image/jpeg, image/png"
            onChange={onChangeImg}
          />
        </PhotoBtn>
      </ImageWrapper>
      <InputContainer>
        <Text fontStyle={font.body1} color={color.gray.d900}>
          닉네임
        </Text>
        <TextField
          placeholder="닉네임"
          backgroundColor={color.gray.d50}
          padding={"1.6rem"}
          inputStyles={css`
            border-radius: 1.2rem;
          `}
          value={nickname ?? ""}
          onChange={(e) => setNickname(e.target.value)}
          marginBottom="1.4rem"
        />
      </InputContainer>
    </Container>
  );
};

export default EditProfileTopSection;
