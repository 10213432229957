import React from "react";
import { styled } from "styled-components";
import Text from "../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import { setCommas } from "../../../../../function/common";
import DefaultLogo from "../../../../../components/ver1.5/assets/img/shopProfileImgDefault.svg";
import DefaultMenuImg from "../../../../../components/ver1.5/assets/img/menuItemDefault.svg";

const Container = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  padding: 0 1.6rem;
`;

const ItemContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const Image = styled.img`
  min-width: 10rem;
  height: 8rem;
  aspect-ratio: 34 / 21;
  border-radius: 0.8rem;
  overflow: hidden;
  object-fit: cover;
  flex-shrink: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;

const Logo = styled.img`
  width: 2.4rem;
  aspect-ratio: 1;
  border-radius: 1.2rem;
  overflow: hidden;
  object-fit: contain;
  border: 1px solid ${color.gray.d100};
  flex-shrink: 0;
  align-self: flex-start;
`;

const GiftItem = ({
  businessName = "",
  description = "",
  images = [],
  name = "",
  price = 0,
  logoUrl = "",
}) => {
  return (
    <Container>
      <Text
        marginTop="1.2rem"
        marginBottom="1.2rem"
        fontStyle={font.subtitle3}
        color={color.gray.d900}
      >
        선물할 상품
      </Text>
      <ItemContainer>
        <Image
          src={
            Boolean(images[images.length - 1]?.url)
              ? images[images.length - 1]?.url
              : DefaultMenuImg
          }
        />
        <TextContainer>
          <NameContainer>
            <Logo src={Boolean(logoUrl) ? logoUrl : DefaultLogo} />
            <Text fontStyle={font.body3} color={color.gray.d600}>
              {businessName}
            </Text>
          </NameContainer>
          <Text fontStyle={font.body2} color={color.gray.d900}>
            {name}
          </Text>
          <Text fontStyle={font.body4} color={color.gray.d600}>
            {description}
          </Text>
          <Text
            fontStyle={font.subtitle2}
            color={color.gray.d900}
            marginTop="0.4rem"
          >
            {setCommas(price) + "원"}
          </Text>
        </TextContainer>
      </ItemContainer>
    </Container>
  );
};

export default GiftItem;
