

import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import CheckBox from '../../../components/common/CheckBox';

// import ListGroupH from '../../../components/ListGroupH';
// import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState } from 'react';
// import { getImgUrl, LocationUrl } from '../../../function/common';
// import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
// import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, Link, useSearchParams, useParams } from 'react-router-dom';
import { useEffect } from 'react';
// import SideNav from './../../../components/common/SideNav';

import { getApiUrl, PrintConsole, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

import axios from 'axios';

import "./common.css";
import "./QR.css";

export default function QR(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // const [searchParams, setSearchParams] = useSearchParams();
    // // const [Params] = useSearchParams();
    // console.log(searchParams.get('storeurl'));

    const { number } = useParams();
    
    const [item, setItem] = useState();

    function getOrderItem() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('users/me/food-tickets/'+number),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setItem(response.data);
                        
                    }
                    
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    

    useEffect( () => {
        setLoading(true);

        getOrderItem();
        console.log(item); 

        setLoading(false);
    },[])

    if (loading) return <></>

    
    

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
        <div className='demo'>

            <div className="TPayQRPage">
        <div className="container-fluid g-0">

            <nav className="sub-navbar">
                <p className="navbar-title">QR 화면</p>
                <button type="button" className="btn-clear btn-close" onClick={() => {navigate(-1)}}>
                    <img className="img-fluid" alt="뒤로가기"
                        src="https://www.flexdaycdn.net/public/images/ticket/close2.png" />
                </button>
            </nav>

            <div className="QRBox px-4-5 pb-4">
                <div className="QRBox-inner pb-3 pt-5 px-4">
                    <div className="imgbox mb-4 text-center">
                        <img className="img-fluid logo mb-4 mx-auto"
                            src="https://www.flexdaycdn.net/public/images/ticket/inge_type_logo.png" />
                        <img className="img-fluid m-auto qr-img"
                            src={"https://dev-api.flexday.kr/common/qrcode?data="+(item && item.qrcode)+"&size=185"} />
                    </div>

                    <div className="infobox infobox mb-5">
                        <div className="infoitem d-flex align-items-center justify-content-between p-3 pt-0">
                            <div className="tit w-25">
                                상품
                            </div>
                            <div className="desc w-75 text-end">
                                {item && item.productName}
                            </div>
                        </div>
                        <div className="infoitem d-flex align-items-center justify-content-between p-3">
                            <div className="tit w-25">
                                매장
                            </div>
                            <div className="desc w-75 text-end">
                                {item && item.storeName}
                            </div>
                        </div>
                        <div className="infoitem d-flex align-items-center justify-content-between p-3 pb-0 border-0">
                            <div className="tit w-50">
                                유효기간
                            </div>
                            <div className="desc w-50 text-end">
                                {item && item.expireDate}
                            </div>
                        </div>
                    </div>

                    <div className="QRInfotxt text-center">
                        구매한 날짜에 사용하지 못하셨다면 <br />
                        유효기간 내 같은 금액의 식단으로 <br className="mob"/>
                        사용이 가능합니다.
                    </div>
                    <div className="QRBox-Confirm mb-3">
                        <a href={getApiUrl('common/qrcode/download?data='+ process.env.REACT_APP_BASEURL+'user/'+localStorage.getItem('storeDomain')+'&size=1000')} className="btn btn-default" download >
                            이미지 저장
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>

    </div>

        </>
    )
}