import { Button, Row, Col, Container, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { getApiUrl, PrintConsole } from '../../function/common';
import axios from 'axios';

export default function CustomModal( props ){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const StaffDelete = async (e) => {
        let obj = { 
            'domain' : props.domain,
            'staffId' : props.id
        }
        await axios.delete(
            getApiUrl('stores/'+props.domain+'/staff/'+props.staffId),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;",
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                console.log(response);
                if(response.status === 200){
                    // setData({
                    //     ...data,
                    //     ['findIdToken'] : response.data.token,
                    // });
                    alert('정상적으로 직원이 삭제 되었습니다.');
                    window.location.reload();
                    handleClose();
                }else{
                    alert('직원 삭제에 실패 하였습니다.');
                }
            }
        ).catch(function(error){
            PrintConsole(error);
        })
    }
    
    if( props.type === 'DEFAULTSHOW'){
        // 모달 실행
        // setShow(!show);
        return (
            <>
                <Modal show={props.show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{props.mdtext !== undefined ? props.mdtext : "Porps Undefined"}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }else if( props.type === 'STATIC'){
        return (
            <>
                <Button variant="primary" onClick={handleShow}>
                    {props.title !== undefined ? props.title : "Default Title" }
                </Button>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props.mdtext !== undefined ? props.mdtext : "Porps Undefined"}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary">Understood</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }else if( props.type === 'CENTER'){

        return (
            <>
                <Button variant="primary" onClick={handleShow}>
                    {props.title !== undefined ? props.title : "Default Title" }
                </Button>

                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Header closeButton>
                        {/* <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                        </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        {props.mdtitle !== undefined ? <p className="text-center">{props.mdtitle}</p> : null}
                        {props.mdtext !== undefined ? <p className="text-center">{props.mdtext}</p> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="text-center">
                            <Row>
                                { props.leftbtn !== undefined ? <Col onClick={handleClose}>{props.leftbtn !== undefined ? props.leftbtn : "확인"}</Col> : null }
                                { props.rightbtn !== undefined ? <Col onClick={handleClose}>{props.rightbtn !== undefined ? props.rightbtn : "확인"}</Col> : null }
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </>
        )

    }else if( props.type === 'DELETE'){

        return (
            <>
                <Button variant="primary" onClick={handleShow}>
                    {props.title !== undefined ? props.title : "Default Title" }
                </Button>

                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Header closeButton>
                        {/* <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                        </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        {props.mdtitle !== undefined ? <p className="text-center">{props.mdtitle}</p> : null}
                        {props.mdtext !== undefined ? <p className="text-center">{props.mdtext}</p> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="text-center">
                            <Row>
                                { props.leftbtn !== undefined ? <Col onClick={handleClose}>{props.leftbtn !== undefined ? props.leftbtn : "확인"}</Col> : null }
                                { props.rightbtn !== undefined ? <Col onClick={handleClose}>{props.rightbtn !== undefined ? props.rightbtn : "확인"}</Col> : null }
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </>
        )

    }else if( props.type === 'ADMINCENTER'){
        return (
            <>
                <Button variant="primary" onClick={handleShow}>
                    {props.btntext !== undefined ? props.btntext : "Default Title" }
                </Button>

                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Header style={{textAlign: 'center'}} closeButton>{props.title}</Modal.Header>
                    <Modal.Body>
                        <Table responsive>
                            <tbody>
                                <tr>
                                    <td>사유기재</td>
                                    <td><textarea></textarea></td>
                                </tr>
                                <tr>
                                    <td>요 청 자</td>
                                    <td>이름</td>
                                </tr>
                                <tr>
                                    <td>요청일시</td>
                                    <td>YYYY-mm-dd 18:30</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="text-center">
                            <Row>
                                <Col onClick={handleClose}>요청전송</Col>
                                <Col onClick={handleClose}>취소</Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }else if( props.type === 'SM'){
        return (
            <>
                <Button onClick={handleShow} className="me-2">{props.title !== undefined ? props.title : "Default Title" }</Button>
                <Modal show={show} onHide={handleClose} size="sm" aria-labelledby="modal-sizes-title-sm" >
                    <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-sm">
                        Small Modal
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>...</Modal.Body>
                </Modal>
            </>
        )
    }else if( props.type === 'LM'){
        return (
            <>
                <Button onClick={handleShow} className="me-2">{props.title !== undefined ? props.title : "Default Title" }</Button>
                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="modal-sizes-title-lg" >
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-sizes-title-lg">
                            Large Modal
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>...</Modal.Body>
                </Modal>
            </>
        )
    }else if( props.type === 'XM'){
        return (
            <>
                <Button onClick={handleShow} className="me-2">{props.title !== undefined ? props.title : "Default Title" }</Button>
                <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="modal-sizes-title-xl" >
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-sizes-title-xl">
                            Extra Large Modal
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>...</Modal.Body>
                </Modal>
            </>
        )
    }else if( props.type === 'FULL'){

        return (
            <>
                <Button className="me-2 mb-2" onClick={handleShow}>
                    {props.title !== undefined ? props.title : "Default Title" }
                </Button>
                <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Modal body content</Modal.Body>
                </Modal>
            </>
        )

    }else{

        return (
            <>
                <Button variant="primary" onClick={handleShow}>
                    {props.title !== undefined ? props.title : "Default Title" }
                </Button>
    
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )

    }
    
}
