
import { Col, Image } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

export default function Media( props ){

    let tmpImg = props.img;
    if( tmpImg === undefined ){
        tmpImg = "https://placeimg.com/240x240";
    }

    return (
        <>
            <Row>
                <Col xs={5}>
                    <Image fluid src={tmpImg} style={{borderRadius: '50%'}} />
                </Col>
                <Col xs={7} className='d-flex align-items-center'>
                    <span>ceo@flex.day<br/>
                    2022. 05. 06.</span>
                </Col>
            </Row>
        </>
    )
}