import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faStar } from "@fortawesome/free-solid-svg-icons";
// import { defaults } from 'chart.js';
import { useRef } from "react";
import {
  AlertError,
  PrintConsole,
  getApiUrl,
  getImgUrl,
} from "../../../../function/common";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import Button from "../../../../components/ver1.5/atom/Button";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";
import {
  CameraIcon,
  DeleteIconSmall,
  StarIcon,
} from "../../../../components/ver1.5/assets/icons";
import OneBtnModal from "../../../../components/ver1.5/organism/OneBtnModal";
import LabelledTextField from "../../../../components/ver1.5/organism/LabelledTextField";
import { UserContext } from "../../../../contexts/UserContext";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 2.4rem 1.6rem 3.2rem;
`;

const StoreNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  justify-content: center;
`;

const Image = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  flex-shrink: 0;
  border-radius: 1.6rem;
  border: 1px solid ${color.gray.d100};
  object-fit: cover;
`;

const StarRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    flex-shrink: 0;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.6rem;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReviewImage = styled.img`
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 1.2rem;
  overflow: hidden;
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  background: ${color.primary.white};
  ${(p) => p.$styles};
`;

const UserReview = () => {
  const { accessToken } = useContext(UserContext);
  const location = useLocation();
  const isEdit = location.pathname.includes("edit");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const storeUrl = searchParams.get("storeurl");
  const reviewId = searchParams.get("id");
  const prevInfo = location.state?.reviewInfo;
  const [loading, setLoading] = useState(false);
  const [storeInfo, setStoreInfo] = useState({
    storeName: "",
    logoUrl: "",
  });
  const [content, setContent] = useState("");
  const [grade, setGrade] = useState(0);
  const imageLimit = 3;
  const [images, setImages] = useState([]);
  const [unableImageModal, setUnableImageModal] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, text: "" });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const fileUpload = useRef();

  const getStore = async () => {
    const res = await axios
      .get(getApiUrl(`stores/${storeUrl}`))
      .then((res) => setStoreInfo(res.data));
    if (res) return res;
  };

  const onClickUpload = () => {
    fileUpload.current.click();
  };

  const onClickDeleteImage = (index) => {
    setImages((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = () => {
      setImageUrl({ result: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const onChangeImg = ({ target: { files } }) => {
    const imagesLength = images.length;
    let limit = imageLimit;
    if (imagesLength !== 0) {
      limit = imageLimit - imagesLength;
    }
    if (files) {
      if (files.length > limit) {
        alert("이미지는 최대 3장까지 등록가능합니다.");
      }
      let loopLimit = files.length > limit ? limit : files.length;
      Promise.all(
        Object.values(files)
          .slice(0, loopLimit)
          .map(async (file) => {
            return setImageFromFile({
              file,
              setImageUrl: ({ result }) =>
                setImages((prev) => [
                  ...prev,
                  {
                    file,
                    url: result,
                  },
                ]),
            });
          })
      );
      fileUpload.current.value = "";
    }
  };

  const convertUrlToFile = async (url) => {
    const res = await fetch(url);
    const data = await res.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop();
    const metadata = { type: `image/${ext}` };
    return new File([data], filename, metadata);
  };

  const onEdit = async () => {
    setEditLoading(true);
    if (
      images.map((image) => ({ url: image.url })).length !==
      prevInfo.images.map((image) => ({ url: image.url })).length
    ) {
      const convertedImages = [];
      for (let image of images) {
        if (image.file) {
          convertedImages.push(image);
        } else {
          const file = await convertUrlToFile(image.url);
          convertedImages.push({ ...image, file });
        }
      }
      const imageBlob = convertedImages.map((item, index) =>
        item.id ? { id: item.id, priority: index + 1 } : { priority: index + 1 }
      );
      const requestBlob = new Blob([JSON.stringify(imageBlob)], {
        type: "application/json",
      });
      const imageFormData = new FormData();
      imageFormData.append("request", requestBlob);
      convertedImages.forEach((image) =>
        imageFormData.append("images", image.file)
      );
      await axios
        .put(
          getApiUrl(`stores/${storeUrl}/reviews/${reviewId}/images`),
          imageFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .catch((e) => console.log(e));
    }
    const data = {
      grade,
      contents: content,
    };
    await axios
      .post(getApiUrl(`stores/${storeUrl}/reviews/${reviewId}`), data)
      .then(() => {
        setEditLoading(false);
        navigate("/user/" + storeUrl, {
          state: { status: "reviewDone" },
          replace: true,
        });
      })
      .catch((e) => {
        if (e.response?.data?.httpStatus === "FORBIDDEN") {
          setErrorModal({ show: true, text: e.response.data.message });
        }
      });
  };

  const submit = () => {
    setSubmitLoading(true);
    let obj;
    let tmpData = {
      grade,
      contents: content,
      images: images.map((image) => image.file),
    };

    obj = new Blob([JSON.stringify(tmpData)], { type: "application/json" });

    const formData = new FormData();
    formData.append("params", obj);
    images.forEach((image) => {
      formData.append("images", image.file ? image.file : image.url);
    });

    axios
      .post(getApiUrl("stores/" + storeUrl + "/reviews"), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {
        setSubmitLoading(false);
        navigate("/user/" + storeUrl, {
          state: { status: "reviewDone" },
          replace: true,
        });
      })
      .catch((error) => {
        AlertError(error);
        setGrade(0);
        setContent("");
      });
  };

  useEffect(() => {
    //데이터 로딩
    setLoading(true);
    getStore();
    //데이터 로딩 완료
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (prevInfo) {
      setImages(prevInfo.images);
      setGrade(parseInt(prevInfo.grade));
      setContent(prevInfo.contents);
    }
  }, [prevInfo]);

  if (loading) return <></>;

  return (
    <Layout
      footerPadding={false}
      styles={css`
        padding-bottom: 12rem;
      `}
    >
      <ContentContainer>
        <StoreNameWrapper>
          <Image src={storeInfo.logoUrl} />
          <Text fontStyle={font.subtitle3} color={color.gray.d900}>
            {storeInfo.storeName}
          </Text>
        </StoreNameWrapper>
        <StarRatingWrapper>
          {Array.from({ length: 5 }, (_, i) => (
            <IconWrapper onClick={() => setGrade(i + 1)} key={i}>
              <StarIcon
                width={"4.4rem"}
                height={"4.4rem"}
                color={i + 1 > grade ? color.gray.d100 : "#FFDD2D"}
              />
            </IconWrapper>
          ))}
        </StarRatingWrapper>
        <Button
          variant="outline"
          text={`사진 첨부하기 (${images.length}/3)`}
          iconPosition="left"
          icon={
            <>
              <CameraIcon
                width={24}
                height={24}
                fillColor={color.primary.flexRed}
              />
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileUpload}
                onChange={onChangeImg}
                accept="image/*"
                multiple
              />
            </>
          }
          textBtnColor={color.primary.flexRed}
          customFontStyle={css`
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: center;
          `}
          onClick={() => {
            if (images.length >= imageLimit) {
              setUnableImageModal(true);
            } else {
              onClickUpload();
            }
          }}
          marginTop="0.8rem"
          marginBottom="0.8rem"
        />
        <ImageWrapper>
          {images.map((image, index) => (
            <ImageContainer
              key={index}
              onClick={() => onClickDeleteImage(index)}
            >
              <ReviewImage src={image?.url} />
              <div
                style={{ position: "absolute", top: "0.4rem", right: "0.4rem" }}
              >
                <DeleteIconSmall color={color.gray.d700} />
              </div>
            </ImageContainer>
          ))}
        </ImageWrapper>
        <LabelledTextField
          label={"리뷰 내용"}
          elementType={"textarea"}
          placeholder={"솔직한 리뷰를 남겨주세요."}
          rows={10}
          type="filled"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <Text fontStyle={font.caption3} color={color.gray.d400}>
          <li>
            허위 리뷰나 명예훼손, 비방목적의 글은 관련 법률에 따라 제재를 받을
            수 있습니다
          </li>
        </Text>
        <BtnWrapper>
          <Button
            text={isEdit ? "수정" : "완료"}
            disabled={!(grade && content)}
            loading={submitLoading || editLoading}
            onClick={isEdit ? onEdit : submit}
          />
        </BtnWrapper>
      </ContentContainer>
      {unableImageModal && (
        <OneBtnModal
          text={"더이상 사진을 첨부할 수 없습니다"}
          onAction={() => setUnableImageModal(false)}
        />
      )}
      {errorModal.show && (
        <OneBtnModal
          text={errorModal.text}
          onAction={() => setErrorModal({ show: false, text: "" })}
        />
      )}
    </Layout>
  );
};

export default UserReview;
