import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiUrl, PrintConsole } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import "./inje-mms.css";

import { Modal } from 'react-bootstrap';

export default function Injemms(props){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState('STEP1');

    const [tel, setTel] = useState();

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const { code } = useParams();
    PrintConsole(code);

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    function SubmitTel(){
        setTel(document.getElementById('Tel').value);
        setMode('STEP2');
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (loading) return <></>

    const str = props.couponNumber;
    const regex = /.{1,4}/g;
    const result = str.match(regex).join('-');

    return (
        <>
            <div class="MMS-wrap2">
                <div class="MMS-Contents">

                    <div class="MMS-Txtbox">
                        <p class="sub-txt1">
                            <span>상품명</span>
                        </p>
                        <div class="sub-txt2" id="menuName">
                            {props.productName}
                        </div>
                        <img class="prd-img" src={props.representImageUrl} />
                    </div>

                    <div class="MMS-Txtbox">
                        <p class="sub-txt1">
                            <span>사용처</span>
                        </p>
                        <div class="sub-txt2" id="storeName">
                            블루포트 인제대학교 도서관점
                        </div>
                        <div class="sub-txt3" id="address">
                            경남 김해시 인제로 197
                        </div>
                    </div>

                    <div class="MMS-Txtbox last">
                        <p class="sub-txt1">
                            <span>유효기간</span>
                        </p>
                        <div class="sub-txt2" id="expireDate">
                            {props.expireDate}
                        </div>
                    </div>

                    <div class="MMS-QR">
                        <img className="QR-Img" id="qrcodeUrl" data-size="300"
                                src={"https://dev-api.flexday.kr/common/qrcode?data="+(props.qrCode)+"&size=300"} />

                        <p class="QR-Number" id="couponNumber">
                            {result}
                        </p>
                    </div>

                    <img class="flex-logo" src="https://www.flexdaycdn.net/public/images/ticket/inje_flexday_logo.png" />
                </div>
            </div>
        </>
    )
}