import { QRCodeCanvas } from "qrcode.react";


export default function QrCode( props ){
    console.log(props.QRCodeUrl)

    return (
        <>
            <QRCodeCanvas value={process.env.REACT_APP_BASEURL + 'user/'+props.QRCodeUrl} id={props.QRCodeId} style={{width:'100%', height:'100%'}}/>
        </>
    )
}