import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import axios from 'axios';
import { AlertError, getApiUrl } from '../../function/common';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { getImgUrl } from './../../function/common';

export default function Fail(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }

    console.log(Parameters);

    function PgResult() {
        return new Promise( async function(resolve, reject) {
            let obj = { 
                "storeDomain"           : localStorage.getItem('storeDomain'),
                "products"              : localStorage.getItem('products'),
                "orderNumber"           : localStorage.getItem('orderId')
            }
            console.log(obj);
            await axios.post( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/products/'+localStorage.getItem('products')+'/orders/'+localStorage.getItem('orderId')+'/fail'),
                obj, 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json;"
            ).then( 
                function(response){
                    console.log(response.data);
                }
            ).catch(function(error){
                AlertError(error);
            })
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        PgResult();
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container className='p-3 mt-5'>
                <Row>
                    <Col className='text-center'>
                        <Image className='w-50' src={getImgUrl('images/icon/image_errerpage_payment-failed.png')} fluid/>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center mt-5' xs={12}>
                        <h1 className="display-1 fw-bold LabelTitle Label404">결제 실패</h1>
                        
                        <p className="form-check-label mt-5">
                            결제가 실패 되었습니다 <br/>
                            051-621-0203, support@flex.day 에서 <br/>
                            도움을 받으실 수 있습니다.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-grid mt-5'>
                        <div className="d-grid mt-1 gap-1">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ () => {navigate('/user/search')}}>
                                홈으로
                            </Button>
                        </div>
                    </Col>
                    
                </Row>
            </Container>
        </>
    )
}