import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState, useRef } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { AlertError, getApiUrl, PrintConsole } from '../../../function/common';
import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
import { getImgUrl } from './../../../function/common';
import Btn from './../../../components/common/Btn';

export default function StoreLogo(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    const fileUpload = useRef();
    const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

    const onClickUpload = () => {
        fileUpload.current.click();
    }

    const onChangeImg = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile(uploadFile);

            document.getElementById('PreView').style.display='block';
            document.getElementById('PreViewImg').src = URL.createObjectURL(e.target.files[0]);
        }
    }

    async function submit(){  

        document.getElementById('submitBtn').setAttribute("disabled","true");

        var formData = new FormData();
        formData.append('image', file);

        axios.put(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/logo'), 
            formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization' : 'Bearer ' + localStorage.getItem('accessToken')
                },
            }
        ).then(
            function(response){
                if(response.status === 200){
                    alert('수정이 완료하였습니다.');
                    navigate('/store/'+localStorage.getItem('storeDomain'));
                }
            }
        ).catch(function(error){
            AlertError(error);
            // navigate('/404');
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    async function getAuthority(){
        //권한
        axios.get(
            getApiUrl('users/me/stores/'+localStorage.getItem('storeDomain')), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    if(response.data.type['code'] !== 1){
                        //직원은 사용못함
                        alert('권한이 없습니다.');
                        navigate(-1);
                    }
                }
            }
        ).catch(function(error){
            AlertError(error);
            navigate(-1);
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        //권한
        getAuthority();
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="로고 수정"/>
                <Row className='p-1'>
                    <Col xs={{ span:5, offset:1 }} className='mt-5'>
                        <Form.Group className="xs-12" controlId="logo">
                            <Form.Label>로고 업로드</Form.Label>
                            <Col>
                                <input type="file" style={{display : 'none'}} ref={fileUpload} onChange={onChangeImg}  />
                                {/* <button onClick={onClickUpload}>IMG 변경</button> */}
                                <Image className='w-100 DefaultShadow' fluid src={getImgUrl('images/icon/button_upload_add.png')} onClick={onClickUpload}/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col xs={{span:5}} id='PreView' style={{display:'none'}}>
                        <Form.Group className="xs-12" controlId="logo">
                            <Form.Label>업로드 프리뷰</Form.Label>
                            <Col>
                                <Image className='w-100 DefaultShadow' id='PreViewImg' fluid src='' />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col xs={{ span:10, offset:1 }}>
                        <div className="d-grid gap-1 mt-2">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ submit }>
                                로고 이미지 변경
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}