import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { PrintConsole, getImgUrl, setCommas, ReturnYear, getApiUrl } from './../../function/common';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Image, Table, Tabs } from 'react-bootstrap';

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';

import { Tab } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { openQRRead, setAccessToken, setNav5 } from '../../api/ApiWebView';

import './common.css';
import './StoreCalculate.css';

export default function StoreCalculate(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [printCoupon, setPrintCoupon] = useState({});
    const onChangeData2 = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setPrintCoupon({
          ...printCoupon,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };
    
    // console.log('==================='+localStorage.getItem('storeDomain'))

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

        
    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    const tableCss = {verticalAlign: 'top'};

    const Sum = useRef(0);

    const [item, setItem] = useState();
    function getOrderItem() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                storeDomain : localStorage.getItem('storeDomain'),
                startDate: '22-11-01',
                endDate: '24-11-30'
            }
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupon-sales'),
                {params : obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setItem( response.data );
                        
                        
                    }
                    
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };
    
    useEffect( () => {
        //데이터 로딩
        setLoading(true);


        try{
            //webview에 토큰값, 도메인 전달
            setAccessToken();
        }catch(e){}

        getOrderItem();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    useEffect( () => {
        Sum.current = 0;
        item && item.data.forEach((row) => {
            // console.log(row);
            if(row.nowStatus['code'] !== 3){
                Sum.current = Sum.current + parseInt(row['price'])
            }
        })
        document.getElementById('SumPrice').innerHTML = setCommas(Sum.current);
    }, [item]);

    console.log(printCoupon);

    if (loading) return <></>

    return (
        <>
        <div className='demo'>

            <div className="TSettlementPage">
        <div className="container-fluid g-0">

            <nav className="sub-navbar">
                <p className="navbar-title">매출정산</p>
                <button type="button" className="btn-clear btn-back" onClick={() => {navigate(-1);}}>
                    <img className="img-fluid" alt="뒤로가기" src="https://www.flexdaycdn.net/public/images/ticket/back.png" />
                </button>
                <button className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4" type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <img className="img-fluid" alt="메뉴" src="https://www.flexdaycdn.net/public/images/ticket/menu.png" />
                </button>

                <div className="container-fluid">
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-btnbox pt-4 px-4 text-end">
                            <button type="button" style={{position:"relative"}} className="btn-close opacity-100 shadow-none"
                                data-bs-dismiss="offcanvas" aria-label="Close">
                                <img className="img-fluid close" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/close.png" />
                            </button>
                        </div>
                        <div className="offcanvas-header p-4">
                            <img className="img-fluid logo" alt=""
                                src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                            <div className="offcanvas-title" id="offcanvasNavbarLabel">
                                인제대학교 늘빛관(다인)
                            </div>
                        </div>
                        <div className="offcanvas-body position-relative py-4-5">
                            <div className="navbar-nav justify-content-end">
                                <div className="nav-item">
                                    <a className="nav-link  d-flex align-items-center pb-4" aria-current="page"
                                        href="javascript:void(0)" onClick={() => {navigate(-1);}}>
                                        <div className="imgbox position-relative">
                                            <img className="img-fluid be img1" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/home_02.png" />
                                            <img className="img-fluid af img1" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/home.png" />
                                        </div>
                                        <span>관리자 메인</span>
                                    </a>
                                </div>
                                <div className="nav-item">
                                    <a className="nav-link d-flex align-items-center pb-4" href="javascript:void(0)" onClick={()=>{openQRRead()}}>
                                        <div className="imgbox position-relative">
                                            <img className="img-fluid be img2" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/qr_pay.png" />
                                            <img className="img-fluid af img2" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/qr_pay_02.png" />
                                        </div>
                                        <span>QR 결제</span>
                                    </a>
                                </div>
                                <div className="nav-item">
                                    <a className="nav-link active d-flex align-items-center pb-4" href="/demo/storecalculate">
                                        <div className="imgbox position-relative">
                                            <img className="img-fluid be img3" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/wallet.png" />
                                            <img className="img-fluid af img3" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/wallet_02.png" />
                                        </div>
                                        <span>매출 / 정산</span>
                                    </a>
                                </div>
                            </div>
                            <button type="button" className="btn btn-default" onClick={ ()=> { localStorage.clear(); navigate("/demo/login") }}>로그아웃</button>
                        </div>
                    </div>
                </div>

            </nav>


            <div className="PayCateTab">
                <div className="nav nav-pills justify-content-around mb-4-5 pt-4-5 px-4-5" id="pills-tab" role="tablist">
                    <div className="nav-item" role="presentation">
                        <button className="nav-link active PayCateBtn" id="pills-cate-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-cate" type="button" role="tab" aria-controls="pills-cate"
                            aria-selected="true">
                            매출 내역
                        </button>
                    </div>
                    {/* <div className="nav-item" role="presentation" data-bs-toggle="modal" data-bs-target="#Modal-01"> */}
                    <div className="nav-item" role="presentation" data-bs-toggle="modal">
                        <button className="nav-link PayCateBtn" id="pills-gift-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-gift" type="button" role="tab" aria-controls="pills-gift"
                            aria-selected="false">
                            정산 내역
                        </button>
                    </div>
                </div>
                <div className="tab-content mb-4" id="pills-tabContent">
                    <div className="tab-pane show active" id="pills-cate" role="tabpanel" aria-labelledby="pills-cate-tab">
                        <div className="SettleList">
                            
                            <div className="SettleTop1 pb-4-5 px-4-5">
                                <p className="Settle-sub mb-0">
                                    이번주
                                </p>
                                <p className="Settle-date mb-0">
                                    2022. 12. 19 ~ 12. 25
                                </p>
                                <p className="Settle-price">
                                    <span id='SumPrice'>{Sum.current}</span>원 
                                </p>
                                <Link to="/demo/settlehistory" className="btn btn-default">지난 내역</Link>
                            </div>
                                
                            <div className="SettleBottom1">

                            {
                                item && item.data.map( row => (

                                    row.nowStatus['code'] === 3 ? 
                                    <div className="Listitem cancel px-4-5 py-4">
                                        <p className="text-end time">{row.createdAt}</p>
                                        <div className="align-items-center d-flex justify-content-between">
                                            <img className="img-fluid logo" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                            <div className="txtbox">
                                                <p className="mb-0 name">{row.productName}</p>
                                                <p className="t-num mb-0">
                                                    ({row.couponNumber})
                                                </p>
                                                <p className="price">
                                                    <span className="num">{ row.nowStatus['code'] === 3 ? "-"+setCommas(row.price) : setCommas(row.price)}</span> 원
                                                </p>
                                            </div>
                                        </div>
                                        
                                        <p className="text-end cancel-txt">취소된 내역</p>
                                    </div>
                                    :
                                    <div className="Listitem px-4-5 py-4">
                                        <p className="text-end time">{row.createdAt}</p>
                                        <div className="align-items-center d-flex justify-content-between">
                                            <img className="img-fluid logo" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                            <div className="txtbox">
                                                <p className="mb-0 name">{row.productName}</p>
                                                <p className="t-num mb-0">
                                                    ({row.couponNumber})
                                                </p>
                                                <p className="price">
                                                    <span className="num">{ row.nowStatus['code'] === 3 ? "-"+setCommas(row.price) : setCommas(row.price)}</span> 원
                                                </p>
                                            </div>
                                        </div>
                                        
                                        {/* <p className="text-end cancel-txt">취소된 내역</p> */}
                                    </div>

                                ))
                            }

                            </div>

                        </div>
                    </div>
                    <div className="tab-pane" id="pills-gift" role="tabpanel" aria-labelledby="pills-gift-tab">
                        <div className="SettleList">
                            <div className="SettleTop2 pb-4-5 px-4-5 text-center">
                                <img className="img-fluid smile mb-4"
                                    src="https://www.flexdaycdn.net/public/images/static/payment/smile_emoji.png"
                                    alt="" />
                                <p className="PageTit mb-2">
                                    이번 주(월-일요일)의 매출금액은 <br />
                                    <span className="color">다음주 금요일</span>에 지급됩니다.
                                </p>
                                <div className="accordion ver3">
                                    <div className="accordion-item border-0">
                                        <p className="accordion-headerd">
                                            <button className="accordion-button justify-content-center mt-4 px-2 py-0"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="false" aria-controls="collapseOne">
                                                정산일 한눈에 확인하기
                                            </button>
                                        </p>
                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body px-0">
                                                <div className="Calendar p-4 mt-4">
                                                    <div className="Week-Day row g-0 mb-4">
                                                        <div className="col">월</div>
                                                        <div className="col">화</div>
                                                        <div className="col">수</div>
                                                        <div className="col">목</div>
                                                        <div className="col">금</div>
                                                        <div className="col ColorF">토</div>
                                                        <div className="col ColorA">일</div>
                                                    </div>
                                                    <div className="Week-NowDate row g-0 mb-2 py-2">
                                                        <div className="col">19</div>
                                                        <div className="col">20</div>
                                                        <div className="col">21</div>
                                                        <div className="col">22</div>
                                                        <div className="col">23</div>
                                                        <div className="col ColorF">24</div>
                                                        <div className="col ColorA">25</div>
                                                    </div>
                                                    <div className="Week-Txt ColorA mb-4">
                                                        일주일 매출
                                                    </div>
                                                    <div className="Week-NextDate row g-0">
                                                        <div className="col">26</div>
                                                        <div className="col">27</div>
                                                        <div className="col">28</div>
                                                        <div className="col">29</div>
                                                        <div className="col ColorA active">
                                                            <span>30</span>
                                                            <p className="mb-0 mt-3 ColorA">정산일</p>
                                                        </div>
                                                        <div className="col ColorF">31</div>
                                                        <div className="col ColorA">1</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="SettleBottom2">
                                <div className="Listitem px-4-5 py-4">
                                    <Link to={"/demo/settledetail/1"}
                                        className="align-items-center d-flex justify-content-between">
                                        <div className="txtbox">
                                            <p className="date mb-0">
                                                12월12일 ~ 12월18일
                                            </p>
                                            <p className="price">
                                                <span className="num">
                                                    7,555,275
                                                </span> 원
                                            </p>
                                        </div>
                                        <div className="align-items-center btnbox d-flex">
                                            <span>정산 대기</span>
                                            <img className="img-fluid arrow"
                                                src="https://www.flexdaycdn.net/public/images/ticket/more_info.png"
                                                alt="" />
                                        </div>
                                    </Link>
                                </div>
                                <div className="Listitem hold px-4-5 py-4">
                                    <Link to={"/demo/settledetail/2"}
                                        className="align-items-center d-flex justify-content-between">
                                        <div className="txtbox">
                                            <p className="date mb-0">
                                                12월5일 ~ 12월11일
                                            </p>
                                            <p className="price">
                                                <span className="num">
                                                    5,599,255
                                                </span> 원
                                            </p>
                                        </div>
                                        <div className="align-items-center btnbox d-flex">
                                            <span>정산 지연</span>
                                            <img className="img-fluid arrow"
                                                src="https://www.flexdaycdn.net/public/images/ticket/more_info.png"
                                                alt="" />
                                        </div>
                                    </Link>
                                </div>
                                <div className="Listitem done px-4-5 py-4">
                                    <Link to={"/demo/settledetail/3"}
                                        className="align-items-center d-flex justify-content-between">
                                        <div className="txtbox">
                                            <p className="date mb-0">
                                                11월28일 ~ 12월4일
                                            </p>
                                            <p className="price">
                                                <span className="num">
                                                    5,396,900
                                                </span> 원
                                            </p>
                                        </div>
                                        <div className="align-items-center btnbox d-flex">
                                            <span>정산 완료</span>
                                            <img className="img-fluid arrow"
                                                src="https://www.flexdaycdn.net/public/images/ticket/more_info.png"
                                                alt="" />
                                        </div>
                                    </Link>
                                </div>
                                {/* <div className="Listitem done px-4-5 py-4">
                                <Link to={"/demo/settledetail/3"}
                                        className="align-items-center d-flex justify-content-between">
                                        <div className="txtbox">
                                            <p className="date mb-0">
                                                11월21일 ~ 11월27일
                                            </p>
                                            <p className="price">
                                                <span className="num">
                                                    9,228,350
                                                </span> 원
                                            </p>
                                        </div>
                                        <div className="align-items-center btnbox d-flex">
                                            <span>정산 완료</span>
                                            <img className="img-fluid arrow"
                                                src="https://www.flexdaycdn.net/public/images/ticket/more_info.png"
                                                alt="" />
                                        </div>
                                    </Link>
                                </div>
                                <div className="Listitem done px-4-5 py-4">
                                <Link to={"/demo/settledetail/3"}
                                        className="align-items-center d-flex justify-content-between">
                                        <div className="txtbox">
                                            <p className="date mb-0">
                                                11월14일 ~ 11월20일
                                            </p>
                                            <p className="price">
                                                <span className="num">
                                                    5,599,000
                                                </span> 원
                                            </p>
                                        </div>
                                        <div className="align-items-center btnbox d-flex">
                                            <span>정산 완료</span>
                                            <img className="img-fluid arrow"
                                                src="https://www.flexdaycdn.net/public/images/ticket/more_info.png"
                                                alt="" />
                                        </div>
                                    </Link>
                                </div> */}
                            </div>

                        </div>


                        <div className="modal fade modal-register text-center" data-bs-backdrop="static" id="Modal-01"
                            tabIndex="-1" aria-labelledby="Modal-01-Label" aria-hidden="true">
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <p className="modal-title">
                                            <img className="img-fluid icon" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/alert_icon.png" />
                                            계좌 등록 안내
                                        </p>
                                    </div>
                                    <div className="modal-body">
                                        정산 완료를 위해 <br />
                                        계좌 정보를 등록해주세요.
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-clear btn-modal-secondary"
                                            data-bs-dismiss="modal">다음에 하기</button>
                                        <button type="button" className="btn btn-clear btn-modal-primary"
                                            data-bs-dismiss="modal">계좌 등록하기</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>

    </div>
        </>
    )
}