import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { styled } from "styled-components";
import { color } from "../styles/theme";
import { headerFooterZIndex } from "../constants";

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(22, 30, 38, 0.7);
  z-index: ${headerFooterZIndex + 1};
`;

const ContentContainer = styled.div`
  width: calc(100% - 9.4rem);
  max-width: 50rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${color.primary.white};
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${headerFooterZIndex + 2};
  padding-top: 2.4rem;
`;

const RenderModal = ({ children, onClickOverlay }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    return () => (document.body.style.overflow = "");
  }, []);

  return (
    <>
      <Overlay onClick={() => (onClickOverlay ? onClickOverlay() : null)} />
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};

const ModalLayout = ({ children, onClickOverlay }) => {
  return ReactDOM.createPortal(
    <RenderModal onClickOverlay={onClickOverlay}>{children}</RenderModal>,
    document.body
  );
};

export default ModalLayout;
