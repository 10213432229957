import React, { useEffect, useState } from "react";
import Layout from "../../../components/ver1.5/layout/Layout";
import { styled } from "styled-components";
import { color } from "../../../components/ver1.5/styles/theme";
import faqList, { faqTypes } from "./components/faqList";
import Text from "../../../components/ver1.5/atom/Text";
import FaqListItem from "./components/organism/FaqListItem";
import axios from "axios";
import { getApiUrl } from "../../../function/common";

const TabWrapper = styled.div`
  overflow: hidden;
`;

const TabList = styled.div`
  padding: 0 1.6rem;
  gap: 0.6rem;
  display: flex;
  align-items: center;
  > * {
    flex-shrink: 0;
  }
  overflow-x: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.6rem;
  border-radius: 10rem;
  background-color: ${(p) =>
    p.$active ? color.gray.d900 : color.primary.white};
  color: ${(p) => (p.$active ? color.primary.white : color.gray.d600)};
  border: 1px solid ${(p) => (p.$active ? color.gray.d900 : color.gray.d100)};
  font-family: "Pretendard Medium", sans-serif;
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
`;

const ContentWrapper = styled.div`
  padding: 1.6rem;
`;

const Faq = () => {
  const [currentTab, setCurrentTab] = useState("all");
  const [faqs, setFaqs] = useState([]);
  const [selectedFaqs, setSelectedFaqs] = useState([]);

  const getFaqs = async () => {
    await axios
      .get(getApiUrl("faqs"), {
        params: {
          type: "USER",
        },
      })
      .then((res) => {
        setFaqs(res.data);
      })
      .catch((error) => console.error(error));
  };

  const onClickCurrent = (value) => {
    setCurrentTab(value);
  };

  let allList = [];
  for (let item of faqList) {
    for (let content of item.contents) {
      allList.push(content);
    }
  }

  useEffect(() => {
    getFaqs();
  }, []);

  useEffect(() => {
    if (faqs.length) {
      if (currentTab !== "all") {
        setSelectedFaqs(faqs.filter((faq) => faq.faqType === currentTab));
      }
    }
  }, [currentTab]);

  return (
    <Layout footerPadding={false}>
      <TabWrapper>
        <TabList>
          <Tab
            value={"all"}
            onClick={() => onClickCurrent("all")}
            $active={currentTab === "all"}
          >
            전체
          </Tab>
          {faqTypes.map((item, index) => (
            <Tab
              key={index}
              value={item.type}
              onClick={() => onClickCurrent(item.type)}
              $active={currentTab === item.type}
            >
              {item.name}
            </Tab>
          ))}
        </TabList>
      </TabWrapper>
      <ContentWrapper>
        {currentTab === "all"
          ? faqs.map(({ title, contents }, index) => (
              <FaqListItem
                key={index}
                isAccordion
                isLast={index === faqs.length - 1}
                dividerWidth={"0.1rem"}
                sectionTitle={title}
                content={contents}
              />
            ))
          : selectedFaqs.map(({ title, contents }, index) => (
              <FaqListItem
                key={index + title}
                isAccordion
                isLast={index === selectedFaqs.length - 1}
                dividerWidth={"0.1rem"}
                sectionTitle={title}
                content={contents}
              />
            ))}
      </ContentWrapper>
    </Layout>
  );
};

export default Faq;
