import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import axios from 'axios';
import { AlertError, getApiUrl, sleep } from '../../function/common';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { getImgUrl, PrintConsole, setCommas } from './../../function/common';

export default function Success(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);
    PrintConsole(localStorage.getItem('accessToken'))

    const [data, setData] = useState([]);

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    function PgResult() {
        return new Promise( async function(resolve, reject) {
            let obj = { 
                "paymentKey"            : Parameters[1].paymentKey,
                "amount"                : Parameters[2].amount
            }
            console.log(obj);
            await axios.post( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/products/'+localStorage.getItem('products')+'/orders/'+localStorage.getItem('orderId')+'/success'),
                obj, 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json;"
            ).then( 
                function(response){
                    if(process.env.NODE_ENV !== 'production'){
                        console.log(response.data);
                    }

                    if(response.status === 200){
                        setData(response.data);

                        localStorage.removeItem('storeDomain');
                        localStorage.removeItem('products');
                        localStorage.removeItem('orderId');
                    }else{
                        
                    }
                }
            ).catch(function(error){
                AlertError(error);
            })
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        PgResult();
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container className='p-3'>
                <Row>
                    
                    <Col className='text-center'>

                        <div className='d-flex flex-column mb-3 ColorArea'>
                            <div className='p-1'>
                                <Image className='w-25' src={getImgUrl('images/icon/image_payment_sucess.png')} fluid/>    
                            </div>
                            <div className='p-1'><h3 className='TextTitle'>고객님의 주문이</h3></div>
                            <div className='p-1'><h3 className='TextTitle'>정상적으로 완료 되었습니다.</h3></div>
                        </div>
                    </Col>
                    
                </Row>
                <Row>
                    <Col className='mt-3'>
                        <ul>
                        {/* {data.recipient && data.recipient.name} /  */}
                            <li>선물하신 상품은</li>
                            <li>{ data.recipient && data.recipient.phoneNumber} 님께 </li>
                            <li>메세지로 전달 하였습니다.</li>
                        </ul>
                        <ul className='mt-3'>
                            <li>주문내역은 선물내역에서 확인하실 수 있습니다.</li>
                            <li>주문상품 : {data && data.productName}</li>
                        </ul>
                        <ul className='mt-3'>
                            <li><small>주문일자 : {data && data.orderedAt}</small></li>
                            <li><small>주문번호 : {data && data.orderNumber}</small></li>
                        </ul>
                        <hr/>
                        <h4>결제정보</h4>
                        <table class='table table-borderless mt-2'>
                            <tr>
                                <td>총 상품금액</td>
                                <td className='PointText'>{setCommas(data && data.price) + ' 원'}</td>
                            </tr>
                            <tr>
                                <td>결제방법</td>
                                <td className='text-right' >{data.payType && data.payType.description}</td>
                            </tr>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-grid mt-5'>
                        {/* <Link to="/user/giftlist" className="btn btn-secondary"><Button variant="secondary" size='lg'>선물내역확인</Button></Link> */}
                        <div className="d-grid mt-1 gap-1">
                            <Button variant="secondary" size="lg" id='submitBtn' onClick={ () => {navigate('/user/giftlist')}}>
                                선물내역확인
                            </Button>
                        </div>
                    </Col>
                    <Col className='d-grid mt-5'>
                        {/* <Link to="/user/search" className="btn btn-primary"><Button size='lg'>홈으로</Button></Link> */}
                        <div className="d-grid mt-1 gap-1">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ () => {navigate('/user/search')}}>
                                홈으로
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}