import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { PrintConsole, getImgUrl } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { setAccessToken, setNav1 } from './../../api/ApiWebView';

import './common.css';
import './SettleDetail.css';
import { useParams } from 'react-router-dom';

export default function StoreHome2(){
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const { Mode } = useParams();

    console.log(Mode);

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출
        try{
            setAccessToken();
        }catch(e){}
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    if(Mode === "1"){
        return (
            <>
            <div className='demo'>

                <div className="TSettleDetailPage">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">정산 상세</p>
                            <button type="button" className="btn-clear btn-close" onClick={()=>{navigate(-1)}}>
                                <img className="img-fluid" alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/close2.png" />
                            </button>
                        </nav>

                        <div className="DetailBox pt-4-5 px-4-5">
                            <div className="Detail-1 pb-4 px-4">
                                <p className="week align-items-center d-flex mb-0">
                                    <img className="img-fluid calendar" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/calendar.png" />
                                    <span>이번주</span>
                                </p>
                                <p className="period mb-0">
                                    2022. 12. 12(월) ~ <br className="mob320" />
                                    2022. 12. 18(일)
                                </p>
                            </div>
                            <div className="Detail-2 py-4 px-4">
                                <p className="mb-0 state text-end">
                                    <span className="px-2 py-1">정산 대기</span>
                                </p>
                                <p className="txt mb-0">
                                    총 입금 금액
                                </p>
                                <p className="price mb-0">
                                    <span>7,555,275</span> 원
                                </p>
                            </div>
                            <div className="Detail-3 py-4-5 px-4">
                                <div className="align-items-center d-flex justify-content-between mb-2">
                                    <div className="align-items-center d-flex tit">
                                        <img className="img-fluid icon" alt=""
                                            src="https://www.flexdaycdn.net/public/images/ticket/text.png" />
                                        총 판매액
                                    </div>
                                    <div className="desc">
                                        <span>7,995,000</span> 원
                                    </div>
                                </div>
                                <div className="align-items-center d-flex justify-content-between">
                                    <div className="align-items-center d-flex tit">
                                        <img className="img-fluid icon" alt=""
                                            src="https://www.flexdaycdn.net/public/images/ticket/text.png" />
                                        수수료
                                    </div>
                                    <div className="desc">
                                        <span>-439,725</span> 원
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="DetailBox2 pt-4-5 px-4-5">
                            <div className="Detail-4">
                                <div className="align-items-center d-flex justify-content-between mb-2">
                                    <div className="tit">
                                        정산 예정일
                                    </div>
                                    <div className="desc date">
                                        2022년 12월 23일
                                    </div>
                                </div>
                                <div className="align-items-center d-flex justify-content-between">
                                    <div className="tit">
                                        정산 예정 계좌
                                    </div>
                                    <div className="desc text-end">
                                        국민은행 <br />
                                        000000-00-000000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="DetailInfotxt py-4-5 text-center">
                            정산 관련 문의는<br />
                            고객센터 (051-621-0203)를 이용해주세요.
                        </div>
                    </div>
                </div>

                </div>
            </>
        )
    }else if( Mode === "2"){
        return (
            <>
            <div className='demo'>

                <div className="TSettleDetailPage hold">
        <div className="container-fluid g-0">
            <nav className="sub-navbar">
                <p className="navbar-title">정산 상세</p>
                <button type="button" className="btn-clear btn-close" onClick={() => {navigate(-1)}}>
                    <img className="img-fluid" alt="뒤로가기"
                        src="https://www.flexdaycdn.net/public/images/ticket/close2.png" />
                </button>
            </nav>

            <div className="DetailBox pt-4-5 px-4-5">

                <div className="Detail-1 pb-4 px-4">
                    <p className="week align-items-center d-flex mb-0">
                        <img className="img-fluid calendar" alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/calendar.png" />
                        <span>이번주</span>
                    </p>
                    <p className="period mb-0">
                        2022. 12. 5(월) ~ <br className="mob320" />
                        2022. 12. 11(일)
                    </p>
                </div>
                <div className="Detail-2 py-4 px-4">
                    <p className="mb-0 state hold text-end">
                        <span className="px-2 py-1">정산 지연</span>
                    </p>
                    <p className="txt mb-0">
                        총 입금 금액
                    </p>
                    <p className="price mb-0">
                        <span>61,425</span> 원
                    </p>
                </div>
                <div className="Detail-3 py-4-5 px-4">
                    <div className="align-items-center d-flex justify-content-between mb-2">
                        <div className="align-items-center d-flex tit">
                            <img className="img-fluid icon" alt=""
                                src="https://www.flexdaycdn.net/public/images/ticket/text.png" />
                            총 판매액
                        </div>
                        <div className="desc">
                            <span>65,000</span> 원
                        </div>
                    </div>
                    <div className="align-items-center d-flex justify-content-between">
                        <div className="align-items-center d-flex tit">
                            <img className="img-fluid icon" alt=""
                                src="https://www.flexdaycdn.net/public/images/ticket/text.png" />
                            수수료
                        </div>
                        <div className="desc">
                            <span>-3,575</span> 원
                        </div>
                    </div>
                </div>
            </div>
            <div className="DetailHoldbox py-4-5 text-center">
                <p className="tit">
                    <img className="img-fluid icon" alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket/alert_icon2.png" />
                    정산 지연 사유 안내
                </p>
                <p className="desc mb-0">
                    죄송합니다. 토스 페이먼츠의 정산이 지연되어 <br />
                    지급이 미뤄지는점 양해 부탁드립니다.
                </p>
            </div>
            <div className="DetailBox2 pt-4-5 px-4-5">
                <div className="Detail-4">
                    <div className="align-items-center d-flex justify-content-between mb-2">
                        <div className="tit">
                            정산 예정일
                        </div>
                        <div className="desc date">
                            2022년 12월 16일
                        </div>
                    </div>
                    <div className="align-items-center d-flex justify-content-between">
                        <div className="tit">
                            정산 예정 계좌
                        </div>
                        <div className="desc text-end">
                            <button type="button" className="btn w-auto h-auto btn-default">
                                계좌 등록
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="DetailInfotxt py-4-5 text-center">
                정산 관련 문의는<br />
                고객센터 (051-621-0203)를 이용해주세요.
            </div>
        </div>
    </div>
    </div>
            </>
        )
    }else{
        return (
            <>
            <div className='demo'>

                <div className="TSettleDetailPage done">
            
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">정산 상세</p>
                            <button type="button" className="btn-clear btn-close" onClick={() => {navigate(-1)}}>
                                <img className="img-fluid" alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/close2.png" />
                            </button>
                        </nav>

                        <div className="DetailBox pt-4-5 px-4-5">
                            <div className="Detail-1 pb-4 px-4">
                                <p className="week align-items-center d-flex mb-0">
                                    <img className="img-fluid calendar" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/calendar.png" />
                                    <span>이번주</span>
                                </p>
                                <p className="period mb-0">
                                    2022. 11. 28(월) ~ <br className="mob320" />
                                    2022. 12. 4(일)
                                </p>
                            </div>
                            <div className="Detail-2 py-4 px-4">
                                <p className="mb-0 state text-end">
                                    <span className="px-2 py-1">정산 완료</span>
                                </p>
                                <p className="txt mb-0">
                                    총 입금 금액
                                </p>
                                <p className="price mb-0">
                                    <span>99,225</span> 원
                                </p>
                            </div>
                            <div className="Detail-3 py-4-5 px-4">
                                <div className="align-items-center d-flex justify-content-between mb-2">
                                    <div className="align-items-center d-flex tit">
                                        <img className="img-fluid icon" alt=""
                                            src="https://www.flexdaycdn.net/public/images/ticket/text.png" />
                                        총 판매액
                                    </div>
                                    <div className="desc">
                                        <span>105,000</span> 원
                                    </div>
                                </div>
                                <div className="align-items-center d-flex justify-content-between">
                                    <div className="align-items-center d-flex tit">
                                        <img className="img-fluid icon" alt=""
                                            src="https://www.flexdaycdn.net/public/images/ticket/text.png" />
                                        수수료
                                    </div>
                                    <div className="desc">
                                        <span>-5,775</span> 원
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="DetailBox2 pt-4-5 px-4-5">
                            <div className="Detail-4">
                                <div className="align-items-center d-flex justify-content-between mb-2">
                                    <div className="tit">
                                        정산 예정일
                                    </div>
                                    <div className="desc">
                                        2022년 12월 9일
                                    </div>
                                </div>
                                <div className="align-items-center d-flex justify-content-between">
                                    <div className="tit">
                                        정산 예정 계좌
                                    </div>
                                    <div className="desc text-end">
                                        국민은행 <br />
                                        000000-00-000000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="DetailInfotxt py-4-5 text-center">
                            정산 관련 문의는<br />
                            고객센터 (051-621-0203)를 이용해주세요.
                        </div>
                    </div>

                </div>
            </div>

    
            </>
        )
    }
}