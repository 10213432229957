import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { styled } from "styled-components";
import { color, font } from "../styles/theme";
import Text from "../atom/Text";
import { headerFooterZIndex } from "../constants";
import Divider from "../atom/Divider";

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(22, 30, 38, 0.7);
  z-index: ${headerFooterZIndex + 1};
`;

const ContentContainer = styled.div`
  width: calc(100% - 9.4rem);
  max-width: 50rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${color.primary.white};
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${headerFooterZIndex + 1};
  padding-top: 5.6rem;
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  height: 5.6rem;
  border-top: 1px solid ${color.gray.d100};
`;

const Btn = styled.button`
  width: calc(50% - 0.05rem);
  flex-shrink: 0;
  padding: 1.6rem 0;
`;

const RenderModal = ({
  title,
  description,
  cancelText,
  actionText,
  onCancel: onCancelProp,
  onAction: onActionProp,
  descriptionStyles,
}) => {
  const onCancel = () => {
    document.body.style.overflow = "";
    if (onCancelProp) {
      onCancelProp();
    }
  };

  const onAction = () => {
    document.body.style.overflow = "";
    if (onActionProp) {
      onActionProp();
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <>
      <Overlay onClick={() => onCancel()} />
      <ContentContainer>
        {title && (
          <Text fontStyle={font.subtitle2} color={color.gray.d900}>
            {title}
          </Text>
        )}
        <Text
          fontStyle={font.caption1}
          color={color.gray.d900}
          marginBottom="4rem"
          styles={descriptionStyles}
        >
          {description}
        </Text>
        <BtnContainer>
          <Btn onClick={onCancel}>
            <Text fontStyle={font.body1} color={color.gray.d400}>
              {cancelText ?? "cancel"}
            </Text>
          </Btn>
          <Divider
            direction="vertical"
            width={"0.1rem"}
            color={color.gray.d100}
          />
          <Btn onClick={onAction}>
            <Text fontStyle={font.body1} color={color.functional.link.d600}>
              {actionText ?? "confirm"}
            </Text>
          </Btn>
        </BtnContainer>
      </ContentContainer>
    </>
  );
};

const DefaultModal = ({
  title,
  description,
  cancelText,
  actionText,
  onCancel,
  onAction,
  descriptionStyles,
}) => {
  return ReactDOM.createPortal(
    <RenderModal
      title={title}
      description={description}
      cancelText={cancelText}
      actionText={actionText}
      onCancel={onCancel}
      onAction={onAction}
      descriptionStyles={descriptionStyles}
    />,
    document.body
  );
};

export default DefaultModal;
