
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { DaumPostcodeEmbed } from 'react-daum-postcode';
import { getImgUrl } from './../../../function/common';
export default function WorkdPlace(){

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
       
        zipCode             : "",
        address             : "",
        addressDetail       : ""
    });

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        // console.log(data);
    };

    const fileUpload = useRef();
    const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

    const onClickUpload = () => {
        fileUpload.current.click();
    }

    const onChangeImg = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile(uploadFile);

            document.getElementById('PreView').style.display='block';
            document.getElementById('PreViewImg').src = URL.createObjectURL(e.target.files[0]);
        }
    }

    //주소 관련
    const [isOpenPost, setIsOpenPost] = useState(false);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
      };
    
    const onCompletePost = (mapData) => {
        let fullAddress = mapData.address;
        let extraAddr = '';
    
        if (mapData.addressType === 'R') {
            if (mapData.bname !== '') {
                extraAddr += mapData.bname;
            }
            if (mapData.buildingName !== '') {
                extraAddr += extraAddr !== '' ? `, ${mapData.buildingName}` : mapData.buildingName;
            }
            fullAddress += extraAddr !== '' ? ` (${extraAddr})` : '';
        }
        setIsOpenPost(false);

        setData({
            ...data,
            zipCode: mapData.zonecode,
            address: fullAddress
        });
    };

    let obj;

    async function submit(){  
        var formData = new FormData();
        formData.append('signupRequest', obj);
        formData.append('logo', file);
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        // getData();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container className='mb-5'>
                <Btn type="NAVBACK" title="사업장 정보" />
                <Row>
                    <Col>
                        <h4>사업장 등록 정보</h4>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="StoreName">
                            <Form.Label>상호 </Form.Label>
                            <Form.Control type="text" placeholder="상호명(상호점)" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="StoreName">
                            <Form.Label>사업자등록번호</Form.Label>
                            <Form.Control type="text" placeholder="777-77-77777" />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {isOpenPost  ? (
                            <div>
                                <DaumPostcodeEmbed className='postCodeStyle' autoClose onComplete={onCompletePost } />
                            </div>
                        ) : null}
                        <Form.Group className="xs-12" controlId="zipCode">
                            <Form.Label>우편번호 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" value={data['zipCode']} placeholder="지번, 도로명, 건물명으로 검색" onChange={onChangeData} onClick={onChangeOpenPost}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="address">
                            <Form.Label>기본주소 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" value={data['address']} placeholder="지번, 도로명, 건물명으로 검색" onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="addressDetail">
                            <Form.Label>상세주소</Form.Label>
                            <Form.Control type="text" value={data['addressDetail']} placeholder="예)플렉스상가 101동 101호 플레스카페" onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="StoreName">
                            <Form.Label>운영시간 </Form.Label>
                            <Form.Control type="text" placeholder="주중~ 주말~"/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="StoreName">
                            <Form.Label>휴무일 </Form.Label>
                            <Form.Control type="text" placeholder="매월 16일"/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="logo">
                            <Form.Label>로고 업로드</Form.Label>
                            {/* <FileInput id='upLoadFile' onChange={this.handleChange}/> */}
                            <Col xs={3}>
                                <input type="file" style={{display : 'none'}} ref={fileUpload} onChange={onChangeImg}  />
                                <Image className='w-100 DefaultShadow' fluid src={getImgUrl('images/icon/button_upload_add.png')} onClick={onClickUpload}/>
                                {/* <button >IMG 변경</button> */}
                            </Col>
                            <Col xs={{span:3}} id='PreView' style={{display:'none'}}>
                                <Form.Group className="xs-12" controlId="logo">
                                    <Form.Label>업로드 프리뷰</Form.Label>
                                    <Col>
                                        <Image className='w-100 DefaultShadow' id='PreViewImg' fluid src='' />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <div className="d-grid gap-1">
                        <Button variant="primary" size="lg" onClick={submit}>
                            완료
                        </Button>
                    </div>
                </Row>
            </Container>
        </>
    )
}