import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { AlertError, getApiUrl, PrintConsole } from '../../../function/common';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
import { setCommas } from './../../../function/common';
import { setAccessToken } from './../../../api/ApiWebView';

export default function UseGift(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    // if( Parameters.length === 0 ){
    //     navigate('/404');
    // }


    const [item, setItem] = useState();
    function getOrderItem() {
        // alert(localStorage.getItem('accessToken'));
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('stores/'+Parameters[0].storedomain+'/coupons/'+Parameters[1].couponnumber),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                    }
                }
            ).then(
                function(response){
                    setItem(response.data);
                }
            ).catch(function(error){
                console.log(error);
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    const useGift = async (e) => {
        let obj = { 
            'storeDomain' : Parameters[1].storedomain,
            'couponnumber' : Parameters[0].couponnumber
        }
        await axios.post(
            getApiUrl('stores/'+ Parameters[0].storedomain + '/coupons/' + Parameters[1].couponnumber),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;",
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                PrintConsole(response.data);
                if(response.status === 200){
                    alert('쿠폰이 사용 처리 되었습니다.');
                    navigate('/store/' + localStorage.getItem('storeDomain'));
                }else{
                    alert('쿠폰이 사용 처리에 실패하였습니다.');
                }
            }
        ).catch(function(error){
            PrintConsole(error);
            AlertError(error);
        })
    }

    const cancelGift = async (e) => {
        let obj = { 
            'storeDomain' : Parameters[1].storedomain,
            'couponnumber' : Parameters[0].couponnumber
        }
        await axios.delete(
            getApiUrl('stores/'+ Parameters[0].storedomain + '/coupons/' + Parameters[1].couponnumber),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;",
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                PrintConsole(response.data);
                if(response.status === 200){
                    alert('쿠폰이 사용이 취소 되었습니다.');
                    navigate('/store/' + localStorage.getItem('storeDomain'));
                }else{
                    alert('쿠폰이 사용 처리에 실패하였습니다.');
                }
            }
        ).catch(function(error){
            PrintConsole(error);
            AlertError(error);
        })
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        getOrderItem();

        try{
            if (localStorage.getItem('domainType') === 'STORE'){
                //webview에 토큰값, 도메인 전달
                setAccessToken(localStorage.getItem('accessToken'));
            }
        }catch(e){
            console.log(e);
        }
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Row>
                    <Btn type="USERNAVBACK" title="교환권 확인"/>
                </Row>
                <Row>
                    <Col className='text-center mt-3'>
                        <Image className='w-50' src={item && item.representImageUrl === null ? 'http://placeimg.com/80/80' : item && item.representImageUrl} fluid rounded />
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center mt-3' xs={{span:10, offset:1}}>
                        <h1 className="display-1 fw-bold LabelTitle Label404">{item && item.status.code === 1 ? '결제 가능' : '사용 불가'}</h1>
                        <p className="fs-3 mt-3 PointText LabelTitle Label404">{item && item.status.code === 1 ? '사용 가능한 교환권 입니다.' : '사용 불가능한 교환권 입니다.'}</p>
                    </Col>
                    <Col className='mt-3' xs={{span : 8, offset : 2}}>
                        <ul className='text-center UnderBarUl'>
                            <li className='pt-2'><p>{item && item.storeName}</p></li>
                            <li className='pt-2'>메뉴이름 : {item && item.productName}</li>
                            <li className='pt-2'>메뉴가격 : {item && setCommas(item.priceInfo.storeSalesPrice) + ' 원'}</li>
                            <li className='pt-2'>유효기간 : {item && item.expireDate}</li>
                            {/* <li>주문번호 : {item && item.couponNumber}</li> */}
                        </ul>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={{span:10, offset:1}}>
                        <div className="d-grid gap-1">
                            <Button variant="primary" size="lg" onClick={ item && item.status.code === 1 ? useGift : cancelGift } >
                                {item && item.status.code === 1 ? '결제(사용) 사용처리' : '사용 취소'}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}