import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import CheckBox from "../../../components/common/CheckBox";

import ListGroupH from "../../../components/ListGroupH";
import { GetAuthorize, GetUserMe } from "./../../../api/ApiGetUser";
import { useState } from "react";
import { getImgUrl, LocationUrl } from "../../../function/common";
import {
  getVersion,
  setAccessToken,
  deviceType,
  setNav3,
} from "./../../../api/ApiWebView";
import {
  sleep,
  includeStr,
  getSubDomain,
  PrintConsole,
} from "./../../../function/common";
import { useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import SideNav from "./../../../components/common/SideNav";

import "./Login.css";

export default function Login() {
  const navigate = useNavigate();

  const [account, setAccount] = useState({
    Id: "",
    Password: "",
    clientId: "10001",
  });

  const onChangeAccount = (e) => {
    //console.log(e.target.value);
    setAccount({
      ...account,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    // localStorage.removeItem('accessToken');

    if (
      localStorage.getItem("accessToken") !== undefined &&
      localStorage.getItem("accessToken") !== null
    ) {
      //토큰 있을때 추가 작업할 일 있을 시 사용
      navigate("/store/" + localStorage.getItem("storeDomain"));
    } else {
      // navigate('/user/search');
    }

    try {
      setNav3();
    } catch (e) {}
  });

  const loginBtn = async () => {
    try {
      document.getElementById("submitBtn").setAttribute("disabled", "true");

      await GetAuthorize(account);

      sleep(500);
      PrintConsole("localStorage : " + localStorage.getItem("accessToken"));
      PrintConsole("localStorage : " + localStorage.getItem("refreshToken"));

      let resUserMe = await GetUserMe(account);

      sleep(500);
      try {
        localStorage.setItem("domainType", resUserMe.data.domainType);
        localStorage.setItem("name", resUserMe.data.name);
        localStorage.setItem(
          "myStores",
          JSON.stringify(resUserMe.data.myStores[0])
        );
        localStorage.setItem(
          "storeDomain",
          resUserMe.data.myStores[0].storeDomain
        );
      } catch (e) {
        console.log("mystore 없음 스텝 or 가입안함");
        localStorage.setItem("storeDomain", null);
      }

      // if(localStorage.getItem('domainType') === 'STORE'){
      // console.log(localStorage.getItem('myStores'));
      // var myStores = JSON.parse(localStorage.getItem('myStores'));
      // console.log(myStores);
      // }

      console.log("//////////////////////////////////");
      console.log(resUserMe);

      if (resUserMe.data.isCEO === true) {
        if (resUserMe.data.shouldAskWhetherCEO === true) {
          navigate("/workplace/join");
        } else {
          try {
            setAccessToken();
          } catch (e) {}
          navigate("/store/" + localStorage.getItem("storeDomain"));
        }
      } else if (resUserMe.data.isCEO === false) {
        console.log("sm");
        try {
          setAccessToken();
        } catch (e) {
          console.log(e);
        }
        navigate("/staff/staffmain");
      } else {
        console.log("sq");
        navigate("/store/storequestions");
      }

      // if(resUserMe.data.shouldAskWhetherCEO === true){
      //     if( resUserMe.data.myStores.length === 0 ){
      //         navigate('/workplace/join');
      //     }
      // }else{
      //     if( resUserMe.data.isCEO === 'CEO' ){
      //         navigate('/store'+localStorage.getItem('storeDomain')+'/storemain?storeurl='+ localStorage.getItem('storeDomain'));
      //     }else if( resUserMe.data.isCEO === false ){
      //         navigate('/staff/main');
      //     }else{
      //         navigate('/store/storequestions');
      //     }
      // }

      //     if(resUserMe.data.myStores[0].storeDomain !== undefined){

      //         localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
      //         try{
      //             console.log(deviceType())
      //             setAccessToken(localStorage.getItem('accessToken'));
      //         }catch(e){
      //             console.log(e);
      //         }

      //         navigate('/store/'+ resUserMe.data.myStores[0].storeDomain );

      //     }else{
      //         navigate('/workplace/join');
      //     }

      //     if(resUserMe.data.domainType === 'STORE'){
      //         if( resUserMe.data.isCEO === 'CEO' ){

      //                 // window.location.replace('/store/'+ resUserMe.data.myStores[0].storeDomain);
      //                 // navigate('/store'+localStorage.getItem('storeDomain')+'/storemain?storeurl='+ localStorage.getItem('storeDomain'));
      //             }
      //         }else if( resUserMe.data.isCEO === false ){
      //             navigate('/staff/main');
      //         }else{
      //             // navigate('/404');
      //         }
      //     }
      // }
      document.getElementById("submitBtn").removeAttribute("disabled");
    } catch (e) {
      console.log(e);
      document.getElementById("submitBtn").removeAttribute("disabled");
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      loginBtn();
    }
  };
  //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
  // getVersion();
  // setAccessToken();

  return (
    <div className="LoginPage">
      <div className="container PosionCenter d-flex align-items-center mw-100">
        <div class="Inner m-auto p-2">
          <div class="LogoImg text-center">
            {window.location.pathname.includes("/store/login") ? (
              <>
                <img
                  src="https://www.flexdaycdn.net/public/images/static/login/new-logo.png"
                  alt="FLEX"
                  style={{
                    display: "block",
                    margin: "0 auto",
                    marginBottom: "1rem",
                  }}
                />
                <label>
                  사장님 전용 서비스입니다.
                  <br />
                  신규가입은 고객센터에 문의해주세요.
                </label>
              </>
            ) : (
              <img
                src="https://www.flexdaycdn.net/public/images/static/login/new-logo.png"
                alt="FLEX"
              />
            )}
          </div>
          <div class="InfoSearch d-flex justify-content-end">
            <Link to="/findid/store">아이디 찾기</Link>
            <Link to="/findpw/store">비밀번호 찾기</Link>
          </div>
          <div class="LoginBox">
            <div class="row">
              <input
                type="text"
                id="Id"
                class="form-control InputType"
                placeholder="아이디를 입력해주세요."
                onChange={onChangeAccount}
                onKeyPress={onKeyPress}
              />
            </div>
            <div class="row">
              <input
                type="password"
                id="Password"
                class="form-control InputType"
                placeholder="비밀번호를 입력해주세요."
                onChange={onChangeAccount}
                onKeyPress={onKeyPress}
              />
            </div>
            <button
              type="button"
              id="submitBtn"
              class="btn btn-primary"
              onClick={(e) => {
                loginBtn();
              }}
            >
              로그인
            </button>
            <div class="form-check d-flex align-items-center p-0 mt-2">
              <input
                class="CheckInput2 small form-check-input"
                name="Check"
                id="Check-01"
                type="checkbox"
                checked
              />
              <label class="form-check-label CheckLabel" for="Check-01">
                자동 로그인
              </label>
            </div>
          </div>
          {/* <div class="JoinBox mt-4 mob-mt-5">
                        <p class="InfoTxt mb-3"><span>아직 회원이 아니신가요?</span></p>
                        <Link to="/store/join" class="btn btn-default">회원가입</Link>
                    </div> */}
          {/* <div class="AlertBox active mt-3">
                        확인 : 아이디 또는 비밀번호 불일치 / class "active" 추가
                        아이디 또는 비밀번호가 틀렸습니다.
                    </div> */}
        </div>
      </div>
      {/* <Container>
                <Row>
                    <Col sm={{ span:4, offset:4 }} className='text-center mt-3'>
                        <Image className='LogoImg w-40' src={getImgUrl('images/icon/img_login.png')}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span:10, offset:1 }} className='mt-3 mb-3'>
                        <Form.Group controlId='Id'>
                            <Form.Label>아이디</Form.Label>
                            <Form.Control type="text" controlid='Id' name='Id' placeholder="아이디를 입력해 주세요." onChange={onChangeAccount} onKeyPress={onKeyPress} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span:10, offset:1 }}>
                        <Form.Group controlId='Password' className='mt-3'>
                            <Form.Label>비밀번호</Form.Label>
                            <Form.Control type="password" autoComplete="off" controlid='Password' name='Password' onChange={onChangeAccount} onKeyPress={onKeyPress} />
                        </Form.Group>
                    </Col>
                </Row>
                
                <Row>
                    <Col xs={{ span:10, offset:1 }}>
                        <div className="d-grid gap-1 mt-5">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ (e) => { loginBtn(); } } >
                                로그인
                            </Button>
                        </div>
                    </Col>
                </Row>

                

                <div class="form-check d-flex align-items-center p-0 mt-2">
                    <input class="CheckInput2 small form-check-input" name="Check" id="Check-01" type="checkbox" value="" required=""/>
                    <label class="form-check-label CheckLabel" for="Check-01">자동 로그인</label>
                </div>

                <Row className="center-block">
                    <Col className='mt-3'>
                        <ListGroupH listdata={[{'key':1,'title':'아이디 찾기', "url":"/findid/store"},{'key':2,'title':'비밀번호 찾기', "url":"/findpw/store"},{'key':3,'title':'회원가입', "url":"/store/join"}]}/>
                    </Col>
                </Row>
            </Container> */}
    </div>
  );
}
