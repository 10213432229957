import Information from "../pages/franchisee/workplace/Information";
import WorkdPlace from "../pages/franchisee/workplace/WorkPlace";
import Calculate from "../pages/franchisee/workplace/Calculate";
import StoreUrl from "../pages/franchisee/workplace/StoreUrl";
import Sectors from "../pages/franchisee/workplace/Sectors";
import ChangeStore from "../pages/franchisee/workplace/ChangeStore";
import WpJoin from "../pages/franchisee/workplace/WpJoin";

const WorkplaceRoutes = [
  { path: "join", element: <WpJoin /> },
  { path: "information", element: <Information /> },
  { path: "workplace", element: <WorkdPlace /> },
  { path: "calculate", element: <Calculate /> },
  { path: "storeurl", element: <StoreUrl /> },
  { path: "sectors", element: <Sectors /> },
  { path: "changestore", element: <ChangeStore /> },
];

export default WorkplaceRoutes;
