import {
  Row,
  Col,
  Container,
  Form,
  Table,
  Image,
  ProgressBar,
  Button,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import CustomAlert from "./../../../components/common/CustomAlert";
import StoreItem from "./../../../components/common/StoreItem";
import CustomModal from "./../../../components/common/CustomModal";
import {
  AlertError,
  getApiUrl,
  getRedirctUrl,
  includeStr,
  setCommas,
} from "../../../function/common";
import QRFooter from "../../user/QRFooter";
import LineChart from "../../../components/chart/LineChart";
import QrCode from "./../../../components/common/QrCode";
import { getImgUrl, PrintConsole } from "./../../../function/common";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./../../../components/common/Footer";
import Gradient from "./../../../components/common/Gradient";
import StoreInfo from "./../../../components/common/StoreInfo";
import { useParams } from "react-router-dom";
import { GetUserMe } from "../../../api/ApiGetUser";
import { deviceType, setAccessToken, setNav1 } from "../../../api/ApiWebView";
import { getStoreAuth } from "./../../../api/ApiGetStoreUser";
import Footer_V2 from "../../../components/common/Footer_V2";
import "./StoreMain.css";
import useInfiniteScroll from "../../../components/hooks/useInfiniteScroll";

export default function StoreMain() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const starImgUrl = (type) => {
    const baseUrl =
      "https://www.flexdaycdn.net/public/images/static/store-main/store_star";
    switch (type) {
      case "zero":
        return baseUrl + ".png";
      case "half":
        return baseUrl + "_color_half.png";
      case "default":
        return baseUrl + "_color.png";
      default:
        return baseUrl + "_color.png";
    }
  };

  const { storeDomain } = useParams();
  // console.log(storeDomain);
  // if(storeDomain === 'null' || storeDomain === undefined){
  //     console.log(storeDomain);
  //     navigate('/404');
  // }

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }

  // console.log(localStorage.getItem("domainType"));
  // console.log(localStorage.getItem("storeDomain"));

  const [store, setStore] = useState();

  function getStore() {
    if (localStorage.getItem("domainType") === "STORE") {
      return new Promise(async function (resolve, reject) {
        const response = await axios
          .get(
            getApiUrl("stores/" + storeDomain),
            (axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("accessToken")),
            (axios.defaults.headers.common["ContentType"] =
              "application/json; charset=utf-8")
          )
          .then(function (response) {
            console.log("----------------------------------");
            console.log(response);
            if (response.status === 200) {
              setStore(response.data);
            }
          })
          .catch(function (error) {
            navigate("/404");
            console.log("error");
          });
        // if (response) {
        //     console.log(response);
        //     return resolve(response);
        // }
      });
    } else {
      return new Promise(async function (resolve, reject) {
        const response = await axios
          .get(getApiUrl("stores/" + storeDomain))
          .then(function (response) {
            // console.log("----------------------------------");
            // console.log(response);
            if (response.status === 200) {
              setStore(response.data);
            }
          })
          .catch(function (error) {
            navigate("/404");
            console.log("error");
          });
        // if (response) {
        //     console.log(response);
        //     return resolve(response);
        // }
      });
    }
  }

  const [storeAuth, setStoreAuth] = useState();

  const [saleItem, setSaleItem] = useState();

  function getSeleItem() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(getApiUrl("stores/" + storeDomain + "/products?status=SALE"))
        .then(function (response) {
          setSaleItem(response.data);
        })
        .catch(function (e) {
          console.log(e);
        });
      if (response) {
        return resolve(response);
      }
    });
  }
  const [stopItem, setStopItem] = useState();
  function getStopItem() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("stores/" + storeDomain + "/products?status=SALE_STOPPED")
        )
        .then(function (response) {
          setStopItem(response.data);
        })
        .catch(function (e) {
          console.log(e);
        });
      if (response) {
        return resolve(response);
      }
    });
  }

  const [storeAdmin, setStoreAdmin] = useState();
  function getStoreAdmin(sort) {
    return new Promise(async function (resolve, reject) {
      const response = await (getApiUrl("stores/" + storeDomain + "/products"),
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }).then(function (response) {
        // console.log('*********************************');
        // console.log(response.data);
        setStoreAdmin(response.data);
      });
      if (response) {
        return resolve(response);
      }
    });
  }

  const [reviewLoading, setReviewLoading] = useState(false);
  const [reviewSize, setReviewSize] = useState(10);
  const [reviewPage, setReviewPage] = useState(0);
  const [reviewIsLast, setReviewIsLast] = useState(false);
  const [reviewSort, setReviewSort] = useState("NEW");
  const [storeReview, setStoreReview] = useState([]);

  const clearReview = () => {
    setReviewLoading(false);
    setReviewSize(10);
    setReviewPage(0);
    setReviewIsLast(false);
    setStoreReview([]);
  };
  const getStoreReview = async ({ size, page }) => {
    if (reviewLoading) return;
    setReviewLoading(true);
    await axios
      .get(getApiUrl("stores/" + storeDomain + "/reviews"), {
        params: {
          reviewOrder: reviewSort,
          size,
          page,
        },
      })
      .then((res) => {
        if (res.data) {
          if (res.data.last) {
            setReviewIsLast(true);
          } else {
            setReviewPage((prev) => prev + 1);
          }
          setStoreReview((prev) => [...prev, ...res.data.content]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setReviewLoading(false);
  };

  const titleFileUpload = useRef();
  const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

  const onClickUpload = () => {
    titleFileUpload.current.click();
  };

  const onChangeImg = (e) => {
    if (e.target.files) {
      const uploadFile = e.target.files[0];
      setFile(uploadFile);

      let tmpData = [{ priority: 1 }];

      let obj = new Blob([JSON.stringify(tmpData)], {
        type: "application/json",
      });

      var formData = new FormData();
      formData.append("request", obj);
      formData.append("images", uploadFile);

      axios
        .put(
          getApiUrl("stores/" + storeDomain + "/images"),
          formData,
          (axios.defaults.headers.common["ContentType"] =
            "multipart/form-data"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            window.location.reload();
          } else {
            alert("매장 사진이 변경되었습니다.");
          }
        })
        .catch(function (error) {
          AlertError(error);
        });
    }
  };

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //상점 정보
    getStore();
    //아이템 정보
    getSeleItem();
    getStopItem();
    //리뷰
    // getStoreReview({ page: reviewPage, size: reviewSize });

    try {
      setAccessToken();
    } catch (e) {}

    try {
      setNav1();
    } catch (e) {}

    setStoreAuth(getStoreAuth());

    // console.log('+++++++'+localStorage.getItem('domainType'));
    //데이터 로딩 완료
    setLoading(false);
  }, []);

  useEffect(() => {
    clearReview();
    getStoreReview({ page: 0, size: reviewSize });
  }, [reviewSort]);

  const { Target } = useInfiniteScroll(async (entry, observer) => {
    const isShow = document.getElementById("fill-tab-tabpane-review");
    if (!isShow?.getAttribute("class")?.includes("active show")) return;
    if (reviewLoading) return;
    if (reviewIsLast) return;
    observer.unobserve(entry.target);
    await getStoreReview({ page: reviewPage, size: reviewSize });
    observer.observe(entry.target);
  });

  if (loading) return <></>;

  if (
    localStorage.getItem("domainType") === "USER" ||
    localStorage.getItem("domainType") === null
  ) {
    // 승인전 승인 심사 페이지로 이동
    if (store && store.status.code === 4) {
      navigate("/user/underreview");
    }
  }

  // console.log(storeAuth);

  // console.log(saleItem);

  return (
    <div
      className="StoreMainPage container menu"
      style={
        window.location.pathname.includes("/user")
          ? {
              paddingTop: 0,
            }
          : {}
      }
    >
      <Col>
        <Carousel
          fade
          controls={false}
          indicators={false}
          className={`StorePhoto ${
            store && store.images.length === 0 ? "p-3" : ""
          }`}
        >
          {store && store.images.length === 0 ? (
            <Carousel.Item key="und">
              {localStorage.getItem("domainType") === "STORE" ? (
                <div
                  className="BeforePhoto text-center active"
                  style={{ height: "12rem", cursor: "pointer" }}
                  onClick={onClickUpload}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={titleFileUpload}
                    onChange={onChangeImg}
                  />
                  <div>
                    <Image
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/static/store-main/store_add.png"
                    />
                    <p className="mt-3 mb-0">매장 사진을 등록해주세요.</p>
                  </div>
                </div>
              ) : (
                //   <Carousel.Caption>
                //     <input
                //       type="file"
                //       style={{ display: "none" }}
                //       ref={titleFileUpload}
                //       onChange={onChangeImg}
                //     />
                //     <img
                //       className="d-block w-15"
                //       src={getImgUrl("images/icon/button_main_edit.png")}
                //       alt="1"
                //       // onClick={setTitleImg}
                //       onClick={onClickUpload}
                //     />
                //   </Carousel.Caption>
                <img
                  className="d-block w-100"
                  src={getImgUrl("images/icon/store_default_img.jpg")}
                  alt="1"
                />
              )}
            </Carousel.Item>
          ) : (
            store &&
            store.images.map((row) => (
              <Carousel.Item interval={1000} key={row.id}>
                <img className="d-block w-100" src={row.url} alt="1" />
                {localStorage.getItem("domainType") === "STORE" ? (
                  <Carousel.Caption
                    style={{ bottom: "calc(1.25rem + 25px)", left: "2.2rem" }}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={titleFileUpload}
                      onChange={onChangeImg}
                    />
                    <img
                      className="img-fluid btn btn-camera p-0 d-block"
                      src={
                        "https://www.flexdaycdn.net/public/images/static/store-main/store_camera.png"
                      }
                      alt="1"
                      // onClick={setTitleImg}
                      onClick={onClickUpload}
                    />
                  </Carousel.Caption>
                ) : (
                  <></>
                )}
              </Carousel.Item>
            ))
          )}
        </Carousel>
      </Col>

      <Container className="StoreDetail pt-4-5 mw-100" style={{ zIndex: 1 }}>
        <Row className="d-flex align-items-center justify-content-center p-3">
          <div className="StoreTitle text-center px-4-5">
            <p className="tit">{store && store.storeName}</p>
            <ul className="StoreRate d-flex">
              {store && store.grade === 0 ? (
                <li>
                  <Image className="img-fluid" src={starImgUrl("zero")} />
                </li>
              ) : (
                <>
                  {Array.from({ length: 5 }, (_, i) => i + 1).map((i) => {
                    let type =
                      i < store?.grade
                        ? "default"
                        : i === Math.ceil(store?.grade)
                        ? store?.grade % 1 === 0
                          ? "default"
                          : "half"
                        : "zero";
                    return (
                      <li key={i}>
                        <Image className="img-fluid" src={starImgUrl(type)} />
                      </li>
                    );
                  })}
                </>
              )}
              <li>{store && store.grade}</li>
            </ul>
          </div>
          {/* <h6>가게정보 &middot; 원산지<FontAwesomeIcon icon={faAngleRight} /></h6> */}
          <StoreInfo
            storeName={store && store.storeName}
            businessHours={store && store.businessHours}
            holiday={store && store.holiday}
            businessNumber={store && store.businessNumber}
            storePhonewNumber={store && store.storePhoneNumber}
            storeZipCode={store && store.storeAddress.zipCode}
            storeAddress={store && store.storeAddress.address}
            storeAddressDetail={store && store.storeAddress.addressDetail}
            originLabelling={store && store.originLabelling}
            ownerName={store && store.ownerName}
            businessName={store && store.businessName}
          />
          {/* storeZipCode2={store && store.businessAddress.zipCode} storeAddress2={store && store.businessAddress.address} storeAddressDetail2={store && store.businessAddress.addressDetail}  */}
        </Row>

        {store && store.status.code === 4 ? (
          <CustomAlert type="BTN" code={store.status.code} />
        ) : store && store.status.code === 3 ? (
          <CustomAlert type="BTN" code={store.status.code} />
        ) : (
          <></>
        )}

        <div className="StoreTab-wrap">
          <Row className="StoreTab">
            <Tabs
              defaultActiveKey="store"
              id="fill-tab"
              className="px-4-5"
              // justify
            >
              {localStorage.getItem("domainType") === "STORE" && (
                <Tab eventKey="store" title="판매">
                  <div>
                    <>
                      {saleItem && saleItem.data.length === 0 ? (
                        // <CustomAlert type="ALERT"text="판매중인 교환권이 없습니다." />
                        <Row className="EditBtnGroup d-flex mb-3 px-4-5">
                          <Button
                            variant="default"
                            onClick={() =>
                              navigate(
                                "/store/itemcreate?storeurl=" + storeDomain
                              )
                            }
                          >
                            교환권 추가
                          </Button>
                        </Row>
                      ) : (
                        saleItem && (
                          <>
                            {/* <Row className="EditBtnGroup d-flex px-4-5">
                              <Col className="px-0">
                                <Button
                                  variant="light"
                                  className="type8 btn h-45px"
                                  onClick={() =>
                                    navigate("/store/productcategory")
                                  }
                                >
                                  카테고리 관리
                                </Button>
                              </Col>
                              <Col className="px-0">
                                <Button
                                  variant="default"
                                  className="type6 h-45px"
                                  onClick={() =>
                                    navigate(
                                      "/store/itemcreate?storeurl=" +
                                        storeDomain
                                    )
                                  }
                                >
                                  교환권 추가
                                </Button>
                              </Col>
                            </Row> */}
                            <Row className="EditBtnGroup d-flex mb-3 px-4-5">
                              <Button
                                variant="default"
                                onClick={() =>
                                  navigate(
                                    "/store/itemcreate?storeurl=" + storeDomain
                                  )
                                }
                              >
                                교환권 추가
                              </Button>
                            </Row>
                            <div className="ProductList">
                              {saleItem.data.map((row) => (
                                <StoreItem
                                  key={"Adm" + row.id}
                                  id={row.id}
                                  type={"STOP"}
                                  domain={store && store.domain}
                                  title={row.name}
                                  img={row.images[0].url}
                                  description={row.description}
                                  price={setCommas(row.price) + " 원"}
                                  onSele="getSeleItem"
                                  onStop="getStopItem"
                                  menuType={row.menuType.description}
                                />
                              ))}
                            </div>
                          </>
                        )
                      )}
                    </>
                  </div>
                </Tab>
              )}

              {localStorage.getItem("domainType") === "STORE" && (
                <Tab eventKey="view" title="판매중지">
                  <Container>
                    <div className="ProductList">
                      {stopItem && stopItem.data.length === 0 ? (
                        // <CustomAlert type="ALERT"text="판매중지 교환권이 없습니다." />
                        <></>
                      ) : (
                        stopItem &&
                        stopItem.data.map((row) => (
                          <StoreItem
                            key={row.id}
                            id={row.id}
                            type={"START"}
                            domain={store && store.domain}
                            title={row.name}
                            img={row.images[0].url}
                            description={row.description}
                            price={setCommas(row.price) + " 원"}
                          />
                        ))
                      )}
                    </div>
                  </Container>
                </Tab>
              )}

              {localStorage.getItem("domainType") === "USER" ||
              localStorage.getItem("domainType") === null ? (
                <Tab eventKey="store" title="교환권">
                  <Container>
                    <div className="ProductList">
                      {saleItem && saleItem.data.length === 0 ? (
                        // <CustomAlert type="ALERT"text="판매중인 교환권이 없습니다." />
                        <></>
                      ) : (
                        saleItem &&
                        saleItem.data.map((row) => (
                          <StoreItem
                            key={"Adm" + row.id}
                            id={row.id}
                            domain={store && store.domain}
                            title={row.name}
                            img={row.images[0].url}
                            description={row.description}
                            price={setCommas(row.price) + " 원"}
                            onSele="getSeleItem"
                            onStop="getStopItem"
                          />
                        ))
                      )}
                    </div>
                  </Container>
                </Tab>
              ) : (
                <></>
              )}

              {localStorage.getItem("domainType") === "HIDE" && (
                <Tab eventKey="qrcode" title="접속 QR코드 보기">
                  <Col xs={12} className="p-5">
                    <QrCode
                      QRCodeUrl={getRedirctUrl(store && store.domain)}
                      QRCodeId={"QRCode"}
                    />
                  </Col>
                </Tab>
              )}

              {/* { localStorage.getItem('domainType') === 'USER' && */}
              <Tab eventKey="review" title={`리뷰(${store?.reviewCount ?? 0})`}>
                <Container>
                  <Row className="pt-3 justify-content-between">
                    <Col>
                      <h4>최근 리뷰 {store && store.reviewCount} 개</h4>
                    </Col>
                    <Col className="text-end">
                      {localStorage.getItem("domainType") === "USER" && (
                        <Button
                          variant="primary"
                          onClick={() =>
                            navigate(
                              "/user/storereview?storeurl=" + storeDomain
                            )
                          }
                        >
                          후기
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    {/* <Col xs={4} > */}
                    {/* <div key='default-checkbox' className='DefaultCheckBox'>
                                                <Form.Check type='checkbox' id='default-checkbox' label='사진리뷰만'/>
                                            </div> */}
                    {/* </Col> */}
                    <Col xs={12} className="">
                      <ul className="list-group list-group-horizontal justify-content-end">
                        <li
                          className="list-group-item"
                          onClick={(e) => setReviewSort("NEW")}
                          style={{
                            cursor: "pointer",
                            fontWeight: reviewSort === "NEW" ? "700" : "400",
                          }}
                        >
                          최신순
                        </li>
                        <li
                          className="list-group-item"
                          onClick={(e) => setReviewSort("HIGH")}
                          style={{
                            cursor: "pointer",
                            fontWeight: reviewSort === "HIGH" ? "700" : "400",
                          }}
                        >
                          별점높은순
                        </li>
                        <li
                          className="list-group-item"
                          onClick={(e) => setReviewSort("LOW")}
                          style={{
                            cursor: "pointer",
                            fontWeight: reviewSort === "LOW" ? "700" : "400",
                          }}
                        >
                          별점낮은순
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    {/* <Col xs={2}>
                      <Image src="http://placeimg.com/480/480" fluid rounded />
                    </Col> */}

                    {storeReview.length === 0 ? (
                      <Col>
                        <CustomAlert
                          type="ALERT"
                          text="등록된 리뷰가 없습니다."
                        />
                      </Col>
                    ) : (
                      storeReview.map((row) => (
                        <div key={row.id}>
                          <Col xs={12}>
                            <Row>
                              <Col xs={12}>
                                <p>
                                  {row.reviewerName}{" "}
                                  <small>{row.createdAt}</small>
                                </p>
                              </Col>
                              <Col xs={6}>
                                <Gradient grade={row.grade} />
                              </Col>
                              <Col xs={6} className="AlignRight">
                                {localStorage.getItem("domainType") !==
                                "STORE" ? (
                                  localStorage.getItem("domainType") ===
                                  "USER" ? (
                                    <label
                                      onClick={() => {
                                        navigate(
                                          "/user/report?domain=" +
                                            storeDomain +
                                            "&id=" +
                                            row.id
                                        );
                                      }}
                                    >
                                      신고하기
                                    </label>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} className="ReviewImg">
                            {row.images.length !== 0 ? (
                              <Image src={row.images[0].url} fluid rounded />
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col>
                            <pre>{row.contents}</pre>
                          </Col>
                        </div>
                      ))
                    )}
                  </Row>
                  <Target />
                </Container>
              </Tab>

              {localStorage.getItem("domainType") === "USER" ||
              localStorage.getItem("domainType") === null ? (
                <Tab eventKey="detail" title="상세정보">
                  <Container>
                    <Row>
                      <Col xs={{ span: 10, offset: 1 }}>
                        <Form.Label className="LabelTitle">
                          영업 정보
                        </Form.Label>
                        <Table className="TextTable">
                          <tbody>
                            <tr>
                              <td>상호명</td>
                              <td>{store && store.storeName}</td>
                            </tr>
                            <tr>
                              <td>운영시간</td>
                              <td>{store && store.businessHours}</td>
                            </tr>
                            <tr>
                              <td>휴무일</td>
                              <td>{store && store.holiday}</td>
                            </tr>
                            <tr>
                              <td>전화번호</td>
                              <td>{store && store.storePhoneNumber}</td>
                            </tr>
                            <tr>
                              <td>주소</td>
                              <td>
                                {store && store.storeAddress.zipCode} <br />{" "}
                                {store && store.storeAddress.address} <br />{" "}
                                {store && store.storeAddress.addressDetail}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={{ span: 10, offset: 1 }}>
                        <Form.Label className="LabelTitle">
                          사업자 정보
                        </Form.Label>
                        <Table className="TextTable">
                          <tbody>
                            <tr>
                              <td>대표자명</td>
                              <td>{store && store.ownerName}</td>
                            </tr>
                            <tr>
                              <td>상호명</td>
                              <td>{store && store.businessName}</td>
                            </tr>
                            <tr>
                              <td>사업자주소</td>
                              <td>
                                {store && store.businessAddress.zipCode} <br />{" "}
                                {store && store.businessAddress.address} <br />{" "}
                                {store && store.businessAddress.addressDetail}
                              </td>
                            </tr>
                            <tr>
                              <td>사업자등록번호</td>
                              <td>{store && store.businessNumber}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={{ span: 10, offset: 1 }}>
                        <Form.Label className="LabelTitle">
                          원산지 표기
                        </Form.Label>
                        <Table className="TextTable">
                          <tbody>
                            <tr colSpan="2">
                              <td>
                                <pre>{store && store.originLabelling}</pre>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>

                  <div className="LineBreak mt-3"></div>
                  <QRFooter store={store && store.businessName} />
                  <div className="LineBreak mt-3"></div>
                  {/* <Container>
                                    <Row>
                                        <Col className='mt-3'>
                                            <Form.Label className='LabelTitle'>이 브랜드의 인기선물</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{span:4}} className="ContentCenter" key='F1'>
                                            <Image src="http://placeimg.com/480/480" fluid rounded />
                                        </Col>
                                        <Col xs={{span:4}} className="ContentCenter" key='F2'>
                                            <Image src="http://placeimg.com/480/480" fluid rounded />
                                        </Col>
                                        <Col xs={{span:4}} className="ContentCenter" key='F3'>
                                            <Image src="http://placeimg.com/480/480" fluid rounded />
                                        </Col>
                                    </Row>
                                </Container> */}
                </Tab>
              ) : (
                <></>
              )}
            </Tabs>
          </Row>
        </div>
      </Container>
      {/* <Footer></Footer> */}
      {/* <Footer_V2 /> */}
    </div>
  );
}
