import React from "react";
import { css, styled } from "styled-components";
import HistoryListItem from "../molecule/HistoryListItem";
import { color } from "../../../../../components/ver1.5/styles/theme";
import { presetHeight } from "../../../../../components/ver1.5/constants";
import axios from "axios";
import { getApiUrl } from "../../../../../function/common";

const Container = styled.div`
  padding: 4.8rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  ${(props) =>
    !props.datalen &&
    css`
      height: 70vh;
      align-items: center;
      justify-content: center;
    `}
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${color.gray.d600};
`;

const HistoryList = ({ data = [], loading, onClickCancel }) => {
  return (
    <Container datalen={data.length}>
      {data.length ? (
        data.map((row) => (
          <HistoryListItem
            key={row.orderNumber}
            {...row}
            onClickCancel={onClickCancel}
          />
        ))
      ) : (
        <Text>{loading ? "데이터 로딩 중" : "구매내역이 없습니다."}</Text>
      )}
    </Container>
  );
};

export default HistoryList;
