import PosCheck from "../pages/pos/PosCheck";
import PosLogin from "../pages/pos/PosLogin";
import PosMain from "../pages/pos/PosMain";

const PosRoutes = [
  { path: "login", element: <PosLogin /> },
  { path: "main", element: <PosMain /> },
  { path: "check", element: <PosCheck /> },
];

export default PosRoutes;
