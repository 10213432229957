import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { PrintConsole, getImgUrl } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { setAccessToken, setNav1 } from './../../api/ApiWebView';

export default function StoreHome(){
    const navigate = useNavigate();
    navigate('/store/'+localStorage.getItem('storeDomain'));
    

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출
        try{
            setAccessToken();
        }catch(e){}
        try{
            setNav1();
        }catch(e){}
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
        </>
    )
}