import React, { useEffect, useState } from "react";
import Layout from "../../../components/ver1.5/layout/Layout";
import noticeListSample from "./components/noticeListSample";
import NoticeListItem from "./components/organism/NoticeListItem";
import { styled } from "styled-components";
import axios from "axios";
import { getApiUrl } from "../../../function/common";
import useInfiniteScroll from "../../../components/ver1.5/hooks/useInfiniteScroll";

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.6rem;
`;

const Notice = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [cursorId, setCursorId] = useState(undefined);
  const [size, setSize] = useState(10);

  const getNotices = async ({ cursorId, size }) => {
    if (loading) return;
    setLoading(true);
    await axios
      .get(getApiUrl("notices"), {
        params: {
          ...(cursorId && { cursorId }),
          size,
          type: "USER",
        },
      })
      .then((res) => {
        if (res.data.hasNext) {
          if (res.data.nextCursorId) {
            setCursorId(res.data.nextCursorId);
          }
          setHasNext(true);
        } else {
          setHasNext(false);
        }

        setList((prev) => [...prev, ...res.data.data]);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getNotices({
      cursorId,
      size,
    });
    // setList(noticeListSample);
  }, []);

  const { Target } = useInfiniteScroll(async (entry, observer) => {
    if (loading) {
      return;
    }
    if (!hasNext) {
      return;
    }
    observer.unobserve(entry.target);
    await getNotices({ size, cursorId });
    observer.observe(entry.target);
  });

  return (
    <Layout footerPadding={false}>
      <ListContainer>
        {list.map((item, index) => (
          <NoticeListItem
            key={index}
            title={item.title}
            date={item.createdAt}
            content={item.contents}
            isLast={list.length - 1 === index}
          />
        ))}
      </ListContainer>
      <Target />
    </Layout>
  );
};

export default Notice;
