import React, { useEffect, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import LabelledTextField from "../../../../components/ver1.5/organism/LabelledTextField";
import { css, styled } from "styled-components";
import { presetHeight } from "../../../../components/ver1.5/constants";
import { passwordReg } from "../../../../components/common/constants";
import Button from "../../../../components/ver1.5/atom/Button";
import PasswordModal from "../components/organism/PasswordModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiUrl } from "../../../../function/common";
import sha256 from "sha256";

const BottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
`;

const ResetPw = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [rePasswordError, setRePasswordError] = useState(false);
  const [passwordModal, setPasswordModal] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [valid, setValid] = useState(false);

  const onSubmit = async () => {
    const res = await axios.put(getApiUrl("users/me/password"), {
      nowPassword: sha256(currentPassword),
      passwordToBeChanged: sha256(password),
    });
    if (res.status === 200) {
      alert("비밀번호가 변경되었습니다.");
      navigate(-1);
    } else {
      alert("비밀번호 변경에 실패하였습니다.");
      navigate(-1);
    }
  };

  const onCheckNowPw = async () => {
    await axios
      .post(getApiUrl("users/me/password"), {
        nowPassword: sha256(currentPassword),
      })
      .then((res) => {
        if (res.status === 200) {
          setPasswordModal(false);
        }
      })
      .catch((e) => alert(e.response?.data?.message));
  };

  useEffect(() => {
    if (!password.length) {
      setPasswordError(false);
    } else {
      if (!passwordReg.test(password)) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
  }, [password]);

  useEffect(() => {
    if (!rePassword.length) {
      setRePasswordError(false);
    } else {
      if (rePassword !== password) {
        setRePasswordError(true);
      } else {
        setRePasswordError(false);
      }
    }
  }, [rePassword, password]);

  useEffect(() => {
    if (
      password.length > 0 &&
      rePassword.length > 0 &&
      !passwordError &&
      !rePasswordError
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [password, rePassword, passwordError, rePasswordError]);

  return (
    <Layout
      styles={css`
        padding: 1.6rem;
        padding-top: ${`calc(${presetHeight.header} + 1.6rem)`};
      `}
    >
      <LabelledTextField
        label={"새 비밀번호"}
        placeholder={"비밀번호 입력"}
        marginBottom={"3.2rem"}
        required
        type={password.length > 0 ? "bordered" : "filled"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={passwordError}
        helperText={"영문+숫자+특수문자 !@#$%^ 를 포함 10~18자"}
        inputType="password"
      />
      <LabelledTextField
        label={"새 비밀번호 확인"}
        placeholder={"비밀번호 재입력"}
        required
        type={rePassword.length > 0 ? "bordered" : "filled"}
        value={rePassword}
        onChange={(e) => setRePassword(e.target.value)}
        error={rePasswordError}
        helperText={
          rePasswordError
            ? "비밀번호가 일치하지 않습니다"
            : !rePasswordError && rePassword.length > 10
            ? "비밀번호가 일치합니다."
            : null
        }
        inputType="password"
        status={!rePasswordError && !passwordError ? "complete" : null}
      />
      <BottomContainer>
        <Button text="변경하기" disabled={!valid} onClick={onSubmit} />
      </BottomContainer>
      {passwordModal && (
        <PasswordModal
          onAction={onCheckNowPw}
          onCancel={() => {
            document.body.style.overflow = "";
            navigate(-1);
          }}
          password={currentPassword}
          setPassword={setCurrentPassword}
          from={"resetPw"}
        />
      )}
    </Layout>
  );
};

export default ResetPw;
