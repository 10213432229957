import React from "react";
import { css, styled } from "styled-components";
import {
  ColumnContainer,
  RowContainer,
} from "../../../../../components/ver1.5/styles/Ver2GlobalStyles";
import Text from "../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../components/ver1.5/styles/theme";

const Container = styled(ColumnContainer)`
  padding: 1.6rem;
  padding-top: 2.4rem;
  gap: 0.8rem;
`;

const InfoRow = styled(RowContainer)`
  justify-content: space-between;
  padding: 1.4rem 0;
  ${(p) =>
    !p.$isLast &&
    css`
      border-bottom: 1px solid ${color.gray.d50};
    `}
`;

const PaymentInfo = ({
  cardName,
  type,
  installment,
  cardNumber,
  approveDate,
  approveId,
}) => {
  function getOrderedAt(orderedAt) {
    if (!Boolean(orderedAt) || isNaN(parseInt(orderedAt))) return "-";
    let arr = [];
    const target = orderedAt.split("");
    for (let i = 0; i < target.length; i += 2) {
      arr.push(target.slice(i, i + 2).join(""));
    }
    const date = arr.slice(0, 3);
    const hour = arr.slice(3);
    return "20" + date.join("-") + " " + hour.join(":");
  }

  const info = [
    {
      label: "카드종류",
      value: cardName,
    },
    {
      label: "거래종류",
      value: "신용거래",
      // value: type,
    },
    {
      label: "할부개월",
      value: "일시불",
      // value: installment,
    },
    {
      label: "카드번호",
      value: cardNumber,
    },
    {
      label: "거래일시",
      value: getOrderedAt(approveDate),
    },
    {
      label: "승인번호",
      value: approveId,
    },
  ];

  return (
    <Container>
      <Text fontStyle={font.subtitle3} color={color.gray.d900}>
        결제정보
      </Text>
      <ColumnContainer>
        {info.map(({ label, value }, index) => (
          <InfoRow key={index} $isLast={index === info.length - 1}>
            <Text fontStyle={font.caption2} color={color.gray.d600}>
              {label}
            </Text>
            <Text fontStyle={font.caption2} color={color.gray.d900}>
              {value}
            </Text>
          </InfoRow>
        ))}
      </ColumnContainer>
    </Container>
  );
};

export default PaymentInfo;
