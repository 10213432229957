import "./baemin.css";
import "./common.css";
// import './main.css'
// import './normalize.css'

export default function Baemin() {
  return (
    <>
      <div className="demo">
        <div className="TAdPaymentPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">주문하기</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick="history.back()"
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <button
                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <img
                  className="img-fluid"
                  alt="메뉴"
                  src="https://www.flexdaycdn.net/public/images/ticket_ver2/baemin_home.png"
                />
              </button>
            </nav>

            <div className="AdPaymentBox">
              <div className="pay_infobox px-4-5">
                <div className="ad_tit info_txt">
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket_ver2/bike_icon.png"
                    alt=""
                  />
                  배달로 받을게요
                </div>
                <div className="time_txt">
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket_ver2/clock_icon2.png"
                    alt=""
                  />
                  17~32분 후 도착
                </div>
              </div>

              <div className="pay_address px-4-5">
                <p className="ad_tit txt1">
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket_ver2/map_icon.png"
                    alt=""
                  />
                  회사
                </p>
                <p className="add1">남구 문현동 1227-2</p>
                <p className="add2">[도로명] 전포대로 133</p>
                <input
                  type="text"
                  className="form-control InputType ad-input"
                  placeholder="상세주소를 입력해주세요 (건물명, 동/호수 등)"
                />
              </div>

              <div className="pay_phoneinfo">
                <div className="numberBox  px-4-5">
                  <p className="number">010-1234-5678</p>
                  <button type="button" className="btn btn-clear btn-numchange">
                    변경
                  </button>
                </div>
                <div className="numberCheck px-4-5">
                  <div className="ad_checkbox">
                    <input
                      className="form-check-input ad_check"
                      type="checkbox"
                      id="safe_num"
                      checked
                    />
                    <label
                      className="form-check-label ad_label"
                      htmlFor="safe_num"
                    >
                      안심번호 사용
                    </label>
                    <button
                      type="button"
                      className="btn btn-clear btn-more btn-safemore"
                    >
                      자세히
                    </button>
                  </div>
                </div>
                <div className="numberTxt  px-4-5">
                  내 주문횟수를 가게에 제공하지 않습니다. (최근 6개월)
                </div>
              </div>
            </div>

            <div className="AdPaymentBox">
              <div className="pay_request">
                <div className="pay_request-box px-4-5">
                  <div className="ad_tit">요청사항</div>
                  <div className="ad_checkbox">
                    <input
                      className="form-check-input ad_check"
                      type="checkbox"
                      id="check_request1"
                      checked
                    />
                    <label
                      className="form-check-label ad_label"
                      htmlFor="check_request1"
                    >
                      일회용 수저, 포크 안 주셔도 돼요
                    </label>
                  </div>
                </div>
                <div className="pay_request-sub px-4-5">
                  <div className="tit">가게 사장님께</div>
                  <input
                    type="text"
                    className="form-control InputType ad-input"
                    placeholder="예) 견과류 빼주세요, 덜 맵게 해주세요"
                  />
                  <div className="ad_checkbox">
                    <input
                      className="form-check-input ad_check"
                      type="checkbox"
                      id="check_request2"
                    />
                    <label
                      className="form-check-label ad_label"
                      htmlFor="check_request2"
                    >
                      다음에도 사용
                    </label>
                  </div>
                </div>
                <div className="pay_request-sub px-4-5">
                  <div className="tit">라이더님께</div>
                  <div className="pay_request-Select">
                    <button
                      className="btn btn-clear collapsed selectBtn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#SelectBox"
                    >
                      문 앞에 두고 벨 눌러주세요.
                    </button>
                    <div className="collapse" id="SelectBox">
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="selectRider1"
                          type="radio"
                          name="selectRider"
                          checked
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="selectRider1"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          onClick="selectRider()"
                        >
                          문 앞에 두고 벨 눌러주세요
                        </label>
                      </div>
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="selectRider2"
                          type="radio"
                          name="selectRider"
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="selectRider2"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          onClick="selectRider()"
                        >
                          문 앞에 두고 노크해주세요
                        </label>
                      </div>
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="selectRider3"
                          type="radio"
                          name="selectRider"
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="selectRider3"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          onClick="selectRider()"
                        >
                          문 앞에 두면 가져갈게요(벨X, 노크X)
                        </label>
                      </div>
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="selectRider4"
                          type="radio"
                          name="selectRider"
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="selectRider4"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          onClick="selectRider()"
                        >
                          직접 받을게요
                        </label>
                      </div>
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="selectRider5"
                          type="radio"
                          name="selectRider"
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="selectRider5"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          onClick="selectRider()"
                        >
                          전화주시면 마중 나갈게요
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="ad_checkbox">
                    <input
                      className="form-check-input ad_check"
                      type="checkbox"
                      id="check_request"
                    />
                    <label
                      className="form-check-label ad_label"
                      htmlFor="check_request"
                    >
                      이 주소에 다음에도 사용
                    </label>
                    <button
                      type="button"
                      className="btn btn-clear btn-more btn-addinfo"
                    >
                      자세히
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="AdPaymentBox">
              <div className="pay_method">
                <div className="pay_method-box px-4-5">
                  <div className="ad_tit">결제수단</div>
                  <div className="ad_radiobox">
                    <input
                      className="form-check-input ad_radio"
                      type="radio"
                      id="pay_select1"
                      name="pay_select"
                      disabled
                    />
                    <label
                      className="form-check-label ad_radiolabel"
                      htmlFor="pay_select1"
                    >
                      배민페이
                      <span className="info-txt">머니 결제 시 포인트 적립</span>
                      <img
                        className="img-fluid info-icon"
                        src="https://www.flexdaycdn.net/public/images/ticket_ver2/info_icon.png"
                        alt=""
                      />
                      <div className="baemin_img">
                        <img
                          className="img-fluid"
                          src="https://www.flexdaycdn.net/public/images/ticket_ver2/baemin.png"
                          alt=""
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="pay_method-box px-4-5">
                  <div className="ad_radiobox">
                    <input
                      className="form-check-input ad_radio"
                      type="radio"
                      id="pay_select2"
                      name="pay_select"
                      checked
                    />
                    <label
                      className="form-check-label ad_radiolabel"
                      htmlFor="pay_select2"
                    >
                      다른 결제수단
                    </label>
                  </div>
                  <div className="ad_inputbox">
                    <input
                      type="text"
                      className="form-control InputType ad-input2"
                      value="네이버페이"
                      readOnly
                    />
                    <button
                      type="button"
                      className="btn btn-clear btn-paychange"
                    >
                      변경
                    </button>
                  </div>
                </div>

                <div className="pay_method-info px-4-5">
                  <p className="color">
                    배민현대카드는 최대 5% 배민포인트 적립
                    <img
                      className="img-fluid info-icon"
                      src="https://www.flexdaycdn.net/public/images/ticket_ver2/arrow_B.png"
                      alt=""
                    />
                  </p>
                  <p>
                    배민페이에서 신한카드로 결제한 적이 없다면? 아래의
                    할인쿠폰을 눌러 쿠폰코드 '신한카드첫결제'를 입력하면 3천원
                    즉시 할인!
                  </p>
                </div>
              </div>
            </div>

            <div className="AdPaymentBox">
              <div className="additional-item gift px-4-5">
                <div className="txt">선물함</div>
                <div className="cont">
                  <span>0원</span>
                  <img
                    src="https://www.flexdaycdn.net/public/images/ticket/arrow-right.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="additional-item sale px-4-5">
                <div className="txt">할인쿠폰</div>
                <div className="cont">
                  <span>2개보유</span>
                  <img
                    src="https://www.flexdaycdn.net/public/images/ticket/arrow-right.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="additional-item point px-4-5 disabled">
                <div className="txt">배민포인트</div>
                <div className="cont">
                  <span>0원</span>
                  <img
                    src="https://www.flexdaycdn.net/public/images/ticket/arrow-right.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="AdPaymentBox">
              <div className="additional-item file px-4-5">
                <div className="txt">파일첨부</div>
                <div className="cont">
                  <input
                    type="file"
                    id="baemin_file"
                    className="BaeminFile"
                    onChange="FileChangeTxt()"
                  />
                  <label htmlFor="baemin_file" className="BaeminFile-Label">
                    파일을 업로드 해주세요.
                  </label>
                </div>
              </div>
            </div>

            <div className="AdPaymentBox">
              <div className="additional-item CashReceipts px-4-5">
                <div className="txt">현금영수증</div>
                <div className="cont">
                  <div className="ad_radiobox">
                    <input
                      className="form-check-input ad_radio"
                      type="radio"
                      id="CashReceipts1"
                      name="CashReceipts"
                      checked
                    />
                    <label
                      className="form-check-label ad_radiolabel"
                      htmlFor="CashReceipts1"
                    >
                      미신청
                    </label>
                  </div>
                  <div className="ad_radiobox">
                    <input
                      className="form-check-input ad_radio"
                      type="radio"
                      id="CashReceipts2"
                      name="CashReceipts"
                      checked
                    />
                    <label
                      className="form-check-label ad_radiolabel"
                      htmlFor="CashReceipts2"
                    >
                      신청
                    </label>
                  </div>
                </div>
              </div>
              <div className="additional-item CashNum px-4-5">
                <div className="txt">010-1234-5678 (개인소득공제용)</div>
                <div className="cont">
                  <button
                    type="button"
                    className="btn btn-clear btn-CashReceipts"
                  >
                    변경
                  </button>
                </div>
              </div>
            </div>

            <div className="AdPaymentBox">
              <div className="price-box px-4-5">
                <div className="ad_tit">결제금액</div>
                <div className="price-item">
                  <div className="txt">주문금액</div>
                  <div className="cont">8,000원</div>
                </div>
                <div className="price-item">
                  <div className="txt">
                    배달팁{" "}
                    <button type="button" className="btn btn-clear btn-tip">
                      자세히
                    </button>
                  </div>
                  <div className="cont">1,700원</div>
                </div>
                <div className="price-item total">
                  <div className="txt">총 결제금액</div>
                  <div className="cont">9,700원</div>
                </div>
              </div>
            </div>

            <div className="AdInfoBox">
              <div className="AdInfoBox-item px-4-5">
                <button
                  className="btn btn-clear collapsed btn-infoBtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#AdInfoBox1"
                >
                  배달상품 주의사항
                </button>
                <div className="collapse infoCont" id="AdInfoBox1">
                  풀이 있는 끓는 넣는 힘차게 목숨을 것이다. 눈이 황금시대를
                  쓸쓸한 원질이 그들은 위하여, 피가 아니다. 품고 꽃이 위하여,
                  보이는 영원히 풍부하게 이상의 수 말이다. 가치를 고행을
                  타오르고 끓는 설산에서 구하기 아니다. 얼마나 얼음이 내는 같은
                  피부가 것이다. 스며들어 천고에 남는 평화스러운 이것은 일월과
                  약동하다. 얼마나 그들에게 인류의 역사를 꽃이 때문이다. 곳이 이
                  눈에 용감하고 열락의 그리하였는가? 창공에 영락과 그들은 안고,
                  영원히 어디 것이다. 품에 피고 많이 청춘의 아니다. 수 가진
                  바이며, 끓는 때에, 많이 그림자는 뿐이다. 용기가 힘차게
                  피어나는 목숨이 발휘하기 청춘에서만 운다. 아니한 인간에 밝은
                  같은 운다. 가치를 그것을 청춘 이상의 두손을 인생에 그들은
                  고행을 바이며, 있는가? 피에 사람은 같으며, 고행을 동산에는
                  그들의 싶이 말이다. 끝에 무엇을 무엇을 영원히 있는가? 생의 수
                  풀이 우는 고행을 든 것이다. 든 무엇을 곳이 인류의 가는 것은
                  뿐이다.
                </div>
              </div>
              <div className="AdInfoBox-item px-4-5">
                <button
                  className="btn btn-clear collapsed btn-infoBtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#AdInfoBox2"
                >
                  개인정보 제3자 제공
                </button>
                <div className="collapse infoCont" id="AdInfoBox2">
                  스며들어 천고에 남는 평화스러운 이것은 일월과 약동하다. 얼마나
                  그들에게 인류의 역사를 꽃이 때문이다. 곳이 이 눈에 용감하고
                  열락의 그리하였는가? 창공에 영락과 그들은 안고, 영원히 어디
                  것이다. 품에 피고 많이 청춘의 아니다. 수 가진 바이며, 끓는
                  때에, 많이 그림자는 뿐이다.
                </div>
              </div>
              <div className="AdInfoBox-item px-4-5">
                <button
                  className="btn btn-clear collapsed btn-infoBtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#AdInfoBox3"
                >
                  업주의 개인정보 처리 위탁 안내
                </button>
                <div className="collapse infoCont" id="AdInfoBox3">
                  용기가 힘차게 피어나는 목숨이 발휘하기 청춘에서만 운다. 아니한
                  인간에 밝은 같은 운다. 가치를 그것을 청춘 이상의 두손을 인생에
                  그들은 고행을 바이며, 있는가? 피에 사람은 같으며, 고행을
                  동산에는 그들의 싶이 말이다. 끝에 무엇을 무엇을 영원히 있는가?
                  생의 수 풀이 우는 고행을 든 것이다. 든 무엇을 곳이 인류의 가는
                  것은 뿐이다.
                </div>
              </div>
              <div className="AdInfoBox-item px-4-5">
                <p className="last-info mb-0">
                  위 내용을 확인하였으며 결제에 동의합니다.
                </p>
              </div>
            </div>

            <div className="AdPaymentBtn px-4-5">
              <button type="button" className="btn btn-clear">
                9,700원 결제하기
              </button>
            </div>
          </div>
        </div>
      </div>

      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-OERcA2EqjJCMA+/3y+gxIOqMEjwtxJY7qPCqsdltbNJuaOe923+mo//f6V8Qbsw3"
        crossOrigin="anonymous"
      ></script>

      <script>
        {/*    function selectRider() {*/}
        {/*    let selectText = document.querySelector(".selectBtn");*/}
        {/*    selectText.innerText = event.target.innerText;*/}
        {/*}*/}

        {/*    function FileChangeTxt() {*/}
        {/*    const FileTarget = document.querySelector('.BaeminFile');*/}
        {/*    const FileLabel = document.querySelector('.BaeminFile-Label');*/}

        {/*    if (window.FileReader) { // modern browser*/}
        {/*    if (FileTarget.value) {*/}
        {/*    let filename = FileTarget.files[0].name;*/}
        {/*    console.log(filename);*/}

        {/*    FileLabel.innerText = filename;*/}
        {/*    FileLabel.classList.add("active");*/}

        {/*} else if (!FileTarget.value) {*/}
        {/*    FileLabel.innerText = "파일을 업로드 해주세요.";*/}
        {/*    FileLabel.classList.remove("active");*/}
        {/*}*/}
        {/*}*/}
        {/*}*/}
      </script>
    </>
  );
}
