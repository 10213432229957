import React, { useContext, useEffect, useMemo, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import axios from "axios";
import { getApiUrl, gradeConvert } from "../../../../function/common";
import TabList from "../../../../components/ver1.5/organism/TabList";
import ProductList from "./components/organism/ProductList";
import { css, styled } from "styled-components";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import SwiperComponent from "../../../../components/ver1.5/organism/SwiperComponent";
import Text from "../../../../components/ver1.5/atom/Text";
import {
  CheckCircleLineIcon,
  StarIcon,
} from "../../../../components/ver1.5/assets/icons";
import {
  headerFooterZIndex,
  reviewSortList,
} from "../../../../components/ver1.5/constants";
import ReviewList from "./components/organism/ReviewList";
import DetailInfo from "./components/organism/DetailInfo";
import Toast from "../../../../components/ver1.5/organism/Toast";
import DefaultModal from "../../../../components/ver1.5/organism/DefaultModal";
import { UserContext } from "../../../../contexts/UserContext";
import DefaultBackground from "../../../../components/ver1.5/assets/img/shopBackgroundDefault.svg";
import DefaultLogo from "../../../../components/ver1.5/assets/img/shopProfileImgDefault.svg";
import useInfiniteScroll from "../../../../components/ver1.5/hooks/useInfiniteScroll";

const StoreImageContainer = styled.div`
  width: 100%;
  height: 32rem;
  position: fixed;
  top: 0;
  left: 0;
`;

const StoreImage = styled.img`
  width: 100%;
  height: 32rem;
  object-fit: cover;
  flex-shrink: 0;
`;

const StoreImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 32.29%,
    rgba(0, 0, 0, 0) 71.35%,
    rgba(0, 0, 0, 0.3) 100%
  );
  position: absolute;
  top: 0;
  z-index: 1;
`;

const ContentsContainer = styled.div`
  background: ${color.primary.white};
  border-radius: 2.4rem 2.4rem 0 0;
  margin-top: 29.6rem;
  min-height: calc(100vh - 29.6rem);
  z-index: ${headerFooterZIndex + 1};
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: start;
  margin: 2.4rem 2.4rem 3.2rem;
  gap: 1.2rem;
  > img {
    flex-shrink: 0;
  }
`;

const Logo = styled.img`
  width: 4.8rem;
  aspect-ratio: 1;
  border-radius: 2.4rem;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const initialStoreInfo = {
  images: [],
  logoUrl: DefaultLogo,
  reviewCount: 0,
  storeName: "",
  storeAddress: {
    address: "",
    addressDetail: "",
  },
  grade: 0,
};

const StoreDetail = () => {
  const { accessToken } = useContext(UserContext);
  const { setCurrentStore } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const status = location.state?.status;
  const { storeDomain = "" } = useParams();
  const [storeInfo, setStoreInfo] = useState(initialStoreInfo);
  const [products, setProducts] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    status && status === "reviewDone" ? "review" : "product"
  );
  const [reviewSort, setReviewSort] = useState(reviewSortList[0].value);
  const [loading, setLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [modal, setModal] = useState(false);

  const storeDetailTabList = useMemo(() => {
    return [
      {
        name: "상품",
        value: "product",
        component: (
          <ProductList
            data={products}
            storeDomain={storeDomain}
            modal={modal}
            setModal={setModal}
          />
        ),
      },
      {
        name: `리뷰 ${storeInfo.reviewCount}`,
        value: "review",
        component: (
          <ReviewList
            data={reviews}
            average={storeInfo.grade}
            sort={reviewSort}
            setSort={setReviewSort}
            storeDomain={storeDomain}
            modal={modal}
            setModal={setModal}
            deleteCallback={() => {
              getReviews({ page, size });
              getStoreInfo().then((res) => {
                if (res.status === 200) {
                  return setStoreInfo(res.data);
                }
              });
            }}
          />
        ),
      },
      {
        name: "상세정보",
        value: "detail",
        component: <DetailInfo storeInfo={storeInfo} />,
      },
    ];
    // eslint-disable-next-line
  }, [storeInfo, products, reviews, storeDomain, reviewSort]);

  const getStoreInfo = async () => {
    const res = await axios.get(
      getApiUrl(`stores/${storeDomain}`),
      accessToken &&
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken),
      (axios.defaults.headers.common["ContentType"] =
        "application/json; charset=utf-8")
    );
    return res;
  };

  const getProducts = async () => {
    const res = await axios.get(
      getApiUrl(`stores/${storeDomain}/products?status=SALE`)
    );
    return res;
  };

  const getReviews = async ({ size, page }) => {
    if (loading) return;
    setLoading(true);
    await axios
      .get(getApiUrl(`stores/${storeDomain}/reviews`), {
        params: {
          reviewOrder: reviewSort,
          size,
          page,
        },
      })
      .then((res) => {
        if (res.data) {
          if (res.data.last) {
            setIsLast(true);
          } else {
            setPage((prev) => prev + 1);
          }
          setReviews((prev) => [...prev, ...res.data.content]);
        }
      })
      .catch((error) => console.error(error));
    setLoading(false);
  };

  useEffect(() => {
    setStoreLoading(true);
    getStoreInfo().then((res) => {
      if (res.status === 200) {
        return setStoreInfo(res.data);
      }
    });
    getProducts().then((res) => {
      if (res.status === 200) {
        return setProducts(res.data.data);
      }
    });
    setStoreLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (status === "reviewDone") {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
    // eslint-disable-next-line
  }, [status]);

  const clear = () => {
    setLoading(false);
    setPage(0);
    setSize(10);
    setIsLast(false);
    setReviews([]);
  };
  // review refetch by sort
  useEffect(() => {
    clear();
    getReviews({ size, page: 0 });
    // eslint-disable-next-line
  }, [reviewSort]);

  // 현재 스토어 정보 state에 담기
  useEffect(() => {
    if (storeInfo) {
      setCurrentStore({
        storeName: storeInfo.storeName,
        storeAddress: storeInfo.storeAddress,
      });

      if (storeInfo?.storeName) {
        localStorage.setItem("lastVisitedStoreName", storeInfo.storeName);
      }
    }
    // eslint-disable-next-line
  }, [storeInfo]);

  useEffect(() => {
    const setCurrentStoreInfo = () => {
      setCurrentStore({ storeName: "", storeAddress: "" });
    };
    window.addEventListener("popstate", () => {
      setCurrentStoreInfo();
      window.removeEventListener("popstate", () => setCurrentStoreInfo());
    });
    // eslint-disable-next-line
  }, []);

  const { Target } = useInfiniteScroll(async (entry, observer) => {
    if (currentTab !== "review") {
      return;
    }
    if (loading) {
      return;
    }
    if (isLast) {
      return;
    }
    observer.unobserve(entry.target);
    await getReviews({ page, size });
    observer.observe(entry.target);
  });

  if (storeLoading) return <></>;

  return (
    <Layout
      headerPadding={false}
      footerPadding={false}
      styles={css`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow-x: hidden;
      `}
    >
      <StoreImageContainer>
        {/* TODO: 나중에 이미지 여러장되면 다시 체크 */}
        {storeInfo.images?.length > 0 ? (
          <SwiperComponent
            data={storeInfo.images}
            centeredSlides
            loop={Boolean(storeInfo.images.length > 1)}
            swiperComponent={(item) => (
              <StoreImage src={item.url} key={item.id} />
            )}
            pagination
            spaceBetweenZero
          />
        ) : (
          <StoreImage src={DefaultBackground} />
        )}
        <StoreImageOverlay />
      </StoreImageContainer>
      <ContentsContainer>
        <InfoContainer>
          <Logo src={storeInfo.logoUrl ?? DefaultLogo} />
          <TextWrapper>
            <Text fontStyle={font.subtitle2} color={color.gray.d900}>
              {storeInfo.storeName}
            </Text>
            <Text
              fontStyle={font.body4}
              color={color.gray.d500}
            >{`${storeInfo.storeAddress.address} ${storeInfo.storeAddress.addressDetail}`}</Text>
          </TextWrapper>
          <Text
            fontStyle={font.body2}
            color={color.gray.d900}
            styles={css`
              display: flex;
              align-items: center;
              gap: 0.4rem;
            `}
            marginLeft="auto"
          >
            <StarIcon />
            {gradeConvert(storeInfo.grade)}
          </Text>
        </InfoContainer>
        <TabList
          rows={storeDetailTabList}
          currentTab={currentTab}
          type="storeDetail"
          onClick={(row) => setCurrentTab(row.value)}
        />
        {storeDetailTabList.find((tab) => tab.value === currentTab).component}
      </ContentsContainer>
      {showToast && (
        <Toast
          text={"리뷰를 작성하였습니다."}
          icon={<CheckCircleLineIcon />}
          setShow={setShowToast}
        />
      )}
      {modal && (
        <DefaultModal
          title={"로그인이 필요합니다."}
          actionText={"로그인"}
          cancelText={"취소"}
          onAction={() =>
            navigate("/user/login", {
              state: { redirectUrl: `/user/${storeDomain}` },
            })
          }
          onCancel={() => setModal(false)}
        />
      )}
      <Target />
    </Layout>
  );
};

export default StoreDetail;
