import MSLogin from "../pages/moneyshow/Login";
import MSIntro from "../pages/moneyshow/Intro";
import MSMain from "../pages/moneyshow/Main";

const MsRoutes = [
  { path: "login", element: <MSLogin /> },
  { path: "intro", element: <MSIntro /> },
  { path: "main", element: <MSMain /> },
];

export default MsRoutes;
