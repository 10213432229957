import React, { useState } from "react";
import { css, styled } from "styled-components";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";
import { ToggleIcon } from "../../../../../components/ver1.5/assets/icons";
import Divider from "../../../../../components/ver1.5/atom/Divider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem 0;
  gap: ${(p) => (p.$open ? "1.8rem" : 0)};
  transition: gap 0.3s ease-in-out;
`;

const ContentWrapper = styled.div`
  ${font.body2};
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  max-height: ${(p) => (p.$open ? "300vh" : 0)};
  opacity: ${(p) => (p.$open ? 1 : 0)};
  visibility: ${(p) => (p.$open ? "visible" : "hidden")};
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  pre,
  div {
    max-height: ${(p) => (p.$open ? "300vh" : 0)};
    visibility: ${(p) => (p.$open ? "visible" : "hidden")};
    transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  width: 2.4rem;
  aspect-ratio: 1;
  margin-left: auto;
  ${(p) =>
    p.$open &&
    css`
      transform: rotate(180deg);
    `}
  transition: transform .3s ease-in-out;
`;

const FaqListItem = ({ sectionTitle, isLast, content }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Container $open={open}>
        <Text
          fontStyle={font.caption1}
          color={color.gray.d900}
          styles={css`
            display: flex;
            align-items: center;
            span {
              ${font.subtitle3};
              color: ${color.primary.flexRed};
              margin-right: 0.8rem;
            }
          `}
          onClick={() => setOpen((prev) => !prev)}
        >
          <span>Q. </span>
          {sectionTitle}
          <IconWrapper $open={open}>
            <ToggleIcon />
          </IconWrapper>
        </Text>
        <ContentWrapper $open={open}>
          <pre>{content}</pre>
        </ContentWrapper>
      </Container>
      {!isLast && (
        <Divider
          direction="horizontal"
          width={"0.1rem"}
          color={color.gray.d50}
        />
      )}
    </>
  );
};

export default FaqListItem;
