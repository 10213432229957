
import { Container, Row, Col, Form, Image, ButtonGroup, Button, InputGroup } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

import { loadTossPayments } from '@tosspayments/payment-sdk'
import { useSearchParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import axios from 'axios';
import Footer from '../../components/common/Footer';
// import QRFooter from './QRFooter';
import { setCommas, getTextLength } from './../../function/common';
import Serviceterms from './../../components/common/ServiceTerms';

import "./common.css";
import "./Payment.css";

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY


export default function Payment(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams]=useSearchParams();
    // const [Params] = useSearchParams();
    console.log(searchParams.get('storeurl'));
    console.log(searchParams.get('productid'));
    // const location = useLocation();
    // console.log(location);
    // var Parameters = [];
    // for (const entry of Params.entries()) {
    //     Parameters.push({ [entry[0]] : entry[1] });
    // }

    const [gift, setGift] = useState({
        recipientName           : "",
        recipientPhoneNumber    : "",
        orderPrice              : ""
    });

    const payMode = useRef();
    
    const onChangeGift = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setGift({
          ...gift,
          [e.target.id]: e.target.value,
        });
        PrintConsole(gift);
    };

    const [isCheck, setIsCheck] = useState([]);
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);

        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const [orderId, setOrderId] = useState();
    function getOrderId() {
        document.getElementById('submitBtn').setAttribute("disabled","true");
        // let s1 = document.getElementById('s1');
        // if (!s1.checked){
        //     alert('개인정보 수집에 동의해주세요.');
        //     document.getElementById('submitBtn').removeAttribute("disabled");
        //     return false;
        // }

        console.log(localStorage.getItem('accessToken'));

        let recipientPhoneNumber = '';
        let messageToRecipient = '';
        if(document.getElementById("recipientPhoneNumber")){
            recipientPhoneNumber = document.getElementById('recipientPhoneNumber').value;
        }
        if(document.getElementById("recipientName")){
            messageToRecipient = document.getElementById('recipientName').value;
        }

        
        // if(recipientPhoneNumber === undefined || recipientPhoneNumber === null || recipientPhoneNumber === ''){
        //     alert('받는사람 전화번호를 입력해주세요.');
        //     document.getElementById('submitBtn').removeAttribute("disabled");
        //     return false;
        // }
        
        return new Promise( async function(resolve, reject) {
            let obj = { 
                "isLoggedIn": true,
                "orderPrice"            : '6000',
                "payType"               : payMode.current,
                "recipientName"         : '중식(정식)',
                "recipientPhoneNumber"  : '01071405615'
            }
            PrintConsole(obj);
            await axios.post( 
                getApiUrl('stores/'+searchParams.get('storeurl')+'/products/'+searchParams.get('productid')+'/food-ticket-orders'),
                obj, 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json;"
            ).then( 
                function(response){
                    PrintConsole(response.data.orderId);
                    tossloading(response.data.orderId);
                }
            )
            .catch(error => {
                AlertError(error);
                document.getElementById('submitBtn').removeAttribute("disabled");
            });
        });
    };

    async function tossloading(orderId) {

        localStorage.setItem('storeDomain', searchParams.get('storeurl'));
        localStorage.setItem('products', searchParams.get('productid'));
        localStorage.setItem('orderId', orderId);

        const tossPayments = await loadTossPayments(clientKey);
        let tossMode = '';
        if (payMode.current === 'CARD'){
            tossMode = '카드';
        }else if(payMode.current === 'PHONE'){
            tossMode = '휴대폰';
        }else if(payMode.current === 'ACCOUNT_TRANSFER'){
            tossMode = '계좌이체';
        }else{
            alert('결제 모듈 호출에 실패하였습니다.');
        }

        PrintConsole('orderId ' + orderId);
        //계좌이체, 휴대폰
        tossPayments.requestPayment(tossMode, { // 결제 수단 파라미터
            // 결제 정보 파라미터
            amount: 6000, 
            orderId: orderId,
            orderName: '중식(정식)',
            customerName: "부경대학교" ,
            successUrl: process.env.REACT_APP_BASEURL+'demo2/success',
            failUrl: process.env.REACT_APP_BASEURL+'demo2/success'
        })
    }
    function setPayMode(mode){
        payMode.current = mode;
        // document.querySelector('#ACCOUNT_TRANSFER').classList.remove('btn-primary');
        // document.querySelector('#CARD').classList.remove('btn-primary');
        // document.querySelector('#PHONE').classList.remove('btn-primary');

        // if(!document.querySelector('#ACCOUNT_TRANSFER').classList.contains('btn-secondary')){
        //     document.querySelector('#ACCOUNT_TRANSFER').classList.add('btn-secondary')
        // }
        // if(!document.querySelector('#CARD').classList.contains('btn-secondary')){
        //     document.querySelector('#CARD').classList.add('btn-secondary')
        // }
        // if(!document.querySelector('#PHONE').classList.contains('btn-secondary')){
        //     document.querySelector('#PHONE').classList.add('btn-secondary')
        // }

        // document.querySelector('#'+mode).classList.remove('btn-secondary');
        // document.querySelector('#'+mode).classList.add('btn-primary');
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        // getOrderId();
        // getOrderItem();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    
    return (
        <>
            <div className='demo'>

            <div className="TPaymentPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">결제</p>
                        <button type="button" className="btn-clear btn-back" onClick={() => {navigate(-1);}}>
                            <img className="img-fluid" alt="뒤로가기"
                                src="https://www.flexdaycdn.net/public/images/ticket/back.png" />
                        </button>
                        <button className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4" type="button"
                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <img className="img-fluid" alt="메뉴"
                                src="https://www.flexdaycdn.net/public/images/ticket/menu.png" />
                        </button>

                        
                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-btnbox pt-4 px-4 text-end">
                                <button type="button" style={{position:'relative'}} className="btn-close opacity-100 shadow-none" data-bs-dismiss="offcanvas"
                                    aria-label="Close">
                                    <img className="img-fluid close" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/close.png" />
                                </button>
                            </div>
                            <div className="offcanvas-header p-4">
                                <img className="img-fluid logo" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/logo2.png" />
                                <div className="offcanvas-title" id="offcanvasNavbarLabel">
                                    양수정 님
                                </div>
                            </div>
                            <div className="offcanvas-body position-relative py-4-5">
                                <div className="navbar-nav justify-content-end">
                                    <div className="nav-item">
                                        <Link to={"/demo/userlogin"} className="nav-link active d-flex align-items-center pb-4" aria-current="page" href="#none">
                                            <div className="imgbox position-relative">
                                                <img className="img-fluid be img1" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/home_02.png" />
                                                <img className="img-fluid af img1" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/home.png" />
                                            </div>
                                            <span>메인</span>
                                        </Link>
                                    </div>
                                    <div className="nav-item">
                                        <a href={"/demo/paymentlist"} className="nav-link d-flex align-items-center pb-4">
                                            <div className="imgbox position-relative">
                                                <img className="img-fluid be img2" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/pay_list.png" />
                                                <img className="img-fluid af img2" alt=""
                                                src="https://www.flexdaycdn.net/public/images/ticket/pay_list_02.png" />
                                            </div>
                                            <span>구매내역</span>
                                        </a>
                                    </div>
                                </div>
                                <button type="button"
                                className="btn btn-default" onClick={ ()=> { localStorage.clear(); navigate("/demo2/userlogin") }}>로그아웃</button>
                            </div>
                        </div>
                        

                    </nav>

                    {
                        localStorage.getItem('accessToken') === null ?

                            <div className="PaymentBox my-4-5">
                                <div className="Recipient TicketBox pb-5 px-4-5">
                                    <p className="PageTit2 mb-3">
                                        <img className="img-fluid man" src="https://www.flexdaycdn.net/public/images/ticket/people.png"
                                            alt="" />
                                        식권 받는 분
                                    </p>
                                    <div className="Payment-Inputbox mb-2">
                                        <input type="text" id='recipientName' className="form-control InputType" placeholder="받는 분의 이름을 입력해주세요." />
                                    </div>
                                    <div className="Payment-Inputbox">
                                        <input type="number" id='recipientPhoneNumber' className="form-control InputType"
                                            placeholder="받는 분의 연락처를 입력해주세요. ('-'없이 숫자만 입력)" />
                                    </div>

                                </div>
                            </div>
                            :
                            <></>
                    }

                    <div className="PaymentBox my-4-5">
                        <div className="Check TicketBox pb-3 px-4-5">
                            <p className="PageTit2 mb-2">
                                <img className="img-fluid rice" src="https://www.flexdaycdn.net/public/images/ticket/rice.png"
                                    alt="" />
                                식권확인
                            </p>
                            <div className="align-items-center g-0 row">
                                <div className="col-5">
                                    <img className="img-fluid w-100"
                                        src="https://www.flexdaycdn.net/public/images/ticket/menu2_01.png" alt="" />
                                </div>
                                <div className="col">
                                    <p className="prd-tit">
                                        중식(정식)
                                    </p>
                                    <p className="prd-sub">
                                        
                                    잡곡밥 or 건강현미밥 / 바지락콩나물국 / 돈육고추볶음 / 두부구이 or 양념장 / 호박나물 / 그린샐러드 or
                                    펜네샐러드 / 오리엔탈 or 유자 드레싱 / 배추김치
                                
                                    </p>
                                    <p className="prd-price">
                                        <span>6,000</span>원
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="PaymentBox my-4-5">
                        <div className="Info TicketBox border-0 px-4-5">
                            <p className="PageTit2 mb-4">
                                <img className="img-fluid rice" src="https://www.flexdaycdn.net/public/images/ticket/pay.png"
                                    alt="" />
                                결제 정보
                            </p>
                            <div className="Total align-items-center d-flex justify-content-between mb-3 px-2">
                                <div className="tit">
                                    최종 결제 금액
                                </div>
                                <div className="price">
                                    <span>6,000</span>원
                                </div>
                            </div>
                            <div className="form-radiobox mb-2 position-relative">
                                <input className="CheckInput3 end-0 form-check-input position-absolute top-50 translate-middle"
                                    id="CheckPay1" type="radio" value="" name="CheckPay" onClick={ () => { setPayMode('CARD'); }} />
                                <label className="RadioLabel align-items-center d-flex" htmlFor="CheckPay1">
                                    <span>카드 결제</span>
                                </label>
                            </div>
                            <div className="form-radiobox mb-2 position-relative">
                                <input className="CheckInput3 end-0 form-check-input position-absolute top-50 translate-middle"
                                    id="CheckPay2" type="radio" value="" name="CheckPay" onClick={ () => { setPayMode('PHONE'); }} />
                                <label className="RadioLabel align-items-center d-flex" htmlFor="CheckPay2">
                                    <span>휴대폰 결제</span>
                                </label>
                            </div>
                            <div className="form-radiobox mb-2 position-relative">
                                <input className="CheckInput3 end-0 form-check-input position-absolute top-50 translate-middle"
                                    id="CheckPay3" type="radio" value="" name="CheckPay" onClick={ () => { setPayMode('ACCOUNT_TRANSFER'); }}/>
                                <label className="RadioLabel align-items-center d-flex" htmlFor="CheckPay3">
                                    <span>계좌 이체</span>
                                </label>
                            </div>

                            <div className="accordion ver3">
                                <div className="accordion-item border-0">
                                    <p className="accordion-headerd">
                                        <button className="accordion-button mt-4 px-2 py-0 collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                            aria-controls="collapseOne">
                                            주문 내용 및 결제 조건을 확인했으며 결제에 동의합니다.
                                        </button>
                                    </p>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            내용
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="Payment-Confirm px-4-5 mb-4">
                        <button type="button" id='submitBtn' className="btn btn-default" onClick={ () => {getOrderId()}}>
                            6,000원 결제하기
                        </button>
                    </div>

                </div>
            </div>

            </div>
        </>
    )
}