import React from "react";
import { useNavigate } from "react-router-dom";
import { css, styled } from "styled-components";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";
import { DefaultProfile } from "../../../../../components/ver1.5/assets/icons";

const Container = styled.div`
  display: flex;
  gap: 1.6rem;
  padding: 1.6rem;
  padding-bottom: 2.4rem;
  width: 100%;
  > * {
    flex-shrink: 0;
  }
`;

const ProfileImage = styled.img`
  width: 6.4rem;
  height: 6.4rem;
  overflow: hidden;
  border-radius: 3.2rem;
  border: 1px solid ${color.gray.d100};
  object-fit: cover;
  display: inline-block;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: calc(100% - 8.1rem);
`;

const EditBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0;
  border-radius: 1.2rem;
  border: 1px solid ${color.gray.d100};
  width: 100%;
  margin-top: 1rem;
`;

const Profile = ({ username, img, email, isLoggedIn }) => {
  const navigate = useNavigate();

  return (
    <Container>
      {img ? <ProfileImage src={img ?? null} /> : <DefaultProfile />}
      <TextContainer>
        <Text
          fontStyle={font.subtitle1}
          color={color.gray.d900}
          styles={css`
            span {
              font-family: "Pretendard Regular", sans-serif;
            }
          `}
        >
          {isLoggedIn ? (
            <>
              {username}
              <span>님</span>
            </>
          ) : (
            <>
              로그인
              <span>이 필요합니다</span>
            </>
          )}
        </Text>
        <Text>{email}</Text>
        <EditBtn
          onClick={() =>
            isLoggedIn
              ? navigate("/user/my/editProfile")
              : navigate("/user/login")
          }
        >
          <Text fontStyle={font.body2} color={color.gray.d600}>
            {isLoggedIn ? "내 정보 관리" : "로그인"}
          </Text>
        </EditBtn>
      </TextContainer>
    </Container>
  );
};

export default Profile;
