import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import axios from "axios";
import { AlertError, getApiUrl } from "../../../../function/common";
import { UserContext } from "../../../../contexts/UserContext";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Button from "../../../../components/ver1.5/atom/Button";
import IconNotice from "../../../../components/ver1.5/assets/img/iconNotice.svg";
import Text from "../../../../components/ver1.5/atom/Text";

const Container = styled.div`
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
`;

const Icon = styled.img`
  width: 7.2rem;
  height: 7.2rem;
`;

const SubTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CsContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  padding: 0.4rem 1.2rem;
  border-radius: 5rem;
  background: ${color.gray.d50};
`;

const Footer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  background: ${color.primary.white};
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const Fail = () => {
  const { accessToken } = useContext(UserContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }

  console.log(Parameters);

  function PgResult() {
    return new Promise(async function (resolve, reject) {
      let obj = {
        storeDomain: localStorage.getItem("storeDomain"),
        products: localStorage.getItem("products"),
        orderNumber: localStorage.getItem("orderId"),
      };
      console.log(obj);
      await axios
        .post(
          getApiUrl(
            "stores/" +
              localStorage.getItem("storeDomain") +
              "/products/" +
              localStorage.getItem("products") +
              "/orders/" +
              localStorage.getItem("orderId") +
              "/fail"
          ),
          obj,
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken),
          (axios.defaults.headers.common["ContentType"] = "application/json;")
        )
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          AlertError(error);
        });
    });
  }

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    PgResult();
    //데이터 로딩 완료
    setLoading(false);
  }, []);

  if (loading) return <></>;

  return (
    <Layout footerPadding={false}>
      <Container>
        <Icon src={IconNotice} />
        <Text
          styles={css`
            font-family: "Pretendard Bold", sans-serif;
            font-size: 3.2rem;
            line-height: 3rem;
            letter-spacing: -0.1rem;
          `}
          color={color.gray.d900}
        >
          결제 실패
        </Text>
        <SubTextContainer>
          <Text fontStyle={font.subtitle3} color={color.gray.d600}>
            결제에 실패하였습니다
          </Text>
          <Text fontStyle={font.subtitle3} color={color.gray.d600}>
            재시도를 하신 후에도 같은 오류가 발생한다면
          </Text>
          <Text fontStyle={font.subtitle3} color={color.gray.d600}>
            아래 고객센터로 문의주세요
          </Text>
        </SubTextContainer>
        <CsContainer>
          <Text fontStyle={font.body3} color={color.gray.d600}>
            고객센터
          </Text>
          <Text fontStyle={font.body3} color={color.gray.d700}>
            051-621-0203
          </Text>
        </CsContainer>
      </Container>
      <Footer>
        <Button
          text="홈으로"
          variant="primaryDark"
          onClick={() => navigate("/", { replace: true })}
        />
        <Button
          text="다시 결제하기"
          variant="primary"
          onClick={() =>
            navigate(`/user/${localStorage.getItem("lastVisitedStoreName")}`, {
              replace: true,
            })
          }
        />
      </Footer>
    </Layout>
  );
};

export default Fail;
