import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Btn from "./../../../components/common/Btn";
import { useNavigate } from "react-router-dom";
export default function StoreQuestions() {
  const navigate = useNavigate();

  return (
    <div className="StoreRegister border-none">
      <Container className="g-0">
        <div className="Inner m-auto py-2">
          <div className="StoreRegister-title mt-20vh">
            <p className="tit mb-1 d-flex align-center">
              <Image
                fluid
                className="me-2"
                src="https://www.flexdaycdn.net/public/images/static/store-register/smile.png"
              />
              사장님 이신가요?
            </p>
            <p className="sub mb-0">
              사장님 이시라면, 사업장 정보를 입력해주세요.
            </p>
          </div>

          <>
            <Button
              variant="default"
              className="mb-3"
              onClick={() => {
                navigate("/workplace/join");
              }}
            >
              예(사업장 등록하러가기)
            </Button>
            <Button
              variant="default"
              className="mb-3"
              onClick={() => {
                navigate("/workplace/join");
              }}
            >
              아니요. 직원입니다.
            </Button>
          </>
          {/* <div className="fixed-bottom mb-4 row">
          <div className="col-10 offset-1">
            <div className="PageInfoTxt2 p-2 p-3">
              <p>• 가맹점은 가맹점의 이름을 타인에게 빌려주어선 안됩니다.</p>
              <p>• 가맹점은 다른 가맹점의 명의를 빌려 거래 하시면 안됩니다.</p>
              <p className="mb-0">
                • 가맹점이 아닌자가 가맹점의 명의로 거래를 하시면 안됩니다.
              </p>
            </div>
          </div>
        </div> */}
        </div>
      </Container>
    </div>
  );
}
