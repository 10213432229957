import DemoLogin2 from "../pages/demo2/Login";
import DemoUserLogin2 from "../pages/demo2/UserLogin";
import DemoStoreMain2 from "../pages/demo2/StoreMain";
import DemoUserMain2 from "../pages/demo2/UserStore";
import DemoQR2 from "../pages/demo2/QR";
import DemoQRDetail2 from "../pages/demo2/QRDetail";
import DemoItemCreate2 from "../pages/demo2/ItemCreate";
import DemoStoreCalculate2 from "../pages/demo2/StoreCalculate";
import DemoSettleHistory2 from "../pages/demo2/SettleHistory";
import DemoSettleHistorySub2 from "../pages/demo2/SettleHistorySub";
import DemoSettleDetail2 from "../pages/demo2/SettleDetail";
import DemoPayment2 from "../pages/demo2/Payment";
import DemoPaymentList2 from "../pages/demo2/PaymentList";
import DemoSuccess2 from "../pages/demo2/Success";
import DemoPaymentCancel2 from "../pages/demo2/PaymentCancel";

const Demo2Routes = [
  { path: "login", element: <DemoLogin2 /> },
  { path: "userlogin", element: <DemoUserLogin2 /> },
  { path: "userstore/:storeDomain", element: <DemoUserMain2 /> },
  { path: "qr/:number", element: <DemoQR2 /> },
  { path: "qrdetail/:number", element: <DemoQRDetail2 /> },
  { path: "payment", element: <DemoPayment2 /> },
  { path: "paymentlist", element: <DemoPaymentList2 /> },
  { path: "success", element: <DemoSuccess2 /> },
  { path: "paymentcancel", element: <DemoPaymentCancel2 /> },
  { path: "store/:storeDomain", element: <DemoStoreMain2 /> },
  { path: "itemcreate", element: <DemoItemCreate2 /> },
  { path: "storecalculate", element: <DemoStoreCalculate2 /> },
  { path: "settlehistory", element: <DemoSettleHistory2 /> },
  { path: "settlehistorysub", element: <DemoSettleHistorySub2 /> },
  { path: "settledetail/:Mode", element: <DemoSettleDetail2 /> },
];

export default Demo2Routes;
