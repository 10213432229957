import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { AlertError, getApiUrl, PrintConsole } from '../../../function/common';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import Btn from '../../../components/common/Btn';

export default function WPSelect(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    function getStore() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get(
                getApiUrl('users/me/stores'),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    console.log('----------------------------------');
                    console.log(response);
                    if(response.status === 200){
                        setData(response.data);
                        console.log(data);
                    }
                }
            ).catch(function(error){
                navigate('/404');
                console.log('error');
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    async function getAuthority(){
        //권한
        axios.get(
            getApiUrl('users/me/stores/'+localStorage.getItem('storeDomain')), 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        ).then(
            function(response){
                if(response.status === 200){
                    if(response.data.type['code'] !== 1){
                        //사장외 권한 없슴
                        alert('권한이 없습니다.');
                        navigate(-1);
                    }
                }
            }
        ).catch(function(error){
            AlertError(error);
            navigate(-1);
        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //권한
        getAuthority();
        
        //함수 호출
        getStore();

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    console.log(data);

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="사업장 관리" />
                <Row className="MainSpace">
                    <Col xs={{ span:10, offset:1 }}>
                        <h5>현재 보유 중인</h5>
                        <h5>스토어 리스트 입니다.</h5>
                    </Col>
                </Row>
                <Row className='p-1 mt-3'>
                    <Col xs={{ span:10, offset:1 }}>
                        <div className="d-grid gap-1 mt-2">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ () => {navigate('/workplace/join') } }>
                                사업장 추가
                            </Button>
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col className='mt-3' xs={{ span:10, offset:1 }}>
                        <label>사업장 목록</label>
                        <Table>
                            <thead>
                                <tr className="text-center">
                                    <th>사업장명</th>
                                    <th>관리</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.data && data.data.length !== 0 ?
                                    data.data && data.data.map( row => (
                                        <tr className='AlignCenter'>
                                            <td>{row.storeName}</td>
                                            <td>
                                                <Button variant="primary" id='submitBtn' onClick={ (e) => { localStorage.setItem('storeDomain', row.storeDomain); navigate('/store/'+localStorage.getItem('storeDomain')) } }>
                                                    변경
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                :
                                <></>

                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            </Container>
        </>
    )
}