import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import Button from "../../../../components/ver1.5/atom/Button";
import EditProfileTopSection from "../components/organism/EditProfileTopSection";
import Divider from "../../../../components/ver1.5/atom/Divider";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { css, styled } from "styled-components";
import Text from "../../../../components/ver1.5/atom/Text";
import { MoreRightIcon } from "../../../../components/ver1.5/assets/icons";
import { useNavigate, useOutletContext } from "react-router-dom";
import { phoneNumberFormatter } from "../../../../components/ver1.5/utils";
import ActionListItem from "../components/molecule/ActionListItem";
import DefaultModal from "../../../../components/ver1.5/organism/DefaultModal";
import { UserContext } from "../../../../contexts/UserContext";
import { presetHeight } from "../../../../components/ver1.5/constants";
import axios from "axios";
import { getApiUrl } from "../../../../function/common";
import OneBtnModal from "../../../../components/ver1.5/organism/OneBtnModal";

const Row = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

const BtnContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${color.primary.white};
  padding: 1.6rem;
  padding-bottom: 3.4rem;
`;

const ItemContainer = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
`;

const LabelledBtn = ({ text, onClick }) => {
  return (
    <Row onClick={() => (onClick ? onClick() : null)}>
      <Text fontStyle={font.body1} color={color.gray.d700}>
        {text}
      </Text>
      <MoreRightIcon />
    </Row>
  );
};

const WithdrawalBtn = ({ navigate }) => {
  return (
    <Text
      fontStyle={font.caption1}
      color={color.gray.d400}
      marginLeft="1.6rem"
      marginRight="1.6rem"
      onClick={() => navigate("/user/my/withdrawal")}
    >
      회원탈퇴
    </Text>
  );
};

const EditProfile = () => {
  const { logout, accessToken } = useContext(UserContext);
  const { me, refetch } = useOutletContext();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState();
  const [nickname, setNickname] = useState("");
  const [logoutModal, setLogoutModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const actionList = [
    {
      text: "이메일",
      rightContent: (
        <LabelledBtn
          text={me.email ?? "-"}
          onClick={() => navigate("/user/my/editEmail")}
        />
      ),
    },
    {
      text: "전화번호",
      rightContent: (
        <LabelledBtn
          text={me.phoneNumber ? phoneNumberFormatter(me.phoneNumber) : "-"}
          onClick={() => navigate("/user/my/editPhone")}
        />
      ),
    },
    {
      text: "비밀번호 변경",
      rightContent: (
        <LabelledBtn onClick={() => navigate("/user/my/resetPw")} />
      ),
      onClickContainer: () => navigate("/user/my/resetPw"),
    },
    {
      text: "로그아웃",
      onClickContainer: () => setLogoutModal(true),
    },
  ];

  const onChangeImg = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const onSave = async () => {
    setSaveLoading(true);
    let saveDone = { nickname: false, profile: false };
    if (nickname && nickname !== me.nickName) {
      await axios
        .put(getApiUrl("users/me/nickname"), {
          nickName: nickname,
        })
        .then((res) => {
          if (res.status === 200) {
            setNickname(res.nickName);
            saveDone.nickname = true;
          }
        })
        .catch((e) => {
          if (e.response?.data) {
            alert(e.response.data?.message);
            setSaveLoading(false);
          }
        });
    } else {
      saveDone.nickname = true;
    }
    if (imageUrl && imageFile && imageUrl !== me.profileImageUrl) {
      const formData = new FormData();
      formData.append("image", imageFile);
      await axios
        .put(getApiUrl("users/me/image"), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            saveDone.profile = true;
          }
        })
        .catch((e) => {
          if (e.response?.data) {
            alert(e.response.data?.message);
            setSaveLoading(false);
          }
        });
    } else {
      saveDone.profile = true;
    }
    if (saveDone.nickname && saveDone.profile) {
      refetch();
      setSaveLoading(false);
      setSaveModal(true);
    }
  };

  const logOutUser = () => {
    logout();
    setLogoutModal(false);
    navigate("/user", { replace: true });
  };

  useEffect(() => {
    if (me) {
      setImageUrl(me.profileImageUrl);
      setNickname(me.nickName);
    }
  }, [me]);

  return (
    <Layout
      styles={css`
        padding-bottom: ${`calc(${presetHeight.bottomNav} + 3.4rem)`};
      `}
    >
      <EditProfileTopSection
        imageUrl={imageUrl}
        onChangeImg={onChangeImg}
        nickname={nickname}
        setNickname={setNickname}
      />
      <Divider direction="horizontal" width={"1rem"} color={color.gray.d50} />
      <ItemContainer>
        {actionList.map((item, index) => {
          return (
            <ActionListItem
              key={index}
              fontStyle={font.caption1}
              text={item.text}
              rightContent={item.rightContent}
              containerStyle={css`
                padding: 1.6rem 0;
              `}
              onClickContainer={() =>
                item.onClickContainer ? item.onClickContainer() : null
              }
            />
          );
        })}
      </ItemContainer>
      <WithdrawalBtn navigate={navigate} />
      <BtnContainer>
        <Button text="저장하기" onClick={onSave} loading={saveLoading} />
      </BtnContainer>
      {logoutModal && (
        <DefaultModal
          description={"로그아웃 하시겠습니까?"}
          cancelText={"취소"}
          actionText={"로그아웃"}
          onCancel={() => setLogoutModal(false)}
          onAction={() => logOutUser()}
        />
      )}
      {saveModal && (
        <OneBtnModal
          text={"저장이 완료되었습니다."}
          onAction={() => {
            setSaveModal(false);
          }}
        />
      )}
    </Layout>
  );
};

export default EditProfile;
