import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { PrintConsole } from '../../../function/common';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function DragDrop(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [fruitItems, setFruitItems] = useState([
        { id: "1", Title: "Apple" },
        { id: "2", Title: "Mango" },
        { id: "3", Title: "Peach" },
        { id: "4", Title: "Grapes" },
        { id: "5", Title: "BlueBerry" },
        { id: "6", Title: "StrawBerry" }
    ]);

    const onDragEnd = (result) => {
        const newItems = Array.from(fruitItems);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setFruitItems(newItems);
    };
    
    const grid = 8;
    
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
    
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "lightblue",
    
        // styles we need to apply on draggables
        ...draggableStyle
    });
    
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "grey",
        padding: grid,
        width: 250
    });

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>
    
    


    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {fruitItems.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            >
                                            {item.Title}
                                            </div>
                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>
                    </Col>
                </Row>
            </Container>
        </>
    )
}