import {
  Container,
  Row,
  Button,
  Col,
  Form,
  Image,
  Modal,
} from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import sha256 from "sha256";

import Btn from "../../../components/common/Btn";
import { AlertError, EmailCheck, getApiUrl } from "../../../function/common";
import { Typography } from "./Join";
import { detectMobile, emailList } from "../../../components/common/constants";

export default function Certification() {
  const navigate = useNavigate();

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }

  const appPush = useRef();
  const phoneText = useRef();
  const email = useRef();
  const phone = useRef();
  const mail = useRef();
  const token_version_id = useRef();
  const enc_data = useRef();
  const integrity_value = useRef();
  const isAcceptedInessentialPrivacy = useRef();

  useEffect(() => {
    if (Parameters.length !== 0) {
      let obj = {
        token_version_id: Parameters[0].token_version_id,
        enc_data: Parameters[1].enc_data,
        integrity_value: Parameters[2].integrity_value,
      };
      console.log(obj);
      axios
        .post(
          getApiUrl("auth/self/result"),
          obj,
          (axios.defaults.headers.common["ContentType"] = "application/json;")
        )
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            console.log(response.data);
            let obj = JSON.parse(response.data.customData);
            console.log(obj.indexOf("s4"));

            appPush.current = obj.indexOf("s5") !== -1 ? true : false;
            phoneText.current = obj.indexOf("s6") !== -1 ? true : false;
            email.current = obj.indexOf("s7") !== -1 ? true : false;
            phone.current = obj.indexOf("s8") !== -1 ? true : false;
            mail.current = obj.indexOf("s9") !== -1 ? true : false;
            isAcceptedInessentialPrivacy.current =
              obj.indexOf("s4") !== -1 ? true : false;
            token_version_id.current = Parameters[0].token_version_id;
            enc_data.current = Parameters[1].enc_data;
            integrity_value.current = Parameters[2].integrity_value;
          } else {
            alert("본인인증에 실패하였습니다.");
            navigate("/404");
          }
        })
        .catch(function (error) {
          AlertError(error);
          if (window.location.href.indexOf("/store/") !== -1) {
            navigate("/store/login");
          } else {
            navigate("/user/login");
          }
        });
    }
  }, []);

  const [validation, setValidation] = useState({
    loginId: false,
    // password          : false,
    // passwordCk        : false,
    // email             : false
  });

  const [data, setData] = useState({
    loginId: "",
    password: "",
    passwordCk: "",
    email: "",
  });
  const [customAddressOn, setCustomAddressOn] = useState(false);
  const [doneModal, setDoneModal] = useState(false);

  const onChangeData = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    if (e.target.id === "email") {
      const select = document.getElementById("emailAddressSelect");
      if (select.value && select.value !== "1") {
        setData({
          ...data,
          [e.target.id]: e.target.value + "@" + select.value,
        });
      } else {
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      setData({
        ...data,
        [e.target.id]: e.target.value,
      });
    }

    console.log(data);
  };

  const onLoginId = (e) => {
    //아이디 중복체크
    let obj = {
      loginId: data.loginId,
    };
    axios
      .post(
        getApiUrl("auth/login-id"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data.loginIdExist);
          if (response.data.loginIdExist === false) {
            setValidation({ ...validation, loginId: true });
            e.target.parentElement
              .querySelector("div")
              .classList.remove("invalid-feedback");
            e.target.parentElement
              .querySelector("div")
              .classList.add("valid-feedback");
          } else {
            setValidation({ ...validation, loginId: false });
            e.target.parentElement
              .querySelector("div")
              .classList.remove("valid-feedback");
            e.target.parentElement
              .querySelector("div")
              .classList.add("invalid-feedback");
            e.target.parentElement.querySelector("div").textContent =
              "중복된 ID 입니다.";
          }
        }
      })
      .catch(function (error) {
        setValidation({ ...validation, loginId: false });
        e.target.parentElement
          .querySelector("div")
          .classList.remove("valid-feedback");
        e.target.parentElement
          .querySelector("div")
          .classList.add("invalid-feedback");
        e.target.parentElement.querySelector("div").textContent =
          error.response.data.errorDetails[0].reason;
      });
  };

  const onEmail = (e) => {
    if (!EmailCheck(data.email)) {
      e.target.parentElement.nextSibling.classList.remove("valid-feedback");
      e.target.parentElement.nextSibling.classList.add("invalid-feedback");
      e.target.parentElement.nextSibling.textContent = "잘못된 이메일 입니다.";
      return false;
    } else {
      e.target.parentElement.nextSibling.classList.remove("invalid-feedback");
      e.target.parentElement.nextSibling.classList.add("valid-feedback");
    }
  };

  const onChangeEmailSelect = (e) => {
    const { value } = e.target;
    if (data.email.length !== 0) {
      if (value !== null && parseInt(value) !== 1) {
        setData((prev) => ({
          ...prev,
          email: prev.email.split("@")[0] + "@" + value,
        }));
        setCustomAddressOn(false);
      } else {
        if (parseInt(value) === 1) {
          setCustomAddressOn(true);
        }
      }
    }
  };

  useEffect(() => {
    if (customAddressOn) {
      setData((prev) => ({
        ...prev,
        email: prev.email.split("@")[0],
      }));
    }
  }, [customAddressOn]);

  async function submit() {
    // console.log(validation);
    if (validation.loginId !== true) {
      alert("아이디를 확인해주세요.");
      document.getElementById("loginId").focus();
      return false;
    }
    if (data.password.length < 4) {
      console.log(data.password + " / " + data.password.length);
      alert("비밀번호를 확인해주세요.");
      document.getElementById("password").focus();
      return false;
    }
    if (data.passwordCk.length < 4) {
      alert("비밀번호 확인을 확인해주세요.");
      document.getElementById("passwordCk").focus();
      return false;
    }
    if (data.email.length < 4) {
      alert("이메일 주소를 확인해주세요.");
      document.getElementById("email").focus();
      return false;
    }

    if (data.password !== data.passwordCk) {
      alert("비밀번호가 다릅니다.");
      document.getElementById("password").focus();
      return false;
    }

    if (!EmailCheck(data.email)) {
      alert("이메일 양식이 아닙니다.");
      return false;
    }

    let obj;
    let tmpData = {
      loginId: data.loginId,
      password: sha256(data.password),
      email: data.email,
      isAcceptedInessentialPrivacy: isAcceptedInessentialPrivacy.current,
      alarm: {
        appPush: appPush.current,
        phoneText: phoneText.current,
        email: email.current,
        phone: phone.current,
        mail: mail.current,
      },
      selfAuth: {
        token_version_id: token_version_id.current,
        enc_data: enc_data.current,
        integrity_value: integrity_value.current,
      },
    };

    console.log(tmpData);

    // console.log(file);
    // obj = new Blob( [JSON.stringify(tmpData)], { type: 'application/json'} );

    var formData = new FormData();
    formData.append("signupRequest", obj);
    // formData.append('logo', file);

    axios
      .post(
        getApiUrl("users"),
        tmpData
        // formData, {
        // headers: {
        // "Content-Type": "multipart/form-data",
        // },
        // }
      )
      .then(function (response) {
        if (response.status === 200) {
          setDoneModal(true);
        }
      })
      .catch(function (error) {
        AlertError(error);
        // navigate('/404');
      });
  }

  return (
    <>
      <Btn type="TITLE" title="회원가입" titleCanClose />
      <Container className="SignupPage responsiveInner">
        <Row className="SignUp-title">
          <Col className="mt-5">
            <Typography
              size={"20px"}
              weight={"var(--Medium)"}
              color={"var(--ColorTypeB)"}
            >
              회원가입 단계가
              <br />
              거의 끝났어요&nbsp;
              <Image
                src={
                  "https://www.flexdaycdn.net/public/images/static/login/signup_emoji2.png"
                }
                width={22}
                className="img-fluid"
              />
            </Typography>
          </Col>
          <Typography
            size={"14px"}
            weight={"var(--Regular)"}
            color={"var(--ColorTypeC)"}
          >
            아래 내용을 입력해주세요.
          </Typography>
        </Row>

        <Row>
          <Col className="mt-3">
            <Form.Group className="xs-12" controlId="loginId">
              <Form.Control
                type="text"
                className="InputType"
                onBlur={onLoginId}
                placeholder="(필수) 아이디"
                onChange={onChangeData}
                required
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mt-3">
            <Form.Group className="xs-12" controlId="password">
              <Form.Control
                type="password"
                className="InputType"
                onBlur={() => {
                  console.log("password");
                }}
                placeholder="(필수) 비밀번호"
                onChange={onChangeData}
                required
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mt-3">
            <Form.Group className="xs-12" controlId="passwordCk">
              <Form.Control
                type="password"
                className="InputType"
                onBlur={() => {
                  console.log("passwordCk");
                }}
                placeholder="(필수) 비밀번호 확인"
                onChange={onChangeData}
                required
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mt-3">
            <Form.Group
              className="xs-12 d-flex gap-10px align-items-center"
              controlId="email"
            >
              <Form.Control
                type="text"
                className="InputType"
                placeholder="(필수) 이메일"
                onChange={onChangeData}
                required
              />
              <Typography color={"var(--ColorTypeC)"} weight={"var(--Regular)"}>
                @
              </Typography>
              <Form.Select
                onChange={onChangeEmailSelect}
                defaultValue={""}
                id="emailAddressSelect"
                onBlur={(e) => {
                  if (e.target.value !== "1") {
                    onEmail(e);
                  }
                }}
              >
                <option value="" disabled hidden>
                  주소 선택
                </option>
                {emailList.map((address, index) => (
                  <option value={address} key={index}>
                    {address}
                  </option>
                ))}
                <option value="1">직접입력</option>
              </Form.Select>
            </Form.Group>
            {!customAddressOn && (
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            )}
          </Col>
        </Row>

        {customAddressOn && (
          <Row>
            <Col className="mt-3">
              <Form.Group className="xs-12" controlId="customAddress">
                <Form.Control
                  type="text"
                  className="InputType"
                  onBlur={(e) => onEmail(e)}
                  placeholder="직접입력"
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      email: prev.email.split("@")[0] + "@" + e.target.value,
                    }))
                  }
                />
              </Form.Group>
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </Col>
          </Row>
        )}

        <Row className={`${!detectMobile() ? "fixed-bottom" : ""} py-5`}>
          <Col className="d-grid" xs={{ span: 10, offset: 1 }}>
            <Button
              variant="primary"
              size="lg"
              onClick={submit}
              style={{
                backgroundColor: "var(--ColorTypeB)",
                borderColor: "var(--ColorTypeB)",
              }}
            >
              회원가입
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal show={doneModal} centered size="sm">
        <Modal.Header className="pt-4-5">
          <Modal.Title style={{ textAlign: "center" }}>
            회원가입이 완료되었습니다!
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            className="btn-clear w-100 btn-modal-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              if (window.location.href.indexOf("/store/") !== -1) {
                navigate("/store/login");
              } else {
                navigate("/user/login");
              }
            }}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
