
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { GetAuthorize, GetUserMe } from './../../api/ApiGetUser';
import { PrintConsole, sleep } from '../../function/common';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import { getImgUrl } from './../../function/common';
import CheckBox from '../../components/common/CheckBox';
import ListGroupH from '../../components/ListGroupH';

import './PosLogin.css';

export default function PosLogin(){
    const navigate = useNavigate();

    const [account, setAccount] = useState({
        Id: "",
        Password: "",
        clientId: '10001'
    });

    const onChangeAccount = (e) => {
        //console.log(e.target.value);
        setAccount({
          ...account,
          [e.target.id]: e.target.value,
        });
    };

    useEffect( () => {
        if( localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null ){
            //토큰 있을때 추가 작업할 일 있을 시 사용
            navigate('/pos/main');
        }else{
            //토큰 없을때
        }
    })

    const loginBtn = async () => {

        try{
            document.getElementById('submitBtn').setAttribute("disabled","true");
            await GetAuthorize(account);
                        
            sleep(500);
            PrintConsole("localStorage : " + localStorage.getItem('accessToken'));
            PrintConsole("localStorage : " + localStorage.getItem('refreshToken'));
            
            let resUserMe = await GetUserMe(account);
            
            sleep(500);
            try{
                localStorage.setItem('domainType', resUserMe.data.domainType);
                localStorage.setItem('name', resUserMe.data.name);
                localStorage.setItem('myStores' , JSON.stringify(resUserMe.data.myStores[0]));
                localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);

                navigate('/pos/main');

            }catch(e){
                console.log('mystore 없음 스텝 or 가입안함');
                localStorage.setItem('storeDomain', null);
                document.getElementById('submitBtn').removeAttribute("disabled");
            }

            console.log(localStorage.getItem('storeDomain'));
            
            

            // if(resUserMe.data.domainType === 'USER'){
                
            // }else if(resUserMe.data.domainType === 'STORE'){
            //     if(resUserMe.data.shouldAskWhetherCEO === true){
            //         navigate('/workplace/join');
            //     }else{
            //         localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
            //         // navigate('/store/storemain?storeurl='+ resUserMe.data.myStores[0].storeDomain);
            //         navigate('/store/'+ resUserMe.data.myStores[0].storeDomain );
            //     }
            // }else{
            //     navigate('/404');
            // }
        }catch(e){
            document.getElementById('submitBtn').removeAttribute("disabled");
        }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter') {
            loginBtn();
        }
    }

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
            <div className='LogoImg text-center'>
                <img src={getImgUrl("images/pos/login_logo.png")} alt="FLEX"/>
            </div>
            <div className='Inner m-auto p-2'>
                <div className='LoginBox'>
                    <div className='row'>
                        <input type="text" id='Id' name='Id' className="form-control InputType" placeholder="아이디를 입력해주세요." onChange={onChangeAccount} onKeyPress={onKeyPress}/>
                    </div>
                    <div className='row'>
                        <input type="password" id='Password' name='Password' className="form-control InputType" placeholder="비밀번호를 입력해주세요." onChange={onChangeAccount} onKeyPress={onKeyPress}/>
                    </div>

                    <button type="button" className="btn btn-primary" id='submitBtn' onClick={ (e) => { loginBtn(); } }>로그인</button>

                </div>
            </div>

            <Container>
                
                
                
                {/* <Row>
                    <Col className='mt-3'>
                        <CheckBox controlId="AutoLogin" label="자동 로그인"/> 
                    </Col>
                </Row> */}

                {/* <Row>
                    <Col xs={{ span:10, offset:1 }}>
                        <div className="d-grid gap-1 mt-5">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ (e) => { loginBtn(); } }>
                                로그인
                            </Button>
                        </div>
                    </Col>
                </Row> */}

            </Container>
        </>
    )
}