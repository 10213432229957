import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { getImgUrl } from "../../../function/common";
import { styled } from "styled-components";

const Container = styled.div`
  @media screen and (max-width: 768px) {
    .col {
      .btn-secondary {
        height: fit-content !important;
        font-size: 1rem !important;
        padding: 0.8rem 0 !important;
      }
    }
  }
`;

const keypadInfo = [
  ["1", "2", "3"],
  ["4", "5", "6"],
  ["7", "8", "9"],
  [
    { label: "전체삭제", value: "CLEAR" },
    "0",
    {
      label: (
        <img
          className="DelIcon"
          src={getImgUrl("images/pos/delet.png")}
          alt="DEL"
        />
      ),
      value: "DEL",
    },
  ],
];

const Key = ({ value, onClick }) => {
  const isString = typeof value === "string";
  const targetValue = isString ? value : value.value;
  const targetLabel = isString ? value : value.label;
  return (
    <Col>
      <Button
        variant="secondary"
        size="lg"
        onClick={() => (onClick ? onClick(targetValue) : null)}
      >
        {targetLabel}
      </Button>
    </Col>
  );
};

const KeyLine = ({ keys, onClick }) => {
  return (
    <Row>
      {keys.map((key, index) => (
        <Key
          key={index}
          value={key}
          onClick={(value) => (onClick ? onClick(value) : null)}
        />
      ))}
    </Row>
  );
};

const KeyPad = ({ onClick }) => {
  return (
    <Container className="keypad_box">
      {keypadInfo.map((line, index) => (
        <KeyLine keys={line} onClick={onClick} key={index} />
      ))}
    </Container>
  );
};

export default KeyPad;
