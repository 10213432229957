
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { getApiUrl, PrintConsole } from '../../../function/common';
import QrCode from './../../../components/common/QrCode';
import { Container, Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
import { setAccessToken } from './../../../api/ApiWebView';

export default function StoreQRCode(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    const [store, setStore] = useState();
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        console.log(e.target.value);
        setStore({
            ...store,
            [e.target.id]: e.target.value,
        });
        // PrintConsole(data);
    };

    function getStore() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain') ),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    setStore(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        getStore();

        try{
            if (localStorage.getItem('domainType') === 'STORE'){
                //webview에 토큰값, 도메인 전달
                setAccessToken(localStorage.getItem('accessToken'));
            }
        }catch(e){
            console.log(e);
        }
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="QR코드" />
                <Row>
                    <Col xs={{span:10, offset:1}} className='mt-5'>
                        <QrCode QRCodeUrl={store && store.domain} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span:10, offset:1}} className='d-grid mt-5'>
                        <a className='btn btn-primary' href={getApiUrl('common/qrcode/download?data='+ process.env.REACT_APP_BASEURL+'user/'+localStorage.getItem('storeDomain')+'&size=1000')}>
                        QR코드 다운로드
                        </a>
                    </Col>
                </Row>


            </Container>
        </>
    )
}