import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState, useRef } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { AlertError, EmailCheck, getApiUrl, PrintConsole } from '../../../function/common';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
import { setAccessToken } from '../../../api/ApiWebView';
import { getImgUrl } from './../../../function/common';

export default function StoreAccount(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [store, setStore] = useState();
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        console.log(e.target.value);
        setStore({
            ...store,
            [e.target.id]: e.target.value,
        });
        // PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = []; 
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
        alert('로그인이 필요한 서비스 입니다.');
        navigate('/store/login');
    }

    const fileUpload2 = useRef();
    const [file2, setFile2] = useState([]);

    const onClickUpload2 = () => {
        fileUpload2.current.click();
    }

    const onChangeImg2 = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile2(uploadFile);

            document.getElementById('PreView2').style.display='block';
            document.getElementById('PreViewImg2').src = URL.createObjectURL(e.target.files[0]);
        }
    }

    const [bank, setBank] = useState();
    function getBank() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('common/code?name=BANK_CODE') ).then(
                function(response){
                    // console.log(response);
                    setBank(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
            // reject(new Error("Request is failed"));
        });
    };

    async function submit(){  

        document.getElementById('submitBtn').setAttribute("disabled","true");

        if(!EmailCheck(document.getElementById('taxEmail').value)){
            alert('이메일 양식이 아닙니다.');
            document.getElementById('submitBtn').removeAttribute("disabled");
            return false;
        }

        let e = document.getElementById("bank");

        let obj = {
            'bank' : e.options[e.selectedIndex].value,
            'accountNumber' : document.getElementById('accountNumber').value,
            'accountOwner' : document.getElementById('accountOwner').value,
            'email' : document.getElementById('taxEmail').value
        }
        obj = new Blob( [JSON.stringify(obj)], { type: 'application/json'} );

        var formData = new FormData();
        formData.append('bankCopy', file2);
        formData.append('settleAccount', obj);

        axios.put(
            getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/settle-account'), 
            formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization' : 'Bearer ' + localStorage.getItem('accessToken')
                },
            }
        ).then(
            function(response){
                if(response.status === 200){
                    alert('수정이 완료되었습니다.');
                    navigate('/store/'+localStorage.getItem('storeDomain'));
                }
            }
        ).catch(function(error){
            AlertError(error);
            // navigate('/404');
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    function getStore() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/settle-account' ),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
            ).then(
                function(response){
                    console.log(response.data);
                    setStore(response.data);
                    console.log(store);
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        getBank();

        getStore();

        try{
            setAccessToken();
        }catch(e){}

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    console.log(store)

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="정산계좌 수정"/>

                <Row className='p-1 mt-5'>
                    <Col xs={{ span:5, offset:1 }} className='mt-3'>
                        <Form.Group className="xs-12" controlId="logo">
                            <Form.Label>계좌번호 사본 <span className='PointText'>*</span></Form.Label>
                            <Col>
                                <input type="file" style={{display : 'none'}} ref={fileUpload2} onChange={onChangeImg2}  />
                                <Image className='w-100 DefaultShadow' fluid src={getImgUrl('images/icon/button_upload_add.png')} onClick={onClickUpload2}/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col xs={{span:5}} className='mt-3' id='PreView2' style={{display:'none'}}>
                        <Form.Group className="xs-12" controlId="logo">
                            <Form.Label>업로드 프리뷰</Form.Label>
                            <Col>
                                <Image className='w-100 DefaultShadow' id='PreViewImg2' fluid src='' />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='p-1 mt-2'>
                    <Col xs={{ span:10, offset:1 }}>
                        <Form.Group controlId="bank">
                            <Form.Select size="lg" onChange={onChangeData} key={store && store.bank['code']} defaultValue={store && store.bank['code']}>
                            {
                                bank && bank.map( row => (
                                    <option key={row.code} value={row.code}>{row.korName}</option>
                                ) )
                            }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='p-1'>
                    <Col xs={{ span:10, offset:1 }}>
                        <Form.Group className="xs-12" controlId="accountNumber">
                            <Form.Label>계좌번호 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="number" defaultValue={store && store.accountNumber || ''} placeholder="계좌번호 입력" onChange={onChangeData} onKeyDown={(e)=> e.key === 'e' && e.preventDefault()}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='p-1'>
                    <Col xs={{ span:10, offset:1 }}>
                        <Form.Group className="xs-12" controlId="accountOwner">
                            <Form.Label>예금주명 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" defaultValue={store && store.accountOwner || ''} placeholder="예금주" onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col className='mt-3' xs={{span:10, offset:1}}>
                        <Form.Group className="xs-12" controlId="taxEmail">
                            <Form.Label>이메일 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="email" onBlur={() => {console.log('email')}}  placeholder="이메일 주소를 입력해 주세요." onChange={onChangeData} defaultValue={store && store.taxEmail} />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='p-1'>
                    <Col xs={{ span:10, offset:1 }}>
                        <div className="d-grid gap-1 mt-2">
                            <Button variant="primary" size="lg" id='submitBtn' onClick={ submit }>
                                정산계좌 변경
                            </Button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}