import React from "react";
import { useNavigate } from "react-router-dom";

export function getRedirctUrl(url = "") {
  return process.env.REACT_APP_BASEURL + url;
}

export function getApiUrl(api = "") {
  return process.env.REACT_APP_API + api;
  // return "https://api.flexday.kr/" + api;
  // return 'https://dev-api.flexday.kr/' + api;
}

export function getImgUrl(url) {
  return process.env.REACT_APP_IMGURL + url;
  // return "https://dev.flexdaycdn.net/public/" + url;
  // return "https://www.flexdaycdn.net/public/" + url;
}
export function LocationUrl(url) {
  const navigate = useNavigate();
  navigate(url);
}

//await timeout(1000);
export function LoginCheck() {
  if (
    localStorage.getItem("accessToken") !== undefined &&
    localStorage.getItem("accessToken") !== null
  ) {
    return "LOGIN";
  } else {
    return "VIEWER";
  }
}

export function PrintConsole(log) {
  if (process.env.NODE_ENV !== "production") {
    console.log(log);
  }
}

export function getSubDomain() {
  if (window.location.host.split(".")[0] === "admin") {
    return "admin";
  } else if (window.location.host.split(".")[0] === "store") {
    return "store";
  } else if (window.location.host.split(".")[0] === "user") {
    return "user";
  } else {
    return "localhost";
  }
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setCommas(x) {
  if (isNaN(x)) {
    return "";
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export function getNewlines(html) {
  if (!html) return null;

  const lines = html.split("\\n");

  if (lines.length === 0) {
    return html;
  }

  return lines.map((line, index) =>
    React.createElement(
      "span",
      {
        key: index,
      },
      line,
      React.createElement("br")
    )
  );
}

export function includeStr(str, check) {
  if (str.includes(check)) {
    return true;
  } else {
    return false;
  }
}

export function makeAddress(item) {
  if (!item) {
    return;
  }

  var name = item.name,
    region = item.region,
    land = item.land,
    isRoadAddress = name === "roadaddr";

  var sido = "",
    sigugun = "",
    dongmyun = "",
    ri = "",
    rest = "";

  if (hasArea(region.area1)) {
    sido = region.area1.name;
  }

  if (hasArea(region.area2)) {
    sigugun = region.area2.name;
  }

  if (hasArea(region.area3)) {
    dongmyun = region.area3.name;
  }

  if (hasArea(region.area4)) {
    ri = region.area4.name;
  }

  if (land) {
    if (hasData(land.number1)) {
      if (hasData(land.type) && land.type === "2") {
        rest += "산";
      }

      rest += land.number1;

      if (hasData(land.number2)) {
        rest += "-" + land.number2;
      }
    }

    if (isRoadAddress === true) {
      if (checkLastString(dongmyun, "면")) {
        ri = land.name;
      } else {
        dongmyun = land.name;
        ri = "";
      }

      if (hasAddition(land.addition0)) {
        rest += " " + land.addition0.value;
      }
    }
  }

  return [sido, sigugun, dongmyun, ri, rest].join(" ");
}

export function hasArea(area) {
  return !!(area && area.name && area.name !== "");
}

export function hasData(data) {
  return !!(data && data !== "");
}

export function checkLastString(word, lastString) {
  return new RegExp(lastString + "$").test(word);
}

export function hasAddition(addition) {
  return !!(addition && addition.value);
}

export function maxLengthCheck(object) {
  if (object.value.Length > object.max.Length)
    object.value = object.value.slice(0, object.max.Length);
}

export function EmailCheck(text) {
  var regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

  if (text.match(regExp) != null) {
    return true;
  } else {
    return false;
  }
}
export function EngNumCheck(text) {
  console.log(text);
  var regExp = /^[A-Za-z0-9]+$/;
  if (text.match(regExp) != null) {
    return false;
  } else {
    return true;
  }
}

export function encodeFileToBase64(fileBlob) {
  const reader = new FileReader();
  reader.readAsDataURL(fileBlob);
  return new Promise((resolve) => {
    reader.onload = () => {
      console.log(reader.result);
      return reader.result;
    };
  });
}

export function timer(id, time) {
  var timer = time;
  var min = "";
  var sec = "";

  var x = setInterval(function () {
    min = parseInt(timer / 60);
    sec = timer % 60;

    document.getElementById(id).innerHTML = min + "분" + sec + "초";
    timer--;

    if (timer < 0) {
      clearInterval(x);
    }
  }, 1000);

  return x;
}

export function AlertError(error) {
  let detailMessage;
  let errorDetails = error.response.data.errorDetails;
  if (errorDetails && errorDetails.length > 0) {
    detailMessage = errorDetails[0].reason;
  } else {
    detailMessage = error.response.data.message;
  }
  alert(detailMessage);
}

export function getTextLength(str) {
  var len = 0;
  for (var i = 0; i < str.length; i++) {
    if (escape(str.charAt(i)).length == 6) {
      len++;
    }
    len++;
  }
  return len;
}

export const phoneNumberConvert = (phoneNumber) => {
  return phoneNumber
    .replace(/[^0-9]/g, "")
    .replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, "$1-$2-$3");
};
export const securePhoneNumber = (phoneNumber) => {
  return "010-****-" + phoneNumber.split("-")[2];
};

export const gradeConvert = (grade) => {
  if (grade === 0) {
    return grade + ".0";
  } else if (grade) {
    if (!(grade % 1)) {
      return grade + ".0";
    } else {
      return "" + grade;
    }
  } else {
    return "-";
  }
};
