import React, { useState } from "react";
import SwiperComponent from "../../../../components/ver1.5/organism/SwiperComponent";
import { color } from "../../../../components/ver1.5/styles/theme";
import { css, styled } from "styled-components";
import Text from "../../../../components/ver1.5/atom/Text";

const Container = styled.div`
  position: relative;
`;

const BannerImage = styled.div`
  width: calc(100%);
  /* height: 14.3rem; */
  /* border-radius: 1.6rem; */
  aspect-ratio: 1;
  margin: 0 auto;
  ${(p) =>
    p.backgroundColor &&
    css`
      background-color: p.backgroundColor;
    `}
`;

const IndexChip = styled.div`
  width: fit-content;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 40px;
  position: absolute;
  bottom: 1.2rem;
  right: 2.8rem;
  z-index: 10;
  padding: 0.4rem 0.75rem;
`;

const BannerItem = ({ url, text, backgroundColor }) => {
  return (
    <BannerImage
      as={url ? "img" : "div"}
      src={url ? url : null}
      backgroundColor={backgroundColor}
    >
      {text && (
        <Text
          styles={css`
            font-family: "twayair";
            font-size: 2.6rem;
          `}
        >
          {text.replace("\n", "\u000A")}
        </Text>
      )}
    </BannerImage>
  );
};

const BannerSwiper = ({ data = [] }) => {
  const [current, setCurrent] = useState(1);
  return (
    <Container>
      <SwiperComponent
        data={data}
        centeredSlides
        loop
        swiperComponent={(item, index) => <BannerItem {...item} key={index} />}
        swiperStyle={{
          marginBottom: "3.2rem",
        }}
        onSlideChange={(e) => setCurrent(e.realIndex + 1)}
      />
      <IndexChip>
        <Text
          color="#D6D6D6"
          styles={css`
            font-family: "Pretendard Medium";
            font-size: 1.1rem;
          `}
        >
          <Text elementType="span" color={"#fff"}>
            {current}
          </Text>
          {" / " + (data.length > 0 ? data.length : 1)}
        </Text>
      </IndexChip>
    </Container>
  );
};

export default BannerSwiper;
