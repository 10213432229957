import React from "react";
import { css, styled } from "styled-components";
import {
  color,
  elevation,
  font,
} from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";
import {
  phoneNumberConvert,
  securePhoneNumber,
  setCommas,
} from "../../../../../function/common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 2.4rem 1.6rem 3.6rem;
`;

const Row = styled.div`
  display: flex;
  align-items: ${(p) => (p.$alignItems ? p.$alignItems : "center")};
  ${(p) =>
    p.$gap &&
    css`
      gap: ${p.$gap};
    `}
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
    ${(p) => p.$styles};
`;

const Card = styled.div`
  border-radius: 1.2rem;
  box-shadow: ${elevation.d2};
  background: ${color.primary.white};
`;

const CardHeader = styled.div`
  display: flex;
  padding: 1.6rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color.gray.d100};
`;

const CardContent = styled.div`
  display: flex;
  padding: 1.6rem;
  padding-bottom: 2.4rem;
  flex-direction: column;
`;

const Image = styled.img`
  width: 8rem;
  min-height: 4rem;
  aspect-ratio: 34 / 21;
  border-radius: 0.4rem;
  overflow: hidden;
  object-fit: cover;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const Logo = styled.img`
  width: 2.4rem;
  aspect-ratio: 1;
  overflow: hidden;
  object-fit: contain;
  border-radius: 1.2rem;
  border: 1px solid ${color.gray.d100};
`;

const HistoryDetailItem = ({
  orderedAt,
  recipient,
  imageUrl,
  logoUrl,
  storeName,
  name,
  price,
  orderNumber,
  status,
}) => {
  return (
    <Container>
      <Row $gap={"0.8rem"}>
        <Text fontStyle={font.body2} color={color.gray.d700}>
          주문일
        </Text>
        <Text fontStyle={font.body2} color={color.gray.d700}>
          {orderedAt ? "20" + orderedAt : "-"}
        </Text>
      </Row>
      <Card>
        <CardHeader>
          <Row $gap={"0.4rem"}>
            <Text fontStyle={font.body2} color={color.gray.d900}>
              To.{recipient?.name}
            </Text>
            <Text fontStyle={font.caption2} color={color.gray.d600}>
              (
              {recipient?.phoneNumber
                ? securePhoneNumber(phoneNumberConvert(recipient.phoneNumber))
                : "-"}
              )
            </Text>
          </Row>
        </CardHeader>
        <CardContent>
          <Row $gap={"1.2rem"} $alignItems={"flex-start"}>
            <Image src={imageUrl} />
            <TextContainer>
              <Row $gap={"0.4rem"}>
                <Logo src={logoUrl} />
                <Text fontStyle={font.body3} color={color.gray.d600}>
                  {storeName}
                </Text>
              </Row>
              <Text fontStyle={font.body2} color={color.gray.d800}>
                {name}
              </Text>
              <Text fontStyle={font.subtitle3} color={color.gray.d900}>
                {setCommas(price) + "원"}
              </Text>
            </TextContainer>
          </Row>
          <Row
            $styles={css`
              gap: 0.8rem;
              padding: 1.6rem;
              background: ${color.gray.d50};
              border-radius: 1.2rem;
              margin-top: 1.6rem;
            `}
          >
            <Text fontStyle={font.body2} color={color.gray.d600}>
              주문번호
            </Text>
            <Text fontStyle={font.body2} color={color.gray.d600}>
              {orderNumber}
            </Text>
          </Row>
          <Text
            fontStyle={font.body2}
            color={color.gray.d600}
            marginTop="1.6rem"
            marginLeft="auto"
          >
            {status?.description}
          </Text>
        </CardContent>
      </Card>
    </Container>
  );
};

export default HistoryDetailItem;
