import Logout from "../pages/pos2/Logout";
import PosCheck2 from "../pages/pos2/PosCheck";
import PosLogin2 from "../pages/pos2/PosLogin";
import PosMain2 from "../pages/pos2/PosMain";

const Pos2Routes = [
  { path: "login", element: <PosLogin2 /> },
  { path: "main", element: <PosMain2 /> },
  { path: "check", element: <PosCheck2 /> },
  { path: "logout", element: <Logout /> },
];

export default Pos2Routes;
