import React from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import {
  CloseIcon,
  DoneIcon,
} from "../../../../components/ver1.5/assets/icons";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { css, styled } from "styled-components";
import Text from "../../../../components/ver1.5/atom/Text";
import Button from "../../../../components/ver1.5/atom/Button";
import { useNavigate } from "react-router-dom";

const CloseBtnContainer = styled.div`
  width: 20%;
  padding: 0 1.6rem;
  height: 5.4rem;
  display: flex;
  align-items: center;
  min-width: fit-content;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 13rem;
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
`;

const ReportEnd = () => {
  const navigate = useNavigate();

  const onClickNavigate = () => {
    navigate("/user", {
      replace: true,
    });
  };

  return (
    <Layout headerPadding={false} footerPadding={false}>
      <CloseBtnContainer onClick={onClickNavigate}>
        <CloseIcon color={color.gray.d900} />
      </CloseBtnContainer>
      <ContentContainer>
        <DoneIcon />
        <Text
          styles={css`
            font-family: "Pretendard Bold", sans-serif;
            font-weight: 700;
            font-size: 3.2rem;
            letter-spacing: -0.1rem;
            span {
              color: ${color.primary.flexRed};
            }
          `}
          marginTop="3.2rem"
        >
          신고 <span>완료</span>
        </Text>
        <Text
          fontStyle={font.subtitle3}
          color={color.gray.d600}
          marginTop="3.2rem"
        >
          신고를 접수하였습니다
        </Text>
        <Text
          fontStyle={font.subtitle3}
          color={color.gray.d600}
          marginBottom="8rem"
        >
          관리자의 검토 후 빠르게 처리 도와드겠습니다
        </Text>
      </ContentContainer>
      <BtnWrapper>
        <Button onClick={onClickNavigate} text="홈으로" variant="primaryDark" />
      </BtnWrapper>
    </Layout>
  );
};

export default ReportEnd;
