import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import Switch from "../../../../components/ver1.5/atom/Switch";
import { useOutletContext } from "react-router-dom";
import ActionListItem from "../components/molecule/ActionListItem";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { css, styled } from "styled-components";
import axios from "axios";
import { getApiUrl } from "../../../../function/common";
import { FlexAlarmIcon } from "../../../../components/ver1.5/assets/icons";
import Text from "../../../../components/ver1.5/atom/Text";
import Divider from "../../../../components/ver1.5/atom/Divider";
import { UserContext } from "../../../../contexts/UserContext";
import AlarmOnModal from "../components/organism/AlarmOnModal";

const TopContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  justify-content: center;
  padding: 3.2rem 0;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem;
`;

const Alarm = () => {
  const { accessToken } = useContext(UserContext);

  const {
    me: { alarm },
    refetch,
  } = useOutletContext();
  const [alarmStatus, setAlarmStatus] = useState({
    appPush: false,
    email: false,
    phone: false,
    phoneText: false,
    // mail: false,
  });
  const [alarmOnModal, setAlarmOnModal] = useState({
    show: false,
    type: "",
    updatedAt: "",
  });

  const list = [
    {
      name: "앱 푸시",
      type: "appPush",
    },
    {
      name: "문자SMS",
      type: "phoneText",
    },
    {
      name: "이메일",
      type: "email",
    },
    {
      name: "전화",
      type: "phone",
    },
    // {
    //   name: "우편",
    //   type: "mail",
    // },
  ];

  const onChangeAlarmSetting = async (target, status) => {
    const res = await axios
      .put(
        getApiUrl("users/me/alarm-setting"),
        {
          ...alarmStatus,
          [target]: status,
        },
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken)
      )
      .then((res) => ({ ...res.data, target }))
      .catch((e) => console.log(e));
    return res;
  };

  const onClickChange = (target, status) => {
    onChangeAlarmSetting(target, status).then((alarmRes) => {
      refetch().then((res = { alarm: {} }) => {
        setAlarmStatus({
          appPush: res.alarm?.appPush,
          email: res.alarm?.email,
          phone: res.alarm?.phone,
          phoneText: res.alarm?.phoneText,
          // mail: res.alarm?.mail,
        });
        if (status) {
          setAlarmOnModal({
            show: true,
            type: list.find((item) => item.type === alarmRes.target).name,
            updatedAt: alarmRes[`${alarmRes.target}ApprovedAt`],
          });
        }
      });
    });
  };

  useEffect(() => {
    if (alarm) {
      setAlarmStatus({
        appPush: alarm?.appPush,
        email: alarm?.email,
        phone: alarm?.phone,
        phoneText: alarm?.phoneText,
        // mail: alarm?.mail,
      });
    }
  }, [alarm]);

  return (
    <Layout footerPadding={false}>
      <TopContainer>
        <FlexAlarmIcon />
        <div>
          <Text fontStyle={font.body1} color={color.gray.d800}>
            플렉스데이에서 제공하는
          </Text>
          <Text fontStyle={font.body1} color={color.gray.d800}>
            각종 혜택 · 이벤트 소식을 받아보세요!
          </Text>
        </div>
      </TopContainer>
      <Divider direction="horizontal" width={"1rem"} color={color.gray.d50} />
      <ItemContainer>
        {list.map(({ name, type }) => {
          return (
            <ActionListItem
              key={name}
              text={name}
              fontStyle={font.body1}
              containerStyle={css`
                padding: 2.4rem 0;
              `}
              rightContent={
                <Switch
                  value={alarmStatus[type]}
                  setValue={(status) => onClickChange(type, status)}
                />
              }
            />
          );
        })}
      </ItemContainer>
      {alarmOnModal.show && (
        <AlarmOnModal
          alarmType={alarmOnModal.type}
          updatedAt={alarmOnModal.updatedAt}
          onAction={() =>
            setAlarmOnModal({
              show: false,
              type: "",
              updatedAt: "",
            })
          }
        />
      )}
    </Layout>
  );
};

export default Alarm;
