
import { InputGroup, Button } from 'react-bootstrap';
export default function SelectBox( props ){
    const selectdata = props.selectdata;
    const selectItems = selectdata.map((data) =>
        <option key={data.key} value={data.value}>{data.title}</option>
        //selected
    )

    if (props.type === "ADDON"){
        return (
            <>
                <InputGroup className="mb-3">
                    <select className="form-select" aria-label="Default select" aria-describedby={props.id}>
                        {selectItems}
                    </select>
                    <Button variant="outline-secondary" id={props.id}>
                        {props.btntitle}
                    </Button>
                </InputGroup>
            </>
        )
    }else{
        return (
            <select className="form-select" aria-label="Default select">
                {selectItems}
            </select>
        )
    }
}