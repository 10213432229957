
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonMilitaryToPerson, faStar } from '@fortawesome/free-solid-svg-icons';

export default function Gradient(props){
    // console.log(props.grade);
    if(props.grade === '5'){
        // console.log('5점');
        return (
            <>
                <FontAwesomeIcon key={5} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={4} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={3} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={2} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={1} className='Gradient' icon={faStar} />
            </>
        )
    }else if(props.grade === '4'){
        return (
            <>
                <FontAwesomeIcon key={5} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={4} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={3} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={2} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={1} className='UnGradient' icon={faStar} />
            </>
        )
    }else if(props.grade === '3'){
        return (
            <>
                <FontAwesomeIcon key={5} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={4} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={3} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={2} className='UnGradient' icon={faStar} />
                <FontAwesomeIcon key={1} className='UnGradient' icon={faStar} />
            </>
        )
    }else if(props.grade === '2'){
        return (
            <>
                <FontAwesomeIcon key={5} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={4} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={3} className='UnGradient' icon={faStar} />
                <FontAwesomeIcon key={2} className='UnGradient' icon={faStar} />
                <FontAwesomeIcon key={1} className='UnGradient' icon={faStar} />
            </>
        )
    }else if(props.grade === '1'){
        return (
            <>
                <FontAwesomeIcon key={5} className='Gradient' icon={faStar} />
                <FontAwesomeIcon key={4} className='UnGradient' icon={faStar} />
                <FontAwesomeIcon key={3} className='UnGradient' icon={faStar} />
                <FontAwesomeIcon key={2} className='UnGradient' icon={faStar} />
                <FontAwesomeIcon key={1} className='UnGradient' icon={faStar} />
            </>
        )
    }else{
        return (<></>)
    }
}