import React from "react";
import { css, styled } from "styled-components";
import { color } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";

const Container = styled.div`
  display: flex;
  padding-left: 1.6rem;
  overflow: hidden;
`;

const TabWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 0.6rem;
  align-items: center;
  padding-right: 1.6rem;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.6rem;
  border-radius: 10rem;
  background: ${(p) => (p.$active ? color.gray.d900 : color.primary.white)};
  border: ${(p) =>
    `1px solid ${p.$active ? color.gray.d900 : color.gray.d100}`};
  margin-bottom: 1.6rem;
`;

const SubCategoryTab = ({ name, value, active, onClick }) => {
  return (
    <TabContainer
      value={value}
      $active={active}
      onClick={() => (onClick ? onClick() : null)}
    >
      <Text
        styles={css`
          font-family: "Pretendard Medium", sans-serif;
          font-size: 1.8rem;
          color: ${active ? color.primary.white : color.gray.d600};
          word-break: keep-all;
          white-space: nowrap;
        `}
      >
        {name}
      </Text>
    </TabContainer>
  );
};

const SubCategoryTabList = ({ list = [], current, setCurrent }) => {
  return (
    <Container>
      <TabWrapper>
        {list.map((tab, index) => (
          <SubCategoryTab
            key={index}
            name={tab.name}
            value={tab.value}
            active={current === tab.value}
            onClick={() => setCurrent(tab.value)}
          />
        ))}
      </TabWrapper>
    </Container>
  );
};

export default SubCategoryTabList;
