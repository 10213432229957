import React, { useEffect, useState } from "react";
import { css, styled } from "styled-components";
import LabelledTextField from "../../../../../../components/ver1.5/organism/LabelledTextField";
import Text from "../../../../../../components/ver1.5/atom/Text";
import Button from "../../../../../../components/ver1.5/atom/Button";
import { color, font } from "../../../../../../components/ver1.5/styles/theme";
import { useNavigate } from "react-router-dom";
import useTimer from "../../../../../../components/ver1.5/hooks/useTimer";
import ConfirmModal from "../molecule/ConfirmModal";
import { passwordReg } from "../../../../../../components/common/constants";
import { getApiUrl } from "../../../../../../function/common";
import axios from "axios";
import sha256 from "sha256";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.4rem;
`;

const Row = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
`;

const CertCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const CertBtn = styled.button`
  padding: 1.5rem 1.6rem;
  background: ${(p) => p.$backgroundColor};
  border: 1px solid ${(p) => p.$borderColor};
  border-radius: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  ${(p) => p.$styles};
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const FindPw = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(null);
  const [data, setData] = useState({
    email: "",
    name: "",
    phone: "",
    certificationNumber: "",
  });
  const [token, setToken] = useState(undefined);
  const [certDisabled, setCertDisabled] = useState(true);
  const [certSend, setCertSend] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newRePassword, setNewRePassword] = useState("");
  const [done, setDone] = useState(false);
  const [notFoundModal, setNotFoundModal] = useState(false);
  const [changedModal, setChangedModal] = useState(false);
  const [error, setError] = useState({
    password: false,
    rePassword: false,
  });
  const { time, timerFunc } = useTimer({
    timerSeconds: 180,
    timeEndCallback: () => setTimerOn(false),
  });

  const onChangeData = (e, target) => {
    setData((prev) => ({ ...prev, [target]: e.target.value }));
  };

  const getAuthCode = async () => {
    if (!(data.name && data.phone)) {
      return;
    }
    if (loading) return;
    setLoading(true);
    await axios
      .post(getApiUrl("auth/pw/code"), {
        name: data.name,
        phoneNumber: data.phone,
      })
      .then((res) => {
        setToken(res.data.token);
        setCertSend(true);
        setTimerOn(true);
      })
      .catch((error) => {
        console.error(error);
        alert(
          error?.response?.data?.message
            ? error.response.data.message
            : error.message
        );
      });
    setLoading(false);
  };

  const checkInfo = async () => {
    if (!(data.certificationNumber && token)) {
      return;
    }
    if (loading) return;
    await axios
      .post(getApiUrl("auth/pw/auth"), {
        findPwToken: token,
        authCode: data.certificationNumber,
      })
      .then((res) => {
        setDone(true);
      })
      .catch((error) => {
        console.error(error);
        // setNotFoundModal(true);
        alert(
          error?.response?.data?.message
            ? error.response.data.message
            : error.message
        );
      });
    setLoading(false);
  };

  const resetPw = async () => {
    if (!token) return;
    if (loading) return;
    setLoading(true);
    await axios
      .post(getApiUrl("auth/pw/reset"), {
        findPwToken: token,
        password: sha256(newPassword),
      })
      .then((res) => {
        setChangedModal(true);
      })
      .catch((error) => {
        console.error(error);
        alert(
          error?.response?.data?.message
            ? error.response.data.message
            : error.message
        );
      });
    setLoading(false);
  };

  useEffect(() => {
    if (timerOn) {
      timerFunc();
    }
    // eslint-disable-next-line
  }, [timerOn]);

  useEffect(() => {
    if (data.phone.length > 10) {
      setCertDisabled(false);
    } else {
      setCertDisabled(true);
    }
  }, [data]);

  // 비밀번호 error
  useEffect(() => {
    if (newPassword.length) {
      if (!passwordReg.test(newPassword)) {
        setError((prev) => ({ ...prev, password: true }));
      } else {
        setError((prev) => ({ ...prev, password: false }));
      }
    } else {
      setError((prev) => ({ ...prev, password: false }));
    }
  }, [newPassword]);

  // 비밀번호 확인 error
  useEffect(() => {
    if (newRePassword.length && newPassword.length) {
      if (newPassword !== newRePassword) {
        setError((prev) => ({ ...prev, rePassword: true }));
      } else {
        setError((prev) => ({ ...prev, rePassword: false }));
      }
    } else {
      setError((prev) => ({ ...prev, rePassword: false }));
    }
  }, [newRePassword, newPassword]);

  return (
    <Container>
      {!done && (
        <>
          <LabelledTextField
            label={"이메일"}
            placeholder={"example@xxxx.com"}
            type={
              focused === "email" || data.email.length > 0
                ? "bordered"
                : "filled"
            }
            value={data.email}
            onChange={(e) => onChangeData(e, "email")}
            onFocus={() => setFocused("email")}
            onBlur={() => setFocused(null)}
            customBorderColor={
              focused === "email" ? color.gray.d900 : color.gray.d100
            }
          />
          <LabelledTextField
            label={"이름"}
            placeholder={"실명 입력"}
            type={
              focused === "name" || data.name.length > 0 ? "bordered" : "filled"
            }
            value={data.name}
            onChange={(e) => onChangeData(e, "name")}
            onFocus={() => setFocused("name")}
            onBlur={() => setFocused(null)}
            customBorderColor={
              focused === "name" ? color.gray.d900 : color.gray.d100
            }
          />
          <CertCol>
            <Row>
              <LabelledTextField
                label={"휴대폰 번호"}
                placeholder={"숫자만 입력"}
                type={
                  focused === "phone" || data.phone.length > 0
                    ? "bordered"
                    : "filled"
                }
                value={data.phone}
                onChange={(e) => onChangeData(e, "phone")}
                onFocus={() => setFocused("phone")}
                onBlur={() => setFocused(null)}
                customBorderColor={
                  focused === "phone" ? color.gray.d900 : color.gray.d100
                }
                maxLength={11}
                onlyNumber
                inputMode={"tel"}
              />
              <CertBtn
                $backgroundColor={
                  certDisabled ? color.gray.d200 : color.primary.white
                }
                $borderColor={
                  certDisabled ? "transparent" : color.primary.flexRed
                }
                disabled={certDisabled}
                onClick={getAuthCode}
              >
                <Text
                  fontStyle={font.body1}
                  color={
                    certDisabled ? color.primary.white : color.primary.flexRed
                  }
                  styles={css`
                    white-space: nowrap;
                  `}
                >
                  {certSend ? "재전송" : "인증번호"}
                </Text>
              </CertBtn>
            </Row>
            <RelativeWrapper>
              <LabelledTextField
                placeholder={"인증번호 입력"}
                type={
                  focused === "certificationNumber" ||
                  data.certificationNumber.length > 0
                    ? "bordered"
                    : "filled"
                }
                value={data.certificationNumber}
                onChange={(e) => onChangeData(e, "certificationNumber")}
                onFocus={() => setFocused("certificationNumber")}
                onBlur={() => setFocused(null)}
                customBorderColor={
                  focused === "certificationNumber"
                    ? color.gray.d900
                    : color.gray.d100
                }
                helperText={!timerOn && certSend && "유효시간이 지났습니다."}
                status={!timerOn && certSend && "error"}
              />
              {timerOn && (
                <Text
                  fontStyle={font.caption1}
                  color={color.primary.flexRed}
                  styles={css`
                    position: absolute;
                    right: 1.6rem;
                    bottom: 1.6rem;
                  `}
                >
                  {`0${Math.floor(time / 60)}:${
                    time % 60 === 0
                      ? "00"
                      : time % 60 >= 10
                      ? time % 60
                      : "0" + (time % 60)
                  }`}
                </Text>
              )}
            </RelativeWrapper>
          </CertCol>
          <BtnWrapper>
            <Button
              disabled={
                Object.values(data).filter((value) => Boolean(value) === true)
                  .length !== 4
              }
              text="확인"
              onClick={checkInfo}
            />
          </BtnWrapper>
          {notFoundModal && (
            <ConfirmModal
              onAction={() => {
                setNotFoundModal(false);
                setData({
                  email: "",
                  name: "",
                  phone: "",
                  certificationNumber: "",
                });
                setCertDisabled(false);
                setCertSend(false);
                setTimerOn(false);
              }}
              text={"일치하는 회원정보가 없습니다"}
            />
          )}
        </>
      )}
      {done && (
        <>
          <div>
            <Text fontStyle={font.h2} color={color.gray.d800}>
              새로운 비밀번호를
            </Text>
            <Text fontStyle={font.h2} color={color.gray.d800}>
              입력해주세요
            </Text>
          </div>
          <LabelledTextField
            label={"새 비밀번호"}
            placeholder={"비밀번호 입력"}
            type={
              focused === "newPassword" || newPassword.length > 0
                ? "bordered"
                : "filled"
            }
            inputType="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onFocus={() => setFocused("newPassword")}
            onBlur={() => setFocused(null)}
            customBorderColor={
              focused === "newPassword" ? color.gray.d900 : color.gray.d100
            }
            error={error.password}
            helperText={
              error.password
                ? "영문+숫자+특수문자 !@#$%^ 를 포함 10~18자를\n충족하지 못했습니다"
                : "영문+숫자+특수문자 !@#$%^ 를 포함 10~18자"
            }
          />
          <LabelledTextField
            label={"새 비밀번호 확인"}
            placeholder={"비밀번호 재입력"}
            type={
              focused === "newRePassword" || newRePassword.length > 0
                ? "bordered"
                : "filled"
            }
            inputType="password"
            value={newRePassword}
            onChange={(e) => setNewRePassword(e.target.value)}
            onFocus={() => setFocused("newRePassword")}
            onBlur={() => setFocused(null)}
            customBorderColor={
              focused === "newRePassword" ? color.gray.d900 : color.gray.d100
            }
            error={error.rePassword}
            status={!error.rePassword && !error.password ? "complete" : null}
            helperText={
              error.rePassword
                ? "비밀번호가 일치하지 않습니다."
                : !error.rePassword && newRePassword.length > 10
                ? "비밀번호가 일치합니다."
                : null
            }
          />
          <BtnWrapper
            $styles={css`
              display: flex;
              gap: 1rem;
            `}
          >
            <Button
              text="비밀번호 변경"
              onClick={resetPw}
              disabled={
                Object.values(error).filter((value) => value === true)
                  .length !== 0 ||
                newPassword.length < 10 ||
                newRePassword.length < 10
              }
            />
          </BtnWrapper>
          {changedModal && (
            <ConfirmModal
              text={"비밀번호를 변경하였습니다"}
              onAction={() => navigate("/user/login")}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default FindPw;
