import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import LabelledTextField from "../../../../components/ver1.5/organism/LabelledTextField";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";
import { phoneNumberFormatter } from "../../../../components/ver1.5/utils";
import axios from "axios";
import { getApiUrl, getRedirctUrl } from "../../../../function/common";
import { UserContext } from "../../../../contexts/UserContext";
import OneBtnModal from "../../../../components/ver1.5/organism/OneBtnModal";

const Container = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: end;
`;

const Btn = styled.button`
  padding: 1.6rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.2rem;
  border: 1px solid ${color.gray.d100};
  background: ${color.primary.white};
  height: 5.6rem;
  flex-shrink: 0;
`;

const EditPhone = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { me = { phoneNumber: "" }, refetch } = useOutletContext();
  const { accessToken } = useContext(UserContext);
  const [phone, setPhone] = useState("");
  const [tokenVersionId, setTokenVersionId] = useState();
  const [encData, setEncData] = useState();
  const [integrityValue, setIntegrityValue] = useState();
  const niceIdRef = useRef();
  const [searchParams] = useSearchParams();
  const received_integrity_value = searchParams.get("integrity_value");
  const received_token_version_id = searchParams.get("token_version_id");
  const received_enc_data = searchParams.get("enc_data");
  const [confirmModal, setConfirmModal] = useState(false);

  const onClickChangeBtn = async () => {
    await axios
      .post(getApiUrl("users/me/auth/self"), {
        popupyn: "N",
        redirectUrl: getRedirctUrl("user/my/editPhone"),
        customData: "",
      })
      .then((res) => {
        if (res.status === 200) {
          const { token_version_id, enc_data, integrity_value } = res.data;
          setTokenVersionId(token_version_id);
          setEncData(enc_data);
          setIntegrityValue(integrity_value);
        }
      });
  };

  useEffect(() => {
    if (
      received_token_version_id &&
      received_integrity_value &&
      received_enc_data
    ) {
      axios
        .put(
          getApiUrl("users/me/phoneNumber"),
          {
            token_version_id: received_token_version_id,
            integrity_value: received_integrity_value,
            enc_data: received_enc_data,
          },
          ((axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`))
        )
        .then((res) => {
          if (res.status === 200) {
            setConfirmModal(true);
            setPhone(res.phoneNumber);
            refetch();
            // TODO: 나이스 api 호출된 후 브라우저 히스토리에 남아있어 뒤로가기 부분 수정 필요 / 테스트 중
            navigate(-4);
            // navigate(location.pathname, { replace: true });
          }
        })
        .catch((e) => {
          if (e.response?.data) {
            alert(e.response.data?.message);
          }
        });
    }
    // eslint-disable-next-line
  }, [received_enc_data, received_integrity_value, received_token_version_id]);

  useEffect(() => {
    if (
      integrityValue !== null &&
      integrityValue !== "" &&
      integrityValue !== undefined
    ) {
      niceIdRef.current.submit();
    }
  }, [integrityValue]);

  useEffect(() => {
    if (me.phoneNumber) {
      setPhone(me.phoneNumber);
    }
  }, [me]);

  return (
    <Layout>
      <Container>
        <InputContainer>
          <LabelledTextField
            label={"휴대폰 인증"}
            value={phone ? phoneNumberFormatter(phone.toString()) : ""}
            readOnly
            onlyNumber
            type={"filled"}
            inputMode={"numeric"}
          />
          <Btn onClick={onClickChangeBtn}>
            <Text fontStyle={font.body1} color={color.gray.d700}>
              변경
            </Text>
          </Btn>
        </InputContainer>
        <Text fontStyle={font.caption2} color={color.gray.d600}>
          인증을 완료하였습니다
        </Text>
      </Container>
      <form
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
        ref={niceIdRef}
        method="post"
      >
        <input type="hidden" name="m" value="checkplusSerivce" />
        <input
          type="hidden"
          id="token_version_id"
          name="token_version_id"
          value={tokenVersionId || ""}
          onChange={(e) => setTokenVersionId(e.target.value)}
        />
        <input
          type="hidden"
          id="enc_data"
          name="enc_data"
          value={encData || ""}
          onChange={(e) => setEncData(e.target.value)}
        />
        <input
          type="hidden"
          id="integrity_value"
          name="integrity_value"
          value={integrityValue || ""}
          onChange={(e) => setIntegrityValue(e.target.value)}
        />
      </form>
      {confirmModal && (
        <OneBtnModal
          text={"전화번호 변경이 완료되었습니다"}
          onAction={() => setConfirmModal(false)}
        />
      )}
    </Layout>
  );
};

export default EditPhone;
