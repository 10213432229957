import { Container, Row, Col, Accordion, Table } from "react-bootstrap";
import Btn from "./../../components/common/Btn";

export default function Faq() {
  return (
    <>
      <Container className="gx-0">
        <Btn type="NAVBACK" title="FAQ" />
        <Row className="gx-0">
          <Col xs={{ span: 10, offset: 1 }} className="mt-5 mb-5">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>지급보증</Accordion.Header>
                <Accordion.Body>
                  {/* <h5>지급보증</h5> */}
                  {/* <br/> */}
                  <pre>
                    본 모바일 교환권은 지급보증 및 피해보상보험 계약 없이
                    (주)플렉스데이의 신용으로 발행합니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>취소/환불 정책 및 방법</Accordion.Header>
                <Accordion.Body>
                  {/* <h5>취소/환불 정책 및 방법</h5>
                                    <br/> */}
                  <pre>
                    취소/환불 정책 및 방법 1. 구매자 취소 (청약철회) 가능 기간
                    이내에는 구매자가 결제 금액의 100%에 대해 결제 취소/환불을
                    요청할 수 있습니다.
                    <br />
                    2. 구매자 취소 (청약철회) 가능 기간 이후에는 수신자가 결제
                    금액의 90%에 대해 환불 요청을 할 수 있습니다. 단, 환불
                    신청시 간단한 본인인증 및 본인계좌 확인이 필요할 수
                    있습니다.
                    <br />
                    3. 환불은 실제 결제한 금액으로 진행되며, 이로 인해 결제
                    취소/환불 신청시 안내된 금액과 다를 수 있습니다.
                    <br />
                    4. 결제취소 또는 환불요청은 미사용 교환권에 대해서만
                    가능하며, 사용한 교환권에 대해서는 불가능합니다.
                    <br />
                    5. 미성년자의 회원이 서비스를 이용하여 상품 등을 구매 시
                    법정대리인이 해당 계약에 대하여 동의를 하여야 정상적인 상품
                    등의 구매계약이 체결될 수 있습니다. 만약, 미성년자인 회원이
                    법정대리인의 동의 없이 상품 등을 구매하는 경우 본인 또는
                    법정대리인이 이를 취소할 수 있습니다.
                    <br />
                    6. 취소가능 기간은 구매일로 부터 7일까지 가능합니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>거래조건에 관한 정보</Accordion.Header>
                <Accordion.Body>
                  {/* <h5>거래조건에 관한 정보</h5> */}
                  <pre>
                    유효기간 정책 및 연장 방법
                    <br />
                    1. 2022.5.31 까지의 주문된 물품 교환권 및 금액권은 3개월,
                    잔액형 교환권은 1년의 유효기간을 가지며, 2022.6.1 이후
                    주문된 교환권의 유효기간은 1년입니다. 유효기간은 최종
                    유효기간 내에서 연장 신청이 가능하고, 특별한 사유가 없는 한
                    3개월 단위로 연장합니다. <br />
                    2. 한시적으로 제공되는 무료쿠폰 및 프로모션 연계상품의 경우
                    유효기간 연장 및 연장 정책이 일반상품과 상이할 수 있습니다.
                    <br />
                    <br />
                    교환권 영수증 발행 안내
                    <br />
                    1. 교환권은 구매하실 때 현금 영수증이 발행되지 않으며, 선물
                    받으신 분께서 물품 또는 서비스로 교환하실 때 각 사용처에
                    매장에서 발행됩니다.
                    <br />
                    2. 거래명세서(또는 거래확인서)의 발급은 결제수단에 따라
                    아래의 방법으로 진행하실 수 있습니다.
                    <br />
                    - 휴대폰 결제
                    <br />
                    토스 홈페이지(https://pay.toss.im/pay/)
                    <br />
                    - 일반 신용카드 결제
                    <br />
                    토스 홈페이지(https://pay.toss.im/pay/)
                    <br />
                    물품제공 불가시 정책
                    <br />
                    물품교환형 교환권에 대해 해당 물품의 제공이 불가능하거나
                    제공에 필요한 통상적인 기간보다 현저히 제공이 지체되는
                    경우에는 해당 교환권과 동일한 금전적 가치의 교환권으로
                    변경될 수 있으며, 다른 교환권으로 교환이 불가능한 경우에는
                    구매액 전액을 취소/환불할 수 있습니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>구매 주의사항</Accordion.Header>
                <Accordion.Body>
                  {/* <h5>구매 주의사항</h5> */}
                  <pre>
                    결제대금예치에 관한 사항 (주)플렉스데이는 고객께서 현금성
                    결제수단(가상계좌 등)으로 결제한 거래에 대하여 안전거래를
                    보장하고 있습니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>스토어 생성은 어떻게하나요?</Accordion.Header>
                <Accordion.Body>
                  <pre>
                    플렉스데이 스토어는 회원가입 후 사업자 인증을 통해 스토어
                    생성이 가능 합니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  상품금액이 변경되면 어떻게하나요?
                </Accordion.Header>
                <Accordion.Body>
                  <pre>
                    상품금액 변경시 기존 판매 상품은 판매 중지 후 새로 등록하여
                    사용하실 수 있습니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  직원을 초대하려면 어떻게하나요?
                </Accordion.Header>
                <Accordion.Body>
                  <pre>
                    플렉스 스토어 앱을 다운로드 받은 경우
                    <br />
                    - 직원 &gt; 직원추가 &gt; 아이디 검색으로 직원 조회
                    <br />
                    이후 직원분이 수락하시면 정상적으로 초대가 완료 됩니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>건당 중개수수료있나요?</Accordion.Header>
                <Accordion.Body>
                  <pre>
                    교환권 사용 시 결제 방법에 따라 건당 중개수수료가
                    부가됩니다.
                  </pre>
                  <Table className="TextTable">
                    <thead>
                      <tr className="AlignCenter">
                        <td>결제 방법</td>
                        <td>결제 수수료</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="AlignCenter">
                        <td>신용, 체크 카드</td>
                        <td rowSpan="3">5.5%</td>
                      </tr>
                      <tr className="AlignCenter">
                        <td>간편결제</td>
                      </tr>
                      <tr className="AlignCenter">
                        <td>계좌이체</td>
                      </tr>
                      <tr className="AlignCenter">
                        <td>휴대폰 결제</td>
                        <td>7.7%</td>
                      </tr>
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>법인카드 사용 가능한가요?</Accordion.Header>
                <Accordion.Body>
                  <pre>
                    플렉스데이 선물하기 서비스는 개인 및 법인 카드 모두 사용
                    가능합니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  다른 배달앱처럼 이벤트 진행있나요?
                </Accordion.Header>
                <Accordion.Body>
                  <pre>이벤트는 추후 서비스 예정입니다.</pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  수수료가 어떻게 이렇게 싼거죠?
                </Accordion.Header>
                <Accordion.Body>
                  <pre>
                    플렉스데이 선물하기 서비스는 자사 플랫폼을 이용하여 중간
                    발급자, 유통자의 과정이 없어 저렴한 수수료로 제공해 드리고
                    있습니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  이미 판매된 상품을 취소할수 있나요?
                </Accordion.Header>
                <Accordion.Body>
                  <pre>
                    이미 판매된 상품은 편의를 위해 사용당일 23시59분 까지만 사용
                    취소가 가능합니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  제 선물하기 스토어 주소는 어디서 확인하나요?
                </Accordion.Header>
                <Accordion.Body>
                  <pre>
                    플렉스데이에 최초 가입시 작성한 스토어 주소를 다시 확인하실
                    수 있습니다.
                    <br />
                    메뉴 &gt; 사업장정보 &gt; 스토어주소
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  결제할 수 있는 금액 제한이 있나요?
                </Accordion.Header>
                <Accordion.Body>
                  <pre>
                    일부 상품의 경우, 플렉스데이 및 판매업체의 사정에 따라 한
                    번에 구매할 수 있는 금액에 제한이 있을 수 있습니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header>
                  선물할 수 있는 수량 제한이 있나요?
                </Accordion.Header>
                <Accordion.Body>
                  <pre>
                    선물하기의 기본 수량은 1개입니다. 해당 상품의 상품
                    결제화면에서 내용을 확인할 수 있습니다.
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="15">
                                <Accordion.Header></Accordion.Header>
                                <Accordion.Body>
                                </Accordion.Body>
                            </Accordion.Item> */}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}
