
import { Container, Row, Col } from 'react-bootstrap';
import CustomAlert from '../../components/common/CustomAlert';
import { useState } from 'react';

export default function Coupon( props ) {
    const [mode, setMode] = useState("STEP1");

    if(mode === 'STEP1'){
        return (
            <>
                <Container>
                    <Row >
                        <Col className='text-center'>
                            <img
                                className="d-center w-50"
                                src="https://placeimg.com/480/480"
                                alt="1"
                            />
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <p>쿠폰번호 : 1111 - 1111 - 1111 - 1111</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CustomAlert type="ALERT" text="사용 가능한 쿠폰입니다." />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h4>플렉스 브런치 카페</h4>
                            <p>메뉴이름 : 플렉스 아메리카노</p>
                            <p>메뉴가격 : 18,000원</p>
                            <p>유효기간 : YYYY-mm-dd</p>
                        </Col>s
                    </Row>

                    <Row>
                        <Col className='d-grid'>
                            <button className = "btn btn-default" onClick={ () => ( setMode('STEP2') )}>결제(사용) 처리하기</button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }else if(mode === 'STEP2'){
        return (
            <>
                <Container>
                    <Row >
                        <img
                            style={{margin:"0 auto"}}
                            className="d-center w-50"
                            src="https://placeimg.com/480/480"
                            alt="1"
                        />
                    </Row>
                    <Row className="text-center">
                        <p>쿠폰번호 : 1111 - 1111 - 1111 - 1111</p>
                    </Row>
                    <Row className='text-center'>
                        <CustomAlert type="ALERT" text="이미 사용한 쿠폰입니다." />
                    </Row>

                    <Row>
                        <h4>플렉스 브런치 카페</h4>
                        <p>메뉴이름 : 플렉스 아메리카노</p>
                        <p>메뉴가격 : 18,000원</p>
                        <p>유효기간 : YYYY-mm-dd</p>
                    </Row>

                    <Row className='text-left' >
                        <CustomAlert css="LEFT" type="ALERT" text="결 제 자 : 홍길동\n결제일시 : YYYY. mm. dd. (t:m) " />
                    </Row>
                </Container>
            </>
        )
    }else{
        return (<></>)
    }
}