import { Row, Col, ButtonGroup, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getApiUrl, PrintConsole, setCommas } from "./../../function/common";
import axios from "axios";
import { useRef } from "react";

export default function StoreItem(props) {
  const navigate = useNavigate();
  const stopAlertRef = useRef();
  const startAlertRef = useRef();

  const ItemStop = async (e) => {
    let obj = {
      name: props.domain,
      phoneNumber: props.id,
    };
    await axios
      .delete(
        getApiUrl("stores/" + props.domain + "/products/" + props.id),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;"),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          // setData({
          //     ...data,
          //     ['findIdToken'] : response.data.token,
          // });
          stopAlertRef.current.classList.add("active");
          setTimeout(() => {
            stopAlertRef.current.classList.remove("active");
          }, 1500);
          setTimeout(() => {
            window.location.reload();
          }, 1800);
        } else {
          alert("판매 중지에 실패하였습니다.");
        }
      })
      .catch(function (error) {
        PrintConsole(error);
      });
  };

  const ItemStart = async (e) => {
    let obj = {
      name: props.domain,
      phoneNumber: props.id,
    };
    await axios
      .post(
        getApiUrl("stores/" + props.domain + "/products/" + props.id),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;"),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          // setData({
          //     ...data,
          //     ['findIdToken'] : response.data.token,
          // });
          startAlertRef.current.classList.add("active");
          setTimeout(() => {
            startAlertRef.current.classList.remove("active");
          }, 1500);
          setTimeout(() => {
            window.location.reload();
          }, 1800);
        } else {
          alert("판매 시작에 실패하였습니다.");
        }
      })
      .catch(function (error) {
        PrintConsole(error);
      });
  };

  if (props.type === "VIEWER") {
    return (
      <>
        <Row style={{ borderBottom: "1px solid gray" }} className="p-2">
          <Col xs={8}>
            <h4>{props.title}</h4>
            <p>{props.description}</p>
            <h4>{setCommas(props.price) + " 원"}</h4>
          </Col>

          <Col xs={4}>
            <img
              className="d-block w-100"
              src="https://placeimg.com/480/480"
              alt="1"
            />
          </Col>
        </Row>
      </>
    );
  } else if (props.type === "STOP") {
    return (
      <>
        <Row>
          <Col xs={8}>
            {props.menuType && (
              <p className="prd-badge">
                <span className="badge badge-default">
                  {props.menuType.split(" ")[0]}
                </span>
              </p>
            )}
            <p className="prd-tit">
              <span>{props.title}</span>
            </p>
            <p className="prd-sub">{props.description}</p>
            <p className="prd-price">{props.price}</p>
            <div className="prd-btnbox d-flex">
              {/* <button
                type="button"
                className="btn type14 btn-light"
                onClick={() =>
                  navigate("/store/itemcreate?productId=" + props.id)
                }
              >
                수정하기
              </button> */}
              <button
                type="button"
                className="btn type13 btn-default"
                onClick={() => {
                  ItemStop();
                }}
              >
                판매중지
              </button>
            </div>
          </Col>
          <Col xs={4}>
            <img
              className="img-fluid w-100"
              style={{ aspectRatio: 1 }}
              src={
                props.img === null ? "https://placeimg.com/480/480" : props.img
              }
              alt="1"
            />
          </Col>

          {/* <Col xs={8}>
                        <h4>{props.title}</h4>
                        <p>{props.description}</p>
                        <h4>{props.price}</h4>
                    </Col>

                    <Col xs={4}>
                        <img
                        className="d-block w-100 h-100 ItemImgSize"
                        src={props.img === null ? 'https://placeimg.com/480/480' : props.img}
                        alt="1"
                        />
                    </Col>

                    <Col className="text-center" style={{marginTop:"-30px"}}>
                        <ButtonGroup>
                            <Button onClick={ ItemStop }>판매중지</Button>
                        </ButtonGroup>
                    </Col> */}
          <Col className="col-12 px-0">
            <div ref={stopAlertRef} className="AlertBox mt-3">
              해당 상품이 판매중지 처리 되었습니다.
            </div>
          </Col>
        </Row>
      </>
    );
  } else if (props.type === "START") {
    return (
      <>
        <Row>
          <Col xs={8}>
            {/* <p class="prd-badge">
                            <span class="badge badge-default">BEST</span>
                        </p> */}
            <p className="prd-tit">
              <span>{props.title}</span>
            </p>
            <p className="prd-sub">{props.description}</p>
            <p className="prd-price">{props.price}</p>
            <div className="prd-btnbox d-flex">
              {/* <button type="button" class="btn type14 btn-light">수정하기</button> */}
              <button
                type="button"
                className="btn type13 btn-default"
                onClick={() => {
                  ItemStart();
                }}
              >
                판매시작
              </button>
            </div>
          </Col>
          <Col xs={4}>
            <img
              className="img-fluid w-100"
              style={{ aspectRatio: 1 }}
              src={
                props.img === null ? "https://placeimg.com/480/480" : props.img
              }
              alt="1"
            />
          </Col>
          {/* <Col xs={8}>
                        <h4>{props.title}</h4>
                        <p>{props.description}</p>
                        <h4>{props.price}</h4>
                    </Col>

                    <Col xs={4}>
                        <img
                        className="d-block w-100 h-100 ItemImgSize"
                        src={props.img === null ? 'https://placeimg.com/480/480' : props.img}
                        alt="1"
                        />
                    </Col>

                    <Col className="text-center" style={{marginTop:"-30px"}}>
                        <ButtonGroup>
                            <Button onClick={ ItemStart }>판매시작</Button>
                        </ButtonGroup>
                    </Col> */}
          <Col className="col-12 px-0">
            <div ref={startAlertRef} className="AlertBox mt-3">
              해당 상품을 다시 판매합니다!
            </div>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row
          onClick={() => {
            navigate(
              "/user/gift?storeurl=" + props.domain + "&productid=" + props.id
            );
          }}
        >
          <Col xs={8}>
            {/* <p class="prd-badge">
                            <span class="badge badge-default">BEST</span>
                        </p> */}
            <p className="prd-tit">
              <span>{props.title}</span>
            </p>
            <p className="prd-sub">{props.description}</p>
            <p className="prd-price">{props.price}</p>
          </Col>
          <Col xs={4}>
            <img
              className="img-fluid w-100"
              style={{ aspectRatio: 1 }}
              src={
                props.img === null ? "https://placeimg.com/480/480" : props.img
              }
              alt="1"
            />
          </Col>
        </Row>
      </>
    );
  }
}
