import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/ver1.5/layout/Layout";
import TabList from "../../../components/ver1.5/organism/TabList";
import BannerSwiper from "./components/BannerSwiper";
import { headerMenuList } from "../../../components/ver1.5/constants";
import ShopList from "./components/organism/ShopList";
import { css } from "styled-components";
import Text from "../../../components/ver1.5/atom/Text";
import Divider from "../../../components/ver1.5/atom/Divider";
import { color, font } from "../../../components/ver1.5/styles/theme";
import axios from "axios";
import { getApiUrl } from "../../../function/common";
import useInfiniteScroll from "../../../components/ver1.5/hooks/useInfiniteScroll";
import { UserContext } from "../../../contexts/UserContext";
import SubCategoryTabList from "./components/organism/SubCategoryTabList";

const bannerList = [
  // {
  //   url: require("../../../components/ver1.5/assets/img/m_ban01.png"),
  // },
  // {
  //   url: require("../../../components/ver1.5/assets/img/m_ban02.png"),
  // },
  // {
  //   url: require("../../../components/ver1.5/assets/img/m_ban03.png"),
  // },
  {
    url: require("../../../components/ver1.5/assets/img/002.png"),
  },
  {
    url: require("../../../components/ver1.5/assets/img/003.png"),
  },
  {
    url: require("../../../components/ver1.5/assets/img/004.png"),
  },
];

const SectionTitle = ({ children }) => {
  return (
    <Text
      // styles={css`
      //   font-family: "Pretendard Bold", sans-serif;
      //   font-size: 2rem;
      // `}
      fontStyle={font.subtitle1}
      color={color.gray.d900}
      marginLeft="1.6rem"
      marginBottom="1.2rem"
    >
      {children}
    </Text>
  );
};

const subCategorySample = [
  {
    name: "한식",
    value: "korean",
  },
  {
    name: "양식",
    value: "foreign",
  },
  {
    name: "아시아음식",
    value: "asian",
  },
  {
    name: "일식",
    value: "japanese",
  },
  {
    name: "중식",
    value: "chinese",
  },
  {
    name: "분식",
    value: "casual",
  },
  {
    name: "패스트푸드",
    value: "fastFood",
  },
  {
    name: "고기/구이",
    value: "meat",
  },
  {
    name: "기타",
    value: "etc",
  },
];

const Main = () => {
  const { name } = useContext(UserContext);

  const [currentTab, setCurrentTab] = useState("all");
  const [currentCategory, setCurrentCategory] = useState("korean");
  const [newStore, setNewStore] = useState([]);
  const [recommendLoading, setRecommendLoading] = useState(false);
  const [recommendStores, setRecommendStores] = useState([]);
  const [recommendNextCursorId, setRecommendNextCursorId] = useState(null);
  const username = name ?? "회원";
  const listLimit = 10;
  const newListLimit = 10;

  const getTargetCode = (codeType) => {
    return headerMenuList.find((x) => x.value === currentTab)?.[codeType];
  };

  const getStores = async ({ businessClassType, cursorId }) => {
    // console.log(currentTab)
    const res = await axios
      .get(
        getApiUrl(
          `stores-display?size=${listLimit}&categoryCode=${
            getTargetCode("personalCode")
              ? getTargetCode("personalCode")
              : "001"
          }` +
            // (businessClassType
            //   ? `&businessClassType=${businessClassType}`
            //   : "") +
            (cursorId ? `&cursorId=${cursorId}` : "")
        )
      )
      .then((res) => res.data);
    return res;
  };

  const getNewStores = async ({ businessClassType }) => {
    const res = await axios
      .get(
        getApiUrl(
          `stores-display?size=${newListLimit}&categoryCode=${
            getTargetCode("categoryCode")
            // headerMenuList.find((x) => x.value === currentTab).categoryCode
          }`
          // (businessClassType ? `&businessClassType=${businessClassType}` : "")
        )
      )
      .then((res) => res.data);
    return res;
  };

  useEffect(() => {
    setRecommendLoading(true);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    const businessClassType =
      currentTab === "all"
        ? null
        : headerMenuList.find((menu) => menu.value === currentTab).name;
    getStores({ businessClassType }).then((res) => {
      setRecommendStores(res.data);
      if (res.hasNext) {
        setRecommendNextCursorId(res.nextCursorId);
      } else {
        setRecommendNextCursorId(null);
      }
      setRecommendLoading(false);
    });
    getNewStores({ businessClassType }).then((res) => setNewStore(res.data));
    // eslint-disable-next-line
  }, [currentTab]);

  const { Target } = useInfiniteScroll(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (recommendNextCursorId) {
      const formData = {
        businessClassType: null,
        cursorId: recommendNextCursorId,
      };
      if (currentTab !== "all") {
        formData.businessClassType = headerMenuList.find(
          (menu) => menu.value === currentTab
        ).name;
      }
      await getStores(formData).then((res) => {
        setRecommendStores((prev) => [...prev, ...res.data]);
        if (res.hasNext) {
          setRecommendNextCursorId(res.nextCursorId);
        } else {
          setRecommendNextCursorId(null);
        }
        setRecommendLoading(false);
      });
    }
  });

  return (
    <Layout>
      <TabList
        rows={headerMenuList}
        currentTab={currentTab}
        onClick={(row) => setCurrentTab(row.value)}
        marginBottom="1.6rem"
      />
      {/* TODO: 서브 카테고리 api 작업 후 확인 */}
      {/* <SubCategoryTabList
        list={subCategorySample}
        current={currentCategory}
        setCurrent={setCurrentCategory}
      /> */}
      <BannerSwiper data={bannerList} />
      {newStore.length > 0 ? (
        <>
          <SectionTitle>
            {headerMenuList.find((x) => x.value === currentTab).name} 신규 입점
            🫶
          </SectionTitle>
          <ShopList data={newStore} marginBottom="3.2rem" />
        </>
      ) : (
        <Text
          fontStyle={font.body2}
          styles={css`
            text-align: center;
          `}
          marginTop="3.2rem"
          marginBottom="3.2rem"
        >
          해당 카테고리에 신규 입점한 상점이 없습니다.
        </Text>
      )}
      <Divider
        direction="horizontal"
        color={color.gray.d50}
        width={"1.2rem"}
        marginBottom={"3.2rem"}
      />
      {recommendStores.length > 0 ? (
        <>
          <SectionTitle>{username}님을 위한 추천</SectionTitle>
          <ShopList
            data={recommendStores}
            type="full"
            direction="column"
            marginBottom="3.2rem"
          />
        </>
      ) : (
        <>
          {recommendLoading ? (
            <Text
              fontStyle={font.body2}
              styles={css`
                text-align: center;
              `}
              marginTop="6.4rem"
            >
              데이터 로딩 중
            </Text>
          ) : (
            <Text
              fontStyle={font.body2}
              styles={css`
                text-align: center;
              `}
              marginTop="6.4rem"
            >
              해당 카테고리에 등록된 상점이 없습니다.
            </Text>
          )}
        </>
      )}
      {!recommendLoading && <Target />}
    </Layout>
  );
};

export default Main;
