
import { Form, Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function CheckBox(props){
    if (props.type === 'btnAddOn'){
        return (
            <Container>
                <Row>
                    <div className="col-10 form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">{props.label}</label>
                    </div>
                    <div className="col-2">
                        <div style={{textAlign: 'right'}}>
                            <Link to = "/">&gt;</Link>
                        </div>
                    </div>
                </Row>
            </Container>
        )
    }else if(props.type === 'btnAddOnSpan4'){
        return (
            <div className="col-4 form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">{props.label}</label>
            </div>
        )
    }else if(props.type === 'TableCheck'){
        return (
            <>
                <Form.Check aria-label={props.label} />
            </>
        )
    }
    return (
        <Col xs={{ span:12, offset:0 }}>
            <Form.Group className="" controlId={props.controlId}>
                <Form.Check type="checkbox" label={props.label} />
            </Form.Group>
        </Col>
    )
}