import React from "react";
import { css, styled } from "styled-components";
import Tab from "../molecule/Tab";
import { color } from "../styles/theme";

const Container = styled.div`
  overflow: hidden;
  ${(p) =>
    p.$type !== "storeDetail" &&
    css`
      position: sticky;
      top: 5.4rem;
      z-index: 998;
    `}
  background-color: ${color.primary.white};
  ${(p) =>
    p.$marginTop &&
    css`
      margin-top: ${p.$marginTop};
    `}
  ${(p) =>
    p.$marginBottom &&
    css`
      margin-bottom: ${p.$marginBottom};
    `};
  ${(p) => p.$tabListContainerStyle};
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2.6rem;
  padding: 1rem 1.8rem 0 1.8rem;
  border-bottom: 1px solid ${color.gray.d100};
  overflow: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    flex-shrink: 0;
  }
  ${(p) => p.$tabListStyle};
`;

/**
 * TabList 구성요소
 * @typedef {object} TabListProperty
 * @property {TabProperty[]} rows
 * @property {any=} currentTab
 * @property {string=} marginTop
 * @property {string=} marginBottom
 * @property {"default"|"storeDetail"} type
 * @property {void} onClick
 * - 각 탭에 내려주는 onClick 각 아이템을 row로 받음
 * @property {any=} tabContainerStyle
 * @property {any=} tabListContainerStyle
 * @property {any=} tabContentStyle
 * @property {any=} customIndicator
 * @property {any=} tabListStyle
 *
 * @param {TabListProperty}
 * @returns {TabListProperty}
 */
const TabList = ({
  rows = [],
  currentTab,
  onClick,
  marginBottom,
  marginTop,
  type = "default",
  customIndicator,
  tabContainerStyle,
  tabContentStyle,
  tabListStyle,
  tabListContainerStyle,
}) => {
  return (
    <Container
      $type={type}
      $marginBottom={marginBottom}
      $marginTop={marginTop}
      $tabListContainerStyle={tabListContainerStyle}
    >
      <TabWrapper $type={type} $tabListStyle={tabListStyle}>
        {rows.map((row) => {
          return (
            <Tab
              key={row.name}
              text={row.name}
              selected={row.value === currentTab}
              onClick={() => (onClick ? onClick(row) : null)}
              type={type}
              customIndicator={customIndicator}
              tabContainerStyle={tabContainerStyle}
              tabContentStyle={tabContentStyle}
            />
          );
        })}
      </TabWrapper>
    </Container>
  );
};

export default TabList;
