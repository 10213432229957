import React, { useState } from "react";
import { css, styled } from "styled-components";
import MenuDefault from "../../../../components/ver1.5/assets/img/menuItemDefault.svg";
import GiftIllust from "../../../../components/ver1.5/assets/img/receiveGift.svg";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 1.6rem);
`;

const Icon = styled.img`
  width: 8rem;
  height: 8rem;
`;

const MessageContainer = styled.div`
  padding: 1.6rem 0 0.8rem;
  border-radius: 0.8rem;
  background: ${color.primary.white};
  box-shadow: 0 0 0.8rem 0 ${color.gray.d100};
  gap: 1.6rem;
  /* margin-top: 1.6rem; */
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem;
  gap: 1rem;
`;

const ProductContainer = styled.div`
  display: flex;
  padding: 1.2rem 1.6rem;
  border-top: 1px solid ${color.gray.d100};
  gap: 1rem;
`;

const ProductImage = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 1.2rem;
  overflow: hidden;
  object-fit: cover;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const MoreBtn = styled.span`
  cursor: pointer;
  ${font.caption2};
  color: ${color.gray.d500};
  &::before {
    content: "...";
    margin-left: 0.2rem;
    margin-right: 0.8rem;
  }
`;

const CouponTopContent = ({
  senderName,
  receiverName,
  message,
  productImage = MenuDefault,
  storeName = "-",
  productName = "-",
}) => {
  const [displayMessage, setDisplayMessage] = useState(
    message ? message.slice(0, 50) : null
  );

  const onClickShowAll = () => {
    setDisplayMessage(message);
  };

  return (
    <TopContainer>
      {/* <Icon src={GiftIllust} /> */}
      {/* <Text fontStyle={font.subtitle2} color={color.gray.d900}>
        From. {senderName}
      </Text> */}
      {message && (
        <MessageContainer>
          <TextContainer>
            <Text
              fontStyle={font.subtitle3}
              color={color.gray.d900}
              marginBottom="1rem"
            >
              To. {receiverName}
            </Text>
            {displayMessage && (
              <Text
                fontStyle={font.caption2}
                color={color.gray.d500}
                marginBottom="1.6rem"
                styles={css`
                  word-break: break-all;
                `}
              >
                <pre>{displayMessage}</pre>
                {message?.length > 50 &&
                  displayMessage.length !== message.length && (
                    <MoreBtn onClick={() => onClickShowAll()}>더보기</MoreBtn>
                  )}
              </Text>
            )}
          </TextContainer>
          {/* <ProductContainer>
          <ProductImage src={productImage} />
          <ProductInfo>
            <Text fontStyle={font.caption2} color={color.gray.d600}>
              {storeName}
            </Text>
            <Text fontStyle={font.body2} color={color.gray.d900}>
              {productName}
            </Text>
          </ProductInfo>
        </ProductContainer> */}
        </MessageContainer>
      )}
    </TopContainer>
  );
};

export default CouponTopContent;
