
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Btn from './../../../components/common/Btn';
export default function ChangeStore(){

    let obj;

    async function submit(){  
        var formData = new FormData();
        formData.append('signupRequest', obj);
        // formData.append('logo', file);
    }

    return (
        <>
            <Container>
                <Btn type="NAVBACK" title="스토어 변경" />
                <Row>
                    <Col>
                        <h4>등록된 스토어 변경하기</h4>
                        <h6>한 계정으로 여러 스토어를 관리할 수 있습니다.</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul className='Box'>
                            <li>
                                <h4>상호명</h4>
                                <Form.Label>
                                    스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소
                                    스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소
                                </Form.Label>
                            </li>
                            <hr/>
                            <li>
                                <h4>상호명</h4>
                                <Form.Label>
                                    스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소
                                    스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소스토어 주소
                                </Form.Label>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <div className="d-grid gap-1">
                        <Button variant="primary" size="lg" onClick={submit}>
                            계속하기
                        </Button>
                    </div>
                </Row>
            </Container>
        </>
    )
}