import { useState, useEffect } from "react";
import axios from "axios";
import { css, styled } from "styled-components";

import Serviceterms from "../../../../components/common/ServiceTerms";
import Userterms from "../../../../components/common/UserTerms";
import Userterms2 from "../../../../components/common/UserTerms2";
import ConsignmentTerms from "../../../../components/common/ConsignmentTerms";
import { getApiUrl, getRedirctUrl } from "../../../../function/common";
import Layout from "../../../../components/ver1.5/layout/Layout";
import Text from "../../../../components/ver1.5/atom/Text";
import { presetHeight } from "../../../../components/ver1.5/constants";
import AgreementRadio from "./components/molecule/AgreementRadio";
import Button from "../../../../components/ver1.5/atom/Button";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import { ToggleIcon } from "../../../../components/ver1.5/assets/icons";

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.6rem 0.8rem 2.4rem;
  margin-top: 0.8rem;
  max-height: ${(p) => (p.$open ? "300vh" : 0)};
  opacity: ${(p) => (p.$open ? 1 : 0)};
  visibility: ${(p) => (p.$open ? "visible" : "hidden")};
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
`;

const SubTermContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding-top: 1.6rem;
  padding-left: 3.2rem;
`;

const RowWrapper = styled.div`
  display: flex;
  gap: 1.6rem 2.4rem;
  flex-wrap: wrap;
  > * {
    flex-shrink: 0;
  }
`;

const AllCheckContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  border: 1px solid ${color.gray.d100};
  border-radius: 1.2rem;
`;

const IconWrapper = styled.div`
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  ${(p) =>
    p.$open &&
    css`
      transform: rotate(180deg);
    `}
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
`;

export const Typography = ({
  children,
  size,
  weight,
  color,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  textAlign,
  className,
  onClick,
  styles,
}) => (
  <label
    style={{
      fontSize: size ?? "16px",
      fontWeight: weight ?? 400,
      color: color ?? "#606060",
      ...(marginLeft && { marginLeft: marginLeft }),
      ...(marginRight && { marginRight: marginRight }),
      ...(marginTop && { marginTop: marginTop }),
      ...(marginBottom && { marginBottom: marginBottom }),
      ...(textAlign && { textAlign: textAlign }),
      ...(onClick && { cursor: "pointer" }),
      ...(styles && styles),
    }}
    className={className}
    onClick={() => (onClick ? onClick() : null)}
  >
    {children}
  </label>
);

const terms = [
  {
    checkId: "s1",
    label: "(필수) 서비스이용 및 개인정보처리 약관",
    termComponent: (
      <Serviceterms
        customBtn={(onClick) => (
          <Button
            variant="text"
            text="보기"
            onClick={() => onClick()}
            textBtnFontStyle={font.caption2}
            textBtnColor={color.gray.d400}
          />
        )}
      />
    ),
  },
  {
    checkId: "s2",
    label: "(필수) 개인정보 수집 및 이용 동의",
    termComponent: (
      <Userterms
        customBtn={(onClick) => (
          <Button
            variant="text"
            text="보기"
            onClick={() => onClick()}
            textBtnFontStyle={font.caption2}
            textBtnColor={color.gray.d400}
          />
        )}
      />
    ),
  },
  {
    checkId: "s3",
    label: "(필수) 개인정보처리방침",
    termComponent: (
      <Userterms2
        customBtn={(onClick) => (
          <Button
            variant="text"
            text="보기"
            onClick={() => onClick()}
            textBtnFontStyle={font.caption2}
            textBtnColor={color.gray.d400}
          />
        )}
      />
    ),
  },
  {
    checkId: "s4",
    label: "(필수) 상품권 발행 및 판매 위탁 특약",
    termComponent: (
      <ConsignmentTerms
        customBtn={(onClick) => (
          <Button
            variant="text"
            text="보기"
            onClick={() => onClick()}
            textBtnFontStyle={font.caption2}
            textBtnColor={color.gray.d400}
          />
        )}
      />
    ),
  },
  {
    label: "(선택) 마케팅 정보 수신 동의",
    // termComponent: <Serviceterms />
    children: [
      {
        checkId: "s5",
        label: "앱 푸시",
      },
      {
        checkId: "s6",
        label: "문자",
      },
      {
        checkId: "s7",
        label: "이메일",
      },
      {
        checkId: "s8",
        label: "전화",
      },
    ],
  },
];

const Join = () => {
  const [agreementOpen, setAgreementOpen] = useState(true);
  const [isCheckEssential, setIsCheckEssential] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [validation, setValidation] = useState({
    phoneNumber: false,
  });

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);

    setIsCheck(Array.from({ length: 9 }, (_, i) => "s" + (i + 1)));
    if (isCheckAll) {
      setIsCheck([]);
      setIsCheckEssential(false);
    } else {
      setIsCheckEssential(true);
    }
  };

  const handleClick = (target) => {
    const { checkId } = target;
    if (checkId) {
      if (isCheck.find((id) => id === checkId)) {
        setIsCheck(isCheck.filter((id) => id !== checkId));
      } else {
        setIsCheck([...isCheck, checkId]);
      }
    } else {
      const childrenTerms = terms.find((term) => !term.checkId).children;
      const childrenLength = childrenTerms.filter((child) =>
        isCheck.includes(child.checkId)
      ).length;
      if (childrenLength < 4) {
        childrenTerms.forEach((term) => {
          return setIsCheck((prev) => [
            ...prev.filter((check) => check !== term.checkId),
            term.checkId,
          ]);
        });
      } else {
        for (let term of childrenTerms) {
          setIsCheck((prev) => prev.filter((check) => check !== term.checkId));
        }
      }
    }
  };

  const [tokenVersionId, setTokenVersionId] = useState();
  const [encData, setEncData] = useState();
  const [integrityValue, setIntegrityValue] = useState();

  // console.log(JSON.stringify(isCheck));
  const PhoneNumber = async (e) => {
    // 'redirectUrl' : getApiUrl('certification'),
    let obj = {};
    // if (window.location.href.indexOf("/store/") !== -1) {
    //   obj = {
    //     popupyn: "N",
    //     redirectUrl: getRedirctUrl("store/certification"),
    //     customData: JSON.stringify(isCheck),
    //   };
    // } else {
    obj = {
      popupyn: "N",
      redirectUrl: getRedirctUrl("user/certification"),
      customData: JSON.stringify(isCheck),
    };
    // }

    await axios
      .post(
        getApiUrl("auth/self"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        // console.log(response);
        if (response.status === 200) {
          setTokenVersionId(response.data.token_version_id);
          setEncData(response.data.enc_data);
          setIntegrityValue(response.data.integrity_value);
        } else {
          setValidation({ ...validation, phoneNumber: false });
        }
      })
      .catch(function (error) {
        setValidation({ ...validation, phoneNumber: false });
      });
  };

  useEffect(() => {
    if (
      integrityValue !== null &&
      integrityValue !== "" &&
      integrityValue !== undefined
    ) {
      document.getElementById("NiceID").submit();
    }
  }, [integrityValue]);

  useEffect(() => {
    if (isCheck.length <= 8) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(true);
    }
  }, [isCheck]);

  useEffect(() => {
    if (isCheck.filter((id) => parseInt(id.split("s")[1]) < 5).length === 4) {
      setIsCheckEssential(true);
    } else {
      setIsCheckEssential(false);
    }
  }, [isCheck]);

  // 기본 페이지 분기

  return (
    <Layout
      styles={css`
        padding: 3.2rem 2.4rem;
        padding-top: ${`calc(${presetHeight.header} + 3.2rem)`};
        padding-bottom: ${`calc(${presetHeight.bottomNav} + 3.2rem)`};
      `}
    >
      <Text
        styles={css`
          font-family: "Pretendard Bold", sans-serif;
          font-weight: 700;
          font-size: 3.2rem;
          letter-spacing: -0.1rem;
        `}
        marginBottom="3.2rem"
      >
        약관에 동의해주세요
      </Text>
      <AllCheckContainer>
        <AgreementRadio
          labelFontStyle={font.subtitle2}
          label={"이용약관 전체 동의"}
          checked={isCheckAll}
          onClick={handleSelectAll}
        />
        <IconWrapper
          onClick={() => setAgreementOpen(!agreementOpen)}
          $open={agreementOpen}
        >
          <ToggleIcon />
        </IconWrapper>
      </AllCheckContainer>
      <TermsContainer $open={agreementOpen}>
        {terms.map((term, index) => (
          <AgreementRadio
            key={index}
            label={term.label}
            checked={
              !term.children
                ? isCheck.includes(term.checkId)
                : term.children.filter((child) =>
                    isCheck.includes(child.checkId)
                  ).length > 0
            }
            onClick={() => handleClick(term)}
            termComponent={term.termComponent}
          />
        ))}
        <SubTermContainer>
          <div>
            <Text fontStyle={font.caption1} color={color.gray.d600}>
              플렉스데이에서 제공하는
            </Text>
            <Text fontStyle={font.caption1} color={color.gray.d600}>
              각종 혜택 · 이벤트 소식을 받아보세요!
            </Text>
          </div>
          <RowWrapper>
            {terms
              .find((term) => term.children)
              .children.map((term) => {
                return (
                  <AgreementRadio
                    key={term.checkId}
                    label={term.label}
                    checked={
                      !term.children
                        ? isCheck.includes(term.checkId)
                        : term.children.filter((child) =>
                            isCheck.includes(child.checkId)
                          ).length > 0
                    }
                    onClick={() => handleClick(term)}
                  />
                );
              })}
          </RowWrapper>
        </SubTermContainer>
      </TermsContainer>
      <BtnWrapper>
        <Button
          disabled={!isCheckEssential}
          onClick={() => {
            isCheckEssential
              ? PhoneNumber()
              : alert("필수 이용약관에 동의해주세요.");
          }}
          text="본인인증"
        />
      </BtnWrapper>
      <form
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
        id="NiceID"
        method="post"
      >
        <input type="hidden" name="m" value="checkplusSerivce" />
        <input
          type="hidden"
          id="token_version_id"
          name="token_version_id"
          value={tokenVersionId || ""}
          onChange={(e) => setTokenVersionId(e.target.value)}
        />
        <input
          type="hidden"
          id="enc_data"
          name="enc_data"
          value={encData || ""}
          onChange={(e) => setEncData(e.target.value)}
        />
        <input
          type="hidden"
          id="integrity_value"
          name="integrity_value"
          value={integrityValue || ""}
          onChange={(e) => setIntegrityValue(e.target.value)}
        />
      </form>
    </Layout>
  );
};

export default Join;
