import { useEffect, useRef } from 'react';
import { RenderAfterNavermapsLoaded } from 'react-naver-maps';

export default function Map ( props ) {

    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);

    // const handleLoad = () => setLoading(false);
    // const handleError = (error) => setError(error);

    // let map;

    // useEffect(() => {

    //     const client = "f5m7nn0j50";
    //     const script = document.createElement('script');
    //     script.src = "https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId="+client;
    //     script.async = true;
    //     document.body.appendChild(script);

    //     script.addEventListener("load", handleLoad);
    //     script.addEventListener("error", handleError);

    //     map = document.createElement('div');
    //     map.key='NaverMap';
    //     map.id ='map';
    //     map.style.width="100%";
    //     map.style.height="auto";
    //     map.style.minHeight ="300px";
    //     //document.getElementById('root').append(map);

    //     return () => {
    //         script.removeEventListener("load", handleLoad);
    //         script.removeEventListener("error", handleError);
    //     };
    // }, []);

    // if (error) return <p>오류!</p>;
    // if (loading) return <p>로딩 중 ...</p>;

    // const { naver } = window;

    // map = document.createElement('div');
    // map.id ='map';
    // map.style = { width: '500px', height: '500px' };
    // document.body.appendChild(map);

    // const location = new naver.maps.LatLng(37.3595704, 127.105399);
    // // 지도에 표시할 위치의 위도와 경도 설정
    
    // const mapOptions = {
    //     center: location,
    //     // 중앙에 배치할 위치
    //     zoom: 17,
    //     // 확대 단계
    // };

    // map = new naver.maps.Map('map', mapOptions);

    // const markser = new naver.maps.Marker({
    //     map,
    //     position: location,
    // });
    
    // const location = new naver.maps.LatLng(37.3595704, 127.105399);
    

    // const mapOptions = {
    //     center: location,
    //     zoom: 17,
    // };

    // const map = new naver.maps.Map('map', mapOptions);
    // DOM 요소에 지도 삽입 (지도를 삽입할 HTML 요소의 id, 지도의 옵션 객체)
    // const markser = new naver.maps.Marker({
    //     map,
    //     position: location,
    // });

    const mapElement = useRef(null);

    useEffect(() =>{
        if (document.querySelector('script[src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=f5m7nn0j50"]')){
            return;
        }
        const script = document.createElement("script");
        script.src = "https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=f5m7nn0j50";
        script.async = true;
        document.body.appendChild(script);

        const { naver } = window;
        if (!mapElement.current || !naver) return;

        // const location = new naver.maps.LatLng(37.5656, 126.9769);
        // const mapOptions = {
        //     center: location,
        //     zoom: 17,
        //     zoomControl: true,
        //     zoomControlOptions: {
        //         position: naver.maps.Position.TOP_RIGHT,
        //     },
        // };

        // const map = new naver.maps.Map(mapElement.current, mapOptions);
        //     new naver.maps.Marker({
        //     position: location,
        //     map,
        // });

        const map = new naver.maps.Map(mapElement.current, {
            center: new naver.maps.LatLng(37.3595704, 127.105399),
            zoom: 10
        });

    },[]);
  
    return (
        <>
            <div ref={mapElement} style={{ minHeight: '400px' }} />
        </>
    )
}