export const phoneNumberFormatter = (e) => {
  let tmp = "";
  if (e.length < 4) {
    if (e.length >= 3) {
      tmp += e;
      tmp += "-";
      return tmp;
    } else {
      return e;
    }
  } else if (e.length < 8) {
    tmp += e.substr(0, 3);
    tmp += "-";
    tmp += e.substr(3);
    return tmp;
  } else {
    tmp += e.substr(0, 3);
    tmp += "-";
    tmp += e.substr(3, 4);
    tmp += "-";
    tmp += e.substr(7);
    return tmp;
  }
};

export const dateFormatter = ({ date: dateProp, separator = "." }) => {
  const targetArr = dateProp.split(" ")[0].split("-");
  let result = "";
  targetArr.forEach((target, index) => {
    if (index === targetArr.length - 1) {
      result += target;
    } else {
      result += target + separator;
    }
  });
  return 20 + result;
};

export const localeStringFormatter = (num, unit) => {
  const result = Number(num).toLocaleString() + (unit ? unit : "");
  return result;
};
