import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

import { AlertError, getApiUrl, PrintConsole } from "../../function/common";
import Btn from "../../components/common/Btn";
import { getImgUrl, setCommas } from "./../../function/common";

import "./PosMain.css";
import { Modal } from "react-bootstrap";
import KeyPad from "./components/KeyPad";

export default function PosMain() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});
  const onChangeData = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
    PrintConsole(data);
  };

  const [mode, setMode] = useState("STEP1");

  const [appData, setAppData] = useState([]);

  const [totalPrice, setTotalPrice] = useState({});
  const onChangeTotalPrice = (e) => {
    e.target.value = e.target.value.replace(/^[0-9]/, "");
    //데이터셋 변경시 id값 읽어 대입
    setTotalPrice({
      ...totalPrice,
      [e.target.id]: e.target.value,
    });
    PrintConsole(totalPrice);
    // inputNumberCheck();
  };

  const [sumItem, setSumItem] = useState({});

  function inputNumberCheck() {
    let PriceInput = document.querySelector(".PayPrice");
    let Value = PriceInput.value;

    if (Value < 1000) {
      let Btn = document.querySelector(".btn-numcheck");
      Btn.classList.add("btn-secondary");
      Btn.classList.remove("btn-default");

      PriceInput.classList.add("active");
    } else {
      let Btn = document.querySelector(".btn-numcheck");
      Btn.classList.remove("btn-secondary");
      Btn.classList.add("btn-default");

      PriceInput.classList.remove("active");
    }
  }

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }
  PrintConsole(Parameters);

  function checkAuth() {
    //권한
    axios
      .get(
        getApiUrl("users/me/stores/" + localStorage.getItem("storeDomain")),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        if (response.status === 200) {
        }
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.code == -6) {
          localStorage.removeItem("accessToken");
          navigate("/pos2/login");
        } else {
          AlertError(error);
        }
      });
  }

  // const [item, setItem] = useState();
  function getOrderItem() {
    // alert(localStorage.getItem('accessToken'));
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl(
            "stores/" +
              localStorage.getItem("storeDomain") +
              "/coupons/" +
              document.getElementById("CouponNumber").value
          ),
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then(function (response) {
          // setItem(response.data);
          let row = response.data;
          console.log(row);
          console.log(row.status["code"]);
          if (row.status["code"] === 1 || row.status["code"] === "1") {
            setAppData((appData) => [
              {
                productName: row.productName,
                couponNumber: row.couponNumber,
                storeSalesPrice: row.priceInfo["storeSalesPrice"],
                productstatus: row.status["code"],
                expireDate: row.expireDate,
              },
              ...appData,
            ]);

            setMode("STEP2");
          } else if (row.status["code"] === 2 || row.status["code"] === "2") {
            alert("사용이 불가능한 교환권 입니다.");
            setMode("STEP2");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      if (response) {
        return resolve(response);
      }
    });
  }

  async function useGift() {
    document.getElementById("submitBtn").setAttribute("disabled", "true");

    if (
      document.getElementById("CouponNumber").value === null ||
      document.getElementById("CouponNumber").value === "" ||
      document.getElementById("CouponNumber").value.length < 11
    ) {
      alert("잘못된 교환권 번호입니다.");
      document.getElementById("submitBtn").removeAttribute("disabled");
      return false;
    }

    axios
      .post(
        getApiUrl(
          "stores/" +
            localStorage.getItem("storeDomain") +
            "/mobile-couponNumber/" +
            document.getElementById("CouponNumber").value
        ),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        console.log(response.status);
        if (response.status === 200) {
          console.log(response.data.redirectUrl);
          let TmpUrl = response.data.redirectUrl.split("usegift?");
          console.log("/pos2/check?" + TmpUrl[1]);
          getOrderItem();
        }
      })
      .catch(function (error) {
        AlertError(error);
        document.getElementById("submitBtn").removeAttribute("disabled");
      });
  }

  async function cancelGift() {
    document.getElementById("submitBtn").setAttribute("disabled", "true");

    if (
      document.getElementById("CouponNumber").value === null ||
      document.getElementById("CouponNumber").value === "" ||
      document.getElementById("CouponNumber").value.length < 11
    ) {
      alert("잘못된 교환권 번호입니다.");
      document.getElementById("submitBtn").removeAttribute("disabled");
      return false;
    }

    axios
      .post(
        getApiUrl(
          "stores/" +
            localStorage.getItem("storeDomain") +
            "/mobile-couponNumber/" +
            document.getElementById("CouponNumber").value
        ),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data.redirectUrl);
          let TmpUrl = response.data.redirectUrl.split("usegift?");
          console.log("/pos2/check?" + TmpUrl[1]);
          window.location.href = "/pos2/check?" + TmpUrl[1];
        }
      })
      .catch(function (error) {
        AlertError(error);
        document.getElementById("submitBtn").removeAttribute("disabled");
      });
  }

  const [slicePosition, setSlicePosition] = useState(undefined);
  function InputPos(Id, Val) {
    if (Val === "CLEAR") {
      document.getElementById(Id).value = "";
      if (Id === "TotalPrice") {
        setTotalPrice({
          ...totalPrice,
          TotalPrice: 0,
        });
      }
    } else if (Val === "DEL") {
      let before = document.getElementById(Id).value;
      document.getElementById(Id).value = before.slice(0, -1);
      if (Id === "TotalPrice") {
        setTotalPrice({
          ...totalPrice,
          TotalPrice: before.slice(0, -1),
        });
      }
    } else {
      let before = document.getElementById(Id).value;
      if (
        !isNaN(slicePosition) &&
        slicePosition !== undefined &&
        slicePosition !== null &&
        slicePosition !== before.toString().length
      ) {
        const firstValue = before.toString().slice(0, slicePosition);
        const lastValue = before.toString().slice(slicePosition);
        document.getElementById(Id).value =
          firstValue + Val.toString() + lastValue;
        setSlicePosition((prev) => prev + 1);
      } else {
        document.getElementById(Id).value = before.toString() + Val.toString();
        setSlicePosition(undefined);
      }
      if (Id === "TotalPrice") {
        document.getElementById(Id).value = parseInt(
          document.getElementById(Id).value
        );
        setTotalPrice({
          ...totalPrice,
          TotalPrice: (before + Val) * 1,
        });
      }
    }
  }

  useEffect(() => {
    const target = document.querySelector("input.form-control.InputType");
    if (!target) return;
    target.addEventListener("click", (e) => {
      setSlicePosition(e.target.selectionStart);
    });
  }, [mode]);

  useEffect(() => {
    setSlicePosition(undefined);
  }, [mode]);

  function itemDelete(delItem) {
    setAppData(appData.filter((row) => row.couponNumber !== delItem));
  }

  const bulkUseGift = async (e) => {
    let obj = {
      numbers: [],
      totalPrice: totalPrice["TotalPrice"],
    };
    appData.map((row) => {
      obj["numbers"].push(row.couponNumber);
    });

    console.log(obj);

    await axios
      .post(
        getApiUrl(
          "stores/" + localStorage.getItem("storeDomain") + "/coupons/"
        ),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;"),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        PrintConsole(response.data);
        if (response.status === 200) {
          setMode("STEP3");
        } else {
          alert("교환권이 사용 처리에 실패하였습니다.");
        }
      })
      .catch(function (error) {
        PrintConsole(error);
        AlertError(error);
      });
  };

  useEffect(() => {
    //데이터 로딩
    setLoading(true);
    if (
      localStorage.getItem("storeDomain") === undefined ||
      localStorage.getItem("storeDomain") === null
    ) {
      alert("로그인이 필요한 서비스 입니다.");
      localStorage.clear();
      navigate("/pos2/login");
    }
    checkAuth();
    //데이터 로딩 완료
    setLoading(false);
  }, []);

  useEffect(() => {
    var TmpSum = 0;

    console.log(appData.length);
    if (appData.length > 0) {
      appData.map((row) => {
        console.log(row.productstatus);
        if (row.productstatus === "1" || row.productstatus === 1) {
          TmpSum = parseInt(TmpSum) + parseInt(row.storeSalesPrice);
        }
      });
    }
    setSumItem(TmpSum);
  }, [appData]);

  useEffect(() => {
    if (location.pathname.includes("pos2/main")) {
      document.body.classList.add("showScrollbar");
    } else {
      document.body.classList.remove("showScrollbar");
    }
  }, [location]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (loading) return <></>;

  if (mode === "STEP1") {
    return (
      <>
        <div className="demo Pos2 PaymentPage  align-items-center d-flex demo flex-column ">
          <Button
            variant="default Pos2_Logout"
            onClick={() => {
              navigate("/pos2/logout");
            }}
          >
            로그아웃
          </Button>
          <Container className="PosInner">
            <div className="PaymentBox">
              <div className="Payment-title mt-5">
                <p className="PageTit mb-1">
                  총 결제 금액
                  <img
                    className="img-fluid mb-1"
                    src="https://www.flexdaycdn.net/public/images/static/payment/pay_icon.png"
                    alt=""
                  />
                </p>
                <p className="PageSib">
                  결제할 총 금액을 입력해주세요. <br />
                  여러 개의 결제를 한 번에 할 수 있어요!
                </p>
              </div>
              <div className="Payment-Contents row mb-4">
                <div className="col Payment-Inputbox position-relative">
                  <input
                    type="text"
                    className="form-control InputType PayPrice"
                    placeholder="총 결제 금액"
                    required
                    id="TotalPrice"
                    onChange={onChangeTotalPrice}
                  />
                  <div className="end-0 me-3 mt-3 pe-3 position-absolute price-txt top-0">
                    원
                  </div>
                  <div className="form-text info-txt">
                    1,000원부터 입력 가능합니다.
                  </div>
                </div>
              </div>
            </div>

            <KeyPad onClick={(value) => InputPos("TotalPrice", value)} />
            <Row className="mt-5 submitBtnWrapper">
              <Col className="align-self-end">
                <Button
                  variant="default btnSubmit"
                  id="submitBtn"
                  onClick={() => {
                    setMode("STEP2");
                  }}
                >
                  결제하기
                </Button>
                <p className="Payment-info-txt mt-3 text-center">
                  <a
                    href="#"
                    onClick={() => {
                      setMode("DEL");
                    }}
                  >
                    결제 취소
                  </a>
                  가 필요하세요?
                </p>
              </Col>
            </Row>
            <p style={{ color: "#d3d3d3", textAlign: "center" }}>
              {process.env.REACT_APP_VERSION}
            </p>
          </Container>
        </div>
      </>
    );
  } else if (mode === "STEP2") {
    return (
      <>
        <div className="demo Pos2 Pos">
          <div className="PosInner d-flex flex-column vh-100">
            <div className="PaymentBox pt-5">
              <div className="PayPriceTotal">
                <div className="row py-2 px-4-5 align-items-center g-0">
                  <div className="col-7 tit">총 결제 금액</div>
                  <div className="col-5 price text-end">
                    <span className="price-total me-1">
                      {setCommas(totalPrice["TotalPrice"])}
                    </span>
                    원
                  </div>
                </div>
              </div>

              <div className="PayPriceAdd">
                <div className="row py-3 px-4-5 align-items-center g-0">
                  <div className="col-7 tit">추가로 결제하실 금액</div>
                  <div className="col-5 price text-end">
                    <span className="price-add me-1">
                      {setCommas(totalPrice["TotalPrice"] - sumItem)}
                    </span>
                    원
                  </div>
                </div>
              </div>
            </div>

            <div className="PaymentList">
              <div className="align-items-center g-0 py-4 row">
                {appData &&
                  appData.map((row) => (
                    <div
                      className="col-md-12 col-sm-12 mb-4-5 px-4-5"
                      key={row.couponNumber}
                    >
                      <div className="PaymentList-Item position-relative">
                        <div className="d-flex align-items-end g-0 justify-content-between prd-titbox row">
                          <div className="name w-75">{row.productName}</div>
                          <div className="text-end use w-25">
                            {row.productstatus === 1
                              ? "사용가능"
                              : "사용 불가능"}
                          </div>
                        </div>
                        <div className="align-items-end d-flex justify-content-between prd-infobox">
                          <div className="price">
                            <span>{setCommas(row.storeSalesPrice)}</span>원
                          </div>
                          <div className="date text-end">{row.expireDate}</div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-clear end-0 p-2 position-absolute top-0"
                          onClick={() => {
                            itemDelete(row.couponNumber);
                          }}
                        >
                          <img
                            src="https://www.flexdaycdn.net/public/images/static/payment/close_btn.png"
                            alt=""
                            className="img-fluid del_btn"
                          />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="Payment-Confirm d-flex PosInner">
              <button
                type="button"
                className="align-items-center btn btn-light d-flex justify-content-center w-50"
                onClick={() => {
                  setMode("ADD");
                }}
              >
                {/* <img className="img-fluid me-2 qr-scan"
                                    src="https://www.flexdaycdn.net/public/images/static/payment/qr_scan.png" alt=""/> */}
                교환권 입력
              </button>
              <Button
                variant="default"
                onClick={() => {
                  handleShow();
                }}
              >
                결제 완료
              </Button>
            </div>

            <Modal
              size="sm"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="modal fade modal-prd-stop text-center Pos2_modal"
              centered
            >
              <Modal.Header className="pt-4-5">
                <Modal.Title>
                  <p className="modal-title">결제 하시겠습니까?</p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-clear btn-modal-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-clear btn-modal-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    bulkUseGift();
                  }}
                >
                  확인
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    );
  } else if (mode === "STEP3") {
    return (
      <>
        <div className="PaymentPage Pos Pos2 vh-100 d-flex">
          <div className="container-fluid g-0 PosInner">
            <div className="PaymentBox Inner" style={{ maxWidth: "600px" }}>
              <div className="Payment-title my-5 text-center">
                <img
                  className="img-fluid mb-4"
                  src="https://www.flexdaycdn.net/public/images/static/payment/smile_emoji.png"
                  alt=""
                />
                <p className="PageTit2 done mb-1">
                  결제가 성공적으로 <br />
                  완료되었습니다.
                </p>
              </div>

              <div className="PaymentResult mb-5 px-4-5 py-4-5">
                <div className="PaymentResult-Item d-flex justify-content-between mb-2">
                  <div className="tit w-50">입력하신 금액</div>
                  <div className="price text-end w-50">
                    <span>{setCommas(totalPrice["TotalPrice"])}</span>원
                  </div>
                </div>

                <div className="PaymentResult-Item d-flex mb-3">
                  <div className="tit w-50">결제 완료 금액</div>
                  <div className="price text-end w-50">
                    <span>{setCommas(sumItem)}</span>원
                  </div>
                </div>
                <div className="PaymentResult-Item add-price d-flex">
                  <div className="tit w-75">추가로 결제하실 금액</div>
                  <div className="price text-end w-50">
                    <span>{setCommas(totalPrice["TotalPrice"] - sumItem)}</span>
                    원
                  </div>
                </div>
              </div>
            </div>

            <div
              className="Payment-Confirm d-flex mx-auto pt-5"
              style={{ maxWidth: "600px" }}
            >
              <button
                type="button"
                className="btn2 btn-default"
                onClick={() => {
                  setMode("STEP1");
                  setAppData([]);
                  handleClose();
                }}
              >
                확인
              </button>
              {/* <p className="Payment-info-txt mt-3 text-center">
                                <a href="javascript:void(0);" onClick={()=>{}}>결제 취소</a>가 필요하세요?
                            </p> */}
            </div>
          </div>
        </div>
      </>
    );
  } else if (mode === "ADD") {
    return (
      <>
        <div className="demo Pos2 PaymentPage  align-items-center d-flex demo flex-column h-100vh vh-100">
          <Button
            variant="default Pos2_Logout"
            onClick={() => {
              navigate("/pos2/logout");
            }}
          >
            로그아웃
          </Button>
          <Container className="PosInner">
            <Row className="mt-5 posTopWrapper">
              <Col>
                <Button
                  variant="light Pos2_Prev"
                  onClick={() => {
                    setMode("STEP2");
                  }}
                >
                  이전
                </Button>
                <p className="PageSib2">
                  <span className="PointText">*</span>
                  교환권 번호를 입력해주세요.
                </p>
                <div className="Payment-Contents">
                  <div className="Payment-Inputbox">
                    <input
                      type="text"
                      id="CouponNumber"
                      name="Id"
                      className="form-control InputType"
                      placeholder="교환권 번호를 입력해주세요. ('-' 없이 숫자만 입력)"
                      onChange={onChangeData}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <KeyPad onClick={(value) => InputPos("CouponNumber", value)} />
            <Row className="mt-5 submitBtnWrapper">
              <Col className="align-self-end">
                <Button
                  variant="default btnSubmit"
                  id="submitBtn"
                  onClick={useGift}
                >
                  교환권 조회
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  } else if (mode === "DEL") {
    return (
      <>
        <div className="demo Pos2 PaymentPage  align-items-center d-flex demo flex-column h-100vh vh-100">
          <Container className="PosInner">
            <div className="PaymentBox">
              <Row className="mt-5 posTopWrapper">
                <Col>
                  <Button
                    variant="light Pos2_Prev"
                    onClick={() => {
                      setMode("STEP1");
                    }}
                  >
                    이전
                  </Button>
                  <p>
                    <label className="PointText">*</label> 교환권 번호를
                    입력해주세요.
                  </p>
                  <input
                    type="text"
                    id="CouponNumber"
                    name="Id"
                    className="form-control InputType"
                    placeholder="교환권 번호를 입력해주세요. ('-' 없이 숫자만 입력)"
                    onChange={onChangeData}
                  />
                </Col>
              </Row>
            </div>

            <KeyPad onClick={(value) => InputPos("CouponNumber", value)} />
            <Row className="mt-5 submitBtnWrapper">
              <Col className="align-self-end">
                <Button variant="primary" id="submitBtn" onClick={cancelGift}>
                  교환권 조회
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
