import { Container, Row, Button, Col, Form, Accordion, Card, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import Btn from '../../../components/common/Btn';
import { getApiUrl, getRedirctUrl } from '../../../function/common';
import Serviceterms from '../../../components/common/ServiceTerms';
import Userterms from './../../../components/common/UserTerms';
import Userterms2 from './../../../components/common/UserTerms2';
import ConsignmentTerms from './../../../components/common/ConsignmentTerms';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';

export const Typography = ({children, size, weight, color, marginLeft, marginRight, marginTop, marginBottom, textAlign, className, onClick, styles}) => <label style={{
    fontSize: size ?? '16px',
    fontWeight: weight ?? 400,
    color: color ?? '#606060',
    ...(marginLeft && {marginLeft: marginLeft}),
    ...(marginRight && {marginRight: marginRight}),
    ...(marginTop && {marginTop: marginTop}),
    ...(marginBottom && {marginBottom: marginBottom}),
    ...(textAlign && {textAlign: textAlign}),
    ...(onClick && {cursor: 'pointer'}),
    ...(styles && styles)
}}
    className={className}
    onClick={() => onClick ? onClick() : null}
>
    {children}
</label>

const terms = [
    {
        checkId: 's2',
        label: '(필수) 서비스 이용약관 / 개인정보 처리 약관',
        termComponent: <Serviceterms />
    },
    {
        checkId: 's3',
        label: '(필수) 개인정보 수집 및 이용 동의서',
        termComponent: <Userterms />
    },
    {
        checkId: 's4',
        label: '(필수) 개인정보 처리 방침',
        termComponent: <Userterms2 />
    },
    {
        checkId: 's10',
        label: '(필수) 상품권 발행 및 판매 위탁 특약',
        termComponent: <ConsignmentTerms />
    },
    {
        label: '(선택) 마케팅 정보 수신 동의',
        // termComponent: <Serviceterms />
        children: [
            {
                checkId: 's5',
                label: '앱푸쉬',
            },
            {
                checkId: 's6',
                label: '문자',
            },
            {
                checkId: 's7',
                label: '이메일',
            },
            {
                checkId: 's8',
                label: '전화',
            },
            {
                checkId: 's9',
                label: '우편',
            },
        ]
    },
]

export default function Join(){

    const [isCheckEssential, setisCheckEssential] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([
        {'id':'s1'}, {'id':'s2'},{'id':'s3'},{'id':'s4'},{'id':'s5'},{'id':'s6'},{'id':'s7'},{'id':'s8'},{'id':'s9'},{'id':'s10'}
    ]);

    const [Params] = useSearchParams();

    var Parameters = [];

    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }

    if(Parameters.length !== 0){
        
    }

    const [validation, setValidation] = useState({
        phoneNumber             : false
    });

    const [data, setData] = useState({
        loginId             : ""
    });

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
            ...data,
            [e.target.id]: e.target.value,
        });

        console.log(data);
    };
    
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
            setisCheckEssential(false);
        }else{
            setisCheckEssential(true);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        if (id) {
            setIsCheck([...isCheck, id]);
    
            // let s1 = document.getElementById('s1');
            let s2 = document.getElementById('s2');
            let s3 = document.getElementById('s3');
            let s4 = document.getElementById('s4');
            let s10 = document.getElementById('s10');
    
            if (!checked) {
                setIsCheck(isCheck.filter(item => item !== id));
            }
    
            if (s2.checked && s3.checked && s4.checked && s10.checked){
                setisCheckEssential(true);
            }else{
                setisCheckEssential(false);
            }
        } else {
            const childrenTerms = terms.find(term => !term.checkId).children
            const childrenLength = childrenTerms.filter(child => isCheck.includes(child.checkId)).length
            if (childrenLength < 5) {
                childrenTerms.forEach(term => {
                    return setIsCheck(prev => [...prev.filter(check => check !== term.checkId), term.checkId])
                })
            } else {
                for (let term of childrenTerms) {
                    setIsCheck(prev => prev.filter(check => check !== term.checkId))
                }
            }
        }
    };

    const [tokenVersionId, setTokenVersionId]    = useState();
    const [encData       , setEncData]           = useState();
    const [integrityValue, setIntegrityValue]    = useState();

    // console.log(JSON.stringify(isCheck));

    const PhoneNumber = async (e) => {
        // 'redirectUrl' : getApiUrl('certification'),
        let obj = { }
        if ( window.location.href.indexOf("/store/") !== -1 ){
            obj = { 
                'popupyn' : 'N',
                'redirectUrl' : getRedirctUrl('store/certification'),
                'customData' : JSON.stringify(isCheck)
            }
        }else{
            obj = { 
                'popupyn' : 'N',
                'redirectUrl' : getRedirctUrl('user/certification'),
                'customData' : JSON.stringify(isCheck)
            }
        }
        
        await axios.post(
            getApiUrl('auth/self'),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                // console.log(response);
                if(response.status === 200){
                    setTokenVersionId(response.data.token_version_id);
                    setEncData(response.data.enc_data);
                    setIntegrityValue(response.data.integrity_value);
                }else{
                    setValidation({...validation, phoneNumber : false});
                }
            }
        ).catch(function(error){
            setValidation({...validation, phoneNumber : false});
        })
    }

    useEffect( () => {
        if(integrityValue !== null && integrityValue !== '' && integrityValue !== undefined){
            document.getElementById('NiceID').submit();
        }
    },[integrityValue])

    useEffect(() => {
        if (isCheck.length <= 5) {
            setIsCheckAll(false)
        } else {
            setIsCheckAll(true)
        }
    }, [isCheck])


    // 기본 페이지 분기

    return (
        <>
            <Btn type="TITLE" title="회원가입" titleCanClose />
            <Container className='responsiveInner SignupPage'>
                <Col className='d-flex align-items-center justify-content-center mt-5 mb-1'>
                    <Typography
                        size={'20px'}
                        color={'var(--ColorTypeB)'}
                        weight={'var(--Medium)'}
                    >반가워요</Typography>
                    <Image src={'https://www.flexdaycdn.net/public/images/static/login/signup_emoji.png'} width={22} className='img-fluid' />
                </Col>
                <Row className='justify-content-center'>
                    <Typography
                        size={'14px'}
                        color={'var(--ColorTypeC)'}
                        weight={'var(--Regular)'}
                        textAlign={'center'}
                        marginBottom={'2rem'}
                    >약관에 동의해주시면 회원가입이 진행됩니다.</Typography>
                </Row>
                <Accordion className='ver2'>
                    <Accordion.Item>
                        <Row className='accordion-headerd d-flex align-items-center mb-3 g-0'>
                            <FormCheckInput className='CheckInput1 me-0 AllCheck'
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                                name='hobbyAll'
                            />
                            <Col>
                                <Accordion.Button>플렉스데이 이용약관 전체 동의</Accordion.Button>
                            </Col>
                        </Row>
                        <Accordion.Body>
                            {
                                terms.map((term, index) => (
                                    <Row className='pt-1 pb-1 d-flex align-items-center' key={index}>
                                        <Col>
                                            <FormCheckInput className='CheckInput2' id={term.checkId} onChange={handleClick} checked={!term.children ? isCheck.includes(term.checkId) : term.children.filter(child => isCheck.includes(child.checkId)).length > 0} />
                                            <Form.Label className='CheckLabel' htmlFor={term.checkId}>{term.label}</Form.Label>
                                        </Col>
                                        {/* <Form.Check type='checkbox' id='s2' label='(필수) 서비스 이용약관 / 개인정보 처리 약관'  /> */}
                                        <Col className='col-2 d-flex justify-content-end'>
                                            {term.termComponent}
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Accordion.Body>
                        <Row style={{
                            paddingLeft: 'calc(1rem + 28px + .25rem)'
                        }}>
                            <Typography
                                size={'13px'}
                                weight={'var(--Regular)'}
                                color={'var(--ColorTypeC)'}
                                styles={{
                                    wordBreak: 'keep-all'
                                }}
                                className={'mb-3'}
                            >플렉스데이에서 제공하는 각종 혜택 및 이벤트,<br/>사은품에 대한 안내를 받을 수 있습니다.</Typography>
                            <Form.Group className='form-check-group row flex-wrap g-0' style={{gap: '0.5rem 0'}}>
                                {
                                    terms.find(term => term.label.includes('(선택)')).children.map(({checkId, label}) => {
                                        return <Col key={checkId} className='col-4'>
                                            <FormCheckInput className='CheckInput3' id={checkId} onChange={handleClick} checked={isCheck.includes(checkId)} />
                                            <Form.Label className='CheckLabel' htmlFor={checkId}>{label}</Form.Label>
                                        </Col>
                                    })
                                }
                            </Form.Group>
                        </Row>
                    </Accordion.Item>
                </Accordion>
                <Row>
                    <Col xs={{span:10, offset:1}} className='d-grid mt-4'>
                        <Button
                            size="lg"
                            variant={isCheckEssential ? 'primary' : 'primary'}
                            onClick={() => { isCheckEssential ? PhoneNumber() : alert('필수 이용약관에 동의해주세요.')}}
                            style={{
                                backgroundColor: 'var(--ColorTypeB)',
                                borderColor: 'var(--ColorTypeB)'
                            }}
                        >
                            본인인증
                        </Button>
                    </Col>
                </Row>
                <form action='https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb' id='NiceID' method="post">
                    <input type='hidden' name='m' value='checkplusSerivce'/>
                    <input type='hidden' id='token_version_id' name='token_version_id' value={tokenVersionId || ''} onChange={ (e) => (setTokenVersionId(e.target.value))}/>
                    <input type='hidden' id='enc_data' name='enc_data' value={encData || ''} onChange={ (e) => (setEncData(e.target.value))}/>
                    <input type='hidden' id='integrity_value' name='integrity_value' value={integrityValue || ''} onChange={ (e) => (setIntegrityValue(e.target.value))}/>
                </form>
                
            </Container>
        </>
    )
}