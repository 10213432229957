import React, { useEffect, useState } from "react";
import { css, styled } from "styled-components";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";
import {
  NewIcon,
  ToggleIcon,
} from "../../../../../components/ver1.5/assets/icons";
import Divider from "../../../../../components/ver1.5/atom/Divider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem 0;
  gap: ${(p) => (p.$open ? "1.8rem" : 0)};
  transition: gap 0.3s ease-in-out;
`;

const ContentWrapper = styled.div`
  ${font.body2};
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  max-height: ${(p) => (p.$open ? "300vh" : 0)};
  opacity: ${(p) => (p.$open ? 1 : 0)};
  visibility: ${(p) => (p.$open ? "visible" : "hidden")};
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  pre,
  div {
    max-height: ${(p) => (p.$open ? "300vh" : 0)};
    visibility: ${(p) => (p.$open ? "visible" : "hidden")};
    transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  max-width: calc(100% - 3.6rem);
`;

const NewIconWrapper = styled.div`
  align-self: ${(p) => (p.$open ? "center" : "flex-end")};
  margin-bottom: 0.6rem;
  margin-left: 0.4rem;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 2.4rem;
  aspect-ratio: 1;
  margin-left: auto;
  ${(p) =>
    p.$open &&
    css`
      transform: rotate(180deg);
    `}
  transition: transform .3s ease-in-out;
`;

const NoticeListItem = ({ isLast, title, date, content }) => {
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    const target = new Date(date).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    if (target === today) {
      setIsNew(true);
    }
  }, [date]);

  return (
    <>
      <Container $open={open}>
        <TitleContainer onClick={() => setOpen(!open)}>
          <TextWrapper>
            <div>
              <Text
                fontStyle={font.caption2}
                color={color.gray.d600}
                marginBottom="0.4rem"
              >
                {date ? date.split("T")[0] : ""}
              </Text>
              <Text
                fontStyle={font.caption1}
                color={color.gray.d900}
                ellipsis={!open}
              >
                {title}
              </Text>
            </div>
            {isNew && (
              <NewIconWrapper $open={open}>
                <NewIcon />
              </NewIconWrapper>
            )}
          </TextWrapper>
          <IconWrapper $open={open}>
            <ToggleIcon />
          </IconWrapper>
        </TitleContainer>
        <ContentWrapper $open={open}>
          <pre>{content}</pre>
        </ContentWrapper>
      </Container>
      {!isLast && (
        <Divider
          direction="horizontal"
          width={"0.1rem"}
          color={color.gray.d50}
        />
      )}
    </>
  );
};

export default NoticeListItem;
