import React from "react";
import { styled } from "styled-components";
import Button from "../../../../../components/ver1.5/atom/Button";
import {
  color,
  elevation,
} from "../../../../../components/ver1.5/styles/theme";
import { headerFooterZIndex } from "../../../../../components/ver1.5/constants";
import { setCommas } from "../../../../../function/common";
import CheckboxAccordion from "./CheckboxAccordion";
import Divider from "../../../../../components/ver1.5/atom/Divider";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 80rem;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
  gap: 1.6rem;
  background: ${color.primary.white};
  z-index: ${headerFooterZIndex};
  width: 100%;
  box-shadow: ${elevation.d1};
`;

const GiftFooter = ({
  price = 0,
  agree,
  setAgree,
  disabled,
  onClickSubmit,
}) => {
  return (
    <Container>
      <CheckboxAccordion
        title={"플렉스데이 주문내용 확인 및 결제 동의"}
        checked={agree}
        onClick={() => setAgree(!agree)}
      />
      <Divider
        direction="horizontal"
        width={"0.1rem"}
        color={color.gray.d100}
        marginBottom={"1.6rem"}
      />
      <Button
        text={`${setCommas(price)}원 결제하기`}
        disabled={disabled}
        onClick={() => (onClickSubmit ? onClickSubmit() : null)}
      />
    </Container>
  );
};

export default GiftFooter;
