import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { DaumPostcodeEmbed } from 'react-daum-postcode';

// import { GetSignup } from './../../api/ApiBusinessUser';
import axios from 'axios';
import sha256 from 'sha256';
import Footer from './../../components/common/Footer';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApiUrl } from '../../function/common';

export default function Join( props ) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState({
        loginId             : false,
        email               : false,
        businessNumber      : false,
        domain              : false
    });

    const [data, setData] = useState({
        loginId             : "",
        password            : "",
        passwordCk          : "",
        name                : "",
        phoneNumber         : "",
        email               : "",
        businessType        : "1",
        businessNumber      : "",
        domain              : "",
        storePhoneNumber    : "",
        businessName        : "",
        storeName           : "",
        zipCode             : "",
        address             : "",
        addressDetail       : "",
        operationTime       : "",
        holidays            : "",
        recommender         : ""
    });
    

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        // console.log(data);
    };

    const [businessClass, setBusinessClass] = useState([]);

    const onCheckData = (e) => {
        if(e.currentTarget.checked){
            setBusinessClass([
                ...businessClass, e.target.value
            ]);
            e.currentTarget.parentElement.querySelector('label').classList.remove('btn-default');
            e.currentTarget.parentElement.querySelector('label').classList.add('btn-primary');
        }else{
            setBusinessClass(businessClass.filter(row => Number(row) !== Number(e.target.value) ));
            e.currentTarget.parentElement.querySelector('label').classList.remove('btn-primary');
            e.currentTarget.parentElement.querySelector('label').classList.add('btn-default');
        }
        // console.log(businessClass);         
    };

    const fileUpload = useRef();
    const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

    const onClickUpload = () => {
        fileUpload.current.click();
    }

    const onChangeImg = (e) => {
        if(e.target.files){
            const uploadFile = e.target.files[0];
            setFile(uploadFile);
        }
    }

    const onLoginId = (e) => {
        //아이디 중복체크
        let obj = { 
            loginId : data.loginId,
        }
        axios.post(
            'http://118.67.142.31:8095/users/login-id/check',
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    setValidation({...validation, loginId : true});
                    e.target.parentElement.querySelector('div').classList.remove('invalid-feedback');
                    e.target.parentElement.querySelector('div').classList.add('valid-feedback');
                }else{
                    setValidation({...validation, loginId : false});
                    e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
                    e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
                    //강제 포커싱
                    e.target.parentElement.querySelector('input').focus();
                }
            }
        ).catch(function(error){
            setValidation({...validation, loginId : false});
            e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
            e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
            e.target.parentElement.querySelector('div').textContent = error.response.data.errorDetails[0].reason;
        })
    }

    const onEmail = (e) => {
        //이메일 중복체크
        let obj = { 
            email : data.email,
        }
        axios.post(
            'http://118.67.142.31:8095/users/email/check',
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    setValidation({...validation, email : true});
                    e.target.parentElement.querySelector('div').classList.remove('invalid-feedback');
                    e.target.parentElement.querySelector('div').classList.add('valid-feedback');
                }else{
                    setValidation({...validation, email : false});
                    e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
                    e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
                    //강제 포커싱
                    e.target.parentElement.querySelector('input').focus();
                }
            }
        ).catch(function(error){
            setValidation({...validation, email : false});
            e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
            e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
            e.target.parentElement.querySelector('div').textContent = error.response.data.errorDetails[0].reason;
        })
    }

    const onDomain = (e) => {
        //도메인 중복체크
        let obj = { 
            storeDomain : data.domain,
        }
        axios.post(
            'http://118.67.142.31:8095/stores/domain/check',
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    setValidation({...validation, domain : true});
                    e.target.parentElement.querySelector('div').classList.remove('invalid-feedback');
                    e.target.parentElement.querySelector('div').classList.add('valid-feedback');
                }else{
                    setValidation({...validation, domain : false});
                    e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
                    e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
                    //강제 포커싱
                    e.target.parentElement.querySelector('input').focus();
                }
            }
        ).catch(function(error){
            setValidation({...validation, domain : false});
            e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
            e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
            e.target.parentElement.querySelector('div').textContent = error.response.data.errorDetails[0].reason;
        })
    }

    const onBusinessNumber = (e) => {
        //사업자번호 중복체크
        let obj = { 
            businessNumber : data.businessNumber,
        }
        axios.post(
            'http://118.67.142.31:8095/stores/business-number/check',
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    setValidation({...validation, businessNumber : true});
                    e.target.parentElement.querySelector('div').classList.remove('invalid-feedback');
                    e.target.parentElement.querySelector('div').classList.add('valid-feedback');
                }else{
                    setValidation({...validation, businessNumber : false});
                    e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
                    e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
                    //강제 포커싱
                    e.target.parentElement.querySelector('input').focus();
                }
            }
        ).catch(function(error){
            setValidation({...validation, businessNumber : false});
            e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
            e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
            e.target.parentElement.querySelector('div').textContent = error.response.data.errorDetails[0].reason;
        })
    }

    const onPassword = (e) => {
        // let password = document.getElementById("password");
        // let passwordCk = document.getElementById("passwordCk");
        
        if(data.password !== data.passwordCk){
            // console.log(false);
            if(e.target.id === 'passwordCk'){
                e.target.parentElement.querySelector('div').classList.remove('valid-feedback');
                e.target.parentElement.querySelector('div').classList.add('invalid-feedback');
                e.target.parentElement.querySelector('div').textContent = '비밀번호를 확인해 주세요.';
            }
        }else{
            // console.log(true);
            if(e.target.id === 'passwordCk'){
                e.target.parentElement.querySelector('div').classList.remove('invalid-feedback');
                e.target.parentElement.querySelector('div').classList.add('valid-feedback');
                e.target.parentElement.querySelector('div').textContent = '';
            }
        }
    }

    //주소 관련
    const [isOpenPost, setIsOpenPost] = useState(false);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
      };
    
    const onCompletePost = (mapData) => {
        let fullAddress = mapData.address;
        let extraAddr = '';
    
        if (mapData.addressType === 'R') {
            if (mapData.bname !== '') {
                extraAddr += mapData.bname;
            }
            if (mapData.buildingName !== '') {
                extraAddr += extraAddr !== '' ? `, ${mapData.buildingName}` : mapData.buildingName;
            }
            fullAddress += extraAddr !== '' ? ` (${extraAddr})` : '';
        }
        setIsOpenPost(false);

        setData({
            ...data,
            zipCode: mapData.zonecode,
            address: fullAddress
        });
    };

    const [res, setRes] = useState();
    function getData() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get(getApiUrl('/common/signup')).then(
                function(response){
                    // console.log(response);
                    setRes(response.data);
                }
            )
            if (response) {
                return resolve(response);
            }
            // reject(new Error("Request is failed"));
        });
    };

    async function submit(){  
        // console.log(validation);
        if(validation.loginId !== true){
            alert('아이디를 확인해주세요.');
            document.getElementById("loginId").focus();
            return false;
        }
        if(validation.email !== true){
            alert('이메일을 확인해주세요.');
            document.getElementById("email").focus();
            return false;
        }
        if(validation.businessNumber !== true){
            alert('사업자 번호를 확인해주세요.');
            document.getElementById("businessNumber").focus();
            return false;
        }
        if(validation.domain !== true){
            alert('스토어 주소를 확인해주세요.');
            document.getElementById("domain").focus();
            return false;
        }

        if(data.password !== data.passwordCk){
            alert('비밀번호가 다릅니다.'); 
            document.getElementById("password").focus();
            return false;
        }

        let obj;
        let tmpData = { 
            'user' : {
                loginId         : data.loginId,
                password        : sha256(data.password),
                phoneNumber     : '36534153253',
                email           : data.email,
                name            : 'tmp'
            },
            'store' : {
                businessType        : data.businessType,
                businessClassType   : data.businessType,
                businessNumber      : data.businessNumber,
                domain              : data.domain,
                storePhoneNumber    : '00000000000',
                businessName        : data.businessName,
                storeName           : data.storeName,
                zipCode             : data.zipCode,
                address             : data.address,
                addressDetail       : data.addressDetail,
                operationTime       : data.operationTime,
                holidays            : data.holidays,
                recommender         : data.origin,
                businessClass       : businessClass
            }
        }
        // console.log(file);
        obj = new Blob( [JSON.stringify(tmpData)], { type: 'application/json'} );

        var formData = new FormData();
        formData.append('signupRequest', obj);
        formData.append('logo', file);

        axios.post(
            "http://118.67.142.31:8095/users", 
            formData, {
                headers: {
                "Content-Type": "multipart/form-data",
                },
            }
        );
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        getData();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    if(res === undefined || res === null) return <></>
    
    return (
        <>
            <Container>
                <Row className="MainSpace">
                    <Col>
                        <h4>지금 가입하시고</h4>
                        <h4>모바일 선물하기 서비스를 이용하세요.</h4>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="loginId">
                            <Form.Label>아이디 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" onBlur={onLoginId}  placeholder="로그인에 이용할 아이디를 입력해 주세요." onChange={onChangeData} />
                            {/* <Form.Text className="text-muted">
                            중복된 아이디 입니다.
                            </Form.Text> */}
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="password">
                            <Form.Label>비밀번호 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="password" placeholder="" onBlur={onPassword} onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="passwordCk" >
                            <Form.Label>비밀번호 확인 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="password" placeholder="" onBlur={onPassword} onChange={onChangeData}/>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="email">
                            <Form.Label>이메일 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="email" placeholder="" onBlur={onEmail} onChange={onChangeData}/>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h4>사업장 정보확인</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Check type='radio' label='개인사업자' id='businessType' value='1' checked={data['businessType'] ===  '1'} onChange={onChangeData}/>
                    </Col>
                    <Col>
                        <Form.Check type='radio' label='법인사업자' id='businessType' value='2' checked={data['businessType'] ===  '2'} onChange={onChangeData}/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="businessName">
                            <Form.Label>상호명</Form.Label>
                            <Form.Control type="text" placeholder="사업자등록증상 상호명을 입력해 주세요." onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="businessNumber">
                            <Form.Label>사업자등록번호 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="- 없이 숫자만 입력" onBlur={onBusinessNumber} onChange={onChangeData}/>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {isOpenPost  ? (
                            <div>
                                <DaumPostcodeEmbed className='postCodeStyle' autoClose onComplete={onCompletePost } />
                            </div>
                        ) : null}
                        <Form.Group className="xs-12" controlId="zipCode">
                            <Form.Label>우편번호 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" value={data['zipCode']} placeholder="지번, 도로명, 건물명으로 검색" onChange={onChangeData} onClick={onChangeOpenPost}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="address">
                            <Form.Label>기본주소 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" value={data['address']} placeholder="지번, 도로명, 건물명으로 검색" onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="addressDetail">
                            <Form.Label>상세주소</Form.Label>
                            <Form.Control type="text" value={data['addressDetail']} placeholder="예)플렉스상가 101동 101호 플레스카페" onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='ColorArea'>
                    <Col>
                        
                        <ul>
                            <li><h4>이렇게 검색해 보세요.</h4></li>
                        </ul>

                        <ul>
                            <li>- 도로명 + 건물번호</li>
                            <li>예) 플레스로 12길 3</li>
                        </ul>

                        <ul>
                            <li>- 지역명 + 번지</li>
                            <li>예) 플레스동 12-3</li>
                        </ul>

                        <ul>
                            <li>- 건물명</li>
                            <li>예) 플레스로 12길 3</li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h4>사업장 정보 입력</h4>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="storeName">
                            <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="매장명을 입력하세요." onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="operationTime">
                            <Form.Label>운영시간 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="예)오전11:00~오후12:00,주말:오전10:00~오후11:00" onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="holidays">
                            <Form.Label>휴무일 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="예) 매월 16일 휴무, 연중무휴" onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="logo">
                            <Form.Label>로고 업로드</Form.Label>
                            <Col>
                                <input type="file" id={props.id} style={{display : 'none'}} ref={fileUpload} onChange={onChangeImg}  />
                                <button onClick={onClickUpload}>IMG 변경</button>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Label>업종 <span className='PointText'>*</span></Form.Label>
                        {/* <div direction="horizontal" gap={1}> */}
                        <div className="d-flex flex-wrap justify-content-start">
                            {
                                res.businessClass.map( row => (
                                    // <Col xs="2" key={row.code}>
                                    //     <Form.Check type='checkbox' label={row.value} id={row.code} value={row.code}  onChange={onCheckData}/>
                                    // </Col>
                                    <div className='p-1' key={row.value+row.code}>
                                        <input type='checkbox' className="btn-check" autoComplete="off" id={row.value+row.code} value={row.code} onChange={onCheckData}/>
                                        <label htmlFor={row.value+row.code} className="btn btn-default">{row.value}</label>
                                    </div>
                                ) )
                            }
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="domain">
                            <Form.Label>스토어 주소 <span className='PointText'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="앱상에 보여질 스토어주소를 작성해 주세요." onBlur={onDomain} onChange={onChangeData}/>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="xs-12" controlId="recommender">
                            <Form.Label>영업 사원 이름</Form.Label>
                            <Form.Control type="text" placeholder='영업 사원 이름을 입력해주세요.' onChange={onChangeData}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <div className="d-grid gap-1">
                        <Button variant="primary" size="lg" onClick={submit}>
                            완료
                        </Button>
                    </div>
                </Row>

                <Row>
                    <Footer />
                </Row>
            </Container>
        </>
    )
}