import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { GetAuthorize, GetUserMe } from "./../../api/ApiGetUser";
import { PrintConsole, sleep } from "../../function/common";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { getImgUrl } from "./../../function/common";
import CheckBox from "../../components/common/CheckBox";
import ListGroupH from "../../components/ListGroupH";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  const [account, setAccount] = useState({
    Id: "",
    Password: "",
    clientId: "10000",
  });

  const onChangeAccount = (e) => {
    //console.log(e.target.value);
    setAccount({
      ...account,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") !== undefined &&
      localStorage.getItem("accessToken") !== null
    ) {
      //토큰 있을때 추가 작업할 일 있을 시 사용
      navigate("/user/search");
    } else {
      //토큰 없을때
    }
  });

  const loginBtn = async () => {
    try {
      document.getElementById("submitBtn").setAttribute("disabled", "true");
      await GetAuthorize(account);

      sleep(500);
      PrintConsole("localStorage : " + localStorage.getItem("accessToken"));
      PrintConsole("localStorage : " + localStorage.getItem("refreshToken"));

      let resUserMe = await GetUserMe(account);

      console.log(resUserMe);

      sleep(500);
      localStorage.setItem("domainType", resUserMe.data.domainType);
      localStorage.setItem("name", resUserMe.data.name);

      if (location.state?.redirectUrl) {
        navigate(location.state.redirectUrl);
      } else {
        if (window.location.pathname.includes("/user")) {
          navigate("/user");
        } else {
          navigate("/user/search");
        }
      }

      // if(resUserMe.data.domainType === 'USER'){

      // }else if(resUserMe.data.domainType === 'STORE'){
      //     if(resUserMe.data.shouldAskWhetherCEO === true){
      //         navigate('/workplace/join');
      //     }else{
      //         localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
      //         // navigate('/store/storemain?storeurl='+ resUserMe.data.myStores[0].storeDomain);
      //         navigate('/store/'+ resUserMe.data.myStores[0].storeDomain );
      //     }
      // }else{
      //     navigate('/404');
      // }
    } catch (e) {
      document.getElementById("submitBtn").removeAttribute("disabled");
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      loginBtn();
    }
  };

  //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
  // getVersion();
  // setAccessToken();

  return (
    <>
      <Container>
        <Row>
          <Col sm={{ span: 4, offset: 4 }} className="text-center mt-3">
            {/* <img src="/assets/logo.png" className="img-fluid"/> */}
            <Image
              className="LogoImg w-40"
              src={getImgUrl("images/icon/img_login.png")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 10, offset: 1 }} className="mt-3">
            <Form.Group controlId="Id">
              <Form.Label
                style={
                  location.pathname.includes("/user")
                    ? {
                        fontSize: "1.4rem",
                      }
                    : {}
                }
              >
                아이디
              </Form.Label>
              <Form.Control
                type="text"
                controlid="Id"
                name="Id"
                placeholder="아이디를 입력해 주세요."
                onChange={onChangeAccount}
                onKeyPress={onKeyPress}
                style={
                  location.pathname.includes("/user")
                    ? {
                        fontSize: "1.6rem",
                      }
                    : {}
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <Form.Group
              controlId="Password"
              className={!location.pathname.includes("/user") ? "mt-3" : ""}
              style={
                location.pathname.includes("/user")
                  ? {
                      marginTop: "1.6rem",
                    }
                  : {}
              }
            >
              <Form.Label
                style={
                  location.pathname.includes("/user")
                    ? {
                        fontSize: "1.4rem",
                      }
                    : {}
                }
              >
                비밀번호
              </Form.Label>
              <Form.Control
                type="password"
                autoComplete="off"
                controlid="Password"
                name="Password"
                onChange={onChangeAccount}
                onKeyPress={onKeyPress}
                style={
                  location.pathname.includes("/user")
                    ? {
                        fontSize: "1.6rem",
                      }
                    : {}
                }
              />
            </Form.Group>
          </Col>
        </Row>
        {/* <Row>
                    <Col className='mt-3'>
                        <CheckBox controlId="AutoLogin" label="자동 로그인"/> 
                    </Col>
                </Row> */}

        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <div className="d-grid gap-1 mt-5">
              <Button
                variant="primary"
                size="lg"
                id="submitBtn"
                onClick={(e) => {
                  loginBtn();
                }}
              >
                로그인
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="center-block">
          <Col xs={{ span: 10, offset: 1 }} className="mt-3">
            <ListGroupH
              listdata={[
                { key: 1, title: "아이디 찾기", url: "/user/findId" },
                { key: 2, title: "비밀번호 찾기", url: "/user/findPw" },
                { key: 3, title: "회원가입", url: "/user/join" },
              ]}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
