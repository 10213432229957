import { createContext, useEffect, useState } from "react";

export const UserContext = createContext({
  accessToken:
    localStorage.getItem("userAccessToken") ||
    sessionStorage.getItem("userAccessToken") ||
    undefined,
  setAccessToken: () => {},
  refreshToken:
    localStorage.getItem("userRefreshToken") ||
    sessionStorage.getItem("userRefreshToken") ||
    undefined,
  setRefreshToken: () => {},
  name:
    localStorage.getItem("userName") ||
    sessionStorage.getItem("userName") ||
    undefined,
  setName: () => {},
  domainType:
    localStorage.getItem("userDomainType") ||
    sessionStorage.getItem("userDomainType") ||
    undefined,
  setDomainType: () => {},
  nickName:
    localStorage.getItem("userPhoneNumber") ||
    sessionStorage.getItem("userPhoneNumber") ||
    undefined,
  setNickName: () => {},
  phoneNumber:
    localStorage.getItem("userPhoneNumber") ||
    sessionStorage.getItem("userPhoneNumber") ||
    undefined,
  setPhoneNumber: () => {},
  email:
    localStorage.getItem("userEmail") ||
    sessionStorage.getItem("userEmail") ||
    undefined,
  setEmail: () => {},
  login: () => {},
  logout: () => {},
});

const UserProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("userAccessToken") ||
      sessionStorage.getItem("userAccessToken") ||
      undefined
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("userRefreshToken") ||
      sessionStorage.getItem("userRefreshToken") ||
      undefined
  );
  const [name, setName] = useState(
    localStorage.getItem("userName") ||
      sessionStorage.getItem("userName") ||
      undefined
  );
  const [domainType, setDomainType] = useState(
    localStorage.getItem("userDomainType") ||
      sessionStorage.getItem("userDomainType") ||
      undefined
  );
  const [nickName, setNickName] = useState(
    localStorage.getItem("userNickName") ||
      sessionStorage.getItem("userNickName") ||
      undefined
  );
  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem("userPhoneNumber") ||
      sessionStorage.getItem("userPhoneNumber") ||
      undefined
  );
  const [email, setEmail] = useState(
    localStorage.getItem("userEmail") ||
      sessionStorage.getItem("userEmail") ||
      undefined
  );

  const login = () => {
    if (localStorage.getItem("userAccessToken")) {
      setAccessToken(localStorage.getItem("userAccessToken"));
    }
    if (localStorage.getItem("userRefreshToken")) {
      setRefreshToken(localStorage.getItem("userRefreshToken"));
    }
    if (localStorage.getItem("userName")) {
      setName(localStorage.getItem("userName"));
    }
    if (localStorage.getItem("userDomainType")) {
      setDomainType(localStorage.getItem("userDomainType"));
    }
    if (localStorage.getItem("userNickName")) {
      setNickName(localStorage.getItem("userNickName"));
    }
    if (localStorage.getItem("userPhoneNumber")) {
      setPhoneNumber(localStorage.getItem("userPhoneNumber"));
    }
    if (localStorage.getItem("userEmail")) {
      setEmail(localStorage.getItem("userEmail"));
    }

    if (sessionStorage.getItem("userAccessToken")) {
      setAccessToken(sessionStorage.getItem("userAccessToken"));
    }
    if (sessionStorage.getItem("userRefreshToken")) {
      setRefreshToken(sessionStorage.getItem("userRefreshToken"));
    }
    if (sessionStorage.getItem("userName")) {
      setName(sessionStorage.getItem("userName"));
    }
    if (sessionStorage.getItem("userDomainType")) {
      setDomainType(sessionStorage.getItem("userDomainType"));
    }
    if (sessionStorage.getItem("userNickName")) {
      setNickName(sessionStorage.getItem("userNickName"));
    }
    if (sessionStorage.getItem("userPhoneNumber")) {
      setPhoneNumber(sessionStorage.getItem("userPhoneNumber"));
    }
    if (localStorage.getItem("userEmail")) {
      setEmail(localStorage.getItem("userEmail"));
    }
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setName(undefined);
    setDomainType(undefined);
    setNickName(undefined);
    setPhoneNumber(undefined);
    setEmail(undefined);
  };
  useEffect(() => {
    login();
  }, []);
  return (
    <UserContext.Provider
      value={{
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        name,
        setName,
        domainType,
        setDomainType,
        nickName,
        setNickName,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
