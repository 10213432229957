import React from "react";
import {
  ColumnContainer,
  RowContainer,
} from "../../../../components/ver1.5/styles/Ver2GlobalStyles";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";

const OperationDisplay = ({ businessHours }) => {
  const week = [
    {
      label: "월요일",
      value: "mon",
    },
    {
      label: "화요일",
      value: "tue",
    },
    {
      label: "수요일",
      value: "wed",
    },
    {
      label: "목요일",
      value: "thu",
    },
    {
      label: "금요일",
      value: "fri",
    },
    {
      label: "토요일",
      value: "sat",
    },
    {
      label: "일요일",
      value: "sun",
    },
  ];
  return (
    <ColumnContainer
      style={{
        background: color.gray.d50,
        padding: "2.4rem 3.2rem",
        borderRadius: "1.2rem",
        gap: "1.6rem",
      }}
    >
      {week.map((w) => {
        const targetHours = businessHours.find(
          (hour) => hour.selectedDays.findIndex((day) => day === w.value) !== -1
        );
        const startBusinessHours = targetHours?.startBusinessHours;
        const endBusinessHours = targetHours?.endBusinessHours;
        const startBreakHours = targetHours?.startBreakHours;
        const endBreakHours = targetHours?.endBreakHours;
        return (
          <RowContainer key={w.value} style={{ gap: "1.2rem" }}>
            <Text fontStyle={font.body1}>{w.label}</Text>
            <RowContainer>
              <Text fontStyle={font.caption1}>
                {startBusinessHours ? (
                  <>
                    {startBusinessHours}
                    <span>&nbsp;</span>
                  </>
                ) : (
                  "휴무"
                )}
              </Text>
              {Boolean(endBusinessHours) && (
                <Text fontStyle={font.caption1}>~{" " + endBusinessHours}</Text>
              )}
            </RowContainer>
            {startBusinessHours && endBusinessHours && (
              <Text fontStyle={font.caption1}>
                (휴게시간
                {startBreakHours && endBreakHours
                  ? " " + startBreakHours + " ~ " + endBreakHours
                  : " 없음"}
                )
              </Text>
            )}
          </RowContainer>
        );
      })}
    </ColumnContainer>
  );
};

export default OperationDisplay;
