import React from "react";
import ModalLayout from "../../../../../components/ver1.5/molecule/ModalLayout";
import { styled } from "styled-components";
import { color, font } from "../../../../../components/ver1.5/styles/theme";
import Text from "../../../../../components/ver1.5/atom/Text";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.4rem;
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  height: 5.6rem;
  border-top: 1px solid ${color.gray.d100};
`;

const Btn = styled.button`
  width: 100%;
  flex-shrink: 0;
  padding: 1.6rem 0;
`;

const WithdrawalConfirmModal = ({ onAction }) => {
  return (
    <ModalLayout onClickOverlay={onAction}>
      <ContentContainer>
        <DescriptionWrapper>
          <Text fontStyle={font.body1} color={color.gray.d900}>
            회원탈퇴를 완료하였습니다
          </Text>
          <Text fontStyle={font.body1} color={color.gray.d900}>
            재가입은 30일 이후에 가능합니다
          </Text>
        </DescriptionWrapper>
      </ContentContainer>
      <BtnContainer>
        <Btn onClick={() => onAction()}>
          <Text fontStyle={font.body1} color={color.functional.link.d600}>
            확인
          </Text>
        </Btn>
      </BtnContainer>
    </ModalLayout>
  );
};

export default WithdrawalConfirmModal;
