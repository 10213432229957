
import { Table } from 'react-bootstrap';
export default function Today( props ){

    return (
        <>
            <label>일자별 요약</label>
            <Table responsive>
                <thead>
                    <tr className="text-center">
                        <th>일자</th>
                        <th>주문수</th>
                        <th>매출액</th>
                        <th>방문</th>
                        <th>가입</th>
                        <th>발행</th>
                        <th>사용</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>YYYY-mm-dd</td>
                        <td>0</td>
                        <td>0 원</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>YYYY-mm-dd</td>
                        <td>0</td>
                        <td>0 원</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>YYYY-mm-dd</td>
                        <td>0</td>
                        <td>0 원</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}