import axios from "axios";
import { getRedirctUrl } from "../function/common";
import { getApiUrl } from "./../function/common";
import sha256 from "sha256";

export const GetAuthorize = async (obj, autoLogin) => {
  var variables = {};
  let redirectUrl = "/";

  if (obj.clientId === "10000") {
    redirectUrl = "user/search";
  } else {
    redirectUrl = "/";
  }

  variables = {
    clientId: obj.clientId,
    redirectUri: redirectUrl,
    responseType: "string",
  };

  //서비스 호출
  const res = await axios
    .get(
      getApiUrl("oauth/authorize"),
      {
        params: variables,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => res)
    .catch((error) =>
      error?.response?.data?.message
        ? error.response.data.message
        : error.message
    );
  if (res?.status !== 200) {
    alert(res);
    return;
  }
  if (res.data["shouldRedirectLogin"] === true) {
    //테스트 - 로그인 페이지 이동
    await GetLogin(obj, autoLogin);
  } else if (res.data["shouldRedirectLogin"] === false) {
    //url로 이동
    await GetLogin(obj, autoLogin);
  } else {
    console.log("error");
  }
};

export const GetLogin = async (obj, autoLogin) => {
  let variables = {};

  variables = {
    loginId: obj.Id,
    password: sha256(obj.Password),
    clientId: obj.clientId, //사용자 10000, 가맹점 10001
    autoLogin: true,
  };

  //서비스 호출

  const res = await axios
    .post(getApiUrl("oauth/login"), variables, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((res) => res)
    .catch((error) =>
      error?.response?.data?.message
        ? error.response.data.message
        : error.message
    );
  if (res?.status !== 200) {
    alert(res);
    return;
  }
  await GetToken(obj, res.data["code"], autoLogin);
};

export const GetToken = async (obj, code, autoLogin) => {
  let variables = {};

  variables = {
    clientId: obj.clientId,
    code: code,
  };

  //서비스 호출
  const res = await axios
    .post(getApiUrl("oauth/token"), variables, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        if (autoLogin) {
          localStorage.setItem("userAccessToken", response.data["accessToken"]);
          localStorage.setItem(
            "userRefreshToken",
            response.data["refreshToken"]
          );
        } else {
          sessionStorage.setItem(
            "userAccessToken",
            response.data["accessToken"]
          );
          sessionStorage.setItem(
            "userRefreshToken",
            response.data["refreshToken"]
          );
        }
      }
    })
    .catch((e) => {
      alert(
        e?.response?.data?.message ? e?.response?.data?.message : e.message
      );
    });
};

export const GetUserMe = async (obj, autoLogin) => {
  try {
    let response;

    var variables = {};

    if (obj.clientId === "10001") {
      variables = {
        clientId: "10001",
        redirectUri: getRedirctUrl("/store/home"),
        // "responseType": "string"
      };
    } else {
      variables = {
        clientId: "10000",
        redirectUri: getRedirctUrl("user/home"),
        // "responseType": "string"
      };
    }

    //서비스 호출
    response = await axios.get(
      getApiUrl("users/me"),
      variables,
      (axios.defaults.headers.common["Authorization"] =
        "Bearer " +
        (autoLogin
          ? localStorage.getItem("userAccessToken")
          : sessionStorage.getItem("userAccessToken"))),
      (axios.defaults.headers.common["ContentType"] =
        "application/json; charset=utf-8")
    );
    return response;
  } catch (e) {
    alert(e?.response?.data?.message ? e?.response?.data?.message : e.message);
  }
};
