import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
import CheckBox from '../../../components/common/CheckBox';

import ListGroupH from '../../../components/ListGroupH';
import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState, useRef } from 'react';
import { getImgUrl, LocationUrl } from '../../../function/common';
import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import SideNav from './../../../components/common/SideNav';
import { Nav } from 'react-bootstrap';

export default function TmpLogin(){
    const navigate = useNavigate();

    const [account, setAccount] = useState({
        Id: "",
        Password: "",
        clientId: '10001'
    });

    const onChangeAccount = (e) => {
        //console.log(e.target.value);
        setAccount({
          ...account,
          [e.target.id]: e.target.value,
        });
    };

    useEffect( () => {
        // localStorage.removeItem('accessToken');

        if( localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null ){
            //토큰 있을때 추가 작업할 일 있을 시 사용
            // navigate('/store/'+ localStorage.getItem('storeDomain'));
        }else{
            // navigate('/user/search');
        }

        try{
            setNav3();
        }catch(e){}
    })

    const loginBtn = async () => {

        try{

            return false;

            document.getElementById('submitBtn').setAttribute("disabled","true");

            await GetAuthorize(account);
                        
            sleep(500);
            PrintConsole("localStorage : " + localStorage.getItem('accessToken'));
            PrintConsole("localStorage : " + localStorage.getItem('refreshToken'));
            
            let resUserMe = await GetUserMe(account);
            
            sleep(500);
            try{
                localStorage.setItem('domainType', resUserMe.data.domainType);
                localStorage.setItem('name', resUserMe.data.name);
                localStorage.setItem('myStores' , JSON.stringify(resUserMe.data.myStores[0]));
                localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
            }catch(e){
                console.log('mystore 없음 스텝 or 가입안함');
                localStorage.setItem('storeDomain', null);
            }
            
            
            // if(localStorage.getItem('domainType') === 'STORE'){
                // console.log(localStorage.getItem('myStores'));
                // var myStores = JSON.parse(localStorage.getItem('myStores'));
                // console.log(myStores);
            // }

            if( resUserMe.data.isCEO === true ){
                if(resUserMe.data.shouldAskWhetherCEO === true){
                    navigate('/workplace/join');
                }else{
                    try{
                        setAccessToken();
                    }catch(e){}
                    navigate('/store/'+localStorage.getItem('storeDomain'));
                }
            }else if( resUserMe.data.isCEO === false ){
                console.log('sm');
                try{
                    setAccessToken();
                }catch(e){}
                navigate('/staff/staffmain');
            }else{
                console.log('sq');
                navigate('/store/storequestions');
            }

            // if(resUserMe.data.shouldAskWhetherCEO === true){
            //     if( resUserMe.data.myStores.length === 0 ){
            //         navigate('/workplace/join');
            //     }
            // }else{
            //     if( resUserMe.data.isCEO === 'CEO' ){
            //         navigate('/store'+localStorage.getItem('storeDomain')+'/storemain?storeurl='+ localStorage.getItem('storeDomain'));
            //     }else if( resUserMe.data.isCEO === false ){
            //         navigate('/staff/main');
            //     }else{
            //         navigate('/store/storequestions');
            //     }
            // }

            //     if(resUserMe.data.myStores[0].storeDomain !== undefined){

            //         localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
            //         try{
            //             console.log(deviceType())
            //             setAccessToken(localStorage.getItem('accessToken'));
            //         }catch(e){
            //             console.log(e);
            //         }

            //         navigate('/store/'+ resUserMe.data.myStores[0].storeDomain );

            //     }else{
            //         navigate('/workplace/join');
            //     }
                
            //     if(resUserMe.data.domainType === 'STORE'){
            //         if( resUserMe.data.isCEO === 'CEO' ){
                        
            //                 // window.location.replace('/store/'+ resUserMe.data.myStores[0].storeDomain);
            //                 // navigate('/store'+localStorage.getItem('storeDomain')+'/storemain?storeurl='+ localStorage.getItem('storeDomain'));
            //             }
            //         }else if( resUserMe.data.isCEO === false ){
            //             navigate('/staff/main');
            //         }else{
            //             // navigate('/404');
            //         }
            //     }
            // }
            document.getElementById('submitBtn').removeAttribute("disabled");
        }catch(e){
            console.log(e);
            document.getElementById('submitBtn').removeAttribute("disabled");
        }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter') {
            loginBtn();
        }
    }

    const back = useRef(null);
    const [toggle, setToggle] = useState(false);
    const clickedToggle = () => {
        setToggle((prev) => !prev);  
        if(toggle === false){
            console.log(toggle);
            document.getElementById('AlertInfo').style.display = 'none';
        }else{
            document.getElementById('AlertInfo').style.display = 'block';
        }
    };

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
            <Container id='TmpFont'>
                <Row>
                    <Col sm={{ span:4, offset:4 }} className='text-center mt-3'>
                        {/* <img src="/assets/logo.png" className="img-fluid"/> */}
                        <Image className='LoginLogo w-25' src={getImgUrl('images/icon/FlexLogo.png')}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span:10, offset:1 }} className='mt-3'>
                        <Nav className="justify-content-end">
                            <Nav.Item key='1'>
                                <Nav.Link as={NavLink} to='/findid/store'>아이디 찾기</Nav.Link>
                            </Nav.Item>
                            <Nav.Item key='3' className='SpaceHr'>
                                |
                            </Nav.Item>
                            <Nav.Item key='2'>
                                <Nav.Link as={NavLink} to='/findpw/store' style={{paddingRight:'0px'}}>비밀번호 찾기</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col xs={{ span:10, offset:1 }} className='mt-2'>
                        <Form.Group controlId='Id'>
                            <Form.Control size="lg" className='InputType1' type="text" controlid='Id' name='Id' placeholder="아이디를 입력해 주세요." onChange={onChangeAccount} onKeyPress={onKeyPress} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span:10, offset:1 }}>
                        <Form.Group controlId='Password' className='mt-3'>
                            <Form.Control size="lg" className='InputType1' type="password" autoComplete="off" placeholder="비밀번호를 입력해 주세요."controlid='Password' name='Password' onChange={onChangeAccount} onKeyPress={onKeyPress} />
                        </Form.Group>
                    </Col>
                </Row>
                {/* <Row>
                    <Col className='mt-3'>
                        <CheckBox controlId="AutoLogin" chec label="자동 로그인" checked={true}/> 
                    </Col>
                </Row> */}

                <Row>
                    <Col xs={{ span:10, offset:1 }} className='mt-3'>
                        <div className="d-grid gap-1">
                            <Button variant="primary" className='ButtonType1' size="lg" id='submitBtn' onClick={ (e) => { loginBtn(); } } >
                                로그인
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{span:10, offset:1}} className='mt-5'>
                        <Row>
                            <Col xs={3} className='AlignCenter'>
                                <hr/>
                            </Col>
                            <Col xs={6} className='AlignCenter'>
                                <span>아직 회원이 아니신가요?</span>
                            </Col>
                            <Col xs={3} className='AlignCenter'>
                                <hr/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{ span:10, offset:1 }} className='mt-3'>
                        <div className="d-grid gap-1">
                            <Button variant="primary" className='ButtonType2' size="lg" id='submitBtn' onClick={clickedToggle} >
                                회원가입
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row id='AlertInfo'>
                    <Col xs={{ span:10, offset:1 }} className='mt-3'>
                        <div className="alert alert-info" role="alert">
                            아이디 또는 비밀번호가 틀렸습니다.
                        </div>
                    </Col>
                </Row>

                <Row>
                    <textarea placeholder="1&#10;2&#10;3&#10;4&#10;5" rows="5">
                        
                    </textarea>
                </Row>
            </Container>
        </>
    )
}