
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import LeftMenu from '../../../components/admin/LeftMenu';
import Media from '../../../components/common/Media';

import BarChart from './../../../components/chart/BarChart';
import CustomCard from './../../../components/common/CustomCard';
import Todo from './../../../components/admin/Todo';
import Today from './../../../components/admin/Today';
import DashBoardA from './DashBoardA';
import DashBoardB from './DashBoardB';
import DashBoardC from './DashBoardC';



export default function DashBoard ( props ) {

    return (
        <>
            <Container fluid>
                <Row>

                    <Col xs ={2}>
                        <LeftMenu />
                    </Col>

                    <Col xs={8}>
                        <Row>
                            <Todo />
                        </Row>

                        <Row>

                            <Col xs={6}>
                                <BarChart />
                            </Col>

                            <Col xs={6} className="text-center">
                                <Row>
                                    <Today />
                                </Row>
                            </Col>

                        </Row>

                        <Row>
                            <DashBoardA/>
                            <DashBoardB/>
                            <DashBoardC/>
                        </Row>
                    </Col>

                    <Col xs={2}>
                        <Row>
                            <Col xs={12}>
                                <CustomCard title='관리자' text='ceo@flex.day' />
                            </Col>
                            <Col xs={12}>
                                <Media key='1' />
                                <Media key='2' />
                                <Media key='3' />
                                <Media key='4' />
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </>
    )
}