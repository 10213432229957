import { Container, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getImgUrl } from "./../function/common";
import { useState } from "react";
import { useEffect } from "react";

export default function NotFound() {
  const navigate = useNavigate();
  const [navOption, setNavOption] = useState("user");
  let location = "/store/login";
  useEffect(function () {
    console.log(localStorage.getItem("domainType"));
    console.log(localStorage.getItem("storeDomain"));

    if (
      localStorage.getItem("storeDomain") === "null" ||
      localStorage.getItem("storeDomain") === null ||
      localStorage.getItem("storeDomain") === undefined
    ) {
      location = "/store/login";
      localStorage.clear();
    } else {
      if (localStorage.getItem("domainType") === "STORE") {
        setNavOption("STORE");
        location = "/store/" + localStorage.getItem("storeDomain");
      } else if (localStorage.getItem("domainType") === "USER") {
        setNavOption("USER");
        location = "/user/storemain/" + localStorage.getItem("storeDomain");
      } else {
        setNavOption("LOGIN");
      }
    }
    // navOption === 'store' ?
    //                     localStorage.getItem('storeDomain') !== undefined && localStorage.getItem('storeDomain') !== null ?
    //                     '/store/' + localStorage.getItem('storeDomain') : '/store/login'
    //                 : '/store/login'
  }, []);

  return (
    <div className="StoreAlertPage">
      <Container className="g-0">
        <div className="Store-AlertPage Inner text-center">
          <Image
            fluid
            className="Emoji mb-4"
            src="https://www.flexdaycdn.net/public/images/static/store-register/cry-emoji.png"
          />
          <p className="StoreAlert-tit mb-1">페이지를 찾을 수 없어요 !</p>
          <p className="StoreAlert-desc">
            요청하신 페이지가 사라졌거나
            <br />
            잘못된 경로를 이용하셨습니다.
          </p>
        </div>
        <div
          className="fixed-bottom responsiveInner text-center m-auto"
          style={{ width: "90%" }}
        >
          <p className="StoreAlert-sub mb-4">
            아래 버튼을 눌러 다시 시도해주세요.
            <br />
            응답이 없을 경우, 앱을 종료하신 후 다시 실행해주세요.
          </p>
          <Button
            as="a"
            variant="primary"
            className="mb-5"
            onClick={() => navigate(location)}
          >
            스토어로 가기
          </Button>
        </div>
      </Container>
    </div>
  );
}
