import React from "react";
import { css, styled } from "styled-components";
import Text from "../../../../../components/ver1.5/atom/Text";
import { color, font } from "../../../../../components/ver1.5/styles/theme";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) =>
    !p.$isLast &&
    css`
      border-bottom: 1px solid ${color.gray.d100};
    `};
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
  ${(p) => p.$containerStyle};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const ActionListItem = ({
  text,
  subText,
  rightContent,
  fontStyle,
  containerStyle,
  isLast,
  onClickContainer,
}) => {
  return (
    <Container
      $containerStyle={containerStyle}
      $isLast={isLast}
      onClick={() => (onClickContainer ? onClickContainer() : null)}
    >
      <TextContainer>
        <Text fontStyle={fontStyle}>{text}</Text>
        {subText && (
          <Text fontStyle={font.caption2} color={color.gray.d600}>
            {subText}
          </Text>
        )}
      </TextContainer>
      {rightContent && rightContent}
    </Container>
  );
};

export default ActionListItem;
