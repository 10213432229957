import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "../../pages/franchisee/user/Join";

export default function Userterms() {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow() {
    setFullscreen("lg-down");
    setShow(true);
  }

  return (
    <>
      <Typography
        size={"13px"}
        weight={"var(--Regular)"}
        color={"var(--ColorTypeD)"}
        onClick={() => handleShow()}
      >
        보기
      </Typography>
      {/* <FontAwesomeIcon icon={faAngleRight} onClick={() => handleShow()}/> */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              주식회사 플렉스데이
              <br />
              개인정보 수집 및 이용 동의서
            </h3>
            <small>제정 2022.07.01.</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>
            &lt; (주)플렉스데이 &gt;('www.flex.day'이하 '플렉스데이')는
            「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고
            이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
            다음과 같이 개인정보 처리방침을 수립·공개합니다.
            <br />
            <br />
            ○ 이 개인정보처리방침은 2022년 7월 4부터 적용됩니다.
            <br />
            <br />
            제1조(개인정보의 처리 목적)
            <br />
            &lt; (주)플렉스데이 &gt;('www.flex.day'이하 '플렉스데이')는 다음의
            목적을 위하여 개인정보를 처리합니다. <br />
            처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며
            이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라
            별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
            <br />
            <br />
            1. 홈페이지 회원가입 및 관리
            <br />
            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
            회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의
            개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지 목적으로
            개인정보를 처리합니다.
            <br />
            <br />
            2. 민원사무 처리
            <br />
            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지,
            처리결과 통보 목적으로 개인정보를 처리합니다.
            <br />
            <br />
            3. 재화 또는 서비스 제공
            <br />
            서비스 제공, 계약서·청구서 발송, 본인인증, 요금결제·정산을 목적으로
            개인정보를 처리합니다.
            <br />
            <br />
            4. 마케팅 및 광고에의 활용
            <br />
            신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보
            제공 및 참여기회 제공 , 서비스의 유효성 확인, 접속빈도 파악 또는
            회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
            <br />
            <br />
            제2조(개인정보의 처리 및 보유 기간)
            <br />
            ① &lt; (주)플렉스데이 &gt;는 법령에 따른 개인정보 보유·이용기간 또는
            정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간
            내에서 개인정보를 처리·보유합니다.
            <br />
            ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            <br />
            <br />
            1.&lt;마케팅 및 광고에의 활용&gt;
            <br />
            &lt;마케팅 및 광고에의 활용&gt;와 관련한 개인정보는 수집.이용에 관한
            동의일로부터&lt;6개월&gt;까지 위 이용목적을 위하여 보유.이용됩니다.
            <br />
            보유근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            관련법령 : 표시/광고에 관한 기록 : 6개월
            <br />
            <br />
            2.&lt;재화 또는 서비스 제공&gt;
            <br />
            &lt;재화 또는 서비스 제공&gt;와 관련한 개인정보는 수집.이용에 관한
            동의일로부터&lt;5년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.
            <br />
            보유근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            관련법령 : 계약 또는 청약철회 등에 관한 기록 : 5년
            <br />
            <br />
            3.&lt;재화 또는 서비스 제공&gt;
            <br />
            &lt;재화 또는 서비스 제공&gt;와 관련한 개인정보는 수집.이용에 관한
            동의일로부터&lt;5년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.
            <br />
            보유근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            관련법령 : 대금결제 및 재화 등의 공급에 관한 기록 : 5년
            <br />
            <br />
            4.&lt;민원사무 처리&gt;
            <br />
            &lt;민원사무 처리&gt;와 관련한 개인정보는 수집.이용에 관한
            동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.
            <br />
            보유근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            관련법령 : 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
            <br />
            <br />
            5.&lt;민원사무 처리&gt;
            <br />
            &lt;민원사무 처리&gt;와 관련한 개인정보는 수집.이용에 관한
            동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.
            <br />
            보유근거 : 신용정보의 이용 및 보호에 관한 법률
            <br />
            관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
            <br />
            <br />
            제3조(개인정보의 제3자 제공)
            <br />
            ① &lt; (주)플렉스데이 &gt;는 개인정보를 제1조(개인정보의 처리
            목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의
            특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는
            경우에만 개인정보를 제3자에게 제공합니다.
            <br />
            <br />
            ② 삭제
            <br />
            <br />
            제4조(개인정보처리 위탁)
            <br />
            ① &lt; (주)플렉스데이 &gt;는 원활한 개인정보 업무처리를 위하여
            다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
            <br />
            1. &lt; 카드결제, 휴대폰결제, 실시간 계좌이체 대행 &gt;
            <br />
            위탁받는 자 (수탁자) : Toss Payments.
            <br />
            위탁하는 업무의 내용 : 구매 및 요금 결제
            <br />
            2. &lt; 본인인증 &gt;
            <br />
            위탁받는 자 (수탁자) : (주)NICE 신용평가
            <br />
            위탁하는 업무의 내용 : 본인인증(금융거래, 금융서비스)
            <br />
            3. &lt; 계좌확인&gt;
            <br />
            위탁받는 자 (수탁자) : (주)NICE 신용평가
            <br />
            위탁하는 업무의 내용 : 계좌확인(금융거래, 금융서비스)
            <br />
            ② &lt; (주)플렉스데이 &gt;는 위탁계약 체결시 「개인정보 보호법」
            제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적
            보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에
            관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게
            처리하는지를 감독하고 있습니다.
            <br />
            ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
            처리방침을 통하여 공개하도록 하겠습니다.
            <br />
            <br />
            제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)
            <br />
            ① 정보주체는 (주)플렉스데이에 대해 언제든지 개인정보
            열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
            <br />
            ② 제1항에 따른 권리 행사는(주)플렉스데이에 대해 「개인정보 보호법」
            시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여
            하실 수 있으며 (주)플렉스데이는 이에 대해 지체 없이 조치하겠습니다.
            <br />
            ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자
            등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에
            관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야
            합니다.
            <br />
            ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
            제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
            <br />
            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
            대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            <br />
            ⑥ (주)플렉스데이는 정보주체 권리에 따른 열람의 요구, 정정·삭제의
            요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
            대리인인지를 확인합니다.
            <br />
            <br />
            제6조(처리하는 개인정보의 항목 작성)
            <br />
            ① &lt; (주)플렉스데이 &gt;는 다음의 개인정보 항목을 처리하고
            있습니다.
            <br />
            1&lt; 홈페이지 회원가입 및 관리 &gt;
            <br />
            필수항목 : 성명, 아이디, 비밀번호, 이메일, 휴대전화번호, 생년월일,
            주소
            <br />
            선택항목 : 환불 필요정보(계좌번호, 통장사본, 상품권 번호,
            본인인증번호
            <br />
            1). 회원관리
            <br />
            회원가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별•인증,
            회원자격 유지•관리, 제한적 본인확인제 시행에 따른 본인 확인, 서비스
            부정이용 방지, 만 14세 미만 아동의 개인정보 처리 시 법정대리인의
            동의 여부 확인, 각종 고지•통지, 고충처리 등<br />
            - 성명, 아이디, 비밀번호, 이동전화번호, 이메일, 주소 등<br />
            2) 본인인증
            <br />
            - 이름, 성별, 생년월일, 휴대폰번호, 통신사업자, 내/외국인 여부,
            암호화된 이용자확인값(CI), 중복가입확인정보(DI)
            <br />
            3) 재화 또는 서비스 제공
            <br />
            상품권 발송, 문자메시지 (MMS 또는 SMS), 휴대폰 인증, 결제/이용확인
            등<br />
            - 웹사이트 : 서비스 이용기록, 접속로그, 방문기록, 쿠키, IP정보 등
            <br />
            - 모바일 앱 : 서비스 이용기록, 인증번호 등<br />
            4) 고충처리
            <br />
            신원확인, 민원사항 확인, 사실조사를 위한 연락•통지, 처리결과 통보 등
            <br />
            5) 결제, 잔액 환불
            <br />
            - 휴대폰 : 휴대전화번호, 인증 번호 등<br />
            - 계좌이체 : 은행명, 본인계좌번호 등<br />
            - 상품권 : 상품권 번호 등<br />
            6) 정산 계좌 등록
            <br />
            - 은행명, 계좌번호, 계좌주, 생년월일 또는 사업자등록번호
            <br />
            <br />
            제7조(개인정보의 파기)
            <br />
            ① &lt; (주)플렉스데이 &gt; 는 개인정보 보유기간의 경과, 처리목적
            달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
            파기합니다.
            <br />
            ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
            달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
            하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
            보관장소를 달리하여 보존합니다.
            <br />
            1. 보존하는 개인정보 항목 : 계좌정보, 거래날짜
            <br />
            ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
            <br />
            1. 파기절차
            <br />
            &lt; (주)플렉스데이 &gt; 는 파기 사유가 발생한 개인정보를 선정하고,
            &lt; (주)플렉스데이 &gt; 의 개인정보 보호책임자의 승인을 받아
            개인정보를 파기합니다.
            <br />
            2. 파기방법
            <br />
            전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
            사용합니다
            <br />
            <br />
            제8조(개인정보의 안전성 확보 조치)
            <br />
            &lt; (주)플렉스데이 &gt;는 개인정보의 안전성 확보를 위해 다음과 같은
            조치를 취하고 있습니다.
            <br />
            1. 정기적인 자체 감사 실시
            <br />
            개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체
            감사를 실시하고 있습니다.
            <br />
            2. 개인정보 취급 직원의 최소화 및 교육
            <br />
            개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여
            개인정보를 관리하는 대책을 시행하고 있습니다.
            <br />
            3. 내부관리계획의 수립 및 시행
            <br />
            개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
            있습니다.
            <br />
            4. 해킹 등에 대비한 기술적 대책
            <br />
            &lt;(주)플렉스데이&gt;('플렉스데이')은 해킹이나 컴퓨터 바이러스 등에
            의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고
            주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
            설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
            <br />
            5. 개인정보의 암호화
            <br />
            이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어,
            본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화
            하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고
            있습니다.
            <br />
            6. 접속기록의 보관 및 위변조 방지
            <br />
            개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고
            있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나,
            고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관,
            관리하고 있습니다.
            <br />
            또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고
            있습니다.
            <br />
            7. 개인정보에 대한 접근 제한
            <br />
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
            부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
            조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
            접근을 통제하고 있습니다.
            <br />
            8. 문서보안을 위한 잠금장치 사용 개인정보가 포함된 서류,
            보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
            <br />
            9. 비인가자에 대한 출입 통제 개인정보를 보관하고 있는 물리적 보관
            장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고
            있습니다.
            <br />
            <br />
            제9조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)
            <br />
            ① (주)플렉스데이 는 이용자에게 개별적인 맞춤서비스를 제공하기 위해
            이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
            <br />
            ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
            브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
            하드디스크에 저장되기도 합니다.
            <br />
            가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에
            대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
            이용자에게 최적화된 정보 제공을 위해 사용됩니다.
            <br />
            나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷
            옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
            있습니다.
            <br />
            다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
            있습니다.
            <br />
            <br />
            제10조 (개인정보 보호책임자)
            <br />
            ① (주)플렉스데이 는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
            개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
            아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            <br />
            ▶ 개인정보 보호책임자
            <br />
            개인정보보호책임자 : <br />
            성명 : 조영웅 대리 / 전화번호 : 051-621-0203 / 이메일 :
            security@flex.day
            <br />
            ② 정보주체께서는 (주)플렉스데이 의 서비스(또는 사업)을 이용하시면서
            발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한
            사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
            (주)플렉스데이 는 정보주체의 문의에 대해 지체 없이 답변 및
            처리해드릴 것입니다.
            <br />
            <br />
            제11조(개인정보 열람청구)
            <br />
            정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를
            아래의 부서에 할 수 있습니다. &lt; (주)플렉스데이 &gt;는 정보주체의
            개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
            <br />
            ▶ 개인정보 열람청구 접수·처리 부서
            <br />
            부서명 : 개발팀
            <br />
            담당자 : 조영웅 대리
            <br />
            연락처 : 051-621-0203, security@flex.day
            <br />
            <br />
            제12조(권익침해 구제방법)
            <br />
            정보주체는 개인정보침해로 인한 구제를 받기 위하여
            개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
            개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
            바랍니다.
            <br />
            - 개인정보분쟁조정위원회(www.kopico.or.kr, 02-405-5150)
            <br />
            - 한국인터넷진흥원 개인정보 침해신고 센터(www.1336.or.kr, 02-118)
            <br />
            - 정보보호마크인증위원회(www.eprivacy.or.kr, 02-580-0533~4)
            <br />
            - 대검찰청 첨단범죄수사과(www.spo.go.kr, 02-3480-2000)
            <br />
            - 경찰청 사이버테러대응센터(www.ctrc.go.kr, 02-392-0330)
            <br />
            <br />
            제13조(개인정보처리방침 고지 또는 통지 방법)
            <br />
            본 방침은 2022 년 07 월 01 일부터 시행됩니다. 개인정보 취급방침의
            내용은 수시로 변경될 수 있으며, 내용의 변경이 있을 경우 변경사항을
            최소 7일전부터 선물하기 공지사항을 통하여 공지합니다. 단, 회원의
            권리 또는 의무에 중요한 내용의 변경은 시행일로부터 30일 이전에
            고지합니다.
            <br />
          </pre>
        </Modal.Body>
      </Modal>
    </>
  );
}
