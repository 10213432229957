// 헤더, 바텀 내비게이션 높이 설정 - 추후 변동 시 각 패딩 값 등에 영향 주지 않고 일괄 변경 위함.
export const presetHeight = {
  header: "5.4rem",
  bottomNav: "8rem",
};

// 헤더, 바텀 내비게이션 z-index 값 설정
export const headerFooterZIndex = 999;

// 상단 탭에 노출되는 리스트
export const headerMenuList = [
  {
    name: "전체",
    value: "all",
    categoryCode: "002",
  },
  {
    name: "맛집",
    value: "food",
    categoryCode: "003",
    personalCode: "007",
  },
  {
    name: "카페",
    value: "cafe",
    categoryCode: "004",
    personalCode: "008",
  },
  // {
  //   name: "주점",
  //   value: "drink",
  // },
  {
    name: "뷰티",
    value: "beauty",
    categoryCode: "005",
    personalCode: "009",
  },
  // {
  //   name: "엔터",
  //   value: "entertainment",
  // },
  // {
  //   name: "운동",
  //   value: "exercise",
  // },
  // {
  //   name: "여가",
  //   value: "living",
  // },
  // {
  //   name: "반려동물",
  //   value: "pet",
  // },
  {
    name: "기타",
    value: "etc",
    categoryCode: "006",
    personalCode: "010",
  },
];

export const reviewSortList = [
  {
    name: "최신순",
    value: "NEW",
  },
  {
    name: "별점 높은 순",
    value: "HIGH",
  },
  {
    name: "별점 낮은 순",
    value: "LOW",
  },
];

export const couponSortList = [
  {
    name: "최신순",
    value: "NEW",
  },
  {
    name: "구매순",
    value: "ORDER",
  },
];
