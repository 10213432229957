import React, { forwardRef } from "react";
import { css, styled } from "styled-components";
import { color } from "../styles/theme";

const Typography = styled.div`
  ${(p) => p.$fontStyle};
  color: ${(p) => (p.$color ? p.$color : color.gray.d900)};
  margin-top: ${(p) => (p.$marginTop ? p.$marginTop : 0)};
  margin-bottom: ${(p) => (p.$marginBottom ? p.$marginBottom : 0)};
  margin-right: ${(p) => (p.$marginRight ? p.$marginRight : 0)};
  margin-left: ${(p) => (p.$marginLeft ? p.$marginLeft : 0)};
  letter-spacing: -0.02rem;
  /* user-select: none; */
  ${(p) =>
    p.$ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${(p) => (p.$ellipsisNum ? p.$ellipsisNum : 1)};
      -webkit-box-orient: vertical;
      word-break: break-word;
    `}
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
  ${(p) => p.$styles};
`;

/**
 * 타이포그래피 구성요소
 * @typedef {object} TypoProperty
 * @property {any=} children
 * @property {object} color
 * @property {boolean=} ellipsis
 * @property {number=} ellipsisNum
 * @property {string=} marginTop
 * @property {string=} marginBottom
 * @property {string=} marginLeft
 * @property {string=} marginRight
 * @property {any=} fontStyle
 * - using " css`...` "
 * @property {any=} styles
 * - using " css`...` "
 * @property {void} onClick
 * - onClick 필요할 때 있으면 사용
 * @property {"div"|"span"} elementType
 * - 기본적으로 div이나 span등 다른 엘리먼트로 랜더해야할때 작성
 *
 * @param {TypoProperty}
 */
const Text = (
  {
    children,
    color,
    ellipsis,
    ellipsisNum,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    fontStyle,
    styles,
    onClick,
    elementType = "div",
  },
  ref
) => {
  return (
    <Typography
      as={elementType}
      ref={ref}
      $color={color}
      $ellipsis={ellipsis}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $marginRight={marginRight}
      $marginLeft={marginLeft}
      $styles={styles}
      $fontStyle={fontStyle}
      $ellipsisNum={ellipsisNum}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      {children}
    </Typography>
  );
};

export default forwardRef(Text);
