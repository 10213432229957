import React from "react";
import { StarIcon } from "../../../../../../components/ver1.5/assets/icons";
import { color, font } from "../../../../../../components/ver1.5/styles/theme";
import { styled } from "styled-components";
import Text from "../../../../../../components/ver1.5/atom/Text";
import StarIcons from "../atom/StarIcons";

const Container = styled.div`
  width: calc(100% - 3.2rem);
  margin: 0 auto;
  background: ${color.gray.d10};
  border-radius: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.3rem 0;
  gap: 0.6rem;
`;

const ReviewAverage = ({ average }) => {
  return (
    <Container>
      <StarIcons grade={average} iconSize={"2.4rem"} />
      <Text fontStyle={font.subtitle3} color={color.gray.d900}>
        {average}
      </Text>
      <Text fontStyle={font.body1} color={color.gray.d400}>
        / 5
      </Text>
    </Container>
  );
};

export default ReviewAverage;
