import React from "react";
import { css, styled } from "styled-components";
import {
  color,
  elevation,
  font,
} from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";
import {
  phoneNumberConvert,
  securePhoneNumber,
  setCommas,
} from "../../../../function/common";
import Button from "../../../../components/ver1.5/atom/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  padding: 1.6rem 2.4rem 4.8rem;
`;

const Row = styled.div`
  display: flex;
  align-items: ${(p) => (p.$alignItems ? p.$alignItems : "center")};
  ${(p) =>
    p.$gap &&
    css`
      gap: ${p.$gap};
    `}
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
    ${(p) => p.$styles};
`;

const Card = styled.div`
  border-radius: 1.2rem;
  box-shadow: ${elevation.d2};
  background: ${color.primary.white};
`;

const CardHeader = styled.div`
  display: flex;
  padding: 1.6rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color.gray.d100};
`;

const CardContent = styled.div`
  display: flex;
  padding: 1.6rem;
  padding-bottom: 2.4rem;
  flex-direction: column;
`;

const Image = styled.img`
  width: 8rem;
  min-height: 4rem;
  aspect-ratio: 34 / 21;
  border-radius: 0.4rem;
  overflow: hidden;
  object-fit: cover;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const Logo = styled.img`
  width: 2.4rem;
  aspect-ratio: 1;
  overflow: hidden;
  object-fit: contain;
  border-radius: 1.2rem;
  border: 1px solid ${color.gray.d100};
`;

const TopContent = ({
  navigate,
  orderedAt,
  recipient,
  imageUrl,
  logoUrl,
  storeName,
  productName,
  price,
  status,
  receiptInfo,
}) => {
  function getOrderedAt(orderedAt) {
    if (!Boolean(orderedAt) || isNaN(parseInt(orderedAt))) return "-";
    let arr = [];
    const target = orderedAt.split("");
    for (let i = 0; i < target.length; i += 2) {
      arr.push(target.slice(i, i + 2).join(""));
    }
    const date = arr.slice(0, 3);
    const hour = arr.slice(3);
    return "20" + date.join("-") + " " + hour.join(":");
  }

  return (
    <Container>
      <Row $gap={"0.8rem"}>
        <Text fontStyle={font.body2} color={color.gray.d700}>
          주문일
        </Text>
        <Text fontStyle={font.body2} color={color.gray.d700}>
          {getOrderedAt(orderedAt)}
        </Text>
      </Row>
      <Card>
        <CardHeader>
          <Row $gap={"0.4rem"}>
            <Text fontStyle={font.body2} color={color.gray.d900}>
              To.{recipient?.name}
            </Text>
            <Text fontStyle={font.caption2} color={color.gray.d600}>
              (
              {recipient?.phoneNumber
                ? securePhoneNumber(phoneNumberConvert(recipient.phoneNumber))
                : "-"}
              )
            </Text>
          </Row>
        </CardHeader>
        <CardContent>
          <Row $gap={"1.2rem"} $alignItems={"flex-start"}>
            <Image src={imageUrl} />
            <TextContainer>
              <Row $gap={"0.4rem"}>
                <Logo src={logoUrl} />
                <Text fontStyle={font.body3} color={color.gray.d600}>
                  {storeName}
                </Text>
              </Row>
              <Text fontStyle={font.body2} color={color.gray.d800}>
                {productName}
              </Text>
              <Text fontStyle={font.subtitle3} color={color.gray.d900}>
                {setCommas(price) + "원"}
              </Text>
            </TextContainer>
          </Row>
          <Text
            fontStyle={font.body2}
            color={color.gray.d600}
            marginTop="1.6rem"
            marginLeft="auto"
          >
            {status?.description}
          </Text>
          <Button
            variant="outline"
            text="카드 영수증"
            customButtonStyle={css`
              border-color: ${color.gray.d100};
              padding: 0.6rem 0;
              border-radius: 0.8rem;
              > div {
                ${font.body2};
                color: ${color.gray.d800};
              }
            `}
            onClick={() => navigate("detail", { state: { receiptInfo } })}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default TopContent;
