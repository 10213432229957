import React from "react";
import { css, styled } from "styled-components";
import ShopListItem from "../molecule/ShopListItem";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: calc(100% - 1.6rem);
  margin: 0;
  margin-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  flex-direction: ${(p) => p.direction};
  gap: 3.2rem 1rem;
  ${(p) =>
    p.$marginTop &&
    css`
      margin-top: ${(p) => p.$marginTop};
    `}
  ${(p) =>
    p.$marginBottom &&
    css`
      margin-bottom: ${(p) => p.$marginBottom};
    `}
    ${(p) =>
    p.type === "square" &&
    css`
      overflow: hidden;
      overflow-x: auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    `}
`;

/**
 * ShopList 구성요소
 * @typedef {object} ShopListProperty
 * @property {void} onClick
 * - onClick 필요할 때 있으면 사용
 * @property {"full"|"square"=} type
 * @property {"row"|"column"} direction
 * @property {array} data
 * @property {string=} marginTop
 * @property {string=} marginBottom
 *
 * @param {ShopListProperty}
 */
const ShopList = ({
  data = [],
  type = "square",
  direction = "row",
  marginTop,
  marginBottom,
}) => {
  const navigate = useNavigate();
  return (
    <Container
      direction={direction}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      type={type}
    >
      {data.map((item, index) => {
        return (
          <ShopListItem
            variant={type}
            key={index}
            item={item}
            onClick={() => {
              if (item.domain) {
                navigate(`/user/${item.domain}`);
              }
            }}
          />
        );
      })}
    </Container>
  );
};

export default ShopList;
