import React, { useEffect, useMemo, useState } from "react";
import { css, styled } from "styled-components";
import { color, font } from "../../../../components/ver1.5/styles/theme";
import Text from "../../../../components/ver1.5/atom/Text";
import Button from "../../../../components/ver1.5/atom/Button";
import { DownloadIcon } from "../../../../components/ver1.5/assets/icons";
import BwipJs from "bwip-js";
import { localeStringFormatter } from "../../../../components/ver1.5/utils";
import { RowContainer } from "../../../../components/ver1.5/styles/Ver2GlobalStyles";
// import ProductCard from "./ProductCard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 3.2rem;
  background: ${color.primary.white};
  border-radius: 0.8rem;
  width: calc(100% - 1.6rem);
  box-shadow: 0 0 0.8rem 0 ${color.gray.d100};
`;
const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductCard = styled.img`
  width: 100%;
  aspect-ratio: 34/21;
  border-radius: 0.8rem;
  object-fit: cover;
`;

const QrContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
  box-sizing: border-box;
  border-top: 1px solid ${color.gray.d100};
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1.6rem;
  gap: 0.8rem;
`;

const QrImage = styled.img`
  width: 18rem;
  height: 18rem;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
`;

const InfoRow = styled.div`
  display: flex;
  padding: 1.4rem 0;
  gap: 1.2rem;
  justify-content: space-between;
  align-items: center;
  ${(p) =>
    !p.$isLast &&
    css`
      border-bottom: 1px solid ${color.gray.d50};
    `}
  > * {
    flex-shrink: 0;
    flex-wrap: wrap;
    max-width: 50%;
    word-break: keep-all;
  }
`;

export const codeFormatter = (num) => {
  let target = num;
  if (typeof num === "number") {
    target = num.toString();
  }
  return (
    target.slice(0, 4) + " " + target.slice(4, 8) + " " + target.slice(8, 12)
  );
};

const CouponInfoContent = ({
  productImage = null,
  productType = null,
  productBalance = 0,
  productRewardBalance = 0,
  productName = "",
  couponNumber = "000000000000",
  expireDate: expireDateProp = "-",
  used,
  orderNumber,
  storeName = "-",
  issuedDate = "-",
  onClickCapture,
  qrImgUrl,
  singleStore,
  productCardColor,
  productTextColor,
  productPrice,
  productStatus,
}) => {
  //   console.log(expireDate);
  //   console.log(new Date(expireDate));
  const expireDate = new Date(expireDateProp);
  const [displayDate, setDisplayDate] = useState("-");

  const infoList = useMemo(() => {
    let result = [];
    const default1 = {
      title: "유효기간",
      value: displayDate,
    };
    result = [...result, default1];
    if (Boolean(orderNumber)) {
      result = [
        ...result,
        {
          title: "주문번호",
          value: orderNumber,
        },
      ];
    }
    result = [
      ...result,
      {
        title: "사용처",
        value: storeName,
      },
    ];
    if (!singleStore) {
      result = [
        ...result,
        {
          title: "이용매장",
          value: "용호본점,대연점,오륙도점,서면점,동래점,동부산점,창원점",
        },
      ];
    }
    const default2 = [
      {
        title: "선물 주문일",
        value: issuedDate,
      },
      {
        title: "선물상태",
        value:
          productStatus.code === 2
            ? "사용완료"
            : productStatus.code === 6
            ? "사용중"
            : "사용가능",
      },
    ];
    result = [...result, ...default2];

    return result;
  }, [
    singleStore,
    displayDate,
    orderNumber,
    storeName,
    issuedDate,
    productStatus,
  ]);

  useEffect(() => {
    if (expireDate !== "Invalid Date") {
      setDisplayDate(
        `${expireDate.getFullYear()}년 ${
          expireDate.getMonth() + 1 > 9
            ? expireDate.getMonth() + 1
            : "0" + (expireDate.getMonth() + 1)
        }월 ${expireDate.getDate()}일까지`
      );
    }
    // eslint-disable-next-line
  }, [expireDateProp]);

  useEffect(() => {
    if (couponNumber) {
      try {
        BwipJs.toCanvas("barcodeCanvas", {
          bcid: "pdf417",
          text: couponNumber,
          scale: 3,
          height: 7,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [couponNumber]);

  return (
    <Container>
      <ImgContainer>
        <ProductCard src={productImage} />
        {/* <ProductCard
          productCardColor={productCardColor}
          productTextColor={productTextColor}
          productPrice={productBalance}
          productImage={productImage}
          productName={productName}
          storeName={storeName}
          productType={productType}
          productBalance={productBalance}
        /> */}
      </ImgContainer>
      <QrContainer>
        {/* <InfoContainer
          style={{
            width: "100%",
            marginTop: "1.6rem",
            marginBottom: "0.8rem",
            justifyContent: "center",
          }}
        >
          <Text
            fontStyle={font.body3}
            color={color.gray.d200}
            styles={css`
              text-align: center;
            `}
          >
            {storeName}
          </Text>
          <Text
            fontStyle={font.h2}
            styles={css`
              text-align: center;
            `}
          >
            {localeStringFormatter(productPrice, " 원")}
          </Text>
        </InfoContainer> */}
        {productType !== 1 && (
          <RowContainer style={{ gap: "4.8rem" }}>
            <BalanceContainer>
              <Text fontStyle={font.caption3} color={color.gray.d700}>
                {productName}
              </Text>
              <Text fontStyle={font.subtitle2}>
                {localeStringFormatter(productBalance, " 원")}
              </Text>
            </BalanceContainer>
            {Boolean(productRewardBalance) && (
              <BalanceContainer>
                <Text fontStyle={font.caption3} color={color.gray.d700}>
                  + 추가 적립금
                </Text>
                <Text fontStyle={font.subtitle2}>
                  {localeStringFormatter(productRewardBalance, " 원")}
                </Text>
              </BalanceContainer>
            )}
          </RowContainer>
        )}
        <ImgWrapper>
          {/* <QrImage src={qrImgUrl} /> */}
          <canvas
            id="barcodeCanvas"
            style={{
              ...(productType === 1
                ? { padding: "3rem 0" }
                : { paddingBottom: "3rem" }),
            }}
          />
          <Text
            fontStyle={font.subtitle2}
            color={color.gray.d900}
            styles={css`
              position: absolute;
              bottom: -0.8rem;
              left: 50%;
              transform: translate(-50%, 0);
              width: 100%;
              text-align: center;
            `}
          >
            {codeFormatter(couponNumber)}
          </Text>
        </ImgWrapper>
        <Button
          variant="text"
          text="선물 교환권 저장"
          icon={<DownloadIcon />}
          textBtnStyle={css`
            border: 1px solid ${color.gray.d100};
            border-radius: 0.4rem;
            padding: 0.6rem 1.6rem;
            div {
              display: flex;
              gap: 0.4rem;
              align-items: center;
            }
          `}
          textBtnFontStyle={font.caption3}
          textBtnColor={color.gray.d800}
          onClick={() => {
            onClickCapture();
          }}
        />
      </QrContainer>
      <InfoContainer>
        <Text fontStyle={font.subtitle3} color={color.gray.d900}>
          선물 정보
        </Text>
        <div>
          {infoList.map((info, index) => (
            <InfoRow key={index} $isLast={index === infoList.length - 1}>
              <Text fontStyle={font.caption2} color={color.gray.d600}>
                {info.title}
              </Text>
              <Text
                fontStyle={font.caption2}
                color={color.gray.d900}
                styles={css`
                  text-align: right;
                `}
              >
                {info.value}
              </Text>
            </InfoRow>
          ))}
        </div>
      </InfoContainer>
    </Container>
  );
};

export default CouponInfoContent;
