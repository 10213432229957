import { makeAddress } from './../../function/common';

export default function CoustomGeocode (props){

    const { naver } = window;

    let latlng = new naver.maps.LatLng(37.5466103, 126.9783882); // 위/경도 좌표
    console.log(latlng);

    let utmk = naver.maps.TransCoord.fromLatLngToUTMK(latlng); // 위/경도 -> UTMK
    console.log("utmk : " + utmk.x + " : " + utmk.y);
    let tm128 = naver.maps.TransCoord.fromUTMKToTM128(utmk);   // UTMK -> TM128
    console.log("tm128 : " + tm128.x + " : " + tm128.y);
    var naverCoord = naver.maps.TransCoord.fromTM128ToNaver(tm128); //네이버로 최종 치환
    console.log("naverCoord : " + naverCoord.x + " : " + naverCoord.y);

    naver.maps.Service.reverseGeocode({
        coords: latlng,
        orders: [
            naver.maps.Service.OrderType.ADDR,
            naver.maps.Service.OrderType.ROAD_ADDR
        ].join(',')
    }, function(status, response) {
        if (status === naver.maps.Service.Status.ERROR) {
            return alert('Something Wrong!');
        }

        var items = response.v2.results,
            address = '',
            htmlAddresses = []; 

        for (var i=0, ii=items.length, item, addrType; i<ii; i++) {
            item = items[i];
            address = makeAddress(item) || '';
            addrType = item.name === 'roadaddr' ? '[도로명 주소]' : '[지번 주소]';

            htmlAddresses.push((i+1) +'. '+ addrType +' '+ address);
            console.log((i+1) +'. '+ addrType +' '+ address);
        }
    });

    return (
        <>
            <p>{naverCoord.x + " : " + naverCoord.y}</p>
        </>
    )
}