
import { useState } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';


import './Login.css';
import { setAccessToken } from './../../api/ApiWebView';
import { GetAuthorize } from './../../api/ApiGetUser';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { PrintConsole, sleep } from '../../function/common';

export default function Login(){
    const navigate = useNavigate();

    const [account, setAccount] = useState({
        Id: "",
        Password: "",
        clientId: '10001'
    });

    const onChangeAccount = (e) => {
        //console.log(e.target.value);
        setAccount({
          ...account,
          [e.target.id]: e.target.value,
        });
    };

    useEffect( () => {
        // localStorage.removeItem('accessToken');

        if( localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null ){
            //토큰 있을때 추가 작업할 일 있을 시 사용
            navigate('/store/'+ localStorage.getItem('storeDomain'));
        }else{
            // navigate('/user/search');
        }

        try{
            // setNav3();
        }catch(e){}
    })

    const loginBtn = async () => {

        try{
            document.getElementById('submitBtn').setAttribute("disabled","true");

            await GetAuthorize(account);
                        
            sleep(500);
            PrintConsole("localStorage : " + localStorage.getItem('accessToken'));
            PrintConsole("localStorage : " + localStorage.getItem('refreshToken'));
            
            let resUserMe = await GetUserMe(account);
            
            sleep(500);
            try{
                localStorage.setItem('domainType', resUserMe.data.domainType);
                localStorage.setItem('name', resUserMe.data.name);
                localStorage.setItem('myStores' , JSON.stringify(resUserMe.data.myStores[0]));
                localStorage.setItem('storeDomain', resUserMe.data.myStores[0].storeDomain);
            }catch(e){
                console.log('mystore 없음 스텝 or 가입안함');
                localStorage.setItem('storeDomain', null);
            }

            console.log('//////////////////////////////////');
            console.log(resUserMe);

            navigate('/ms/intro');

            document.getElementById('submitBtn').removeAttribute("disabled");
            
        }catch(e){
            console.log(e);
            document.getElementById('submitBtn').removeAttribute("disabled");
        }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter') {
            loginBtn();
        }
    }

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
            <div className="MS">
                <div className="MS-Login d-flex align-items-center justify-content-center">
                    <div className="MSLoginBox">
                        <div className="imgbox text-center">
                            <img src="https://www.flexdaycdn.net/public/images/moneyshow/flexday_logo_big.png" alt="FLEX"
                                className="flex-logo img-fluid mb-3" />
                            <img src="https://www.flexdaycdn.net/public/images/moneyshow/moneyshow_logo.png" alt="FLEX"
                                className="moneyshow-logo img-fluid" />
                        </div>
                        <div className="row g-0">
                            <div className="col-12 mb-2">
                                <input type="text" id='Id' className="form-control InputType h-50px px-4" placeholder="아이디" 
                                    onChange={onChangeAccount} onKeyPress={onKeyPress}
                                />
                            </div>
                            <div className="col-12 mb-4">
                                <input type="password" id='Password' className="form-control InputType h-50px px-4" placeholder="비밀번호" onChange={onChangeAccount} onKeyPress={onKeyPress}
                                />
                            </div>
                            <div className="col-12">
                                <button type="button" className="btn btn-default h-auto py-3" id='submitBtn' onClick={ (e) => { loginBtn(); } }>로그인</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}