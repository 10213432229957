import React, { forwardRef, useState } from "react";
import { css, styled } from "styled-components";
import Text from "./Text";
import { color, font } from "../styles/theme";

const TextInputContainer = styled.div`
  position: relative;
  width: auto;
`;

const TextInput = styled.input`
  width: 100%;
  font-family: "Pretendard Regular", sans-serif;
  font-size: 1.4rem;
  border: none;
  outline: none;
  ${(p) =>
    p.$padding &&
    css`
      padding: ${(p) => p.$padding};
    `};
  margin-top: ${(p) => (p.$marginTop ? p.$marginTop : 0)};
  margin-bottom: ${(p) => (p.$marginBottom ? p.$marginBottom : 0)};
  margin-right: ${(p) => (p.$marginRight ? p.$marginRight : 0)};
  margin-left: ${(p) => (p.$marginLeft ? p.$marginLeft : 0)};
  background-color: ${(p) =>
    p.$focus
      ? p.$backgroundColor
        ? p.$backgroundColor
        : "transparent"
      : p.$backgroundColor
      ? p.$backgroundColor
      : "unset"};
  ${(P) => P.$backgroundColor && css``}
  ${(p) =>
    !p.autoHeight &&
    css`
      resize: none;
    `}
    ${(p) =>
    p.$label &&
    css`
      &:focus {
        border: 1px inset ${color.gray.d900};
      }
    `}
  ${(p) => p.$inputStyles};
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 1.2rem;
  top: 50%;
  transform: translate(0, -50%);
`;

/**
 * TextField 구성요소
 * @typedef {object} TextFieldProperty
 * @property {"email" | "password" | "text" | "tel" | "url" | "search" | "hidden"} type
 * - 텍스트 유형 (input에서 설정가능한 모든 type)
 * @property {'input'|'textarea'} elementType
 * @property {number=} maxLength
 * - 글자 최대 입력 가능 수 설정
 * @property {number=} rows
 * - 여러줄 일때 기본 높이 적용
 * @property {boolean=} autoHeight
 * - 여러줄 일때 스크롤이 아닌 텍스트 길이에 따른 높이 변경 시 필요
 * - autoHeight가 설정돼있지 않으면, rows보다 길이가 커질 경우 스크롤 노출.
 * @property {string | number} value
 * - 텍스트 필드 사용 시, value를 설정하면 setValue를 같이 사용해야하며, setValue가 아닌 onChange로 사용 시 onDelete 속성도 같이 필요.
 * - onChange만 사용했을 때 우측의 리셋버튼을 통한 텍스트 삭제를 위해 onDelete가 필요.
 * - 상기 조건이 충족되지 않을 시 콘솔 창에 warning이 나오니 필수 체크 요망.
 * @property {string=} marginTop
 * @property {string=} marginBottom
 * @property {string=} marginLeft
 * @property {string=} marginRight
 * @property {void} setValue
 * - 텍스트 필드 사용 시, value를 설정하면 setValue를 같이 사용해야하며, setValue가 아닌 onChange로 사용 시 onDelete 속성도 같이 필요.
 * - onChange만 사용했을 때 우측의 리셋버튼을 통한 텍스트 삭제를 위해 onDelete가 필요.
 * - 상기 조건이 충족되지 않을 시 콘솔 창에 warning이 나오니 필수 체크 요망.
 * @property {string} placeholder
 * @property {void=} onKeyUp
 * @property {void=} onKeyDown
 * @property {void} onChange
 * - onChange에서 e는 e.target.value
 * - 텍스트 필드 사용 시, value를 설정하면 setValue를 같이 사용해야하며, setValue가 아닌 onChange로 사용 시 onDelete 속성도 같이 필요.
 * - onChange만 사용했을 때 우측의 리셋버튼을 통한 텍스트 삭제를 위해 onDelete가 필요.
 * - 상기 조건이 충족되지 않을 시 콘솔 창에 warning이 나오니 필수 체크 요망.
 * @property {void=} onBlur
 * @property {void=} onFocus
 * @property {void=} inputStyles
 * @property {"new-password" | "password" | "on" | "off"} autoComplete
 * @property {boolean=} disabled
 * @property {any=} padding
 * @property {string=} backgroundColor
 * @property {boolean} onlyNumber
 * @property {boolean} readOnly
 * @property {string} label
 * @property {"text" | "decimal" | "numeric" | "tel" | "url" | "search" | "email"} inputMode
 * @property {any=} leftIcon
 * @property {string|number} iconWidth
 *
 * @param {TextFieldProperty}
 */
const TextField = (
  {
    elementType,
    type = "text",
    maxLength,
    rows,
    autoHeight,
    value,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    setValue,
    placeholder,
    onKeyUp,
    onKeyDown,
    onChange,
    onBlur: onBlurProp,
    onFocus: onFocusProp,
    inputStyles,
    autoComplete,
    disabled,
    padding,
    backgroundColor,
    onlyNumber = false,
    readOnly,
    id,
    label,
    inputMode,
    leftIcon,
    iconWidth,
  },
  ref
) => {
  const [focus, setFocus] = useState(false);

  const onFocus = () => {
    if (onFocusProp) {
      onFocusProp();
    }
    setFocus(true);
  };

  const onBlur = () => {
    if (onBlurProp) {
      onBlurProp();
    }
    setFocus(false);
  };

  return (
    <TextInputContainer>
      {leftIcon && <IconWrapper>{leftIcon}</IconWrapper>}
      <TextInput
        ref={ref}
        id={id}
        as={elementType}
        type={type}
        maxLength={maxLength}
        rows={rows}
        autoHeight={autoHeight}
        value={value}
        $marginTop={marginTop}
        $marginBottom={marginBottom}
        $marginLeft={marginLeft}
        $marginRight={marginRight}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onChange={(e) => {
          if (maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
          }
          if (onChange) {
            if (onlyNumber) {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }
            onChange(e);
          } else {
            setValue(e);
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        $inputStyles={inputStyles}
        autoComplete={autoComplete}
        disabled={disabled}
        $padding={padding}
        $backgroundColor={backgroundColor}
        readOnly={readOnly}
        $label={label}
        $focus={focus}
        inputMode={inputMode}
        $iconWidth={leftIcon && iconWidth && iconWidth}
      />
      {label && focus && (
        <Text
          fontStyle={font.caption3}
          color={color.gray.d900}
          styles={css`
            position: absolute;
            top: -0.4rem;
            left: 2rem;
            background-color: ${color.primary.white};
            padding: 0 0.4rem;
            line-height: 1em;
          `}
        >
          {label}
        </Text>
      )}
    </TextInputContainer>
  );
};

export default forwardRef(TextField);
