import Loding from "../components/common/Loding";
import DragDrop from "../pages/franchisee/tmp/DragDrop";
import TmpQuestions from "../pages/franchisee/tmp/TmpQuestion";
import TmpLogin from "../pages/franchisee/user/TmpLogin";

const TmpRoutes = [
  { path: "login", element: <TmpLogin /> },
  { path: "question", element: <TmpQuestions /> },
  { path: "dragdrop", element: <DragDrop /> },
  { path: "loding", element: <Loding /> },
];

export default TmpRoutes;
