import React from "react";
import { css, styled } from "styled-components";
import Text from "../atom/Text";
import TextField from "../atom/TextField";
import { color, font } from "../styles/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  margin-top: ${(p) => (p.$marginTop ? p.$marginTop : 0)};
  margin-bottom: ${(p) => (p.$marginBottom ? p.$marginBottom : 0)};
  margin-right: ${(p) => (p.$marginRight ? p.$marginRight : 0)};
  margin-left: ${(p) => (p.$marginLeft ? p.$marginLeft : 0)};
`;

const LabelledTextField = ({
  label,
  placeholder,
  value,
  onChange,
  readOnly,
  onlyNumber,
  type = "bordered",
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  helperText,
  error,
  required,
  inputType = "text",
  status,
  onBlur,
  onFocus,
  customBorderColor,
  autoComplete = "off",
  inputMode,
  maxLength,
  elementType,
  rows,
}) => {
  return (
    <Container
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $marginLeft={marginLeft}
      $marginRight={marginRight}
    >
      {label && (
        <Text
          fontStyle={font.body1}
          color={color.gray.d900}
          marginBottom="0.4rem"
          styles={css`
            span {
              color: ${color.primary.flexRed};
            }
          `}
        >
          {label}
          {required && <span> *</span>}
        </Text>
      )}
      <TextField
        type={inputType}
        value={value}
        onChange={onChange}
        onBlur={(e) => (onBlur ? onBlur(e) : null)}
        onFocus={(e) => (onFocus ? onFocus(e) : null)}
        placeholder={placeholder}
        readOnly={readOnly}
        autoComplete={autoComplete}
        inputStyles={
          type === "filled"
            ? css`
                background: ${color.gray.d50};
                ${elementType !== "textarea" &&
                css`
                  height: 5.6rem;
                `}
                border-radius: 1.2rem;
              `
            : type === "bordered"
            ? css`
                border: 1px solid
                  ${error
                    ? color.functional.danger.d600
                    : customBorderColor
                    ? customBorderColor
                    : color.gray.d100};
                border-radius: 1.2rem;
                ${elementType !== "textarea" &&
                css`
                  height: 5.6rem;
                `}
                width: 100%;
              `
            : null
        }
        padding={"1.6rem"}
        onlyNumber={onlyNumber}
        inputMode={inputMode}
        maxLength={maxLength}
        elementType={elementType}
        rows={rows}
      />
      {helperText && (
        <Text
          fontStyle={font.caption2}
          color={
            error
              ? color.functional.danger.d600
              : status === "complete"
              ? color.functional.success.d600
              : status === "error"
              ? color.functional.danger.d600
              : color.gray.d600
          }
        >
          {helperText}
        </Text>
      )}
    </Container>
  );
};

export default LabelledTextField;
