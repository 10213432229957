
import "./injeiosqr.css";

export default function Injeiosqr(){

    window.location.replace('https://apps.apple.com/kr/app/%EC%9D%B8%EC%A0%9C-%ED%8E%98%EC%9D%B4/id1671936601');

    return (
        <>
        {/* <div className="demo">
            <div className="T404Page">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">앱 출시 준비중입니다.</p>
                    </nav>

                    <div className="FailureBox Inner">
                        <div className="FailureBox-title my-5 text-center">
                            <img className="img-fluid mb-4"
                                src="https://www.flexdaycdn.net/public/images/static/store-register/cry-emoji.png" alt="" />
                            <p className="PageTit2 mb-4">
                                iOS 앱은 곧 출시 예정입니다!
                            </p>
                            <p className="PageSub">
                                불편을 드려 죄송합니다.
                            </p>
                        </div>
                    </div>

                    <div className="fixed-bottom px-4-5 pt-3 pb-4">
                        <div className="CancelConfirm d-flex justify-content-between flex-wrap">
                            <p className="PageSub text-center mb-4 w-100">
                                아래 버튼을 눌러 모바일 웹버전을 이용해주세요.
                            </p>
                            <button onClick={()=>{window.location.replace('https://ivoucher.kr/')}} className="btn btn-default">
                                모바일 웹버전 바로가기
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div> */}
        </>
    )
}