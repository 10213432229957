
import { Accordion } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


export default function LeftMenu( props ){

    const navigate = useNavigate();

    return (
        <>
            <Accordion defaultActiveKey={'0'} flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>메인 메뉴 #1</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>
                                <Link as={NavLink} to={"/admin/home"}>홈</Link>
                            </li>
                            <li>
                                <Link as={NavLink} to={"/admin/pg1"}>pg1</Link>
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>메인 메뉴 #2</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>
                                <Link as={NavLink} to={"/admin/home"}>홈</Link>
                            </li>
                            <li>
                                <Link as={NavLink} to={"/admin/pg1"}>pg1</Link>
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </> 
    )
}