import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiUrl, PrintConsole } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import "./MS-Common.css";
import "./MS-MobileTicket.css";

import { Modal } from 'react-bootstrap';
import MmsGentz from './MmsGentz';
import MmsGentz2 from './MmsGentz2';
import MmsGentz3 from './MmsGentz3';
import Injemms from './inje-mms';

export default function TicketType(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState('STEP1');

    const [tel, setTel] = useState();

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const { code } = useParams();
    PrintConsole(code);

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출


        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    async function checkGift(){

        document.getElementById('submitBtn').setAttribute("disabled","true");

        let obj = {
            "phoneNumber": document.getElementById('Tel').value
        };

        axios.post(
            getApiUrl("coupons/qrcode/"+code),
            obj
        ).then(
            function(response){

                console.log(response.status);
                if(response.status === 200){
                    // console.log(response.data.redirectUrl);
                    // let TmpUrl = response.data.redirectUrl.split('usegift?');
                    // console.log('/pos2/check?'+TmpUrl[1]);
                    console.log(response);
                    if(response.data.status.code==1){
                        setData(response.data);
                        setMode('STEP2');
                    }else{
                        handleShow2()
                    }
                    document.getElementById('submitBtn').removeAttribute("disabled");
                }else{

                }
            }
        ).catch(function(error){
            console.log(error);
            handleShow();
            document.getElementById('submitBtn').removeAttribute("disabled");
        });
    }

    function SubmitTel(){
        setTel(document.getElementById('Tel').value);
        checkGift()
        // setMode('STEP2');
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    if (loading) return <></>

    

    if (mode === 'STEP1'){
        return (
            <>
                 <div className="MS">
                    <div className="MS-MobileTicket responsiveTablet">
    
                        <div className="MobileBg"></div>
    
                        <div className="container-fluid g-0">
                            <nav className="sub-navbar">
                                <p className="navbar-title">모바일 교환권</p>
                            </nav>
    
                            <div className="MTBox Inner">
                                <div className="MTBox-title text-center">
                                    <p className="tit mb-2">
                                        본인 확인을 위해 <br />
                                        연락처 끝 번호 4자리를 입력해주세요.
                                    </p>
                                    <p className="sub">
                                        예시) 010-****-<span className="color">0000</span>
                                    </p>
                                </div>
    
                                <div className="MTBox-Input">
                                    <input type="number" id='Tel' className="InputType form-control mb-3 px-3"
                                        placeholder="연락처 끝 번호 4자리를 입력해주세요." />
                                    <button id='submitBtn' onClick={ () => {SubmitTel()}} className="btn btn-default" >
                                        입력
                                    </button>
                                </div>
                            </div>
                        </div>

                        <Modal
                            size="sm"
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            className="modal fade modal-prd-stop text-center"
                            centered
                        >
                            <Modal.Header className='pt-4-5'>
                                <Modal.Title>
                                    <p className="modal-title fw-semibold lh-sm">
                                        연락처 끝 번호가 <br className="mob" /> 일치하지 않습니다.
                                    </p>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                번호를 확인하신 후 다시 입력해주세요.
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <button type="button" className="btn btn-clear btn-modal-secondary" data-bs-dismiss="modal" >확인</button> */}
                                {/* <button type="button" className="btn btn-clear btn-modal-primary" data-bs-dismiss="modal" onClick={() => {}}>확인</button> */}
                                <button type="button" className="btn btn-clear btn-modal-primary w-100" data-bs-dismiss="modal" onClick={handleClose}>확인</button>
                            </Modal.Footer>
                        </Modal>
    
                        
                        {/* <div className="modal fade text-center" id="MS-Modal-04" tabIndex="-1">
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                                <div className="modal-content rounded-2">
                                    <div className="modal-header">
                                        <p className="modal-title fw-semibold lh-sm">
                                            연락처 끝 번호가 <br className="mob" /> 일치하지 않습니다.
                                        </p>
                                    </div>
                                    <div className="modal-body">
                                        번호를 확인하신 후 다시 입력해주세요.
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-clear btn-modal-primary w-100"
                                            data-bs-dismiss="modal">확인</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <Modal
                            size="sm"
                            show={show2}
                            onHide={handleClose2}
                            backdrop="static"
                            keyboard={false}
                            className="modal fade modal-prd-stop text-center"
                            centered
                        >
                            <Modal.Header className='pt-4-5'>
                                <Modal.Title>
                                    <p className="modal-title fw-semibold lh-sm">
                                        사용완료 또는 취소 되어 사용할 수 없는 교환권입니다.
                                    </p>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/*번호를 확인하신 후 다시 입력해주세요.*/}
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <button type="button" className="btn btn-clear btn-modal-secondary" data-bs-dismiss="modal" >확인</button> */}
                                {/* <button type="button" className="btn btn-clear btn-modal-primary" data-bs-dismiss="modal" onClick={() => {}}>확인</button> */}
                                <button type="button" className="btn btn-clear btn-modal-primary w-100" data-bs-dismiss="modal" onClick={handleClose2}>확인</button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            </>
        )
    }else if (mode === 'STEP2'){
        let type = data && data.templateType['code'];
        if(type === 2){
            return (
                <>
                    <MmsGentz 
                        couponNumber={ data && data.couponNumber}
                        expireDate={ data && data.expireDate}
                        productName={ data && data.productName}
                        storeName={ data && data.storeName}
                        representImageUrl = { data && data.representImageUrl }
                        qrCode ={ code }
                    ></MmsGentz>
                </>
            )
        }else if(type === 3){
            return (
                <>
                    <MmsGentz2
                        couponNumber={ data && data.couponNumber}
                        expireDate={ data && data.expireDate}
                        productName={ data && data.productName}
                        storeName={ data && data.storeName}
                        representImageUrl = { data && data.representImageUrl }
                        qrCode ={ code }
                    ></MmsGentz2>
                </>
            )
        }else if(type === 4){
            return (
                <>
                    <MmsGentz3
                        couponNumber={ data && data.couponNumber}
                        expireDate={ data && data.expireDate}
                        productName={ data && data.productName}
                        storeName={ data && data.storeName}
                        representImageUrl = { data && data.representImageUrl }
                        qrCode ={ code }
                    ></MmsGentz3>
                </>
            )
        }else if(type === 5){
            return (
                <>
                    <Injemms
                        couponNumber={ data && data.couponNumber}
                        expireDate={ data && data.expireDate}
                        productName={ data && data.productName}
                        storeName={ data && data.storeName}
                        representImageUrl = { data && data.representImageUrl }
                        qrCode ={ code }
                    ></Injemms>
                </>
            )
        }
    }
}