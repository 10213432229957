import { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import sha256 from "sha256";
import { AlertError, EmailCheck, getApiUrl } from "../../../../function/common";
import Layout from "../../../../components/ver1.5/layout/Layout";
import { css, styled } from "styled-components";
import { presetHeight } from "../../../../components/ver1.5/constants";
import Text from "../../../../components/ver1.5/atom/Text";
import LabelledTextField from "../../../../components/ver1.5/organism/LabelledTextField";
import Button from "../../../../components/ver1.5/atom/Button";
import { color } from "../../../../components/ver1.5/styles/theme";
import { passwordReg } from "../../../../components/common/constants";
import { randomUsername } from "korean-random-username";

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 3.4rem;
`;

const Certification = () => {
  const navigate = useNavigate();
  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }

  const appPush = useRef();
  const phoneText = useRef();
  const email = useRef();
  const phone = useRef();
  const mail = useRef();
  const token_version_id = useRef();
  const enc_data = useRef();
  const integrity_value = useRef();
  const isAcceptedInessentialPrivacy = useRef();
  const [focused, setFocused] = useState(null);
  const [data, setData] = useState({
    email: "",
    password: "",
    passwordCk: "",
    nickname: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
    passwordCk: false,
  });
  const [isValid, setIsValid] = useState(false);

  const onChangeData = (e, target) => {
    setData({
      ...data,
      [target]: e.target.value,
    });
  };

  // const onLoginId = (e) => {
  //   //아이디 중복체크
  //   let obj = {
  //     loginId: data.loginId,
  //   };
  //   axios
  //     .post(
  //       getApiUrl("auth/login-id"),
  //       obj,
  //       (axios.defaults.headers.common["ContentType"] = "application/json;")
  //     )
  //     .then(function (response) {
  //       if (response.status === 200) {
  //         console.log(response.data.loginIdExist);
  //         if (response.data.loginIdExist === false) {
  //           setValidation({ ...validation, loginId: true });
  //           e.target.parentElement
  //             .querySelector("div")
  //             .classList.remove("invalid-feedback");
  //           e.target.parentElement
  //             .querySelector("div")
  //             .classList.add("valid-feedback");
  //         } else {
  //           setValidation({ ...validation, loginId: false });
  //           e.target.parentElement
  //             .querySelector("div")
  //             .classList.remove("valid-feedback");
  //           e.target.parentElement
  //             .querySelector("div")
  //             .classList.add("invalid-feedback");
  //           e.target.parentElement.querySelector("div").textContent =
  //             "중복된 ID 입니다.";
  //         }
  //       }
  //     })
  //     .catch(function (error) {
  //       setValidation({ ...validation, loginId: false });
  //       e.target.parentElement
  //         .querySelector("div")
  //         .classList.remove("valid-feedback");
  //       e.target.parentElement
  //         .querySelector("div")
  //         .classList.add("invalid-feedback");
  //       e.target.parentElement.querySelector("div").textContent =
  //         error.response.data.errorDetails[0].reason;
  //     });
  // };

  // 가입하기 버튼
  async function submit() {
    // console.log(validation);
    if (data.email.length < 4) {
      alert("이메일 주소를 확인해주세요.");
      document.getElementById("email").focus();
      return false;
    }

    if (data.password !== data.passwordCk) {
      alert("비밀번호가 다릅니다.");
      document.getElementById("password").focus();
      return false;
    }
    const submitNickName = Boolean(data.nickname)
      ? data.nickname
      : randomUsername();
    let obj;
    let tmpData = {
      loginId: data.email,
      password: sha256(data.password),
      email: data.email,
      isAcceptedInessentialPrivacy: isAcceptedInessentialPrivacy.current,
      alarm: {
        appPush: appPush.current,
        phoneText: phoneText.current,
        email: email.current,
        phone: phone.current,
        mail: mail.current,
      },
      selfAuth: {
        token_version_id: token_version_id.current,
        enc_data: enc_data.current,
        integrity_value: integrity_value.current,
      },
      nickName: submitNickName,
      lastVisitedStoreName: localStorage.getItem("lastVisitedStoreName"),
    };

    // console.log(tmpData);

    // console.log(file);
    // obj = new Blob( [JSON.stringify(tmpData)], { type: 'application/json'} );

    var formData = new FormData();
    formData.append("signupRequest", obj);
    // formData.append('logo', file);

    axios
      .post(
        getApiUrl("users"),
        tmpData
        // formData, {
        // headers: {
        // "Content-Type": "multipart/form-data",
        // },
        // }
      )
      .then(function (response) {
        if (response.status === 200) {
          // setDoneModal(true);
          const isSaved = sessionStorage.getItem("giftProduct");
          const now = new Date();
          navigate("/user/signupEnd", {
            replace: true,
            state: {
              redirectUrl: isSaved && `/user/gift/${isSaved}`,
              nickname: submitNickName,
              email: data.email,
              createdAt: `${now.getFullYear()}-${
                now.getMonth() + 1 > 10
                  ? now.getMonth() + 1
                  : "0" + (now.getMonth() + 1)
              }-${now.getDate()} ${now.getHours()}시`,
              alarmAppPush: appPush.current,
              alarmPhoneText: phoneText.current,
              alarmEmail: email.current,
              alarmPhone: phone.current,
            },
          });
        }
      })
      .catch(function (error) {
        AlertError(error);
        // navigate('/404');
      });
  }

  // 이메일 error
  useEffect(() => {
    if (data.email.length) {
      if (!EmailCheck(data.email)) {
        setError((prev) => ({ ...prev, email: true }));
      } else {
        setError((prev) => ({ ...prev, email: false }));
      }
    } else {
      setError((prev) => ({ ...prev, email: false }));
    }
  }, [data.email]);

  // 비밀번호 error
  useEffect(() => {
    if (data.password.length) {
      if (!passwordReg.test(data.password)) {
        setError((prev) => ({ ...prev, password: true }));
      } else {
        setError((prev) => ({ ...prev, password: false }));
      }
    } else {
      setError((prev) => ({ ...prev, password: false }));
    }
  }, [data.password]);

  // 비밀번호 확인 error
  useEffect(() => {
    if (data.passwordCk.length && data.password.length) {
      if (data.password !== data.passwordCk) {
        setError((prev) => ({ ...prev, passwordCk: true }));
      } else {
        setError((prev) => ({ ...prev, passwordCk: false }));
      }
    } else {
      setError((prev) => ({ ...prev, passwordCk: false }));
    }
  }, [data.passwordCk, data.password]);

  // 버튼 유효성
  useEffect(() => {
    if (
      Object.values(error).filter((item) => item !== false).length === 0 &&
      data.email &&
      data.password &&
      data.passwordCk
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [data, error]);

  useEffect(() => {
    if (Parameters.length !== 0) {
      let obj = {
        token_version_id: Parameters[0].token_version_id,
        enc_data: Parameters[1].enc_data,
        integrity_value: Parameters[2].integrity_value,
      };
      // console.log(obj);
      axios
        .post(
          getApiUrl("auth/self/result"),
          obj,
          (axios.defaults.headers.common["ContentType"] = "application/json;")
        )
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            // console.log(response.data);
            let obj = JSON.parse(response.data.customData);

            appPush.current = obj.indexOf("s5") !== -1 ? true : false;
            phoneText.current = obj.indexOf("s6") !== -1 ? true : false;
            email.current = obj.indexOf("s7") !== -1 ? true : false;
            phone.current = obj.indexOf("s8") !== -1 ? true : false;
            isAcceptedInessentialPrivacy.current =
              obj.indexOf("s5") !== -1 ||
              obj.indexOf("s6") !== -1 ||
              obj.indexOf("s7") !== -1 ||
              obj.indexOf("s8") !== -1
                ? true
                : false;
            token_version_id.current = Parameters[0].token_version_id;
            enc_data.current = Parameters[1].enc_data;
            integrity_value.current = Parameters[2].integrity_value;
          } else {
            alert("본인인증에 실패하였습니다.");
            navigate("/404");
          }
        })
        .catch(function (error) {
          AlertError(error);
          // if (window.location.href.indexOf("/store/") !== -1) {
          //   navigate("/store/login");
          // } else {
          const isSaved = sessionStorage.getItem("giftProduct");
          navigate("/user/login", {
            replace: true,
            state: {
              redirectUrl: isSaved && `/user/gift/${isSaved}`,
            },
          });
          // }
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      styles={css`
        padding: 3.2rem 2.4rem;
        padding-top: ${`calc(${presetHeight.header} + 3.2rem)`};
        padding-bottom: ${`calc(${presetHeight.bottomNav} + 3.2rem)`};
      `}
    >
      <Text
        styles={css`
          font-family: "Pretendard Bold", sans-serif;
          font-weight: 700;
          font-size: 3.2rem;
          letter-spacing: -0.1rem;
        `}
        marginBottom="3.2rem"
      >
        회원정보를 입력해주세요
      </Text>
      <InputGroup>
        <LabelledTextField
          label={"이메일"}
          required
          placeholder={"example@xxxx.com"}
          type={
            focused === "email" || data.email.length > 0 ? "bordered" : "filled"
          }
          value={data.email}
          onChange={(e) => onChangeData(e, "email")}
          error={error.email}
          helperText={error.email && "유효한 이메일을 입력해주세요"}
          onFocus={() => setFocused("email")}
          onBlur={() => setFocused(null)}
          customBorderColor={
            !error.email && focused === "email"
              ? color.gray.d900
              : color.gray.d100
          }
        />
        <LabelledTextField
          label={"비밀번호"}
          required
          placeholder={"비밀번호 입력"}
          type={
            focused === "password" || data.email.length > 0
              ? "bordered"
              : "filled"
          }
          inputType="password"
          autoComplete="new-password"
          value={data.password}
          onChange={(e) => onChangeData(e, "password")}
          error={error.password}
          helperText={
            error.password
              ? "영문+숫자+특수문자 !@#$%^ 를 포함 10~18자를\n충족하지 못했습니다"
              : "영문+숫자+특수문자 !@#$%^ 를 포함 10~18자"
          }
          onFocus={() => setFocused("password")}
          onBlur={() => setFocused(null)}
        />
        <LabelledTextField
          label={"비밀번호 확인"}
          required
          placeholder={"비밀번호 재입력"}
          type="filled"
          inputType="password"
          autoComplete="new-password"
          value={data.passwordCk}
          onChange={(e) => onChangeData(e, "passwordCk")}
          error={error.passwordCk}
          status={!error.passwordCk && !error.password ? "complete" : null}
          helperText={
            error.passwordCk
              ? "비밀번호가 일치하지 않습니다."
              : !error.passwordCk && data.passwordCk.length > 10
              ? "비밀번호가 일치합니다."
              : null
          }
        />
        <LabelledTextField
          label={"닉네임"}
          placeholder={"닉네임 입력"}
          type="filled"
          value={data.nickname}
          onChange={(e) => onChangeData(e, "nickname")}
        />
      </InputGroup>
      <BtnWrapper>
        <Button disabled={!isValid} onClick={() => submit()} text="가입하기" />
      </BtnWrapper>
    </Layout>
  );
};

export default Certification;
