
import { Container, Tab, Table } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Tabs } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import Btn from '../../components/common/Btn';
import SelectBox from '../../components/common/SelectBox';
import CheckBox from './../../components/common/CheckBox';
import CustomModal from '../../components/common/CustomModal';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';

import { useState } from 'react';

export default function History( props ){
    const tableCss = {verticalAlign: 'top'};

    const [startDate, setStartDate] = useState(new Date());
    const [startEnd, setEndDate] = useState(new Date());

    return (
        <> 
            <Container>
                <Tabs
                defaultActiveKey="history"
                id="fill-tab"
                className=""
                justify
                >
                    <Tab eventKey="history" title="사용내역">
                        <Container>
                            <Row className="text-center">
                                <Col xs={3}>오늘</Col>
                                <Col xs={3}>이번주</Col>
                                <Col xs={3}>지난주</Col>
                                <Col xs={3}>이번달</Col>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col>
                                    <ReactDatePicker className='text-center' selected={startDate} locale={ko} dateFormat='yyyy년 MM월 dd일' onChange={date => setStartDate(date)} /> 
                                </Col>
                                <Col className='text-center'> ~ </Col>
                                <Col>
                                    <ReactDatePicker className='text-center' selected={startEnd} locale={ko} dateFormat='yyyy년 MM월 dd일' onChange={date => setEndDate(date)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <label>최대 1년까지 조회 가능합니다.</label>
                                </Col>
                                <Col xs={12} className='d-grid'>
                                    <Btn btntext="조회하기" />
                                </Col>
                            </Row>
                            <Row>
                                <Table className='text-center table-borderless'>
                                    <thead>
                                        <tr>
                                            <td>총 판매액</td>
                                            <td>수수료</td>
                                            <td>정산예정액</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>2,800,000원</td>
                                            <td>2,800,000원</td>
                                            <td>2,800,000원</td>
                                        </tr>
                                        <tr>
                                            <td colSpan='3'><label>월요일~일요일 사용한 금액이 차주 수요일 입금(정산)됩니다.</label></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                            
                            <Row>
                                <Table>
                                    <thead>
                                        <tr className="text-center">
                                            <th>No</th>
                                            <th>쿠폰번호</th>
                                            <th>사용일시</th>
                                            <th>주문내용</th>
                                            <th>이용금액</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>0000-0000-0000-0000</td>
                                            <td>YYYY-mm-dd 10:34</td>
                                            <td>플렉스 시그니처..</td>
                                            <td>18,000원</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>0000-0000-0000-0000</td>
                                            <td>YYYY-mm-dd 10:34</td>
                                            <td>플렉스 시그니처..</td>
                                            <td>18,000원</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>0000-0000-0000-0000</td>
                                            <td>YYYY-mm-dd 10:34</td>
                                            <td>플렉스 시그니처..</td>
                                            <td>18,000원</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                        </Container>
                    </Tab>
                    <Tab eventKey="cancel" title="판매취소 내역 보기">
                        <Container>
                            <Row className="text-center">
                                <Col xs={3}>오늘</Col>
                                <Col xs={3}>이번주</Col>
                                <Col xs={3}>지난주</Col>
                                <Col xs={3}>이번달</Col>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col>
                                    <ReactDatePicker className='text-center' selected={startDate} locale={ko} dateFormat='yyyy년 MM월 dd일' onChange={date => setStartDate(date)} />
                                </Col>
                                <Col className='text-center'> ~ </Col>
                                <Col>
                                    <ReactDatePicker className='text-center' selected={startEnd} locale={ko} dateFormat='yyyy년 MM월 dd일' onChange={date => setEndDate(date)} />
                                </Col>
                            </Row>
                            <Row className='align-items-center'>
                                <Col xs={3} >
                                    <label>상품별 선택</label>
                                </Col>
                                <Col>
                                    <SelectBox selectdata={[{key:1, value:1, title:'아뭬리까노'}, {key:2, value:1, title:'뜨거운 아이스 아뭬리까노'}]} />
                                </Col>
                            </Row>
                            <Row>
                                <label>최대 1년까지 조회 가능합니다.</label>
                                <Btn btntext="조회하기" />
                            </Row>
                            <Row>
                                <CheckBox controlId="id" label="전체선택"/>
                                <Table>
                                    <thead>
                                        <tr className="text-center">
                                            <th>선택</th>
                                            <th>번호</th>
                                            <th>주문번호</th>
                                            <th>주문내용</th>
                                            <th>판매가격</th>
                                        </tr>
                                    </thead>
                                    <tbody style={tableCss}>
                                        <tr>
                                            <td><CheckBox controlId="1"/></td>
                                            <td>1</td>
                                            <td>
                                                <p>000123</p>
                                                <p>구매일시</p>
                                                <p>2022.0.02.(18:05)</p>
                                            </td>
                                            <td>
                                                <p>플렉스 시그니처..</p>
                                                <p>결제자 : 홍길동</p>
                                                <p>주문상세 &gt;</p>
                                            </td>
                                            <td><p>16,000원</p></td>
                                        </tr>
                                        <tr>
                                            <td><CheckBox controlId="2"/></td>
                                            <td>1</td>
                                            <td>
                                                <p>000123</p>
                                                <p>구매일시</p>
                                                <p>2022.0.02.(18:05)</p>
                                            </td>
                                            <td>
                                                <p>플렉스 시그니처..</p>
                                                <p>결제자 : 홍길동</p>
                                                <p>주문상세 &gt;</p>
                                            </td>
                                            <td><p>16,000원</p></td>
                                        </tr>
                                        <tr>
                                            <td><CheckBox controlId="3"/></td>
                                            <td>1</td>
                                            <td>
                                                <p>000123</p>
                                                <p>구매일시</p>
                                                <p>2022.0.02.(18:05)</p>
                                            </td>
                                            <td>
                                                <p>플렉스 시그니처..</p>
                                                <p>결제자 : 홍길동</p>
                                                <p>주문상세 &gt;</p>
                                            </td>
                                            <td><p>16,000원</p></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                총 판매액
                                            </td>
                                            <td>
                                                64,000원
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                선택 내역 금액
                                            </td>
                                            <td>
                                                16,000원
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>
                                <CustomModal type='CENTER' mdtitle='판매취소 하시겠습니까?' mdtext='판매취소할 경우, 교환권은 미사용으로 바뀝니다.' rightbtn='판매취소하기' title='판매취소' />
                            </Row>                            
                        </Container>
                    </Tab>
                </Tabs>
            </Container>
        </>
    )
}